import { BUDGET_SHARED_TYPE, onboardingFlags, PAGE_URLS } from "../constants";
import * as _icons from "../assets/icons/dock";
import dayjs from "dayjs";
const icons = _icons as any;

export type TabLink = {
  name: string;
  url: string;
  icon?: string;
  icon_selected?: string;
  subLinks?: TabLink[];
  color?: string;
};

const homeTabLinks: TabLink[] = [
  { name: "bills", url: PAGE_URLS.BILLS },
  { name: "billsAudit", url: PAGE_URLS.BILLS_AUDIT_MAIN },
  { name: "budget", url: PAGE_URLS.BUDGET_HOME },
  { name: "cashFlow", url: PAGE_URLS.CASH_FLOW },
  { name: "forecast", url: PAGE_URLS.SPENDING_FORECAST },
  { name: "netWorth", url: PAGE_URLS.NET_WORTH },
  { name: "breakdown", url: PAGE_URLS.SPENDING_ANALYSIS },
  { name: "challenges", url: PAGE_URLS.SPENDING_CHALLENGES_OVERVIEW },
  { name: "byPerson", url: PAGE_URLS.SPENDING_BY_PERSON_HOME },
].map((item) => ({
  ...item,
  url: item.url
    .replace(":effectiveDate", dayjs().startOf("month").format("YYYY-MM-DD"))
    .replace(":budgetType", BUDGET_SHARED_TYPE.HOUSEHOLD),
}));

export const tabLinks: TabLink[] = [
  { name: "home", url: PAGE_URLS.HOME, subLinks: homeTabLinks, color: "#E3EFFC" },
  { name: "balances", url: PAGE_URLS.ACCTS_HOME, color: "#E0FAEC" },
  { name: "transactions", url: PAGE_URLS.TRANS_HOME, color: "#E4E1FF" },
  { name: "goals", url: PAGE_URLS.GOALS_HOME, color: "#FFEAE6" },
  { name: "more", url: PAGE_URLS.MORE_HOME, color: "" },
].map((item) => ({
  ...item,
  icon: icons[item.name] || null,
  icon_selected: icons[item.name + "_selected"] || null,
}));

export const getCurrentOnboaringRoute = (flag: string) => {
  switch (flag) {
    case onboardingFlags.INVITE_PARTNER:
      return PAGE_URLS.SIGNUP_PARTNER;

    case onboardingFlags.ADD_ACCOUNTS_P2:
      return PAGE_URLS.SIGNUP_PARTNERS_LINKED_ACCTS;

    case onboardingFlags.ADD_ACCOUNTS_P1:
      return PAGE_URLS.SIGNUP_LINK_ACCTS;

    case onboardingFlags.SHARE_SETTINGS:
      return PAGE_URLS.SIGNUP_SHARE_SETTINGS;

    case onboardingFlags.SET_SUBSCRIPTION:
      return PAGE_URLS.SIGNUP_START_TRIAL;

    case onboardingFlags.FINISHED_ONBOARDING:
      return null;

    case onboardingFlags.NEW:
    default:
      return PAGE_URLS.WELCOME;
  }
};
