import { Dispatch } from "redux";
import * as types from "../actionTypes";
import { get, post } from "../../utils/apiOps";
import {
  RulesRes,
  RulesResponse,
  TransactionRuleTypes,
  ViewRulesItemType,
  ViewRulesType,
} from "../../types/transactionRules";
import { RootState } from "../reducers";
import { setLoading } from "./ui";
import { Transaction } from "../../types";
import {
  generateUpdateInfo,
  RULES_MATCH_TYPES,
  translateViewToMatchCriteria,
} from "../../utils/transactionRules";
import cloneDeep from "lodash/cloneDeep";
import {
  TransactionUpdateData,
  TransactionUpdateDataFields,
  updateDataForArrayTransactions,
} from "./transactions";

export const setTransactionRules = (billsDataObj: RulesResponse[]) => {
  return {
    type: types.SET_TRANSACTION_RULES,
    payload: billsDataObj,
  };
};

export const setTransactionRulesLoading = (status: boolean = true) => {
  return {
    type: types.TRANSACTION_RULES_LOADING,
    payload: status,
  };
};

export const getTransactionRules = () => async (dispatch: Dispatch<any>, getState: any) => {
  dispatch(setTransactionRulesLoading(true));
  get(true, {
    endpoint: "/rules/getAllOrOne",
    params: {
      includeDeleted: true,
    },
  })
    .then(({ data }: RulesRes) => {
      dispatch(setTransactionRules(data));
    })
    .catch()
    .finally(() => {
      dispatch(setTransactionRulesLoading(false));
    });
};

export const createTransactionRule =
  (
    selectedTransaction: Transaction | undefined,
    transactionRuleType: TransactionRuleTypes,
    isAccountOnly: boolean,
    viewRulesArray: ViewRulesType[]
  ) =>
  async (dispatch: Dispatch<any>, getState: () => RootState) => {
    if (!selectedTransaction) return;
    dispatch(setLoading(true));

    const {
      users: { userId, hhIdDefault },
      householdData: { members },
    } = getState();

    const isCustomRule =
      viewRulesArray.find((r) => r.viewType === ViewRulesItemType.NAME)?.matchType !==
      RULES_MATCH_TYPES.NAME.SIMILAR_TO;

    post(true, {
      endpoint: "/rules/add",
      bodyData: {
        userId,
        hhId: hhIdDefault,
        accountId: isAccountOnly ? selectedTransaction.accountId : null,
        type: transactionRuleType,
        updateExistingTransactions: true, //hardcoding for now
        isCustomRule,
        ruleDetails: {
          matchingCriteria: translateViewToMatchCriteria(viewRulesArray, selectedTransaction),
          updateInfo: generateUpdateInfo(
            transactionRuleType,
            selectedTransaction.hhInfo[0],
            userId,
            members
          ),
        },
      },
    })
      .then(({ data }) => {
        const newRules = cloneDeep(selectedTransaction.rules);
        newRules.push(data.ruleId);
        const updateDataArray: TransactionUpdateData[] = [
          {
            field: TransactionUpdateDataFields.RULES,
            value: newRules,
          },
        ];
        const hhInfo = selectedTransaction?.hhInfo[0];
        if (transactionRuleType === TransactionRuleTypes.CATEGORY) {
          updateDataArray.push(
            {
              field: TransactionUpdateDataFields.CATEGORY,
              value: hhInfo.beefCategoryName,
            },
            {
              field: TransactionUpdateDataFields.SUBCATEGORY,
              value: hhInfo.honeyfiCategoryName,
            }
          );
        }
        if (transactionRuleType === TransactionRuleTypes.YOM) {
          updateDataArray.push(
            {
              field: TransactionUpdateDataFields.IS_PRIVATE,
              value: hhInfo?.isPrivate,
            },
            {
              field: TransactionUpdateDataFields.SHARED_EXPENSE,
              value: hhInfo?.sharedExpense,
            },
            {
              field: TransactionUpdateDataFields.SHARED_EXPENSE_INDIVIDUAL,
              value: hhInfo?.sharedExpenseIndividual,
            }
          );
        }
        dispatch(
          updateDataForArrayTransactions({
            transactionIdsArray: data.transactions,
            updateDataArray,
          })
        );
        dispatch(getTransactionRules());
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
