import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonProgressBar,
  IonRow,
} from "@ionic/react";
import dayjs from "dayjs";
import numeral from "numeral";
import { getBudgetAnalyticsState, getCategoryMap } from "../../../redux/selectors";
import { BudgetSubcategoryObj } from "../../../types/budgetAnalytics";
import { getTopSpendingSubCategories } from "../../../utils/homeTab";
import { CategoryImg } from "../../shared/Miscellaneous/CategoryImg";
import { Category } from "../../../types";
import "./TopSpendingSubCategoriesCard.scss";
import { getTransactionLink } from '../../../utils/transUtils';

export const TopSpendingSubCategoriesCard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { budgetsArray } = useSelector(getBudgetAnalyticsState);
  const categoryMap = useSelector(getCategoryMap);

  const subCategories = getTopSpendingSubCategories(budgetsArray[0]).slice(0, 3);
  const max = subCategories[0]?.amountActual ?? 0;

  return subCategories.length ? (
    <IonCard className="top-spending-sub-categories-card">
      <IonCardHeader>
        <IonCardTitle className="ion-text-center">{t("homeCards_topSpending")}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className="ion-no-padding">
        <IonList>
          {subCategories.map((sub: BudgetSubcategoryObj) => {
            const { design, categoryFullName } = categoryMap[sub.category] || {};
            return (
              <IonItem
                lines="none"
                key={sub._id}
                routerLink={getTransactionLink("advancedSearch", {
                  subCategoryFilter: sub.subCategory,
                  budgetMonth: dayjs().startOf("month").format("YYYY-MM-DD"),
                  isPrivate: false,
                })}
                button
                detail={false}
              >
                <div className="category-image-container">
                  <CategoryImg category={{ design, categoryFullName } as Category} />
                </div>
                <IonGrid>
                  <IonCol>
                    <IonRow className="name">{sub.displayName}</IonRow>
                    <IonRow className="amount">{numeral(sub.amountActual).format("$0,0")}</IonRow>
                    <IonRow>
                      <IonProgressBar
                        className="dynamic"
                        style={{ "--progress-background": design?.bgColor }}
                        value={sub.amountActual / max}
                      />
                    </IonRow>
                  </IonCol>
                </IonGrid>
              </IonItem>
            );
          })}
        </IonList>
      </IonCardContent>
    </IonCard>
  ) : null;
};
