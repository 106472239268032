import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IonText,
  IonLabel,
  IonItem,
  IonCheckbox,
  IonTextarea,
} from "@ionic/react";
import { PAGE_URLS } from "../../../../constants";
import { chevronDown, chevronForward } from "ionicons/icons";
import { useDispatch, useSelector } from "../../../../redux/hooks";
import { getUsersState } from "../../../../redux/selectors";
import { editUserFinancialProfile } from "../../../../redux/actions";
import { PageTemplate, Section, NavHeader } from '../../../shared/PageTemplate';
const slideOpts = {
  initialSlide: 1,
  speed: 400,
};

export const IndivGoals: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector(getUsersState);
  const financialProfile = userData.financialProfile || {};
  const goals = financialProfile.goals || {};
  const [isOpen, setIsOpen] = useState(false);
  const handleUpdateProfile = (slice: object) => {
    dispatch(
      editUserFinancialProfile({
        financialProfile: {
          ...financialProfile,
          goals: {
            ...goals,
            ...slice,
          },
        },
      })
    );
  };
  return (
    <PageTemplate
      header={<NavHeader title={t("profile")} defaultHref={PAGE_URLS.MORE_PROFILE_INDIV_FINANCES} />}
    >
      <Section>
        <IonText className="finances-header">
          <h2>{t("profileGoals")}</h2>
          <p>{t("profileGoalsDef")}</p>
        </IonText>
        {Object.keys(goals).map((goal) => {
          if (goal === "other") {
            return (
              <div key={goal}>
                <IonItem
                  lines="none"
                  detail
                  detailIcon={!isOpen ? chevronForward : chevronDown}
                  className="other-field"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <IonLabel>{t(`other`)}</IonLabel>
                </IonItem>
                <IonTextarea
                  rows={4}
                  cols={2}
                  className={isOpen ? "other-goals-text-after" : "other-goals-text"}
                  placeholder={t("profileGoalsPlaceholder")}
                  value={goals[goal]}
                  onIonChange={(e) => handleUpdateProfile({ [goal]: e.detail.value! })}
                ></IonTextarea>
              </div>
            );
          }
          return (
            <IonItem lines="none" key={goal}>
              <IonLabel>{t(`profileGoals_${goal}`)}</IonLabel>
              <IonCheckbox
                checked={goals[goal]}
                onIonChange={(e) => handleUpdateProfile({ [goal]: e.detail.checked! })}
              />
            </IonItem>
          );
        })}
      </Section>
    </PageTemplate>
  );
};
