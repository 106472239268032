import constants from "../../constants";
import { HouseholdProfile, MemberRelationshipTypes } from "../../types";
import {
  SET_HOUSEHOLD_MEMBER_DATA,
  HOUSEHOLD_DATA_LOADING,
  CLEAR_HOUSEHOLD_DATA,
  SET_HOUSEHOLD_PROFILE_DATA,
} from "../actionTypes";
import { corePro, HouseholdMemberInvite } from "./users";

const initialState: householdDataState = {
  loading: false,
  members: [],
  hhProfiles: [],
};

interface householdDataState {
  loading: boolean;
  members: Member[];
  primaryPartner?: Member;
  hhProfiles: Array<HouseholdProfile>;
}

export type Member = {
  firstName: string;
  lastName: string;
  emailId: string;
  mobile: string;
  imageURL: string;
  corePro: corePro;
  financialProfile: any;
  emailValidated: boolean;
  _id: string;
  relationshipType: MemberRelationshipTypes;
  singlePlayerMode?: boolean;
  invites?: HouseholdMemberInvite[];
  gender?: string;
};

interface action {
  type: string;
  payload: any;
}

const householdData = (state: householdDataState = initialState, action: action) => {
  switch (action.type) {
    case SET_HOUSEHOLD_PROFILE_DATA: {
      return {
        ...state,
        hhProfiles: action.payload as Array<HouseholdProfile>,
        loading: false,
      };
    }
    case SET_HOUSEHOLD_MEMBER_DATA: {
      return {
        ...state,
        members: action.payload as Member[],
        primaryPartner: action.payload.find(
          (m: Member) => m.relationshipType === MemberRelationshipTypes.PARTNER
        ) as Member,
        loading: false,
      };
    }
    case HOUSEHOLD_DATA_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case CLEAR_HOUSEHOLD_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default householdData;
