import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonInput,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
} from "@ionic/react";
import dayjs from "dayjs";
import { calendarOutline, closeOutline, createOutline } from "ionicons/icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getAccountsState, getCategoryState, getTransactionState } from "../../../redux/selectors";
import * as type from "../../../types/transactions";
import { getAllSubCategory, getRelatedSubCategory } from "../../../utils/transactions";
import { useTranslation } from "react-i18next";
import { Account, AccountSource, accountState } from "../../../redux/reducers/accounts";
import { PAGE_URLS, PRIVILEGE_TYPE_DATA_2 } from "../../../constants";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import isEqual from "lodash/isEqual";
import { useUserName } from "../../shared/User/useUserName";
import { useResponsiveInterface } from "../../../hooks/useResponsiveInterface";
import { categoryState } from "../../../redux/reducers/category";
import { DatePicker } from "../../shared/DatePicker/DatePicker";
import { useViewport } from "../../../hooks/useViewport";
import { Field } from "../../shared/Field/Field";
import { getTransactionLink } from "../../../utils/transUtils";

interface ContainerProps {
  advancedSearchIsOpen: boolean;
  handleAdvancedSearchToggleClose: () => void;
  handleAdvancedSearchActive: () => void;
}

const nonSearchParams = {
  merchName: undefined,
  nameHash: undefined,
  budgetMonth: undefined,
  billIds: undefined,
}

export const TransactionAdvancedSearch: React.FC<ContainerProps> = (props) => {
  const { advancedSearchIsOpen, handleAdvancedSearchToggleClose } = props;
  const { isMobile } = useViewport();
  const history = useHistory();
  const { t } = useTranslation();
  const _interface = useResponsiveInterface();

  //redux state
  const { categoryData, subCategoryData }: categoryState = useSelector(getCategoryState);
  const { myAccounts, otherAccountCollection }: accountState = useSelector(getAccountsState);
  const { partnerName, singlePlayerMode } = useUserName();
  const { searchParams } = useSelector(getTransactionState);

  //state
  const [advancedSearchParams, setadvancedSearchParams] = useState(searchParams);

  const defaultSearchParams: type.SearchParam = {
    ...searchParams,
    startDate: searchParams.startDate || dayjs().subtract(90, "days").format("MM/DD/YYYY"),
    endDate: searchParams.endDate || dayjs().format("MM/DD/YYYY"),
  };

  //The reason why add this useEffect is because Ionic Iononchange will triggers automatically. So when click reset button every field will triggler onIonChange event so fileds will not clear as what expected.
  useEffect(() => {
    setadvancedSearchParams(defaultSearchParams);
  }, [searchParams, advancedSearchIsOpen]);

  const checkSliceValue = (slice: Partial<type.SearchParam>) => {
    return !!slice.keyword ||
      !!slice.transactionFilter ||
      !!slice.categoryFilter ||
      !!slice.subCategoryFilter ||
      !!slice.accountIds ||
      !!slice.baseType ||
      slice.startDate
      ? slice.startDate !== dayjs().add(-90, "days").format("MM/DD/YYYY")
      : false || slice.endDate
      ? slice.endDate !== dayjs().format("MM/DD/YYYY")
      : false;
  };

  const handleParamsChange = (slice: Partial<type.SearchParam>) => {
    if (checkSliceValue(slice) || isEqual(advancedSearchParams, defaultSearchParams)) {
      setadvancedSearchParams({ ...advancedSearchParams, ...slice, ...nonSearchParams, pageNumber: 1 });
    }
  };

  const handleResetBtnClick = () => {
    setadvancedSearchParams(defaultSearchParams);
    history.replace(PAGE_URLS.TRANS_HOME)
  };

  const handleAdvancedSearchBtnClick = () => {
    history.replace(getTransactionLink("advancedSearch", advancedSearchParams));
    handleAdvancedSearchToggleClose();
  };

  return (
    <IonModal isOpen={advancedSearchIsOpen} onDidDismiss={handleAdvancedSearchToggleClose}>
      <ModalHeader
        title={t("filter")}
        startBtnIcon={closeOutline}
        startBtnOnClick={handleAdvancedSearchToggleClose}
      />
      <IonContent fullscreen>
        <IonList>
          <Field label={t("keywords")} icon={createOutline}>
            <IonInput
              placeholder={`(${t("none")})`}
              className="transaction-search-input-field"
              onIonChange={(e) => handleParamsChange({ keyword: e.detail.value || "" })}
              value={advancedSearchParams.keyword}
              data-testid="advancedSearchKeyword"
            />
          </Field>

          <Field label={t("dateRangeStart")} icon={isMobile ? calendarOutline : undefined}>
            <DatePicker
              displayFormat="M/D/YY"
              value={advancedSearchParams.startDate}
              onChange={(date) =>
                handleParamsChange({
                  startDate: dayjs(date || "").format("MM/DD/YYYY"),
                })
              }
              data-testid="advancedSearchStartDate"
            />
          </Field>
          <Field label={t("dateRangeEnd")} icon={isMobile ? calendarOutline : undefined}>
            <DatePicker
              displayFormat="M/D/YY"
              value={advancedSearchParams.endDate}
              onChange={(date) =>
                handleParamsChange({
                  endDate: dayjs(date || "").format("MM/DD/YYYY"),
                })
              }
              data-testid="advancedSearchDateTo"
            />
          </Field>

          {!singlePlayerMode && (
            <Field label={t("sharedStatus")}>
              <IonSelect
                value={advancedSearchParams.transactionFilter}
                placeholder="All"
                onIonChange={(e) => handleParamsChange({
                  transactionFilter: e.detail.value || "",
                  isPrivate: e.detail.value === "Private" || "both",
                })}
                interface={_interface}
                data-testid="advancedSearchSharedStatus"
              >
                <IonSelectOption value="">{t("all")}</IonSelectOption>
                <IonSelectOption value="Just shared">{t("justHousehold")}</IonSelectOption>
                <IonSelectOption value="Just mine">{t("justMine")}</IonSelectOption>
                <IonSelectOption value="Just theirs">
                  {t("just")} {partnerName}'s
                </IonSelectOption>
                <IonSelectOption value="Private">{t("private")}</IonSelectOption>
              </IonSelect>
            </Field>
          )}

          <Field label={t("category")}>
            <IonSelect
              value={advancedSearchParams.categoryFilter}
              onIonChange={(e) => handleParamsChange({ categoryFilter: e.detail.value || "" })}
              interface={_interface}
              data-testid="advancedSearchCategory"
            >
              <IonSelectOption value="">{t("all")}</IonSelectOption>
              {categoryData
                .filter((category) => !category.isDeleted)
                .map((category) => {
                  return (
                    <IonSelectOption
                      value={category.categoryShortName}
                      key={category.categoryShortName}
                    >
                      {category.categoryFullName}
                    </IonSelectOption>
                  );
                })}
            </IonSelect>
          </Field>

          <Field label={t("subCategory")}>
            <IonSelect
              value={advancedSearchParams.subCategoryFilter}
              name="subCategoryFilter"
              onIonChange={(e) => handleParamsChange({ subCategoryFilter: e.detail.value || "" })}
              interface={_interface}
              data-testid="advancedSearchSubCategory"
            >
              <IonSelectOption value="">{t("all")}</IonSelectOption>
              {!advancedSearchParams.categoryFilter
                ? getAllSubCategory(subCategoryData).map((subCategory, index) => {
                    return (
                      <IonSelectOption value={subCategory.subCategory} key={index}>
                        {subCategory.displayName}
                      </IonSelectOption>
                    );
                  })
                : getRelatedSubCategory(
                    subCategoryData,
                    advancedSearchParams.categoryFilter,
                    true
                  ).map((subCategory: any, index: number) => {
                    return (
                      <IonSelectOption value={subCategory.subCategory} key={index}>
                        {subCategory.displayName}
                      </IonSelectOption>
                    );
                  })}
            </IonSelect>
          </Field>

          <Field label={t("account")}>
            <IonSelect
              value={JSON.parse(advancedSearchParams.accountIds || `[""]`)[0]}
              placeholder="All"
              name="accountIds"
              onIonChange={(e) =>
                handleParamsChange({ accountIds: JSON.stringify([e.detail.value || ""]) })
              }
              interface={_interface}
              data-testid="advancedSearchAccount"
            >
              <IonSelectOption value="">{t("all")}</IonSelectOption>

              {myAccounts &&
                myAccounts
                  .concat(otherAccountCollection || [])
                  .reduce((all, bank) => all.concat(...bank.subBanks), [] as Account[])
                  .filter(
                    (account) =>
                      account.hhInfo[0].privilegeType !== PRIVILEGE_TYPE_DATA_2.IGNORE &&
                      account.source !== AccountSource.COREPRO
                  )
                  .map((account) => {
                    return (
                      <IonSelectOption value={account._id} key={account._id}>
                        {account.accountNickname || account.accountName}
                      </IonSelectOption>
                    );
                  })}
            </IonSelect>
          </Field>

          <Field label={t("type")}>
            <IonSelect
              value={advancedSearchParams.baseType}
              placeholder="All"
              name="baseType"
              onIonChange={(e) => handleParamsChange({ baseType: e.detail.value || "" })}
              interface={_interface}
              data-testid="advancedSearchType"
            >
              <IonSelectOption value="">{t("all")}</IonSelectOption>
              <IonSelectOption value="CREDIT">{t("credit")}</IonSelectOption>
              <IonSelectOption value="DEBIT">{t("debit")}</IonSelectOption>
            </IonSelect>
          </Field>
        </IonList>
      </IonContent>
      <IonFooter className="ion-no-border ion-padding-vertical ion-text-center">
        {!isEqual(advancedSearchParams, defaultSearchParams) && (
          <IonButton
            onClick={handleResetBtnClick}
            data-testid="advancedSearchReset"
            fill="clear"
            size="large"
          >
            {t("reset")}
          </IonButton>
        )}
        <IonButton
          disabled={isEqual(advancedSearchParams, defaultSearchParams)}
          size="large"
          onClick={handleAdvancedSearchBtnClick}
          data-testid="advancedSearchBtn"
        >
          {t("search")}
        </IonButton>
      </IonFooter>
    </IonModal>
  );
};
