import { Member } from "../redux/reducers/householdData";
import { Transaction, TransactionHHInfo } from "../types";
import {
  RuleMatchingCriteria,
  RuleMatchingCriteriaField,
  RuleMatchingCriteriaMatchTypes,
  RuleMatchTypes,
  RULES_MATCH_TYPE,
  RuleUpdateInfo,
  RuleUpdateInfoField,
  RuleUpdateInfoPrefix,
  RuleUpdateInfoTypes,
  TransactionRuleTypes,
  ViewRulesType,
  ViewRulesItemType,
} from "../types/transactionRules";

export const RULES_MATCH_TYPES: RULES_MATCH_TYPE = {
  NAME: {
    SIMILAR_TO: RuleMatchTypes.SIMILAR_TO,
    CONTAINS: RuleMatchTypes.CONTAINS,
    BEGINS_WITH: RuleMatchTypes.BEGINS_WITH,
    ENDS_WITH: RuleMatchTypes.ENDS_WITH,
    IS_EXACTLY: RuleMatchTypes.IS_EXACTLY,
  },
  AMOUNT: {
    EQUAL: RuleMatchTypes.IS_EQUAL,
    GREATER_THAN: RuleMatchTypes.IS_GREATER,
    GREATER_THAN_EQUAL: RuleMatchTypes.IS_GREATER_EQUAL,
    LESS_THAN: RuleMatchTypes.IS_LESS,
    LESS_THAN_EQUAL: RuleMatchTypes.IS_LESS_EQUAL,
  },
  BASE_TYPE: {
    CREDIT: RuleMatchTypes.IS_CREDIT,
    DEBIT: RuleMatchTypes.IS_DEBIT,
  },
};

export const matchTypeArrays = {
  name: [
    RULES_MATCH_TYPES.NAME.SIMILAR_TO,
    RULES_MATCH_TYPES.NAME.CONTAINS,
    RULES_MATCH_TYPES.NAME.BEGINS_WITH,
    RULES_MATCH_TYPES.NAME.ENDS_WITH,
    RULES_MATCH_TYPES.NAME.IS_EXACTLY,
  ],
  amount: [
    RULES_MATCH_TYPES.AMOUNT.EQUAL,
    RULES_MATCH_TYPES.AMOUNT.GREATER_THAN,
    RULES_MATCH_TYPES.AMOUNT.GREATER_THAN_EQUAL,
    RULES_MATCH_TYPES.AMOUNT.LESS_THAN,
    RULES_MATCH_TYPES.AMOUNT.LESS_THAN_EQUAL,
  ],
  baseType: [RULES_MATCH_TYPES.BASE_TYPE.CREDIT, RULES_MATCH_TYPES.BASE_TYPE.DEBIT],
};

export const generateUpdateInfo = (
  transactionRuleType: TransactionRuleTypes,
  hhInfo: TransactionHHInfo,
  userId: string,
  members: Member[]
) => {
  const updateInfoArr: RuleUpdateInfo[] = [];
  if (transactionRuleType === TransactionRuleTypes.CATEGORY) {
    updateInfoArr.push(
      {
        field: RuleUpdateInfoField.CATEGORY,
        prefix: RuleUpdateInfoPrefix.HH_INFO,
        type: RuleUpdateInfoTypes.STRING,
        value: hhInfo.beefCategoryName,
      },
      {
        field: RuleUpdateInfoField.SUBCATEGORY,
        prefix: RuleUpdateInfoPrefix.HH_INFO,
        type: RuleUpdateInfoTypes.STRING,
        value: hhInfo.honeyfiCategoryName,
      }
    );
  } else if (transactionRuleType === TransactionRuleTypes.YOM) {
    updateInfoArr.push(
      {
        field: RuleUpdateInfoField.SHARED_EXPENSE,
        prefix: RuleUpdateInfoPrefix.HH_INFO,
        type: RuleUpdateInfoTypes.STRING,
        value: hhInfo.sharedExpense,
      },
      {
        field: RuleUpdateInfoField.IS_PRIVATE,
        prefix: RuleUpdateInfoPrefix.HH_INFO,
        type: RuleUpdateInfoTypes.BOOLEAN,
        value: hhInfo.isPrivate,
      },
      {
        field: RuleUpdateInfoField.TRANSACTION_VISIBILITY,
        prefix: RuleUpdateInfoPrefix.HH_INFO,
        type: RuleUpdateInfoTypes.ARRAY_OF_MONGO_IDS,
        value: hhInfo.transactionVisibility,
      },
      {
        field: RuleUpdateInfoField.SHARED_EXPENSE_INDIVIDUAL,
        prefix: RuleUpdateInfoPrefix.HH_INFO,
        type: RuleUpdateInfoTypes.ARRAY_OF_MONGO_IDS,
        value: hhInfo.sharedExpenseIndividual,
      }
    );
  }
  return updateInfoArr;
};

interface MatchingCriteria {
  field: RuleMatchingCriteriaField;
  value: string;
  ruleMatchType: RuleMatchTypes;
}

export const generateMatchingCriteria = ({ field, value, ruleMatchType }: MatchingCriteria) => {
  const matchCriteriaObj: RuleMatchingCriteria = {
    field,
    matchType: RuleMatchingCriteriaMatchTypes.EQUAL,
    value: field !== RuleMatchingCriteriaField.AMOUNT ? value + "" : value,
    type: field === RuleMatchingCriteriaField.AMOUNT ? "number" : "string",
    prefix: "",
    matchTypeDetails: {
      caseSensitive: false,
      start: 0,
      exactPosition: false,
      wholeWord: false,
    },
  };

  if (
    field === RuleMatchingCriteriaField.CATEGORY ||
    field === RuleMatchingCriteriaField.SUBCATEGORY
  ) {
    matchCriteriaObj.prefix = RuleUpdateInfoPrefix.HH_INFO;
    if (matchCriteriaObj.matchTypeDetails) {
      matchCriteriaObj.matchTypeDetails.exactPosition = true;
      matchCriteriaObj.matchTypeDetails.wholeWord = true;
    }
  } else {
    switch (ruleMatchType) {
      case RULES_MATCH_TYPES.NAME.IS_EXACTLY:
      case RULES_MATCH_TYPES.AMOUNT.EQUAL:
        matchCriteriaObj.matchType = RuleMatchingCriteriaMatchTypes.EQUAL;
        if (matchCriteriaObj.matchTypeDetails)
          matchCriteriaObj.matchTypeDetails.exactPosition = matchCriteriaObj.type === "string";
        break;
      case RULES_MATCH_TYPES.AMOUNT.GREATER_THAN:
        matchCriteriaObj.matchType = RuleMatchingCriteriaMatchTypes.GREATER_THAN;
        break;
      case RULES_MATCH_TYPES.AMOUNT.GREATER_THAN_EQUAL:
        matchCriteriaObj.matchType = RuleMatchingCriteriaMatchTypes.GREATER_THAN_EQUAL;
        break;
      case RULES_MATCH_TYPES.AMOUNT.LESS_THAN_EQUAL:
        matchCriteriaObj.matchType = RuleMatchingCriteriaMatchTypes.LESS_THAN_EQUAL;
        break;
      case RULES_MATCH_TYPES.AMOUNT.LESS_THAN:
        matchCriteriaObj.matchType = RuleMatchingCriteriaMatchTypes.LESS_THAN;
        break;
      case RULES_MATCH_TYPES.NAME.BEGINS_WITH:
        matchCriteriaObj.matchType = RuleMatchingCriteriaMatchTypes.REGEX;
        if (matchCriteriaObj.matchTypeDetails)
          matchCriteriaObj.matchTypeDetails.exactPosition = true;
        break;
      case RULES_MATCH_TYPES.NAME.ENDS_WITH:
        matchCriteriaObj.matchType = RuleMatchingCriteriaMatchTypes.REGEX;
        if (matchCriteriaObj.matchTypeDetails) {
          matchCriteriaObj.matchTypeDetails.exactPosition = true;
          matchCriteriaObj.matchTypeDetails.start = -1;
        }
        break;
      case RULES_MATCH_TYPES.NAME.CONTAINS:
        matchCriteriaObj.matchType = RuleMatchingCriteriaMatchTypes.REGEX;
        break;
      case RULES_MATCH_TYPES.BASE_TYPE.CREDIT:
        matchCriteriaObj.matchType = RuleMatchingCriteriaMatchTypes.EQUAL;
        matchCriteriaObj.value = "CREDIT";
        matchCriteriaObj.type = "string";
        break;
      case RULES_MATCH_TYPES.BASE_TYPE.DEBIT:
        matchCriteriaObj.matchType = RuleMatchingCriteriaMatchTypes.EQUAL;
        matchCriteriaObj.value = "DEBIT";
        matchCriteriaObj.type = "string";
        break;
      default:
        matchCriteriaObj.matchType = RuleMatchingCriteriaMatchTypes.EQUAL;
    } //end switch
  }
  return matchCriteriaObj;
};

/**
 * Translates the viewRulesArray into API-ready matchCriteriaArray
 * @param viewRulesArray
 * @param transaction
 * @returns RuleMatchingCriteria[]
 */
export const translateViewToMatchCriteria = (
  viewRulesArray: ViewRulesType[],
  { description }: Transaction
) => {
  const matchCriteriaArray: RuleMatchingCriteria[] = [];

  viewRulesArray.map((viewRule) => {
    switch (viewRule.viewType) {
      case ViewRulesItemType.NAME: {
        matchCriteriaArray.push(
          generateMatchingCriteria({
            field: description.simple
              ? RuleMatchingCriteriaField.DESCRIPTION_SIMPLE
              : RuleMatchingCriteriaField.DESCRIPTION_ORIGINAL,
            ruleMatchType: viewRule.matchType,
            value: viewRule.inputValue,
          })
        );
        break;
      }
      case ViewRulesItemType.CATEGORY: {
        viewRule.matchType.split(":").map((catSubcat: string, i: number) => {
          matchCriteriaArray.push(
            generateMatchingCriteria({
              field:
                i === 0
                  ? RuleMatchingCriteriaField.CATEGORY
                  : RuleMatchingCriteriaField.SUBCATEGORY,
              ruleMatchType: RULES_MATCH_TYPES.AMOUNT.EQUAL,
              value: catSubcat.trim(),
            })
          );
        });
        break;
      }
      case ViewRulesItemType.AMOUNT: {
        matchCriteriaArray.push(
          generateMatchingCriteria({
            field: RuleMatchingCriteriaField.AMOUNT,
            ruleMatchType: viewRule.matchType,
            value: viewRule.inputValue,
          })
        );
        break;
      }
      case ViewRulesItemType.BASE_TYPE: {
        matchCriteriaArray.push(
          generateMatchingCriteria({
            field: RuleMatchingCriteriaField.BASE_TYPE,
            ruleMatchType: viewRule.matchType,
            value: viewRule.inputValue,
          })
        );
        break;
      }
      default: {
        return;
      }
    }
  });
  return matchCriteriaArray;
};
