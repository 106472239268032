import React from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonTitle,
  IonLabel,
} from "@ionic/react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import { SpendingAnalysis } from "./SpendingAnalysis";
import { PAGE_URLS } from "../../../constants";
import { useSpendingAnalysisData } from "./useSpendingAnalysisData";

export const SpendingAnalysisCard: React.FC = (props) => {
  const { t } = useTranslation();
  const { index, ...data } = useSpendingAnalysisData();
  return (
    <IonCard
      className="spending-analysis-card ion-text-center"
      routerLink={`${PAGE_URLS.SPENDING_ANALYSIS}?i=${index}`}
    >
      <IonCardHeader>
        <IonCardTitle>{t("monthSpending", { month: dayjs().format("MMMM") })}</IonCardTitle>
        <IonCardSubtitle color="primary" className="ion-margin-top">
          {t("totalAmount", { amount: numeral(data.totalSpending).format("$0,0") })}
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <SpendingAnalysis {...data} />
      </IonCardContent>
    </IonCard>
  );
};
