import React from "react";
import Banner from "@workday/canvas-kit-react-banner";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../redux/hooks";
import { getVersionCheckState } from "../../../redux/selectors";
import "../NoInternet/NoInternet.scss";
import {
  versionCheckResponse,
  versionCheckState,
  VersionCheckStatus,
} from "../../../redux/reducers/versioncheck";
import { useIonAlert } from "@ionic/react";
import { useHandleBackBtn } from "../Miscellaneous/UseHandleBackBtn";

export const UpgradeAppVersionBanner: React.FC = () => {
  const { t } = useTranslation();
  const { versionCheck }: versionCheckState = useSelector(getVersionCheckState);

  if (versionCheck.status === VersionCheckStatus.SOFT) {
    return (
      <div className="accounts-ui-alert">
        <Banner actionText="" slot="center" label={t("updateAppSoft")} />
      </div>
    );
  }

  return null;
};

export const BlockAppBanner: React.FC<{ vcRes: versionCheckResponse }> = ({ vcRes }) => {
  const { t } = useTranslation();
  const [present] = useIonAlert();
  const { register } = useHandleBackBtn();

  if (vcRes?.maintenance?.active) {
    register();
    present({
      header: t("maintenance"),
      message: vcRes?.maintenance.msg,
      backdropDismiss: false,
    });
    return null;
  }

  if (vcRes?.status === VersionCheckStatus.HARD) {
    register();
    present({
      header: t("updateAppHardTitle"),
      message: t("updateAppHardMsg"),
      backdropDismiss: false,
    });
    return null;
  }

  return null;
};

export default UpgradeAppVersionBanner;
