import React, { useState } from "react";
import { IonButton, IonButtons, IonIcon, IonSearchbar, IonSpinner, IonToolbar } from "@ionic/react";
import { useHistory } from "react-router";
import { useSelector } from "../../../redux/hooks";
import { getTransactionState } from "../../../redux/selectors";
import { useTranslation } from "react-i18next";
import { useViewport } from "../../../hooks/useViewport";
import { NavButton } from "../../shared/PageTemplate";
import { optionsOutline } from "ionicons/icons";
import { TransactionAdvancedSearchResult } from ".";
import "./TransactionSearch.scss";
import { PAGE_URLS } from "../../../constants";
import { getTransactionLink } from "../../../utils/transUtils";

interface ContainerProps {
  handleAdvancedSearchToggleOpen: () => void;
}

export const TransactionSearch: React.FC<ContainerProps> = ({ handleAdvancedSearchToggleOpen }) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useViewport();
  const history = useHistory();
  const [input, setInput] = useState(); // Since the debounced url param is laggy, we need to track the real-time input state

  // redux state
  const { searchResultsMeta, loading, searchParams, context } = useSelector(getTransactionState);

  return (
    <>
      <IonToolbar id="TransactionSearch" className="ion-padding-top">
        <div className="transaction-search-section">
          <IonSearchbar
            placeholder={t("searchLast90Days")}
            value={input ?? searchParams?.keyword}
            debounce={200}
            onIonInput={(e) => setInput((e?.detail?.target as any)?.value ?? "")}
            onIonChange={({ detail }) => (detail.value || "").length > 2 && history.replace(getTransactionLink("search", { keyword: detail.value }))}
            onIonClear={() => history.replace(PAGE_URLS.TRANS_HOME)}
            data-testid="transactionSearch"
          />
          <IonSpinner name="dots" className={`ion-align-self-center ml-auto ${loading ? '' : 'hidden'}`} />

          {isMobile && (
            <IonButton
              fill="clear"
              color="secondary"
              onClick={() => handleAdvancedSearchToggleOpen()}
              data-testid="advancedSearch"
              size="small"
            >
              {t("advanced")}
            </IonButton>
          )}

          {isDesktop && (
            <IonButtons className="fy-nav-buttons">
              <NavButton
                icon={<IonIcon icon={optionsOutline} />}
                label={t("filters")}
                onClick={() => handleAdvancedSearchToggleOpen()}
              />
            </IonButtons>
          )}
        </div>
      </IonToolbar>
      {context === "search" &&
        (searchResultsMeta.credit.count > 0 || searchResultsMeta.debit.count > 0) && (
          <TransactionAdvancedSearchResult resultOnly />
        )}
    </>
  );
};
