import { post, put, get } from "./apiOps";
import {
  Transaction,
  SaveReaction,
  RemoveReaction,
  UpdateSubCategory,
  SaveSplitTransactionType,
  AddComments,
  EditTransactionSharingType,
} from "../types/transactions";
import { Dispatch } from "redux";
import {
  getAllTransactions,
  setLoading,
  TransactionUpdateDataFields,
  updateDataForArrayTransactions,
} from "../redux/actions";
import {
  AVATAR_DISPLAY,
  PAGE_URLS,
  PRIVILEGE_TYPE_DATA_2,
  TRANSACTION_VISIBILITY,
} from "../constants";
import { transNameHelper } from "../utils/transUtils";
import { history } from "../history";
import {
  RuleItem,
  RuleMatchingCriteriaField,
  RuleMatchingCriteriaMatchTypes,
  RuleUpdateInfo,
  RuleUpdateInfoField,
} from "../types/transactionRules";
import { getCatDisplayName, getSubcatDisplayName } from "./categorizationUtils";
import { Category, SubCategory } from "../types";
import numeral from "numeral";

type Reaction = "" | "angry" | "like" | "sad" | "love" | "wow" | "happy";

export const saveReaction = async (
  bodyData: SaveReaction,
  setReactionId: React.Dispatch<React.SetStateAction<string>>
) => {
  try {
    const { data } = await put(true, {
      endpoint: "/transaction/reaction",
      bodyData,
    });
    setReactionId(data.reactions[0]._id);
  } catch (error) {}
};

export const removeReaction = async (
  bodyData: RemoveReaction,
  setReactionId: React.Dispatch<React.SetStateAction<string>>
) => {
  try {
    put(true, {
      endpoint: "/transaction/reactionRemove",
      bodyData,
    });
    setReactionId("");
  } catch (error) {}
};

export const saveComments = async (bodyData: AddComments, dispatch: Dispatch<any>) => {
  try {
    await put(true, {
      endpoint: "/transaction/comment",
      bodyData,
    });
    dispatch(getAllTransactions());
    dispatch(setLoading(false));
  } catch (error) {}
};

export const editTransactionSharing = async (
  bodyData: EditTransactionSharingType,
  dispatch: Dispatch<any>
) => {
  try {
    const { data } = await put(true, {
      endpoint: "/transaction/sharing",
      bodyData,
    });
    dispatch(
      updateDataForArrayTransactions({
        transactionIdsArray: [bodyData.transactionId],
        updateDataArray: [
          {
            field: TransactionUpdateDataFields.IS_PRIVATE,
            value: data.isPrivate,
          },
          {
            field: TransactionUpdateDataFields.SHARED_EXPENSE,
            value: data.sharedExpense,
          },
          {
            field: TransactionUpdateDataFields.SHARED_EXPENSE_INDIVIDUAL,
            value: data.sharedExpenseIndividual,
          },
        ],
      })
    );
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const saveSplitTransaction = async (
  bodyData: SaveSplitTransactionType,
  dispatch: Dispatch<any>
) => {
  try {
    await post(true, {
      endpoint: "/transaction/split",
      bodyData,
    });
    dispatch(getAllTransactions());
  } catch (error) {}
};

export const saveUnsplitTransaction = async (
  bodyData: { origTransactionId: string },
  dispatch: Dispatch<any>
) => {
  try {
    await post(true, {
      endpoint: "/transaction/unsplit",
      bodyData,
    });
    dispatch(getAllTransactions());
  } catch (error) {
    dispatch(getAllTransactions);
    history.push(PAGE_URLS.TRANS_HOME);
  }
};

export const findTransactionDetail = async (params: { transactionId: string }) => {
  try {
    return await get(true, {
      endpoint: "view/transactionDetail",
      params,
    });
  } catch (error) {}
};

export const updateSubCategory = async (bodyData: UpdateSubCategory) => {
  try {
    put(true, {
      endpoint: "transaction/editSubcategory",
      bodyData,
    });
  } catch (error) {}
};

export const findTargetTransaction = async (
  params: { transactionId: string },
  setTargetTransaction: React.Dispatch<React.SetStateAction<Transaction>>,
  setSelectedReaction?: React.Dispatch<React.SetStateAction<Reaction>>,
  setReactionId?: React.Dispatch<any>,
  setNickNameValue?: React.Dispatch<React.SetStateAction<string>>
) => {
  try {
    const res = await get(true, {
      endpoint: "view/transactionDetail",
      params,
    });
    setTargetTransaction(res.data);
    if (setSelectedReaction) setSelectedReaction(res.data.reactions[0]?.reaction);
    if (setReactionId) setReactionId(res.data.reactions[0]?._id);
    if (setNickNameValue) setNickNameValue(transNameHelper(res.data));
  } catch (error) {}
};

export const getRelatedSubCategory = (
  subCategoryData: Array<SubCategory>,
  category: string,
  includeDeactivated: boolean = false
) => {
  return subCategoryData
    .filter(
      (subCategory) =>
        !subCategory.isDeleted &&
        (includeDeactivated || !subCategory.isDeactivated) &&
        subCategory.category === category
    )
    .sort((a, b) =>
      a.displayName !== b.displayName ? (a.displayName < b.displayName ? -1 : 1) : 0
    );
};

export const getAllSubCategory = (subCategoryData: Array<any>) => {
  return subCategoryData
    .filter((subCategory: any) => !subCategory.isDeleted)
    .sort((a: any, b: any) =>
      a.displayName !== b.displayName ? (a.displayName < b.displayName ? -1 : 1) : 0
    );
};

export const getRulesText = (
  rule: RuleItem,
  categories: Category[],
  subcategories: SubCategory[],
  t: (arg0: string) => any
) => {
  console.debug("getrulestext");
  let ruleText = "";
  rule.matchingCriteria.map((mc, matchIndex) => {
    if (mc) {
      if (matchIndex > 0 && mc.field !== RuleMatchingCriteriaField.DESCRIPTION_NAME_HASH) {
        ruleText += t("and");
      }
      if (mc.field === RuleMatchingCriteriaField.DESCRIPTION_NAME_HASH) {
        ruleText +=
          matchIndex === 0 ? `${t("nameThat")} ${t("similarTo")} "${rule.merchantName}` : '"';
      } else if (
        mc.field === RuleMatchingCriteriaField.DESCRIPTION_ORIGINAL ||
        mc.field === RuleMatchingCriteriaField.DESCRIPTION_SIMPLE
      ) {
        ruleText += t("nameThat");
        if (mc.matchType === RuleMatchingCriteriaMatchTypes.REGEX) {
          if (mc.matchTypeDetails?.start === 0 && !mc.matchTypeDetails.exactPosition) {
            ruleText += t("contains") + '"' + rule.merchantName + '"';
          } else if (mc.matchTypeDetails?.start === -1 && mc.matchTypeDetails.exactPosition) {
            ruleText += t("endsWith") + '"' + rule.merchantName + '"';
          } else if (mc.matchTypeDetails?.start === 0 && mc.matchTypeDetails.exactPosition) {
            ruleText += t("beginsWith") + '"' + rule.merchantName + '"';
          }
        } else if (mc.matchType === RuleMatchingCriteriaMatchTypes.EQUAL) {
          ruleText += t("isExactly") + '"' + rule.merchantName + '"';
        }
      } else if (mc.field === RuleMatchingCriteriaField.BASE_TYPE) {
        if (mc.value == "DEBIT") {
          ruleText += t("isADebit");
        } else if (mc.value == "CREDIT") {
          ruleText += t("isACredit");
        }
      } else if (mc.field === RuleMatchingCriteriaField.AMOUNT) {
        if (mc.matchType == RuleMatchingCriteriaMatchTypes.LESS_THAN) {
          ruleText += t("isLessThan") + numeral(mc.value).format("$0,0");
        } else if (mc.matchType == RuleMatchingCriteriaMatchTypes.LESS_THAN_EQUAL) {
          ruleText += t("isLessThan") + t("orEqualTo") + numeral(mc.value).format("$0,0");
        } else if (mc.matchType == RuleMatchingCriteriaMatchTypes.EQUAL) {
          ruleText += t("isEqualTo") + numeral(mc.value).format("$0,0");
        } else if (mc.matchType == RuleMatchingCriteriaMatchTypes.GREATER_THAN) {
          ruleText += t("isGreaterThan") + numeral(mc.value).format("$0,0");
        } else if (mc.matchType == RuleMatchingCriteriaMatchTypes.GREATER_THAN_EQUAL) {
          ruleText += t("isGreaterThan") + t("orEqualTo") + numeral(mc.value).format("$0,0");
        }
      } else if (mc.field === RuleMatchingCriteriaField.CATEGORY) {
        // @ts-ignore
        ruleText += t("isCategorizedAs") + getCatDisplayName(mc.value, categories);
      } else if (mc.field === "honeyfiCategoryName") {
        // @ts-ignore
        ruleText += t("isSubcategorizedAs") + getSubcatDisplayName(mc.value, subcategories);
      }
    }
  });
  return ruleText;
};

function findWithAttr(array: any[], attr: string, value: string) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

export const getRuleSharedStatus = (updateInfo: RuleUpdateInfo[], userId: string) => {
  let isOwner = false;
  let privType: any = 0;
  let avatarDisplay: number = AVATAR_DISPLAY.PRIVATE;
  try {
    const pInd = findWithAttr(updateInfo, "field", RuleUpdateInfoField.IS_PRIVATE);
    if (updateInfo[pInd].value) {
      privType = PRIVILEGE_TYPE_DATA_2.JUSTME;
      avatarDisplay = AVATAR_DISPLAY.ME;
      isOwner = true;
    } else {
      const sInd = findWithAttr(updateInfo, "field", RuleUpdateInfoField.SHARED_EXPENSE);
      const oInd = findWithAttr(updateInfo, "field", RuleUpdateInfoField.SHARED_EXPENSE_INDIVIDUAL);
      if (
        updateInfo[sInd].value === TRANSACTION_VISIBILITY.SHARED &&
        // @ts-ignore
        updateInfo[oInd].value.length > 1
      ) {
        privType = PRIVILEGE_TYPE_DATA_2.SHARED;
        isOwner = true;
        avatarDisplay = AVATAR_DISPLAY.SHARED;
      } else {
        privType = PRIVILEGE_TYPE_DATA_2.VISIBLE;
        avatarDisplay = AVATAR_DISPLAY.PARTNER;
        // @ts-ignore
        updateInfo[oInd].value.map((u) => {
          if (u.toString() === userId.toString()) {
            isOwner = true;
          }
        });
      }
    }
  } catch (e) {
    // nothing
  }

  return { isOwner, privType, avatarDisplay };
};
