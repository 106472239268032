import { IonPopover } from "@ionic/react";
import React from "react";
import {
  angry,
  like,
  sad,
  love,
  wow,
  happy,
} from "../../../assets/images/reactions";
import "./Reactions.scss";

interface ReactionProps {
  onDidDismiss: () => void;
  handleReactionSelect: (value: any) => void;
  isOpen: boolean;
  event: any;
}

export const Reactions: React.FC<ReactionProps> = (props) => {
  const { onDidDismiss, handleReactionSelect, isOpen, event } = props;
  return (
    <IonPopover
      cssClass="reaction-container"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      event={event}
    >
      <img src={like} alt="like" onClick={handleReactionSelect} data-testid="reaction-box" />
      <img src={love} alt="love" onClick={handleReactionSelect} />
      <img src={happy} alt="happy" onClick={handleReactionSelect} />
      <img src={wow} alt="wow" onClick={handleReactionSelect} />
      <img src={sad} alt="sad" onClick={handleReactionSelect} />
      <img src={angry} alt="angry" onClick={handleReactionSelect} />
    </IonPopover>
  );
};
