import {
  IonInput,
  IonButton,
  IonContent,
  IonFooter,
  IonPage,
  IonItem,
  IonLabel,
} from "@ionic/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { EXTERNAL_URLS, PAGE_URLS } from "../../../constants";
import { useSelector, useDispatch } from "../../../redux/hooks";
import { getUsersState } from "../../../redux/selectors";
import { createSavingsUser } from "../../../redux/actions/goal";
import { useNavigation } from "../../../utils/useNavigation";
import { NavHeader, PageTemplate, Section } from '../../shared/PageTemplate';
import { useViewport } from '../../../hooks/useViewport';
import { HelpButton } from "../../shared/Support/HelpButton";
import { Field } from "../../shared/Field/Field";
import { createOutline } from "ionicons/icons";

export const FundingSetupName: React.FC = (props) => {
  const { goalId } = useParams<{ goalId: string }>();
  const { isMobile } = useViewport();
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { firstName, lastName } = useSelector(getUsersState) ?? {};
  const [name, setName] = useState({ firstName, lastName });
  const { t } = useTranslation();

  const next = () =>
    dispatch(createSavingsUser(name)).then(() =>
      navigate(PAGE_URLS.GOALS_FUNDING_CHOOSE_ACCOUNT.replace(":goalId", goalId), "forward")
    );

  const footerSection = <IonFooter className="ion-no-border ion-text-center">
    <IonButton
      className="ion-margin"
      data-testid="confirmLegalNameButton"
      id="confirmLegalNameButton"
      fill="solid"
      disabled={!name.firstName || !name.lastName}
      onClick={next}
    >
      {t("next")}
    </IonButton>
    <div className="ion-text-center ion-margin-horizontal ion-margin-bottom font-xs">
      {t("continueToAuthorizeTransfers")}{" "}
      <a href={EXTERNAL_URLS.TERMS_AND_CONDITIONS} target="_blank" className="weblink">
        {t("termsOfUse")}
      </a>{" "}
      {t("and")}{" "}
      <a href={EXTERNAL_URLS.PRIVACY_POLICY} target="_blank" className="weblink">
        {t("privacyPolicy")}
      </a>
    </div>
  </IonFooter>

  return (
    <PageTemplate
      header={<NavHeader title={t("savingsGoal")} defaultHref={PAGE_URLS.GOALS_HOME}>
        <HelpButton pageLocation="funding-setup-name" className="fy-nav-buttons" color="" />
      </NavHeader>}
      footer={isMobile ? footerSection : undefined}
    >
      <Section>
        <h1 className="ion-padding">{t("isThisYourName")}</h1>
        <Field label={t("legalFirstName")} icon={createOutline}>
          <IonInput
            value={name.firstName}
            onIonChange={(e) => setName({ ...name, firstName: e.detail.value! })}
            placeholder={t("legalFirstName")}
          />
        </Field>
        <Field label={t("legalLastName")} icon={createOutline}>
          <IonInput
            value={name.lastName}
            onIonChange={(e) => setName({ ...name, lastName: e.detail.value! })}
            placeholder={t("legalLastName")}
          />
        </Field>
        {isMobile ? null : footerSection}
      </Section>
    </PageTemplate>
  );
};
