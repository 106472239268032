import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { IonButton, IonContent, IonInput, IonPage, IonFooter, IonText } from "@ionic/react";
import { useCurrentGoal } from "../../../utils/useCurrentGoal";
import { useTranslation } from "react-i18next";

import "./NewGoalName.scss";
import { useDispatch, useSelector } from "react-redux";
import { modifyGoal } from "../../../redux/actions";
import { AccountInfo, Goal } from "../../../types";
import { getGoalsState } from "../../../redux/selectors";
import { PAGE_URLS } from "../../../constants";
import { ExitAlert } from "./ExitAlert";
import { useNavigation } from "../../../utils/useNavigation";
import { useViewport } from '../../../hooks/useViewport';
import { NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { HelpButton } from '../../shared/Support/HelpButton';
import { Field } from "../../shared/Field/Field";
import { createOutline } from "ionicons/icons";

export const NewGoalName: React.FC<RouteComponentProps<{ goalId: string }>> = ({ match }) => {
  const goal = useCurrentGoal() as Goal;
  const { accountInfo } = useSelector(getGoalsState);
  const [name, setName] = useState<string>(goal?.name);
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { isMobile } = useViewport();

  const footerSection = <IonFooter className="ion-text-center ion-no-border">
    <IonButton
      className="ion-margin"
      data-testid="skipGoalNameButton"
      fill="clear"
      routerLink={nextPage(goal, accountInfo)}
      routerDirection="forward"
    >
      {t("skip")}
    </IonButton>
    <IonButton
      className="ion-margin"
      color="primary"
      data-testid="newGoalNameButton"
      onClick={() =>
        dispatch(modifyGoal(goal?.id, { name })) && navigate(nextPage(goal, accountInfo), "forward")
      }
    >
      {t("next")}
    </IonButton>
  </IonFooter>

  return (
    <PageTemplate
      pageProps={{ className: "new-goal-name" }}
      header={<NavHeader
        title={t("savingsGoal")}
        backButton={<ExitAlert message={t("areYouSureExit")} />}
      >
        <HelpButton pageLocation="new-goal-name" className="fy-nav-buttons" color="" />
      </NavHeader>}
      footer={isMobile ? footerSection : undefined}
    >
      <Section>
        <IonText>
          <h1 className="ion-padding">{t("nameYourGoal")}</h1>
        </IonText>
        <Field label={t("name")} icon={createOutline}>
          <IonInput
            type="text"
            placeholder={goal?.name}
            value={name ?? goal?.name}
            onIonChange={(e) => setName(e.detail.value as string)}
          />
        </Field>
        {isMobile ? null : footerSection}
      </Section>
    </PageTemplate>
  );
};

const nextPage = (goal: Goal, accountInfo: AccountInfo) => {
  if (!accountInfo.hasSavingsAccount) {
    // User has NOT started the setup process
    return PAGE_URLS.GOALS_FUNDING_SETUP_START.replace(":goalId", goal?.id);
  } else if (!accountInfo.hasExternalFundingAccount) {
    // User has customer record at CorePro, but has not setup funding account
    return PAGE_URLS.GOALS_FUNDING_CHOOSE_ACCOUNT.replace(":goalId", goal?.id);
  } else {
    // User has customer record at CorePro and has setup funding account
    return PAGE_URLS.GOALS_HOW_SAVE.replace(":goalId", goal?.id);
  }
};
