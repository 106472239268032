import React from "react";
import { useSelector } from "react-redux";
import { IonIcon, IonButton, IonPage } from "@ionic/react";
import { addOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import "./GoalsTab.scss";

import images from "../../../assets/images/illustrations";
import { getGoalsState } from "../../../redux/selectors";
import { GoalSummary } from "./GoalSummary";
import { NavButton, NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { PAGE_URLS } from "../../../constants";
import { useInterestRate } from "../../../hooks/useInterestRate";

import { useViewport } from "../../../hooks/useViewport";
import MainLoadingUi from "../../shared/MainLoading/MainLoading";
import { FAQButton } from "../../shared/Support/FAQButton";
import { getGoalStatusGroups } from "../../../utils/goalUtils";

export const GoalsTab: React.FC = () => {
  const { loading, viewerGoals, accountInfo } = useSelector(getGoalsState);
  const { showRate, interestRate } = useInterestRate();
  const { isMobile } = useViewport();
  const history = useHistory();
  const { t } = useTranslation();

  if (!loading && isMobile && viewerGoals.length === 0) {
    setTimeout(() => history.replace(PAGE_URLS.GOALS_NEW), 500);
    return (
      <IonPage>
        <MainLoadingUi />
      </IonPage>
    );
  }

  return (
    <PageTemplate
      contentProps={{ className: "goals-tab" }}
      header={
        <NavHeader title={t("tab_goals")} showBackButton={false}>
          <FAQButton pageLocation="goals-list" className="fy-nav-buttons" color="" />
          <NavButton
            icon={<IonIcon icon={addOutline} />}
            label={t("createNewGoal")}
            routerLink={PAGE_URLS.GOALS_NEW}
            routerDirection="forward"
            data-testid="addGoalButton"
          />
        </NavHeader>
      }
    >
      {viewerGoals.length ? (
        getGoalStatusGroups(viewerGoals).map(({ status, goals }) => (
          <>
            {status && (
              <h2
                className={`font-lg margin-top-24 ion-text-capitalize ${
                  isMobile ? "ion-margin-horizontal" : ""
                }`}
                key={status}
              >
                {status.toLowerCase()}
              </h2>
            )}
            {goals.map((goal) => (
              <GoalSummary key={goal.id} goal={goal} accountInfo={accountInfo} />
            ))}
          </>
        ))
      ) : loading ? (
        <MainLoadingUi />
      ) : (
        <>
          <Section className="d-flex goals-intro-section">
            <div className="goals-intro-image ion-margin ion-padding ion-text-center">
              <img src={images.hiker} />
            </div>
            <div className="goals-intro-text ion-margin ion-padding">
              <div>
                {t(showRate ? "goalsIntroText_withInterest" : "goalsIntroText", { interestRate })}
              </div>
              <IonButton size="large" className="ion-margin-top" routerLink={PAGE_URLS.GOALS_NEW}>
                {t("createAGoal")}
              </IonButton>
            </div>
          </Section>
          {/* <Testimonials /> temporarily commenting until we have real testimonials */}
        </>
      )}
    </PageTemplate>
  );
};

const Testimonials: React.FC<any> = (props) => {
  const { t } = useTranslation();
  const testimonials = [
    {
      name: t("testimonialName_1"),
      date: t("testimonialDate_1"),
      text: t("testimonialText_1"),
      image: images.goalsTestimonial_1,
    },
    {
      name: t("testimonialName_2"),
      date: t("testimonialDate_2"),
      text: t("testimonialText_2"),
      image: images.goalsTestimonial_2,
    },
  ];
  return (
    <div className="goals-testimonials">
      {testimonials.map(({ name, date, text, image }) => (
        <div key={name} className="goals-testimonial-section fy-section-card ion-margin">
          <div className="top-image-section" style={{ backgroundImage: `url(${image})` }}>
            <div className="name-section">
              <div className="name font-title-3">{name}</div>
              <div className="date font-sm">{date}</div>
            </div>
          </div>
          <div className="text-section">{text}</div>
        </div>
      ))}
    </div>
  );
};
