import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getSpendingChallengeState } from "../../../redux/selectors";
import { showedSpendingChallenge } from "../../../utils/spendingChallenge";
import { SpendingChallengeCardTemp } from "./SpendingChallengeCardTemp";

export const SpendingChallengeActiveCard: React.FC = () => {
  const { t } = useTranslation();
  const { spendingChallengesList } = useSelector(getSpendingChallengeState);

  return spendingChallengesList?.length ? (
    <SpendingChallengeCardTemp
      spendingChallenge={showedSpendingChallenge(spendingChallengesList)[0]}
      type="active"
    />
  ) : null;
};
