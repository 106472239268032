import React from "react";

import { Goal } from "../../../types";
import { PRIVILEGE_TYPE_DATA_3, NEW_GOAL_TYPES } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useUserName } from "../../shared/User/useUserName";
import { AmountInput } from "../../shared/AmountInput/AmountInput";
import { IonText } from "@ionic/react";
import { getGoalDefs } from '../../../utils/goalUtils';

type ChangeTargetButtonProps = {
  goal: Goal;
  onChange: (goal: Partial<Goal>) => void;
  label: string;
  className?: string;
};

export const ChangeTargetButton: React.FC<ChangeTargetButtonProps> = (props) => {
  const { goal, onChange, label = "", className } = props;
  const { t } = useTranslation();
  const { partnerName } = useUserName();

  const goalOptions = getGoalDefs(goal.category)
  return (
    <AmountInput
      label={label}
      initialValue={goal.targetAmount}
      mostCommon={goalOptions.mostCommon}
      min={goalOptions.min}
      max={goalOptions.max}
      step={goalOptions.step}
      onChange={(targetAmount) => onChange({ targetAmount })}
      className={className}
    >
      <IonText>
        <h1 className="ion-text-left">
          {goal.privilegeType === PRIVILEGE_TYPE_DATA_3.SHARED
            ? t("howMuchWillYouSave")
            : t("howMuchWillYouAndPartnerSave", { partnerName })}
        </h1>
      </IonText>
    </AmountInput>
  );
};
