import React, { useEffect, useState } from "react";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonList,
  IonModal,
  IonRow,
  IonThumbnail,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryState } from "../../../redux/selectors";
import { CategoryMapObj, Design } from "../../../types";
import {
  getAllCategorizationData,
  refreshCategoryDependencies,
  setLoading,
} from "../../../redux/actions";
import {
  createCategoryObject,
  getSubcatObjWithCustomSubcat,
  customCategoryDesigns,
  postCategoryMap,
} from "../../../utils/categorizationUtils";
import images from "../../../assets/images/categorization";
import { cloneDeep } from "lodash";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import "./AddCustomCategoryModal.scss";
import { trackEvent } from "../../../vendors/monitoring";

interface AddCustomCategoryModalProps {
  isOpen: boolean;
  onClose: any;
}

export const AddCustomCategoryModal: React.FC<AddCustomCategoryModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { categoryData, subCategoryData } = useSelector(getCategoryState);
  const [newCategoryName, setNewCategoryName] = useState<string>();
  const [selectedCategoryIcon, setSelectedCategoryIcon] = useState<string>();
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(isOpen);
  const [showCreateSubcategoryAlert, setShowCreateSubcategoryAlert] = useState(false);

  useEffect(() => {
    setShowCreateCategoryModal(isOpen);
  }, [isOpen]);

  const saveNewCategory = () => {
    if (!newCategoryName || !selectedCategoryIcon) {
      return;
    }
    const designOb = customCategoryDesigns().find(
      (d: Design) => d.iconDarkValue === `${selectedCategoryIcon}_dark`
    );
    if (!designOb) {
      return;
    }
    setShowCreateCategoryModal(false);
    setShowCreateSubcategoryAlert(true);
  };

  const saveCustomSubcategory = (name: string) => {
    if (!newCategoryName || !selectedCategoryIcon) {
      return;
    }
    const designOb = customCategoryDesigns().find(
      (d: Design) => d.iconDarkValue === `${selectedCategoryIcon}_dark`
    );
    if (!designOb) {
      return;
    }
    dispatch(setLoading(true));
    const newCat = createCategoryObject(newCategoryName, designOb);
    const categories = cloneDeep(categoryData);
    categories.push(newCat);
    const { subCategories } = getSubcatObjWithCustomSubcat({
      selectedCategory: newCat.categoryShortName,
      newSubcategoryName: name,
      subCategoryData,
    });
    const categoryMap: CategoryMapObj = {
      isCustomMap: true,
      categories,
      subCategories,
    };
    postCategoryMap(categoryMap)
      .then(() => {
        dispatch(getAllCategorizationData());
        dispatch(refreshCategoryDependencies());
        resetStateAndClose();
      })
      .catch()
      .finally(() => {
        trackEvent("save_customCategory", { category: "editCategories" });
        dispatch(setLoading(false));
      });
  };

  const resetStateAndClose = () => {
    setNewCategoryName(undefined);
    setSelectedCategoryIcon(undefined);
    setShowCreateCategoryModal(false);
    setShowCreateSubcategoryAlert(false);
    onClose();
  };

  const imgStringArr = [
    ["custom_1", "custom_2", "custom_3", "custom_4"],
    ["custom_5", "custom_6", "custom_7", "custom_8"],
  ];

  const imgArr = imgStringArr.map((s: any[]) => {
    return s.map((s2: string) => {
      // @ts-ignore
      return images[`${s2}_dark`];
    });
  });

  return (
    <>
      <IonModal
        isOpen={showCreateCategoryModal}
        cssClass="AddCustomCategoryModal"
        onDidDismiss={onClose}
      >
        <ModalHeader title={t("customCategoryTitle")} />
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol className="ion-text-center">{t("customCategoryMsg")}</IonCol>
            </IonRow>
          </IonGrid>
          <IonList>
            <IonItem lines="none">
              <IonInput
                className="ion-text-center"
                placeholder={t("enterName")}
                type="text"
                data-testid="AddCustomCategoryModal_category"
                onIonChange={({ detail }) => setNewCategoryName(detail.value || "")}
              />
            </IonItem>
          </IonList>
          <IonGrid>
            {imgArr.map((row: any[], rowIndex: number) => {
              return (
                <IonRow key={rowIndex}>
                  {row.map((img: any, colIndex: number) => {
                    return (
                      <IonCol
                        key={colIndex}
                        size="3"
                        className="ion-text-center"
                        data-testid={imgStringArr[rowIndex][colIndex]}
                        onClick={() =>
                          setSelectedCategoryIcon(String(imgStringArr[rowIndex][colIndex]))
                        }
                      >
                        <IonThumbnail
                          className={
                            selectedCategoryIcon === imgStringArr[rowIndex][colIndex]
                              ? "selected"
                              : ""
                          }
                        >
                          <img src={img} />
                        </IonThumbnail>
                      </IonCol>
                    );
                  })}
                </IonRow>
              );
            })}
          </IonGrid>

          <div className="ion-text-center ion-padding-bottom">
            <IonButton fill="clear" slot="start" onClick={() => onClose(false)}>
              {t("cancel")}
            </IonButton>
            <IonButton
              slot="end"
              onClick={() => saveNewCategory()}
              data-testid="AddCustomCategoryModal_save"
              disabled={!newCategoryName || !selectedCategoryIcon}
            >
              {t("save")}
            </IonButton>
          </div>
        </IonContent>
      </IonModal>
      <IonAlert
        isOpen={showCreateSubcategoryAlert}
        header={t("addSubcategory")}
        message={t("addSubcategoryCatMsg")}
        inputs={[
          {
            name: "name",
            type: "text",
            cssClass: "ion-text-center",
            placeholder: t("subcategory"),
          },
        ]}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
          },
          {
            text: t("update"),
            handler: ({ name }) => saveCustomSubcategory(name),
          },
        ]}
      />
    </>
  );
};
