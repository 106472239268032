import React from "react";
import { IonItem, IonInput, IonLabel, IonIcon } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { AVATAR_DISPLAY, PAGE_URLS } from "../../../../constants";
import Avatars from "../../../shared/Avatar/Avatars";
import { userFields } from "../../../../types/user";
import { UserProps } from "../../../../types/user";
import { CameraIcon } from "./CameraIcon";
import InputMask from "react-input-mask";
import "./Me.scss";
import { formattedName } from "../../../../utils/formatUtils";
import { Picker } from "../../../shared/Picker/Picker";
import { createOutline } from "ionicons/icons";

export const Me: React.FC<UserProps> = (props) => {
  const { userData, handleUpdate } = props;
  const { t } = useTranslation();
  const notSet = t("profileNotSet");
  const userFields: Array<userFields> = [
    {
      label: "firstName",
      value: formattedName(userData?.firstName),
      type: "text",
      autocapitalize: "words",
    },
    {
      label: "lastName",
      value: formattedName(userData?.lastName),
      type: "text",
      autocapitalize: "words",
    },
    { label: "email", value: userData?.email, type: "email" },
  ];

  return (
    <div className="profile-tab">
      <Avatars
        className="ion-margin-top ion-margin-bottom"
        sizeStyle="lg"
        avatarType={AVATAR_DISPLAY.ME}
        extraLayer={<CameraIcon />}
      />
      {userFields.map((field) => {
        return (
          <IonItem lines="none" key={field.label}>
            <IonLabel position="floating">{t(field.label)}</IonLabel>
            <IonInput
              value={typeof field.value != "string" ? notSet : field.value}
              type={field.type as any}
              maxlength={field.maxlength || undefined}
              autocapitalize={field.autocapitalize || "off"}
              onIonChange={(e) => handleUpdate({ [field.label]: e.detail.value! })}
            ></IonInput>
            <IonIcon className="ion-align-self-end font-md" icon={createOutline} slot="end" />
          </IonItem>
        );
      })}
      <IonItem lines="none">
        <IonLabel position="stacked">{t("mobile")}</IonLabel>
        <div className="profile-input-mask-div">
          <InputMask
            type="tel"
            name="mobile"
            slot="end"
            placeholder={notSet}
            mask={"(999)999-9999"}
            inputMode="tel"
            autoComplete="tel"
            value={userData.mobile || ""}
            onChange={(e) => {
              handleUpdate({ mobile: e.target.value?.replace(/\D+/g, "") });
            }}
            className="profile-input"
            data-testid="ba_mobile"
            maskChar=" "
          />
        </div>
      </IonItem>
      <IonItem lines="none">
        <IonLabel position="floating">{t("gender")}</IonLabel>
        <Picker
          name="Gender"
          options={[t("genderMale"), t("genderFemale"), t("other")]}
          value={userData.gender || notSet}
          onChange={(value) => handleUpdate({ gender: value }, "user")}
        />
      </IonItem>
      <IonItem detail routerLink={PAGE_URLS.MORE_PROFILE_INDIV_FINANCES} lines="none">
        <IonLabel>{t("finances")}</IonLabel>
      </IonItem>
    </div>
  );
};
