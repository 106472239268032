import React, { useEffect, useState } from "react";
import {
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAccountsState } from "../../../redux/selectors";
import { get } from "../../../utils/apiOps";
import { PAGE_URLS } from "../../../constants";
import { HelpButton } from "../../shared/Support/HelpButton";
import { ManageAccountsUrlParams } from "../../../types";
import { operations, components } from "../../../types/schema";
import numeral from "numeral";
import { PageTemplate, Section, NavHeader } from '../../shared/PageTemplate';

type insSearchResp =
  operations["api_plaid_institutions"]["responses"]["200"]["content"]["application/json"];
type insStatusRes = components["schemas"]["InstitutionSearchSchema"][0];

export const LinkedInstitutionStatus: React.FC = () => {
  const { t } = useTranslation();
  const { sourceInstitutionId } = useParams<ManageAccountsUrlParams>();
  const { myAccounts, otherAccountCollection } = useSelector(getAccountsState);
  const [selectedInstitution, setSelectedInstitution] = useState<any>();
  const [institutionStatus, setInstitutionStatus] = useState<insStatusRes>();

  useEffect(() => {
    const allAccounts = myAccounts.concat(otherAccountCollection);
    if (allAccounts.length === 0) {
      return;
    }
    const foundInstitution = allAccounts?.find(
      (institution: any) => institution.sourceInstitutionId === sourceInstitutionId
    );
    setSelectedInstitution(foundInstitution);
    get(true, {
      endpoint: "/plaid/institutions",
      params: {
        bankName: encodeURIComponent(foundInstitution.sourceInstitutionBaseId),
        searchAuth: false,
        version: 2,
      },
    }).then(({ data }: insSearchResp) => {
      setInstitutionStatus(data[0]);
    });
  }, [sourceInstitutionId, myAccounts, otherAccountCollection]);

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={<NavHeader
        defaultHref={PAGE_URLS.MORE_ACCTS_MANAGE_LINKED_LIST.replace(":sourceInstitutionId", sourceInstitutionId)}
        title={selectedInstitution?.bank || t("accounts")}
      />}
    >
      <Section>
        <IonGrid className="ion-margin-top">
          {!institutionStatus?.status.transactions_updates ? (
            <IonRow className="ion-margin-top">
              <IonCol className="ion-margin-top ion-text-center">{t("notEnoughData")}</IonCol>
            </IonRow>
          ) : (
            <>
              <IonRow className="ion-margin-top">
                <IonCol className="ion-margin-top ion-text-center">
                  {institutionStatus.status.transactions_updates.status === "HEALTHY"
                    ? t("bankStatusGood")
                    : t("bankStatusBad")}
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol className="ion-margin-top ion-text-center">
                  {numeral(
                    institutionStatus.status.transactions_updates.breakdown.success
                  ).format("0.0%")}{" "}
                  {t("accountsUpdated")}
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol className="ion-margin-top ion-text-center">
                  {t("bankStatusHelpMsg1")}
                  {institutionStatus.status.transactions_updates.status === "HEALTHY"
                    ? t("bankStatusHelpMsg2")
                    : t("bankStatusHelpMsg3")}
                  {t("bankStatusHelpMsg4")}
                </IonCol>
              </IonRow>
            </>
          )}
          <IonRow className="ion-margin-top">
            <IonCol className="ion-margin-top ion-text-center">
              <HelpButton buttonCopy="getHelp" />
            </IonCol>
          </IonRow>
        </IonGrid>
      </Section>
    </PageTemplate >
  );
};
