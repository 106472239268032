import React, { useEffect, useState } from "react";
import {
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonFooter,
  IonBadge,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { PAGE_URLS } from "../../../constants";
import { openChat, intercomGetUnreadConversationCt, openFAQ } from "../../../vendors/intercom";
import { trackEvent } from "../../../vendors/monitoring";
import { getDeviceDataState } from "../../../redux/selectors";
import { PageTemplate, Section, NavHeader } from '../../shared/PageTemplate';

export const Help: React.FC = () => {
  const { t } = useTranslation();
  const [unreadMessagesCt, setUnreadMessagesCt] = useState(0);
  const { deviceDetails } = useSelector(getDeviceDataState);

  const checkUnreadMessages = () => {
    intercomGetUnreadConversationCt().then((unreadCt) => {
      setUnreadMessagesCt(unreadCt);
    });
  };
  const openIntercomChat = () => {
    trackEvent("open Intercom chat");
    openChat();
  };

  const openIntercomFAQ = () => {
    trackEvent("open Intercom FAQ");
    openFAQ();
  };

  useEffect(() => {
    checkUnreadMessages();
  }, []);

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={<NavHeader title={t("help")} defaultHref={PAGE_URLS.MORE_HOME} />}
      footer={<IonFooter className="ion-text-center ion-padding fy-section-card">
        {(deviceDetails?.platform === "web" && t("appBuildVersionWeb")) ||
          t("appBuildVersion", {
            appVersion: deviceDetails?.appVersion,
            buildVersion: deviceDetails?.appBuild,
          })}
      </IonFooter>}
    >
      <Section>
        <IonList lines="none">
          <IonItemDivider>
            <IonLabel>{t("support")}</IonLabel>
          </IonItemDivider>
          <IonItem onClick={() => openIntercomFAQ()} detail={true}>
            <IonLabel>{t("faq")}</IonLabel>
          </IonItem>
          <IonItem onClick={() => openIntercomChat()} detail={true}>
            <IonLabel>{t("contactUs")}</IonLabel>
            {unreadMessagesCt > 0 && <IonBadge color="dark">{unreadMessagesCt}</IonBadge>}
          </IonItem>
        </IonList>
      </Section>
    </PageTemplate>
  );
};
