import React from "react";
import { TransactionBaseType } from "../../../types";

/**
 * Formats currencies. Legacy from Honeyfi.
 * @param amount
 * @param showCents
 * @param baseType
 * @param dontShowPlus
 */
function amountHelper(
  amount: number,
  showCents: boolean = false,
  baseType?: TransactionBaseType,
  dontShowPlus: boolean = false
) {
  if (!isNaN(amount)) {
    let output: any = Math.abs(amount);

    // add the currency
    if (showCents) {
      output = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(Math.trunc(output));
    } else {
      output = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(output);
    }

    // if the baseType is "CREDIT", add the minus
    if (baseType === TransactionBaseType.DEBIT && amount !== 0) {
      output = "-" + output;
    }

    // if the value is less than $0 OR the baseType is "CREDIT", add the plus
    if (!dontShowPlus && (amount < 0 || baseType === TransactionBaseType.CREDIT)) {
      output = "+" + output;
    }

    return output;
  } else {
    return amount;
  }
}

interface ContainerProps {
  amount: number;
  baseType?: TransactionBaseType;
  showCents?: boolean;
  formatOff?: boolean;
}

const Amount: React.FC<ContainerProps> = ({ amount, baseType, showCents, formatOff }) => {
  return (
    <span
      className={formatOff ? "" : baseType === TransactionBaseType.DEBIT ? "money-out" : "money-in"}
    >
      {amountHelper(amount, showCents, baseType)}
      {showCents && (
        <span className="font-xs medium">
          .
          {Math.round(amount * 100)
            .toString()
            .slice(-2)}
        </span>
      )}
    </span>
  );
};

export default Amount;
