import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  IonAvatar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonProgressBar,
  IonRow,
} from "@ionic/react";
import { getPersonAvatar, getMaxSpendingAmount } from "../../../utils/homeTab";
import { getBudgetAnalyticsState, getUsersState } from "../../../redux/selectors";
import Avatars from "../../shared/Avatar/Avatars";
import { PAGE_URLS, BUDGET_SHARED_TYPE } from "../../../constants";
import dayjs from "dayjs";
import numeral from "numeral";

export const SpendingByPersonCard: React.FC = () => {
  const { t } = useTranslation();

  //redux stata
  const { userId, singlePlayerMode } = useSelector(getUsersState);
  const spendingByPersonArray = useSelector(getBudgetAnalyticsState).spendingByPersonArray?.filter(
    (spending) => !singlePlayerMode || spending.id?.includes(userId)
  );

  const maxSpendingAmount = getMaxSpendingAmount(spendingByPersonArray);

  return spendingByPersonArray ? (
    <IonCard
      routerLink={PAGE_URLS.SPENDING_BY_PERSON_HOME.replace(
        ":effectiveDate",
        dayjs().startOf("month").format("YYYY-MM-DD")
      ).replace(":budgetType", BUDGET_SHARED_TYPE.HOUSEHOLD)}
      data-testid="SpendingByPerson"
    >
      <IonCardHeader>
        <IonCardTitle className="ion-text-center">{t("spendingByPerson")}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className="ion-text-center ion-no-padding">
        <IonList>
          {spendingByPersonArray.map((data: any) => {
            return (
              <IonItem lines="none" key={data.id}>
                <IonAvatar slot="start">
                  <Avatars
                    avatarType={getPersonAvatar(data, userId)}
                    sizeStyle="sm"
                    diagonal={true}
                  />
                </IonAvatar>
                <IonGrid>
                  <IonCol>
                    <IonRow>{numeral(data.Expenses.amountActual).format("$0,0")}</IonRow>
                    <IonRow>
                      <IonProgressBar
                        value={data.Expenses.amountActual / maxSpendingAmount}
                      ></IonProgressBar>
                    </IonRow>
                  </IonCol>
                </IonGrid>
              </IonItem>
            );
          })}
        </IonList>
      </IonCardContent>
    </IonCard>
  ) : null;
};
