import React, { useState } from "react";
import {
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonPage,
  IonFooter,
  IonToolbar,
  IonButtons,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../../utils/formUtils";
import "../Onboarding/onboarding.scss";
import { Plugins } from "@capacitor/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { doLogin, setLoading, setToastMessage } from "../../../redux/actions";
import { getBranchDataState } from "../../../redux/selectors";
import constants, { PAGE_URLS } from "../../../constants";
import { OnboardingNavHeader } from "../Onboarding/OnboardingNavHeader";
import { firstlyLogo } from "../../../assets/images";
import Template, { OnboardingCopy } from "../Onboarding/Template";

import { trackEvent } from "../../../vendors/monitoring";

import { processDeepLink } from "../../../vendors/attribution";
import { SessionData } from "branch-sdk";
import { BranchInitEvent } from "capacitor-branch-deep-links";

const { Device } = Plugins;

interface ContainerProps {
  user: any;
  setUserId: any;
}

export const Login: React.FC<ContainerProps> = ({ user, setUserId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { branchResp } = useSelector(getBranchDataState);
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const dispatch = useDispatch();
  const formGood = Boolean(email && password);

  const handleLogin = async () => {
    if (!email || validateEmail(email) === false) {
      dispatch(setToastMessage(t("invalidEmail")));
      return;
    }
    if (!password || password.length < 2) {
      dispatch(setToastMessage(t("invalidPassword")));
      return;
    }
    const deviceInfo = await Device.getInfo();
    dispatch(setLoading(true));
    (dispatch(doLogin(email, password, deviceInfo.platform, deviceInfo.uuid)) as any)
      .then((redirectRoute: string | null) => {
        if (!redirectRoute && branchResp) {
          return processDeepLink(
            (branchResp as SessionData).data_parsed ||
              (branchResp as BranchInitEvent).referringParams
          );
        }
        history.push(redirectRoute || PAGE_URLS.HOME);
      })
      .catch((error: any) => {
        // other toasts handled by apiOps
        if (error && error.code === "NotAuthorizedException") {
          dispatch(setToastMessage(t("incorrectUsernameOrPassword")));
        }
      })
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <Template
      header={
        <OnboardingNavHeader
          showBackButton={true}
          defaultHref={PAGE_URLS.WELCOME}
          pageLocation="sign-in"
          title=" "
        />
      }
      footer={
        <IonFooter className="ion-text-center ion-no-border">
          <IonToolbar>
            <IonButtons slot="start" className="ion-margin-start">
              <IonButton
                size="small"
                color="secondary"
                fill="clear"
                data-testid="signup"
                routerLink={PAGE_URLS.SIGNUP_HAS_PARTNER}
                onClick={() => trackEvent("click_createAccount", { category: "onboard" })}
              >
                {t("createAccount")}
              </IonButton>
            </IonButtons>
            <IonButtons slot="end" className="ion-margin-end">
              <IonButton
                size="small"
                color="secondary"
                fill="clear"
                data-testid="forgot-pass"
                routerLink={PAGE_URLS.FORGOT_PASS}
                onClick={() => trackEvent("click_forgotPassword", { category: "onboard" })}
              >
                {t("forgotPassword")}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      }
    >
      <IonGrid>
        <IonRow className="hidden-desktop">
          <IonCol size="5" offset="3.5" size-md="2" offset-md="5">
            <img src={firstlyLogo} alt={constants.APP_NAME} />
          </IonCol>
        </IonRow>
        <OnboardingCopy desktopPrefix={t("signIn")} />
        <IonRow className="bounded">
          <IonCol>
            <IonList>
              <IonItem>
                <IonLabel position="stacked">{t("email")}</IonLabel>
                <IonInput
                  type="email"
                  value={email}
                  minlength={3}
                  required
                  inputmode="email"
                  autocomplete="email"
                  debounce={25}
                  data-testid="email"
                  onIonChange={(e) => {
                    setEmail(e.detail.value!);
                  }}
                  onKeyPress={(e) => e.key === "Enter" && handleLogin()}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">{t("password")}</IonLabel>
                <IonInput
                  type="password"
                  value={password}
                  data-testid="password"
                  inputmode="text"
                  autocomplete="current-password"
                  minlength={8}
                  debounce={25}
                  clearOnEdit={false}
                  required
                  onIonChange={(e) => {
                    setPassword(e.detail.value!);
                  }}
                  onKeyPress={(e) => e.key === "Enter" && handleLogin()}
                />
              </IonItem>
            </IonList>
            <br />
            <IonButton
              disabled={!formGood}
              color="primary"
              expand="block"
              size="large"
              data-testid="login"
              onClick={handleLogin}
              className="ion-padding-horizontal"
            >
              {t("logIn")}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Template>
  );
};
