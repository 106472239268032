import React from "react";
import { IonItem } from "@ionic/react";
import { AccountCard } from "./AccountCard";
import { trackEvent } from "../../../vendors/monitoring";

export const Account: React.FC<any> = (props: any) => {
  const { account, onSelect } = props;
  const moneyStyle = account.balance.balToUse > 0 ? "money-in" : "money-out";

  return (
    <IonItem
      lines="none"
      className="account-item"
      onClick={() => {
        onSelect(account._id);
        trackEvent("view_acct", {
          category: "account",
          account: account.accountNickname || account.accountName || account.name,
        });
      }}
    >
      <AccountCard account={account} />
    </IonItem>
  );
};
