import React from "react";
import { IonButton, IonContent, IonFooter } from "@ionic/react";
import { Confetti } from "../Confetti/Confetti";
import { Modal } from "../Modal/Modal";

type CelebrationModalProps = {
  open: boolean;
  onClose: () => void;
  buttonText: string;
};

export const CelebrationModal: React.FC<CelebrationModalProps> = (props) => {
  const { open, onClose, buttonText, children } = props;
  return (
    <Modal
      open={open}
      title=" "
      onClose={onClose}
      modalStyle="celebration-modal"
      withHeader={false}
    >
      <IonContent className="celebration-modal-content">
        <Confetti>
          {children}
        </Confetti>
      </IonContent>
      <IonFooter className="ion-no-border ion-text-center">
        <IonButton fill="solid" className="ion-margin" onClick={onClose} data-testid="celebrationCloseButton">
          {buttonText}!
        </IonButton>
      </IonFooter>
    </Modal>
  );
};
