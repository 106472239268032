import React, { useState } from "react";
import { IonCol, IonContent, IonGrid, IonModal, IonRow } from "@ionic/react";
import { useTranslation } from "react-i18next";

import { InstitutionSearchResp } from "./LinkInstitutionWrapper";
import bankLogos from "../../../assets/images/bank_logos";

type BankLinkSplashModalProps = {
  isOpen: boolean;
  onClose: any;
  institution: InstitutionSearchResp;
};

export const BankLinkSplashModal: React.FC<BankLinkSplashModalProps> = (props) => {
  const { isOpen, institution, onClose } = props;
  const { t } = useTranslation();
  const insImg = institution.logo
    ? `data:image/png;base64,${institution.logo}`
    : bankLogos[`${institution.id}`];
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonContent>
        <IonGrid className="ion-padding-top ion-margin-top">
          <IonRow>
            <IonCol>
              {insImg && (
                <div className="ion-text-center">
                  <img src={insImg} className="avatar"></img>
                </div>
              )}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className="ion-text-center">
                {t("bankLinkSplashText", { selectedInstitutionName: institution.name })}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};
