import React from "react";
import { IonAvatar, IonCol, IonItem, IonList, IonProgressBar, IonRow } from "@ionic/react";
import { SpendingChallengeType } from "../../../types/spendingChallenge";
import Avatars from "../../shared/Avatar/Avatars";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { getUsersState } from "../../../redux/selectors";
import { ChallengeType } from "../../../types/spendingChallenge";

interface SpendingChallengeProgressBar {
  spendingChallenge: SpendingChallengeType;
}

export const SpendingChallengeProgressBar: React.FC<SpendingChallengeProgressBar> = (props) => {
  const { spendingChallenge } = props;

  const { userId } = useSelector(getUsersState);

  const findRelatedTarget = (result: any) => {
    return spendingChallenge.targets.find((target: any) => target._id === result.targetId);
  };

  const findAvatarType = (userIdFromResult: string) => {
    if (spendingChallenge.competitionType === ChallengeType.INDIVIDUAL) {
      return 1;
    } else if (spendingChallenge.competitionType === ChallengeType.COLLABORATIVE) {
      return 0;
    } else if (spendingChallenge.competitionType === ChallengeType.COMPETITIVE) {
      if (userId === userIdFromResult) {
        return 1;
      } else {
        return 2;
      }
    }
  };
  const challengeAmountStatus = (result: any) => {
    // competitive mode does not show or calculate target amount
    // Whoever spends less, wins.
    if (spendingChallenge.competitionType === ChallengeType.COMPETITIVE) {
      return result ? (
        <>
          <strong>{numeral(result.amt).format("$0,0")}&nbsp;</strong>
        </>
      ) : (
        "$0"
      );
    } else {
      return result ? (
        <>
          <strong>{numeral(result.amt).format("$0,0")}&nbsp;</strong>/{" "}
          {numeral(findRelatedTarget(result)?.value).format("$0,0")}
        </>
      ) : (
        "$0"
      );
    }
  };

  const progressBarValue = (result: any) => {
    if (spendingChallenge.competitionType === ChallengeType.COMPETITIVE) {
      // get highest value to build the competitive progress bar

      const highestResult = Math.max(...spendingChallenge.results.map((res) => res.amt));
      return result.amt ? result.amt / highestResult : 0;
    }
    return result
      ? result.amt / (findRelatedTarget(result)?.value || spendingChallenge.targets[0].value)
      : 0;
  };

  return (
    <IonList lines="none">
      {spendingChallenge.results.map((result: any, index: number) => (
        <IonItem lines="none" className="ion-margin-top" key={index}>
          <IonAvatar slot="start">
            <Avatars
              avatarType={findAvatarType(result.userIds[0])}
              sizeStyle="sm"
              diagonal={true}
            />
          </IonAvatar>
          <IonCol>
            <IonRow>{challengeAmountStatus(result)}</IonRow>
            <IonRow>
              <IonProgressBar value={progressBarValue(result)}></IonProgressBar>
            </IonRow>
          </IonCol>
        </IonItem>
      ))}
    </IonList>
  );
};
