import React from 'react'
import { IonItem, IonListHeader, IonText } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import numeral from 'numeral';
import dayjs from 'dayjs';

import './TransactionList.scss';

import { FundingInfo, Goal, GoalTransaction } from '../../../types'
import icons from "../../../assets/images/goal_icons";
import Avatars from '../../shared/Avatar/Avatars';
import { AVATAR_DISPLAY, ACCOUNT_OWNER_TYPE } from '../../../constants';
import { useUserName } from '../../shared/User/useUserName';
import { getTransferStatus, getAvailableDate } from '../../../utils/goalUtils';
import { formatAccountNumber } from '../../../utils/formatUtils';

type TransactionListProps = {
  goal?: Goal;
  account?: FundingInfo["fundingAccount"];
  transactions?: GoalTransaction[];
  dateFormat?: string;
  includeAccountName?: boolean;
}

export const TransactionList: React.FC<TransactionListProps> = props => {
  const { transactions, goal, account, dateFormat, includeAccountName } = props
  const { t } = useTranslation();

  const _transactionsAsc = transactions || goal?.transfers?.rows || [];
  const _transactions = [..._transactionsAsc].reverse();

  return <>
    <h3 className="font-title-3 ion-margin-start fy-section-title-border">
      {t("activity")}
    </h3>
    <br />
    {_transactions.length === 0 ? <IonItem lines="none">
      <IonText>{t("noRecentActivity")}</IonText>
    </IonItem> : null}
    {_transactions.map((item, i) => <TransactionItem
      item={item}
      account={account || (item.ownedByViewer ? goal?.viewerFundingInfo : goal?.nonViewerFundingInfo)?.fundingAccount}
      dateFormat={dateFormat}
      includeAccountName={includeAccountName}
      key={item.createdOn + i}
    />)}
  </>
}

type TransactionItemProps = {
  item: GoalTransaction;
  account: FundingInfo["fundingAccount"];
  dateFormat?: string;
  includeAccountName?: boolean;
}

const TransactionItem: React.FC<TransactionItemProps> = props => {
  const { account, item, dateFormat = "MMM D", includeAccountName = true } = props;
  const { partnerName } = useUserName();
  const { t } = useTranslation();

  const sharedStatus = account?.hhInfo.accountOwnershipType === ACCOUNT_OWNER_TYPE.JOINT ? AVATAR_DISPLAY.SHARED : AVATAR_DISPLAY.ME;
  const textParams = {
    name: item.ownedByViewer ? t("you") : partnerName,
    amount: numeral(item.amount).format("$0,0.00"),
    transaction: item.baseType === "DEBIT" ? t("withdrew") : t("saved"),
  };

  return <IonItem lines="none" className="ion-padding-bottom goal-transaction-item">
    {item.isSavingsBonus
      ? <div className="savings-bonus-logo">
        <img src={icons.savings_bonus} alt="money bag" />
      </div>
      : <Avatars avatarType={sharedStatus} alignment="center" sizeStyle={sharedStatus === AVATAR_DISPLAY.SHARED ? "sm" : "md"} diagonal />}
    <IonText className="transaction-text">
      <h4 className="font-md">
        {t(item.isSavingsBonus ? "earnedASavingsBonus" : "goalTransferText", textParams)}
      </h4>
      <h6 className="font-sm">
        {includeAccountName ? <AccountName account={account} /> : null}
      </h6>
      <h6 className="font-xs">
        {item.createdOn && dayjs(item.createdOn).format(dateFormat)}
        &nbsp;|&nbsp;
        {getAvailableDate(item) ? t("estimatedArrival", { date: getAvailableDate(item) }) : getTransferStatus(item)}
      </h6>
      <h6 className="font-xs">

      </h6>
    </IonText>
  </IonItem>
}

type AccountNameProps = {
  account: FundingInfo["fundingAccount"];
}
const AccountName: React.FC<AccountNameProps> = ({ account }) => {
  return <span className="transfer-account-name">
    <span className="account-name">
      {account.nickname || account.accountName}
    </span>
    <span className="institution-name">
      &nbsp;|&nbsp;{account.institution.name}
    </span>
    <span className="account-number">
      &nbsp;({formatAccountNumber(account.accountNumber)})
    </span>
  </span>
}