import React, { useState } from "react";
import { IonButton, IonList, IonItem, IonLabel, IonAlert } from "@ionic/react";
import "./ExportTransactions.scss";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { get } from "../../../../utils/apiOps";
import { PageTemplate, Section, NavHeader } from "../../../shared/PageTemplate";
import { PAGE_URLS } from "../../../../constants";
import { DatePicker } from "../../../shared/DatePicker/DatePicker";
import { trackEvent } from "../../../../vendors/monitoring";

export const ExportTransactions: React.FC = () => {
  const { t } = useTranslation();

  //state
  const [startDate, setStartDate] = useState(
    dayjs(Date.now()).add(-90, "days").format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(dayjs(Date.now()).format("MM/DD/YYYY"));
  const [exportModalOpen, setExportModalOpen] = useState(false);

  //methods
  const handleTransactionExport = async () => {
    if (!validateDateRange()) {
      const exportParams = { toExport: true, endDate, startDate, isPrivate: "both" };
      try {
        const data = await get(true, {
          endpoint: "/filterTransaction",
          params: exportParams,
        });
        trackEvent("export_exportTrans", { category: "settings" });
        setExportModalOpen(true);
      } catch (error) {
        //do nothing?
      }
    }
  };

  const validateDateRange = () => {
    return dayjs(endDate).diff(startDate, "day") > 100;
  };

  return (
    <PageTemplate
      header={<NavHeader defaultHref={PAGE_URLS.MORE_SETTINGS} title={t("settings")} />}
    >
      <Section>
        <IonList lines="none">
          <IonItem>
            <div className="ion-text-center export-transactions-des">
              {t("exportTransactionsDescription")}
            </div>
          </IonItem>
          <IonItem>
            <IonLabel>{t("dateFrom")}</IonLabel>
            <DatePicker
              displayFormat="MM/DD/YYYY"
              value={startDate}
              onChange={(date) => setStartDate(dayjs(date || "").format("MM/DD/YYYY"))}
              data-testid="export_transactions_startdate"
            />
          </IonItem>
          <IonItem>
            <IonLabel>{t("dateTo")}</IonLabel>
            <DatePicker
              displayFormat="MM/DD/YYYY"
              value={endDate}
              onChange={(date) => setEndDate(dayjs(date || "").format("MM/DD/YYYY"))}
            />
          </IonItem>
        </IonList>
        {validateDateRange() ? (
          <div className="export-transactions-warning" data-testid="export_transactions_warning">
            {t("exportTransactionWarningMessage")}
          </div>
        ) : (
          ""
        )}
        <IonButton
          className="export-transactions-button"
          onClick={handleTransactionExport}
          disabled={validateDateRange()}
          data-testid="export_transactions_button"
        >
          {t("export")}
        </IonButton>
      </Section>
      <IonAlert
        isOpen={exportModalOpen}
        onDidDismiss={() => setExportModalOpen(false)}
        header={t("exportSent")}
        message={t("exportTransactionSuccessMessage")}
        buttons={[
          {
            text: t("gotIt"),
            role: "cancel",
            handler: () => setExportModalOpen(false),
          },
        ]}
      ></IonAlert>
    </PageTemplate>
  );
};
