import { SessionData } from "branch-sdk";
import { BranchInitEvent } from "capacitor-branch-deep-links";
import * as types from "../actionTypes";

export const setBranchData = (data: SessionData | BranchInitEvent) => {
  return {
    type: types.SET_BRANCH_DATA,
    payload: data,
  };
};
