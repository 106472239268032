import React, { useState } from "react";
import {
  IonList,
  IonRow,
  IonGrid,
  IonCol,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonNote,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Amount from "../../shared/Amount/Amount";
import { transNameHelper } from "../../../utils/transUtils";
import { get } from "../../../utils/apiOps";
import { SearchParam, Transaction, TransactionBaseType } from "../../../types";

type SearchResults = {
  transactions: Transaction[];
};

interface BillModalSearchProps {
  effectiveDate: string;
  transactionOnClick: any;
}

export const BillModalSearch: React.FC<BillModalSearchProps> = ({
  effectiveDate,
  transactionOnClick,
}) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState<Transaction[] | null>();

  const handleSearch = ({ detail }: any) => {
    setSearchText(detail.value!);
    if (!detail.value || detail.value.length < 3) {
      setSearchResults(null);
      return;
    }
    const startDate = dayjs(effectiveDate).subtract(1, "month").format("MM/DD/YYYY");
    const endDate = dayjs(effectiveDate).add(1, "month").endOf("month").format("MM/DD/YYYY");
    const searchParams: SearchParam = {
      baseType: TransactionBaseType.DEBIT,
      keyword: detail.value,
      isPrivate: "both",
      startDate,
      endDate,
    };
    get(true, {
      endpoint: "/filterTransaction",
      params: searchParams,
    })
      .then(({ data }) => {
        setSearchResults(data ? data.transactions.filter((t: Transaction) => !t.isBill) : []);
      })
      .catch(() => {
        setSearchResults(null);
      });
  };

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol className="font-sm ion-text-center">
            <IonSearchbar
              value={searchText}
              onIonChange={handleSearch}
              debounce={250}
            ></IonSearchbar>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonList lines="none">
        {searchResults && searchResults.length === 0 ? (
          <div className="ion-text-center">{t("createBill_noTransactions")}</div>
        ) : (
          searchResults &&
          searchResults
            .filter((t) => !t.isBill)
            .map((transaction) => {
              return (
                <IonItem
                  key={transaction._id}
                  onClick={() => {
                    transactionOnClick(transaction._id, transaction);
                    setSearchText("");
                    setSearchResults(null);
                  }}
                >
                  <IonLabel>
                    <h3 className="font-md">{transNameHelper(transaction)}</h3>
                    <h4 className="font-sm placeholder-text">
                      {dayjs(transaction.date).format("ddd, MMM D")}
                    </h4>
                  </IonLabel>
                  <IonNote className="ion-text-end ion-align-self-start">
                    <h3 className="font-md">
                      <Amount
                        baseType={transaction.baseType}
                        amount={transaction.amount.amount}
                        showCents={false}
                      />
                    </h3>
                  </IonNote>
                </IonItem>
              );
            })
        )}
      </IonList>
    </>
  );
};
