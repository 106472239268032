import React from "react";
import {
  IonContent,
  IonRow,
  IonPage,
  IonCol,
  IonButton,
  IonToolbar,
  IonFooter,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { WelcomeSlides } from "./WelcomeSlides";
import { PAGE_URLS } from "../../../constants";
import "./onboarding.scss";
import { OnboardingNavHeader } from "./OnboardingNavHeader";

import { trackEvent } from "../../../vendors/monitoring";

export const Welcome: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <IonPage id="welcome-page">
      <OnboardingNavHeader />
      <IonContent fullscreen>
        <IonRow className="container">
          <IonCol>
            <WelcomeSlides />
          </IonCol>
        </IonRow>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar no-border className="ion-text-center">
          <IonButton
            fill="clear"
            color="primary"
            size="large"
            data-testid="welcomeLogin"
            routerLink={PAGE_URLS.LOGIN}
            className="ion-margin-end ion-margin-bottom"
            onClick={() => trackEvent("click_signIn", { category: "onboard" })}
          >
            {t("signIn")}
          </IonButton>
          <IonButton
            color="primary"
            data-testid="signup"
            size="large"
            className="ion-margin-start ion-margin-bottom"
            routerLink={PAGE_URLS.SIGNUP_HAS_PARTNER}
            onClick={() => trackEvent("click_getStarted", { category: "onboard" })}
          >
            {t("signUpBtn")}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};
