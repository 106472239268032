import { useState, useEffect } from "react";
import dayjs from "dayjs";

import { useDispatch, useSelector } from "../../../redux/hooks";
import { categoryState } from "../../../redux/reducers/category";
import { getBudgetAnalyticsState, getCategoryState } from "../../../redux/selectors";
import { BudgetItem } from "../../../types/budgetAnalytics";
import { getBudget } from "../../../redux/actions";
import { trackEvent } from "../../../vendors/monitoring";

export const useSpendingAnalysisData = (
  month = dayjs().startOf("month").format("YYYY-MM-DD"),
  visibility: "hh" | "user" = "hh",
  initialIndex = 0
) => {
  const dispatch = useDispatch();
  const [index, setIndex] = useState(() => initialIndex);
  const { loading, loadingMonth, budgetsArray } = useSelector(getBudgetAnalyticsState);
  const data = budgetsArray.find((item) => item.budgetAnalyticsDate === month);
  const { categoryData = [] } = useSelector(getCategoryState) as categoryState;
  const { subs: spending, amountActual: totalSpending } =
    (data || ({} as BudgetItem))[visibility]?.budget?.Expenses! || {};
  const categories = Object.values(spending || {}).filter((cat) => cat.amountActual);
  const onIndexChange = (diff: -1 | 1) => (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIndex((index + diff + categories.length) % categories.length);
    trackEvent("click_categorySpendCarousel", { category: "spendCarousel" });
  };
  const category = categories[index];
  const categoryDetails = categoryData.find((c) => c.categoryFullName === category?.displayName);
  useEffect(() => {
    if (!data && (!loading || loadingMonth !== month)) {
      dispatch(getBudget({ effectiveDate: month }));
    }
    if (data && index >= categories.length) {
      setIndex(0); // in case the newly selected month has less categories than the last;
    }
  }, [month]);

  return { loading: !data, totalSpending, category, categoryDetails, index, onIndexChange };
};
