import React from "react";
import { useTranslation } from "react-i18next";
import { IonAlert } from "@ionic/react";
import { getDeviceDataState } from "../../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { saveCards } from "../../../utils/homeTab";
import { trackEvent } from "../../../vendors/monitoring";

interface HideCardAlertProps {
  open: boolean;
  close: () => void;
  removedCard: string;
}

export const HideCardAlert: React.FC<HideCardAlertProps> = (props) => {
  const { open, close, removedCard } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //redux state
  const { deviceDetails, cardPreferences } = useSelector(getDeviceDataState);

  //method
  const handleCardHide = () => {
    trackEvent("click_hideCard", { category: "home", label: removedCard });
    const index = cardPreferences.cardArr.indexOf(removedCard);
    const newCardArry = [...cardPreferences.cardArr];
    newCardArry.splice(index, 1);
    saveCards(
      { newHomeCardsArray: newCardArry, cardPreferences, uuid: deviceDetails!.uuid },
      dispatch
    );
  };

  return (
    <IonAlert
      isOpen={open}
      onDidDismiss={() => close()}
      header={t("hideCardTtitle")}
      message={t("hideCardInfo")}
      buttons={[
        {
          text: t("no"),
          role: "cancel",
          cssClass: "secondary",
          handler: () => close(),
        },
        {
          text: t("yes"),
          handler: () => handleCardHide(),
        },
      ]}
    />
  );
};
