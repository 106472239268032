import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAccountsState, getUsersState } from "../../../redux/selectors";
import Template, { OnboardingCopy } from "./Template";
import { useTranslation } from "react-i18next";
import { PAGE_URLS } from "../../../constants";
import { useDispatch } from "react-redux";
import { finishOnboarding } from "../../../redux/actions";
import { ShareSettingsAccountList } from "../../shared/Link/ShareSettingsAccountList";
import "./onboarding.scss";
import { OnboardingNavHeader } from "./OnboardingNavHeader";
import { OnboardingProgress } from "./OnboardingProgress";

const pageLocation = "share-settings";

export const ShareSettings: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { myAccounts } = useSelector(getAccountsState);
  const { memberType, firstName, partnerEmail, hasPartner } = useSelector(getUsersState);
  const [index, setIndex] = useState<number>(0);
  const [selectedBank, setSelectedBank] = useState<any>();
  const dispatch = useDispatch();
  const primaryCopy = index + 1 === myAccounts.length ? "finish" : "nextBank";
  const secondaryCopy = myAccounts.length > 1 && index + 1 === myAccounts.length && "prevBank";

  // TO DO - Handle formGood
  // Probs need to add a flag as to whether an account has been set
  const formGood = Boolean(false);

  useEffect(() => {
    setSelectedBank(myAccounts[index]);
  }, [myAccounts, index]);

  const handlePrevious = () => {
    const curInd = index;
    setIndex(curInd - 1);
    setSelectedBank(myAccounts[curInd - 1]);
  };

  const handleNext = () => {
    const curInd = index;
    if (curInd + 1 < myAccounts.length) {
      setIndex(curInd + 1);
      setSelectedBank(myAccounts[curInd + 1]);
      return;
    }
    handleNextScreen();
  };

  const handleNextScreen = async () => {
    const nextScreen = (await dispatch(finishOnboarding())) as any;
    if (nextScreen) history.push(nextScreen);
  };

  return (
    <Template
      header={
        <OnboardingNavHeader
          defaultHref={PAGE_URLS.SIGNUP_LINK_ACCTS}
          pageLocation={pageLocation}
          showBackButton
          desktopTitle={<OnboardingProgress step={7} />}
        />
      }
      primaryCopy={primaryCopy}
      handlePrimary={handleNext}
      primaryTestId="shareSettings_finishSharing"
      onboardingStep={7}
      secondaryCopy={secondaryCopy || ""}
      showSecondary={Boolean(secondaryCopy)}
      handleSecondary={handlePrevious}
    >
      <OnboardingCopy title="shareSettings_1" />
      <ShareSettingsAccountList selectedBank={selectedBank} index={index} />
    </Template>
  );
};
