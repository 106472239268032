import React, { useEffect, useState } from "react";
import { IonGrid, IonRow, IonCol, IonButton, IonAlert } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { RULE_FIELD_NAMES_DISPLAY } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountsState,
  getCategoryState,
  getTransactionRulesState,
  getUsersState,
} from "../../../redux/selectors";
import { post } from "../../../utils/apiOps";
import { setLoading } from "../../../redux/actions";
import {
  RuleItem,
  RuleUpdateInfoField,
  TransactionRuleTypes,
} from "../../../types/transactionRules";
import { findAccountById } from "../../../redux/reducers/accounts";
import { getRuleSharedStatus, getRulesText } from "../../../utils/transactions";
import { getCatDisplayName, getSubcatDisplayName } from "../../../utils/categorizationUtils";
import "./ManageRulesDetail.scss";
import { cloneDeep } from "lodash";
import { getTransactionRules } from "../../../redux/actions/transactionRules";
import Avatars from "../../shared/Avatar/Avatars";
import { TransactionRulesState } from "../../../redux/reducers/transactionRules";

interface RuleDetailItemProps {
  ruleId: string;
  showDelete?: boolean;
}

export const RuleDetailItem: React.FC<RuleDetailItemProps> = ({ ruleId, showDelete = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userId } = useSelector(getUsersState);
  const { allBalances } = useSelector(getAccountsState);
  const { subCategoryData, categoryData } = useSelector(getCategoryState);
  const { rulesData }: TransactionRulesState = useSelector(getTransactionRulesState);
  const [rule, setRule] = useState<RuleItem>();
  const [showRemoveRuleAlert, setShowRemoveRuleAlert] = useState(false);

  useEffect(() => {
    if (!ruleId || !rulesData) {
      return;
    }
    const thisRule = rulesData.flatMap((a) => a.rules).find((r) => r._id === ruleId);
    if (thisRule) {
      const cleanRule = cloneDeep(thisRule);
      // @ts-ignore
      cleanRule.ruleText = getRulesText(cleanRule, categoryData, subCategoryData, t);
      const info = getRuleSharedStatus(cleanRule.updateInfo, userId);
      cleanRule.isOwner = info.isOwner;
      cleanRule.privType = info.privType;
      cleanRule.avatarDisplay = info.avatarDisplay;
      cleanRule.accountInfo = findAccountById(allBalances, cleanRule.accountId);
      setRule(cleanRule);
    }
  }, [rulesData, ruleId, allBalances, subCategoryData, categoryData]);

  return rule ? (
    <>
      <IonRow className="RuleDetailItem ion-padding-vertical">
        <IonCol size={`${showDelete ? "9" : "12"}`}>
          <IonGrid>
            <IonRow>
              <IonCol className="font-sm">
                {t("allTransactions")}{" "}
                {rule.accountInfo && t("fromYour") + " " + rule.accountInfo.accountName}{" "}
                {rule.ruleText} {t("willBe")}{" "}
                {rule.type === TransactionRuleTypes.YOM ? t("marked") : t("categorized")} {t("as")}:
              </IonCol>
            </IonRow>

            {rule.type === TransactionRuleTypes.YOM ? (
              <IonRow>
                <IonCol size="6">{t("person")}</IonCol>
                <IonCol size="6">
                  <Avatars sizeStyle="sm" avatarType={rule.avatarDisplay} alignment="end" />
                </IonCol>
              </IonRow>
            ) : (
              rule.updateInfo.map((info, ind2) => (
                <IonRow key={ind2}>
                  <IonCol size="6" className="font-sm">
                    {
                      // @ts-ignore
                      RULE_FIELD_NAMES_DISPLAY[info.field.toLowerCase()].long
                    }
                  </IonCol>
                  <IonCol size="6" className="font-sm ion-text-end">
                    {info.field === RuleUpdateInfoField.CATEGORY
                      ? getCatDisplayName(String(info.value), categoryData)
                      : getSubcatDisplayName(String(info.value), subCategoryData)}
                  </IonCol>
                </IonRow>
              ))
            )}
          </IonGrid>
        </IonCol>
        {showDelete && (
          <IonCol size="3" className="ion-align-self-center font-sm">
            <IonButton
              fill="clear"
              color="danger"
              size="small"
              onClick={() => setShowRemoveRuleAlert(true)}
            >
              {t("delete")}
            </IonButton>
          </IonCol>
        )}
      </IonRow>

      <IonAlert
        isOpen={showRemoveRuleAlert}
        onDidDismiss={() => setShowRemoveRuleAlert(false)}
        header={t("areYouSure")}
        message={t("removeRuleAlert_msg")}
        buttons={[
          {
            text: t("no"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: t("yes"),
            role: "destructive",
            handler: () => {
              dispatch(setLoading(true));
              post(true, {
                endpoint: "/rules/deleteAllOrOne",
                bodyData: {
                  ruleId: rule._id,
                },
              })
                .then(() => {
                  dispatch(getTransactionRules());
                })
                .catch()
                .finally(() => {
                  dispatch(setLoading(false));
                });
            },
          },
        ]}
      />
    </>
  ) : null;
};
