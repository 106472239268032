import { put } from "./apiOps";

interface updateShareSettingsBodyData {
  accountIds: Array<string>;
  accountStatus: string;
  ownership: string;
  balVisibility: boolean;
}

/**
 * Update the Share settings for an account
 * @param params
 */
export const updateShareSettings = async (bodyData: updateShareSettingsBodyData) => {
  return await put(true, {
    endpoint: "/user/edit/accountPrivilege",
    bodyData,
  });
};

export const getAccountSettingType = function (value: string | undefined) {
  switch (value) {
    case "1":
      return {
        name: "VISIBLE",
        primary: "personal",
        shareTrans: true,
        value: "1",
      };
    case "3":
      return {
        name: "IGNORE",
        primary: "ignore",
        shareTrans: false,
        value: "3",
      };
    case "4":
      return {
        name: "JUST ME",
        primary: "personal",
        shareTrans: false,
        value: "4",
      };
    default:
      return {
        name: "SHARED",
        primary: "household",
        shareTrans: true,
        value: "0",
      };
  }
};
