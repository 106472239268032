import React, { useState } from "react";
import {
  IonButton,
  IonRadioGroup,
  IonRadio,
  IonItem,
  IonFooter,
  IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";

import { GOAL_RULES, GOAL_STATUS, PAGE_URLS } from "../../../constants";
import { Goal } from "../../../types";
import { useCurrentGoal } from "../../../utils/useCurrentGoal";
import { ExitAlert } from "./ExitAlert";
import { NavHeader, PageTemplate, Section } from '../../shared/PageTemplate';
import { useViewport } from '../../../hooks/useViewport';
import { HelpButton } from "../../shared/Support/HelpButton";

type Rule = "ADHOC" | "WEEKLY" | "BIWEEKLY";

export const HowToSave: React.FC = () => {
  const goal = useCurrentGoal() as Goal;
  const { isMobile } = useViewport();
  const [selectedRule, setSelectedRule] = useState<Rule>("ADHOC");
  const { t } = useTranslation();

  const footerSection = <IonFooter className="ion-no-border ion-text-center">
    <IonButton
      className="ion-margin"
      color="primary"
      data-testid="savingStrategyButton"
      routerLink={nextPage(goal, selectedRule)}
      routerDirection="forward"
    >
      {t("next")}
    </IonButton>
  </IonFooter>

  return (
    <PageTemplate
      pageProps={{ className: "how-to-save" }}
      header={<NavHeader title={t("savingsGoal")} backButton={goal.status === GOAL_STATUS.NEW ? <ExitAlert /> : undefined}>
        <HelpButton pageLocation="goal-how-save" className="fy-nav-buttons" color="" />
      </NavHeader>}
      footer={isMobile ? footerSection : undefined}
    >

      <Section>
        <h1 className="form-header ion-padding">
          {t("saveForGoalRule")}
        </h1>
        <IonRadioGroup value={selectedRule} onIonChange={(e) => setSelectedRule(e.detail.value)}>
          {GOAL_RULES.map((rule) => (
            <IonItem
              lines="none"
              key={rule.frequency}
              className={rule.frequency === selectedRule ? "selected" : ""}
            >
              <label htmlFor={rule.frequency} className="ion-margin-bottom">
                <IonText slot="start">
                  <h2>{rule.name}</h2>
                  {rule.description}
                </IonText>
              </label>
              <IonRadio slot="end" value={rule.frequency} id={rule.frequency} />
            </IonItem>
          ))}
        </IonRadioGroup>
        {isMobile ? null : footerSection}
      </Section>
    </PageTemplate>
  );
};

const nextPage = (goal: Goal, selectedRule: Rule) => {
  return (selectedRule === "ADHOC"
    ? PAGE_URLS.GOALS_FUNDING_PAYDAY
    : PAGE_URLS.GOALS_FUNDING_SCHEDULE
  )
    .replace(":goalId", goal?.id)
    .concat(`?frequency=${selectedRule}`);
};
