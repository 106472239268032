import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
  useIonAlert,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getBudgetAnalyticsState } from "../../../redux/selectors";
import dayjs from "dayjs";
import { BudgetItem, BudgetAdditionalData } from "../../../types";
import "./DataNotReadyCard.scss";
import { useDispatch } from "../../../redux/hooks";
import {
  getAccountData,
  getAllTransactions,
  getBills,
  getBudget,
  getSpendingByPersonData,
  getSpendingChallenges,
} from "../../../redux/actions";
import { useNavigation } from "../../../utils/useNavigation";
import { PAGE_URLS } from "../../../constants";
import { getSpendingForecastSummary } from "../../../redux/actions/spendingForecast";

const DataNotReadyCard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { budgetsArray } = useSelector(getBudgetAnalyticsState);
  const [present] = useIonAlert();
  const { navigate } = useNavigation();

  // state
  const [additionalData, setAdditionalData] = useState<BudgetAdditionalData>();
  const [refresherCount, setRefresherCount] = useState<number>(0);

  useEffect(() => {
    if (refresherCount > 10) {
      return;
    }

    const effectiveDate = dayjs().startOf("month").format("YYYY-MM-DD");
    const budgetAnalyticsForThisDate = budgetsArray.find(
      (item: BudgetItem) => item.budgetAnalyticsDate === effectiveDate
    );
    if (!budgetAnalyticsForThisDate) {
      return;
    }
    setAdditionalData(budgetAnalyticsForThisDate.additionalData);
    if (budgetAnalyticsForThisDate.additionalData?.pendingTokens?.status) {
      setRefresherCount(refresherCount + 1);
      setTimeout(() => {
        dispatch(
          getBudget({
            effectiveDate,
            forceRefresh: true,
          })
        );
      }, 30000);
    } else if (refresherCount > 0) {
      Promise.all([
        dispatch(getAccountData()),
        dispatch(getAllTransactions()),
        dispatch(
          getBills({
            effectiveDate: dayjs().startOf("month").format("YYYY-MM-DD"),
            forceRefresh: true,
          })
        ),
        dispatch(getSpendingForecastSummary()),
        dispatch(getSpendingByPersonData()),
        dispatch(getSpendingChallenges()),
      ])
        .catch(() => {})
        .finally(() => {
          present({
            header: t("dataReadyAlertTitle"),
            message: t("dataReadyAlertMsg"),
            buttons: [
              {
                text: t("cancel"),
                role: "cancel",
              },
              {
                text: t("okay"),
                handler: () => navigate(PAGE_URLS.HOME),
              },
            ],
          });
          setRefresherCount(0);
        });
    }
  }, [budgetsArray]);

  return additionalData?.pendingTokens?.status ? (
    <IonCard id="DataNotReadyCard">
      <IonCardContent className="ion-text-center">
        <IonSpinner />
        <IonGrid>
          <IonRow>
            <IonCol className="font-title-3 ion-text-center">{t("dataNotReadyTitle")}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="font-md ion-text-center">{t("dataNotReadyMsg")}</IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  ) : null;
};

export default DataNotReadyCard;
