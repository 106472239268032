import React from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
  IonButton,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { PAGE_URLS } from "../../../constants";
import images from "../../../assets/images/illustrations";
import "./BillsAuditCard.scss";
import { trackEvent } from "../../../vendors/monitoring";

const BillsAuditCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonCard id="BillsAuditCard">
      <IonCardHeader className="ion-text-center">
        <img id="BillsAuditCardImg" src={images.billsAudit} alt="bills-audit" />
        <IonCardTitle>{t("billsAuditHomeCardMain")}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <p className="ion-text-center font-md ion-padding-bottom">{t("billsAuditHomeCardSub")}</p>
        <IonButton
          expand="block"
          size="large"
          routerLink={PAGE_URLS.BILLS_AUDIT_MAIN}
          data-testid="bills_audit_card"
          className="font-lg ion-no-margin"
          onClick={() => trackEvent("ba_getStarted", { category: "analyzeBills" })}
        >
          {t("billsAuditHomeCardButton")}
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default BillsAuditCard;
