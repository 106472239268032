import React, { useState, useEffect } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonAvatar,
  IonIcon,
  IonAlert,
  IonButton,
} from "@ionic/react";
import { getCatDisplayName, getSubcatDisplayName } from "../../../utils/categorizationUtils";
import { transAccountNameHelper, transNameHelper } from "../../../utils/transUtils";
import {
  happyOutline,
  chatbubbleOutline,
  gitNetwork,
  flagOutline,
  repeatOutline,
} from "ionicons/icons";
import Amount from "../../shared/Amount/Amount";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Avatars from "../../shared/Avatar/Avatars";
import { Reactions } from "../../shared/Reactions/Reactions";
import { angry, like, sad, love, wow, happy } from "../../../assets/images/reactions";
import { saveReaction, removeReaction } from "../../../utils/transactions";
import {
  SaveReaction,
  RemoveReaction,
  ReactionType,
  TransactionModalType,
  TransactionStatus,
} from "../../../types/transactions";
import { Recategorization } from "./Recategorization";
import { Transaction as typeTransaction } from "../../../types/transactions";
import { getAccountsState, getCategoryState, getUsersState } from "../../../redux/selectors";
import "./Transaction.scss";
import { useHistory } from "react-router-dom";
import { AVATAR_DISPLAY, PAGE_URLS } from "../../../constants";
import { Comments } from "../../shared/Comments/Comments";
import { EditSharedStatus } from "./EditSharedStatus";
import { UnmarkBillAlert } from "../Budget/UnmarkBillAlert";
import { MarkTransactionAsBillModal } from "../Budget/MarkTransactionAsBillModal";
import { CreateRuleModal } from "./CreateRuleModal";
import { TransactionRuleTypes } from "../../../types/transactionRules";
import { useUserName } from "../../shared/User/useUserName";
import { useInvitePrompt } from "../../../hooks/useInvitePrompt";
import { ActionSheet } from "../../shared/PageTemplate/ActionSheet";
import { useViewport } from "../../../hooks/useViewport";
import { categoryState } from "../../../redux/reducers/category";
import { trackEvent } from "../../../vendors/monitoring";

interface ContainerProps {
  transaction: typeTransaction;
}

const Transaction: React.FC<ContainerProps> = (props) => {
  const trans = props.transaction;
  const { t } = useTranslation();
  const { isDesktop } = useViewport();
  const history = useHistory();
  const { singlePlayerMode } = useUserName();
  const openInvitePrompt = useInvitePrompt();
  //data

  const goToDetailPage = (e?: any) => {
    trackEvent("view_detailTrans", { category: "detailTrans" });
    e && e.stopPropagation(); // refactoring as e?.stopPropagation() created an linting error
    history.push(PAGE_URLS.TRANS_DETAILS.replace(":transactionId", trans._id));
  };
  const actionSheetButtons = [
    {
      text: t("changeCategory"),
      handler: () => {
        setOpenModal(TransactionModalType.RECATEGORIZATION);
      },
    },
    {
      text: t(trans.isBill ? "markAsNotABill" : "markAsBill"),
      handler: () => {
        trackEvent("view_markAsBill", { category: "markAsBill" });
        if (trans.isBill) {
          setShowUnmarkBillPaid(true);
        } else {
          setShowMarkAsBillModal(true);
        }
      },
    },
    {
      text: t("splitTransaction"),
      handler: () => {
        trackEvent("view_splitTrans", { category: "splitTrans" });
        trans.status === "PENDING"
          ? setShowSplitTransactionAlert(true)
          : history.push(PAGE_URLS.SPLIT_TRANS.replace(":transactionId", trans._id));
        trackEvent("view_splitTrans", { category: "splitTrans" });
      },
    },
    {
      text: t("viewDetails"),
      handler: () => {
        goToDetailPage();
        trackEvent("view_detailTrans", { category: "detailTrans" });
      },
    },
    {
      text: t("cancel"),
      role: "cancel",
      handler: () => {
        console.log("Cancel clicked");
      },
    },
  ];

  //redux state
  const { categoryData, subCategoryData }: categoryState = useSelector(getCategoryState);
  const accountsState = useSelector(getAccountsState);
  const { userId } = useSelector(getUsersState);

  //state
  const [showActionSheet, setShowActionSheet] = useState<boolean>(false);
  const [showUnmarkBillPaid, setShowUnmarkBillPaid] = useState(false);
  const [showMarkAsBillModal, setShowMarkAsBillModal] = useState(false);

  const [reactionOpen, setReactionOpen] = useState({ showPopover: false, event: undefined });
  const [selectedReaction, setSelectedReaction] = useState<ReactionType>(
    trans.reactions[0]
      ? trans.reactions.find((reaction) => reaction.userId === userId)?.reaction || ""
      : ""
  );
  const [reactionId, setReactionId] = useState(trans.reactions[0] ? trans.reactions[0]._id : "");
  const [openModal, setOpenModal] = useState<TransactionModalType>();
  const [showSplitTransactionAlert, setShowSplitTransactionAlert] = useState(false);

  const selectedEmoji = { angry, like, sad, love, wow, happy, "": undefined }[selectedReaction];
  const activeOffer = trans?.offers?.find((offer) => offer.status === "active");

  useEffect(() => {
    if (trans.reactions[0]) {
      setSelectedReaction(
        trans.reactions.find((reaction) => reaction.userId === userId)?.reaction || ""
      );
      setReactionId(trans.reactions[0]._id);
    }
  }, [trans]);

  //methods
  const showRelatedActionSheetButtons = () => {
    if (trans.isChild && trans.status !== "PENDING") {
      let newActionSheetButtons = [...actionSheetButtons];
      newActionSheetButtons.splice(2, 1);
      return newActionSheetButtons;
    } else {
      return actionSheetButtons;
    }
  };

  const handleReactionSelect = (event: any) => {
    event.stopPropagation();
    let bodyData;
    if (!selectedReaction) {
      setSelectedReaction(event.target.alt);
      setReactionOpen({ showPopover: false, event: undefined });
      bodyData = {
        reaction: event.target.alt,
        transactionId: trans._id,
        isPrivate: "both",
      } as SaveReaction;
      saveReaction(bodyData, setReactionId);
    } else {
      setSelectedReaction("");
      setReactionOpen({ showPopover: false, event: undefined });
      bodyData = {
        reaction: selectedReaction,
        reactionId: reactionId,
        transactionId: trans._id,
        isPrivate: "both",
      } as RemoveReaction;
      removeReaction(bodyData, setReactionId);
    }
  };

  const formatMerchantName = (trans: typeTransaction) => {
    const displayName = transNameHelper(trans);
    if (displayName.length > 50) {
      return displayName.substring(0, 47) + "...";
    }
    return displayName;
  };

  return (
    <>
      <IonItem
        button
        onClick={isDesktop ? goToDetailPage : () => setShowActionSheet(true)}
        key={trans._id}
        lines="none"
        detail={false}
        className="transaction"
        data-testid="transactionsItem"
      >
        <IonAvatar
          slot="start"
          onClick={(e) => {
            trackEvent("view_transOwner", { category: "transOwner" });
            e.stopPropagation();
            singlePlayerMode
              ? openInvitePrompt()
              : setOpenModal(TransactionModalType.EDIT_SHARED_STATUS);
          }}
          className="ion-no-margin"
          data-testid="transactionEditSharedStatus"
        >
          <Avatars
            hhInfoType={singlePlayerMode ? undefined : "transaction"}
            avatarType={singlePlayerMode ? AVATAR_DISPLAY.ME : undefined}
            hhInfo={trans.hhInfo[0]}
            sizeStyle="sm"
            diagonal={true}
          />
        </IonAvatar>
        <IonGrid>
          <IonRow data-testid="transactionActionSheet" className="ion-align-items-center">
            {trans.isChild ? (
              <IonCol size="1">
                <IonIcon icon={gitNetwork} />
              </IonCol>
            ) : null}
            {trans.isBill ? (
              <IonCol size="1">
                <IonIcon icon={repeatOutline} />
              </IonCol>
            ) : null}
            <IonCol>
              <h3 className="font-md transaction-items-header ion-no-margin">
                {formatMerchantName(trans)}
              </h3>
            </IonCol>
            <IonCol size="3.5" sizeXs="4" className="ion-text-end">
              <h6 className="font-md transaction-items-amount ion-no-margin">
                <Amount baseType={trans.baseType} amount={trans.amount.amount} showCents />
              </h6>
            </IonCol>
            <ActionSheet
              isOpen={showActionSheet}
              onDidDismiss={() => setShowActionSheet(false)}
              header={trans.description.original}
              subHeader={`${t("account")}: ${
                showActionSheet && transAccountNameHelper(accountsState, trans.accountId)
              }`}
              buttons={showRelatedActionSheetButtons()}
              buttonProps={{ className: "ion-margin-start hidden-mobile" }}
            />
          </IonRow>
          <IonRow className="transaction-items-category-row">
            <IonCol>
              <h6 className="font-sm placeholder-text transaction-items-header ion-no-margin">
                {t(getCatDisplayName(trans.hhInfo[0].beefCategoryName, categoryData) || "category")}
                {" | "}
                {t(
                  getSubcatDisplayName(trans.hhInfo[0].honeyfiCategoryName, subCategoryData) ||
                    "subCategory"
                )}
              </h6>
            </IonCol>
            {trans.status === TransactionStatus.PENDING ? (
              <IonCol size="3.5" className="ion-text-end">
                <h6 className="font-sm placeholder-text transaction-items-header ion-no-margin">{`(${t(
                  "pending"
                )})`}</h6>
              </IonCol>
            ) : null}
          </IonRow>
          {reactionOpen ? (
            <Reactions
              onDidDismiss={() => setReactionOpen({ showPopover: false, event: undefined })}
              handleReactionSelect={handleReactionSelect}
              isOpen={reactionOpen.showPopover}
              event={reactionOpen.event}
            />
          ) : (
            ""
          )}
          <IonRow>
            <IonCol>
              {!selectedReaction ? (
                <IonIcon
                  icon={happyOutline}
                  className="transaction-items-icon"
                  onClick={(e: any) => {
                    e.persist();
                    e.stopPropagation();
                    setReactionOpen({ showPopover: true, event: e });
                  }}
                  data-testid="transactionReactionIcon"
                ></IonIcon>
              ) : (
                <div
                  className="transaction-items-reaction"
                  onClick={handleReactionSelect}
                  data-testid="transactionReactionEmoji"
                >
                  <img src={selectedEmoji} alt={selectedReaction} />
                </div>
              )}
              <IonIcon
                icon={chatbubbleOutline}
                className="transaction-items-icon"
                onClick={goToDetailPage}
              ></IonIcon>

              {trans.rules && trans.rules.length > 0 && (
                <IonIcon
                  icon={flagOutline}
                  data-testid="IonIcon_flag"
                  className="transaction-items-icon"
                  onClick={goToDetailPage}
                ></IonIcon>
              )}
            </IonCol>
            {activeOffer ? (
              <IonCol className="ion-text-end">
                <IonButton
                  fill="clear"
                  className="ion-no-margin ion-no-padding"
                  routerLink={PAGE_URLS.OFFER_DETAILS.replace(":offerId", activeOffer.offersId)}
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={activeOffer.logo}
                    alt={`icon for "${activeOffer.cta}" button`}
                    className="offer-icon"
                  />
                </IonButton>
              </IonCol>
            ) : null}
          </IonRow>
          {trans.comments.length ? (
            <IonRow>
              <Comments transaction={trans} clickEvents="open" onTransactionTab />
            </IonRow>
          ) : null}
          {trans.comments.length > 2 ? (
            <IonRow onClick={goToDetailPage}>
              <p className="font-xs">{`${t("viewAll")} ${trans.comments.length} ${t(
                "commentsWord"
              )}`}</p>
            </IonRow>
          ) : null}
        </IonGrid>
      </IonItem>
      {openModal === TransactionModalType.RECATEGORIZATION ? (
        <Recategorization
          transaction={trans}
          onClose={(newState: TransactionModalType) => setOpenModal(newState)}
          sendApi={true}
        />
      ) : null}
      {openModal === TransactionModalType.EDIT_SHARED_STATUS ? (
        <EditSharedStatus
          onClose={(newState: TransactionModalType) => setOpenModal(newState)}
          hhInfo={trans.hhInfo[0]}
          transactionOwner={trans.transactionOwner}
          transactionId={trans._id}
        />
      ) : null}
      {openModal === TransactionModalType.CATEGORY_RULE ||
      openModal === TransactionModalType.SHARED_STATUS_RULE ? (
        <CreateRuleModal
          isOpen={true}
          transactionRuleType={
            openModal === TransactionModalType.CATEGORY_RULE
              ? TransactionRuleTypes.CATEGORY
              : TransactionRuleTypes.YOM
          }
          transactionId={trans._id}
          onClose={() => setOpenModal(TransactionModalType.NONE)}
        />
      ) : null}

      <IonAlert
        isOpen={showSplitTransactionAlert}
        onDidDismiss={() => setShowSplitTransactionAlert(false)}
        header={"Unable to split"}
        message={"Only posted transactions can be split. Check back soon"}
        buttons={[t("gotIt")]}
      />
      {trans.isBill && trans.bills && trans.bills.length > 0 ? (
        <UnmarkBillAlert
          billId={trans.bills[0].billId}
          transactionId={trans._id}
          paidSource={"transaction"}
          paidDate={trans.bills[0].billMonth}
          isOpen={showUnmarkBillPaid}
          onClose={() => setShowUnmarkBillPaid(false)}
        />
      ) : null}
      <MarkTransactionAsBillModal
        transactionId={trans._id}
        transactionBaseType={trans.baseType}
        transactionDate={trans.date}
        transactionName={transNameHelper(trans)}
        transactionAmount={trans.amount.amount}
        isOpen={showMarkAsBillModal}
        onClose={() => setShowMarkAsBillModal(false)}
      />
    </>
  );
};

export default Transaction;
