import React from 'react'
import { IonTitle, IonText, IonList, IonPage, IonFooter, IonButton, IonLabel } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import { useUserName } from '../../shared/User/useUserName'
import { useCurrentGoal } from '../../../utils/useCurrentGoal';
import { Goal, AccountInfo } from '../../../types/goals';
import numeral from 'numeral'
import { getContributionText, getContributionAmt } from '../../../utils/goalUtils';
import { useSelector } from '../../../redux/hooks'
import { getGoalsState } from '../../../redux/selectors';
import { useViewport } from '../../../hooks/useViewport';
import { PageTemplate, Section } from '../../shared/PageTemplate';
import { NavHeader } from '../../shared/PageTemplate/NavHeader';
import { PAGE_URLS } from '../../../constants';
import { Field } from '../../shared/Field/Field';

export const PartnerOverview: React.FC = props => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const { partnerName } = useUserName();
  const goal = useCurrentGoal() as Goal;
  const { accountInfo } = useSelector(getGoalsState);

  if (!(goal as Goal).name) return <IonPage />;

  const footerSection = <IonFooter className="ion-no-border ion-text-center">
    <IonButton
      routerLink={getNextRoute(accountInfo)}
      routerDirection="forward"
      className="ion-margin"
      data-testid="partnerOverviewNext"
    >
      {t("next")}
    </IonButton>
  </IonFooter>

  return <PageTemplate
    pageProps={{ className: "partner-overview" }}
    header={<NavHeader title={t("savingsGoal")} defaultHref={PAGE_URLS.GOALS_HOME} />}
    footer={isMobile ? footerSection : undefined}
  >
    <Section>
      <IonList>
        <h2 className="ion-padding-start fy-section-title-border font-title-3">
          {t("startSavingWithPartner", { partnerName })}
        </h2>
        <p className="ion-padding ion-margin-end">
          {t("partnerCreatedAGoal", { partnerName })}
        </p>
        <Field label={<IonLabel position="stacked" className="ion-padding-top">{t("goalName")}</IonLabel>}>
          <IonText>{goal.name}</IonText>
        </Field>
        <Field label={<IonLabel position="stacked" className="ion-padding-top">{t("targetAmount")}</IonLabel>}>
          <IonText>{numeral(goal.targetAmount).format("$0,0")}</IonText>
        </Field>
        <Field label={<IonLabel position="stacked" className="ion-padding-top">{t("howPartnersSaving", { partnerName })}</IonLabel>}>
          <IonText>
            {getContributionText(goal.nonViewerFundingInfo!)}
            &nbsp;
            <small>
              ({getContributionAmt(goal.nonViewerFundingInfo!)})
            </small>
          </IonText>
        </Field>
      </IonList>
      {isMobile ? null : footerSection}
    </Section>
  </PageTemplate>
}

const getNextRoute = (accountInfo: AccountInfo) => {
  if (!accountInfo.hasSavingsAccount) {
    return "funding-setup-start";
  } else if (!accountInfo.hasExternalFundingAccount) {
    return "funding-setup-choose-account";
  } else {
    return "new-goal-how-save";
  }
}