import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonToolbar,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonList,
  IonFooter,
} from "@ionic/react";
import { calendarOutline, closeOutline, createOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { NewTransaction } from "../../../types";
import dayjs from "dayjs";
import { getOwnedOrSharedAccounts, getCategoryState } from "../../../redux/selectors";
import { useDispatch } from "react-redux";
import { createNewTransaction, setLoading } from "../../../redux/actions";
import { getAllSubCategory, getRelatedSubCategory } from "../../../utils/transactions";
import { Account, AccountSource, accountState } from "../../../redux/reducers/accounts";
import { FINANCIAL_ACCOUNT_STATUS } from "../../../constants";
import { useSelector } from "../../../redux/hooks";
import { useResponsiveInterface } from "../../../hooks/useResponsiveInterface";
import { DatePicker } from "../../shared/DatePicker/DatePicker";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import { Field } from "../../shared/Field/Field";
import "./CreateNewTransaction.scss";
import { startCase } from "lodash";
import { trackEvent } from "../../../vendors/monitoring";

interface NewTransactionProps {
  createNewTransactionOpen: boolean;
  handleCreateNewTransactionClose: () => void;
}

export const CreateNewTransaction: React.FC<NewTransactionProps> = (props) => {
  const { t } = useTranslation();
  const _interface = useResponsiveInterface();
  const { createNewTransactionOpen, handleCreateNewTransactionClose } = props;
  const dispatch = useDispatch();

  //redux state
  const accounts = useSelector(getOwnedOrSharedAccounts);
  const { categoryData, subCategoryData } = useSelector(getCategoryState);

  //data
  const defaultNewTransaction = {
    name: "",
    amount: "",
    date: dayjs(Date.now()).format("MM/DD/YYYY"),
    baseType: "DEBIT",
    accountId: "",
    category: "",
    subCategory: "",
  };

  //state
  const [newTransactionParams, setNewTransactionParams] = useState(defaultNewTransaction);
  const [newTransactionParamsChange, setNewTransactionParamsChange] = useState(false);
  const [resetBtnClick, setResetBtnClick] = useState(false);

  useEffect(() => {
    if (resetBtnClick) {
      setNewTransactionParams(defaultNewTransaction);
      setNewTransactionParamsChange(false);
      setResetBtnClick(false);
    }
  }, [resetBtnClick]);

  const checkSliceValue = (slice: any) => {
    return !!slice.name || !!slice.amount || slice.baseType
      ? slice.baseType !== "DEBIT"
      : false || slice.date
        ? slice.date !== dayjs(Date.now()).format("MM/DD/YYYY")
        : false || !!slice.accountId || !!slice.category || !!slice.subCategory;
  };

  const handleParamsChange = (slice: Partial<NewTransaction>) => {
    if (!resetBtnClick && checkSliceValue(slice)) {
      setNewTransactionParamsChange(true);
      setNewTransactionParams({ ...newTransactionParams, ...slice });
    } else if (!resetBtnClick && newTransactionParamsChange) {
      setNewTransactionParams({ ...newTransactionParams, ...slice });
    } else {
      setNewTransactionParamsChange(false);
    }
  };

  const handleNewTransactionParamsReset = () => {
    setResetBtnClick(true);
    setNewTransactionParams(defaultNewTransaction);
  };

  const handleCreateNewTransaction = () => {
    dispatch(setLoading(true));
    try {
      dispatch(createNewTransaction(newTransactionParams));
      handleCreateNewTransactionClose();
      setResetBtnClick(true);
      setNewTransactionParams(defaultNewTransaction);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  const validateCreateNewTransaction = () => {
    return (
      !!newTransactionParams.accountId &&
      !!newTransactionParams.name &&
      !!newTransactionParams.amount &&
      !!newTransactionParams.category &&
      !!newTransactionParams.subCategory
    );
  };

  return (
    <IonModal
      id="CreateNewTransaction"
      isOpen={createNewTransactionOpen}
      onDidDismiss={handleCreateNewTransactionClose}
    >
      <ModalHeader
        title={t("addTransaction")}
        startBtnOnClick={() => handleCreateNewTransactionClose()}
        startBtnIcon={closeOutline}
        endBtnOnClick={newTransactionParamsChange && handleNewTransactionParamsReset}
        endBtnText={newTransactionParamsChange ? t("reset") : ""}
      />
      <IonContent fullscreen>
        <IonList>
          <Field label={t("name")} icon={createOutline}>
            <IonInput
              placeholder={`(${startCase(t("enter"))})`}
              className="transaction-search-input-field"
              onIonChange={(e) => handleParamsChange({ name: e.detail.value || "" })}
              value={newTransactionParams.name}
              data-testid="createTransactionName"
            />
          </Field>

          <Field label={t("amount")} icon={createOutline}>
            <IonInput
              placeholder="$20"
              className="transaction-search-input-field"
              onIonChange={(e) => handleParamsChange({ amount: e.detail.value || "" })}
              value={newTransactionParams.amount || ""}
              type="number"
              data-testid="createTransactionAmount"
            />
          </Field>

          <Field label={t("date")} icon={calendarOutline}>
            <DatePicker
              displayFormat="MM/DD/YYYY"
              value={newTransactionParams.date}
              data-testid="createTransactionDate"
              onChange={(date) =>
                handleParamsChange({
                  date: dayjs(date || "").format("MM/DD/YYYY"),
                })
              }
            />
          </Field>

          <Field label={t("type")}>
            <IonSelect
              value={newTransactionParams.baseType}
              onIonChange={(e) => handleParamsChange({ baseType: e.detail.value || "" })}
              interface={_interface}
              interfaceOptions={{
                id: "createTransactionType",
              }}
              data-testid="createTransactionType"
            >
              <IonSelectOption value="CREDIT">{t("credit")}</IonSelectOption>
              <IonSelectOption value="DEBIT">{t("debit")}</IonSelectOption>
            </IonSelect>
          </Field>

          <Field label={t("account")}>
            <IonSelect
              value={newTransactionParams.accountId}
              onIonChange={(e) => handleParamsChange({ accountId: e.detail.value || "" })}
              interface={_interface}
              interfaceOptions={{
                id: "createTransactionAccount",
              }}
              data-testid="createTransactionAccount"
            >
              <IonSelectOption value="">({t("select")})</IonSelectOption>
              {accounts.map((account) => {
                return (
                  <IonSelectOption value={account._id} key={account._id}>
                    {account.accountNickname || account.accountName}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </Field>

          <Field label={t("category")}>
            <IonSelect
              value={newTransactionParams.category}
              onIonChange={(e) => handleParamsChange({ category: e.detail.value || "" })}
              interface={_interface}
              interfaceOptions={{
                id: "createTransactionCategory",
              }}
              data-testid="createTransactionCategory"
            >
              <IonSelectOption value="">({t("select")})</IonSelectOption>
              {categoryData
                .filter((category: any) => !category.isDeleted)
                .map((category: any, index: number) => {
                  return (
                    <IonSelectOption value={category.categoryShortName} key={index}>
                      {category.categoryFullName}
                    </IonSelectOption>
                  );
                })}
            </IonSelect>
          </Field>

          <Field label={t("subCategory")}>
            <IonSelect
              value={newTransactionParams.subCategory}
              onIonChange={(e) => handleParamsChange({ subCategory: e.detail.value || "" })}
              interface={_interface}
              interfaceOptions={{
                id: "createTransactionSubcategory",
              }}
              data-testid="createTransactionSubcategory"
            >
              <IonSelectOption value="">({t("select")})</IonSelectOption>
              {!newTransactionParams.category
                ? getAllSubCategory(subCategoryData).map((subCategory: any, index: number) => {
                  return (
                    <IonSelectOption value={subCategory.subCategory} key={index}>
                      {subCategory.displayName}
                    </IonSelectOption>
                  );
                })
                : getRelatedSubCategory(subCategoryData, newTransactionParams.category).map(
                  (subCategory: any, index: number) => {
                    return (
                      <IonSelectOption value={subCategory.subCategory} key={index}>
                        {subCategory.displayName}
                      </IonSelectOption>
                    );
                  }
                )}
            </IonSelect>
          </Field>
        </IonList>
      </IonContent>
      {newTransactionParamsChange && (
        <IonFooter>
          <IonToolbar className="ion-text-center">
            <IonButton
              onClick={() => {
                trackEvent("save_addTrans", { category: "addTrans" });
                handleCreateNewTransaction();
              }}
              disabled={!validateCreateNewTransaction()}
              data-testid="createTransactionSave"
            >
              {t("save")}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      )}
    </IonModal>
  );
};
