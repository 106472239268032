import React, { useEffect, useState } from "react";
import { IonCol, IonGrid, IonList, IonRow } from "@ionic/react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryState } from "../../../redux/selectors";
import {
  BudgetItem,
  BudgetObj,
  BudgetSuperCategory,
  BudgetURLParams,
  Category,
} from "../../../types";
import { BUDGET_SUPER_CATEGORIES, PAGE_URLS } from "../../../constants";
import { getBudgetAnalyticsState } from "../../../redux/selectors";
import dayjs from "dayjs";
import { BudgetCardContent } from "./BudgetCard";
import { ShareSettingsButtonAndPopover } from "../../shared/User/ShareSettingsButtonAndPopover";
import { BudgetCategoryDetailItem } from "./BudgetCategoryDetailItem";
import { getBudget, setLoading } from "../../../redux/actions";
import { BudgetMonthHeader } from "./BudgetMonthHeader";
import { BudgetSubcategoryDetailItem } from "./BudgetSubcategoryDetailItem";
import numeral from "numeral";
import "./BudgetCategoryDetail.scss";
import { EditCategoriesModal } from "./EditCategoriesModal";
import { PageTemplate, ActionSheet, Section, NavHeader } from "../../shared/PageTemplate";
import { categoryState } from "../../../redux/reducers/category";
import { trackEvent } from "../../../vendors/monitoring";
import { EditCategoriesButton } from "./EditCategoriesButton";

export const BudgetCategoryDetail: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { effectiveDate, budgetType } = useParams<BudgetURLParams>();
  const { budgetsArray, loading } = useSelector(getBudgetAnalyticsState);
  const [selectedSuperCategory, setSelectedSuperCategory] = useState<BudgetSuperCategory>(
    BUDGET_SUPER_CATEGORIES.EXPENSES
  );
  const [showOverallBudget, setShowOverallBudget] = useState<boolean>(false);
  const [overallBudgetData, setOverallBudgetData] = useState<BudgetObj>();

  const { categoryData, subCategoryData }: categoryState = useSelector(getCategoryState);
  // @ts-ignore
  const incomeCategoryData: Category = categoryData.find(
    (c) => c.superCategory === BUDGET_SUPER_CATEGORIES.INCOME
  );
  const [showEditCategoriesModal, setShowEditCategoriesModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setLoading(loading));
  }, [loading, dispatch]);

  useEffect(() => {
    const budgetAnalyticsForThisDate = budgetsArray.find(
      (item: BudgetItem) => item.budgetAnalyticsDate === effectiveDate
    );
    if (!budgetAnalyticsForThisDate) {
      dispatch(getBudget({ effectiveDate: dayjs(effectiveDate).format("YYYY-MM-DD") }));
    } else {
      setOverallBudgetData(budgetAnalyticsForThisDate[budgetType]!.budget);
    }
  }, [budgetsArray, effectiveDate, budgetType]);

  const createActionSheetButtons = () => {
    const seeSpendingBtn = {
      text: t("seeSpending"),
      handler: () => {
        setShowOverallBudget(false);
        setSelectedSuperCategory(BUDGET_SUPER_CATEGORIES.EXPENSES);
      },
    };
    const seeIncomeBtn = {
      text: t("seeIncome"),
      handler: () => {
        setShowOverallBudget(false);
        setSelectedSuperCategory(BUDGET_SUPER_CATEGORIES.INCOME);
      },
    };
    const seeOverallBudgetBtn = {
      text: t("seeOverallBudget"),
      handler: () => setShowOverallBudget(true),
    };
    const buttons = [];
    if (showOverallBudget) {
      buttons.push(seeSpendingBtn, seeIncomeBtn);
    } else if (selectedSuperCategory === BUDGET_SUPER_CATEGORIES.INCOME) {
      buttons.push(seeSpendingBtn, seeOverallBudgetBtn);
    } else {
      buttons.push(seeIncomeBtn, seeOverallBudgetBtn);
    }

    return [
      ...buttons,
      {
        text: t("editCategories"),
        handler: () => {
          setShowEditCategoriesModal(true);
          trackEvent("view_editCategories", { category: "editCategories" });
        },
      },
      {
        text: t("cancel"),
        role: "cancel",
      },
    ];
  };

  return (
    <PageTemplate
      pageProps={{ id: "BudgetCategoryDetail" }}
      contentProps={{ fullscreen: true }}
      header={
        <NavHeader
          title={<BudgetMonthHeader effectiveDate={effectiveDate} budgetType={budgetType} />}
          desktopTitle={t("budget")}
          subheader="useTitle"
        >
          <ShareSettingsButtonAndPopover
            budgetType={budgetType}
            baseUrl={PAGE_URLS.BUDGET_HOME}
            effectiveDate={effectiveDate}
          />
        </NavHeader>
      }
    >
      <Section>
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol>
              <h1 className="font-title-3">
                {t(
                  showOverallBudget
                    ? "overallBudget"
                    : selectedSuperCategory === BUDGET_SUPER_CATEGORIES.EXPENSES
                      ? "totalSpending"
                      : "totalIncome"
                )}
              </h1>
            </IonCol>
            <IonCol className="ion-text-end">
              <ActionSheet
                header={t("budget")}
                buttons={createActionSheetButtons()}
                buttonProps={{ "data-testid": "BudgetCategoryDetail_superCategoryBtn" }}
              />
              <EditCategoriesModal
                isOpen={showEditCategoriesModal}
                onClose={() => setShowEditCategoriesModal(false)}
              />
            </IonCol>
          </IonRow>
          {showOverallBudget ? (
            <>
              <IonRow className="ion-margin-horizontal">
                <IonCol offset="4" size="4" className="ion-text-end">
                  {t("actual")}
                </IonCol>
                <IonCol size="4" className="ion-text-end">
                  {t("budget")}
                </IonCol>
              </IonRow>
              <IonRow className="font-md ion-margin-horizontal">
                <IonCol size="4">{t("income")}</IonCol>
                <IonCol size="4" className="ion-text-end">
                  {numeral(overallBudgetData?.Income?.amountActual || 0).format("$0,0")}
                </IonCol>
                <IonCol size="4" className="ion-text-end">
                  {numeral(overallBudgetData?.Income?.amount || 0).format("$0,0")}
                </IonCol>
              </IonRow>
              <IonRow className="font-md ion-margin-horizontal">
                <IonCol size="4">{t("spending")}</IonCol>
                <IonCol size="4" className="ion-text-end">
                  {numeral((overallBudgetData?.Expenses.amountActual || 0) * -1).format("$0,0")}
                </IonCol>
                <IonCol size="4" className="ion-text-end">
                  {numeral((overallBudgetData?.Expenses.amount || 0) * -1).format("$0,0")}
                </IonCol>
              </IonRow>
              <IonRow className="font-md ion-margin-horizontal">
                <IonCol size="4">{t("net")}</IonCol>
                <IonCol size="4" className="ion-text-end">
                  {numeral(
                    (overallBudgetData?.Income.amountActual || 0) * -1 -
                    (overallBudgetData?.Expenses.amountActual || 0)
                  ).format("$0,0")}
                </IonCol>
                <IonCol size="4" className="ion-text-end">
                  {numeral(
                    (overallBudgetData?.Income.amount || 0) -
                    (overallBudgetData?.Expenses.amount || 0)
                  ).format("$0,0")}
                </IonCol>
              </IonRow>
            </>
          ) : null}
          {showOverallBudget ? null : (
            <IonRow>
              <IonCol>
                <BudgetCardContent
                  effectiveDate={effectiveDate}
                  budgetType={budgetType}
                  superCategory={selectedSuperCategory}
                />
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </Section>
      <Section>
        {showOverallBudget ? null : (
          <>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <h1 className="font-title-3">
                    {t(
                      selectedSuperCategory === BUDGET_SUPER_CATEGORIES.EXPENSES
                        ? "spendingBudget"
                        : "incomeBudget"
                    )}
                  </h1>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonList>
              {selectedSuperCategory === BUDGET_SUPER_CATEGORIES.EXPENSES
                ? categoryData
                  .filter((c) => !c.isDeleted)
                  .map((category) => {
                    return category.superCategory === selectedSuperCategory ? (
                      <BudgetCategoryDetailItem
                        effectiveDate={effectiveDate}
                        budgetType={budgetType}
                        category={category}
                        superCategory={selectedSuperCategory}
                        key={category.categoryShortName}
                      />
                    ) : null;
                  })
                : subCategoryData
                  .filter(
                    (s) =>
                      !s.isDeleted &&
                      !s.isDeactivated &&
                      s.category === BUDGET_SUPER_CATEGORIES.INCOME
                  )
                  .map((subcategory, i) => {
                    return (
                      <BudgetSubcategoryDetailItem
                        effectiveDate={effectiveDate}
                        budgetType={budgetType}
                        category={incomeCategoryData}
                        subcategory={subcategory}
                        superCategory={selectedSuperCategory}
                        key={i}
                      />
                    );
                  })}
            </IonList>
            <IonGrid>
              <IonRow>
                <IonCol className="ion-text-center">
                  <EditCategoriesButton onClick={() => {
                    setShowEditCategoriesModal(true);
                    trackEvent("view_editCategories", { category: "editCategories" });
                  }} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        )}
      </Section>
    </PageTemplate>
  );
};
