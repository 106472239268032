import React, { useState } from "react";
import { Modal } from "../../shared/Modal/Modal";
import { useTranslation } from "react-i18next";
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonText,
  IonThumbnail,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import Avatars from "../../shared/Avatar/Avatars";
import "./EditSharedStatus.scss";
import { editTransactionSharing } from "../../../utils/transactions";
import { useSelector, useDispatch } from "react-redux";
import { getUsersState, getHouseholdDataState } from "../../../redux/selectors";
import { flagOutline, checkmarkOutline } from "ionicons/icons";
import { setToastMessage, setLoading, refreshCategoryDependencies } from "../../../redux/actions";
import { useUserName } from "../../shared/User/useUserName";
import { startCase } from "lodash";
import { TransactionModalType } from "../../../types";
import { trackEvent } from "../../../vendors/monitoring";

interface EditSharedStatusProps {
  onClose: (arg0: TransactionModalType) => void;
  hhInfo?: any;
  transactionOwner?: string;
  transactionId: string;
}

export const EditSharedStatus: React.FC<EditSharedStatusProps> = (props) => {
  const { onClose, hhInfo, transactionOwner, transactionId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  type SharedStatus = 0 | 1 | 2 | 3 | number;

  //redux state
  const { userId } = useSelector(getUsersState);
  const { members } = useSelector(getHouseholdDataState);
  const { partnerName } = useUserName();

  //data
  const sharedStatusList = [
    { avatarType: 0, name: startCase(t("household")), des: t("includedInHouseHoldSpending") },
    { avatarType: 1, name: "Me", des: t("includedInHouseHoldSpending") },
    { avatarType: 2, name: startCase(partnerName), des: t("includedInHouseHoldSpending") },
    {
      avatarType: 3,
      name: startCase(t("private")),
      des: transactionOwner ? t("includedInprivateSpending") : t("notAvailableForAccounts"),
    },
  ];

  const getSharedStatus = () => {
    if (hhInfo?.isPrivate) {
      return 3;
    }

    if (hhInfo?.sharedExpense === "0") {
      return 0;
    }

    if (hhInfo?.sharedExpense === "1") {
      if (hhInfo.sharedExpenseIndividual.includes(userId)) {
        return 1;
      } else {
        return 2;
      }
    }
    return 0;
  };

  //state
  const [selectedStatus, setSelectedStatus] = useState<SharedStatus>(getSharedStatus());
  const [createRuleToggle, setCreateRuleToggle] = useState(false);

  const getTransactionVisibilityList = () => {
    let transactionVisibilityArr: string[] = [];
    members.forEach((member: any) => transactionVisibilityArr.push(member._id));
    return transactionVisibilityArr;
  };

  const handleSharedStatusSave = () => {
    if (getSharedStatus() === selectedStatus) return;
    dispatch(setLoading(true));
    const transactionVisibility = getTransactionVisibilityList();
    if (selectedStatus === 2 && members.length < 2) {
      dispatch(
        setToastMessage(`${t("partnerSignupError1")} ${partnerName} ${t("partnerSignupError2")}`)
      );
      dispatch(setLoading(false));
      return;
    }
    //@ts-ignore
    const sharedBodyData = {
      0: { transactionId, action: "MARK_SHARED" },
      1: {
        transactionId,
        action: "MARK_INDIVIDUAL",
        transactionVisibility,
        sharedExpenseIndividual: [userId],
      },
      2: {
        transactionId,
        action: "MARK_INDIVIDUAL",
        transactionVisibility,
        sharedExpenseIndividual: [members.filter((member: any) => member._id !== userId)[0]?._id],
      },
      3: {
        transactionId,
        action: "MARK_PRIVATE",
      },
    }[selectedStatus];
    if (!sharedBodyData) return;
    editTransactionSharing(sharedBodyData, dispatch)
      .then(() => checkRuleToggleAndClose())
      .then(() => {
        dispatch(refreshCategoryDependencies());
      });
  };

  const checkRuleToggleAndClose = () => {
    if (createRuleToggle) {
      onClose(TransactionModalType.SHARED_STATUS_RULE);
    } else {
      onClose(TransactionModalType.NONE);
    }
  };

  return (
    <>
      <Modal open title={t("firstly")} onClose={() => onClose(TransactionModalType.NONE)}>
        <IonContent id="EditSharedStatus">
          <h1 className="ion-margin-start font-title-3">{t("whoseTransactionsIs")}</h1>
          <IonList lines="none">
            {sharedStatusList.map((sharedStatusItem, index) => (
              <IonItem
                key={index}
                className={`ion-margin ${index === selectedStatus && "edit-sharedStatus-item-selected"
                  }`}
                onClick={() => (transactionOwner ? setSelectedStatus(index) : null)}
                disabled={!transactionOwner}
                data-testid="EditSharedOptions"
              >
                <IonAvatar slot="start">
                  <Avatars
                    avatarType={sharedStatusItem.avatarType}
                    sizeStyle="sm"
                    diagonal={true}
                  />
                </IonAvatar>
                <IonLabel>
                  <h4 className="font-md">{sharedStatusItem.name}</h4>
                  <h5 className="font-sm">{sharedStatusItem.des}</h5>
                </IonLabel>

                {index === selectedStatus && <IonIcon slot="end" icon={checkmarkOutline} />}
              </IonItem>
            ))}
          </IonList>
        </IonContent>
        <IonFooter className="ion-no-border ion-margin">
          <IonGrid>
            <IonRow>
              <IonCol size="8">
                <IonRow className="ion-align-items-center ion-text-nowrap">
                  <IonIcon icon={flagOutline} className="ion-padding-end" />
                  <p>{t("createRule")}</p>
                  <IonToggle
                    color="primary"
                    data-testid="editSharedStatusRuleToggle"
                    onIonChange={({ detail }) => setCreateRuleToggle(detail.checked)}
                  />
                </IonRow>
              </IonCol>
              <IonCol>
                <IonButton
                  disabled={getSharedStatus() === selectedStatus}
                  onClick={() => {
                    trackEvent("save_transOwner", { category: "transOwner" });
                    handleSharedStatusSave();
                  }}
                  data-testid="sharedstatusBtn"
                >
                  {t("save")}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonFooter>
      </Modal>
    </>
  );
};
