import { Dispatch } from "react";
import {
  billersListApiRes,
  billsAuditDataType,
  offerApiResp,
  seletectedBillerInfoType,
  userInfoType,
} from "../../types/billsAudit";
import * as types from "../actionTypes";
import { get } from "../../utils/apiOps";
import store from "../store";
import { billStatusTriage } from "../../utils/billsAuditUtils";

export const setBillsAuditData = (data: billsAuditDataType) => {
  return {
    type: types.SET_BILLS_AUDIT_DATA,
    payload: {
      billsAuditData: data,
    },
  };
};

export const setBillsAuditUserInfo = (data: userInfoType) => {
  return {
    type: types.SET_BILLS_AUDIT_USERINFO,
    payload: {
      userInfo: data,
    },
  };
};

export const setBillersList = (data: billersListApiRes["data"]) => {
  return {
    type: types.SET_BILLS_AUDIT_BILLERS_LIST,
    payload: {
      billersList: data,
    },
  };
};

export const setSelectedBillerInfo = (data: seletectedBillerInfoType) => {
  return {
    type: types.SET_BILLS_AUDIT_SELECTED_BILLER_INFO,
    payload: {
      selectedBillerInfo: data,
    },
  };
};

export const getBillsAuditData = () => async (dispatch: Dispatch<any>) => {
  try {
    const { data }: offerApiResp = await get(true, {
      endpoint: "/offers/getForUser",
    });
    dispatch(setBillsAuditUserInfo(data.userInfo));
    //TODO: convert to data.bills from tempBills
    dispatch(setBillsAuditData(data.bills));
    const newBills: billsAuditDataType = await Promise.all(
      data.bills.map(async (b: billsAuditDataType[0]) => {
        try {
          const triageResp = await billStatusTriage(b.billsharkProviderId);
          b.buttonText = triageResp.buttonText;
          return b;
        } catch (error) {
          return b;
        }
      })
    );
    dispatch(setBillsAuditData(newBills));
  } catch (error) {}
};

export const getBillersList = () => async (dispatch: Dispatch<any>) => {
  try {
    const { data }: billersListApiRes = await get(true, {
      endpoint: "/offers/rules/getAllOrOne?includeDeleted=false&billshark=true&offersRulesIds=[]",
    });
    dispatch(setBillersList(data));
  } catch (error) {}
};

// used after creating a bill to append it to the selectedBiller object
export const updateSelectedBillInfo = (billProviderId: billsAuditDataType[0]["_id"]) => {
  const { billsAuditData, selectedBillerInfo } = store.getState().billsAudit;
  const selectedBiller = billsAuditData?.find(
    (bill) => bill.billsharkProviderId === billProviderId
  );

  return {
    type: types.SET_BILLS_AUDIT_SELECTED_BILLER_INFO,
    payload: {
      selectedBillerInfo: {
        ...selectedBillerInfo,
        billsharkBillDetails: selectedBiller,
      },
    },
  };
};
