import React, { ReactElement } from "react";
import { IonModal } from "@ionic/react";
import { closeOutline, ellipsisHorizontal } from "ionicons/icons";
import { ModalHeader } from "../Header/ModalHeader";

type ModalProps = {
  open: boolean;
  onClose: () => void;
  onDidDismiss?: () => void;
  onMoreDetails?: () => void;
  moreDetailsIcon?: string;
  title: string | ReactElement<any>;
  modalStyle?: string;
  withHeader?: boolean;
  endBtnText?: string | ReactElement<any>;
};

export const Modal: React.FC<ModalProps> = (props) => {
  const { open, onClose, onDidDismiss, children, onMoreDetails, moreDetailsIcon, title, modalStyle, endBtnText, withHeader = true } = props;
  return (
    <IonModal isOpen={open} cssClass={modalStyle ? modalStyle : ""} onDidDismiss={onDidDismiss}>
      {withHeader ? (
        <ModalHeader
          title={title}
          startBtnIcon={closeOutline}
          startBtnOnClick={onClose}
          endBtnIcon={onMoreDetails && (moreDetailsIcon || ellipsisHorizontal)}
          endBtnText={endBtnText}
          endBtnOnClick={onMoreDetails}
        />
      ) : null}

      {children}
    </IonModal>
  );
};
