import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LinkSearchModal } from "../../shared/Link/LinkSearchModal";
import { SharedSettingsModal } from "./SharedSettingsModal";
import { useUserName } from '../User/useUserName';

type LinkAndShareAccountsProps = {
  isOpen: boolean;
  searchAuthOnly?: boolean; // for goals
  onSuccess?: () => void;
  allowManualAccount?: boolean;
};

export const LinkAndShareAccounts: React.FC<LinkAndShareAccountsProps> = ({
  isOpen,
  searchAuthOnly = false,
  onSuccess = () => { },
  allowManualAccount = true,
}) => {
  const { t } = useTranslation();
  const { singlePlayerMode } = useUserName();
  const [searchAccountModal, setSearchAccountModal] = useState<boolean>(isOpen);
  const [shareSettingsModalOpen, setShareSettingsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setSearchAccountModal(isOpen);
  }, [isOpen]);

  const handleLinkingAccount = (accountLinked: boolean) => {
    const shareSettingsShouldBeOpen = (
      shareSettingsModalOpen || // it's already open
      accountLinked // to set share settings for successfully linked account
    ) && !singlePlayerMode // never open share-settings on single-player mode

    setSearchAccountModal(false);
    if (shareSettingsShouldBeOpen) {
      setShareSettingsModalOpen(true);
    } else {
      onSuccess();
    }
  };

  const handleShareSettingsSaved = () => {
    setShareSettingsModalOpen(false);
    onSuccess();
  };

  return (
    <>
      <LinkSearchModal
        key="linkSearchModalKey"
        isOpen={searchAccountModal}
        onClose={handleLinkingAccount}
        searchAuthOnly={searchAuthOnly}
        allowManualAccount={allowManualAccount}
      />
      <SharedSettingsModal
        key="linkShareSettingsModalKey"
        isOpen={shareSettingsModalOpen}
        onClose={() => setShareSettingsModalOpen(false)}
        onSave={handleShareSettingsSaved}
        title={t("shareSettings_title")}
      />
    </>
  );
};
