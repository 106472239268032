import {
  CREATE_NEW_CHALLENGE,
  UPDATE_CHALLENGE_CATEGORY,
  UPDATE_CHALLENGE_SUBCATEGORY,
  UPDATE_CHALLENGE_FREQUENCY,
  UPDATE_CHALLENGE_AMOUNT,
  SET_SPENDING_CHALLENGE,
} from "../actionTypes";
import { SpendingChallengeCreateParams, SpendingChallengeType } from "../../types";

interface spendingChallengeState {
  newSpendingChallenge: SpendingChallengeCreateParams;
  categoryFilter?: string;
  subCategoryFilter?: string;
  frequency: string;
  amount: number;
  spendingChallengesList: SpendingChallengeType[];
}

const initialState: spendingChallengeState = {
  newSpendingChallenge: {},
  categoryFilter: "",
  subCategoryFilter: "",
  frequency: "",
  amount: 0,
  spendingChallengesList: [],
};

interface action {
  type: string;
  payload: any;
}

const spendingChallenge = (state: spendingChallengeState = initialState, action: action) => {
  switch (action.type) {
    case CREATE_NEW_CHALLENGE:
      return {
        ...state,
        newSpendingChallenge: { ...state.newSpendingChallenge, ...action.payload },
      };
    case UPDATE_CHALLENGE_CATEGORY:
      return {
        ...state,
        categoryFilter: action.payload,
      };
    case UPDATE_CHALLENGE_SUBCATEGORY:
      return {
        ...state,
        subCategoryFilter: action.payload,
      };
    case UPDATE_CHALLENGE_FREQUENCY:
      return {
        ...state,
        frequency: action.payload,
      };
    case UPDATE_CHALLENGE_AMOUNT:
      return {
        ...state,
        amount: action.payload,
      };
    case SET_SPENDING_CHALLENGE:
      return {
        ...state,
        spendingChallengesList: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default spendingChallenge;
