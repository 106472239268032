import React, { useState } from "react";
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonFooter,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { PAGE_URLS } from "../../../constants";
import { useTranslation } from "react-i18next";
import Avatars from "../../shared/Avatar/Avatars";
import { startCase } from "lodash";
import { updateCreateNewChanllengeParams } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getHouseholdDataState } from "../../../redux/selectors";
import { ChallengeType } from "../../../types/spendingChallenge";
import { useUserName } from "../../shared/User/useUserName";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { trackEvent } from "../../../vendors/monitoring";

export const NewSpendingChallenge: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { singlePlayerMode } = useUserName();

  //data
  const sharedStatusList = !singlePlayerMode
    ? [
      {
        competitionType: ChallengeType.COLLABORATIVE,
        avatarType: 0,
        name: startCase(t("shared")),
        des: t("workTogether"),
      },
      {
        competitionType: ChallengeType.COMPETITIVE,
        avatarType: 0,
        name: startCase(t("compete")),
        des: t("competeWithEachOhter"),
      },
      {
        competitionType: ChallengeType.INDIVIDUAL,
        avatarType: 1,
        name: startCase(t("individual")),
        des: t("chanllengeYourself"),
      },
    ]
    : [
      {
        competitionType: ChallengeType.INDIVIDUAL,
        avatarType: 1,
        name: startCase(t("individual")),
        des: t("chanllengeYourself"),
      },
    ];

  //state
  const [selectedCompetitionType, setSelectedCompetitionType] = useState<ChallengeType>(
    sharedStatusList[0].competitionType
  );

  return (
    <PageTemplate
      header={<NavHeader desktopTitle={t("spendingChallengeTitle")} />}
      footer={
        <IonFooter className="ion-no-border">
          <IonToolbar no-border className="ion-text-center">
            <IonButton
              routerLink={PAGE_URLS.SPENDING_CHALLENGES_NEW_CATEGOTY}
              onClick={() => {
                dispatch(
                  updateCreateNewChanllengeParams({ competitionType: selectedCompetitionType })
                );
                trackEvent("select_type_spendChallenge", {
                  category: "spendingChallenge",
                  label: selectedCompetitionType,
                });
                setSelectedCompetitionType(sharedStatusList[0].competitionType);
              }}
              data-testid="newSpendingNextBtn1"
            >
              {t("next")}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      }
    >
      <Section>
        <IonRow>
          <h2 className="ion-margin-start font-lg">{t("spendingChallenge")}</h2>
        </IonRow>
        <IonRow>
          <h3 className="ion-margin-start font-md">{t("newSpendingChallengeKinds")}</h3>
        </IonRow>
        <IonRadioGroup value={selectedCompetitionType}>
          {sharedStatusList.map((sharedStatusItem) => (
            <IonItem
              lines="none"
              key={sharedStatusItem.competitionType}
              className="ion-margin ion-no-border"
              onClick={() => setSelectedCompetitionType(sharedStatusItem.competitionType)}
              data-testid="newSpendingCompetitionType"
            >
              <IonAvatar slot="start">
                <Avatars avatarType={sharedStatusItem.avatarType} sizeStyle="sm" diagonal={true} />
              </IonAvatar>
              <IonCol className="ion-no-padding">
                <IonRow>
                  <div className="font-md fy-padding-vertical-xs edit-sharedStatus-header newSpendingChallenge-h4">
                    {sharedStatusItem.name}
                  </div>
                </IonRow>
                <IonRow>
                  <div className=" edit-sharedStatus-header-des font-sm newSpendingChallenge-h5">
                    {sharedStatusItem.des}
                  </div>
                </IonRow>
              </IonCol>
              <IonRadio value={sharedStatusItem.competitionType} slot="end" />
            </IonItem>
          ))}
        </IonRadioGroup>
      </Section>
    </PageTemplate>
  );
};
