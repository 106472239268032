import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { logout } from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import Avatars from "../../shared/Avatar/Avatars";
import { AVATAR_DISPLAY, PAGE_URLS } from "../../../constants";
import {
  cogOutline,
  documentOutline,
  helpCircleOutline,
  linkOutline,
  personAddOutline,
  personOutline,
  powerOutline,
} from "ionicons/icons";
import { useUserName } from "../../shared/User/useUserName";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getHouseholdDataState, getUsersState } from "../../../redux/selectors";
import { HouseholdProfile } from "../../../types";
import { NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { trackEvent } from "../../../vendors/monitoring";

interface settingsArr {
  label: string;
  icon: any;
  href: string;
  singlePlayerOnly?: boolean;
}
const settingsArr: Array<settingsArr> = [
  { label: "profile", icon: personOutline, href: PAGE_URLS.MORE_PROFILE },
  { label: "accounts", icon: linkOutline, href: PAGE_URLS.MORE_MANAGE_ACCTS },
  {
    label: "inviteAPartner",
    icon: personAddOutline,
    href: PAGE_URLS.INVITE_PARTNER,
    singlePlayerOnly: true,
  },
  { label: "help", icon: helpCircleOutline, href: PAGE_URLS.MORE_GET_HELP },
  { label: "settings", icon: cogOutline, href: PAGE_URLS.MORE_SETTINGS },
  { label: "policies", icon: documentOutline, href: PAGE_URLS.MORE_POLICIES },
];

export const MoreTab: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { userName, partnerName, singlePlayerMode } = useUserName();
  const { hhIdDefault } = useSelector(getUsersState);
  const { hhProfiles } = useSelector(getHouseholdDataState);
  const [selectedHousehold, setSelectedHoushold] = useState<HouseholdProfile>();
  useEffect(() => {
    setSelectedHoushold(hhProfiles.find((hh: HouseholdProfile) => hh._id === hhIdDefault));
  }, [hhProfiles, hhIdDefault]);

  const handleUserLogOut = () => {
    trackEvent("click_logout", { category: "more" });
    dispatch(logout());
    history.push(PAGE_URLS.WELCOME);
  };

  return (
    <PageTemplate contentProps={{ fullscreen: true }} header={<NavHeader title={t("tab_more")} />}>
      <Section>
        <Avatars
          className="ion-margin-top"
          sizeStyle="lg"
          avatarType={singlePlayerMode ? AVATAR_DISPLAY.ME : AVATAR_DISPLAY.SHARED}
        />
        {singlePlayerMode ? (
          <h1 className="ion-text-center font-title-3" data-testid="moreTab-single-player">
            {userName}
          </h1>
        ) : (
          <div>
            <h1 className="ion-text-center font-title-3" data-testid="moreTab-multi-player">
              {userName} & {partnerName}
            </h1>
            <h4 className="ion-text-center font-md">
              {selectedHousehold?.name || `(${t("noHhNameSet")})`}
            </h4>
          </div>
        )}
      </Section>
      <Section>
        <IonList lines="none">
          {settingsArr
            .filter((setting) => singlePlayerMode || !setting.singlePlayerOnly)
            .map((s, i) => {
              return (
                <IonItem
                  detail
                  key={i}
                  data-testid={`more_${s.label}`}
                  routerLink={s.href === "not built" ? undefined : s.href}
                  onClick={s.href === "not built" ? () => alert("Not built") : undefined}
                >
                  <IonIcon slot="start" icon={s.icon} />
                  <IonLabel>{t(s.label)}</IonLabel>
                </IonItem>
              );
            })}
          <IonItem detail data-testid={`more_logout`} onClick={handleUserLogOut}>
            <IonIcon slot="start" icon={powerOutline} />
            <IonLabel>{t("logout")}</IonLabel>
          </IonItem>
        </IonList>
      </Section>
    </PageTemplate>
  );
};
