import React from "react";
import { IonButton, IonGrid, IonRow, IonCol, IonContent, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { PAGE_URLS } from "../../../constants";
import { OnboardingNavHeader } from "../Onboarding/OnboardingNavHeader";
import "../Onboarding/onboarding.scss";
import Template, { OnboardingCopy } from "../Onboarding/Template";
import { useViewport } from '../../../hooks/useViewport';

export const ForgotPasswordSuccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Template
      header={<OnboardingNavHeader
        title={t("forgotPasswordTitle")}
        desktopTitle=" "
        showBackButton={true}
        defaultHref={PAGE_URLS.LOGIN}
        showHelp={false}
      />}
      showFooter={false}
    >
      <OnboardingCopy
        desktopPrefix={"forgotPasswordTitle"}
      />
      <div className="bounded">
        <h1 className="font-title-3 ion-padding-top">
          {t("forgotPassSuc_title")}
        </h1>
        <h3 className="font-md ion-padding-top">
          {t("forgotPassSuc_msg")}
        </h3>
        <br />
        <IonButton
          routerLink={PAGE_URLS.LOGIN}
          routerDirection="back"
          color="primary"
          expand="block"
          size="large"
          data-testid="login"
          className="ion-margin-top"
        >
          {t("returnToSignIn")}
        </IonButton>
      </div>
    </Template >
  );
};
