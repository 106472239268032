import { Dispatch } from "redux";

import * as types from "../actionTypes";
import { get } from "../../utils/apiOps";
import { operations, components } from "../../types/schema";

type apiRespNotifSettings =
  operations["api_user_notificationSettings"]["responses"]["200"]["content"]["application/json"];

export const setNotificationPref = (preferences: any) => {
  return {
    type: types.SAVE_NOTIFICATION_PREF_DATA,
    payload: preferences,
  };
};

export const updateNotificationPref = (preferences: any) => {
  return {
    type: types.UPDATE_NOTIFICATION_PREF_DATA,
    payload: preferences,
  };
};

export const getNotificationPref = () => async (dispatch: Dispatch<any>) => {
  const { data }: apiRespNotifSettings = await get(true, {
    endpoint: "/user/notificationSettings",
  });
  dispatch(setNotificationPref(data));
};
