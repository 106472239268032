import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonFooter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import images from "../../../assets/images/illustrations";
import "./FunFactCard.scss";
import { getFunFact } from "../../../utils/homeTab";
import { useHistory } from "react-router-dom";
import { FunFact } from "../../../types/homeTab";
import numeral from "numeral";
import { HomeCardCloseAlert } from "../Home/HomeCardCloseAlert";
import { trackEvent } from "../../../vendors/monitoring";
import { getTransactionLink } from "../../../utils/transUtils";

export const FunFactCard: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  //state
  const [funFactData, setFunFactData] = useState<FunFact[]>([]);

  useEffect(() => {
    getFunFact(setFunFactData);
  }, []);

  //methods
  const handleFunFactTransactionBtnClick = () => {
    trackEvent("click_funFactCard", { category: "home" });
    history.push(getTransactionLink("advancedSearch", {
      keyword: funFactData[0]._id,
      isPrivate: "both",
    }));
  };

  return funFactData[0] ? (
    <IonCard className="fun-fact">
      <HomeCardCloseAlert removedCard="fun-fact" />
      <IonCardHeader className="ion-text-center ion-no-padding ion-padding-top">
        <img src={images.funFact} alt="fun-fact" />
        <IonCardTitle className="ion-text-center ion-padding-top">{t("funFact")}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className="ion-text-center ion-margin-top">
        <p className="font-md ion-margin-horizontal ion-padding-horizontal">
          {t("funFactMsg", {
            spent: numeral(funFactData[0].amt).format("$0,0"),
            merchant: funFactData[0]._id,
            count: funFactData[0].ct,
            average: numeral(funFactData[0].amt / funFactData[0].ct).format("$0,0"),
          })}
        </p>
      </IonCardContent>
      <IonFooter className="ion-text-center ion-no-border ion-padding">
        <IonButton
          size="large"
          expand="block"
          onClick={handleFunFactTransactionBtnClick}
          data-testid="FunFactCard"
        >
          {t("viewTransactions")}
        </IonButton>
      </IonFooter>
    </IonCard>
  ) : null;
};
