import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { BUDGET_SHARED_TYPE, BUDGET_SUPER_CATEGORIES, PAGE_URLS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { getBudgetAnalyticsState } from "../../../redux/selectors";
import dayjs from "dayjs";
import numeral from "numeral";
import "./BudgetCard.scss";
import "./BudgetDetailItem.scss";
import { BudgetPieChart } from "./BudgetPieChart";
import {
  BudgetItem,
  BudgetSuperCategory,
  BudgetSuperCategoryObj,
  BudgetType,
} from "../../../types";
import { DataArrayItemBudgetPieChart } from "../../../types/budgetAnalytics";
import { getOperator, isOverBudget } from "../../../utils/budget";

import { trackEvent } from "../../../vendors/monitoring";

const BudgetCard: React.FC = () => {
  //TODO: remove from here, but leaving to make it easier to test/style
  const { t } = useTranslation();

  // state
  const { loading } = useSelector(getBudgetAnalyticsState);
  const effectiveDate = dayjs().startOf("month").format("YYYY-MM-DD");

  return (
    <IonCard
      routerLink={PAGE_URLS.BUDGET_HOME.replace(":effectiveDate", effectiveDate).replace(
        ":budgetType",
        BUDGET_SHARED_TYPE.HOUSEHOLD
      )}
      data-testid="BudgetCard"
      onClick={() => trackEvent("view_seeBudget", { category: "seeBudget" })}
    >
      <IonCardHeader>
        <IonCardTitle className="ion-text-center">
          {dayjs(effectiveDate).format("MMMM")} {t("budget")}
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent className="ion-text-center">
        {loading ? <IonSpinner /> : <BudgetCardContent effectiveDate={effectiveDate} />}
      </IonCardContent>
    </IonCard>
  );
};

interface BudgetCardContentProps {
  effectiveDate: string;
  budgetType?: BudgetType;
  superCategory?: BudgetSuperCategory;
}

export const BudgetCardContent: React.FC<BudgetCardContentProps> = ({
  effectiveDate,
  budgetType = BUDGET_SHARED_TYPE.HOUSEHOLD,
  superCategory = BUDGET_SUPER_CATEGORIES.EXPENSES,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { budgetsArray } = useSelector(getBudgetAnalyticsState);
  const [budgetAnalyticsForDate, setBudgetAnalyticsForDate] = useState<BudgetSuperCategoryObj>();
  const [budgetAnalyticsDataForPieChart, setBudgetAnalyticsDataForPieChart] = useState<
    Array<DataArrayItemBudgetPieChart>
  >([]);

  useEffect(() => {
    const budgetAnalyticsForThisDate = budgetsArray.find(
      (item: BudgetItem) => item.budgetAnalyticsDate === effectiveDate
    );
    if (!budgetAnalyticsForThisDate) {
      return;
    }
    // @ts-ignore
    const dataForChart = budgetAnalyticsForThisDate[budgetType]?.budget[superCategory];
    if (budgetAnalyticsForThisDate && dataForChart) {
      setBudgetAnalyticsForDate(dataForChart);
      setBudgetAnalyticsDataForPieChart([
        {
          id: "budgetLeft",
          value: (dataForChart.amount || 0) - (dataForChart.amountActual || 0) || 1,
        },
        {
          id: "budgetSpent",
          value: dataForChart.amountActual,
        },
      ]);
    }
  }, [budgetsArray, effectiveDate, budgetType, superCategory]);

  return (
    <IonGrid id="BudgetCardContentGrid">
      <IonRow className="ion-align-items-center ion-padding-horizontal">
        <IonCol size="5" className="ion-text-center">
          <BudgetPieChart budgetAnalyticsDataForPieChart={budgetAnalyticsDataForPieChart} />
        </IonCol>
        <IonCol size="7" className="ion-text-center">
          <h3 className="font-md">
            <span className="extra-bold">
              {numeral(
                getOperator(superCategory) * (budgetAnalyticsForDate?.amountActual || 0)
              ).format("$0,0")}
            </span>{" "}
            / {numeral(budgetAnalyticsForDate?.amount).format("$0,0")}
          </h3>
          <span id="inner-border"></span>
          <div
            className={
              isOverBudget(superCategory, budgetAnalyticsForDate) ? "budget-header-status" : ""
            }
          >
            <h3
              className={
                isOverBudget(superCategory, budgetAnalyticsForDate)
                  ? "font-sm ion-no-margin over-budget-pill"
                  : "font-sm ion-no-margin"
              }
            >
              {numeral(
                Math.abs(
                  getOperator(superCategory) * (budgetAnalyticsForDate?.amount || 0) -
                  getOperator(superCategory) * (budgetAnalyticsForDate?.amountActual || 0)
                )
              ).format("$0,0")}{" "}
              {isOverBudget(superCategory, budgetAnalyticsForDate) ? t("over") : t("left")}
            </h3>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default BudgetCard;
