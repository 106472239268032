import React, { useState, useEffect } from "react";
import {
  IonSelectOption,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSelect,
  IonFooter,
  IonButton,
  IonToolbar,
} from "@ionic/react";
import { ModalHeader } from "../Header/ModalHeader";
import { useTranslation } from "react-i18next";
import { closeOutline } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAccountById, getAccountsState, getCategoryState } from "../../../redux/selectors";
import { AccountTypesMapItem } from "../../../types";
import { saveManualAccount } from "../../../redux/actions";
import "./AddNewManualAccount.scss";
import { SetSharedModal } from "./SetSharedModal";
import { useUserName } from "../User/useUserName";
import { useResponsiveInterface } from "../../../hooks/useResponsiveInterface";

import { trackEvent } from "../../../vendors/monitoring";

type AddNewManualAccountModalProps = {
  isOpen: boolean;
  handleClose: () => void;
};

export const AddNewManualAccountModal: React.FC<AddNewManualAccountModalProps> = (props) => {
  const { isOpen, handleClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _interface = useResponsiveInterface("alert");
  const { singlePlayerMode } = useUserName();

  //state
  const [selectedAccountNumber, setSelectedAccountNumber] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const [selectedAccountTypesArr, setSelectedAccountTypesArr] = useState<Array<any>>([]);
  const [selectedAccountName, setSelectedAccountName] = useState("");
  const [selectedInstitutionName, setselectedInstitutionName] = useState("");
  const [selectedBalance, setSelectedBalance] = useState(0);
  const [sharedSettingModalOpen, setSharedSettingModalOpen] = useState(false);
  const [savedId, setSavedId] = useState<string>("");

  //redux state
  const { accountTypesMap } = useSelector(getCategoryState);
  const accountData = useSelector(getAccountById(savedId));

  useEffect(() => {
    if (accountTypesMap.length === 0) {
      return;
    }
    let newTypesArr: Array<any> = [];
    accountTypesMap.map((accountType: AccountTypesMapItem) => {
      newTypesArr = newTypesArr.concat(
        accountType?.subtypes.map((subType: string) => {
          return {
            label: `${t(accountType.name)}: ${t(subType)}`,
            value: `${accountType.name}: ${subType}`,
          };
        })
      );
      return null;
    });
    setSelectedAccountTypesArr(newTypesArr);
  }, [accountTypesMap, t]);

  //methods
  const save = async () => {
    const typeArray = selectedAccountType.split(": ");
    const params = {
      accountType: typeArray[0],
      accountSubType: typeArray[1],
      institutionName: selectedInstitutionName,
      accountName: selectedAccountName,
      accountNumber: selectedAccountNumber,
      currentBalance: Number(selectedBalance),
    };

    await dispatch(saveManualAccount(params, setSavedId));
    if (singlePlayerMode) {
      handleClose();
    } else {
      setSharedSettingModalOpen(true);
    }
  };

  const validateManualAccountSave = () => {
    return (
      !!selectedAccountName &&
      !!selectedInstitutionName &&
      selectedBalance >= 0 &&
      !!selectedAccountType
    );
  };

  return (
    <>
      <IonModal isOpen={isOpen} onDidDismiss={handleClose}>
        <ModalHeader
          title={t("balance")}
          startBtnIcon={closeOutline}
          startBtnOnClick={handleClose}
        />
        <IonContent>
          <h2 className="font-lg ion-margin-start">{t("addManualAccount")}</h2>
          <IonList lines="none">
            <IonItem>
              <IonLabel className="font-md">{t("accountName")}</IonLabel>
              <IonInput
                slot="end"
                value={selectedAccountName}
                placeholder={t("myAccount")}
                className="ion-text-end manual-account-input font-md"
                onIonChange={(e) => setSelectedAccountName(e.detail.value || "")}
                data-testid="manualAccountName"
              />
            </IonItem>
            <IonItem>
              <IonLabel className="font-md">{t("accountNumber")}</IonLabel>
              <IonInput
                slot="end"
                value={selectedAccountNumber}
                placeholder={t("optional")}
                className="ion-text-end manual-account-input font-md"
                onIonChange={(e) => setSelectedAccountNumber(e.detail.value || "")}
                data-testid="manualAccountNumber"
              />
            </IonItem>
            <IonItem>
              <IonLabel className="font-md">{t("institutionName")}</IonLabel>
              <IonInput
                slot="end"
                value={selectedInstitutionName}
                placeholder={t("myBank")}
                className="ion-text-end manual-account-input font-md"
                onIonChange={(e) => setselectedInstitutionName(e.detail.value || "")}
                data-testid="manualAccountInstitutionName"
              />
            </IonItem>
            <IonItem>
              <IonLabel className="font-md">{t("accountType")}</IonLabel>
              <IonSelect
                value={selectedAccountType}
                placeholder={t("selectOne")}
                interface={_interface}
                onIonChange={(e) => setSelectedAccountType(e.detail.value)}
                className="manual-account-input font-md"
                data-testid="manualAccountType"
              >
                {selectedAccountTypesArr.length > 0 &&
                  selectedAccountTypesArr.map((type, i) => {
                    return (
                      <IonSelectOption key={i} value={type.value} className="font-md">
                        {type.label}
                      </IonSelectOption>
                    );
                  })}
              </IonSelect>
            </IonItem>
            <IonItem className="ion-justify-content-between">
              <IonLabel className="font-md">{t("balance")}</IonLabel>
              <IonInput
                slot="end"
                value={selectedBalance}
                placeholder="0"
                className="ion-text-end newManualAccountInput  manual-account-input font-md"
                onIonChange={(e) => setSelectedBalance(Number(e.detail.value) || 0)}
                data-testid="manualAccountBalance"
              />
            </IonItem>
          </IonList>
        </IonContent>
        <IonFooter className="ion-no-border">
          <IonToolbar no-border className="ion-text-center">
            <IonButton
              onClick={() =>
                save().then(() => trackEvent("save_addManualAcct", { category: "addManualAcct" }))
              }
              disabled={!validateManualAccountSave()}
              data-testid="manualAccountSaveBtn"
            >
              {t("save")}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
      {sharedSettingModalOpen && accountData ? (
        <SetSharedModal
          isOpen={sharedSettingModalOpen}
          onClose={() => setSharedSettingModalOpen(false)}
          account={accountData}
          handleClose={handleClose}
          showJointIndividual={true}
        />
      ) : null}
    </>
  );
};
