import React, { useEffect, useState } from "react";
import {
  IonModal,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonFooter,
  IonToolbar,
  IonButton,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Bill, BillsData, TransactionBaseType } from "../../../types";
import { getBills } from "../../../redux/actions";
import { useDispatch, useSelector } from "../../../redux/hooks";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import { closeOutline } from "ionicons/icons";
import { getBillsState } from "../../../redux/selectors";
import Amount from "../../shared/Amount/Amount";
import { cloneDeep } from "lodash";
import { CreateBillForm } from "./CreateBillForm";
import "./MarkTransactionAsBillModal.scss";
import { DatePicker } from "../../shared/DatePicker/DatePicker";
import { useResponsiveInterface } from "../../../hooks/useResponsiveInterface";
import { markBillFromTrans } from "../../../utils/bills";
import { trackEvent } from "../../../vendors/monitoring";

interface MarkTransactionAsBillModalProps {
  isOpen: boolean;
  onClose: () => void;
  transactionId: string;
  transactionDate: string;
  transactionName: string;
  transactionAmount: number;
  transactionBaseType: TransactionBaseType;
}

export const MarkTransactionAsBillModal: React.FC<MarkTransactionAsBillModalProps> = ({
  isOpen = false,
  onClose,
  transactionId,
  transactionDate,
  transactionAmount,
  transactionName,
  transactionBaseType,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _interface = useResponsiveInterface("alert");
  const { billsData } = useSelector(getBillsState);

  const [showCreateBill, setShowCreateBill] = useState(false);
  const [selectedBillId, setSelectedBillId] = useState<string>();
  const [billsList, setBillsList] = useState<Bill[]>([]);
  const [effectiveDate, setEffectiveDate] = useState<string>(
    dayjs(transactionDate).startOf("month").format("YYYY-MM-DD")
  );
  const formGood = Boolean(selectedBillId && effectiveDate);

  const clearAndClose = () => {
    setShowCreateBill(false);
    onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const effectiveDate = dayjs(transactionDate).startOf("month").format("YYYY-MM-DD");
    setEffectiveDate(effectiveDate);
    const billsForThisDate = billsData.find(
      (item: BillsData) => item.effectiveDate === effectiveDate
    );
    if (!billsForThisDate || !billsForThisDate.billsArray) {
      dispatch(getBills({ effectiveDate }));
      return;
    }
    if (billsForThisDate) {
      const newBills: Bill[] = cloneDeep(billsForThisDate.billsArray);
      if (newBills.length === 0) {
        return;
      }
      newBills.sort((a, b) => {
        const aName = a.nickname || a.name;
        const bName = b.nickname || b.name;
        return aName !== bName ? (aName < bName ? -1 : 1) : 0;
      });
      setBillsList(newBills);
      setSelectedBillId(newBills[0]._id);
    }
  }, [isOpen, billsData, transactionDate]);

  const handleMarkBill = () => {
    markBillFromTrans(selectedBillId, transactionId, effectiveDate, dispatch, clearAndClose);
  };

  return (
    <IonModal id="MarkTransactionAsBillModal" isOpen={isOpen} onDidDismiss={clearAndClose}>
      <ModalHeader
        title={t("markAsBill")}
        startBtnIcon={closeOutline}
        startBtnOnClick={clearAndClose}
      />
      {showCreateBill ? (
        <CreateBillForm
          onClose={clearAndClose}
          transactionId={transactionId}
          selectedManual={false}
          effectiveDate={effectiveDate}
          selectedBillId={selectedBillId}
        />
      ) : (
        <>
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <h1 className="font-md">{t("markTransAsBill_title")}</h1>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="8">
                  <h3 className="font-sm ion-no-margin">{transactionName}</h3>
                </IonCol>
                <IonCol size="4" className="ion-text-end">
                  <h3 className="font-sm ion-no-margin">
                    <Amount
                      baseType={transactionBaseType}
                      amount={transactionAmount}
                      showCents={false}
                    />
                  </h3>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <h1 className="font-md">{t("markTransAsBill_title2")}</h1>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonList lines="none">
              <IonItem>
                <IonLabel className="font-sm">{t("name")}</IonLabel>
                <IonSelect
                  slot="end"
                  value={selectedBillId}
                  data-testid="selectBill"
                  onIonChange={({ detail }) => setSelectedBillId(detail.value)}
                  interface={_interface}
                >
                  {billsList.map((bill, i) => {
                    return (
                      <IonSelectOption key={i} value={bill._id}>
                        {bill.nickname || bill.name}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel className="font-sm">{t("date")}</IonLabel>
                <DatePicker
                  min={dayjs(transactionDate)
                    .subtract(6, "month")
                    .startOf("month")
                    .format("YYYY-MM-DD")}
                  max={dayjs().add(1, "month").format("YYYY-MM-DD")}
                  displayFormat="MMMM"
                  value={effectiveDate}
                  onChange={(date) =>
                    setEffectiveDate(dayjs(date).startOf("month").format("YYYY-MM-DD"))
                  }
                  className="ml-auto"
                />
              </IonItem>
            </IonList>
          </IonContent>
          <IonFooter className="ion-no-border">
            <IonToolbar no-border className="ion-text-center">
              <IonButton
                fill="clear"
                data-testid="createNewBill"
                className={"ion-margin-bottom"}
                onClick={() => {
                  trackEvent("view_createNewBill", { category: "markAsBill" });

                  setShowCreateBill(true);
                }}
              >
                {t("createNewBill")}
              </IonButton>
              <IonButton
                disabled={!formGood}
                onClick={() => {
                  trackEvent("save_markAsBill", { category: "markAsBill" });
                  handleMarkBill();
                }}

                data-testid="saveMarkBill"
                className={"ion-margin-bottom"}
              >
                {t("save")}
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </>
      )}
    </IonModal>
  );
};
