import React, { useState, useEffect } from "react";
import { IonContent, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";

import { getLinkableAccounts } from "../../../redux/actions";
import { useDispatch, useSelector } from "../../../redux/hooks";
import { Goal, FinancialAccount } from "../../../types";
import { AccountPicker } from "./AccountPicker";
import { getGoalsState } from "../../../redux/selectors";
import { Modal } from "../../shared/Modal/Modal";
import { displayedAccountShort } from "../../../utils/formatUtils";
import { chevronDown, chevronUp } from "ionicons/icons";
import { Field } from "../../shared/Field/Field";

type FundingAccountPickerButtonProps = {
  goal: Goal;
  selectedId: string;
  onChange: (accountId: string) => void;
  label?: string;
};

export const FundingAccountPickerButton: React.FC<FundingAccountPickerButtonProps> = (props) => {
  const { goal, selectedId, onChange, label = "", children } = props;
  const dispatch = useDispatch();
  const fundingAccountId = useSelector(getGoalsState)?.accountInfo?.externalFundingAccountFinId;
  const [linkableAccounts, setLinkableAccounts] = useState<FinancialAccount[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  const selectedAccountId =
    selectedId ||
    goal.viewerFundingInfo?.fundingAccount?.id ||
    fundingAccountId ||
    linkableAccounts[0]?.id;
  const displayedAccount = linkableAccounts.find((account) => account.id === selectedAccountId);

  useEffect(() => {
    dispatch(getLinkableAccounts()).then((accounts) => {
      setLinkableAccounts(accounts.rows);
      if (!selectedId) onChange(accounts.rows[0]?.id);
    });
  }, []);

  return (
    <>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} title={t("selectAccount")}>
        <IonContent>
          {children}
          <AccountPicker
            rows={linkableAccounts}
            onSelect={(accountId) => {
              onChange(accountId);
              setModalOpen(false);
            }}
            selectedAccountId={selectedAccountId}
          />
        </IonContent>
      </Modal>
      <Field
        onClick={() => setModalOpen(!modalOpen)}
        testId="paydayAccountButton"
        label={label}
        icon={modalOpen ? chevronUp : chevronDown}
      >
        <IonText>
          {displayedAccountShort(displayedAccount) || t("selectAccount")}
        </IonText>
      </Field>
    </>
  );
};
