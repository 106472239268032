import { FinancialAccount } from "../types";
import _ from "lodash";

export const formatAccountNumber = (accountNumber: string) => {
  if (!accountNumber) return "";
  return `••${accountNumber.slice(-4)}`;
};

export const displayedAccountShort = (account?: FinancialAccount) => {
  if (!account) return "";
  const name = account.nickname || account.accountName;
  return !account.accountNumber || (name?.length ?? 21) <= 20
    ? name ?? ""
    : formatAccountNumber(account.accountNumber || "");
};

/**
 * Returns text as title cased
 * e.g., "HELLO WORLD" becomes "Hello World"
 * @param txt Text to title case
 * @returns
 */
export const titleCase = (txt: string) => {
  return _.startCase(_.toLower(txt));
};

/** Capitalizes the first letter of hyphened names **/
/** Uses Device capitalization normally **/
export const formattedName = (name: string = ""): string =>
  (name || "").replace(/(^|[\s-])\S/g, function (match: string) {
    return match.toUpperCase();
  });

export const aOrAnFilter = (input = "") =>
  input && "aeiou".indexOf(input.charAt(0).toLowerCase()) >= 0 ? "an" : "a";
