import React, { useEffect } from "react";
import { IonItem, IonLabel, IonList, IonToggle } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { PAGE_URLS } from "../../../../constants";
import { PageTemplate, Section, NavHeader } from "../../../shared/PageTemplate";
import { getDeviceDataState } from "../../../../redux/selectors";
import { useDispatch, useSelector } from "../../../../redux/hooks";
import { DeviceType } from "../../../../redux/reducers/deviceData";
import { put } from "../../../../utils/apiOps";
import { setLoading, updateDeviceInfo } from "../../../../redux/actions";
import { useHistory } from "react-router";
import { FingerprintAIO } from "@ionic-native/fingerprint-aio";

export const FingerprintAuthSetup: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { deviceDetails, fingerprintSignin }: DeviceType = useSelector(getDeviceDataState);
  const fpAuthName = deviceDetails?.platform === "ios" ? t("faceId") : t("fingerprintAuth");

  // Accounts for if you try to hit this page on web
  useEffect(() => {
    FingerprintAIO.isAvailable().catch((err) => {
      console.debug("FingerprintAIO not available", err);
      setTimeout(() => {
        history.push(PAGE_URLS.MORE_SETTINGS);
      }, 500);
    });
  }, []);

  const updateSettings = (checked: boolean) => {
    dispatch(setLoading(true));
    put(true, {
      endpoint: "/devices/update",
      bodyData: {
        deviceUUID: deviceDetails?.uuid,
        fingerprintSignin: checked,
      },
    })
      .then(() => dispatch(updateDeviceInfo({ fingerprintSignin: checked })))
      .catch((err) => {})
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <PageTemplate
      header={<NavHeader defaultHref={PAGE_URLS.MORE_SETTINGS} title={t("settings")} />}
    >
      <Section>
        <IonList lines="none">
          <IonItem className="ion-margin-top font-title-3">{fpAuthName}</IonItem>
          <IonItem className="ion-margin-top font-md">
            {t("fingerprintAuthMsg", {
              fpAuthName,
            })}
          </IonItem>

          <IonItem className="font-md ion-margin-top">
            <IonLabel>{fpAuthName}</IonLabel>
            <IonToggle
              slot="end"
              onIonChange={(e) => updateSettings(e.detail.checked)}
              checked={fingerprintSignin}
            />
          </IonItem>
        </IonList>
      </Section>
    </PageTemplate>
  );
};
