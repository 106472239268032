import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  IonLabel,
  IonList,
  IonListHeader,
  IonItemGroup,
  IonText,
  IonFooter,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import { getAccountsState } from "../../../redux/selectors";
import { AccountDetailModal } from "./AccountDetailModal";
import { findAccountById } from "../../../redux/reducers/accounts";
import { Account } from "./Account";
import { AddAccountOrPropertyForm } from "./AddAccountOrPropertyForm";
import { useUserName } from "../../shared/User/useUserName";
import { ShareSettingsButtonAndPopover } from "../../shared/User/ShareSettingsButtonAndPopover";
import "./AccountsTab.scss";
import { BUDGET_SHARED_TYPE } from "../../../constants";
import { NavButton, NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { HighLight } from "../../shared/Highlight/HighLight";
import { useViewport } from "../../../hooks/useViewport";
import { useDispatch } from "../../../redux/hooks";
import { getAccountData } from "../../../redux/actions";
import { PullToRefresh } from "../../shared/PullToRefresh/PullToRefresh";
import { addOutline } from "ionicons/icons";

enum tabType {
  HOUSEHOLD = "hh",
  USER = "user",
}

export const AccountsTab: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isDesktop } = useViewport();
  const { allBalances } = useSelector(getAccountsState);
  const [tab, setTab] = useState<tabType>(tabType.HOUSEHOLD);
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null);
  const displayedAccounts = allBalances[tab];
  const selectedAccount = findAccountById(allBalances, selectedAccountId);
  const { partnerName } = useUserName();

  if (!displayedAccounts) return null;
  const total = numeral(displayedAccounts.all.total).format("$0,0");
  const netWorth = (
    <IonItem lines="none" data-testid="networth-total">
      <IonLabel className="ion-text-start">
        <h1 className="font-xl ivy-semibold">{t("netWorth")}</h1>
      </IonLabel>
      <IonLabel className="ion-text-end ion-margin-end font-xl">
        {displayedAccounts.all.total < 0 ? <span>{total}</span> : <HighLight>{total}</HighLight>}
      </IonLabel>
    </IonItem>
  );
  return (
    <PageTemplate
      pageProps={{ className: "accounts-tab" }}
      contentProps={{ fullscreen: true }}
      header={
        <NavHeader showBackButton={false} title={t("balances")}>
          <AddAccountOrPropertyForm renderProps={(rest) => <NavButton
            label={t("add")}
            icon={<IonIcon icon={addOutline} />}
            {...rest}
          />} />
          <ShareSettingsButtonAndPopover
            isRoute={false}
            budgetType={
              tab === tabType.HOUSEHOLD ? BUDGET_SHARED_TYPE.HOUSEHOLD : BUDGET_SHARED_TYPE.USER
            }
            onClick={(tab: tabType) => setTab(tab)}
          />
        </NavHeader>
      }
      footer={isDesktop ? undefined : <IonFooter>{netWorth}</IonFooter>}
    >
      <PullToRefresh
        refreshHandler={(event) => {
          (dispatch(getAccountData()) as any)
            .catch(() => { })
            .finally(() => event.detail.complete());
        }}
      />

      {isDesktop && <Section>{netWorth}</Section>}

      {tab === "user" && (
        <IonText className="accounts-visibility-note ion-margin-top">
          {`${partnerName} cannot see these.`}
        </IonText>
      )}

      {["cash", "credit", "investments", "loans", "other", "property"].map((type) => (
        <Section key={type}>
          <AccountsOfType
            accounts={displayedAccounts}
            type={type}
            onSelect={setSelectedAccountId}
          />
        </Section>
      ))}
      <IonGrid>
        <IonRow>
          <IonCol className="ion-text-center">
            <AddAccountOrPropertyForm />
          </IonCol>
        </IonRow>
      </IonGrid>
      <AccountDetailModal account={selectedAccount} onClose={() => setSelectedAccountId(null)} />
    </PageTemplate>
  );
};

const AccountsOfType: React.FC<any> = (props: any) => {
  const { accounts, type, onSelect } = props;
  const { accts, total } = accounts[type.toLowerCase()];
  return accts.length ? (
    <IonList>
      <IonListHeader className="account-category-header">
        <h1 className="font-xl ivy-semibold">{type}</h1>
        <h1 className="font-xl" data-testid={`acctBal_${type}_amt`}>
          {numeral(total).format("$0,0")}
        </h1>
      </IonListHeader>
      <IonItemGroup data-testid={`account-group_${type}`}>
        {accts.map((account: any) => (
          <Account account={account} key={account._id} onSelect={onSelect} />
        ))}
      </IonItemGroup>
    </IonList>
  ) : null;
};
