import { Storage, DeviceInfo } from "@capacitor/core";
import { ALL_HOME_CARDS_ARRAY } from "../../constants";

import {
  SET_LOCAL_PERMISSION_HISTORY,
  UPDATE_DEVICE_INFO,
  UPDATE_DEVICE_DETAILS,
  UPDATE_HOME_CARD_PREFERENCES,
} from "../actionTypes";

export interface DeviceType {
  deviceUUID?: string;
  deviceId?: string;
  localPermissionHistory: LocalPermissionHistory;
  deviceDetails?: DeviceInfo;
  appInfo?: AppInfo;
  fingerprintSignin: boolean;
  balTiming?: string;
  balanceFinAcct?: string[];
  cardPreferences: CardPreferences;
}

export interface AppInfo {
  version: string;
  build: string;
}

export interface CardPreferences {
  cardObj: CardObj;
  cardArr: string[];
  cardAdded: CardAdded;
}

export interface CardAdded {
  "setup-fingerprint": boolean;
  "refer-friend-v2": boolean;
  advisor: boolean;
  "spending-challenges": boolean;
  forecast: boolean;
}

export interface CardObj {
  welcome: boolean;
  funfact: boolean;
  billsAudit: boolean;
  spendingByPerson: boolean;
}

export interface LocalPermissionHistory {
  softPermissionGranted: boolean;
  hardPermissionGranted: boolean;
  requests: number;
  lastRequested: number | null;
  neverAgain: boolean;
}

interface action {
  type: string;
  payload: any;
}

const initialState: DeviceType = {
  localPermissionHistory: {
    softPermissionGranted: false,
    hardPermissionGranted: false,
    requests: 0,
    lastRequested: null,
    neverAgain: false,
  },
  cardPreferences: {
    cardObj: {
      welcome: false,
      funfact: false,
      billsAudit: false,
      spendingByPerson: false,
    },
    cardArr: ALL_HOME_CARDS_ARRAY,
    cardAdded: {
      "setup-fingerprint": false,
      "refer-friend-v2": false,
      advisor: false,
      "spending-challenges": false,
      forecast: false,
    },
  },
  fingerprintSignin: false,
};

const updateLocalStorage = (deviceDataObj: DeviceType) => {
  Storage.set({ key: "deviceData", value: JSON.stringify(deviceDataObj) });
};

const deviceData = (state: DeviceType = initialState, action: action) => {
  switch (action.type) {
    case SET_LOCAL_PERMISSION_HISTORY: {
      const newState = {
        ...state,
        localPermissionHistory: {
          ...state.localPermissionHistory,
          ...(action.payload as LocalPermissionHistory),
        },
      };
      updateLocalStorage(newState);
      return newState;
    }
    case UPDATE_DEVICE_DETAILS: {
      const newState = {
        ...state,
        deviceDetails: action.payload as DeviceInfo,
      };
      updateLocalStorage(newState);
      return newState;
    }
    case UPDATE_DEVICE_INFO: {
      const newState = {
        ...state,
        ...(action.payload as DeviceType),
      };
      updateLocalStorage(newState);
      return newState;
    }
    case UPDATE_HOME_CARD_PREFERENCES: {
      const newState = {
        ...state,
        cardPreferences: action.payload as CardPreferences,
      };
      updateLocalStorage(newState);
      return newState;
    }
    default: {
      return state;
    }
  }
};

export default deviceData;
