import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { BUDGET_SHARED_TYPE, PAGE_URLS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { getBudgetAnalyticsState } from "../../../redux/selectors";
import dayjs from "dayjs";
import numeral from "numeral";
import "./CashFlowCard.scss";
import { BudgetItem, BudgetObj } from "../../../types";

const CashFlowCard: React.FC = () => {
  //TODO: remove from here, but leaving to make it easier to test/style
  const { t } = useTranslation();

  const { loading, budgetsArray } = useSelector(getBudgetAnalyticsState);
  const effectiveDate = dayjs().startOf("month").format("YYYY-MM-DD");
  const [budgetAnalyticsForDate, setBudgetAnalyticsForDate] = useState<BudgetObj>();
  const [netCashFlowAmount, setNetCashFlowAmount] = useState<number>(0);

  useEffect(() => {
    const budgetAnalyticsForThisDate = budgetsArray.find(
      (item: BudgetItem) => item.budgetAnalyticsDate === effectiveDate
    );
    if (!budgetAnalyticsForThisDate) {
      return;
    }
    const dataForChart = budgetAnalyticsForThisDate[BUDGET_SHARED_TYPE.HOUSEHOLD]?.budget;
    if (dataForChart) {
      setBudgetAnalyticsForDate(dataForChart);
      setNetCashFlowAmount(
        (budgetAnalyticsForDate?.Income.amountActual || 0) * -1 -
        (budgetAnalyticsForDate?.Expenses.amountActual || 0)
      );
    }
  }, [budgetsArray, effectiveDate, budgetAnalyticsForDate]);

  return (
    <IonCard
      id="CashFlowCard"
      routerLink={PAGE_URLS.CASH_FLOW.replace(":effectiveDate", effectiveDate).replace(
        ":budgetType",
        BUDGET_SHARED_TYPE.HOUSEHOLD
      )}
    >
      <IonCardHeader>
        <IonCardTitle className="ion-text-center">
          {dayjs(effectiveDate).format("MMMM")} {t("homeCards_cash-flow")}
        </IonCardTitle>
      </IonCardHeader>
      {loading ? (
        <IonCardContent className="ion-text-center">
          <IonSpinner />
        </IonCardContent>
      ) : (
        <IonCardContent>
          <IonGrid className="font-md ion-no-padding">
            <IonRow className="dashed-bottom-border ion-padding-vertical">
              <IonCol size="6" className="ion-no-padding">{t("income")}</IonCol>
              <IonCol className="ion-text-end ion-no-padding money-in" size="6">
                {numeral((budgetAnalyticsForDate?.Income.amountActual || 0) * -1).format("$0,0")}
              </IonCol>
            </IonRow>
            <IonRow className="ion-padding-vertical">
              <IonCol size="6" className="ion-no-padding">{t("expenses")}</IonCol>
              <IonCol className="ion-text-end ion-no-padding" size="6">
                {numeral((budgetAnalyticsForDate?.Expenses.amountActual || 0) * -1).format("$0,0")}
              </IonCol>
            </IonRow>
            <IonRow className="ion-padding-vertical">
              <IonCol size="6" className="ion-no-padding">{t("netCashFlow")}</IonCol>
              <IonCol className={`ion-no-padding ion-text-end ${netCashFlowAmount > 0 && "money-in"}`} size="6">
                {numeral(netCashFlowAmount).format("$0,0")}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      )}
    </IonCard>
  );
};

export default CashFlowCard;
