import React from "react";
import { IonCard, IonCardContent } from "@ionic/react";
import { useTranslation } from "react-i18next";
import images from "../../../assets/images/illustrations";
import { HomeCardCloseAlert } from "./HomeCardCloseAlert";
import { useSelector } from "../../../redux/hooks";
import { getUsersState } from "../../../redux/selectors";

export const WelcomeCard: React.FC = () => {
  const { t } = useTranslation();
  const { firstTime } = useSelector(getUsersState);

  return firstTime ? (
    <IonCard id="welcome-card">
      <HomeCardCloseAlert removedCard="welcome" />

      <IonCardContent className="ion-text-center ion-margin-horizontal ion-margin-top">
        <img src={images.welcome} alt="welcome" className="ion-margin-bottom" />
        <h1 className="font-title-3 half-padding">{t("welcomeCard_title")}</h1>
        <h3 className="font-md ion-margin-horizontal ion-padding-horizontal ion-margin-top">
          {t("welcomeCard_msg")}
        </h3>
      </IonCardContent>
    </IonCard>
  ) : null;
};
