import React, { useState, useEffect, useContext } from "react";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToolbar,
  IonFooter,
  IonGrid,
  IonTextarea,
  IonModal,
  IonText,
} from "@ionic/react";
import {
  flagOutline,
  informationCircleOutline,
  happyOutline,
  arrowUpCircleOutline,
  createOutline,
  chevronDown,
  calendarOutline,
} from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { PAGE_URLS } from "../../../constants";
import { getTransactionLink, transAccountNameHelper, transNameHelper } from "../../../utils/transUtils";
import { findTargetTransaction } from "../../../utils/transactions";
import {
  setToastMessage,
  getAllTransactions,
  setLoading,
  getBills,
  updateTransactionDetail,
  deleteManualTransaction,
} from "../../../redux/actions";
import {
  Transaction,
  SaveReaction,
  FindSimilarTransactionType,
  AddComments,
  ReactionType,
  TransactionModalType,
  TransactionDetailURLParams,
  TransactionSharedExpense,
  TransactionStatus,
} from "../../../types/transactions";
import { saveReaction, saveUnsplitTransaction, saveComments } from "../../../utils/transactions";
import { angry, like, sad, love, wow, happy } from "../../../assets/images/reactions";
import Amount from "../../shared/Amount/Amount";
import {
  getAccountsState,
  getBillsState,
  getCategoryState,
  getTransactionState,
  getUsersState,
} from "../../../redux/selectors";
import { useSelector, useDispatch } from "../../../redux/hooks";
import { AccountDetailModal } from "../AccountsTab/AccountDetailModal";
import { findAccountById } from "../../../redux/reducers/accounts";
import {
  getCatDisplayName,
  getCategoryObject,
  getSubcatDisplayName,
} from "../../../utils/categorizationUtils";
import Avatars from "../../shared/Avatar/Avatars";
import { Recategorization } from "./Recategorization";
import { EditSharedStatus } from "./EditSharedStatus";
import { PartialScreenModal } from "../../shared/Modal/PartialScreenModal";
import "./Transaction.scss";
import "./TransactionDetail.scss";
import dayjs from "dayjs";
import { NavContext } from "@ionic/react";
import { Reactions } from "../../shared/Reactions/Reactions";
import { useUserName } from "../../shared/User/useUserName";
import utc from "dayjs/plugin/utc";
import { Comments } from "../../shared/Comments/Comments";
import images from "../../../assets/images/categorization";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import { RuleDetailItem } from "../MoreTab/RuleDetailItem";
import { ActionSheet, NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { useViewport } from "../../../hooks/useViewport";
import { startCase } from "lodash";
import { Bill } from "../../../types";
import { BillsState } from "../../../redux/reducers/bills";
import { UnmarkBillAlert } from "../Budget/UnmarkBillAlert";
import { CategoryImg } from "../../shared/Miscellaneous/CategoryImg";
import { Field } from "../../shared/Field/Field";
import { CreateRuleModal } from "./CreateRuleModal";
import { TransactionRuleTypes } from "../../../types/transactionRules";
import { trackEvent } from "../../../vendors/monitoring";
import { DatePicker } from "../../shared/DatePicker/DatePicker";

dayjs.extend(utc);

enum ModalType {
  NONE = "",
  DATE = "EDIT_DATE",
}

enum AlertType {
  NICKNAME = "EDIT_NICKNAME",
  SPLIT = "SPLIT_TRANSACTION",
  DELETE = "DELETE_TRANSACTION",
  NONE = "",
}

export const TransactionDetail: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const dispatch = useDispatch();
  const history = useHistory();
  const { transactionId } = useParams<TransactionDetailURLParams>();
  const { navigate, goBack } = useContext(NavContext);
  const { userName, singlePlayerMode, partnerName } = useUserName();
  const recategorizationImg: any = {
    ...images,
  };

  const actionSheetButtons = [
    {
      text: t("findSimilarTransactions"),
      handler: () => {
        trackEvent("click_findSimilarTransactions", { category: "detailTrans" });
        similarTransaction();
      },
    },
    {
      text: t("searchGoogleForMerchant"),
      handler: () => {
        trackEvent("click_searchGoogleMerch", { category: "detailTrans" });
        searchGoogle();
      },
    },
    {
      text: t("venmoRequest"),
      handler: () => {
        trackEvent("click_venmoRequest", { category: "detailTrans" });
        venmoRequest();
      },
    },
    t("cancel"),
  ];

  //redux state
  const { userId } = useSelector(getUsersState);
  const accountState = useSelector(getAccountsState);
  const { billsData }: BillsState = useSelector(getBillsState);
  const { categoryData, subCategoryData } = useSelector(getCategoryState);
  const { transactionsList } = useSelector(getTransactionState);

  const transDetail = transactionsList.filter(
    (transaction: Transaction) =>
      transaction._id === transactionId || transaction.isChildOfTxnId === transactionId
  )[0];

  //state
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null);
  const selectedAccount = findAccountById(accountState.allBalances, selectedAccountId);
  const [openModal, setOpenModal] = useState<TransactionModalType>();
  const [openAlert, setOpenAlert] = useState<AlertType>();
  const [openPartialModal, setOpenPartialModal] = useState<ModalType>();
  const [date, setDate] = useState<string>(dayjs.utc(transDetail?.date).format("YYYY-MM-DD"));
  const [targetTransaction, setTargetTransaction] = useState(transDetail);
  const [nickNameValue, setNickNameValue] = useState<string>(
    transDetail ? transNameHelper(transDetail) : ""
  );
  const [reactionOpen, setReactionOpen] = useState({ showPopover: false, event: undefined });
  const [selectedReaction, setSelectedReaction] = useState<ReactionType>(
    transDetail?.reactions[0]
      ? transDetail.reactions.find((reaction) => reaction.userId === userId)?.reaction || ""
      : ""
  );
  const selectedEmoji = { angry, like, sad, love, wow, happy, "": undefined }[selectedReaction];
  const [reactionId, setReactionId] = useState<string>(
    transDetail?.reactions[0] ? transDetail.reactions[0]._id : ""
  );
  const [chatMessage, setChatMessage] = useState<string>("");
  const [showRuleAlert, setShowRuleAlert] = useState(false);

  useEffect(() => {
    findTargetTransaction(
      { transactionId },
      setTargetTransaction,
      setSelectedReaction,
      setReactionId,
      setNickNameValue
    );
  }, [transactionId, transDetail?.rules?.length]);

  const [billDetail, setBillDetail] = useState<Bill>();
  const [showUnmarkBillPaid, setShowUnmarkBillPaid] = useState(false);

  useEffect(() => {
    if (!targetTransaction || !targetTransaction.isBill) return;
    if (!targetTransaction.bills || targetTransaction.bills.length === 0) return;
    if (billsData.length === 0) return;

    const billItem = targetTransaction?.bills[0];
    if (!billItem) return;

    const effectiveDate = dayjs.utc(billItem.billMonth).format("YYYY-MM-DD");
    const billsForThisDate = billsData.find((item) => item.effectiveDate === effectiveDate);
    if (!billsForThisDate || !billsForThisDate.billsArray) {
      dispatch(getBills({ effectiveDate: effectiveDate }));
      return;
    }
    setBillDetail(
      billsForThisDate.billsArray.find((b) =>
        billItem.billId._id ? b._id === billItem.billId._id : b._id === billItem.billId
      )
    );
  }, [billsData, targetTransaction]);

  //method
  const showRelatedActionSheetButtons = () => {
    let _actionSheetButtons = [...actionSheetButtons];
    if (
      targetTransaction &&
      targetTransaction.isChild &&
      targetTransaction.status !== TransactionStatus.PENDING
    ) {
      _actionSheetButtons.splice(0, 1, {
        text: t("unsplitTransaction"),
        handler: () => setOpenAlert(AlertType.SPLIT),
      });
    }

    if (targetTransaction && !targetTransaction.isChild && targetTransaction.isManual) {
      _actionSheetButtons.splice(3, 0, {
        text: t("deleteTransaction"),
        handler: () => setOpenAlert(AlertType.DELETE),
      });
    }
    return _actionSheetButtons;
  };

  const handleUnsplitTransaction = () => {
    dispatch(setLoading(true));
    navigate(PAGE_URLS.TRANS_HOME, "back");
    setTimeout(() =>
      saveUnsplitTransaction(
        { origTransactionId: targetTransaction.isChildOfTxnId as string },
        dispatch
      )
        .catch()
        .finally(() => dispatch(setLoading(false)))
    );
  };

  const handleTransactionDateUpdate = () => {
    let dateBodyData = {
      transactionId,
      date: dayjs(date).format("YYYY-MM-DD"),
    };
    dispatch(updateTransactionDetail(dateBodyData));
    findTargetTransaction({ transactionId }, setTargetTransaction);
    setOpenPartialModal(ModalType.NONE);
  };

  const handleNickNameUpdate = (nickname: string) => {
    if (nickname) {
      dispatch(setLoading(true));
      let nicknameBodyData = { transactionId, nickname };
      dispatch(updateTransactionDetail(nicknameBodyData));
      setNickNameValue(nickname);
      findTargetTransaction({ transactionId }, setTargetTransaction);
      dispatch(setLoading(false));
      return;
    }
    dispatch(setToastMessage(t("nickNameCantBeEmpty")));
  };

  const venmoRequest = () => {
    const url =
      "https://venmo.com/?txn=charge&audience=private&amount=" +
      targetTransaction.amount.amount +
      "&note=" +
      transNameHelper(targetTransaction) +
      " via Honeyfi";
    window.open(encodeURI(url), "_system", "location=no");
  };

  const searchGoogle = () => {
    const url = "https://www.google.com/search?q=" + targetTransaction.description.original;
    window.open(encodeURI(url), "_blank", "location=no");
  };

  const similarTransaction = () => {
    findTargetTransaction({ transactionId }, setTargetTransaction);
    const params = {} as FindSimilarTransactionType;
    dispatch(setLoading(true));
    if (targetTransaction.description.nameHash) {
      params.nameHash = Number(targetTransaction.description.nameHash);
      params.merchName = transNameHelper(targetTransaction);
      params.isPrivate = "both";
    } else {
      params.keyword = transNameHelper(targetTransaction);
    }
    history.push(getTransactionLink("advancedSearch", params));
  };

  const handleReactionSelect = async (event: any) => {
    if (event.target.alt !== selectedReaction) {
      setSelectedReaction(event.target.alt);
      let addBodyData = {
        reaction: event.target.alt,
        reactionId,
        transactionId: targetTransaction._id,
        isPrivate: "both",
      } as SaveReaction;
      await saveReaction(addBodyData, setReactionId).then(() => dispatch(getAllTransactions()));
      setReactionOpen({ showPopover: false, event: undefined });
    }
  };

  const handleCommentsSave = () => {
    let bodyData = {
      message: chatMessage,
      transactionId,
      userFirstName: userName,
      isPrivate: "both",
    } as AddComments;
    dispatch(setLoading(true));
    saveComments(bodyData, dispatch)
      .then(() => {
        findTargetTransaction({ transactionId }, setTargetTransaction);
      })
      .then(() => setChatMessage(""))
      .catch()
      .finally(() => dispatch(setLoading(false)));
  };

  const handleCommentsRemove = (commentId: string) => {
    let bodyData = {
      transactionId,
      commentId,
    };
    dispatch(setLoading(true));
    dispatch(updateTransactionDetail(bodyData))
      .then(() => {
        findTargetTransaction({ transactionId }, setTargetTransaction);
      })
      .catch()
      .finally(() => dispatch(setLoading(false)));
  };

  const footerSection = (
    <footer className="transaction-detail-comments d-flex ion-align-items-end brand-base bottom-of-section ion-margin">
      <IonIcon
        icon={happyOutline}
        size="large"
        onClick={(e: any) => {
          e.persist();
          setReactionOpen({ showPopover: true, event: e });
        }}
        data-testid="transactionReactionIcon"
      ></IonIcon>
      {reactionOpen ? (
        <Reactions
          onDidDismiss={() => setReactionOpen({ showPopover: false, event: undefined })}
          handleReactionSelect={handleReactionSelect}
          isOpen={reactionOpen.showPopover}
          event={reactionOpen.event}
        />
      ) : null}
      <IonTextarea
        rows={1}
        value={chatMessage}
        placeholder={t("addComment")}
        onIonChange={(e) => setChatMessage(e.detail.value || "")}
        autoGrow
        inputMode="text"
        className="ion-no-margin"
        data-testid="transactionCommentsInput"
        enterkeyhint="send"
        onKeyPress={(e) => e.key === "Enter" && handleCommentsSave()}
      />
      <IonButton
        onClick={handleCommentsSave}
        data-testid="transactionCommentsBtn"
      >Send</IonButton>
    </footer>
  );

  return targetTransaction ? (
    <PageTemplate
      pageProps={{ className: "TransactionDetailPage", "data-testid": "TransactionDetailPage" }}
      header={
        <NavHeader title={t("detail")} defaultHref={PAGE_URLS.TRANS_HOME}>
          <ActionSheet
            header={t("transaction")}
            buttonProps={{
              size: "medium",
              color: "primary",
              "data-testid": "transaction-detail-actions",
            }}
            buttons={showRelatedActionSheetButtons()}
          />
        </NavHeader>
      }
      footer={isMobile ? <IonFooter>{footerSection}</IonFooter> : undefined}
    >
      <Section>
        <IonRow className="transaction-detail-header ion-padding-horizontal">
          <IonCol onClick={() => setOpenAlert(AlertType.NICKNAME)} data-testid="changeNickname">
            <h3 className="font-md transaction-items-header ion-no-margin ion-margin-vertical">
              {nickNameValue}
            </h3>
          </IonCol>
          <IonCol size="4" className="ion-text-end">
            <h6 className="font-md transaction-items-header ion-no-margin ion-margin-top">
              <Amount
                baseType={targetTransaction.baseType}
                amount={targetTransaction.amount.amount}
                showCents
              />
            </h6>

            {targetTransaction.status === TransactionStatus.PENDING && (
              <h6 className="font-sm placeholder-text transaction-items-header ion-no-margin">{`(${t(
                "pending"
              )})`}</h6>
            )}
          </IonCol>
        </IonRow>
        <IonToolbar className="bottom-of-section">
          <h2 className="ion-padding-start font-title-3 fy-section-title-border">
            {t("transaction")}
          </h2>
          <IonList>
            <Field
              label={t("onStatement")}
              icon={createOutline}
              testId="editTransactionName"
              onClick={() => setOpenAlert(AlertType.NICKNAME)}
            >
              <IonText className="font-md">{targetTransaction.description.original}</IonText>
            </Field>

            <Field
              label={t("account")}
              icon={informationCircleOutline}
              testId="editTransactionAccount"
              onClick={() => {
                trackEvent("view_acct", { category: "account" });
                setSelectedAccountId(targetTransaction.accountId);
              }}
            >
              <IonText className="font-md">
                {transAccountNameHelper(accountState, targetTransaction.accountId)}
              </IonText>
            </Field>

            <AccountDetailModal
              account={selectedAccount}
              onClose={() => setSelectedAccountId(null)}
            />

            <Field
              label={t("date")}
              icon={calendarOutline}
              onClick={() => setOpenPartialModal(ModalType.DATE)}
              testId="editTransactionDate"
            >
              <IonText className="font-md">{dayjs(date).format("dddd, M/D/YY")}</IonText>
            </Field>
          </IonList>
        </IonToolbar>
      </Section>
      <Section>
        <IonToolbar className="bottom-of-section">
          <h2 className="ion-padding-start font-title-3 fy-section-title-border">
            {t("organization")}
          </h2>
          <IonList>
            <Field
              label={t("category")}
              icon={informationCircleOutline}
              onClick={() => {
                trackEvent("view_categorization", { category: "categorization" });
                setOpenModal(TransactionModalType.RECATEGORIZATION);
              }}
              testId="transactionDetail_category"
            >
              <IonText className="font-md text-no-wrap">
                <CategoryImg
                  category={getCategoryObject(
                    targetTransaction.hhInfo[0].beefCategoryName,
                    categoryData
                  )}
                  imgProps={{ className: "ion-margin-end" }}
                />
                {t(
                  getCatDisplayName(targetTransaction.hhInfo[0].beefCategoryName, categoryData) ||
                  "category"
                )}{" "}
                |{" "}
                {t(
                  getSubcatDisplayName(
                    targetTransaction.hhInfo[0].honeyfiCategoryName,
                    subCategoryData
                  ) || "subCategory"
                )}
              </IonText>
            </Field>
            {openModal === TransactionModalType.RECATEGORIZATION ? (
              <Recategorization
                onClose={(newState: TransactionModalType) => setOpenModal(newState)}
                transaction={targetTransaction}
                sendApi={true}
                updateTargetTransaction={() =>
                  findTargetTransaction({ transactionId }, setTargetTransaction)
                }
              />
            ) : null}

            {singlePlayerMode ? null : (
              <Field
                label={t("owner")}
                icon={informationCircleOutline}
                testId="editTransactionSharing"
                onClick={() => {
                  trackEvent("view_transOwner", { category: "transOwner" });
                  setOpenModal(TransactionModalType.EDIT_SHARED_STATUS);
                }}
              >
                <IonText className="font-md">
                  <Avatars
                    hhInfoType="transaction"
                    hhInfo={targetTransaction.hhInfo[0]}
                    sizeStyle="xs"
                    diagonal
                    className="ion-margin-end"
                  />
                  {targetTransaction.hhInfo[0].sharedExpense === TransactionSharedExpense.SHARED &&
                    t("household")}

                  {targetTransaction.hhInfo[0].isPrivate && startCase(t("private"))}

                  {!targetTransaction.hhInfo[0].isPrivate &&
                    targetTransaction.hhInfo[0].sharedExpense === TransactionSharedExpense.VISIBLE
                    ? targetTransaction.hhInfo[0].sharedExpenseIndividual.some(
                      (id) => id === userId
                    )
                      ? t("me")
                      : partnerName
                    : null}
                </IonText>
              </Field>
            )}

            {openModal === TransactionModalType.EDIT_SHARED_STATUS ? (
              <EditSharedStatus
                onClose={(newState: TransactionModalType) => setOpenModal(newState)}
                hhInfo={targetTransaction?.hhInfo[0]}
                transactionOwner={targetTransaction?.transactionOwner}
                transactionId={targetTransaction?._id}
              />
            ) : null}

            {openModal === TransactionModalType.CATEGORY_RULE ||
              openModal === TransactionModalType.SHARED_STATUS_RULE ? (
              <CreateRuleModal
                isOpen={true}
                transactionRuleType={
                  openModal === TransactionModalType.CATEGORY_RULE
                    ? TransactionRuleTypes.CATEGORY
                    : TransactionRuleTypes.YOM
                }
                transactionId={transDetail._id}
                onClose={() => setOpenModal(TransactionModalType.NONE)}
              />
            ) : null}

            {/* TO DO - Add on click action for bill */}
            {targetTransaction.isBill && targetTransaction?.bills && billDetail && (
              <>
                <Field
                  label={t("bill")}
                  icon={informationCircleOutline}
                  onClick={() => setShowUnmarkBillPaid(true)}
                >
                  <IonText className="font-md">{billDetail?.nickname || billDetail.name}</IonText>
                </Field>
                <UnmarkBillAlert
                  billId={billDetail._id}
                  paidSource={billDetail.paidSource || "transaction"}
                  transactionId={billDetail.transactionId}
                  paidDate={dayjs
                    .utc(targetTransaction.bills[0].billMonth)
                    .date(billDetail.day)
                    .toDate()}
                  isOpen={showUnmarkBillPaid}
                  onClose={() => setShowUnmarkBillPaid(false)}
                />
              </>
            )}

            {targetTransaction.rules && targetTransaction.rules.length > 0 && (
              <>
                <Field
                  label={t("rule")}
                  icon={informationCircleOutline}
                  onClick={() => setShowRuleAlert(true)}
                >
                  <IonText className="font-md">
                    <IonIcon
                      data-testid="IonIcon_flag"
                      icon={flagOutline}
                      className="ion-margin-end"
                    />
                    {t("ruleAppliedMesg")}
                  </IonText>
                </Field>

                <IonModal
                  id="TransactionRuleAlertModal"
                  isOpen={showRuleAlert}
                  onDidDismiss={() => setShowRuleAlert(false)}
                >
                  <ModalHeader title={t("ruleApplied")} />
                  <IonContent>
                    <RuleDetailItem ruleId={targetTransaction.rules[0]} />
                    <div className="ion-text-center">
                      <IonButton fill="clear" slot="start" onClick={() => setShowRuleAlert(false)}>
                        {t("cancel")}
                      </IonButton>
                      <IonButton
                        slot="end"
                        routerLink={PAGE_URLS.MORE_SETTINGS_RULES}
                        onClick={() => setShowRuleAlert(false)}
                      >
                        {t("manageRules")}
                      </IonButton>
                    </div>
                  </IonContent>
                </IonModal>
              </>
            )}
          </IonList>
        </IonToolbar>
      </Section>
      <Section>
        <IonToolbar>
          <h2 className="ion-padding-start font-title-3 fy-section-title-border">
            {t("comments")}
          </h2>
          <IonList lines="none" className="ion-padding-start">
            {selectedReaction ? (
              <IonItem className="transaction-comments-reaction ion-justify-content-between">
                <img src={selectedEmoji} alt={selectedReaction} />
                <p>{t("reactedDes")}</p>
              </IonItem>
            ) : null}
          </IonList>
          <IonList className="ion-padding-start">
            <Comments
              transaction={targetTransaction}
              clickEvents="edit"
              handleCommentsRemove={handleCommentsRemove}
            />
          </IonList>
        </IonToolbar>
        {isMobile ? null : footerSection}
      </Section>
      {openAlert === AlertType.NICKNAME ? (
        <IonAlert
          isOpen={openAlert === AlertType.NICKNAME}
          onDidDismiss={() => setOpenAlert(AlertType.NONE)}
          header={t("editTransactionName")}
          message={t("editTransNameDes")}
          inputs={[
            {
              name: "transactionName",
              type: "text",
              value: nickNameValue,
              id: "transactionNameEdit",
              attributes: { "data-testid": "transactionNameInput" } as any,
            },
          ]}
          buttons={[
            {
              text: t("close"),
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                setOpenAlert(AlertType.NONE);
              },
            },
            {
              cssClass: "transactionNameUpdate",
              text: t("update"),
              role: "submit",
              handler: (e) => {
                handleNickNameUpdate(e.transactionName);
              },
            },
          ]}
        />
      ) : null}
      {openAlert === AlertType.SPLIT ? (
        <IonAlert
          isOpen={openAlert === AlertType.SPLIT}
          onDidDismiss={() => setOpenAlert(AlertType.NONE)}
          header={t("unSplitTransaction")}
          message={t("unSplitTransactionDes")}
          buttons={[
            {
              text: t("no"),
              role: "cancel",
              handler: () => setOpenAlert(AlertType.NONE),
            },
            {
              text: t("yes"),
              role: "okay",
              handler: () => handleUnsplitTransaction(),
            },
          ]}
        />
      ) : null}

      {openAlert === AlertType.DELETE ? (
        <IonAlert
          isOpen
          onDidDismiss={() => setOpenAlert(AlertType.NONE)}
          header={t("deleteTransactionTitle")}
          message={t("confirmDeleteTransaction")}
          buttons={[
            {
              text: t("cancel"),
              role: "cancel",
              handler: () => setOpenAlert(AlertType.NONE),
            },
            {
              text: t("delete"),
              role: "okay",
              handler: () =>
                dispatch(deleteManualTransaction(targetTransaction._id)).then(() =>
                  goBack(PAGE_URLS.TRANS_HOME)
                ),
            },
          ]}
        />
      ) : null}

      <PartialScreenModal
        open={openPartialModal === ModalType.DATE}
        onClose={() => setOpenPartialModal(ModalType.NONE)}
        title={t("editTransactionDate")}
        subTitle={t("editTransDateDes")}
        onAccept={handleTransactionDateUpdate}
        className="height-sm"
      >
        <IonToolbar className="ion-text-center ion-padding">
          <DatePicker
            displayFormat="M/D/YYYY"
            value={date}
            max={dayjs().format("YYYY-MM-DD")}
            className="partial-modal-input"
            onChange={setDate}
            data-testid="transactionDateEdit"
          />
        </IonToolbar>
      </PartialScreenModal>
    </PageTemplate>
  ) : null;
};
