import React, { useEffect } from "react";
import { IonButton } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "../../../redux/hooks";
import { getTransactionState } from "../../../redux/selectors";
import { getAllTransactions, refreshTransactions } from "../../../redux/actions";
import { Section } from "../../shared/PageTemplate";
import { ListWithDateDivider } from "../../shared/ListWithDateDivider/ListWithDateDivider";
import { PullToRefresh } from "../../shared/PullToRefresh/PullToRefresh";
import { extractTransactionSearchParams } from "../../../utils/transUtils";
import { useLocation } from "react-router-dom";
import { isEqual } from "lodash";
import { PAGE_URLS } from "../../../constants";
import { RefresherToast } from "./RefresherToast";

export const TransactionList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { transactionsList, context, searchParams, loading } = useSelector(getTransactionState);

  useEffect(() => {
    const { context, searchParams: queryParams } = extractTransactionSearchParams(location.search);
    if (!location.pathname.includes(PAGE_URLS.TRANS_HOME) || isEqual(queryParams, searchParams)) return;
    dispatch(getAllTransactions(context, queryParams));
  }, [location.search, location.pathname]);

  const getMoreTransactions = (event: CustomEvent<HTMLIonInfiniteScrollElement>) => {
    const target = event.target as HTMLIonInfiniteScrollElement;
    dispatch(
      getAllTransactions(context, {
        ...searchParams,
        pageNumber: searchParams.pageNumber! + 1,
      })
    ).finally(() => target.complete());
  };

  return (
    <>
      <PullToRefresh
        refreshHandler={({ detail }) => dispatch(refreshTransactions()).finally(() => detail.complete())}
      />
      <RefresherToast onTap={() => dispatch(refreshTransactions())} />
      {transactionsList && transactionsList.length !== 0 ? (
        <ListWithDateDivider
          items={transactionsList}
          itemsType="transactions"
          itemsGroupProp="date"
          infiniteScrollHandler={getMoreTransactions}
        />
      ) : (
        loading === false && (
          <Section>
            <div className="ion-text-center">
              <h4 className="font-md ion-text-center" data-testid="TransactionNoList">
                {t("noTransactionsFound")}
              </h4>
              {context === "search" && (
                <IonButton onClick={() => dispatch(getAllTransactions())}>{t("viewAll")}</IonButton>
              )}
            </div>
          </Section>
        )
      )}
    </>
  );
};
