import React from "react";
import { IonLabel, IonList, IonListHeader, IonTitle, IonItem, IonText, IonSpinner } from "@ionic/react";
import numeral from "numeral";
import "./SpendingAnalysis.scss";
import { useSelector } from "react-redux";
import { BudgetPieChart } from "../Budget/BudgetPieChart";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import { BUDGET_SHARED_TYPE, PAGE_URLS } from "../../../constants";
import { BudgetCategoryObj, BudgetSubcategoryObj } from "../../../types/budgetAnalytics";
import { CategoryButtons } from "./CategoryButtons";
import { Category } from "../../../types/category";
import { getUsersState } from "../../../redux/selectors";
import { Section } from "../../shared/PageTemplate";
import { getTransactionLink } from "../../../utils/transUtils";
type SpendingAnalysisProps = {
  totalSpending: number;
  category?: BudgetCategoryObj;
  categoryDetails?: Category;
  onIndexChange: (diff: -1 | 1) => (event: any) => void;
  loading?: boolean;
  month?: Dayjs;
  visibility?: "hh" | "user";
  includeSubCategoryList?: boolean;
};

export const SpendingAnalysis: React.FC<SpendingAnalysisProps> = (props) => {
  const { t } = useTranslation();
  const { singlePlayerMode } = useSelector(getUsersState);
  const {
    category,
    categoryDetails,
    totalSpending,
    onIndexChange,
    includeSubCategoryList = false,
    visibility = "hh",
    month = dayjs(),
    loading = false,
  } = props;

  const Wrapper = includeSubCategoryList ? Section : React.Fragment;

  if (!totalSpending)
    return (
      <Wrapper>
        <IonText className="ion-text-center ion-padding ion-margin">
          <h2>
            {loading ? <IonSpinner name="crescent" /> : t("zeroSpendingMonth", {
              month: month.format("MMMM"),
              type: t(singlePlayerMode ? "" : visibility === "hh" ? "householdLower" : "private"),
            })}
          </h2>
        </IonText>
      </Wrapper>
    );

  const chartData = [
    { id: "other-categories", value: totalSpending - (category?.amountActual || 0) },
    { id: "category-category", value: category?.amountActual || 0 },
  ];
  const subCategories = Object.values(category?.subs || {}).filter((sub) => sub.amountActual);

  return (
    <div className="spending-analysis">
      <Wrapper>
        <div className="main-container">
          <div className="percentage-label-container flex-center">
            <div className="percentage-label font-xl semibold">
              {numeral((category?.amountActual || 0) / totalSpending).format("0%")}
            </div>
          </div>
          <BudgetPieChart
            budgetAnalyticsDataForPieChart={chartData}
            primaryColor={categoryDetails?.design.bgColor}
          />
          {!includeSubCategoryList && <hr className="hidden-mobile" />}
          <CategoryButtons
            category={category!}
            categoryDetails={categoryDetails!}
            onChange={onIndexChange}
          />
        </div>
      </Wrapper>
      {includeSubCategoryList && subCategories?.length ? (
        <Wrapper>
          <IonList>
            <IonListHeader>
              <IonTitle className="ion-no-padding">
                {t("categoryBreakdown", { category: category!.displayName })}
              </IonTitle>
            </IonListHeader>
            {subCategories.map((sub) => (
              <IonItem
                key={sub._id}
                lines="none"
                disabled={!sub.amountActual}
                routerLink={getTransactionLink("advancedSearch", {
                  subCategoryFilter: sub.subCategory,
                  budgetMonth: dayjs(month).startOf("month").format("YYYY-MM-DD"),
                  isPrivate: visibility === BUDGET_SHARED_TYPE.USER,
                })}
              >
                <IonLabel slot="start">{sub.displayName}</IonLabel>
                <IonText slot="end" className="ion-text-end">
                  {numeral(sub.amountActual).format("$0,0")}
                </IonText>
              </IonItem>
            ))}
          </IonList>
        </Wrapper>
      ) : null}
    </div>
  );
};
