import React from "react";
import { IonIcon } from "@ionic/react";
import { disc } from "ionicons/icons";
import { Account, AccountSource } from "../../../redux/reducers/accounts";
import { bankFilled } from "../../../assets/icons/generic";
import "./AccountLogo.scss";

type AccountLogoProps = {
  account: Partial<Account>;
  className?: string;
};

export const AccountLogo: React.FC<AccountLogoProps> = ({ account, className = "" }) => {
  if (account.source === AccountSource.COREPRO) {
    return <IonIcon icon={disc} className={`account-logo-icon ${className}`} />; // placeholder for Firstly logo
  }
  if (!account.logo) {
    return <IonIcon icon={bankFilled} className={`account-logo-icon generic ${className}`} />; // generic bank logo
  }
  return (
    <img
      alt="bank-logo"
      src={`data:image/png;base64,${account.logo}`}
      className={`account-logo-icon ${className}`}
    />
  );
};
