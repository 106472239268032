import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone"; // needed for guessing user timezone
// import * as intercom from "../../vendors/intercom";
import { post } from "../../utils/apiOps";
import { Auth } from "@aws-amplify/auth";
import type { Dispatch } from "redux";
import { UserMemberTypes, userState } from "../reducers/users";
import { Plugins } from "@capacitor/core";
import { setUserProperties, pushInit, saveUserProperties } from "../actions";

import {
  getAccountData,
  getVersionCheckData,
  getHouseholdData,
  getAllTransactions,
  getSpendingChallenges,
} from "../actions";
import { getBudget, getSpendingByPersonData, refreshBudget, refreshFeeds } from "./budgetAnalytics";
import { getAllViewerGoals, getViewerAccountInfo } from "./goal";
import { getAllCategorizationData } from "./category";
import constants, { onboardingFlags, PAGE_URLS } from "../../constants";
import { setMonitoringUser, trackEvent } from "../../vendors/monitoring";
import { getCurrentOnboaringRoute } from "../../utils/routeUtils";
import { getBills } from "./bills";
import { getTransactionRules } from "./transactionRules";
import { getSpendingForecastSummary } from "./spendingForecast";
import { intercomRegisterAnonUser } from "../../vendors/intercom";
import { versionCheckResponse } from "../reducers/versioncheck";
import { refreshTransactions } from "./transactions";
dayjs.extend(timezone);

const { Storage, Device } = Plugins;

const PERSISTENT_FIELDS = [
  "userId",
  "userName",
  "magicLink",
  "hhIdDefault",
  "firstName",
  "lastName",
  "partnerName",
  "flag",
  "singlePlayerMode",
];

export const initGlobalData =
  (verionCheckData?: versionCheckResponse) => async (dispatch: Dispatch<any>) => {
    console.debug("start initGlobalData");
    let currentUser;
    try {
      dispatch(getVersionCheckData(verionCheckData));
      currentUser = await Auth.currentAuthenticatedUser();
      await Auth.currentSession();
      if (!currentUser || !currentUser.signInUserSession.accessToken.jwtToken) {
        return PAGE_URLS.WELCOME;
      }
      const deviceInfo = await Device.getInfo();
      const postLoginData = {
        deviceType: deviceInfo.platform,
        deviceUUID: deviceInfo.uuid,
      };
      const postLoginCheckResp = await post(true, {
        endpoint: "/user/postLoginCheck",
        bodyData: postLoginData,
      });
      const hhIdDefault = postLoginCheckResp.data.user.hhIdDefault;
      const { _id, firstName, lastName, flag, houseHolds, email, createdOn } =
        postLoginCheckResp.data.user;
      const userName = firstName || "";
      const memberType = houseHolds[0]?.pNum || UserMemberTypes.P1;

      const hoursDiff = dayjs().diff(dayjs(createdOn), "hours");
      const firstTime = hoursDiff <= constants.FIRST_TIME_UX;
      const firstWeekTime = hoursDiff <= constants.FIRST_WEEK_UX;

      dispatch(
        saveUserProperties({
          userId: _id,
          userName,
          hhIdDefault,
          flag,
          createdOn,
          firstTime,
          firstWeekTime,
          memberType,
        })
      );

      await setMonitoringUser({
        userId: _id,
        firstName,
        lastName,
        email,
        memberType,
        hhIdDefault,
        flag,
      });
      trackEvent("initGlobalData");
      // intercom.update({
      //   app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      //   user_id: _id,
      //   email: email,
      //   name: `${firstName} ${lastName}`,
      // });

      dispatch(pushInit());
      dispatch(getAllCategorizationData());
      dispatch(getHouseholdData());
      dispatch(getAccountData());
      dispatch(getTransactionRules());
      if (flag === onboardingFlags.FINISHED_ONBOARDING) {
        dispatch(getSpendingChallenges());
        dispatch(getBudget({ effectiveDate: dayjs().startOf("month").format("YYYY-MM-DD") }));
        dispatch(getBills({ effectiveDate: dayjs().startOf("month").format("YYYY-MM-DD") }));
        dispatch(getViewerAccountInfo());
        dispatch(getAllViewerGoals());
        dispatch(getSpendingForecastSummary());
        dispatch(getSpendingByPersonData());
      }
      console.debug("end initGlobalData");
      return getCurrentOnboaringRoute(flag);
    } catch (error) {
      console.debug("end initGlobalData - error");
      await setMonitoringUser({});
      if (window.location.href.includes(PAGE_URLS.LOGIN)) {
        return PAGE_URLS.LOGIN;
      }
      return PAGE_URLS.HOME;
    }
  };

// puts all the `PERSISTENT_FIELDS` that are saved in `Storage` back into redux (when the app loads up)
export const rehydrateDataFromStorage = () => (dispatch: Dispatch) => {
  Promise.all(
    PERSISTENT_FIELDS.map((key) => Storage.get({ key }).then(({ value }) => ({ key, value })))
  ).then((fields) => {
    const savedData: Partial<userState> = {};
    fields.forEach((field) => (savedData[field.key as keyof userState] = field.value || undefined));
    dispatch(setUserProperties(savedData));
  });
};

export const refreshCategoryDependencies =
  (includeTransactions = false) =>
  (dispatch: Dispatch<any>) => {
    const deps = [
      dispatch(getSpendingChallenges()),
      dispatch(refreshBudget()),
      dispatch(refreshFeeds()),
      dispatch(getSpendingForecastSummary()),
      dispatch(getTransactionRules()),
    ];
    if (includeTransactions) deps.push(dispatch(refreshTransactions()));
    return Promise.all(deps as unknown as Promise<any>[]);
  };

export const refreshTransactionDependencies =
  (includeTransactions = false) =>
  (dispatch: Dispatch<any>) => {
    return Promise.all([
      dispatch(refreshCategoryDependencies(includeTransactions)),
      dispatch(getSpendingByPersonData()),
    ]);
  };
