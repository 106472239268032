import React from "react";
import Template, { OnboardingCopy } from "./Template";
import "./onboarding.scss";
import { onboardingFlags, PAGE_URLS, SIGNUP_URLS } from "../../../constants";
import { initGlobalData, saveUserProperties, setLoading } from "../../../redux/actions";
import { useDispatch } from "../../../redux/hooks";
import { useIonViewDidEnter } from "@ionic/react";
import { trackEvent } from "../../../vendors/monitoring";
import { useTranslation } from "react-i18next";
import { OnboardingNavHeader } from "./OnboardingNavHeader";

const pageLocation = "create-subscription";

export const CreateSubscription: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useIonViewDidEnter(() => {
    trackEvent(`Start ${pageLocation}`);
  });

  return (
    <Template
      header={<OnboardingNavHeader pageLocation={pageLocation} />}
      primaryCopy="startTrial"
      primaryRouterLink={PAGE_URLS.HOME}
      handlePrimary={() => {
        dispatch(setLoading(true));
        dispatch(saveUserProperties({ flag: onboardingFlags.FINISHED_ONBOARDING })).then(() => {
          dispatch(initGlobalData());
        });
      }}
      primaryTestId="createSub_next"
      onboardingStep={SIGNUP_URLS.length}
    >
      <OnboardingCopy title="createSub" />
    </Template>
  );
};
