import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import * as Sentry from "@sentry/react";
import { FinancialAccount, Transaction } from "../types";
import { Account, AccountCollectionItem } from "./reducers/accounts";
import { HouseholdMember, HouseholdMemberInvite } from "./reducers/users";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    // Transform the state to remove sensitive information
    const transformedState = {
      ...state,
      accounts: {
        ...state.accounts,
        myAccounts: institutionsCleanser(state.accounts.myAccounts || []),
        otherAccountCollection: institutionsCleanser(state.accounts.otherAccountCollection || []),
      },
      alertUi: null,
      billsAudit: {
        ...state.billsAudit,
        userInfo: {
          ...state.billsAudit.userInfo,
          legal: null,
          address: null,
          billsharkEmail: null,
          billsharkMobile: null,
        },
        billersList: null,
      },
      budgetAnalytics: {
        ...state.budgetAnalytics,
        feedsArray: null,
        spendingByPersonArray: null,
      },
      goals: {
        ...state.goals,
        goalDetails: null,
        accountInfo: {
          ...state.goals.accountInfo,
          goalsLimits: null,
        },
      },
      householdData: {
        ...state.householdData,
        financialProfile: null,
        members: householdMemberCleanser(state.householdData.members || []),
      },
      transactions: {
        ...state.transactions,
        transactionsList: transactionsCleanser(state.transactionsList || []),
      },
      transactionRules: null,
      ui: null,
      users: {
        ...state.users,
        imageURL: state.users.imageURL === "No Image" ? "No Image" : "Image",
        financialProfile: null,
        externalFundingAccountDetails: null,
        firstName: null,
        lastName: null,
        email: null,
        token: null,
        partnerEmail: null,
        userName: null,
      },
      versionCheck: null,
    };

    return transformedState;
  },
});

// Cleansers
const invitesCleanser = (invites: HouseholdMemberInvite[]) => {
  return invites.map((invite) => {
    return {
      ...invite,
      emailId: null,
      firstName: null,
      requestedByName: null,
    };
  });
};

const householdMemberCleanser = (householdMembers: HouseholdMember[]) => {
  return householdMembers.map((member) => {
    return {
      ...member,
      financialProfile: null,
      corePro: null, //extraneous
      imageURL: member.imageURL === "No Image" ? "No Image" : "Image",
      firstName: null,
      lastName: null,
      emailId: null,
      invites: invitesCleanser(member.invites || []),
    };
  });
};

const institutionsCleanser = (institutions: AccountCollectionItem[]) => {
  return institutions.map((ins) => {
    return {
      ...ins,
      logo: null,
      publicToken: null,
      colors: null,
      subBanks: accountsCleanser(ins.subBanks || []),
    };
  });
};

const accountsCleanser = (accts: Account[]) => {
  return accts.map((acct) => {
    return {
      ...acct,
      balance: null,
      balanceHistory: null,
      plaidPublicToken: null,
      logo: null,
      sourceRawSearch: null,
    };
  });
};

const transactionsCleanser = (transactions: Transaction[]) => {
  return transactions.map((transaction) => {
    return {
      ...transaction,
      description: {
        ...transaction.description,
        original: transaction.description.original.substr(0, 3),
        simple: transaction.description.simple?.substr(0, 3),
        comments: null,
        reactions: null,
      },
    };
  });
};

const store = configureStore({
  reducer: rootReducer,
  enhancers: [sentryReduxEnhancer],
});

export type AppDispatch = typeof store.dispatch;
export default store;
