import React from "react";
import { IonTitle } from "@ionic/react";
import dayjs from "dayjs";

import { PAGE_URLS } from "../../../constants";
import { BudgetType } from "../../../types";
import { MonthPicker } from '../../shared/MonthPicker/MonthPicker';

interface BudgetMonthHeaderProps {
  effectiveDate: string;
  category?: string;
  baseUrl?: string;
  budgetType?: BudgetType;
  allowFutureMonths?: boolean;
}
export const BudgetMonthHeader: React.FC<BudgetMonthHeaderProps> = ({
  effectiveDate,
  category = "",
  baseUrl = PAGE_URLS.BUDGET_HOME,
  budgetType = "",
  allowFutureMonths = false,
}) => {
  return <IonTitle>
    <MonthPicker
      month={dayjs(effectiveDate)}
      getLink={(month) => baseUrl
        .replace(":effectiveDate", month)
        .replace(":budgetType", budgetType)
        .replace(":category", category)}
      allowFutureMonths={allowFutureMonths}
    />
  </IonTitle>
};
