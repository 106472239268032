import React, { useState } from "react";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToggle,
  IonNote,
} from "@ionic/react";
import { buildOutline, checkmarkCircle, flagOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCategoryState } from "../../../redux/selectors";
import { getRelatedSubCategory, updateSubCategory } from "../../../utils/transactions";
import { Transaction, TransactionModalType } from "../../../types/transactions";
import { useDispatch } from "react-redux";
import {
  refreshCategoryDependencies,
  setLoading,
  TransactionUpdateDataFields,
  updateDataForArrayTransactions,
} from "../../../redux/actions";
import { Modal } from "../../shared/Modal/Modal";
import "./Recategorization.scss";
import images from "../../../assets/images/categorization";
import { hexToRGB } from "../../../utils/uiHelper";
import { EditCategoriesModal } from "../Budget/EditCategoriesModal";
import { CategoryImg } from "../../shared/Miscellaneous/CategoryImg";
import { categoryState } from "../../../redux/reducers/category";
import { getCategoryObject } from "../../../utils/categorizationUtils";
import { trackEvent } from "../../../vendors/monitoring";
import { CATEGORY_CONSTANTS } from "../../../constants";

interface RecategorizationProps {
  onClose: (arg0: TransactionModalType) => void;
  transaction: Transaction;
  sendApi: boolean;
  handleCatChange?: (category: string, subCategory: string, uuid: string) => void;
  uuid?: string;
  updateTargetTransaction?: () => void;
}

export const Recategorization: React.FC<RecategorizationProps> = (props) => {
  const { transaction, onClose, sendApi, handleCatChange, uuid, updateTargetTransaction } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const recategorizationImg: any = {
    ...images,
  };

  //redux state
  const { categoryData, subCategoryData }: categoryState = useSelector(getCategoryState);

  //state
  const [selectedCategory, setSelectedCategory] = useState(
    transaction.hhInfo[0]?.beefCategoryName || ""
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    transaction.hhInfo[0]?.honeyfiCategoryName || ""
  );
  const [attemptingModalClose, setAttemptingModalClose] = useState(false);
  const [createRuleToggle, setCreateRuleToggle] = useState(false);
  const [showEditCategoriesModal, setShowEditCategoriesModal] = useState(false);

  const save = async () => {
    if (sendApi) {
      try {
        dispatch(setLoading(true));
        await updateSubCategory({
          transactionId: transaction._id,
          subcategory: selectedSubCategory,
        })
          .then(() => {
            dispatch(
              updateDataForArrayTransactions({
                transactionIdsArray: [transaction._id],
                updateDataArray: [
                  {
                    field: TransactionUpdateDataFields.CATEGORY,
                    value: selectedCategory,
                    hhId: transaction.hhInfo[0].hhId,
                  },
                  {
                    field: TransactionUpdateDataFields.SUBCATEGORY,
                    value: selectedSubCategory,
                    hhId: transaction.hhInfo[0].hhId,
                  },
                ],
              })
            );
          })
          .then(() => dispatch(refreshCategoryDependencies()))
          .then(() => {
            if (createRuleToggle) {
              onClose(TransactionModalType.CATEGORY_RULE);
            } else {
              onClose(TransactionModalType.NONE);
            }
          });
        if (updateTargetTransaction) updateTargetTransaction();
      } catch (error) {
      } finally {
        trackEvent("save_recat", { category: "categorization" });
        dispatch(setLoading(false));
      }
    } else if (handleCatChange && !sendApi) {
      handleCatChange(selectedCategory, selectedSubCategory, uuid || "");
      onClose(TransactionModalType.NONE);
    }
  };

  return (
    <Modal
      open
      title={t("categorization")}
      onClose={() =>
        transaction.hhInfo[0].honeyfiCategoryName !== selectedSubCategory
          ? setAttemptingModalClose(true)
          : onClose(TransactionModalType.NONE)
      }
      moreDetailsIcon={buildOutline}
      onMoreDetails={() => setShowEditCategoriesModal(true)}
    >
      <IonContent className="recategorization-content">
        <IonContent className="recategorization-column">
          <IonList className="ion-no-padding recategorization-list">
            {categoryData
              .filter((category) => !category.isDeleted)
              .map((category, index) => <IonItem
                key={`${category.categoryShortName}-${index}`}
                onClick={(e) =>
                  setSelectedCategory(e.currentTarget.getAttribute("default-value") || "")
                }
                defaultValue={category.categoryShortName}
                id={category.categoryShortName}
                lines="none"
                className={"recategorization-category-item"}
                style={{
                  backgroundColor:
                    selectedCategory === category.categoryShortName
                      ? hexToRGB(category.design.bgColor, 0.1)
                      : "transparent",
                  "--border-color": selectedCategory === category.categoryShortName ? category.design.bgColor : 'transparent',
                }}
                data-testid={`category_${category.categoryShortName}`}
              >
                <CategoryImg
                  category={category}
                  styleType={
                    selectedCategory === category.categoryShortName ? "dark" : "light"
                  }
                  imgProps={{ className: "recategorization-category-img" }}
                />
                <IonLabel className="inter-font">{category.categoryFullName}</IonLabel>
              </IonItem>)}
          </IonList>
        </IonContent>
        <IonContent className="recategorization-column">
          {selectedCategory === CATEGORY_CONSTANTS.IGNORE ? <IonNote className="d-flex ion-text-center font-xs half-padding">
            {t("transactionsIgnoredNote")}
          </IonNote> : null}
          <IonList>
            {getRelatedSubCategory(subCategoryData, selectedCategory).map(
              (subCategory, index) => {
                return (
                  <IonItem
                    key={`${subCategory.displayName}-${index}`}
                    defaultValue={subCategory.subCategory}
                    onClick={(event) =>
                      setSelectedSubCategory(
                        event.currentTarget.getAttribute("default-value") || ""
                      )
                    }
                    lines="none"
                    className="recategorization-item"
                    data-testid={`subcat_${subCategory.subCategory}`}
                  >
                    {selectedSubCategory === subCategory.subCategory ? (
                      <IonIcon
                        icon={checkmarkCircle}
                        style={{
                          color: getCategoryObject(selectedCategory, categoryData)?.design
                            .bgColor,
                        }}
                        className="recategorization-selected-icon"
                      />
                    ) : (
                      ""
                    )}
                    <IonLabel className="inter-font">{subCategory.displayName}</IonLabel>
                  </IonItem>
                );
              }
            )}
          </IonList>
        </IonContent>
      </IonContent>
      <IonAlert
        isOpen={attemptingModalClose}
        onDidDismiss={() => setAttemptingModalClose(false)}
        header={t("saveChanges")}
        message={t("saveChangesDes")}
        buttons={[
          {
            text: t("no"),
            role: "cancel",
            handler: onClose,
          },
          {
            text: t("yes"),
            role: "okay",
            handler: save,
          },
        ]}
      ></IonAlert>
      <IonFooter className="ion-no-border ion-text-center fy-feathered">
        <IonGrid>
          <IonRow>
            <IonCol size="8">
              {sendApi && (
                <IonItem lines="none">
                  <IonIcon icon={flagOutline} className="ion-padding-end" />
                  <p>{t("createRule")}</p>
                  <IonToggle
                    color="primary"
                    data-testid="recategorizationRuleToggle"
                    onIonChange={({ detail }) => {
                      trackEvent(detail.checked ? "createRule_toggleOn" : "createRule_toggleOff", {
                        category: "categorization",
                      });
                      setCreateRuleToggle(detail.checked);
                    }}
                  />
                </IonItem>
              )}
            </IonCol>
            <IonCol size="4">
              <IonButton
                disabled={transaction.hhInfo[0].honeyfiCategoryName === selectedSubCategory} // nothing to save
                onClick={save}
                data-testid="recategorizationSaveBtn"
              >
                {t("save")}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
      <EditCategoriesModal
        isOpen={showEditCategoriesModal}
        onClose={() => setShowEditCategoriesModal(false)}
      />
    </Modal >
  );
};
