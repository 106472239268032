import { PAGE_URLS } from "../constants";

export enum FeedType {
  CATEGORY_CHANGE = "0",
  TRANSACTION_COMMMENTED = "1",
  TRANSACTION_REACTION = "2",
  ACCOUNT_SHARED = "3",
  ACCOUNT_VISIBLE = "4",
  ACCOUNT_JUSTME = "5",
  ACCOUNT_IGNORE = "6",
  TRANSACTION_SHARED = "7",
  TRANSACTION_UNSHARED = "8",
  ACCOUNT_ADD = "9",
  BUDGET_EDITED = "10",
  BUDGET_EDITED_DEFAULT = "11",
  RULE_CHANGE = "12",
  GOAL_ADDED = "13",
  GOAL_COMMENTED = "14",
  SPENDING_CHALLENGE_ADDED = "15",
  SPENDING_CHALLENGE_COMMENTED = "16",
  GOAL_MILESTONE = "17",
  GOAL_TRANSFER = "18",
  BUDGET_MILESTONE = "19",
  ACCOUNT_ADD_SET_SHARE = "20",
  ACCOUNT_ALERT = "21",
}

export const feedDetailSwitch = (entity: FeedType, entityId: string, fromPush: boolean) => {
  let nextStep = {
    page: null as null | string,
    data: {
      entity,
      entityId,
      fromPush,
    },
  };

  switch (entity) {
    case FeedType.CATEGORY_CHANGE:
    case FeedType.TRANSACTION_COMMMENTED:
    case FeedType.TRANSACTION_REACTION:
    case FeedType.TRANSACTION_SHARED:
    case FeedType.TRANSACTION_UNSHARED:
      nextStep.page = PAGE_URLS.TRANS_DETAILS.replace(":transactionId", entityId);
      return nextStep;
    case FeedType.ACCOUNT_SHARED:
    case FeedType.ACCOUNT_VISIBLE:
    case FeedType.ACCOUNT_JUSTME:
    case FeedType.ACCOUNT_IGNORE:
    case FeedType.ACCOUNT_ADD:
    case FeedType.ACCOUNT_ALERT:
      nextStep.page = PAGE_URLS.MORE_ACCTS_MANAGE_LINKED_LIST.replace(
        ":sourceInstitutionId",
        entityId
      );
      return nextStep;
    case FeedType.ACCOUNT_ADD_SET_SHARE:
      const [sourceInstitutionId, accountId] = entityId.split(":");
      nextStep.page = PAGE_URLS.MORE_ACCTS_MANAGE_LINKED_LIST.replace(
        ":sourceInstitutionId",
        sourceInstitutionId
      ).replace(":accountId", accountId);
      return nextStep;
    case FeedType.BUDGET_EDITED:
    case FeedType.BUDGET_EDITED_DEFAULT:
    case FeedType.BUDGET_MILESTONE:
      const [effectiveDate, budgetType] = entityId.split(":");
      nextStep.page = PAGE_URLS.BUDGET_HOME.replace(":effectiveDate", effectiveDate).replace(
        ":budgetType",
        budgetType
      );
      return nextStep;
    case FeedType.RULE_CHANGE:
      nextStep.page = PAGE_URLS.RULES_MANAGE.replace(":accountId", entityId);
      return nextStep;
    case FeedType.GOAL_ADDED:
    case FeedType.GOAL_COMMENTED:
    case FeedType.GOAL_MILESTONE:
    case FeedType.GOAL_TRANSFER:
      nextStep.page = PAGE_URLS.GOALS_DETAILS.replace(":goalId", entityId);
      return nextStep;
    case FeedType.SPENDING_CHALLENGE_ADDED:
    case FeedType.SPENDING_CHALLENGE_COMMENTED:
      nextStep.page = PAGE_URLS.SPENDING_CHALLENGES_DETAILS.replace(":challengeId", entityId);
      return nextStep;
    default:
      return nextStep;
  }
};
