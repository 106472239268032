import { SET_LOADING, SET_TOAST_MESSAGE } from "../actionTypes";

const initialState: uiState = {
  loading: false,
  toastMessage: "",
};

interface uiState {
  loading: boolean;
  toastMessage: string;
}

interface action {
  type: string;
  payload: {
    loading: boolean;
    toastMessage: string;
  };
}

const ui = (state: uiState = initialState, action: action) => {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload.loading,
      };
    }

    case SET_TOAST_MESSAGE: {
      return {
        ...state,
        toastMessage: action.payload.toastMessage,
      };
    }

    default: {
      return state;
    }
  }
};

export default ui;
