import { IonIcon } from "@ionic/react";
import { eyeOutline } from "ionicons/icons";
import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { eye } from "../../../assets/icons/generic";
import { AVATAR_DISPLAY, BILLS_SHARED_STATUS, TRANSACTION_VISIBILITY } from "../../../constants";
import { getUsersState } from "../../../redux/selectors";
import Avatar from "./Avatar";
import "./Avatars.scss";

// for specifically decoding transactions hhInfo
/// Might be applicable to other data types too
export const calcSharedTrans = (hhInfo: any, userId: string, returnString: boolean = false) => {
  if (hhInfo.isPrivate) {
    return returnString ? BILLS_SHARED_STATUS.PRIVATE : AVATAR_DISPLAY.PRIVATE;
  }

  if (hhInfo.sharedExpense === TRANSACTION_VISIBILITY.SHARED) {
    return returnString ? BILLS_SHARED_STATUS.SHARED : AVATAR_DISPLAY.SHARED;
  }

  if (hhInfo.sharedExpense === TRANSACTION_VISIBILITY.INDIVIDUAL) {
    if (hhInfo.sharedExpenseIndividual.includes(userId)) {
      return returnString ? BILLS_SHARED_STATUS.ME : AVATAR_DISPLAY.ME;
    } else {
      return returnString ? BILLS_SHARED_STATUS.PARTNER : AVATAR_DISPLAY.PARTNER;
    }
  }
};
interface AvatarsProps {
  avatarType?: number | string;
  alignAvatars?: string; // start/center/end; not hooked up yet
  sizeStyle?: "xs" | "sm" | "md" | "lg"; // 'xs' (15x15) 'sm' (25px x 25px), 'md' (40x40), or 'lg' (60x60)
  vertical?: boolean;
  hhInfoType?: "transaction" | "bill"; // will be extended in the future
  hhInfo?: any;
  diagonal?: boolean;
  className?: string;
  alignment?: "start" | "center" | "end";
  extraLayer?: ReactElement;
}

const Avatars: React.FC<AvatarsProps> = (props) => {
  const { userId } = useSelector(getUsersState);
  const {
    hhInfo,
    hhInfoType,
    sizeStyle = "md",
    avatarType,
    vertical,
    diagonal = false,
    alignment,
    className,
    extraLayer = null,
  } = props;
  let avatar = avatarType;

  if (hhInfoType === "transaction" || hhInfoType === "bill") {
    avatar = calcSharedTrans(hhInfo, userId);
  }

  return (
    <div
      className={
        "ownership-avatars " +
        className +
        " " +
        sizeStyle +
        (vertical ? " vertical-avatars" : "") +
        (diagonal ? " diagonal-avatars" : "") +
        (alignment === "end" ? " align-end" : "") +
        (alignment === "start" ? " align-start" : "")
      }
    >
      {(() => {
        switch (avatar) {
          case AVATAR_DISPLAY.SHARED:
          case BILLS_SHARED_STATUS.SHARED:
            return (
              <>
                <Avatar isUser={true} />
                <Avatar isUser={false} className="margin-minus" />
              </>
            );
          case AVATAR_DISPLAY.ME:
          case BILLS_SHARED_STATUS.ME:
            return <Avatar isUser={true} />;
          case AVATAR_DISPLAY.PARTNER:
          case BILLS_SHARED_STATUS.PARTNER:
            return <Avatar isUser={false} />;
          case AVATAR_DISPLAY.PRIVATE:
          case BILLS_SHARED_STATUS.PRIVATE:
            return <>
              <Avatar isUser={true} />
              <IonIcon icon={eye} size="small" className={`sub-icon sub-icon-${sizeStyle}`} />
            </>
        }
      })()}
      {extraLayer}
    </div>
  );
};

export default Avatars;
