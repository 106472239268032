import * as types from "../actionTypes";
import { SpendingChallengeCreateParams, SpendingChallengeType } from "../../types";
import { Dispatch } from "redux";
import { get, put } from "../../utils/apiOps";

export const updateCreateNewChanllengeParams = (params: SpendingChallengeCreateParams) => {
  return {
    type: types.CREATE_NEW_CHALLENGE,
    payload: params,
  };
};

export const updateChallengeCategory = (category: string) => {
  return {
    type: types.UPDATE_CHALLENGE_CATEGORY,
    payload: category,
  };
};

export const updateChallengeSubCategory = (subCategory: string) => {
  return {
    type: types.UPDATE_CHALLENGE_SUBCATEGORY,
    payload: subCategory,
  };
};

export const updateChallengeFrequemcy = (frequency: string) => {
  return {
    type: types.UPDATE_CHALLENGE_FREQUENCY,
    payload: frequency,
  };
};

export const updateChallengeAmount = (amount: number) => {
  return {
    type: types.UPDATE_CHALLENGE_AMOUNT,
    payload: amount,
  };
};

export const setSpendingChallenges = (data: SpendingChallengeType[]) => {
  return {
    type: types.SET_SPENDING_CHALLENGE,
    payload: data,
  };
};

export const getSpendingChallenges = () => async (dispatch: Dispatch<any>) => {
  try {
    const { data } = await get(true, {
      endpoint: "/spendingChallenges",
    });
    dispatch(setSpendingChallenges(data));
  } catch (error) {}
};

export const updateSpendingChallenge = (bodyData: any) => async (dispatch: Dispatch<any>) => {
  try {
    await put(true, {
      endpoint: "/spendingchallenges",
      bodyData,
    });
    dispatch(getSpendingChallenges());
  } catch (error) {}
};

export const updateChallengeType =
  (category: string, subCategory: string) => async (dispatch: Dispatch<any>) => {
    dispatch(updateChallengeCategory(category));
    dispatch(updateChallengeSubCategory(subCategory));
  };

export const updateChallengeFreAndAmount =
  (frequency: string, amount: number) => async (dispatch: Dispatch<any>) => {
    dispatch(updateChallengeFrequemcy(frequency));
    dispatch(updateChallengeAmount(amount));
  };
