import React from "react";
import { ResponsivePie } from "@nivo/pie";
import "./BudgetPieChart.scss";
import { Design } from "../../../types";
import { getCategoryImgSrc } from "../../shared/Miscellaneous/CategoryImg";

interface BudgetPieChartProps {
  budgetAnalyticsDataForPieChart: Array<any>;
  primaryColor?: string;
  design?: Design; // send if you want to fill the background image
}

export const BudgetPieChart: React.FC<BudgetPieChartProps> = ({
  budgetAnalyticsDataForPieChart,
  primaryColor = "#1C7FFF",
  design,
}) => {
  const style: React.CSSProperties = {};
  if (design) {
    style.backgroundImage = `url(${getCategoryImgSrc(design)})`;
  }

  return (
    <div className="BudgetPieChart" style={style}>
      <ResponsivePie
        data={budgetAnalyticsDataForPieChart}
        innerRadius={0.8}
        colors={["#E6E6E6", primaryColor]}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        endAngle={-360}
        isInteractive={false}
      />
    </div>
  );
};
