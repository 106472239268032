import React from "react";
import {
  IonCard,
  IonCardHeader,
  IonTitle,
  IonText,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonSpinner,
} from "@ionic/react";
import dayjs from "dayjs";
import numeral from "numeral";
import { useTranslation } from "react-i18next";

import { ForecastChart } from "./SpendingForecastChart";
import { useSelector } from "../../../redux/hooks";
import { getSpendingForecastState } from "../../../redux/selectors";
import { PAGE_URLS } from "../../../constants";
import { prepSpendingForecastData } from "../../../utils/spendingForecastUtils";
import { SpendingForecastState } from "../../../redux/reducers/spendingForecast";

export const SpendingForecastCard: React.FC = (props) => {
  const { t } = useTranslation();
  const { summary, loading }: SpendingForecastState = useSelector(getSpendingForecastState);
  const total = summary.dailyPoints.length
    ? summary.dailyPoints[summary.dailyPoints.length - 1].overallTotal
    : 0;

  return (
    <IonCard routerLink={PAGE_URLS.SPENDING_FORECAST}>
      <IonCardHeader>
        <IonCardTitle className="ion-text-center">
          {t("monthForecast", { month: dayjs().format("MMMM") })}
        </IonCardTitle>
      </IonCardHeader>

      {loading ? (
        <IonCardContent className="ion-text-center">
          <IonSpinner />
        </IonCardContent>
      ) : (
        <IonCardContent>
          {summary.noPredictionAvail === false ? (
            <div className="ion-text-center">
              <IonTitle color="primary" className="ion-padding-top">
                {total ? numeral(total).format("$0,0") : null}
              </IonTitle>
              <IonText color="primary">
                <h3>{t("spendingProjection")}</h3>
              </IonText>
              <ForecastChart data={prepSpendingForecastData(summary.dailyPoints)} />
            </div>
          ) : (
            <IonItem lines="none">
              <IonLabel className="ion-text-center">
                <h3>{t("noData")}</h3>
              </IonLabel>
            </IonItem>
          )}
        </IonCardContent>
      )}
    </IonCard>
  );
};
