import React from "react";
import { IonAlert } from "@ionic/react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { BillPaidSource } from "../../../types";
import {
  setLoading,
  TransactionUpdateDataFields,
  updateBill,
  updateDataForArrayTransactions,
} from "../../../redux/actions";
import { useDispatch } from "../../../redux/hooks";
import { post } from "../../../utils/apiOps";

interface UnmarkBillAlertProps {
  isOpen: boolean;
  onClose: () => void;
  billId: string;
  paidDate: Date;
  paidSource: BillPaidSource;
  transactionId?: string;
}

export const UnmarkBillAlert: React.FC<UnmarkBillAlertProps> = ({
  isOpen,
  onClose,
  billId,
  paidDate,
  paidSource,
  transactionId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <IonAlert
      isOpen={isOpen}
      onDidDismiss={() => onClose()}
      header={t("unmarkBillPaid_header")}
      message={t("unmarkBillPaid_msg")}
      buttons={[
        {
          text: t("cancel"),
          role: "cancel",
        },
        {
          text: t("notBill"),
          role: "destructive",
          handler: () => {
            let postData: any = {
              endpoint: "/bills/markAsNotBill",
              bodyData: {
                transactionId,
                billId,
              },
            };
            if (paidSource === "manual") {
              postData.endpoint = "/bills/unmarkAsPaidManual";
              postData.bodyData = {
                billId,
                paidDate,
              };
            }

            dispatch(setLoading(true));
            post(true, postData)
              .then(() => {
                dispatch(
                  updateBill({
                    billId,
                    updateData: [{ field: "status", value: "unpaid" }],
                    effectiveDate: dayjs(paidDate).startOf("month").format("YYYY-MM-DD"),
                  })
                );
                if (transactionId) {
                  dispatch(
                    updateDataForArrayTransactions({
                      transactionIdsArray: [transactionId],
                      updateDataArray: [
                        { field: TransactionUpdateDataFields.IS_BILL, value: false },
                        {
                          field: TransactionUpdateDataFields.BILLS,
                          value: [],
                        },
                      ],
                    })
                  );
                }
              })
              .catch()
              .finally(() => {
                dispatch(setLoading(false));
              });
          },
        },
      ]}
    />
  );
};
