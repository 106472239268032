import React, { useEffect, useState } from "react";
import { IonInput, IonItem, IonLabel, IonList, useIonViewDidEnter } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToastMessage, saveUserProperties } from "../../../redux/actions";
import { trackEvent } from "../../../vendors/monitoring";
import "./onboarding.scss";
import Template, { OnboardingCopy } from "./Template";
import { useTranslation } from "react-i18next";
import { PAGE_URLS } from "../../../constants";
import { getUsersState } from "../../../redux/selectors";
import { OnboardingNavHeader } from "./OnboardingNavHeader";
import { OnboardingProgress } from "./OnboardingProgress";
import { formattedName } from "../../../utils/formatUtils";

const pageLocation = "enter-name";

export const EnterName: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(getUsersState);

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const formGood = Boolean(firstName && lastName);

  useEffect(() => {
    if (user.firstName && user.firstName !== "") {
      setFirstName(user.firstName);
    }
  }, [user]);

  const handleLogin = async (event?: any) => {
    if (!formGood) {
      dispatch(setToastMessage(t(!firstName ? "invalidFirstName" : "invalidLastName")));
      if (event && event.preventDefault) event.preventDefault();
      return;
    }

    dispatch(saveUserProperties({ firstName, lastName }));
  };

  return (
    <Template
      header={
        <OnboardingNavHeader
          defaultHref={PAGE_URLS.SIGNUP_INVITE}
          pageLocation={pageLocation}
          showBackButton
          desktopTitle={<OnboardingProgress step={3} />}
        />
      }
      primaryCopy="next"
      primaryRouterLink={PAGE_URLS.SIGNUP_CONTACT}
      primaryProps={{ routerDirection: "root" }}
      footerDisabled={!formGood}
      primaryTestId="name_submit"
      handlePrimary={handleLogin}
      onboardingStep={3}
    >
      <OnboardingCopy title="enterName_title" />
      <IonList className="ion-padding-end bounded">
        <IonItem>
          <IonLabel position="stacked">{t("firstName")}</IonLabel>
          <IonInput
            type="text"
            value={formattedName(firstName as string)}
            autofocus={true}
            minlength={1}
            inputmode="text"
            name="firstName"
            autocomplete="given-name"
            autocapitalize="words"
            required={true}
            enterkeyhint="next"
            data-testid="firstName"
            onIonChange={(e) => {
              setFirstName(e.detail.value!);
            }}
            onKeyPress={(e) => e.key === "Enter" && handleLogin()}
          ></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">{t("lastName")}</IonLabel>
          <IonInput
            type="text"
            value={formattedName(lastName as string)}
            name="lastName"
            minlength={1}
            required={true}
            enterkeyhint="next"
            inputmode="text"
            autocomplete="family-name"
            data-testid="lastName"
            autocapitalize="words"
            onIonChange={(e) => {
              setLastName(e.detail.value!);
            }}
            onKeyPress={(e) => e.key === "Enter" && handleLogin()}
          ></IonInput>
        </IonItem>
      </IonList>
    </Template>
  );
};
