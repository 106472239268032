import React, { useEffect, useState } from "react";
import {
  IonList,
  IonToolbar,
  IonContent,
  IonButton,
  IonRow,
  IonGrid,
  IonCol,
  IonItem,
  IonLabel,
  IonAlert,
  IonSelect,
  IonSelectOption,
  IonFooter,
} from "@ionic/react";
import { useSelector } from "../../../redux/hooks";
import { BILLS_FREQUENCY_TYPES, BILL_REMINDER_OPTIONS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { getTransactionState, getUsersState } from "../../../redux/selectors";
import dayjs from "dayjs";
import { BillFrequency, BillReminderDaysOptions, Transaction } from "../../../types";
import numeral from "numeral";
import { getBills, setLoading } from "../../../redux/actions";
import { useDispatch } from "../../../redux/hooks";
import { transNameHelper } from "../../../utils/transUtils";
import Avatars, { calcSharedTrans } from "../../shared/Avatar/Avatars";
import { post, put } from "../../../utils/apiOps";
import { YomPopover } from "../../shared/Sharing/YomPopover";
import { useUserName } from "../../shared/User/useUserName";
import { DatePicker } from "../../shared/DatePicker/DatePicker";
import { useResponsiveInterface } from "../../../hooks/useResponsiveInterface";
import { trackEvent } from "../../../vendors/monitoring";
import { markBillFromTrans } from "../../../utils/bills";

interface CreateBillFormProps {
  onClose: () => void;
  transactionId?: string;
  selectedManual: boolean;
  effectiveDate: string;
  selectedBillId?: string;
}

export const CreateBillForm: React.FC<CreateBillFormProps> = ({
  onClose,
  transactionId,
  selectedManual,
  effectiveDate,
  selectedBillId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _interface = useResponsiveInterface("alert");

  const { userId } = useSelector(getUsersState);
  const { transactionsList } = useSelector(getTransactionState);
  const { partnerName } = useUserName();

  const [billFrequency, setBillFrequency] = useState<BillFrequency>("monthly");
  const [reminderDays, setReminderDays] = useState<BillReminderDaysOptions>(3);
  const [billName, setBillName] = useState<string>("Bill");
  const [billAmount, setBillAmount] = useState<number>(0);
  const [billDay, setBillDay] = useState<string>(dayjs().format("YYYY-MM-DD"));
  const [billAvatarType, setBillAvatarType] = useState<string | number>("shared");

  const formGood = Boolean(billFrequency && reminderDays && billName && billAmount && billDay);

  const [showNicknameAlert, setShowNicknameAlert] = useState(false);
  const [showAmountAlert, setShowAmountAlert] = useState(false);
  const [showEditYomPopover, setShowEditYomPopover] = useState<any>({
    isOpen: false,
    event: null,
  });

  useEffect(() => {
    const transaction = transactionsList.find((t) => t._id === transactionId);
    if (!transaction) {
      return;
    }
    setBillName(transNameHelper(transaction));
    setBillAmount(transaction.amount.amount);
    setBillDay(dayjs(transaction.date).add(1, "month").format("YYYY-MM-DD"));
    setBillAvatarType(calcSharedTrans(transaction?.hhInfo[0], userId, true) || "shared");
  }, [transactionId]);

  useEffect(() => {
    let addMonths = 1;
    switch (billFrequency) {
      case "bimonthly":
        addMonths = 2;
        break;
      case "quarterly":
        addMonths = 3;
        break;
      case "semiannually":
        addMonths = 6;
        break;
      case "annually":
        addMonths = 12;
        break;
      default:
        addMonths = 1;
        break;
    }
    const day = dayjs(billDay).format("D");
    setBillDay(
      dayjs(effectiveDate).add(addMonths, "month").set("date", Number(day)).format("YYYY-MM-DD")
    );
  }, [billFrequency]);

  const updateYom = (newTransDisplay: string) => {
    setShowEditYomPopover({ isOpen: false, event: undefined });
    setBillAvatarType(newTransDisplay);
  };

  const clearAndClose = () => {
    setBillName("Bill");
    setBillAmount(0);
    setBillDay(dayjs().format("YYYY-MM-DD"));
    setBillAvatarType("shared");
    dispatch(
      getBills({ effectiveDate: dayjs(effectiveDate).format("YYYY-MM-DD"), forceRefresh: true })
    );
    onClose();
  };
  
  const createBill = async () => {
    try {
      dispatch(setLoading(true));
      if (selectedManual) {
        const bodyData = {
          name: billName,
          amount: Number(billAmount),
          day: dayjs(billDay).date(),
          frequency: billFrequency,
          sharedStatus: billAvatarType,
          startDate: dayjs(billDay).toDate(),
          sendReminder: false,
          reminderDays: Number(reminderDays || 3),
        };
        if (reminderDays !== false) {
          bodyData.sendReminder = true;
        }
        await post(true, {
          endpoint: "/bills/addBillManually",
          bodyData,
        });
        markBillFromTrans(
          selectedBillId,
          transactionId as any,
          effectiveDate,
          dispatch,
          clearAndClose
        );
        trackEvent("save_createBill", { category: "upcomingBills" });
      } else {
        const res = await post(true, {
          endpoint: "/bills/addFromTransaction",
          bodyData: {
            transactionId: transactionId,
          },
        });
        secondStep(res);
        markBillFromTrans(
          selectedBillId,
          transactionId as any,
          effectiveDate,
          dispatch,
          clearAndClose
        );
      }
      trackEvent("save_createBill", { category: "upcomingBills" });
    } catch (err) {
      console.debug(err);
      dispatch(setLoading(false));
    }
  };

  interface AddBillFromTransactionResponse {
    data: ResData;
  }
  type ResData = {
    newBillId: string;
    transactions: Transaction[];
  };

  const secondStep = async ({ data }: AddBillFromTransactionResponse) => {
    const bodyData = {
      billId: data.newBillId,
      nickname: billName,
      amount: billAmount,
      day: dayjs(billDay).date(),
      frequency: billFrequency,
      sharedStatus: billAvatarType,
      sendReminder: false,
      reminderDays: Number(reminderDays || 3),
    };
    if (reminderDays !== false) {
      bodyData.sendReminder = true;
    }
    await put(true, {
      endpoint: "/bills/edit",
      bodyData,
    });
    return data;
  };

  return (
    <>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <h1 className="font-title-3">{t("createBillModal_title2")}</h1>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonList lines="none">
          <IonItem onClick={() => setShowNicknameAlert(true)}>
            <IonLabel>{t("name")}</IonLabel>
            <IonLabel className="ion-text-end ion-margin-end">{billName}</IonLabel>
          </IonItem>
          <IonItem onClick={() => setShowAmountAlert(true)}>
            <IonLabel>{t("amount")}</IonLabel>
            <IonLabel className="ion-text-end ion-margin-end">
              {numeral(billAmount).format("$0,0")}
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>{t("timing")}</IonLabel>
            <IonSelect
              slot="end"
              value={billFrequency}
              interface={_interface}
              onIonChange={({ detail }) => setBillFrequency(detail.value)}
            >
              {BILLS_FREQUENCY_TYPES.map((s, i) => {
                return (
                  <IonSelectOption key={i} value={s.val}>
                    {t(s.disp)}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>{t("nextDueDate")}</IonLabel>
            <DatePicker
              min={dayjs(effectiveDate).subtract(1, "month").startOf("month").format("YYYY-MM-DD")}
              max={dayjs(effectiveDate).add(1, "year").format("YYYY-MM-DD")}
              displayFormat="M/D/YY (DDD)"
              value={billDay}
              onChange={setBillDay}
              className="ml-auto"
            />
          </IonItem>
          <IonItem>
            <IonLabel>{t("person")}</IonLabel>

            <IonButton
              slot="end"
              fill="clear"
              className="ion-text-end ion-margin-end"
              data-testid="EditYomButton"
              onClick={(event) => {
                event.persist();
                setShowEditYomPopover({ isOpen: true, event });
              }}
            >
              <Avatars sizeStyle="sm" avatarType={billAvatarType} alignment="end" />
            </IonButton>
          </IonItem>
          <IonItem>
            <IonLabel>{t("reminder")}</IonLabel>
            <IonSelect
              slot="end"
              value={reminderDays}
              interface={_interface}
              onIonChange={({ detail }) => setReminderDays(detail.value)}
            >
              {BILL_REMINDER_OPTIONS.map((s, i) => {
                return (
                  <IonSelectOption key={i} value={s.val}>
                    {t(s.disp)}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar no-border className="ion-text-center">
          <IonButton
            disabled={!formGood}
            onClick={() => {
              trackEvent("save_createNewBill");
              createBill();
            }}
            data-testid="saveCreateBill"
            className={"ion-margin-bottom"}
          >
            {t("save")}
          </IonButton>
        </IonToolbar>
      </IonFooter>

      <IonAlert
        isOpen={showNicknameAlert}
        onDidDismiss={() => setShowNicknameAlert(false)}
        header={t("editName")}
        inputs={[
          {
            name: "name",
            type: "text",
            cssClass: "ion-text-center",
            placeholder: t("name"),
            value: String(billName),
          },
        ]}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
          },
          {
            text: t("update"),
            handler: ({ name }) => setBillName(name),
          },
        ]}
      />
      <IonAlert
        isOpen={showAmountAlert}
        onDidDismiss={() => setShowAmountAlert(false)}
        header={t("editAmount")}
        inputs={[
          {
            name: "amount",
            type: "number",
            cssClass: "ion-text-center",
            placeholder: "$50",
            value: billAmount,
          },
        ]}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
          },
          {
            text: t("update"),
            handler: ({ amount }) => setBillAmount(Number(amount)),
          },
        ]}
      />
      <YomPopover
        cssClass="createBill"
        showEditYomPopover={showEditYomPopover}
        setShowEditYomPopover={setShowEditYomPopover}
        partnerName={partnerName}
        updateYom={updateYom}
      />
    </>
  );
};
