import { Dispatch } from "redux";
import * as types from "../actionTypes";
import { get, post } from "../../utils/apiOps";
import { remove } from "lodash";
import { BudgetItem, FeedsParams, SpendingByPersonType, UpdateBudgetParams } from "../../types";
import { FEEDS_ICON } from "../../constants";
import dayjs from "dayjs";

export const setBudgetAnalytics = (budgetAnalyticsObj: any) => {
  return {
    type: types.SET_BUDGET_ANALYTICS,
    payload: budgetAnalyticsObj,
  };
};

export const setBudgetAnalyticsDataLoading = (status: boolean = true, month?: string) => {
  return {
    type: types.BUDGET_ANALYTICS_DATA_LOADING,
    payload: month || status,
  };
};

export const setFeeds = (feeds: FeedsParams[]) => {
  return {
    type: types.SET_FEEDS,
    payload: feeds,
  };
};

export const clearFeeds = () => {
  return {
    type: types.CLEAR_FEEDS,
  };
};

export const setSpendingByPersonData = (spendingByPersonData: SpendingByPersonType[]) => {
  return {
    type: types.SPENDING_BY_PERSON_DATA,
    payload: spendingByPersonData,
  };
};

export const getSpendingByPersonData =
  (effectiveDate: string = dayjs().startOf("month").format("YYYY-MM-DD")) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const { data } = await get(true, {
        endpoint: "/analytics/spendingByPerson",
        params: { effectiveDate: effectiveDate, includeBreakdown: true },
      });

    // TODO: remove this when the API change (that returns an array) has completely deployed
    let spendingArray = [];
    if (Array.isArray(data)) {
      spendingArray = data;
    } else {
      let keys = Object.keys(data);
      for (let key of keys) {
        let value = data[key];
        let newObj = { ...value, id: key };
        spendingArray.push(newObj);
      }
    }

    dispatch(setSpendingByPersonData(spendingArray));
  } catch (error) {}
};

interface getBudgetFields {
  effectiveDate: string;
  forceRefresh?: boolean;
}

export const getBudget =
  (fields: getBudgetFields) => async (dispatch: Dispatch<any>, getState: any) => {
    const { budgetsArray } = getState().budgetAnalytics;
    const budgetAnalyticsForThisDate = budgetsArray.find(
      (item: BudgetItem) => item.budgetAnalyticsDate === fields.effectiveDate
    );
    if (!budgetAnalyticsForThisDate || fields.forceRefresh) {
      dispatch(getBudgetFromAPI(fields));
    }
  };

const getBudgetFromAPI =
  (fields: getBudgetFields) => async (dispatch: Dispatch<any>, getState: any) => {
    dispatch(setBudgetAnalyticsDataLoading(true, fields.effectiveDate));
    const { budgetsArray } = getState().budgetAnalytics;
    await get(true, { endpoint: "/budget", params: fields })
      .then(({ data }) => {
        const newBudgetObj = data;
        newBudgetObj.budgetAnalyticsDate = fields.effectiveDate;
        const newBudgetsArr = fields.forceRefresh
          ? [newBudgetObj]
          : cleanseBudgetsArray(budgetsArray, newBudgetObj);
        dispatch(setBudgetAnalytics(newBudgetsArr));
      })
      .catch(() => {});
  };

export const updateBudget = (bodyData: UpdateBudgetParams) => async (dispatch: Dispatch<any>) => {
  await post(true, {
    endpoint: "/budget/update",
    bodyData,
  });
  return dispatch(
    getBudget({
      effectiveDate: bodyData.effectiveDate,
      forceRefresh: true,
    })
  );
};

export const refreshBudget = () => async (dispatch: Dispatch<any>) => {
  dispatch(setBudgetAnalytics([]));
  dispatch(getBudgetFromAPI({ effectiveDate: dayjs().startOf("month").format("YYYY-MM-DD") }));
};

/**
 * Ensures that there's only one budget for that effectiveDate in Redux
 * @param budgetsArray
 * @param payload
 * @returns new budgets array
 */
const cleanseBudgetsArray = (budgetsArray: Array<BudgetItem>, budgetObj: BudgetItem) => {
  const newBudgetsArr = [...budgetsArray];
  remove(newBudgetsArr, (b) => b.budgetAnalyticsDate === budgetObj.budgetAnalyticsDate);
  newBudgetsArr.push({ ...budgetObj });
  return newBudgetsArr;
};

export const getFeeds = (pageNumber: number) => async (dispatch: Dispatch<any>) => {
  try {
    const { data } = await get(true, {
      endpoint: "/feeds",
      params: { pageNumber },
    });
    const feedsArray = data.feeds as FeedsParams[];
    if (feedsArray.length > 0) {
      const formatedFeedsArray = feedsArray.map((feed) => {
        let feedType = feed.feedType;
        return { ...feed, icon: FEEDS_ICON[feedType] } as FeedsParams;
      });
      dispatch(setFeeds(formatedFeedsArray));
    }
  } catch (error) {}
};

export const refreshFeeds = () => async (dispatch: Dispatch<any>) => {
  dispatch(clearFeeds());
  return dispatch(getFeeds(1));
};
