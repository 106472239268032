import React from "react";
import { IonRadioGroup, IonText, IonItem, IonRadio, IonIcon } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { FinancialAccount } from "../../../types";
import numeral from "numeral";
import { AccountLogo } from "../AccountsTab/AccountLogo";
import { PRIVILEGE_TYPE_DATA_3 } from "../../../constants";
import { eyeOffOutline } from "ionicons/icons";
import { formatAccountNumber } from "../../../utils/formatUtils";

type AccountPickerProps = {
  onSelect: (selectedId: string) => void;
  rows: FinancialAccount[];
  selectedAccountId?: string;
};

export const AccountPicker: React.FC<AccountPickerProps> = (props) => {
  const { onSelect, rows, selectedAccountId } = props;
  const { t } = useTranslation();
  return (
    <IonRadioGroup
      onIonChange={(e) => onSelect(e.detail.value)}
      value={selectedAccountId}
      className="account-picker"
      allowEmptySelection
    >
      {rows.map((account: FinancialAccount) => (
        <IonItem key={account.id} lines="none" data-testid="bankLinkItem">
          <AccountLogo account={account as any} className="ion-no-margin ion-margin-end" />
          <IonText>
            {account.hhInfo?.privilegeType === PRIVILEGE_TYPE_DATA_3.JUSTME ? (
              <i className="private font-xs text-margins">
                <IonIcon icon={eyeOffOutline} /> {t("privateAccount")}
              </i>
            ) : null}
            <h2 className="ion-no-margin font-md text-margins text-no-wrap">
              {account.accountName}{" "}
            </h2>
            <p className="balance ion-no-margin font-sm text-margins">
              {account.institution.name}{" "}
              {account.accountNumber && `(${formatAccountNumber(account.accountNumber)})`} |{" "}
              {numeral(account.balance).format("$0,0")}
            </p>
          </IonText>
          <IonRadio slot="end" value={account.id} />
        </IonItem>
      ))}
    </IonRadioGroup>
  );
};
