import * as types from "../actionTypes";

export const setLoading = (loading: boolean) => {
  return {
    type: types.SET_LOADING,
    payload: {
      loading,
    },
  };
};

export const setToastMessage = (toastMessage: string) => {
  return {
    type: types.SET_TOAST_MESSAGE,
    payload: {
      toastMessage,
    },
  };
};
