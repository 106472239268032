import React, { useState } from "react";
import { IonAlert, IonIcon } from '@ionic/react';
import { useTranslation } from "react-i18next";
import { PAGE_URLS } from "../../../constants";
import { close } from "ionicons/icons";
import { useNavigation } from "../../../utils/useNavigation";
import { NavButton } from "../../shared/PageTemplate";

type ExitAlertProps = {
  message?: string;
}

export const ExitAlert: React.FC<ExitAlertProps> = props => {
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const { message = t("areYouSureExitBeforeLinking") } = props
  const [open, setOpen] = useState(false);

  return <>
    <NavButton
      onClick={() => setOpen(true)}
      icon={<IonIcon icon={close} />}
      label={t("saveAndClose")}
      className="fy-nav-buttons"
      color="primary"
    />
    <IonAlert
      isOpen={open}
      onDidDismiss={() => setOpen(false)}
      header={t("exitGoalWithoutFinishing")}
      message={message}
      buttons={[
        { text: t("cancel"), handler: () => setOpen(false) },
        { text: t("yes"), handler: () => navigate(PAGE_URLS.GOALS_HOME, "back"), role: "destructive" },
      ]} />
  </>
}