import React from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { buildOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

type EditCategoriesButtonProps = {
  onClick: () => void;
};

export const EditCategoriesButton: React.FC<EditCategoriesButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();
  return <IonButton
    onClick={onClick}
    fill="clear"
    data-testid="budget_editCategories"
  >
    <IonIcon icon={buildOutline} className="half-padding" />
    {t("editCategories")}
  </IonButton>
}