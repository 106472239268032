import React, { useState } from "react";
import {
  IonHeader,
  IonLabel,
  IonList,
  IonItem,
  IonIcon,
  IonItemGroup,
  IonModal,
  IonButton,
  IonAlert,
  IonButtons,
  IonToolbar,
  IonTitle,
  IonContent,
} from "@ionic/react";
import { closeOutline, chevronForward } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import { useDispatch } from "../../../redux/hooks";
import { AccountLogo } from "./AccountLogo";
import { BALANCE_TYPES, PAGE_URLS, TRANSACTION_SOURCE } from "../../../constants";
import { formatAccountNumber } from "../../../utils/formatUtils";
import { Account } from "../../../redux/reducers/accounts";
import {
  deletePropertyItem,
  editAccountOrPropertyBalance,
  EditBalanceType,
  setLoading,
  setToastMessage,
} from "../../../redux/actions";
import { useUserName } from "../../shared/User/useUserName";
import "./AccountDetailModal.scss";
import dayjs from "dayjs";
import { trackEvent } from "../../../vendors/monitoring";
import { getTransactionLink } from '../../../utils/transUtils';

type AccountDetailModalProps = {
  account: Account | null;
  onClose: () => void;
};

export const AccountDetailModal: React.FC<AccountDetailModalProps> = (props) => {
  const { account, onClose } = props;
  const [editBalanceAlertOpen, setEditBalanceAlertOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isProperty = account?.balType === BALANCE_TYPES.PROPERTY;
  const isManual = account?.source === TRANSACTION_SOURCE.MANUAL;
  const isCorepro = account?.source === TRANSACTION_SOURCE.COREPRO;
  const { partnerName, singlePlayerMode } = useUserName();
  const canEditBalance = Boolean(account?.isOwner || account?.isJoint) && (isProperty || isManual);

  const handleBalanceUpdate = (updatedBalance: number) => {
    if (!account || !updatedBalance) {
      return;
    }
    dispatch(setLoading(true));
    dispatch(
      editAccountOrPropertyBalance(
        account._id,
        updatedBalance,
        isProperty ? EditBalanceType.PROPERTY : EditBalanceType.MANUAL
      )
    )
      .then(() => dispatch(setToastMessage(t("balanceUpdated"))))
      .catch(() => { })
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <IonModal isOpen={!!account} cssClass="account-detail-modal" onDidDismiss={onClose}>
      {account ? (
        <>
          <IonHeader className="account-header" mode="ios">
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton className="close-button" onClick={onClose}>
                  <IonIcon icon={closeOutline} slot="icon-only" size="large" />
                </IonButton>
              </IonButtons>
              <IonTitle>
                <IonLabel className="institution-title">
                  <AccountLogo account={account} />
                  <h1>{account.institutionName}</h1>
                </IonLabel>
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <h2 className="ion-margin-top ion-text-center font-title-3">
              {account.accountNickname || account.accountName || account.name}{" "}
              {formatAccountNumber(account.accountNumber)}
            </h2>

            <IonList className="ion-margin-top" lines="none">
              <IonItemGroup>
                {account.seeBal || account.isOwner ? (
                  <>
                    <IonItem
                      data-testid="balance-item"
                      onClick={() => canEditBalance && setEditBalanceAlertOpen(true)}
                    >
                      <IonLabel>
                        {t(account.balance.available ? "currentBalance" : "balance")}
                      </IonLabel>
                      {numeral(account.balance.current.amount).format("$0,0.00")}
                      {canEditBalance ? <IonIcon icon={chevronForward} /> : null}
                    </IonItem>
                    {account.balance.available && !isProperty ? (
                      <IonItem data-testid="balance-item">
                        <IonLabel>{t("availableBalance")}</IonLabel>
                        {numeral(account.balance.available.amount).format("$0,0.00")}
                      </IonItem>
                    ) : null}
                  </>
                ) : null}
                <IonItem>
                  <IonLabel>{t("accountType")}</IonLabel>
                  {account.balType}
                </IonItem>
                {singlePlayerMode ? null : isCorepro ? (
                  <IonItem>
                    <IonLabel>{t("whosGoal")}</IonLabel>
                    {/* TODO: render the correct avatar here: */}
                    {
                      {
                        "0": "Shared", // render both avatars
                        "1": account.isOwner ? "Yours" : "Partners", // render one avatar
                        "3": "Private", // render "private" icon
                        "4": null,
                      }[account.hhInfo[0]?.privilegeType]
                    }
                  </IonItem>
                ) : (
                  <IonItem>
                    <IonLabel>{t("owner")}</IonLabel>
                    {account.isJoint ? t("joint") : `${account.isOwner ? "You" : partnerName}`}
                  </IonItem>
                )}
                {!isProperty && !isCorepro ? (
                  <>
                    <IonItem
                      routerLink={getTransactionLink("advancedSearch", {
                        endDate: dayjs().format("MM/DD/YYYY"),
                        startDate: dayjs().subtract(90, "days").format("MM/DD/YYYY"),
                        accountIds: JSON.stringify([account._id]),
                        isPrivate: "both",
                      })}
                      onClick={onClose}
                      detail
                    >
                      <IonLabel>{t("viewTransactions")}</IonLabel>
                    </IonItem>
                    <IonItem
                      routerLink={PAGE_URLS.MORE_ACCTS_MANAGE_LINKED_ACCT.replace(
                        ":sourceInstitutionId",
                        account.sourceInstitutionId
                      ).replace(":accountId", account._id)}
                      onClick={onClose}
                      detail
                    >
                      <IonLabel>{t("manageAccount")}</IonLabel>
                    </IonItem>
                  </>
                ) : null}
                {isCorepro ? (
                  <IonItem
                    routerLink={PAGE_URLS.GOALS_DETAILS.replace(":goalId", account._id)}
                    onClick={onClose}
                    detail
                  >
                    <IonLabel>{t("manageGoals")}</IonLabel>
                  </IonItem>
                ) : null}
                {isProperty && account.isOwner ? (
                  <IonItem onClick={() => setConfirmDeleteOpen(true)} detail>
                    <IonLabel>{t("delete")}</IonLabel>
                  </IonItem>
                ) : null}
              </IonItemGroup>
            </IonList>
          </IonContent>

          <IonAlert
            isOpen={editBalanceAlertOpen}
            onDidDismiss={() => setEditBalanceAlertOpen(false)}
            header={t("editBalance")}
            inputs={[
              {
                name: "balance",
                type: "number",
                cssClass: "ion-text-center",
                value: Number(
                  account.balance.available
                    ? account.balance.available.amount
                    : account.balance.current.amount
                ),
              },
            ]}
            buttons={[
              {
                text: t("cancel"),
                role: "cancel",
              },
              {
                text: t("submit"),
                handler: (e) => handleBalanceUpdate(Number(e.balance)),
              },
            ]}
          />

          {/* For confirming account deletion */}
          <IonAlert
            isOpen={confirmDeleteOpen}
            onDidDismiss={() => setConfirmDeleteOpen(false)}
            header={t("areYouSure")}
            message={t("confirmDeleteProp", { name: account.name })}
            buttons={[
              { text: t("cancel"), role: "cancel" },
              {
                text: t("delete"),
                handler: () => {
                  trackEvent("delete_property", { category: "properties" });
                  dispatch(deletePropertyItem([account._id])).then(onClose);
                },
              },
            ]}
          />
        </>
      ) : null}
    </IonModal>
  );
};
