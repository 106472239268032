const icons = {
  moneyBag: require("./icon-generic-moneyBag-shadow.svg"),
  target: require("./icon-goals-target-shadow.svg"),
  repeat: require("./icon-generic-repeat-shadow.svg"),
  savings_bonus: require("./icon-generic-moneyBag.svg"),
  closed: require("./icon-generic-cancel.svg"),
  completed: require("./icon-generic-checkmark.svg"),
};

export default icons;
