import React from "react";
import { IonButtons, IonButton } from "@ionic/react";
import { useTranslation } from "react-i18next";

import "./DesktopNavBar.scss";

import { firstlyHeaderLogo } from "../../../assets/images";
import constants, { PAGE_URLS } from "../../../constants";
import { EXTERNAL_URLS } from "../../../constants";
import { useDispatch } from "../../../redux/hooks";
import { logout } from "../../../redux/actions";

import { trackEvent } from "../../../vendors/monitoring";

export const DesktopNavBar: React.FC = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <nav className="desktop-nav-bar">
      <IonButtons slot="start" className="logo-container">
        <IonButton
          onClick={() => {
            trackEvent("click_navLogo", { category: "navigation" });
          }}
          slot="start"
          routerLink={PAGE_URLS.HOME}
        >
          <img src={firstlyHeaderLogo} alt={constants.APP_NAME} className="firstly-logo" />
        </IonButton>
      </IonButtons>
      <IonButtons slot="end">
        <a className="nav-item" href={EXTERNAL_URLS.APP}>
          <IonButton
            onClick={() => {
              trackEvent("click_navApp", { category: "navigation" });
            }}
          >
            {t("app")}
          </IonButton>
        </a>
        <a className="nav-item" href={EXTERNAL_URLS.ABOUT}>
          <IonButton
            onClick={() => {
              trackEvent("click_navAbout", { category: "navigation" });
            }}
          >
            {t("aboutUs")}
          </IonButton>
        </a>
        <a className="nav-item" href={EXTERNAL_URLS.ARTICLES}>
          <IonButton
            onClick={() => {
              trackEvent("click_navArticles", { category: "navigation" });
            }}
          >
            {t("articles")}
          </IonButton>
        </a>
        <div className="nav-item">
          <IonButton
            routerLink={PAGE_URLS.LOGOUT}
            onClick={() => {
              trackEvent("click_logout", { category: "navigation" });
              dispatch(logout());
            }}
          >
            {t("logout")}
          </IonButton>
        </div>
      </IonButtons>
    </nav>
  );
};
