import React from "react";
import { AmountInputSlider } from "../../shared/AmountInput/AmountInputSlider";
import { RouteComponentProps } from "react-router";

import {
  GOAL_FUNDING_ACCOUNT_STATUS,
  GOAL_FREQUENCY_DISPLAYS,
  PRIVILEGE_TYPE_DATA_2,
  GOAL_STATUS,
  GOAL_TRIGGER_TYPES,
  PAGE_URLS,
} from "../../../constants";
import { useTranslation } from "react-i18next";
import { useCurrentGoal } from "../../../utils/useCurrentGoal";
import { Goal } from "../../../types/goals";
import { useUserName } from "../../shared/User/useUserName";
import { useDispatch } from "../../../redux/hooks";
import { activateGoal } from "../../../redux/actions/goal";
import { useNavigation } from "../../../utils/useNavigation";
import { NavHeader, PageTemplate, Section } from '../../shared/PageTemplate';
import { useViewport } from '../../../hooks/useViewport';
import { HelpButton } from "../../shared/Support/HelpButton";

/*
  A bit of a hack but it works for now
  The goal is to make this look like a footer in mobile
  even though it needs to be within the IonContent (due to react component hierarchy)
*/
const ContentSimulationWrapper: React.FC = ({ children }) => {
  const { isMobile } = useViewport();
  return <div style={isMobile ? { height: "calc(100vh - 280px)" } : undefined}>
    {children}
  </div>
}

export const ScheduleFunding: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const { navigate } = useNavigation();
  const goal = useCurrentGoal() as Goal;
  const dispatch = useDispatch();
  const { partnerName } = useUserName();
  const frequency = new URLSearchParams(history.location.search).get(
    "frequency"
  )! as keyof typeof GOAL_FREQUENCY_DISPLAYS;
  const { t } = useTranslation();

  const save = (contributionAmount: number) => {
    const params: any = {
      id: goal.id,
      contributionAmount,
      frequency,
      triggerType: GOAL_TRIGGER_TYPES.SCHEDULE,
      triggerDetails: {},
      fundingStatus: GOAL_FUNDING_ACCOUNT_STATUS.ACTIVE,
    };
    if (goal.status === GOAL_STATUS.NEW) {
      params.status = GOAL_STATUS.ACTIVE;
    }
    dispatch(activateGoal(params)).then((nextPage: string) => navigate(nextPage, "forward"));
  };

  return (
    <PageTemplate
      header={<NavHeader title={t("savingsGoal")} defaultHref={PAGE_URLS.GOALS_HOME}>
        <HelpButton pageLocation="goal-schedule-funding" className="fy-nav-buttons" color="" />
      </NavHeader>}
    >
      <Section>

        <AmountInputSlider
          min={1}
          max={500}
          step={1}
          mostCommon={50}
          initialValue={50}
          onChange={save}
          suffix={GOAL_FREQUENCY_DISPLAYS[frequency]}
          wrapper={ContentSimulationWrapper}
        >
          <h3>
            {goal?.hhInfo?.privilegeType === PRIVILEGE_TYPE_DATA_2.SHARED
              ? t("thinkHowMuchSaveWithPartner")
              : t("thinkHowMuchSave")}
          </h3>
        </AmountInputSlider>
      </Section>
    </PageTemplate>
  );
};
