import React, { useState } from "react";
import { IonPage, IonContent, IonFooter, IonButton, useIonViewDidEnter } from "@ionic/react";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./CelebrateActivation.scss";

import { Modal } from "../../shared/Modal/Modal";
import { PAGE_URLS, GOAL_FREQUENCY_TYPES, GOAL_TRIGGER_TYPES } from "../../../constants";
import images from "../../../assets/images/illustrations";
import { Confetti } from "../../shared/Confetti/Confetti";
import { useCurrentGoal } from "../../../utils/useCurrentGoal";
import { Goal } from "../../../types";
import { useNavigation } from "../../../utils/useNavigation";
import { PageTemplate } from '../../shared/PageTemplate/PageTemplate';
import { NavHeader } from "../../shared/PageTemplate";
import { HelpButton } from "../../shared/Support/HelpButton";
import { CelebrationModal } from "../../shared/Modal/CelebrationModal";
import { HighLight } from "../../shared/Highlight/HighLight";

const getFrequencyText = (goal: Partial<Goal>, t: (key: string) => string) => {
  const { frequency, triggerType } = goal.viewerFundingInfo ?? {};
  if (frequency === GOAL_FREQUENCY_TYPES.WEEKLY) {
    return t("fundingRuleWeekly");
  } else if (frequency === GOAL_FREQUENCY_TYPES.BIWEEKLY) {
    return t("fundingRuleBiweekly");
  } else if (
    frequency === GOAL_FREQUENCY_TYPES.ADHOC &&
    triggerType === GOAL_TRIGGER_TYPES.PAYDAY
  ) {
    return t("fundingRulePayday");
  }
  return t("fundingRuleGeneric");
};

export const CelebrateActivation: React.FC<RouteComponentProps> = (props) => {
  const { navigate } = useNavigation();
  const goal = useCurrentGoal();
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  useIonViewDidEnter(() => setModalOpen(true), []);

  const close = () => {
    setModalOpen(false);
    navigate(PAGE_URLS.GOALS_HOME, "forward");
  };

  return (
    <PageTemplate
      header={<NavHeader title={t("savingsGoal")} defaultHref={PAGE_URLS.GOALS_HOME}>
        <HelpButton className="fy-nav-buttons" color="" />
      </NavHeader>}
    >
      <CelebrationModal open={modalOpen} onClose={close} buttonText={t("gotIt")}>
        <img src={images.trophy} alt="celebration" />
        <h1>{t("wayToGo")}</h1>
        <h4>
          {t("youreA")}
          <HighLight>{t("savingsRockstar")}</HighLight>
          {t("wellStartSaving")} {getFrequencyText(goal, t)}
        </h4>
      </CelebrationModal >
    </PageTemplate >
  );
};
