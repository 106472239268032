export type AddRuleBodyData = {
  userId: string;
  hhId: string;
  transactionId?: string;
  isPrivate?: boolean;
  accountId: string | null | undefined;
  type: TransactionRuleTypes;
  updateExistingTransactions: boolean;
  ruleDetails: RuleDetailsObj;
};

export enum TransactionRuleTypes {
  NAME = "name",
  CATEGORY = "category",
  YOM = "yom",
}

type RuleDetailsObj = {
  matchingCriteria: RuleMatchingCriteria[];
  updateInfo: RuleUpdateInfo[];
};

export type RuleUpdateInfo = {
  field: RuleUpdateInfoField;
  prefix: RuleUpdateInfoPrefix;
  type: RuleUpdateInfoTypes;
  value: string | boolean | string[];
};

export enum RuleUpdateInfoPrefix {
  HH_INFO = "hhInfo",
}

export enum RuleUpdateInfoTypes {
  STRING = "string",
  BOOLEAN = "boolean",
  ARRAY_OF_MONGO_IDS = "arrayOfMongoIds",
}

export enum RuleUpdateInfoField {
  CATEGORY = "beefCategoryName",
  SUBCATEGORY = "honeyfiCategoryName",
  SHARED_EXPENSE = "sharedExpense",
  IS_PRIVATE = "isPrivate",
  TRANSACTION_VISIBILITY = "transactionVisibility",
  SHARED_EXPENSE_INDIVIDUAL = "sharedExpenseIndividual",
}

export type RuleMatchingCriteria = {
  field: RuleMatchingCriteriaField;
  matchType: RuleMatchingCriteriaMatchTypes;
  prefix: string;
  type: "string" | "number";
  value: RuleMatchingCriteriaValue;
  matchTypeDetails?: {
    caseSensitive: boolean;
    start: number;
    exactPosition: boolean;
    wholeWord: boolean;
  };
};

export type RuleMatchingCriteriaValue = string | number;

export enum RuleMatchingCriteriaField {
  DESCRIPTION_ORIGINAL = "description.original",
  DESCRIPTION_SIMPLE = "description.simple",
  BASE_TYPE = "baseType",
  AMOUNT = "amount.amount",
  CATEGORY = "beefCategoryName",
  SUBCATEGORY = "honeyfiCategoryName",
  DESCRIPTION_NAME_HASH = "description.nameHash",
}

export enum RuleMatchingCriteriaMatchTypes {
  EQUAL = "$eq",
  GREATER_THAN = "$gt",
  GREATER_THAN_EQUAL = "$gte",
  LESS_THAN_EQUAL = "$lte",
  LESS_THAN = "$lt",
  REGEX = "$regex",
}

export enum RuleMatchTypes {
  SIMILAR_TO = "similarTo",
  CONTAINS = "contains",
  BEGINS_WITH = "beginsWith",
  ENDS_WITH = "endsWith",
  IS_EXACTLY = "isExactly",
  IS_EQUAL = "isEqual",
  IS_GREATER = "isGreater",
  IS_GREATER_EQUAL = "isGreaterEqual",
  IS_LESS = "isLess",
  IS_LESS_EQUAL = "isLessEqual",
  IS_CREDIT = "isCredit",
  IS_DEBIT = "isDebit",
}

export enum ViewRulesFieldType {
  VIEW_TYPE = "viewType",
  MATCH_TYPE = "matchType",
  INPUT_VALUE = "inputValue",
}

export enum ViewRulesItemType {
  NAME = "name",
  BASE_TYPE = "baseType",
  CATEGORY = "category",
  AMOUNT = "amount",
}

export type ViewRulesType = {
  viewType: ViewRulesItemType;
  matchType: RuleMatchTypes;
  inputValue: any;
};

export type RULES_UPDATE_FIELDS_TYPE = {
  [dynamic: string]: RuleUpdateInfoField;
};

export type RULES_MATCH_FIELD = {
  [dynamic: string]: RuleMatchingCriteriaField;
};

export type RULES_MATCH_TYPE = {
  NAME: {
    [dynamic: string]: RuleMatchTypes;
  };
  AMOUNT: {
    [dynamic: string]: RuleMatchTypes;
  };
  BASE_TYPE: {
    [dynamic: string]: RuleMatchTypes;
  };
};

export interface RulesRes {
  data: RulesResponse[];
}

export type RulesResponse = {
  accountInfo: {
    accountName: string;
    accountNumber: string;
    institutionName: string;
    sourceInstitutionId: string;
    _id: string;
  };
  rules: RuleItem[];
};

export type RuleItem = {
  accountId: string;
  accountInfo: any;
  instId: string | null;
  createdDate: Date;
  hhId: string;
  isDeleted: boolean;
  lastUpdated: Date;
  matchingCriteria: RuleMatchingCriteria[];
  merchantName: string;
  meta: {
    merchantName: string;
  };
  type: TransactionRuleTypes;
  updateInfo: RuleUpdateInfo[];
  updatedBy: string;
  userId: string;
  version: string;
  _id: string;
  ruleText: string;
  isOwner: boolean;
  privType: any;
  avatarDisplay: number;
};
