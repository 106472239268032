import { components } from "../../types/schema";
import constants from "../../constants";
import {
  SET_USER_PROPERTIES,
  CLEAR_USER_PROPERTIES,
  SET_USER_PROPERTIES_FROM_HOUSEHOLD_DATA,
  SET_USER_FINANCIAL_PROFILE,
} from "../actionTypes";
import { onboardingFlags } from "../../constants";
import { UserFinancialProfileType } from "../../types/user";
import dayjs from "dayjs";
export enum UserMemberTypes {
  NONE = "",
  P1 = "P1",
  P2 = "P2",
}
const initialState: userState = {
  userId: "NEW",
  hhIdDefault: "",
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  gender: "",
  userName: "",
  token: "",
  magicLink: "",
  partnerName: "",
  partnerEmail: "",
  imageURL: constants.NO_IMAGE,
  corePro: {},
  financialProfile: {},
  emailValidated: undefined,
  hideEmailVerification: false,
  flag: onboardingFlags.NEW,
  memberType: UserMemberTypes.NONE,
  inviteCode: "",
  requestedBy: "",
  createdOn: dayjs().format("YYYY-MM-DD"),
  firstTime: true,
  firstWeekTime: true,
  hasPartner: false,
};

interface action {
  type: string;
  payload: Partial<userState>;
}

export interface userState {
  userId: string;
  hhIdDefault: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  gender?: string;
  userName: string;
  token: string;
  magicLink: string;
  partnerName: string;
  partnerEmail: string;
  imageURL: string;
  corePro: corePro;
  financialProfile: any;
  emailValidated?: boolean;
  hideEmailVerification?: boolean;
  flag: string;
  memberType: UserMemberTypes;
  inviteCode: string;
  requestedBy: string;
  createdOn: string;
  firstTime: boolean;
  firstWeekTime: boolean;
  hasPartner: boolean;
  singlePlayerMode?: boolean;
}

type inviteStatusTypes = components["schemas"]["InviteStatusTypes"];

export interface HouseholdMemberInvite {
  marketing: {
    channel: string | null;
    campaign: string | null;
    tags: string[] | null;
  };
  status: inviteStatusTypes[];
  requestedBy: string;
  isAdmin: boolean;
  referralCode: string;
  suppressInvite: boolean;
  _id: string;
  emailId: string;
  firstName: string;
  inviteCode: string;
  requestedByName: string;
  createdOn: string;
}

export interface HouseholdMember {
  _id: string;
  corePro: corePro;
  firstName: string;
  lastName: string;
  emailId: string;
  mobile: string;
  gender?: string;
  imageURL: string;
  imageURLUpdatedOn: string;
  financialProfile: any;
  emailValidated: boolean;
  relationshipType: string;
  singlePlayerMode?: boolean;
  invites: HouseholdMemberInvite[];
}

export type corePro = {
  customerIdHistory?: Array<any>;
  externalFundingAccountHistory?: Array<any>;
  goalsLimits?: any;
  goalsLimitsTier?: string;
};

const users = (state: userState = initialState, action: action) => {
  switch (action.type) {
    case SET_USER_FINANCIAL_PROFILE: {
      return {
        ...state,
        ...(action.payload as UserFinancialProfileType),
      };
    }
    case SET_USER_PROPERTIES: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case SET_USER_PROPERTIES_FROM_HOUSEHOLD_DATA: {
      // @ts-ignore
      const userPayload = action.payload.find((user: any) => user._id === state.userId);
      if (!userPayload) {
        return state;
      }
      const cleanUser = {
        userId: userPayload._id,
        firstName: userPayload.firstName,
        lastName: userPayload.lastName,
        email: userPayload.emailId,
        mobile: userPayload.mobile,
        gender: userPayload.gender,
        corePro: { ...userPayload.corePro },
        emailValidated: userPayload.emailValidated,
        financialProfile: { ...userPayload.financialProfile },
        imageURL: userPayload.imageURL,
        singlePlayerMode: userPayload.singlePlayerMode,
      };

      return {
        ...state,
        ...cleanUser,
      };
    }

    case CLEAR_USER_PROPERTIES: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default users;
