import React, { useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryState } from "../../../redux/selectors";
import { Category, CategoryMapObj, SubCategory } from "../../../types";
import {
  getAllCategorizationData,
  refreshCategoryDependencies,
  setLoading,
} from "../../../redux/actions";
import { BUDGET_SUPER_CATEGORIES, CATEGORY_CONSTANTS } from "../../../constants";
import { createSubcategoryObj, postCategoryMap } from "../../../utils/categorizationUtils";
import { cloneDeep } from "lodash";
import { useResponsiveInterface } from "../../../hooks/useResponsiveInterface";
import _ from "lodash";
import { PartialScreenModal } from "../../shared/Modal/PartialScreenModal";
import { Field } from "../../shared/Field/Field";
import { Picker } from "../../shared/Picker/Picker";

interface ChangeCategoryModalProps {
  isOpen: boolean;
  onClose: any;
  subcategory: SubCategory;
}

export const ChangeCategoryModal: React.FC<ChangeCategoryModalProps> = ({
  isOpen,
  onClose,
  subcategory,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const _interface = useResponsiveInterface();
  const { categoryData, subCategoryData } = useSelector(getCategoryState);
  const [selectedCategory, setSelectedCategory] = useState<string>();

  const saveNewCategorization = () => {
    if (!selectedCategory) {
      return;
    }
    const newSubcategoryData: SubCategory[] = cloneDeep(subCategoryData);
    const subcatToEdit = newSubcategoryData.find(
      (s: SubCategory) => s.subCategory === subcategory.subCategory
    );
    if (!subcatToEdit) {
      return;
    }
    dispatch(setLoading(true));
    subcatToEdit.category = selectedCategory;
    const categoryMap: CategoryMapObj = {
      isCustomMap: true,
      categories: cloneDeep(categoryData),
      subCategories: createSubcategoryObj(newSubcategoryData),
    };
    postCategoryMap(categoryMap)
      .then(() => {
        dispatch(getAllCategorizationData());
        resetStateAndClose();
      })
      .then(() => dispatch(refreshCategoryDependencies()))
      .catch()
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const resetStateAndClose = () => {
    setSelectedCategory(undefined);
    onClose(false);
  };

  return (
    <PartialScreenModal
      open={isOpen}
      onClose={resetStateAndClose}
      onAccept={saveNewCategorization}
      title={t("changeCategory")}
      subTitle={t("changeCategoryMsg", { subCategory: subcategory.displayName })}
      rightButtonText={t("change")}
      rightButtonProps={{ "data-testid": "ChangeCategoryModal_save" }}
      className="height-sm"
    >
      <Field label={t("category")}>
        <IonSelect
          onIonChange={({ detail }) => setSelectedCategory(detail.value)}
          data-testid="ChangeCategoryModal_category"
          interface={_interface}
          value={selectedCategory || subcategory.category}
        >
          {categoryData
            .filter(
              (c: Category) =>
                !c.isDeleted &&
                c.superCategory === BUDGET_SUPER_CATEGORIES.EXPENSES &&
                c.categoryShortName !== CATEGORY_CONSTANTS.GOALS
            )
            .map((c: Category) => {
              return (
                <IonSelectOption
                  data-testid={_.kebabCase(c.categoryShortName)}
                  value={c.categoryShortName}
                  key={c.categoryShortName}
                >
                  {c.categoryFullName}
                </IonSelectOption>
              );
            })}
        </IonSelect>
      </Field>
    </PartialScreenModal>
  );
};
