import { operations, components } from "./schema";

export type SpendingChallengeCreateParams = {
  name?: string;
  isPrivate?: boolean;
  competitionType?: string;
  challengeType?: string;
  periodType?: string;
  startDate?: string;
  endDate?: string;
  targets?: ({
    /** If doing the challenge for a particular set of accounts only. */
    accountIds?: string[];
    /** Which users are doing this challenge target? */
    users?: ({
      userId?: string;
      status?: string;
    } & { [key: string]: any })[];
    /** The target value. */
    value?: number;
    type?: string;
    /** see rules for how this works in detail */
    matchingCriteria?: ({
      prefix?: string;
      type?: string;
      field?: string;
      value?: string;
      matchType?: string;
      matchTypeDetails?: {
        caseSensitive?: boolean;
        start?: number;
        exactPosition?: boolean;
        wholeWord?: boolean;
      } & { [key: string]: any };
    } & { [key: string]: any })[];
  } & { [key: string]: any })[];
  rewards?: ({
    userIds?: string[];
    type?: string;
    /** A text field for now, but can make an object as well base on type. */
    details: { text: string };
  } & { [key: string]: any })[];
};

export type SpendingChallengeType = components["schemas"]["SpendingChallengesSchema"];

export enum SpendingChallengeFieldTypes {
  CATEGORY = "beefCategoryName",
  SUBCATEGORY = "honeyfiCategoryName",
}

export enum ChallengeType {
  INDIVIDUAL = "individual",
  COLLABORATIVE = "collaborative",
  COMPETITIVE = "competitive",
}

export enum ChallengeStatus {
  ACTIVE = "active",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
}

export enum ChallengeCategory {
  DINING = "dining",
  ALCOHOL = "alcohol",
  COFFEE = "coffee",
  ENTERTAINMENT = "entertainment",
  TAXI = "taxi",
  SHOPPING = "shopping",
}

export type RangeData = {
  floor: number;
  ceil: number;
  step: number;
  amountBasedOnFrequency: number;
  suggestedAmount: number;
  goalAmount: number;
};
