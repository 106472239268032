import React, { useState } from "react";
import { IonLabel, IonIcon, IonModal, IonActionSheet, IonContent, IonButton } from "@ionic/react";
import { addOutline, closeOutline, carOutline, homeOutline, diamondOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { PropertyForm } from "./PropertyForm";
import { LinkAndShareAccounts } from "../../shared/Link/LinkAndShareAccounts";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import "./AddAccountOrPropertyForm.scss";
import { ActionSheet } from "../../shared/PageTemplate";
import { trackEvent } from "../../../vendors/monitoring";

type propertyType = "house" | "car" | "other";

type AddAccountOrPropertyFormProps = {
  renderProps?: (props: any) => JSX.Element;
};

export const AddAccountOrPropertyForm: React.FC<AddAccountOrPropertyFormProps> = ({ renderProps }) => {
  const { t } = useTranslation();

  const [actionSheetEvent, setActionSheetEvent] = useState<Event>();
  const [addPropertyModalOpen, setAddPropertyModalOpen] = useState(false);
  const [addAccountModalOpen, setAddAccountModalOpen] = useState(false);
  const [selectedPropertyType, setSelectedPropertyType] = useState<propertyType>();

  const getClassName = (type: propertyType) => (selectedPropertyType === type ? "selected" : "");

  renderProps = renderProps || ((props: any) => <IonButton data-testid="addAccountOrProperty" {...props} >{t("addAccounts")}</IonButton>);

  return (
    <>
      {renderProps({
        onClick: (e: any) => {
          e.persist();
          setActionSheetEvent(e);
        },
      })}
      <ActionSheet
        cssClass="add-account-action-sheet"
        openEvent={actionSheetEvent}
        onDidDismiss={setActionSheetEvent as () => void}
        hideButton
        buttons={[
          {
            text: t("addFinancialAccount"),
            handler: () => {
              setAddAccountModalOpen(true);
            },
          },
          {
            text: t("addProperty"),
            handler: () => {
              setAddPropertyModalOpen(true);
              trackEvent("view_addProperty", { category: "addProperty" });
            },
          },
          { text: t("cancel"), handler: () => { }, role: "cancel" },
        ]}
      />
      <IonModal
        isOpen={addPropertyModalOpen}
        cssClass="add-property-modal"
        onDidDismiss={() => setAddPropertyModalOpen(false)}
      >
        <ModalHeader
          startBtnIcon={closeOutline}
          startBtnOnClick={() => setAddPropertyModalOpen(false)}
          title={t("addProperty")}
        />
        <IonContent>
          <div className="property-type-options">
            <IonLabel
              onClick={() => {
                trackEvent("click_houseProperty", { category: "addProperty" });
                setSelectedPropertyType("house");
              }}
              className={getClassName("house")}
            >
              <IonIcon size="large" icon={homeOutline} />
              {t("house")}
            </IonLabel>
            <IonLabel
              onClick={() => {
                trackEvent("click_carProperty", { category: "addProperty" });
                setSelectedPropertyType("car");
              }}
              className={getClassName("car")}
            >
              <IonIcon size="large" icon={carOutline} />
              {t("car")}
            </IonLabel>
            <IonLabel
              onClick={() => {
                trackEvent("click_otherProperty", { category: "addProperty" });
                setSelectedPropertyType("other");
              }}
              className={getClassName("other")}
            >
              <IonIcon size="large" icon={diamondOutline} />
              {t("other")}
            </IonLabel>
          </div>
          <PropertyForm
            type={selectedPropertyType}
            onSaveEnd={() => {
              trackEvent("save_addProperty", { category: "addProperty" });
              setAddPropertyModalOpen(false);
            }}
          />
        </IonContent>
      </IonModal>
      <LinkAndShareAccounts
        isOpen={addAccountModalOpen}
        onSuccess={() => setAddAccountModalOpen(false)}
      />
    </>
  );
};
