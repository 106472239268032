const images = {
  benefits_0: require("./icon-budget-handHeart-shadow.svg"),
  benefits_1: require("./icon-generic-mountain-shadow.svg"),
  benefits_2: require("./icon-generic-pieChart-shadow.svg"),
  benefits_3: require("./icon-generic-bell-shadow.svg"),
  benefits_4: require("./icon-generic-slider-shadow.svg"),
  slidesImg_0: require("./illustration-generic-polaroids.svg"),
  slidesImg_1: require("./illustration-onboarding-houseKeychain.svg"),
  slidesImg_2: require("./illustration-onboarding-hourglass.svg"),
  slidesImg_3: require("./illustration-onboarding-jerseys.svg"),
};
export default images;
