import { FINANCIAL_ACCOUNT_STATUS, PAGE_URLS } from "../constants";
import { accountState, findAccountByIdFromState } from "../redux/reducers/accounts";
import { SearchParam, Transaction, TransactionContext, TransactionSource } from "../types";
import { titleCase } from "./formatUtils";
import i18n from "i18next";
import pick from "lodash/pick";
import omitBy from "lodash/omitBy";
import { defaultSearchParams } from "../redux/actions";

/**
 * Returns formatted transaction name
 * @param param0 Transaction
 * @returns formatted transaction name
 */
export function transNameHelper({ description, source }: Partial<Transaction>) {
  const merchName = description?.nickname || description?.simple || description?.original || "";
  if (source === TransactionSource.MANUAL || description?.nickname) {
    return merchName;
  }
  if (merchName === merchName.toUpperCase() || merchName === merchName.toLowerCase()) {
    return titleCase(merchName);
  }
  return merchName;
}

export const transAccountNameHelper = (accountsState: accountState, accountId: string) => {
  const account = findAccountByIdFromState(accountsState, accountId);
  const name = account?.accountNickname || account?.accountName;
  if (account?.accountStatus === FINANCIAL_ACCOUNT_STATUS.CLOSED) {
    return name + ` (${i18n.t("closed")})`;
  }
  return name;
};

export function averageAmount(amt: number, ct: number) {
  return Math.round(amt / ct);
}

export const getTransactionLink = (context: TransactionContext, params: SearchParam) => {
  return PAGE_URLS.TRANS_HOME + '/?' + new URLSearchParams(omitBy({ context, ...params }, v => !v) as any);
}

export const extractTransactionSearchParams = function extractTransactionSearchParams(urlParams: string) {
  const { context, ...rawParams } = Object.fromEntries(new URLSearchParams(urlParams).entries());
  const searchParams = {
    ...defaultSearchParams, ...pick(rawParams, [
      "keyword",
      "transactionFilter",
      "categoryFilter",
      "subCategoryFilter",
      "accountIds",
      "baseType",
      "isPrivate",
      "startDate",
      "endDate",

      // non-search params
      "budgetMonth",
      "nameHash",
      "merchName",
      "billIds",
      "spendingChallengeId",
      "spendingTargetId",
    ])
  } as SearchParam;
  return {
    context: context as TransactionContext,
    searchParams,
  }
}

const paramMap = {
  "keyword": "N/A",
  "transactionFilter": "sharedExpense",
  "categoryFilter": "category",
  "subCategoryFilter": "subcategory",
  "accountIds": "N/A",
  "baseType": "N/A",
  "isPrivate": "isPrivate",
  "startDate": "N/A",
  "endDate": "N/A",

  // non-search params
  "budgetMonth": "N/A",
  "nameHash": "N/A",
  "merchName": "N/A",
  "billIds": "bills",
  "spendingChallengeId": "N/A",
  "spendingTargetId": "N/A",
}
export const filteredFieldChanged = (searchParams: SearchParam, updatedFields: string[]) => {
  const updated = new Set(updatedFields);
  return Object.keys(searchParams)
    .filter(key => searchParams[key as keyof SearchParam] !== defaultSearchParams[key as keyof SearchParam])
    .some(key => updated.has(paramMap[key as keyof typeof paramMap]));
}
