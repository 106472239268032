import React, { useState } from "react";
import { IonAlert, IonAvatar, IonItem, IonLabel, IonList, IonText } from "@ionic/react";
import { Transaction, Comment } from "../../../types/transactions";
import { useHistory } from "react-router-dom";
import { AVATAR_DISPLAY, PAGE_URLS } from "../../../constants";
import { useTranslation } from "react-i18next";
import Avatars from "../Avatar/Avatars";
import dayjs from "dayjs";
import "./Comments.scss";
import fromNow from "dayjs/plugin/relativeTime";
import { getUsersState } from "../../../redux/selectors";
import { useSelector } from "react-redux";
import { useUserName } from "../../shared/User/useUserName";
import orderBy from "lodash/orderBy";
dayjs.extend(fromNow);

interface CommentsProps {
  transaction?: Transaction;
  comments?: any;
  clickEvents: "open" | "edit" | "list";
  reactions?: any;
  handleCommentsRemove?: (commentId: string) => void;
  onTransactionTab?: boolean;
}

export const Comments: React.FC<CommentsProps> = ({
  transaction,
  clickEvents,
  handleCommentsRemove,
  comments,
  reactions,
  onTransactionTab = false,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { userId } = useSelector(getUsersState);
  const { partnerName } = useUserName();

  //state
  const [saveAlertOpen, setSaveAlertOpen] = useState<boolean>(false);
  const [selectedComment, setSelectedComment] = useState<string | undefined | "">("");

  const renderComments =
    clickEvents === "open"
      ? transaction?.comments.slice(-2)
      : clickEvents === "edit"
        ? transaction?.comments
        : comments;

  //method
  const handleCommentClick = (value?: string) => {
    switch (clickEvents) {
      case "open":
        if (transaction) {
          history.push(PAGE_URLS.TRANS_DETAILS.replace(":transactionId", transaction._id));
          return;
        }
      case "edit":
        setSaveAlertOpen(true);
        setSelectedComment(value);
      case "list":
        return;
      default:
        break;
    }
  };

  const handleCommentDelete = () => {
    if (handleCommentsRemove && selectedComment) handleCommentsRemove(selectedComment);
    setSelectedComment("");
  };

  const renderCommentOrReaction = (sectionType: "comment" | "reaction", element: any) => {
    switch (sectionType) {
      case "comment":
        return (
          <IonItem
            key={element._id}
            className="CommentItem ion-text-start ion-no-padding"
            onClick={(e) => {
              e.stopPropagation();
              handleCommentClick(element._id);
            }}
            data-testid="transactionCommentsItem"
          >
            <IonAvatar
              slot="start"
              className={`reactions-avatar ${onTransactionTab ? "ion-no-margin" : "ion-margin-end"
                }`}
            >
              <Avatars
                avatarType={userId === element.userId ? AVATAR_DISPLAY.ME : AVATAR_DISPLAY.PARTNER}
                sizeStyle={onTransactionTab ? "xs" : "sm"}
              />
            </IonAvatar>
            <IonLabel>
              <h3 className={`text-wrap ${onTransactionTab ? "font-sm" : "font-md"}`}>
                {element.message}
              </h3>
              {!onTransactionTab && (
                <h4 className="font-sm">{dayjs(element.updatedOn).fromNow()}</h4>
              )}
            </IonLabel>
          </IonItem>
        );
      case "reaction":
        return (
          <IonItem key={element._id} className="ion-text-start ion-no-padding">
            <IonAvatar slot="start" className="reactions-avatar ion-margin-end">
              <Avatars
                avatarType={userId === element.userId ? AVATAR_DISPLAY.ME : AVATAR_DISPLAY.PARTNER}
                sizeStyle="sm"
              />
            </IonAvatar>
            <IonText className="font-sm">
              {element.userId === userId
                ? `${t("you")} ${element.reaction}${t("ed")} ${partnerName}`
                : `${partnerName} ${element.reaction}${t("ed")} ${t("you").toLowerCase()}`}
            </IonText>
            <IonText slot="end" className="font-sm comment-list-date">
              {dayjs(element.updatedOn).fromNow()}
            </IonText>
          </IonItem>
        );

      default:
        break;
    }
  };

  return (
    <IonList lines="none" className="comments-list" data-testid="commentsList">
      {orderBy([...(renderComments || []), ...(reactions || [])], ["updatedOn"], ["desc"]).map(
        (element: any) =>
          element.reaction
            ? renderCommentOrReaction("reaction", element)
            : renderCommentOrReaction("comment", element)
      )}
      {saveAlertOpen ? (
        <IonAlert
          isOpen={saveAlertOpen}
          onDidDismiss={() => setSaveAlertOpen(false)}
          header={t("deleteComment")}
          message={t("deleteCommentDes")}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => setSaveAlertOpen(false),
            },
            {
              cssClass: "commentsDeleteBtn",
              text: t("delete"),
              handler: () => handleCommentDelete(),
            },
          ]}
        />
      ) : null}
    </IonList>
  );
};
