import React, { useState } from "react";
import {
  IonButtons,
  IonButton,
  IonAlert,
  IonList,
  IonItem,
  IonListHeader,
  IonText,
  IonIcon,
  IonLabel,
  IonPage,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import { helpCircleOutline } from "ionicons/icons";

import "./GoalDetail.scss";

import {
  GOAL_FUNDING_ACCOUNT_STATUS,
  GOAL_STATUS,
  PAGE_URLS,
  PRIVILEGE_TYPE_DATA_2,
} from "../../../constants";
import { Goal } from "../../../types";
import { useCurrentGoal } from "../../../utils/useCurrentGoal";
import { CategoryIcon } from "./CategoryIcon";
import { GoalProgress } from "./GoalProgress";
import { AmountInputSlider } from "../../shared/AmountInput/AmountInputSlider";
import { useSelector } from "../../../redux/hooks";
import { getGoalsState } from "../../../redux/selectors";
import { getGoalDefs, getContributionDescription } from '../../../utils/goalUtils';
import { Modal } from "../../shared/Modal/Modal";
import { GoalTransferConfirmation } from "./GoalTransferConfirmation";
import { ReopenGoalConfirmation } from "./ReopenGoalConfirmation";
import { TransactionList } from "./TransactionList";
import { NavButton, NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { HelpButton } from "../../shared/Support/HelpButton";
import { Field } from "../../shared/Field/Field";

type Dialog =
  | ""
  | "reopen"
  | "unpause"
  | "transfer"
  | "confirmTransfer"
  | "attemptWithdraw"
  | "withdraw"
  | "confirmWithdraw"
  | "noBalance"
  | "whyBalanceDiff";

export const GoalDetail: React.FC = () => {
  const goal = useCurrentGoal() as Goal;
  const { goalsLimits } = useSelector(getGoalsState)?.accountInfo || {};
  const [transferAmount, setTransferAmount] = useState(0);
  const [dialog, setDialog] = useState<Dialog>("");
  const { t } = useTranslation();
  if (!goal.name) return <IonPage />;

  const alertProps = {
    "": {
      type: "",
    },
    reopen: {
      type: "modal",
      title: t("review"),
      onClose: () => setDialog(""),
      children: (
        <ReopenGoalConfirmation type="REOPEN" goal={goal} onSaveEnd={() => setDialog("")} />
      ),
    },
    unpause: {
      type: "modal",
      title: t("review"),
      onClose: () => setDialog(""),
      children: (
        <ReopenGoalConfirmation type="UNPAUSE" goal={goal} onSaveEnd={() => setDialog("")} />
      ),
    },
    transfer: {
      type: "modal",
      title: " ",
      onClose: () => setDialog(""),
      endBtnText: <HelpButton pageLocation="add-goal-funds" />,
      children: (
        <AmountInputSlider
          initialValue={100}
          max={Math.min(1500, goalsLimits?.deposit.single)}
          min={0}
          step={10}
          color={getGoalDefs(goal.category)?.design.textColor}
          onChange={(amount) => {
            setTransferAmount(amount);
            setDialog("confirmTransfer");
          }}
        >
          <h3 className="font-md">{t("howMuchTransfer")}</h3>
        </AmountInputSlider>
      ),
    },
    confirmTransfer: {
      type: "modal",
      title: t("reviewDeposit"),
      onClose: () => setDialog(""),
      children: (
        <GoalTransferConfirmation
          type="DEPOSIT"
          goal={goal}
          amount={transferAmount}
          onSaveEnd={() => setDialog("")}
        />
      ),
    },
    withdraw: {
      type: "modal",
      title: " ",
      onClose: () => setDialog(""),
      endBtnText: <HelpButton pageLocation="withdraw-goal-funds" />,
      children: (
        <AmountInputSlider
          initialValue={goal.viewerFundingInfo ? goal.viewerFundingInfo?.availableBalance / 2 : 0}
          max={goal.viewerFundingInfo?.availableBalance || 0}
          min={0}
          step={1}
          submitText={t("withdraw")}
          color={getGoalDefs(goal.category)?.design.textColor}
          onChange={(amount) => {
            setTransferAmount(amount);
            setDialog("confirmWithdraw");
          }}
        >
          <h3 className="font-md">{t("howMuchWithdraw")}</h3>
        </AmountInputSlider>
      ),
    },
    confirmWithdraw: {
      type: "modal",
      title: t("reviewWithdrawal"),
      onClose: () => setDialog(""),
      children: (
        <GoalTransferConfirmation
          type="WITHDRAWAL"
          goal={goal}
          amount={transferAmount}
          onSaveEnd={() => setDialog("")}
        />
      ),
    },
    attemptWithdraw: {
      type: "alert",
      onDidDismiss: () => setDialog(""),
      header: t("areYouSure"),
      message: t("confirmWithdraw", { goalName: goal.name }),
      buttons: [t("cancel"), { text: t("yesWithdraw"), handler: () => setDialog("withdraw") }],
    },
    noBalance: {
      type: "alert",
      onDidDismiss: () => setDialog(""),
      header: t("noAvailableBalance"),
      message: t("noAvailableBalanceMessage"),
      buttons: [t("gotIt")],
    },
    whyBalanceDiff: {
      type: "alert",
      onDidDismiss: () => setDialog(""),
      header: t("yourAvailableBalance"),
      message: t("availableBalanceExplanation"),
      buttons: [t("gotIt")],
    },
  };

  const isActive = GOAL_STATUS.ACTIVE === goal.status;
  const canEdit = goal.hhInfo?.privilegeType === PRIVILEGE_TYPE_DATA_2.SHARED || !!goal.viewerFundingInfo
  const isClosedOrCompleted = [GOAL_STATUS.COMPLETED, GOAL_STATUS.CLOSED].includes(goal.status!);
  const fundingIsPaused =
    goal.viewerFundingInfo?.status === GOAL_FUNDING_ACCOUNT_STATUS.PAUSED.toLowerCase();
  const fundingIsActive =
    goal.viewerFundingInfo?.status === GOAL_FUNDING_ACCOUNT_STATUS.ACTIVE.toLowerCase();
  const totalBalance =
    (goal.viewerFundingInfo?.balance || 0) + (goal.viewerFundingInfo?.pendingBalance || 0);

  return (
    <PageTemplate
      contentProps={{ className: "goal-detail" }}
      header={
        <NavHeader title={t("goalDetails")} defaultHref={PAGE_URLS.GOALS_HOME}>
          {canEdit && <NavButton
            icon={<>{t("edit")}</>}
            label={t("goal")}
            className="fy-nav-buttons"
            routerLink={PAGE_URLS.GOALS_EDIT.replace(":goalId", goal.id)}
            routerDirection="forward"
            data-testid="editGoalButton"
          />}
        </NavHeader>
      }
    >
      <Section>
        {goal.status !== GOAL_STATUS.ACTIVE ? (
          <div className="status-banner ion-text-center font-md">
            {t("thisGoalStatus", { status: goal.status?.toLowerCase() })}
          </div>
        ) : fundingIsPaused ? (
          <div className="status-banner ion-text-center font-md">
            {t("thisGoalStatus", { status: GOAL_FUNDING_ACCOUNT_STATUS.PAUSED.toLowerCase() })}
          </div>
        ) : null}
        <div className="split-pane ion-padding-top">
          <div>
            <CategoryIcon category={goal.category} type="circle" className="ion-margin-start" />
          </div>
          <IonButtons className="ion-margin-end">
            {canEdit && !isClosedOrCompleted && fundingIsPaused ? (
              <IonButton
                onClick={() => setDialog("unpause")}
                fill="solid"
                className="font-sm bold inline-ml-8"
                data-testid="unpauseButton"
              >
                {t("unpause")}
              </IonButton>
            ) : null}
            {canEdit && !isClosedOrCompleted && fundingIsActive && goalsLimits?.transfersEligible ? (
              <IonButton
                onClick={() => {
                  setDialog("transfer");
                }}
                fill="solid"
                className="font-sm bold inline-ml-8"
                data-testid="addMoneyButton"
              >
                {t("addMoney")}
              </IonButton>
            ) : null}
            {canEdit && isClosedOrCompleted ? (
              <IonButton
                onClick={() => setDialog("reopen")}
                fill="solid"
                className="font-sm bold inline-ml-8"
                data-testid="reopenButton"
              >
                {" "}
                {t("reopenGoal")}
              </IonButton>
            ) : null}
            {canEdit && goalsLimits?.transfersEligible &&
              (isActive || isClosedOrCompleted) &&
              (fundingIsActive || fundingIsPaused) ? (
              <IonButton
                fill="outline"
                className="font-sm bold inline-ml-8"
                onClick={() => {
                  setDialog(
                    goal.viewerFundingInfo?.availableBalance === 0 ? "noBalance" : "attemptWithdraw"
                  );
                }}
              >
                {t("withdraw")}
              </IonButton>
            ) : null}
          </IonButtons>
        </div>
        <IonList>
          <h3 className="font-title-3 fy-section-title-border ion-padding-start">{goal.name}</h3>
          <IonItem lines="none">
            <GoalProgress goal={goal} />
          </IonItem>
          {totalBalance !== goal.viewerFundingInfo?.availableBalance ? <>
            <Field label={<IonLabel position="stacked">
              {t("availableBalance")}
              <IonIcon
                icon={helpCircleOutline}
                className="weblink ion-padding-horizontal vertical-align-bottom font-lg"
                onClick={() => setDialog("whyBalanceDiff")}
              />
            </IonLabel>
            }>
              <IonText>{numeral(goal.viewerFundingInfo?.availableBalance).format("$0,0")}</IonText>
            </Field>
            <Field label={t("totalBalance")}>
              <IonText>{numeral(totalBalance).format("$0,0")}</IonText>
            </Field>
          </> : null}

          {!isClosedOrCompleted && goal?.viewerFundingInfo ? (
            <Field label={t("yourContributionRule")}>
              <IonText>
                {getContributionDescription(goal.viewerFundingInfo)}
              </IonText>
            </Field>
          ) : null}
          {!isClosedOrCompleted &&
            !goal.viewerFundingInfo &&
            goal.hhInfo?.privilegeType === PRIVILEGE_TYPE_DATA_2.SHARED ? (
            <>
              <IonListHeader>
                <IonText>
                  <h3 className="font-title-3">{t("contributionRule")}</h3>
                </IonText>
              </IonListHeader>
              <IonItem lines="none">
                <IonText>
                  {getContributionDescription(goal.nonViewerFundingInfo!)}
                </IonText>
              </IonItem>
            </>
          ) : null}
        </IonList>
      </Section>
      <Section>
        <IonList>
          <TransactionList goal={goal} />
        </IonList>
      </Section>
      <IonAlert
        {...alertProps[dialog]}
        isOpen={alertProps[dialog].type === "alert"}
        cssClass="goal-detail-alert"
      />
      <Modal {...(alertProps[dialog] as any)} open={alertProps[dialog].type === "modal"} />
    </PageTemplate>
  );
};
