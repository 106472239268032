import React from "react";
import { IonItem, IonInput, IonLabel, IonIcon } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { AVATAR_DISPLAY, PAGE_URLS } from "../../../../constants";
import Avatars from "../../../shared/Avatar/Avatars";
import { HHProps } from "../../../../types/user";
import { CameraIcon } from "./CameraIcon";
import { formattedName } from "../../../../utils/formatUtils";
import { Picker } from "../../../shared/Picker/Picker";
import { DatePicker } from "../../../shared/DatePicker/DatePicker";
import { createOutline } from "ionicons/icons";
import dayjs from "dayjs";

export const Household: React.FC<HHProps> = (props) => {
  const { hhData, handleUpdate } = props;
  const { t } = useTranslation();
  const notSet = t("profileNotSet");

  // this ensures nick-name can be empty (in case you want a completely different name)
  return (
    <div className="profile-tab">
      <Avatars
        className="ion-margin-top ion-margin-bottom"
        sizeStyle="lg"
        avatarType={AVATAR_DISPLAY.SHARED}
        extraLayer={<CameraIcon />}
        diagonal
      />
      <IonItem lines="none">
        <IonLabel position="stacked">{t("nickname")}</IonLabel>
        <IonInput
          inputMode="text"
          placeholder={notSet}
          autocapitalize="words"
          value={formattedName(hhData?.name) || ""}
          onIonChange={(e) => handleUpdate({ name: e.detail.value! })}
        ></IonInput>
        <IonIcon className="ion-align-self-end font-md" icon={createOutline} slot="end" />
      </IonItem>
      <IonItem lines="none">
        <IonLabel position="stacked">{t("anniversary")}</IonLabel>
        <DatePicker
          placeholder={notSet}
          displayFormat="MM/DD/YYYY"
          value={hhData?.anniversary}
          max={dayjs().format("YYYY-MM-DD")}
          onChange={(date) => handleUpdate({ anniversary: date })}
        />
      </IonItem>
      <IonItem lines="none">
        <IonLabel position="floating">{t("stage")}</IonLabel>
        <Picker
          value={hhData?.relationshipStage || notSet}
          name="relationshipStage"
          onChange={(value) => handleUpdate({ relationshipStage: value })}
          animated={false}
          options={[
            t("profileNotSet"),
            t("stageDating"),
            t("stageEngaged"),
            t("stageMarried"),
            t("stageCivil"),
            t("stageDomestic"),
            t("other"),
          ]}
        />
      </IonItem>
      <IonItem detail routerLink={PAGE_URLS.MORE_PROFILE_SHARED_FINANCES} lines="none">
        <IonLabel>{t("finances")}</IonLabel>
      </IonItem>
    </div>
  );
};
