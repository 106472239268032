import { IonSelect, IonSelectOption, IonLabel, IonText, IonContent } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";

import "./ChartLegend.scss";

import { Comparisons } from "../../../redux/reducers/spendingForecast";
import { colorById } from "./SpendingForecastChart";
import { useResponsiveInterface } from "../../../hooks/useResponsiveInterface";

type ChartLegendProps = {
  comparison: keyof Comparisons | "";
  onComparisonChange: (comparison: keyof Comparisons | "") => void;
};

export const ChartLegend: React.FC<ChartLegendProps> = (props) => {
  const { t } = useTranslation();
  const _interface = useResponsiveInterface();
  const { comparison, onComparisonChange } = props;

  return (
    <div className="chart-legend">
      <LegendLabel value="actual" key="actual" />
      <LegendLabel value="forecast" key="forecast" />
      <IonSelect
        value={comparison || "compare"}
        onIonChange={(e) => onComparisonChange(e.detail.value)}
        interface={_interface}
        placeholder={t("compare")}
        className={comparison || "compare"}
      >
        {["actualIncome", "prevMonth", "budgetedIncome", "budgetedSpend"].map((value) => (
          <IonSelectOption key={value} value={value}>
            <IonContent>
              <LegendLabel value={value} />
            </IonContent>
          </IonSelectOption>
        ))}
        <IonSelectOption key="noComparison" value="">
          {t("noComparison")}
        </IonSelectOption>
      </IonSelect>
    </div>
  );
};

type LegendLabelProps = {
  value: string;
};
export const LegendLabel: React.FC<LegendLabelProps> = (props) => {
  const { t } = useTranslation();
  const { value } = props;
  return <IonLabel className={`legend-label ${value || "compare"}`}>{t(value)}</IonLabel>;
};
