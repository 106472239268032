import React, { useState } from "react";
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryState } from "../../../redux/selectors";
import { Category } from "../../../types";
import {
  getAllCategorizationData,
  refreshCategoryDependencies,
  setLoading,
} from "../../../redux/actions";
import { CATEGORY_CONSTANTS } from "../../../constants";
import { createNewSubcategory } from "../../../utils/categorizationUtils";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import "./AddSubcategoryModal.scss";
import { useResponsiveInterface } from "../../../hooks/useResponsiveInterface";
import { trackEvent } from "../../../vendors/monitoring";

interface AddSubcategoryModalProps {
  isOpen: boolean;
  onClose: any;
  categoryShortName?: string;
}

export const AddSubcategoryModal: React.FC<AddSubcategoryModalProps> = ({
  isOpen,
  onClose,
  categoryShortName = "",
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const _interface = useResponsiveInterface();
  const { categoryData, subCategoryData } = useSelector(getCategoryState);
  const [selectedCategory, setSelectedCategory] = useState<string>(categoryShortName);
  const [newSubcategoryName, setNewSubcategoryName] = useState<string>("");

  const saveNewSubcategory = () => {
    dispatch(setLoading(true));
    createNewSubcategory({
      selectedCategory,
      newSubcategoryName,
      categoryData,
      subCategoryData,
    })
      .then(() => {
        dispatch(getAllCategorizationData());
        resetStateAndClose();
      })
      .then(() => dispatch(refreshCategoryDependencies()))
      .catch()
      .finally(() => {
        trackEvent("save_addSubcategory", { category: "editCategories" });
        dispatch(setLoading(false));
      });
  };

  const resetStateAndClose = () => {
    setNewSubcategoryName("");
    setSelectedCategory("");
    onClose(false);
  };

  return (
    <IonModal isOpen={isOpen} cssClass="addSubcategoryModal" onDidDismiss={resetStateAndClose}>
      <ModalHeader title={t("addSubcategory")} />
      <IonContent>
        <IonList>
          <IonItem lines="none">
            <IonLabel>{t("category")}</IonLabel>
            <IonSelect
              slot="end"
              onIonChange={({ detail }) => setSelectedCategory(detail.value)}
              data-testid="AddSubcategoryModal_category"
              interface={_interface}
              value={selectedCategory}
            >
              {categoryData
                .filter((c: Category) => c.categoryShortName !== CATEGORY_CONSTANTS.GOALS)
                .map((c: Category) => {
                  return (
                    <IonSelectOption value={c.categoryShortName} key={c.categoryShortName}>
                      {c.categoryFullName}
                    </IonSelectOption>
                  );
                })}
            </IonSelect>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{t("subcategory")}</IonLabel>
            <IonInput
              className="ion-text-end"
              slot="end"
              placeholder={t("enterName")}
              type="text"
              data-testid="AddSubcategoryModal_subcategory"
              onIonChange={({ detail }) => setNewSubcategoryName(detail.value || "")}
            />
          </IonItem>
        </IonList>
        <div className="ion-text-center ion-margin-top">
          <IonButton fill="clear" slot="start" onClick={resetStateAndClose}>
            {t("cancel")}
          </IonButton>
          <IonButton
            slot="end"
            onClick={() => saveNewSubcategory()}
            data-testid="AddSubcategoryModal_save"
          >
            {t("save")}
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};
