import React from "react";
import "./HighLight.scss";

interface HighLightProps {
  color?: string;
  position?: "default" | "left";
  size?: string;
}

export const HighLight: React.FC<HighLightProps> = (props) => {
  const { color = "#6deb86", position = "default", children, size } = props;
  return (
    <span className={`${size}`}>
      <span className="highlight">
        {children}
        <div className={`highlight-${position}`} style={{ backgroundColor: color }}></div>
      </span>
    </span>
  );
};
