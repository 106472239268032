import { Plugins } from "@capacitor/core";
import { BranchInitEvent } from "capacitor-branch-deep-links";
import BranchWeb, { BranchError, SessionData } from "branch-sdk";
import { handlePush, HoneyfiPushPayload } from "../utils/pushUtils";
import { history } from "../history";
import { PushNotification } from "@capacitor/core";
import { setLoading } from "../redux/actions";
import store from "../redux/store";
import { setBranchData } from "../redux/actions/branchData";
import { PAGE_URLS } from "../constants";

const { BranchDeepLinks, Device } = Plugins;
let onDevice = false;

// init branch sdk
export const init = async () => {
  const device = await Device.getInfo();
  try {
    if (device.platform === "web") {
      BranchWeb.init(
        `${process.env.REACT_APP_BRANCH_KEY!}`,
        {},
        (err: BranchError, data: SessionData | null) => {
          if (err) {
            console.error(err);
          } else {
            console.debug(data);
            // alert(`${JSON.stringify(data)}`);
            if (data) {
              store.dispatch(setBranchData(data));
              processDeepLink(data.data_parsed);
            }
          }
        }
      );
    } else {
      onDevice = true;
      BranchDeepLinks.addListener("init", (event: BranchInitEvent) => {
        // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
        // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
        console.debug(event.referringParams);
        // alert(JSON.stringify(event));
        store.dispatch(setBranchData(event));
        processDeepLink(event.referringParams);
      });
      BranchDeepLinks.addListener("initError", (error: any) => {
        console.error(error);
        // alert(JSON.stringify(error));
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const setAppUserId = (userId: string) => {
  try {
    if (onDevice && BranchDeepLinks) {
      BranchDeepLinks.setIdentity({ newIdentity: userId });
    } else {
      BranchWeb.setIdentity(userId);
    }
  } catch (error) {
    console.error(`attribution setAppUserId error: ${JSON.stringify(error)}`);
  }
};

export const attributionLogout = () => {
  try {
    if (onDevice && BranchDeepLinks) {
      BranchDeepLinks.logout();
    } else {
      BranchWeb.logout();
    }
  } catch (error) {
    console.error(`attribution logout error: ${JSON.stringify(error)}`);
  }
};

const branchReservedPrefixes = ["$", "~", "+"]; // https://help.branch.io/using-branch/docs/creating-a-deep-link#reserved-prefixes
export const processDeepLink = (data: Record<string, any>) => {
  let linkParams = "";
  for (let key in data) {
    if (data.hasOwnProperty(key) && !branchReservedPrefixes.includes(key.substr(0, 1))) {
      if (linkParams === "") {
        linkParams += `?${key}=${data[key]}`;
      } else {
        linkParams += `&${key}=${data[key]}`;
      }
    }
  }
  if (data["$deeplink_path"]) {
    store.dispatch(setLoading(true));
    history.push(`${data["$deeplink_path"]}${linkParams}`);
    store.dispatch(setLoading(false));
  } else if (data.pushActionType) {
    //notification engine email
    //format it into a pushPayload
    let payload: Partial<HoneyfiPushPayload> = {
      pushActionDetail: {},
    };
    for (let i in data) {
      if (i.substring(0, 17) === "pushActionDetail.") {
        //converts the flattened urlParams to an object with hierarchy
        // @ts-ignore
        payload.pushActionDetail[i.substring(17)!] = data[i];
      } else {
        payload[i as keyof HoneyfiPushPayload] = data[i];
      }
    }
    //convert from string to number
    payload.pushActionType = data.pushActionType ? parseInt(data.pushActionType) : 0;
    const syntheticNotification: PushNotification = {
      id: "1234",
      data: {},
      notification: {
        message: data.message as string,
        title: data.title as string,
        additionalData: {
          payload: payload,
        },
      },
    };
    handlePush(
      syntheticNotification,
      false,
      store.dispatch, // not used by handlePush when appOpen = false
      store.getState // not used by handlePush when appOpen = false
    );
  } else {
    store.dispatch(setLoading(false));
  }
};
