import { PushNotification, PushNotificationActionPerformed } from "@capacitor/core";
import { Dispatch } from "redux";

import { onboardingFlags, PAGE_URLS } from "../constants";
import { feedDetailSwitch, FeedType } from "./feedUtils";
import { setAlertUiState, setShowAlert } from "../redux/actions";
import { AlertActionType, AlertBoxType, alertDataType } from "../redux/reducers/alertUi";
import { registerForPush } from "../redux/actions/deviceActions";
import { history } from "../history";
import i18n from "i18next";

/*
{
  body: "TEST"
  data:
    message: "TEST"
    notId: "511"
    payload: "{"pushNotificationType":4,"pushActionType":1,"pushActionDetail":{"state":"tab.analytics"}}"
    title: "Honeyfi Dev"
  __proto__: Object
  id: "0:1617302178766553%ecdc4ec8ecdc4ec8"
  title: "Honeyfi Dev"
}
*/

export enum PushNotificationType {
  PARTNER_ACTIVITY = 1,
  CHAT = 2,
  ACCOUNT_ALERT = 3,
  NEW_TRANSACTIONS = 4,
  INSIGHT = 5,
  BILL_REMINDERS = 6,
  BALANCE = 7,
  BUDGET_ALERT = 8,
}

export enum PushActionType {
  GENERIC = 1,
  FEED = 2,
  CHAT = 3,
  REFRESH_HOMESCREEN = 4,
}

export interface PushActionDetail {
  state?: string;
  feedType?: FeedType;
  year?: number;
  month?: number;
  entity?: FeedType;
  entityId?: string;
  refreshAccounts?: boolean;
  comment?: string;
}

export interface HoneyfiPushPayload {
  pushNotificationType: PushNotificationType;
  pushActionType: PushActionType;
  pushActionDetail: PushActionDetail;
  foregroundSuppress: boolean;
}

//Function to handle push notifications
export const handlePush = async (
  notification: PushNotification | PushNotificationActionPerformed,
  appOpen: boolean,
  dispatch: Dispatch,
  getState: any
) => {
  //Need to check to see if notification.notification exists (came from action)
  const notificationBody = (
    notification.notification ? notification.notification : notification
  ) as PushNotification;
  console.debug("notification: " + JSON.stringify(notificationBody, null, 2));
  if (!notificationBody.data) {
    return;
  }
  if (notificationBody.data.receiver === "intercom_sdk") {
    console.debug("intercom push: uh oh");
    return;
  } else if (!notificationBody.data.payload && !notificationBody.data.data.payload) {
    return; //not properly formatted Honeyfi Push
  }
  const realData = notificationBody.data.data || notificationBody.data; // ios || android
  const payload = (
    typeof realData.payload === "string" ? JSON.parse(realData.payload) : realData.payload
  ) as HoneyfiPushPayload;
  const action = payload.pushActionType;
  const actionDetail = payload.pushActionDetail;
  const foregroundSuppress = payload.foregroundSuppress || false;
  console.debug(
    "action: (",
    action,
    "), actionDetail: ",
    actionDetail,
    " foregroundSuppress: ",
    foregroundSuppress
  );
  if (!action || !actionDetail) {
    return;
  }
  // $rootScope.$broadcast("hard-refresh-homescreen"); TODO: Not needed b/c of redux?
  if (appOpen) {
    const user = { ...getState().users };
    let flag = onboardingFlags.NEW;
    if (user && user.flag) {
      flag = user.flag;
    }
    if (flag === onboardingFlags.FINISHED_ONBOARDING && !foregroundSuppress) {
      updatePopup(action, actionDetail, realData.message, dispatch);
    }
  } else {
    //MonitoringService.trackEvent("closed app", action, "push notification");
    const nextStep = await pushSwitch(action, actionDetail);
    history.push(nextStep.page || PAGE_URLS.HOME);
  }
};

const updatePopup = async (
  action: PushActionType,
  actionDetail: PushActionDetail,
  message: string,
  dispatch: Dispatch
) => {
  const nextStep = await pushSwitch(action, actionDetail);
  const alertData: alertDataType = {
    message: message,
    header: i18n.t("update"),
    alertType: AlertBoxType.YES_NO,
    positiveButton: {
      text: i18n.t("showMe"),
      action: AlertActionType.GO_TO_PAGE,
      payload: {
        page: nextStep.page!,
      },
    },
    negativeButton: {
      text: i18n.t("notNow"),
      action: AlertActionType.DO_NOTHING,
      role: "cancel",
      payload: {},
    },
  };
  dispatch(setAlertUiState(alertData));
  dispatch(setShowAlert(true));
};

//Function to do something based on what type of push it is
const pushSwitch = async (action: PushActionType, actionDetail: PushActionDetail) => {
  //TODO: Still needed with redux??
  // if (actionDetail.refreshAccounts !== undefined && actionDetail.refreshAccounts === true) {
  //   BankService.resetAccounts();
  // }
  let nextStep = {
    page: PAGE_URLS.HOME,
    data: {
      entity: actionDetail.entity,
      entityId: actionDetail.entityId,
      fromPush: true,
    },
  };
  switch (action) {
    case PushActionType.GENERIC:
      //TODO: AppRateService.promptForRating("Generic push");
      nextStep.page = `${actionDetail.state}`;
      return nextStep;
    case PushActionType.FEED:
      //TODO: AppRateService.promptForRating("Feed push");
      if (actionDetail.entity !== undefined && actionDetail.entityId !== undefined) {
        return feedDetailSwitch(actionDetail.entity, actionDetail.entityId, true);
      }
      return nextStep;
    case PushActionType.CHAT:
    case PushActionType.REFRESH_HOMESCREEN:
    default:
      return nextStep;
  }
};

export const checkToAskForPushPermissions =
  (skipAskPermission = false) =>
  async (dispatch: Dispatch, getState: any) => {
    return registerForPush(skipAskPermission, dispatch, getState);
  };
