import React, { useEffect, useState } from "react";
import { IonAlert, IonItem } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  BudgetParamsItem,
  BudgetSubcategoryObj,
  BudgetSuperCategory,
  BudgetType,
  Category,
  SubCategory,
  UpdateBudgetParams,
} from "../../../types";
import { getBudgetAnalyticsState } from "../../../redux/selectors";
import numeral from "numeral";
import { BudgetDetailItem } from "./BudgetDetailItem";
import { BUDGET_SHARED_TYPE, PAGE_URLS } from "../../../constants";
import { useHistory } from "react-router";
import { BudgetNotificationsModal } from "./BudgetNotificationsModal";
import { parseBudgetAnalyticsForDate } from "../../../redux/reducers/budgetAnalytics";
import { EditCategoriesModal } from "./EditCategoriesModal";
import { ActionSheet } from "../../shared/PageTemplate";
import { trackEvent } from "../../../vendors/monitoring";
import { updateBudget } from '../../../redux/actions/budgetAnalytics';
import { getTransactionLink } from '../../../utils/transUtils';

interface BudgetSubcategoryDetailItemProps {
  category: Category;
  effectiveDate: string;
  budgetType: BudgetType;
  superCategory: BudgetSuperCategory;
  subcategory: SubCategory;
  buttonIcon?: any;
}

export const BudgetSubcategoryDetailItem: React.FC<BudgetSubcategoryDetailItemProps> = ({
  category,
  effectiveDate,
  budgetType,
  superCategory,
  subcategory,
  buttonIcon,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { budgetsArray } = useSelector(getBudgetAnalyticsState);
  const [showSubcategoryEvent, setShowSubcategoryEvent] = useState<Event>();
  const [showEditBudgetAmountAlert, setShowEditBudgetAmountAlert] = useState<boolean>(false);
  const [budgetSubcategoryData, setBudgetSubcategoryData] = useState<BudgetSubcategoryObj>();
  const [showDeleteBudgetAlert, setShowDeleteBudgetAlert] = useState<boolean>(false);
  const [showEditBudgetNotificationsModal, setShowEditBudgetNotificationsModal] =
    useState<boolean>(false);
  const [showEditCategoriesModal, setShowEditCategoriesModal] = useState<boolean>(false);

  useEffect(() => {
    const subcategoryBudgetData = parseBudgetAnalyticsForDate({
      budgetsArray,
      effectiveDate,
      budgetType,
      superCategory,
      category,
      subcategoryLevel: true,
      subcategory,
    });
    if (!subcategoryBudgetData) {
      return;
    }

    setBudgetSubcategoryData(subcategoryBudgetData);
  }, [budgetsArray, effectiveDate, budgetType, superCategory, category, subcategory]);

  const handleEditBudget = (isDelete: boolean, amount: number = 0) => {
    dispatch(updateBudget({
      effectiveDate: effectiveDate,
      type: budgetType,
      isDelete,
      budgets: [
        {
          amount,
          superCategory,
          category: category.categoryShortName,
          subCategory: subcategory?.subCategory,
          _id: budgetSubcategoryData?._id,
        },
      ],
    }))
    if (isDelete) {
      trackEvent("click_deleteBudget", { category: "spendingBudget" });
    } else {
      trackEvent("save_editBudgetAmount", { category: "spendingBudget" });
    }
  };

  return (
    <>
      <IonItem
        className="BudgetDetailItem"
        lines="none"
        onClick={(e) => {
          trackEvent("view_categorization_budget", {
            category: "categorization",
            budgetCategory: subcategory.subCategory,
          });
          e.persist();
          setShowSubcategoryEvent(e as any);
        }}
        button
        data-testid={`budgetSubcat_${subcategory.subCategory}`}
        detail={false}
      >
        <BudgetDetailItem
          category={category}
          effectiveDate={effectiveDate}
          budgetType={budgetType}
          superCategory={superCategory}
          subcategory={subcategory}
          subcategoryLevel={true}
          buttonIcon={buttonIcon}
        />
      </IonItem>
      <ActionSheet
        hideButton
        openEvent={showSubcategoryEvent}
        onDidDismiss={setShowSubcategoryEvent as () => void}
        header={t("subcategory")}
        buttons={[
          {
            text: t("viewTransactions"),
            handler: () => {
              history.replace(getTransactionLink("advancedSearch", {
                subCategoryFilter: subcategory?.subCategory,
                budgetMonth: effectiveDate,
                isPrivate: budgetType === BUDGET_SHARED_TYPE.USER || "both",
              }));
            },
          },
          {
            text: t("editBudgetAmount"),
            handler: () => setShowEditBudgetAmountAlert(true),
          },
          {
            text: t("editSubcategory"),
            handler: () => {
              setShowEditCategoriesModal(true);
              trackEvent("view_editCategories", { category: "editCategories" });
            },
          },
          {
            text: t("editNotifications"),
            handler: () => setShowEditBudgetNotificationsModal(true),
          },
          {
            text: t("deleteBudget"),
            role: "destructive",
            handler: () => setShowDeleteBudgetAlert(true),
          },
          {
            text: t("cancel"),
            role: "cancel",
          },
        ]}
      />
      <IonAlert
        isOpen={showEditBudgetAmountAlert}
        onDidDismiss={() => setShowEditBudgetAmountAlert(false)}
        header={t("editBudgetAmount")}
        message={`${t("currentAmount")}: ${numeral(budgetSubcategoryData?.amount).format("$0,0")}`}
        inputs={[
          {
            name: "amount",
            type: "number",
            cssClass: "ion-text-center",
            placeholder: String(numeral(budgetSubcategoryData?.amount).format("$0,0")),
          },
        ]}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
          },
          {
            text: t("save"),
            handler: ({ amount }) => handleEditBudget(false, amount),
          },
        ]}
      />
      <IonAlert
        isOpen={showDeleteBudgetAlert}
        onDidDismiss={() => setShowDeleteBudgetAlert(false)}
        header={t("deleteBudget")}
        message={`${t("deleteBudgetAlertMsg")} ${subcategory?.displayName}?`}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
          },
          {
            text: t("delete"),
            role: "destructive",
            handler: () => handleEditBudget(true),
          },
        ]}
      />
      <BudgetNotificationsModal
        isOpen={showEditBudgetNotificationsModal}
        onClose={setShowEditBudgetNotificationsModal}
        budgetType={budgetType}
        subcategory={subcategory}
      />
      <EditCategoriesModal
        isOpen={showEditCategoriesModal}
        onClose={() => setShowEditCategoriesModal(false)}
      />
    </>
  );
};
