import React, { useState } from "react";
import { IonSearchbar } from "@ionic/react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import LinkInstitutionWrapper, {
  BankLinkFormat,
  InstitutionSearchResp,
} from "./LinkInstitutionWrapper";
import { LinkSearchModal } from "./LinkSearchModal";
import { useTranslation } from "react-i18next";
import "./Link.scss";
import { AccountSource } from "../../../redux/reducers/accounts";

interface ContainerProps { }

const Link: React.FC<ContainerProps> = () => {
  const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const quickBanks: InstitutionSearchResp[][] = [
    [
      {
        id: "ins_3",
        name: "Chase",
        source: AccountSource.PLAID,
      },
      {
        id: "ins_127989",
        name: "Bank of America",
        source: AccountSource.PLAID,
      },
    ],
    [
      {
        id: "ins_5",
        name: "Citi",
        source: AccountSource.PLAID,
      },
      {
        id: "h_ins_9",
        name: "Capital One",
        source: AccountSource.BANK_CAPONE,
        url: `${process.env.REACT_APP_API_URL}oauth/capone`,
      },
    ],
    [
      {
        id: "ins_10",
        name: "American Express",
        source: AccountSource.PLAID,
      },
      {
        id: "ins_4",
        name: "Wells Fargo",
        source: AccountSource.PLAID,
      },
    ],
  ];

  return (
    <>
      <IonSearchbar
        data-testid="partner_submit"
        onClick={() => setSearchModalOpen(true)}
        placeholder={t("search")}
      ></IonSearchbar>
      <IonGrid className="ion-no-padding">
        {quickBanks.map((bankRow) => {
          return (
            <IonRow key={bankRow[0].id}>
              {bankRow.map((bank) => {
                return (
                  <IonCol key={bank.id} className="half-padding">
                    <LinkInstitutionWrapper
                      onClose={() => { }}
                      format={BankLinkFormat.QUICKBANK}
                      institution={bank}
                      fromOnboarding
                    />
                  </IonCol>
                );
              })}
            </IonRow>
          );
        })}
      </IonGrid>
      <LinkSearchModal isOpen={searchModalOpen} onClose={() => setSearchModalOpen(false)} fromOnboarding />
    </>
  );
};

export default Link;
