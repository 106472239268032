import React, { useEffect, useState } from "react";
import {
  IonAlert,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonThumbnail,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { closeOutline } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryState } from "../../../redux/selectors";
import { Category, CategoryMapObj, SubCategory, SubcategoryObjForApi } from "../../../types";
import { cloneDeep } from "lodash";
import {
  getAllCategorizationData,
  refreshCategoryDependencies,
  setLoading,
  setToastMessage,
} from "../../../redux/actions";
import { CATEGORY_CONSTANTS } from "../../../constants";
import dayjs from "dayjs";
import { DeleteCategoryModal } from "./DeleteCategoryModal";
import {
  createSubcategoryObj,
  postCategoryMap,
  getCategoryObject,
} from "../../../utils/categorizationUtils";
import { DeleteSubcategoryModal } from "./DeleteSubcategoryModal";
import { AddSubcategoryModal } from "./AddSubcategoryModal";
import { ChangeCategoryModal } from "./ChangeCategoryModal";
import { AddCustomCategoryModal } from "./AddCustomCategoryModal";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import "./EditCategoriesModal.scss";
import { ActionSheet } from "../../shared/PageTemplate";
import { CategoryImg } from "../../shared/Miscellaneous/CategoryImg";
import { trackEvent } from "../../../vendors/monitoring";
import { hexToRGB } from "../../../utils/uiHelper";

const subcategoryConstants = {
  UNCATEGORIZED: "Uncategorized",
};

interface EditCategoriesModalProps {
  isOpen: boolean;
  onClose: any;
}

export const EditCategoriesModal: React.FC<EditCategoriesModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const { categoryData } = useSelector(getCategoryState);
  const [showAddSubcategoryModal, setShowAddSubcategoryModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);

  const scrollToBottom = () => {
    setTimeout(() => {
      setShowAddCategoryModal(false);
      // @ts-ignore
      document.querySelector("ion-content#EditCategoriesModalContent").scrollToBottom(500);
    }, 750);
  };

  return (
    <IonModal
      isOpen={isOpen}
      id="EditCategoriesModal"
      data-testid="EditCategoriesModal"
      onDidDismiss={onClose}
    >
      <ModalHeader
        title={t("editCategories")}
        startBtnIcon={closeOutline}
        startBtnOnClick={onClose}
      >
        <ActionSheet
          header={t("categories")}
          buttonProps={{ size: "medium", "data-testid": "modalEndBtn" }}
          buttons={[
            {
              text: t("addCategory"),
              handler: () => {
                setShowAddCategoryModal(true);
                trackEvent("view_customCategory", { category: "editCategories" });
              },
            },
            {
              text: t("addSubcategory"),
              handler: () => {
                setShowAddSubcategoryModal(true);
                trackEvent("view_addSubcategory", { category: "editCategories" });
              },
            },
            {
              text: t("cancel"),
              role: "cancel",
            },
          ]}
        />
      </ModalHeader>
      <IonContent id="EditCategoriesModalContent">
        <IonList className="ion-no-padding">
          {categoryData
            .filter((c: Category) => !c.isDeleted)
            .map((category: Category) => {
              return (
                <EditCategoriesItem
                  categoryShortName={category.categoryShortName}
                  key={category.categoryShortName}
                />
              );
            })}
        </IonList>
      </IonContent>
      <AddCustomCategoryModal
        isOpen={showAddCategoryModal}
        onClose={scrollToBottom}
      ></AddCustomCategoryModal>
      <AddSubcategoryModal
        isOpen={showAddSubcategoryModal}
        onClose={setShowAddSubcategoryModal}
      ></AddSubcategoryModal>
    </IonModal>
  );
};

interface EditCategoriesItemProps {
  categoryShortName: string;
}

export const EditCategoriesItem: React.FC<EditCategoriesItemProps> = ({ categoryShortName }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { categoryData, subCategoryData } = useSelector(getCategoryState);
  const [category, setCategory] = useState<Category>(categoryData[0]);
  const [showEditNameAlert, setShowEditNameAlert] = useState(false);
  const [showAddSubcategoryModal, setShowAddSubcategoryModal] = useState(false);
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
  const [actionSheetButtons, setActionSheetButtons] = useState<any[]>([]);

  useEffect(() => {
    setCategory(getCategoryObject(categoryShortName, categoryData)!);
    setActionSheetButtons(createActionSheetBtns(categoryShortName));

    function createActionSheetBtns(categoryShortName: string) {
      const buttons: Array<any> = [
        {
          text: t("editName"),
          handler: () => setShowEditNameAlert(true),
        },
      ];

      if (
        categoryShortName !== CATEGORY_CONSTANTS.GOALS &&
        categoryShortName !== CATEGORY_CONSTANTS.INCOME &&
        categoryShortName !== CATEGORY_CONSTANTS.IGNORE
      ) {
        buttons.push(
          {
            text: t("addSubcategory"),
            handler: () => {
              setShowAddSubcategoryModal(true);
              trackEvent("view_addSubcategory", { category: "addSubcategory" });
            },
          },
          {
            text: t("delete"),
            role: "destructive",
            handler: () => setShowDeleteCategoryModal(true),
          }
        );
      }
      buttons.push({
        text: t("cancel"),
        role: "cancel",
      });

      return buttons;
    }
  }, [categoryShortName, categoryData, t]);

  return (
    <div style={{ "--category-theme-color": hexToRGB(category.design.bgColor, 0.15) } as any}>
      <IonItem
        key={category.categoryShortName}
        className="category-header"
        lines="none"
      >
        <IonThumbnail slot="start">
          <CategoryImg category={category} />
        </IonThumbnail>
        <IonLabel className="font-md">{category.categoryFullName}</IonLabel>
        <ActionSheet
          header={t("categories")}
          buttonProps={{
            size: "medium",
            "data-testid": `EditCategoriesItem_${category.categoryShortName}`,
          }}
          buttons={actionSheetButtons}
        />
      </IonItem>

      <DeleteCategoryModal
        isOpen={showDeleteCategoryModal}
        onClose={() => setShowDeleteCategoryModal(false)}
        categoryShortNameToDelete={category.categoryShortName}
      />
      <AddSubcategoryModal
        isOpen={showAddSubcategoryModal}
        onClose={setShowAddSubcategoryModal}
        categoryShortName={category.categoryShortName}
      ></AddSubcategoryModal>
      <IonAlert
        isOpen={showEditNameAlert}
        onDidDismiss={() => setShowEditNameAlert(false)}
        header={t("editCategory")}
        message={t("editCategoryNameMsg")}
        inputs={[
          {
            name: "name",
            type: "text",
            cssClass: "ion-text-center",
            placeholder: t("category"),
            value: String(category.categoryFullName),
          },
        ]}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
          },
          {
            text: t("update"),
            handler: ({ name }) => {
              dispatch(setLoading(true));
              const categoryMap: CategoryMapObj = {
                isCustomMap: true,
                categories: cloneDeep(categoryData).map((c: Category) => {
                  if (c.categoryShortName === category.categoryShortName) {
                    c.categoryFullName = name;
                  }
                  return c;
                }),
                subCategories: createSubcategoryObj(subCategoryData),
              };
              postCategoryMap(categoryMap)
                .then(() => {
                  dispatch(getAllCategorizationData());
                })
                .then(() => dispatch(refreshCategoryDependencies()))
                .catch()
                .finally(() => {
                  dispatch(setLoading(false));
                });
            },
          },
        ]}
      />

      {subCategoryData
        .filter(
          (s: SubCategory) =>
            s.category === category.categoryShortName && !s.isDeleted && !s.isDeactivated
        )
        .map((subcategory: SubCategory) => {
          return (
            <EditSubcategoriesItem
              key={subcategory.subCategory}
              subCategoryId={subcategory.subCategory}
            />
          );
        })}
    </div>
  );
};

interface EditSubcategoriesItemProps {
  subCategoryId: string;
}

export const EditSubcategoriesItem: React.FC<EditSubcategoriesItemProps> = ({ subCategoryId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { subCategoryData, categoryData } = useSelector(getCategoryState);
  const [subcategory, setSubcategory] = useState<SubCategory>(subCategoryData[0]);
  const [showEditNameAlert, setShowEditNameAlert] = useState(false);
  const [showArchiveAlert, setShowArchiveAlert] = useState(false);
  const [showDeleteSubcategoryModal, setShowDeleteSubcategoryModal] = useState(false);
  const [showChangeCategoryModal, setShowChangeCategoryModal] = useState(false);
  const [actionSheetButtons, setActionSheetButtons] = useState<any[]>([]);

  useEffect(() => {
    setSubcategory(subCategoryData.find((s: SubCategory) => s.subCategory === subCategoryId)!);
    setActionSheetButtons(createActionSheetBtns(subCategoryId));
  }, [subCategoryId, subCategoryData]);

  const createActionSheetBtns = (subCategoryId: string) => {
    const subcat = subCategoryData.find((s) => s.subCategory === subCategoryId);

    const buttons = [];
    if (subcat?.category === CATEGORY_CONSTANTS.GOALS) {
      buttons.push({
        text: t("editName"),
        handler: () => setToastMessage(t("editGoalsInTab")),
      });
    } else {
      buttons.push({
        text: t("editName"),
        handler: () => setShowEditNameAlert(true),
      });
    }

    const catsToExclude = [
      CATEGORY_CONSTANTS.GOALS,
      CATEGORY_CONSTANTS.INCOME,
      CATEGORY_CONSTANTS.IGNORE,
    ];
    if (!catsToExclude.includes(subcat?.category || "")) {
      buttons.push({
        text: t("changeCategory"),
        handler: () => setShowChangeCategoryModal(true),
      });
    };
    if (
      // belongs to regular category
      !catsToExclude.includes(subcat?.category || "") ||
      // is custom ignore or income subcategory
      (
        [CATEGORY_CONSTANTS.IGNORE, CATEGORY_CONSTANTS.INCOME].includes(subcat?.category || "") &&
        subcat?.isCustom
      )
    ) {
      buttons.push(
        {
          text: t("archive"),
          handler: () => setShowArchiveAlert(true),
        },
        {
          text: t("delete"),
          role: "destructive",
          handler: () => setShowDeleteSubcategoryModal(true),
        });
    }

    buttons.push({
      text: t("cancel"),
      role: "cancel",
    });
    return buttons;
  };

  return (
    <>
      <IonItem key={subcategory.subCategory} lines="none">
        <IonLabel className="font-sm">{subcategory.displayName}</IonLabel>
        <ActionSheet
          header={t("subcategories")}
          buttons={actionSheetButtons}
          buttonProps={{
            size: "medium",
            "data-testid": `EditSubcategoriesItem_${subcategory.subCategory}`,
          }}
        />
      </IonItem>
      <DeleteSubcategoryModal
        isOpen={showDeleteSubcategoryModal}
        onClose={() => setShowDeleteSubcategoryModal(false)}
        subCategoryToDelete={subcategory.subCategory}
      />
      <ChangeCategoryModal
        isOpen={showChangeCategoryModal}
        onClose={() => setShowChangeCategoryModal(false)}
        subcategory={subcategory}
      />
      <IonAlert
        isOpen={showEditNameAlert}
        onDidDismiss={() => setShowEditNameAlert(false)}
        header={t("editSubcategory")}
        message={t("editSubcategoryNameMsg")}
        inputs={[
          {
            name: "name",
            type: "text",
            cssClass: "ion-text-center",
            placeholder: t("subcategory"),
            value: String(subcategory.displayName),
          },
        ]}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
          },
          {
            text: t("update"),
            handler: ({ name }) => {
              dispatch(setLoading(true));
              const subCategories: SubcategoryObjForApi = {};
              cloneDeep(subCategoryData).map((s: SubCategory) => {
                if (s.subCategory === subcategory.subCategory) {
                  s.displayName = name;
                }
                subCategories[s.subCategory] = s;
                return null;
              });
              const categoryMap: CategoryMapObj = {
                isCustomMap: true,
                categories: cloneDeep(categoryData),
                subCategories,
              };
              postCategoryMap(categoryMap)
                .then(() => {
                  dispatch(getAllCategorizationData());
                })
                .then(() => dispatch(refreshCategoryDependencies()))
                .catch()
                .finally(() => {
                  dispatch(setLoading(false));
                });
            },
          },
        ]}
      />
      <IonAlert
        isOpen={showArchiveAlert}
        onDidDismiss={() => setShowArchiveAlert(false)}
        header={`${t("archive")} ${subcategory.displayName}?`}
        message={`${t("archiveSubcategoryMsg1")} <br /><br /> ${t("archiveSubcategoryMsg2")}`}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
          },
          {
            text: t("archive"),
            role: "destructive",
            handler: () => {
              dispatch(setLoading(true));
              const subCategories: SubcategoryObjForApi = {};
              cloneDeep(subCategoryData).map((s: SubCategory) => {
                if (s.subCategory === subcategory.subCategory) {
                  s.isDeactivated = true;
                  s.dateDeactivated = dayjs().format("YYYY-MM-DD");
                  s.remappedTo = subcategoryConstants.UNCATEGORIZED;
                }
                subCategories[s.subCategory] = s;
                return null;
              });
              const categoryMap: CategoryMapObj = {
                isCustomMap: true,
                categories: cloneDeep(categoryData),
                subCategories,
              };
              postCategoryMap(categoryMap)
                .then(() => {
                  dispatch(getAllCategorizationData());
                })
                .then(() => dispatch(refreshCategoryDependencies()))
                .catch()
                .finally(() => {
                  dispatch(setLoading(false));
                  trackEvent("click_archiveSubcategory", { category: "editCategories" });
                });
            },
          },
        ]}
      />
    </>
  );
};
