import React, { useState } from "react";
import {
  IonButtons,
  IonIcon,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonHeader,
  IonToolbar,
  IonModal,
  IonContent,
  IonText,
} from "@ionic/react";
import { chevronDown, chevronUp, closeOutline } from "ionicons/icons";

import { Goal, GoalCategory } from "../../../types";
import { NEW_GOAL_TYPES } from "../../../constants";
import { useTranslation } from "react-i18next";
import { CategoryIcon } from "./CategoryIcon";
import { Field } from "../../shared/Field/Field";

type ChangeCategoryButtonProps = {
  goal: Goal;
  onChange: (goal: Partial<Goal>) => void;
  label?: string;
};

export const ChangeCategoryButton: React.FC<ChangeCategoryButtonProps> = (props) => {
  const { goal, onChange, label = "" } = props;
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <Field
        onClick={() => setModalOpen(true)}
        testId="changeCategoryButton"
        label={label}
        icon={modalOpen ? chevronUp : chevronDown}
      >
        <div className="d-flex ion-align-items-center mr-auto">
          <CategoryIcon size="large" category={goal.category} type="shadow" className="ion-no-margin inline-logo" />
          {goal.display}
        </div>
      </Field>
      <IonModal
        isOpen={modalOpen}
        cssClass="change-category-button"
        onDidDismiss={() => setModalOpen(false)}
      >
        <IonContent>
          <IonHeader>
            <IonToolbar>
              <IonButtons>
                <IonIcon
                  onClick={() => setModalOpen(false)}
                  icon={closeOutline}
                  size="large ion-padding"
                />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonText>
            <h1 className="ion-padding">{t("whatIsThisFor")}</h1>
          </IonText>
          <IonRadioGroup value={goal.category}>
            {NEW_GOAL_TYPES.map((option) => (
              <IonItem
                key={option.enum}
                lines="none"
                style={{ "--border-color": option.design.textColor, "--background-color": option.design.bgColor }}
                onClick={() => {
                  onChange({
                    category: option.enum as GoalCategory,
                    name: option.display,
                    display: option.display,
                  });
                  setModalOpen(false);
                }}
              >
                <CategoryIcon category={option.enum as GoalCategory} type="shadow" />
                {option.tagline}
                <IonRadio value={option.enum} slot="end" />
              </IonItem>
            ))}
          </IonRadioGroup>
        </IonContent>
      </IonModal>
    </>
  );
};
