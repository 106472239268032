import { IonInput, IonPicker, IonSelect, IonSelectOption } from "@ionic/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { option } from "yargs";

import { useViewport } from "../../../hooks/useViewport";

type PickerOption = {
  text: string;
  value: string;
  disabled?: boolean;
  selected?: boolean;
};

type PickerProps = {
  name: string;
  value: string;
  options: Array<PickerOption | string>;
  onChange: (value: string) => void;
  animated?: boolean;
};

export const Picker: React.FC<PickerProps> = (props) => {
  const { name, value, options, onChange, animated } = props;
  const { isDesktop } = useViewport();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const _options = (options as any[]).map((option: string | PickerOption) =>
    typeof option === "string"
      ? ({
          text: option,
          value: option,
          selected: option === value,
        } as PickerOption)
      : option
  ) as PickerOption[];

  if (isDesktop) {
    return (
      <IonSelect interface="popover" onIonChange={(e) => onChange(e.detail.value)} value={value}>
        {_options.map((option) => (
          <IonSelectOption key={option.value} value={option.value} disabled={option.disabled}>
            {option.text}
          </IonSelectOption>
        ))}
      </IonSelect>
    );
  }

  return (
    <>
      <IonInput value={value} readonly onClick={() => setOpen(true)} />
      <IonPicker
        animated={animated}
        isOpen={open}
        columns={[{ name, options: _options, selectedIndex: 0 }]}
        onDidDismiss={() => setOpen(false)}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
            handler: () => {
              setOpen(false);
            },
          },
          {
            text: t("confirm"),
            handler: (e) => {
              onChange(e[name].value);
              setOpen(false);
            },
          },
        ]}
      />
    </>
  );
};
