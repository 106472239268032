export const goalIcons = {
  beachBall: require("./icon-goals-beachBall.svg"),
  beachBall_shadow: require("./icon-goals-beachBall-shadow.svg"),
  beachBall_circle: require("./icon-goals-beachBall-circle.svg"),
  graduationCap: require("./icon-goals-graduationCap.svg"),
  graduationCap_shadow: require("./icon-goals-graduationCap-shadow.svg"),
  graduationCap_circle: require("./icon-goals-graduationCap-circle.svg"),
  house: require("./icon-goals-house.svg"),
  house_shadow: require("./icon-goals-house-shadow.svg"),
  house_circle: require("./icon-goals-house-circle.svg"),
  ladder: require("./icon-goals-ladder.svg"),
  ladder_shadow: require("./icon-goals-ladder-shadow.svg"),
  ladder_circle: require("./icon-goals-ladder-circle.svg"),
  target: require("./icon-goals-target.svg"),
  target_shadow: require("./icon-goals-target-shadow.svg"),
  target_circle: require("./icon-goals-target-circle.svg"),
  umbrella: require("./icon-goals-umbrella.svg"),
  umbrella_shadow: require("./icon-goals-umbrella-shadow.svg"),
  umbrella_circle: require("./icon-goals-umbrella-circle.svg"),
  martini: require("./icon-challenge-martini.svg"),
  martini_shadow: require("./icon-challenge-martini-shadow.svg"),
  martini_circle: require("./icon-challenge-martini-circle.svg"),
};
