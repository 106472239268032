const icons: any | undefined = {
  home: require("./home.svg"),
  trophyLoss: require("./trophy_loss.svg"),
  trophy: require("./trophy.svg"),
  lostIcon: require("./cancelClose.svg"),
  wonIcon: require("./checkmark.svg"),
  tiedIcon: require("./tiedCheckmark.svg"),
};

export default icons;
