import React from "react";
import { useSelector } from "react-redux";
import { IonList, IonModal, IonItem, IonAvatar, IonContent } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { getAccountsState } from "../../../redux/selectors";
import { AccountLogo } from "../../pages/AccountsTab/AccountLogo";
import { formatAccountNumber } from "../../../utils/formatUtils";
import { ModalHeader } from "../../shared/Header/ModalHeader";

type LinkAccountsListModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const LinkAccountsListModal: React.FC<LinkAccountsListModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { myAccounts } = useSelector(getAccountsState);

  return (
    <IonModal isOpen={isOpen} cssClass="link-search-modal" onDidDismiss={onClose}>
      <ModalHeader
        title={t("accountsAdded")}
        startBtnIcon={closeOutline}
        startBtnOnClick={onClose}
      />
      <IonContent>
        <IonList lines="none">
          {myAccounts.map((bank: any) => (
            <LinkedAccountItem key={bank.sourceInstitutionId} bank={bank}></LinkedAccountItem>
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

type LinkedAccountItemProps = {
  bank: any;
};

export const LinkedAccountItem: React.FC<LinkedAccountItemProps> = ({ bank }) => {
  return (
    <>
      <IonItem>
        <AccountLogo account={bank} />
        {bank.bank}
      </IonItem>
      {bank.subBanks.map((account: any) => {
        return (
          <IonItem key={account._id}>
            {account.accountName} {formatAccountNumber(account.accountNumber)}
          </IonItem>
        );
      })}
    </>
  );
};
