import React, { useState } from "react";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText,
  IonThumbnail,
  IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { closeOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import { useDispatch } from "../../../redux/hooks";
import { getCategoryState } from "../../../redux/selectors";
import {
  BudgetParamsItem,
  BudgetSuperCategory,
  BudgetType,
  Category,
  SubCategory,
  UpdateBudgetParams,
} from "../../../types";
import dayjs from "dayjs";
import { getAllCategorizationData, getBudget, setLoading } from "../../../redux/actions";
import { startCase } from "lodash";
import { post } from "../../../utils/apiOps";
import { createNewSubcategory } from "../../../utils/categorizationUtils";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import "./AddBudgetModal.scss";
import { CategoryImg } from "../../shared/Miscellaneous/CategoryImg";
import { trackEvent } from "../../../vendors/monitoring";
import { useResponsiveInterface } from '../../../hooks/useResponsiveInterface';
import { updateBudget } from '../../../redux/actions/budgetAnalytics';

interface AddBudgetModalProps {
  isOpen: boolean;
  onClose: any;
  category: Category;
  effectiveDate: string;
  budgetType: BudgetType;
  superCategory: BudgetSuperCategory;
}

export const AddBudgetModal: React.FC<AddBudgetModalProps> = ({
  isOpen,
  onClose,
  effectiveDate,
  category,
  budgetType,
  superCategory,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const _interface = useResponsiveInterface();
  const [newBudgetSubcategory, setNewBudgetSubcategory] = useState<SubCategory>();
  const [createCustomSubcategory, setCreateCustomSubcategory] = useState<boolean>(false);
  const [newBudgetCustomName, setNewBudgetCustomName] = useState<string>();
  const [newBudgetAmount, setNewBudgetAmount] = useState<number>(0);
  const { subCategoryData, categoryData } = useSelector(getCategoryState);

  const saveNewBudget = async () => {
    try {
      dispatch(setLoading(true));
      let newSubcat = null;
      if (createCustomSubcategory && newBudgetCustomName) {
        newSubcat = await createNewSubcategory({
          categoryData,
          subCategoryData,
          selectedCategory: category.categoryShortName,
          newSubcategoryName: newBudgetCustomName,
        });
      }
      dispatch(updateBudget({
        effectiveDate: effectiveDate,
        type: budgetType,
        isDelete: false,
        budgets: [
          {
            amount: newBudgetAmount,
            superCategory,
            category: category?.categoryShortName || "",
            subCategory: (newSubcat || newBudgetSubcategory)?.subCategory || "",
          },
        ],
      }))
      trackEvent("save_addBudget", { category: "spendingBudget" });
      dispatch(getAllCategorizationData());
      clearStateAndClose();
    } catch {
      dispatch(setLoading(false));
    }
  };

  const clearStateAndClose = () => {
    setCreateCustomSubcategory(false);
    setNewBudgetCustomName("");
    setNewBudgetAmount(0);
    onClose();
  };

  return (
    <IonModal isOpen={isOpen} id="AddBudgetModal" onDidDismiss={clearStateAndClose}>
      <ModalHeader
        title={t("addBudget")}
        startBtnIcon={closeOutline}
        startBtnOnClick={clearStateAndClose}
      />
      <IonContent>
        <IonList>
          <IonItem
            lines="none"
            className="font-sm"
            style={{
              background: category?.design.bgColor,
            }}
          >
            <IonThumbnail slot="start">
              <CategoryImg category={category} />
            </IonThumbnail>
            {t("addBudgetMsg1")} {category?.categoryFullName} {t("addBudgetMsg2")}
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{t("name")}</IonLabel>
            <IonSelect
              slot="end"
              value={newBudgetSubcategory}
              onIonChange={(e) => {
                setCreateCustomSubcategory(e.detail.value === "custom");
                setNewBudgetSubcategory(e.detail.value);
              }}
              interface={_interface}
              data-testid="addBudget_subcategory"
              placeholder={t("selectName")}
            >
              {subCategoryData
                .filter((s: SubCategory) => s.category === category?.categoryShortName && !s.isDeleted && !s.isDeactivated)
                .map((s: SubCategory, i: number) => {
                  return (
                    <IonSelectOption key={i} value={s}>
                      {s.displayName}
                    </IonSelectOption>
                  );
                })}
              <IonSelectOption value="custom">{t("createCustomSubcategory")}</IonSelectOption>
            </IonSelect>
          </IonItem>
          {createCustomSubcategory && (
            <IonItem lines="none">
              <IonLabel>{t("customName")}</IonLabel>
              <IonInput
                slot="end"
                className="ion-text-end"
                type="text"
                placeholder={startCase(t("enter"))}
                value={newBudgetCustomName}
                onIonChange={(e) => setNewBudgetCustomName(e.detail.value || "")}
                data-testid="addBudget_customName"
              />
            </IonItem>
          )}
          <IonItem lines="none">
            <IonLabel slot="start">{t("budgetedAmount")}</IonLabel>
            <IonText slot="end">$</IonText>
            <IonInput
              slot="end"
              placeholder="100"
              className="ion-text-end"
              type="number"
              value={newBudgetAmount}
              onIonChange={(e) => setNewBudgetAmount(Number(e.detail.value) || 0)}
              data-testid="addBudget_amount"
            />
          </IonItem>

          {!createCustomSubcategory && (
            <IonItem lines="none">
              <IonButton
                slot="start"
                color="secondary"
                size="small"
                fill="clear"
                data-testid="AddBudgetModal_addCustom"
                onClick={() => {
                  // @ts-ignore
                  setNewBudgetSubcategory("custom");
                  setCreateCustomSubcategory(true);
                }}
              >
                {t("createCustomSubcategory")}
              </IonButton>
            </IonItem>
          )}
        </IonList>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar no-border className="ion-text-center">
          <IonButton
            color="primary"
            data-testid="addBudget_save"
            size="large"
            className="ion-margin-start ion-margin-bottom"
            onClick={saveNewBudget}
            disabled={
              !newBudgetSubcategory ||
              (createCustomSubcategory && !newBudgetCustomName) ||
              !newBudgetAmount
            }
          >
            {t("save")}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
