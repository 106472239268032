import React, { ReactElement } from "react";
import { IonPage, IonContent } from "@ionic/react";

import { useViewport } from '../../../hooks/useViewport';

type PageTemplateProps = {
  header?: ReactElement<any>;
  footer?: ReactElement<any>;
  pageProps?: any;
  contentProps?: any;
}

export const PageTemplate: React.FC<PageTemplateProps> = props => {
  const {
    header,
    footer,
    pageProps = {},
    contentProps = {},
    children,
  } = props;
  const { isDesktop, isMobile } = useViewport();
  return <IonPage {...pageProps}>
    {isMobile && header}
    <IonContent {...contentProps}>
      {isMobile && children}
      {isDesktop && <div className="desktop-content-container">
        {header}
        {children}
        {footer}
      </div>}
    </IonContent>
    {isMobile && footer}
  </IonPage>
}