import React, { ReactElement } from "react";
import { IonHeader, IonIcon, IonToolbar, IonTitle, IonButtons, IonButton } from "@ionic/react";
import { firstlyLogo } from "../../../assets/images";
import constants from "../../../constants";
import "./ModalHeader.scss";

type ModalHeaderProps = {
  title?: string | ReactElement<any>;
  titleProps?: any;
  startBtnOnClick?: any;
  startBtnIcon?: string;
  endBtnOnClick?: any;
  endBtnIcon?: string;
  endBtnText?: string | ReactElement<any>;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  titleProps = {},
  startBtnOnClick,
  startBtnIcon,
  endBtnOnClick,
  endBtnIcon,
  endBtnText,
  children,
}) => {
  return (
    <IonHeader mode="ios">
      <IonToolbar className="modal-header">
        {startBtnIcon && (
          <IonButtons slot="start">
            <IonButton onClick={startBtnOnClick} data-testid="modalStartBtn">
              <IonIcon icon={startBtnIcon} slot="icon-only" size="large" />
            </IonButton>
          </IonButtons>
        )}
        {title ? (
          <IonTitle {...titleProps}>{title}</IonTitle>
        ) : (
          <IonTitle className="bold font-md">
            <img src={firstlyLogo} alt={constants.APP_NAME} />
          </IonTitle>
        )}

        {(endBtnIcon || endBtnText || children) && (
          <IonButtons slot="end">
            {children || <IonButton onClick={endBtnOnClick} data-testid="modalEndBtn">
              {endBtnIcon ? (
                <IonIcon icon={endBtnIcon} slot="icon-only" />
              ) : (
                endBtnText
              )}
            </IonButton>}
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};
