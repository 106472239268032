import React, { ReactElement } from "react";
import { IonButton, IonButtons, IonCol, IonGrid, IonRow, IonToolbar } from "@ionic/react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getAccountsState, getCategoryState, getTransactionState } from "../../../redux/selectors";
import numeral from "numeral";
import { CreditDebitData, SearchParam } from "../../../types";
import dayjs from "dayjs";
import "./TransactionAdvancedSearchResult.scss";
import { useTranslation } from "react-i18next";
import { transAccountNameHelper } from "../../../utils/transUtils";
import { useViewport } from "../../../hooks/useViewport";
import { NavButton } from "../../shared/PageTemplate";
import { getCatDisplayName, getSubcatDisplayName } from "../../../utils/categorizationUtils";
import { categoryState } from "../../../redux/reducers/category";
import { PAGE_URLS } from "../../../constants";

interface AdvancedSearchResult {
  handleAdvancedSearchToggleOpen?: () => void;
  resultOnly?: boolean;
}

export const TransactionAdvancedSearchResult: React.FC<AdvancedSearchResult> = ({
  handleAdvancedSearchToggleOpen,
  resultOnly = false,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useViewport();

  //redux state
  const { searchParams, searchResultsMeta } = useSelector(getTransactionState);
  const accountsState = useSelector(getAccountsState);
  const { categoryData, subCategoryData }: categoryState = useSelector(getCategoryState);

  //methods
  const handleCloseButtonClick = () => {
    history.replace(PAGE_URLS.TRANS_HOME);
  };

  const calcText = (searchParams: SearchParam) => {
    const params: string[][] = [];
    if (searchParams.keyword) params.push([t("keyword"), searchParams.keyword]);
    if (searchParams.merchName) params.push([t("keyword"), searchParams.merchName]);

    if (searchParams.accountIds) {
      const accountIds: string[] = JSON.parse(searchParams.accountIds);
      if (accountIds && accountIds.length > 0) {
        accountIds.map((id) => {
          params.push([t("account"), transAccountNameHelper(accountsState, id) || ""]);
        });
      }
    }

    if (searchParams.transactionFilter)
      params.push([t("sharedStatus"), searchParams.transactionFilter]);
    if (searchParams.categoryFilter) {
      // @ts-ignore
      params.push([t("category"), getCatDisplayName(searchParams.categoryFilter, categoryData)]);
    }
    if (searchParams.subCategoryFilter) {
      params.push([
        t("subcategory"),
        // @ts-ignore
        getSubcatDisplayName(searchParams.subCategoryFilter, subCategoryData),
      ]);
    }
    if (searchParams.baseType) params.push([t("type"), searchParams.baseType]);

    return params.map(([key, value]) => <Param key={key} field={key} value={value} />);
  };

  const calcRange = (searchParams: SearchParam) => {
    const format = (date: any) => dayjs(date).format("M/D/YY");
    let start = searchParams.startDate;
    let end: any = searchParams.endDate;
    if (searchParams.budgetMonth) {
      start = searchParams.budgetMonth;
      end = dayjs(searchParams.budgetMonth).endOf("month");
    }
    return <Param key="range" field={t("range")} value={`${format(start)} - ${format(end)}`} />;
  };

  const getTotalText = (searchResultsMeta: CreditDebitData) => {
    const total: ReactElement[] = [];
    if (searchResultsMeta.debit.count > 0) {
      total.push(
        <Param
          key="debits"
          field={t("debits")}
          value={`${numeral(searchResultsMeta.debit.total).format("$0,0")} (${searchResultsMeta.debit.count
            } ${t("txn")})`}
        />
      );
    }
    if (searchResultsMeta.credit.count > 0) {
      total.push(
        <Param
          key="credits"
          field={t("credits")}
          value={`${numeral(searchResultsMeta.credit.total).format("$0,0")} (${searchResultsMeta.credit.count
            } ${t("txn")})`}
        />
      );
    }
    return total;
  };

  return (
    <IonToolbar
      id="TransactionAdvancedSearchResult"
      data-testid="AdvancedSearchResult"
      className="font-sm"
    >
      {
        <IonGrid className="search-params">
          {!resultOnly && (
            <IonRow onClick={handleAdvancedSearchToggleOpen}>
              <IonCol>{calcText(searchParams)}</IonCol>
            </IonRow>
          )}

          {!resultOnly && (searchParams.startDate || searchParams.budgetMonth) && (
            <IonRow onClick={handleAdvancedSearchToggleOpen}>
              <IonCol>{calcRange(searchParams)}</IonCol>
            </IonRow>
          )}

          {(searchResultsMeta.debit.count > 0 || searchResultsMeta.credit.count > 0) && (
            <IonRow onClick={handleAdvancedSearchToggleOpen}>
              <IonCol>{getTotalText(searchResultsMeta)}</IonCol>
            </IonRow>
          )}

          {isMobile && !resultOnly && (
            <IonRow>
              <IonCol>
                <IonButton
                  color="secondary"
                  onClick={handleCloseButtonClick}
                  size="small"
                  fill="clear"
                  className="ion-no-margin ion-no-padding"
                >
                  {t("clearFilter")}
                </IonButton>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      }
      {isDesktop && (
        <IonButtons slot="end" className="fy-nav-buttons">
          <NavButton label={t("clearFilter")} onClick={handleCloseButtonClick} size="small" />
        </IonButtons>
      )}
    </IonToolbar>
  );
};

type ParamProps = {
  field: string;
  value: string;
};

const Param: React.FC<ParamProps> = (props) => {
  const { field, value } = props;
  return (
    <span className="ion-padding-end">
      <strong>{field}:</strong> {value}
    </span>
  );
};
