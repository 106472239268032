import React, { useState } from "react";
import {
  IonButton,
  IonCol,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { PAGE_URLS } from "../../../constants";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTransactionState } from "../../../redux/selectors";
import { Transaction, SplitTransactionType } from "../../../types/transactions";
import { transNameHelper } from "../../../utils/transUtils";
import Amount from "../../shared/Amount/Amount";
import { SplitTransactionItem } from "./SplitTransactionItem";
import { v4 as uuidv4 } from "uuid";
import { saveSplitTransaction } from "../../../utils/transactions";
import { useDispatch } from "react-redux";
import { setToastMessage, setLoading } from "../../../redux/actions";
import { NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { useNavigation } from "../../../utils/useNavigation";
import { trackEvent } from "../../../vendors/monitoring";

export const SplitTransaction: React.FC = () => {
  const { t } = useTranslation();
  const { transactionId } = useParams();
  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  type SplitTransactionData = SplitTransactionType[];

  //redux state
  const { transactionsList } = useSelector(getTransactionState);

  const transDetail =
    transactionsList.filter((transaction: Transaction) => {
      if (transaction.isChild) {
        return transaction.isChildOfTxnId === transactionId;
      } else {
        return transaction._id === transactionId;
      }
    })[0] || null;

  const defaultSplitTransaction: SplitTransactionType = {
    category: transDetail?.hhInfo[0].beefCategoryName,
    subCategory: transDetail?.hhInfo[0].honeyfiCategoryName,
    amount: "",
    readonly: false,
    uuid: uuidv4(),
  };

  //state
  const [splitTransactionData, setSplitTransactionData] = useState<SplitTransactionData>([
    defaultSplitTransaction,
  ]);

  const otherSplitTransactionTotalAmount = splitTransactionData.reduce(
    (accumulator, splitTransaction: SplitTransactionType) =>
      accumulator + Number(splitTransaction.amount),
    0
  );

  const lastSplitTransaction = {
    category: transDetail?.hhInfo[0].beefCategoryName,
    subCategory: transDetail?.hhInfo[0].honeyfiCategoryName,
    amount: String((transDetail?.amount.amount - otherSplitTransactionTotalAmount).toFixed(2)),
    readonly: true,
    uuid: "",
  };

  const allSplitTransactions = [...splitTransactionData, lastSplitTransaction];

  //methods
  const handleCatChange = (category: string, subcategory: string, uuid: string) => {
    if (uuid === "") {
      lastSplitTransaction.category = category;
      lastSplitTransaction.subCategory = subcategory;
    } else {
      let newSplitTransactionData = [...splitTransactionData];
      let targetedSplitTransaction = newSplitTransactionData.find(
        (transaction: SplitTransactionType) => transaction.uuid === uuid
      );
      if (targetedSplitTransaction) {
        targetedSplitTransaction.category = category;
        targetedSplitTransaction.subCategory = subcategory;
      }
      setSplitTransactionData(newSplitTransactionData);
    }
  };

  const handleInputChange = (changedAmount: string, uuid: string) => {
    if (Number(changedAmount) > Number(transDetail.amount.amount)) {
      dispatch(setToastMessage(t("splitAmountExceed")));
      return;
    }
    let newSplitTransactionData = [...splitTransactionData];
    let targetedTransaction = newSplitTransactionData.find(
      (transaction: SplitTransactionType) => transaction.uuid === uuid
    );
    if (targetedTransaction) targetedTransaction.amount = changedAmount;
    let lastSplitTransactionData = splitTransactionData.find(
      (transaction: SplitTransactionType) => transaction.uuid === ""
    );
    if (lastSplitTransactionData) {
      lastSplitTransactionData.amount = String(
        Number(lastSplitTransaction.amount) - Number(changedAmount)
      );
    }
    setSplitTransactionData(newSplitTransactionData);
  };

  const handleAdd = () => {
    let newSpliTransactionData = [...splitTransactionData];
    newSpliTransactionData.splice(newSpliTransactionData.length - 1, 0, defaultSplitTransaction);
    setSplitTransactionData(newSpliTransactionData);
  };

  const removeSplitTransaction = (uuid: string) => {
    setSplitTransactionData(splitTransactionData.filter((t) => t.uuid !== uuid));
  };

  const validateSplitTransactionData = () => {
    if (allSplitTransactions.length < 2) {
      dispatch(setToastMessage(t("mustSplitIntoAtLeastTwo")));
      return false;
    }
    if (otherSplitTransactionTotalAmount > Number(transDetail.amount.amount)) {
      dispatch(setToastMessage(t("splitAmountExceed")));
      return false;
    }
    for (let transaction of allSplitTransactions) {
      if (Number(transaction.amount) > Number(transDetail.amount.amount)) {
        dispatch(setToastMessage(t("splitAmountExceed")));
        return false;
      } else if (transaction.amount === "" || Number(transaction.amount) === 0) {
        dispatch(setToastMessage(t("splitAmountEmpty")));
        return false;
      } else if (Number(transaction.amount) < 0) {
        dispatch(setToastMessage("splitAmountNegative"));
        return false;
      }
    }
    return true;
  };

  const handleSave = () => {
    if (validateSplitTransactionData()) {
      let amounts = [];
      let beefCategoryNames = [];
      let honeyfiCategoryNames = [];
      for (let transaction of allSplitTransactions) {
        amounts.push(Number(transaction.amount));
        beefCategoryNames.push(transaction.category);
        honeyfiCategoryNames.push(transaction.subCategory);
      }
      let params = {
        transactionId: transDetail._id,
        amounts: amounts,
        beefCategoryNames: beefCategoryNames,
        honeyfiCategoryNames: honeyfiCategoryNames,
      };
      dispatch(setLoading(true));
      saveSplitTransaction(params, dispatch)
        .then(() => setSplitTransactionData([defaultSplitTransaction]))
        .catch()
        .finally(() => {
          dispatch(setLoading(false));
          navigate(PAGE_URLS.TRANS_HOME, "back");
          trackEvent("save_splitTrans", { category: "splitTrans" });
        });
    }
  };

  return transDetail ? (
    <PageTemplate
      header={<NavHeader title={t("splitTransactionTitle")} defaultHref={PAGE_URLS.TRANS_HOME} />}
      footer={
        <Section>
          <IonFooter className="ion-no-border">
            <IonToolbar no-border className="ion-text-center">
              <IonButton
                routerLink={PAGE_URLS.TRANS_HOME}
                onClick={() => setSplitTransactionData([defaultSplitTransaction])}
                fill="clear"
                routerDirection="back"
              >
                {t("cancel")}
              </IonButton>
              <IonButton onClick={handleSave} data-testid="splitTransactionSave">
                {t("save")}
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </Section>
      }
    >
      <Section>
        <IonGrid>
          <IonRow className="ion-padding">
            <h2 className="font-lg transaction-items-header">{t("originalTransaction")}</h2>
          </IonRow>
          <IonRow className="ion-margin">
            <IonCol>
              <h5 className="font-md transaction-items-header inter-font">
                {transNameHelper(transDetail)}
              </h5>
            </IonCol>
            <IonCol size="3.5" className="ion-text-end">
              <h6 className="font-md transaction-items-header">
                <Amount
                  baseType={transDetail.baseType}
                  amount={transDetail.amount.amount}
                  showCents={true}
                />
              </h6>
            </IonCol>
          </IonRow>
          <IonRow className="ion-padding">
            <h2 className="font-lg transaction-items-header">{t("splitInto")}</h2>
          </IonRow>
          <IonList lines="none">
            {allSplitTransactions.map((splitTransaction: SplitTransactionType, index: number) => {
              return (
                <SplitTransactionItem
                  splitTransaction={splitTransaction}
                  transactionDetail={transDetail}
                  handleCatChange={handleCatChange}
                  key={splitTransaction.uuid || "txn-split-new"}
                  uuid={splitTransaction.uuid}
                  removeSplitTransaction={removeSplitTransaction}
                  handleInputChange={handleInputChange}
                />
              );
            })}
            {splitTransactionData.length < 6 ? (
              <IonItem>
                <IonRow onClick={handleAdd}>
                  <IonIcon
                    icon={add}
                    className="ion-margin-end"
                    data-testid="addSplitTransaction"
                  />
                </IonRow>
              </IonItem>
            ) : null}
          </IonList>
        </IonGrid>
      </Section>
    </PageTemplate>
  ) : null;
};
