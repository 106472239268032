import React from "react";
import {
  IonBackButton,
  IonHeader,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
  IonButtons,
  useIonAlert,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PAGE_URLS } from "../../../constants";
import { post } from "../../../utils/apiOps";
import { logout, setLoading } from "../../../redux/actions";
import { AES } from "crypto-js";
import { useDispatch } from "../../../redux/hooks";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { trackEvent } from "../../../vendors/monitoring";

export const ManageUserAccount: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [present] = useIonAlert();

  const closeAccount = ({ pw }: { pw: string }) => {
    dispatch(setLoading(true));
    post(true, {
      endpoint: "/user/closeAccount",
      bodyData: {
        password: AES.encrypt(pw, process.env.REACT_APP_PWD_ENCRYPTION_KEY!).toString(),
      },
    })
      .then(() => {
        history.push(PAGE_URLS.LOGOUT);
        dispatch(logout());
      })
      .catch((err) => {
        console.debug(err);
      })
      .finally(() => {
        trackEvent("close_userAcct", { category: "settings" });
        dispatch(setLoading(false));
      });
  };

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={<NavHeader defaultHref={PAGE_URLS.MORE_SETTINGS} title={t("settings")} />}
    >
      <Section>
        <IonList lines="none">
          <IonItemDivider>
            <IonLabel>{t("account")}</IonLabel>
          </IonItemDivider>
          <IonItem
            onClick={() =>
              present({
                header: t("closeAccount"),
                message: t("closeAccountWarning"),
                inputs: [
                  {
                    name: "pw",
                    type: "password",
                    placeholder: "Password",
                  },
                ],
                buttons: [
                  {
                    text: t("cancel"),
                    role: "cancel",
                    cssClass: "secondary",
                  },
                  {
                    text: t("delete"),
                    role: "destructive",
                    handler: closeAccount,
                  },
                ],
              })
            }
            detail={true}
          >
            <IonLabel>{t("closeAccount")}</IonLabel>
          </IonItem>
        </IonList>
      </Section>
    </PageTemplate>
  );
};
