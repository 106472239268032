import Amplify from "@aws-amplify/core";
import { Hub, Logger, Auth } from "aws-amplify"; // eslint-disable-line
import { Plugins, Storage } from "@capacitor/core";
import store from "../redux/store";
import { logout, setToastMessage } from "../redux/actions";
import i18n from "i18next";

const { Device } = Plugins;
const AWS_AUTH_STORAGE_KEY = "AWS_AUTH_STORAGE";
let dataMemory: Record<string, string> = {};

/** @class */
class MyStorage {
  static syncPromise: Promise<void> | null = null;
  /**
   * This is used to set a specific item in storage
   */
  static setItem(key: string, value: string) {
    try {
      dataMemory[key] = value;
      Storage.set({
        key: AWS_AUTH_STORAGE_KEY,
        value: JSON.stringify(dataMemory),
      });
      return dataMemory[key];
    } catch (error) {
      console.error(`error AWS_AUTH_STORAGE: ${JSON.stringify(error)}`);
      throw error;
    }
  }

  /**
   * This is used to get a specific key from storage
   */
  static getItem(key: string) {
    try {
      return Object.prototype.hasOwnProperty.call(dataMemory, key) ? dataMemory[key] : undefined;
    } catch (error) {
      console.error(`error AWS_AUTH_STORAGE getItem: ${JSON.stringify(error)}`);
      throw error;
    }
  }

  /**
   * This is used to remove an item from storage
   */
  static removeItem(key: string) {
    delete dataMemory[key];
    Storage.set({
      key: AWS_AUTH_STORAGE_KEY,
      value: JSON.stringify(dataMemory),
    });
    return;
  }

  /**
   * This is used to clear the storage
   */
  static clear() {
    dataMemory = {};
    Storage.remove({
      key: AWS_AUTH_STORAGE_KEY,
    });
    return dataMemory;
  }

  /**
   * Will sync the MyStorage data from AsyncStorage to storageWindow MyStorage
   */

  static sync() {
    if (!MyStorage.syncPromise) {
      MyStorage.syncPromise = new Promise<void>((response, reject) => {
        Storage.get({ key: AWS_AUTH_STORAGE_KEY })
          .then((resp) => {
            if (resp.value) {
              dataMemory = JSON.parse(resp.value);
              console.debug(`sync3ync ${JSON.stringify(resp)}`);
            } else {
              dataMemory = {};
            }
            response();
          })
          .catch((error) => {
            console.error(`error AWS_AUTH_STORAGE sync: ${JSON.stringify(error)}`);
            reject(error);
          });
      });
    }
    return MyStorage.syncPromise;
  }
}

export const init = async () => {
  const deviceInfo = await Device.getInfo();
  const listener = (data: any) => {
    console.debug("Listener begin");
    console.debug(`data: ${JSON.stringify(data, null, 2)}`);
    switch (data.payload.event) {
      case "signIn":
        console.debug("cognito data signIn:");
        console.debug(JSON.stringify(data, null, 2));
        break;
      case "signUp":
        break;
      case "signOut":
        break;
      case "signIn_failure":
        console.error("cognito data signIn_failure" + JSON.stringify(data, null, 2));
        break;
      case "tokenRefresh":
        break;
      case "tokenRefresh_failure":
        store.dispatch(setToastMessage(i18n.t("unauthorized")));
        store.dispatch(logout());
        break;
      case "configured":
        Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
          .then((user) => {
            console.debug("configured");
            console.debug(user);
            Auth.currentSession(); //refresh the token if needed
          })
          .catch((err) => {
            console.warn(err);
          });
    }
  };
  Hub.listen("auth", listener);

  const config = {
    Auth: {
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
      mandatorySignIn: false,
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: ["email", "profile", "openid"],
        redirectSignIn: process.env.REACT_APP_COGNITO_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_COGNITO_SIGN_OUT,
        responseType: "token",
      },
    },
  };
  // if device, use storage object, if web, use cookies.
  if (deviceInfo.platform === "android" || deviceInfo.platform === "ios") {
    //@ts-ignore
    config.Auth.storage = MyStorage;
    config.Auth.userPoolWebClientId = process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
  } else {
    //@ts-ignore
    config.Auth.cookieStorage = {
      domain: process.env.REACT_APP_COGNITO_COOKIE_DOMAIN,
      path: "/",
      expires: 7,
      secure: process.env.REACT_APP_COGNITO_COOKIE_DOMAIN !== "localhost",
    };
  }
  console.debug(`AWS AMPLIFY CONFIG: ${JSON.stringify(config, null, 2)}`);
  Amplify.configure(config);
};
