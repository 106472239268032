import { SET_SHOW_ALERT, SET_ALERT_UI_STATE } from "../actionTypes";

export interface alertUiState {
  showAlert: boolean;
  alertData: alertDataType;
}

export interface alertDataType {
  header?: string;
  subHeader?: string;
  message: string;
  alertType: AlertBoxType;
  positiveButton: alertDataButton;
  negativeButton?: alertDataButton;
  alternativeButton?: alertDataButton;
}

export interface alertDataButton {
  text: string;
  action: AlertActionType;
  role?: string;
  payload: {
    page?: string;
    fnName?: string;
  };
}

export enum AlertActionType {
  GO_TO_PAGE = "GO_TO_PAGE",
  DO_NOTHING = "DO_NOTHING",
  SOFT_PUSH_PERMISSION = "SOFT_PUSH_PERMISSION",
  SOFT_PUSH_NEVER_AGAIN = "SOFT_PUSH_NEVER_AGAIN",
}

export enum AlertBoxType {
  OK_ONLY = "OK_ONLY",
  YES_NO = "YES_NO",
  YES_ALT_NO = "YES_ALT_NO",
}

const initialState: alertUiState = {
  showAlert: false,
  alertData: {
    header: "",
    subHeader: "",
    message: "",
    alertType: AlertBoxType.YES_NO,
    positiveButton: {
      text: "Yes",
      action: AlertActionType.GO_TO_PAGE,
      payload: {
        page: "/home",
      },
    },
    negativeButton: {
      text: "No",
      action: AlertActionType.DO_NOTHING,
      payload: {},
    },
  },
};

interface action {
  type: string;
  payload: alertUiState;
}

const alertUi = (state: alertUiState = initialState, action: action) => {
  switch (action.type) {
    case SET_SHOW_ALERT: {
      return {
        ...state,
        showAlert: action.payload.showAlert,
      };
    }

    case SET_ALERT_UI_STATE: {
      return {
        ...state,
        showAlert: action.payload.showAlert,
        alertData: action.payload.alertData,
      };
    }

    default: {
      return state;
    }
  }
};

export default alertUi;
