import { SAVE_NOTIFICATION_PREF_DATA, UPDATE_NOTIFICATION_PREF_DATA } from "../actionTypes";

export enum NOTIFICATION_TYPES {
  PARTNER_ACTIVITY = 1,
  CHAT = 2,
  ACCOUNT_ALERT = 3,
  NEW_TRANSACTIONS = 4,
  INSIGHT = 5,
  BILL_REMINDERS = 6,
  BALANCE = 7,
  BUDGET_ALERT = 8
};

export enum NOTIFICATION_BAL_TIMING {
  DAILY = "daily",
  WEEKLY = "weekly"
}

interface notificationPreferencesState {
  typesDisabled: Array<NOTIFICATION_TYPES>;
  timeOfDay: {
    startTime: number;
    endTime: number;
  },
  timeZone: string;
  balTiming: NOTIFICATION_BAL_TIMING;
  balanceFinAcct: Array<string>;
}

const initialState: notificationPreferencesState = {
  typesDisabled: [],
  timeOfDay: {
    startTime: 0,
    endTime: 23
  },
  timeZone: "America/New_York",
  balTiming: NOTIFICATION_BAL_TIMING.WEEKLY,
  balanceFinAcct: []
};

interface action {
  type: string;
  payload: notificationPreferencesState;
}

const notificationPreferences = (state: notificationPreferencesState = initialState, action: action) => {
  switch (action.type) {
    case SAVE_NOTIFICATION_PREF_DATA: {
      return action.payload;
    };
    case UPDATE_NOTIFICATION_PREF_DATA: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default: {
      return state;
    }
  }
};

export default notificationPreferences;
