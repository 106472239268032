export const RESET_APP = "RESET_APP";
export const RESET_APP_HARD = "RESET_APP_HARD";
export const GET_USER = "GET_USER";
export const SET_USER_PROPERTIES = "SET_USER_PROPERTIES";
export const SET_USER_FINANCIAL_PROFILE = "SET_USER_FINANCIAL_PROFILE";
export const CLEAR_USER_PROPERTIES = "CLEAR_USER_PROPERTIES";
export const SET_LOADING = "SET_LOADING";
export const SET_TOAST_MESSAGE = "SET_TOAST_MESSAGE";
export const SAVE_ACCOUNT_DATA = "SAVE_ACCOUNT_DATA";
export const SAVE_LAST_LINKED_ACCOUNT_DATA = "SAVE_LAST_LINKED_ACCOUNT_DATA";
export const ACCOUNT_DATA_LOADING = "ACCOUNT_DATA_LOADING";
export const UPDATE_PROPERTY_BALANCE = "UPDATE_PROPERTY_BALANCE";
export const SAVE_CATEGORIZATION_DATA = "SAVE_CATEGORIZATION_DATA";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_SUBCATEGORY = "GET_SUBCATEGORY";
export const CUSTOM_MAP = "CUSTOM_MAP";
export const ACCOUNT_TYPES_MAP = "ACCOUNT_TYPES_MAP";
export const GET_CATEGORY_ERRORMESSAGE = "GET_CATEGORY_ERRORMESSAGE";
export const SET_TRANSACTION_SEARCH_RESULTS = "SET_TRANSACTION_SEARCH_RESULTS";
export const SET_TRANSACTIONS_LOAD_START = "SET_TRANSACTIONS_LOAD_START";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const SAVE_VERSION_CHECK = "SAVE_VERSION_CHECK";
export const VERSION_CHECK_LOADING = "VERSION_CHECK_LOADING";
export const SET_ALL_VIEWER_GOALS = "SET_ALL_VIEWER_GOALS";
export const GOAL_DATA_LOADING = "GOAL_DATA_LOADING";
export const GOAL_DETAIL_LOADING = "GOAL_DETAIL_LOADING";
export const SET_GOAL_DETAIL = "SET_GOAL_DETAIL";
export const SET_VIEWER_ACCOUNT_INFO = "SET_VIEWER_ACCOUNT_INFO";
export const SET_HOUSEHOLD_MEMBER_DATA = "SET_HOUSEHOLD_MEMBER_DATA";
export const SET_HOUSEHOLD_PROFILE_DATA = "SET_HOUSEHOLD_PROFILE_DATA";
export const HOUSEHOLD_DATA_LOADING = "HOUSEHOLD_DATA_LOADING";
export const CLEAR_HOUSEHOLD_DATA = "CLEAR_HOUSEHOLD_DATA";
export const SET_USER_PROPERTIES_FROM_HOUSEHOLD_DATA = "SET_USER_PROPERTIES_FROM_HOUSEHOLD_DATA";
export const UPDATE_DEVICE_INFO = "UPDATE_DEVICE_INFO";
export const UPDATE_DEVICE_DETAILS = "UPDATE_DEVICE_DETAILS";
export const UPDATE_HOME_CARD_PREFERENCES = "UPDATE_HOME_CARD_PREFERENCES";
export const SET_LOCAL_PERMISSION_HISTORY = "SET_LOCAL_PERMISSION_HISTORY";
export const SET_ALERT_UI_STATE = "SET_ALERT_UI_STATE";
export const SET_SHOW_ALERT = "SET_SHOW_ALERT";
export const SAVE_NOTIFICATION_PREF_DATA = "SAVE_NOTIFICATION_PREF_DATA";
export const UPDATE_NOTIFICATION_PREF_DATA = "UPDATE_NOTIFICATION_PREF_DATA";
export const NOTIFICATION_PREF_DATA_LOADING = "NOTIFICATION_PREF_DATA_LOADING";
export const SET_SPENDING_FORECAST_SUMMARY = "SET_SPENDING_FORECAST_SUMMARY";
export const SET_SPENDING_FORECAST_LOADING = "SET_SPENDING_FORECAST_LOADING";
export const SET_BUDGET_ANALYTICS = "SET_BUDGET_ANALYTICS";
export const BUDGET_ANALYTICS_DATA_LOADING = "BUDGET_ANALYTICS_DATA_LOADING";
export const UPDATE_DATA_FOR_ARRAY_TRANSACTIONS = "UPDATE_DATA_FOR_ARRAY_TRANSACTIONS";
export const SPENDING_BY_PERSON_DATA = "SPENDING_BY_PERSON_DATA";
export const SET_BILLS = "SET_BILLS";
export const BILLS_LOADING = "BILLS_LOADING";
export const SET_FEEDS = "SET_FEEDS";
export const CLEAR_FEEDS = "CLEAR_FEEDS";
export const SET_BILLS_AUDIT_DATA = "SET_BILLS_AUDIT_DATA";
export const SET_BILLS_AUDIT_USERINFO = "SET_BILLS_AUDIT_USERINFO";
export const SET_BILLS_AUDIT_BILLERS_LIST = "SET_BILLS_AUDIT_BILLERS_LIST";
export const SET_BILLS_AUDIT_SELECTED_BILLER_INFO = "SET_BILLS_AUDIT_SELECTED_BILLER_INFO";
export const SET_BILLS_AUDIT_SHOW_GET_STARTED_MODAL = "SET_BILLS_AUDIT_SHOW_GET_STARTED_MODAL";
export const SET_TRANSACTION_RULES = "SET_TRANSACTION_RULES";
export const TRANSACTION_RULES_LOADING = "TRANSACTION_RULES_LOADING";
export const CREATE_NEW_CHALLENGE = "CREATE_NEW_CHALLENGE";
export const UPDATE_CHALLENGE_CATEGORY = "UPDATE_CHALLENGE_CATEGORY";
export const UPDATE_CHALLENGE_SUBCATEGORY = "UPDATE_CHALLENGE_SUBCATEGORY";
export const UPDATE_CHALLENGE_FREQUENCY = "UPDATE_CHALLENGE_FREQUENCY";
export const UPDATE_CHALLENGE_AMOUNT = "UPDATE_CHALLENGE_AMOUNT";
export const UPDATE_BILLS_AUDIT_SELECTED_BILLER_INFO = "UPDATE_BILLS_AUDIT_SELECTED_BILLER_INFO";
export const SET_SPENDING_CHALLENGE = "SET_SPENDING_CHALLENGE";
export const SET_BRANCH_DATA = "SET_BRANCH_DATA";
