/**
 * This modal allows a user to create a transaction rule.
 * Transaction rules are applied to incoming transactions that
 * use an "if this then that" format. For example, a rule could say
 * "all transactions from Target should be categorized as Everyday > Groceries".
 *
 * The triggers for rules are very flexible (e.g., all transactions from "Target",
 * only transactions from a specific account that are credits over $100).
 *
 * Transaction rules can set categorization (e.g., make this category/subcategory X/Y) or
 * shared status (e.g., make all transactions categorized as Groceries household / shared expenses).
 *
 * This modal is triggered to be shown by a toggle in the Recategorization and EditSharedStatus components.
 */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonInput,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { useDispatch } from "../../../redux/hooks";
import {
  getUsersState,
  getCategoryState,
  getTransactionState,
  getHouseholdDataState,
} from "../../../redux/selectors";
import { addOutline, closeOutline } from "ionicons/icons";
import { Transaction, TransactionSharedExpense } from "../../../types";
import { getCatDisplayName, getSubcatDisplayName } from "../../../utils/categorizationUtils";
import upperFirst from "lodash/upperFirst";
import cloneDeep from "lodash/cloneDeep";
import "./CreateRuleModal.scss";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import {
  RuleMatchTypes,
  ViewRulesType,
  ViewRulesFieldType,
  ViewRulesItemType,
  TransactionRuleTypes,
} from "../../../types/transactionRules";
import { useUserName } from "../../shared/User/useUserName";
import { categoryState } from "../../../redux/reducers/category";
import { useResponsiveInterface } from "../../../hooks/useResponsiveInterface";
import { createTransactionRule } from "../../../redux/actions/transactionRules";
import { matchTypeArrays, RULES_MATCH_TYPES } from "../../../utils/transactionRules";
import { trackEvent } from "../../../vendors/monitoring";

interface CreateRuleModalProps {
  isOpen: boolean;
  onClose: () => void;
  transactionId: string;
  transactionRuleType: TransactionRuleTypes;
}

export const CreateRuleModal: React.FC<CreateRuleModalProps> = ({
  isOpen,
  transactionId,
  onClose,
  transactionRuleType = TransactionRuleTypes.CATEGORY,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _interface = useResponsiveInterface("alert");

  const { categoryData, subCategoryData }: categoryState = useSelector(getCategoryState);
  const { partnerName } = useUserName();
  const { transactionsList } = useSelector(getTransactionState);

  const [selectedTransaction, setSelectedTransaction] = useState<Transaction>();
  const [isAccountOnly, setIsAccountOnly] = useState(false);
  const [viewRulesArray, setViewRulesArray] = useState<ViewRulesType[]>([
    {
      viewType: ViewRulesItemType.NAME,
      matchType: RULES_MATCH_TYPES.NAME.SIMILAR_TO,
      inputValue: "",
    },
  ]);

  useEffect(() => {
    const transaction = transactionsList.find((t) => t._id === transactionId);
    if (transaction) {
      setSelectedTransaction(transaction);
      const { description } = transaction;
      const { newViewRulesArray } = editViewRulesArr({
        ruleIndex: 0,
        field: ViewRulesFieldType.INPUT_VALUE,
        newValue: description.simple || description.original,
      });
      setViewRulesArray(newViewRulesArray);
    }
  }, [transactionsList, isOpen, transactionId]);

  interface EditViewRuleProps {
    ruleIndex: number;
    field: ViewRulesFieldType;
    newValue: string;
  }
  const editViewRule = ({ ruleIndex, field, newValue }: EditViewRuleProps) => {
    const { newViewRulesArray, newViewRulesItem } = editViewRulesArr({
      ruleIndex,
      field,
      newValue,
    });
    if (
      ruleIndex === 0 &&
      field === "matchType" &&
      newValue === RULES_MATCH_TYPES.NAME.SIMILAR_TO &&
      newViewRulesItem
    ) {
      setViewRulesArray([newViewRulesItem]);
    } else {
      setViewRulesArray(newViewRulesArray);
    }
  };

  const editViewRulesArr = ({ ruleIndex, field, newValue }: EditViewRuleProps) => {
    const newViewRulesArray = cloneDeep(viewRulesArray);
    const newViewRulesItem = newViewRulesArray[ruleIndex];
    if (newViewRulesItem) {
      newViewRulesItem[field] = newValue;
    }
    return { newViewRulesArray, newViewRulesItem };
  };

  const removeViewRule = (ruleIndex: number) => {
    const newArr = cloneDeep(viewRulesArray);
    newArr.splice(ruleIndex);
    setViewRulesArray(newArr);
  };

  const createRule = () => {
    trackEvent("save_createRule", { category: "categorization" });
    dispatch(
      createTransactionRule(selectedTransaction, transactionRuleType, isAccountOnly, viewRulesArray)
    ).then(onClose);
  };

  const getSharingText = (shareType: TransactionSharedExpense | undefined) => {
    switch (shareType) {
      case "0":
        return "household";
      case "1":
        return "me";
      case "3":
        return partnerName;
      case "4":
      default:
        return "private";
    }
  };

  return (
    <IonModal id="CreateRuleModal" isOpen={isOpen} onDidDismiss={onClose}>
      <ModalHeader title={t("createRule")} startBtnIcon={closeOutline} startBtnOnClick={onClose} />
      <IonContent>
        <IonGrid className="ion-margin-top">
          {transactionRuleType === TransactionRuleTypes.CATEGORY ? (
            <>
              <IonRow className="ion-text-center">
                <IonCol>{t("createRuleMsg")}</IonCol>
              </IonRow>
              <IonRow className="ion-text-center">
                <IonCol>
                  {selectedTransaction &&
                    t(
                      getCatDisplayName(
                        selectedTransaction?.hhInfo[0].beefCategoryName,
                        categoryData
                      ) || "category"
                    )}
                  {" | "}
                  {selectedTransaction &&
                    t(
                      getSubcatDisplayName(
                        selectedTransaction?.hhInfo[0].honeyfiCategoryName,
                        subCategoryData
                      ) || "subCategory"
                    )}
                </IonCol>
              </IonRow>
            </>
          ) : (
            <IonRow className="ion-text-center">
              <IonCol>
                {t("ruleTaggedTo")}
                {t(getSharingText(selectedTransaction?.hhInfo[0].sharedExpense))}.
              </IonCol>
            </IonRow>
          )}

          <IonRow className="ion-margin-top">
            <IonCol className="ion-margin-top">{t("createRuleAccount")}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton color="light" size="small">
                <IonSelect
                  slot="end"
                  interface={_interface}
                  interfaceOptions={{
                    header: t("createRuleAccount"),
                    cssClass: "rule-select-options",
                  }}
                  value={isAccountOnly}
                  onIonChange={({ detail }) => setIsAccountOnly(detail.value)}
                  data-testid="CreateRuleModal_account"
                >
                  <IonSelectOption value={false}>{t("allTransactions")}</IonSelectOption>
                  <IonSelectOption value={true}>{t("onlyTransactionsFromAccount")}</IonSelectOption>
                </IonSelect>
              </IonButton>
            </IonCol>
          </IonRow>

          {viewRulesArray.map((r, i) => {
            return (
              <ViewRulesItem
                key={i}
                ruleIndex={i}
                viewType={r.viewType}
                matchType={r.matchType}
                inputValue={r.inputValue}
                editViewRule={editViewRule}
                transactionRuleType={transactionRuleType}
                removeViewRule={removeViewRule}
              />
            );
          })}

          {viewRulesArray.find((r) => r.viewType === ViewRulesItemType.NAME)?.matchType !==
            RULES_MATCH_TYPES.NAME.SIMILAR_TO &&
            viewRulesArray.length < 4 && (
              <IonButton
                size="small"
                fill="clear"
                className="ion-margin-top"
                onClick={() =>
                  setViewRulesArray(
                    viewRulesArray.concat([
                      {
                        viewType: ViewRulesItemType.AMOUNT,
                        matchType: RULES_MATCH_TYPES.AMOUNT.GREATER_THAN,
                        inputValue: 0,
                      },
                    ])
                  )
                }
                data-testid="CreateRuleModal_addRule"
              >
                <IonIcon icon={addOutline} size="small" />
                {t("addRule")}
              </IonButton>
            )}
        </IonGrid>
      </IonContent>
      <IonFooter className="ion-no-border ion-text-center">
        <IonButton color="light" onClick={createRule} data-testid="CreateRuleModal_save">
          {t("save")}
        </IonButton>
      </IonFooter>
    </IonModal>
  );
};

interface ViewRulesItemProps {
  ruleIndex: number;
  viewType: ViewRulesItemType;
  matchType: RuleMatchTypes;
  inputValue: any;
  editViewRule: any;
  transactionRuleType: TransactionRuleTypes;
  removeViewRule: any;
}

export const ViewRulesItem: React.FC<ViewRulesItemProps> = ({
  ruleIndex,
  viewType,
  matchType,
  inputValue,
  editViewRule,
  transactionRuleType,
  removeViewRule,
}) => {
  const { t } = useTranslation();
  const _interface = useResponsiveInterface("alert");
  const { subCategoryData, categoryData } = useSelector(getCategoryState);
  const [matchTypeArray, setMatchTypeArray] = useState<any[]>([]);
  const [viewTypeArray, setViewTypeArray] = useState<ViewRulesItemType[]>([]);

  useEffect(() => {
    if (transactionRuleType === TransactionRuleTypes.CATEGORY) {
      setViewTypeArray([
        ViewRulesItemType.AMOUNT,
        ViewRulesItemType.BASE_TYPE,
        ViewRulesItemType.CATEGORY,
      ]);
    } else {
      setViewTypeArray([
        ViewRulesItemType.NAME,
        ViewRulesItemType.AMOUNT,
        ViewRulesItemType.BASE_TYPE,
        ViewRulesItemType.CATEGORY,
      ]);
    }
  }, [transactionRuleType]);

  useEffect(() => {
    if (viewType === ViewRulesItemType.CATEGORY) {
      const firstSubcat = subCategoryData.find((s) => !s.isDeactivated && !s.isDeleted);
      editViewRule(ruleIndex, "matchType", `${firstSubcat?.category}: ${firstSubcat?.subCategory}`);
    } else {
      editViewRule(ruleIndex, "matchType", matchTypeArrays[viewType][0]);
    }
  }, [viewType]);

  useEffect(() => {
    if (viewType !== ViewRulesItemType.CATEGORY) {
      // @ts-ignore
      setMatchTypeArray(matchTypeArrays[viewType]);
    }
  }, [viewType, subCategoryData]);

  return (
    <>
      <IonRow className="ion-margin-top ion-margin-start">
        <IonCol>{ruleIndex !== 0 && upperFirst(t("and"))}</IonCol>
      </IonRow>
      <IonRow className="ion-align-items-center">
        {ruleIndex !== 0 && (
          <IonCol size="2">
            <IonButton color="danger" fill="clear" onClick={() => removeViewRule(ruleIndex)}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonCol>
        )}

        <IonCol>
          <IonGrid>
            <IonRow className="ViewRulesItemProps">
              <IonCol>
                {t(
                  (transactionRuleType === TransactionRuleTypes.CATEGORY &&
                    ruleIndex === 0 &&
                    `ViewRulesItemMsg1_${viewType}_alt`) ||
                    ""
                )}
                {((transactionRuleType === TransactionRuleTypes.CATEGORY && ruleIndex !== 0) ||
                  transactionRuleType !== TransactionRuleTypes.CATEGORY) && (
                  <>
                    <IonButton size="small" color="light">
                      <IonSelect
                        slot="end"
                        value={viewType}
                        interface={_interface}
                        interfaceOptions={{
                          cssClass: "rule-select-options",
                        }}
                        onIonChange={({ detail }) =>
                          editViewRule({
                            ruleIndex,
                            field: ViewRulesFieldType.VIEW_TYPE,
                            newValue: detail.value,
                          })
                        }
                        data-testid={`CreateRuleModal_viewType_${ruleIndex}`}
                      >
                        {viewTypeArray.map((s, i) => {
                          return (
                            <IonSelectOption key={i} value={s}>
                              {t(`ViewRulesItemBtn_${s}`)}
                            </IonSelectOption>
                          );
                        })}
                      </IonSelect>
                    </IonButton>
                  </>
                )}
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonButton size="small" color="light">
                  <IonSelect
                    interface={_interface}
                    interfaceOptions={{
                      header:
                        (transactionRuleType === TransactionRuleTypes.CATEGORY &&
                          ruleIndex === 0 &&
                          t(`ViewRulesItemMsg1_${viewType}_alt`)) ||
                        t(`ViewRulesItemBtn_${viewType}`),
                      cssClass: "rule-select-options",
                    }}
                    slot="end"
                    value={matchType}
                    onIonChange={({ detail }) =>
                      editViewRule({ ruleIndex, field: "matchType", newValue: detail.value })
                    }
                    data-testid={`CreateRuleModal_matchType_${ruleIndex}`}
                  >
                    {viewType === ViewRulesItemType.CATEGORY
                      ? subCategoryData.map((s) => {
                          return (
                            <IonSelectOption
                              key={s.subCategory}
                              value={`${s.category}: ${s.subCategory}`}
                            >
                              {`${getCatDisplayName(s.category, categoryData)}: ${s.displayName}`}
                            </IonSelectOption>
                          );
                        })
                      : matchTypeArray.map((s) => {
                          return (
                            <IonSelectOption key={s} value={s}>
                              {t(s)}
                            </IonSelectOption>
                          );
                        })}
                  </IonSelect>
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow className="ViewRulesItemProps">
              <IonCol>
                {(viewType === ViewRulesItemType.NAME || viewType === ViewRulesItemType.AMOUNT) && (
                  <IonInput
                    className="ion-padding-horizontal"
                    placeholder="$0"
                    type="text"
                    value={inputValue}
                    onIonChange={({ detail }) =>
                      editViewRule({ ruleIndex, field: "inputValue", newValue: detail.value })
                    }
                    disabled={
                      viewType === ViewRulesItemType.NAME &&
                      matchType === RULES_MATCH_TYPES.NAME.SIMILAR_TO
                    }
                    data-testid={`CreateRuleModal_input_${ruleIndex}`}
                  />
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCol>
      </IonRow>
    </>
  );
};
