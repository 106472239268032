import * as types from "../actionTypes";
import { Dispatch } from "redux";
import { get, put, post, httpDelete } from "../../utils/apiOps";
import * as plaid from "../../vendors/plaid";
import { setLoading, setToastMessage } from "../actions/ui";
import { ACCOUNT_OWNER_TYPE, PRIVILEGE_TYPE_DATA_2 } from "../../constants";
import i18n from "i18next";
import { AccountCollectionItem } from "../reducers/accounts";

export type NewProperty = {
  name: string;
  amount: number | null;
  category: string;
  ownership: string;
  details: {
    otherType?: string; // 'other' only
    carDetails?: string; // 'car' only
    ownOrNot?: string; // 'car' only
    primary?: string; // 'house' only
  };
  isAsset: boolean;
  balVisibility: boolean;
  privilegeType?: number;
};

export const saveAccountData = (accountData: any) => {
  return {
    type: types.SAVE_ACCOUNT_DATA,
    payload: accountData,
  };
};

export const setAccountDataLoading = (status: boolean = true) => {
  return {
    type: types.ACCOUNT_DATA_LOADING,
    payload: status,
  };
};

export const updatePropertyBalance = (itemId: string, amount: number) => {
  return {
    type: types.UPDATE_PROPERTY_BALANCE,
    payload: { itemId, amount },
  };
};

export const getAccountData = () => async (dispatch: Dispatch<any>) => {
  dispatch(setAccountDataLoading(true));
  const [myAccounts, otherAccountCollection, properties] = await Promise.all([
    get(true, { endpoint: "user/view/allAccounts", params: { includeGoals: true } })
      .then((res) => res.data || [])
      .catch(() => []),
    get(true, { endpoint: "user/view/allAccountsP2", params: { includeGoals: true } })
      .then((res) => res.data || [])
      .catch(() => []),
    get(true, { endpoint: `/balSheetItems/list` })
      .then((res) => res.data)
      .catch(() => []),
  ]);
  const accountsInAlert = checkForAccountAlerts({ myAccounts, otherAccountCollection });
  dispatch(saveAccountData({ myAccounts, otherAccountCollection, properties, accountsInAlert }));
};

interface checkForAccountAlertsProps {
  myAccounts: AccountCollectionItem[];
  otherAccountCollection: AccountCollectionItem[];
}
export const checkForAccountAlerts = ({
  myAccounts,
  otherAccountCollection,
}: checkForAccountAlertsProps) => {
  const filterFn = (ins: AccountCollectionItem) => {
    return ins.isAlert && ins.subBanks.some((act) => act.isActive);
  };
  const mapFn = (inst: AccountCollectionItem) => inst.sourceInstitutionId;

  const myAccountsAlert = myAccounts.filter(filterFn).map(mapFn);
  const otherAlert = otherAccountCollection.filter(filterFn).map(mapFn);

  return { myAccounts: myAccountsAlert || [], otherAccountCollection: otherAlert || [] };
};

export const getMyAccountsData = () => async (dispatch: Dispatch<any>) => {
  dispatch(setAccountDataLoading(true));
  const [myAccounts] = await Promise.all([
    get(true, { endpoint: "user/view/allAccounts" })
      .then((res) => res.data || [])
      .catch(() => []),
  ]);
  dispatch(saveAccountData({ myAccounts }));
};

export const getAccountDataFromToken = (publicToken: string, skipBudgetRecalc: boolean) => async (
  dispatch: Dispatch<any>
) => {
  dispatch(setAccountDataLoading(true));
  const { data } = await plaid.postPlaidToken(publicToken, skipBudgetRecalc);
  await dispatch(getAccountData());
  dispatch(saveLastLinkedAccount(data));
};

export const saveLastLinkedAccount = (lastLinkedAccountData: any) => {
  return {
    type: types.SAVE_LAST_LINKED_ACCOUNT_DATA,
    payload: lastLinkedAccountData,
  };
};

export const savePropertyItem = (propertyData: NewProperty) => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  propertyData.privilegeType = Number(
    propertyData.ownership === ACCOUNT_OWNER_TYPE.JOINT
      ? PRIVILEGE_TYPE_DATA_2.SHARED
      : propertyData.balVisibility
      ? PRIVILEGE_TYPE_DATA_2.VISIBLE
      : PRIVILEGE_TYPE_DATA_2.JUSTME
  );
  await post(true, { endpoint: "/balSheetItems/add", bodyData: propertyData });
  await dispatch(getAccountData() as any);
  dispatch(setLoading(false));
};

export const deletePropertyItem = (itemIds: string[]) => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  await httpDelete(true, { endpoint: "/balSheetItems/delete", bodyData: { itemIds } });
  await dispatch(getAccountData() as any);
  dispatch(setLoading(false));
};

export enum EditBalanceType {
  MANUAL = "manual",
  PROPERTY = "property",
}

export const editAccountOrPropertyBalance = (
  itemOrAccountId: string,
  amount: number,
  type: EditBalanceType
) => async (dispatch: Dispatch) => {
  if (type === EditBalanceType.MANUAL) {
    await put(true, {
      endpoint: "/accounts/editBalance",
      bodyData: { accountId: itemOrAccountId, currentBalance: amount },
    });
  } else if (type === EditBalanceType.PROPERTY) {
    await put(true, {
      endpoint: "/balSheetItems/edit",
      bodyData: { itemId: itemOrAccountId, amount },
    });
  }
  await dispatch(updatePropertyBalance(itemOrAccountId, amount));
};

export const getLinkedAccountAfterRefresh = () => async (dispatch: Dispatch<any>) => {
  await dispatch(getAccountData());
};

export const saveManualAccount = (
  params: any,
  setSaveId: React.Dispatch<React.SetStateAction<string>>
) => async (dispatch: Dispatch<any>) => {
  dispatch(setLoading(true));
  const { data } = await post(true, {
    endpoint: "/accounts/addManual",
    bodyData: params,
  });
  if (data) {
    dispatch(setToastMessage(i18n.t("accountCreated")));
    dispatch(getLinkedAccountAfterRefresh());
    dispatch(setLoading(false));
    setSaveId(Object.keys(data)[0]);
  }
};
