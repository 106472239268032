import React from "react";
import { IonList, IonInput, IonGrid, IonRow, IonCol } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { OnboardingCopy } from "../../pages/Onboarding/Template";
import { formattedName } from "../../../utils/formatUtils";
import { Field } from "../Field/Field";
type Partner = {
  name: string;
  email: string;
};

type PartnerFormProps = {
  partner: Partner;
  onChange: (partner: Partner) => void;
};

export const PartnerForm: React.FC<PartnerFormProps> = (props) => {
  const { t } = useTranslation();
  const { partner, onChange } = props;

  return (
    <>
      <OnboardingCopy title={t("enterPartner_title")} />
      <IonList className="bounded">
        <Field label={t("partnerFirstName")}>
          <IonInput
            type="text"
            autocapitalize="words"
            value={formattedName(partner?.name)}
            data-testid="partnerName"
            onIonChange={(e) => onChange({ ...partner, name: e.detail.value! })}
          ></IonInput>
        </Field>

        <Field label={t("partnerEmail")}>
          <IonInput
            type="email"
            inputMode="email"
            value={partner.email}
            data-testid="partnerEmail"
            onIonChange={(e) => onChange({ ...partner, email: e.detail.value! })}
          ></IonInput>
        </Field>
      </IonList>
    </>
  );
};
