import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { IonAvatar, IonButton, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
import InputMask from "react-input-mask";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_URLS, US_STATES } from "../../../constants";
import { get, post } from "../../../utils/apiOps";

import {
  createBillsharkBillType,
  createBillsharkUserType,
  BillsAuditSteps,
} from "../../../types/billsAudit";
import { getBillsAuditDataState } from "../../../redux/selectors";
import {
  getBillsAuditData,
  setAlertUiState,
  setLoading,
  setShowAlert,
  updateSelectedBillInfo,
} from "../../../redux/actions";
import { AlertActionType, AlertBoxType, alertDataType } from "../../../redux/reducers/alertUi";
import dayjs from "dayjs";
import { billStatusTriage } from "../../../utils/billsAuditUtils";

import "./BillsAuditCreateUser.scss";
import { NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";

export const BillsAuditCreateUser: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { billsAuditData, selectedBillerInfo } = useSelector(getBillsAuditDataState);
  const [formData, setFormData] = useState<createBillsharkUserType>({
    email: "",
    legalFirstName: "",
    legalLastName: "",
    mobile: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    zipCode: "",
    dob: "",
  });

  const whyInfo = (field: "ssn4" | "dob") => {
    let alertData: alertDataType = {
      header: field === "ssn4" ? t("billsAuditSSN4") : t("billsAuditDOB"),
      message: t("billsAuditDOB-SSN4Why"),
      alertType: AlertBoxType.OK_ONLY,
      positiveButton: {
        text: t("gotIt"),
        action: AlertActionType.DO_NOTHING,
        payload: {},
        role: "cancel",
      },
    };
    dispatch(setAlertUiState(alertData));
    dispatch(setShowAlert(true));
  };

  const onFormUpdate = (
    field:
      | "email"
      | "legalFirstName"
      | "legalLastName"
      | "mobile"
      | "street1"
      | "street2"
      | "city"
      | "state"
      | "zipCode"
      | "dob",
    value: string
  ) => {
    let newFormData = { ...formData };
    newFormData[field] = value;
    setFormData(newFormData);
    console.debug(formData);
  };

  const getMaxDate = () => {
    return dayjs().subtract(18, "year").startOf("day").format("YYYY-MM-DD");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // to account for autofill https://stackoverflow.com/questions/44431416/auto-fill-in-react-doesnt-work
    let finalData: any = {};
    for (const key in formData) {
      if (key === "mobile") {
        finalData[key] = event.target[key].value.replace(/\D/g, "");
      } else {
        finalData[key] = event.target[key].value;
      }
    }
    console.debug(finalData);
    dispatch(setLoading(true));
    try {
      await post(true, {
        endpoint: "/billshark/createUser",
        bodyData: finalData,
      });
      await (dispatch(getBillsAuditData()) as any);
      dispatch(updateSelectedBillInfo(selectedBillerInfo.billsharkProviderId!));
      const triageResp = await billStatusTriage(selectedBillerInfo.billsharkProviderId!);
      if (triageResp.createBill) {
        const bodyFields: createBillsharkBillType = {
          offersRulesId: selectedBillerInfo.offersRulesId!,
          billsharkProviderId: selectedBillerInfo.billsharkProviderId!,
          currentBillAmount: selectedBillerInfo.currentBillAmount!,
          isPrivate: selectedBillerInfo.isPrivate!,
          honeyfiTransactionId: selectedBillerInfo.honeyfiTransactionId,
        };
        const { billsharkBillsId } = await post(true, {
          endpoint: "/billshark/createBill",
          bodyData: bodyFields,
        });
      }
      history.replace(triageResp.buttonLink!);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  // make sure they should be here
  const rightPageCheck = () => {
    billStatusTriage(selectedBillerInfo.billsharkProviderId!).then((triageResp) => {
      if (triageResp.baStep !== BillsAuditSteps.ENTER_USER) {
        history.replace(PAGE_URLS.BILLS_AUDIT_MAIN);
      }
    });
  };

  useEffect(() => {
    if (!billsAuditData) {
      (dispatch(getBillsAuditData()) as any).then(() => {
        rightPageCheck();
        dispatch(updateSelectedBillInfo(selectedBillerInfo.billsharkProviderId!));
      });
    } else {
      rightPageCheck();
      dispatch(updateSelectedBillInfo(selectedBillerInfo.billsharkProviderId!));
    }
  }, [dispatch]);

  return (
    <PageTemplate
      pageProps={{ id: "BillsAuditCreateUser" }}
      contentProps={{ fullscreen: true, className: "ion-padding" }}
      header={<NavHeader title={t("homeCards_billsAuditTitle")} />}
    >
      <Section>
        <IonItem lines="none">
          <h1 className="font-title-3">{t("billsAuditLetsTrim")}</h1>
          <IonAvatar id="BillerLogoAvatar" slot="end">
            <img src={selectedBillerInfo.billLogo} />
          </IonAvatar>
        </IonItem>
        <form
          data-testid="billAuditCreateUserForm"
          id="billAuditCreateUserForm"
          onSubmit={handleSubmit}
        >
          <IonItem lines="none">
            <IonLabel>{t("billsAuditLegalFirstName")}</IonLabel>
            <div className="basic-input-div">
              <input
                required
                name="legalFirstName"
                placeholder={t("billsAuditEnterLegalFirstName")}
                slot="end"
                autoComplete="given-name"
                value={formData.legalFirstName}
                onChange={(e) => {
                  onFormUpdate("legalFirstName", e.target.value!);
                }}
                className="basic-input"
                data-testid="ba_first_name"
              />
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{t("billsAuditLegalLastName")}</IonLabel>
            <div className="basic-input-div">
              <input
                required
                name="legalLastName"
                placeholder={t("billsAuditEnterLegalLastName")}
                slot="end"
                autoComplete="family-name"
                value={formData.legalLastName}
                onChange={(e) => {
                  onFormUpdate("legalLastName", e.target.value!);
                }}
                className="basic-input"
                data-testid="ba_last_name"
              />
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{t("billsAuditEmail")}</IonLabel>
            <div className="basic-input-div">
              <input
                type="email"
                required
                name="email"
                slot="end"
                placeholder={t("billsAuditEnterEmail")}
                autoComplete="email"
                inputMode="email"
                value={formData.email}
                onChange={(e) => {
                  onFormUpdate("email", e.target.value!);
                }}
                className="basic-input"
                data-testid="ba_email"
              />
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{t("billsAuditMobile")}</IonLabel>
            <div className="basic-input-div">
              <InputMask
                type="tel"
                required
                name="mobile"
                slot="end"
                mask="(999)999-9999"
                placeholder={t("billsAuditEnterMobile")}
                inputMode="tel"
                autoComplete="tel"
                value={formData.mobile}
                onChange={(e) => {
                  onFormUpdate("mobile", e.target.value!);
                }}
                className="basic-input"
                data-testid="ba_mobile"
              />
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{t("billsAuditAddressHeader")}</IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{t("billsAuditStreet1")}</IonLabel>
            <div className="basic-input-div">
              <input
                required
                name="street1"
                placeholder={t("billsAuditEnterStreet1")}
                slot="end"
                autoComplete="address-line1"
                value={formData.street1}
                onChange={(e) => {
                  onFormUpdate("street1", e.target.value!);
                }}
                className="basic-input"
                data-testid="ba_address_1"
              />
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{t("billsAuditStreet2")}</IonLabel>
            <div className="basic-input-div">
              <input
                name="street2"
                placeholder={t("billsAuditEnterStreet2")}
                slot="end"
                autoComplete="address-line2"
                value={formData.street2}
                onChange={(e) => {
                  onFormUpdate("street2", e.target.value!);
                }}
                className="basic-input"
                data-testid="ba_address_2"
              />
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{t("billsAuditCity")}</IonLabel>
            <div className="basic-input-div">
              <input
                required
                name="city"
                placeholder={t("billsAuditEnterCity")}
                slot="end"
                autoComplete="address-level2"
                value={formData.city}
                onChange={(e) => {
                  onFormUpdate("city", e.target.value!);
                }}
                className="basic-input"
                data-testid="ba_address_city"
              />
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{t("billsAuditState")}</IonLabel>
            <div className="basic-input-div">
              <select
                required
                id="BillsAuditStateInput"
                name="state"
                slot="end"
                value={formData.state}
                placeholder={t("billsAuditSelectState")}
                autoComplete="address-level1"
                onChange={(e) => {
                  onFormUpdate("state", e.target.value);
                }}
                className="select-full-width"
                data-testid="ba_address_state"
              >
                <option key="XX" value="" disabled>
                  {t("billsAuditSelectState")}
                </option>
                {US_STATES.map((state) => {
                  return (
                    <option value={state} key={state}>
                      {state}
                    </option>
                  );
                })}
              </select>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{t("billsAuditZipCode")}</IonLabel>
            <div className="basic-input-div">
              <InputMask
                required
                name="zipCode"
                mask="99999"
                placeholder={t("billsAuditEnterZipCode")}
                slot="end"
                autoComplete="postal-code"
                value={formData.zipCode}
                onChange={(e) => {
                  onFormUpdate("zipCode", e.target.value!);
                }}
                className="basic-input"
                data-testid="ba_address_zip"
              />
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonLabel id="BillsAuditDOB" onClick={() => whyInfo("dob")}>
              {t("billsAuditDOB")}
              <IonIcon icon={informationCircleOutline} />
            </IonLabel>
            <div className="basic-input-div">
              <input
                id="BillsAuditDOBInput"
                required
                type="date"
                max={getMaxDate()}
                min={"1900-01-01"}
                name="dob"
                autoComplete="dob"
                placeholder={t("billsAuditEnterDOB")}
                slot="end"
                value={formData.dob}
                onChange={(e) => {
                  onFormUpdate("dob", e.target.value);
                }}
                className="basic-input"
                data-testid="ba_dob"
              />
            </div>
          </IonItem>
          {/* <IonItem lines="none">
            <IonLabel id="BillsAuditDOB" onClick={() => whyInfo("dob")}>
              {t("billsAuditDOB")}
              <IonIcon icon={informationCircleOutline} />
            </IonLabel>
            <IonInput
              required
              maxlength={10}
              minlength={10}
              name="dob"
              placeholder={t("billsAuditEnterDOB")}
              slot="end"
              autocomplete="bday"
              value={formData.dob}
              onIonChange={(e) => {
                onFormUpdate("dob", e.detail.value!);
              }}
              data-testid="ba_dob"
            />
          </IonItem> */}
          <div id="NextButtonDiv">
            <IonButton id="NextButton" type="submit" data-testid="ba_user_submit">
              {t("next")}
            </IonButton>
            <p className="font-xs">{t("billsAuditTCs")}</p>
          </div>
        </form>
      </Section>
    </PageTemplate>
  );
};
