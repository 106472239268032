import capitalize from "lodash/capitalize";
import i18n from "i18next";

import { FinancialAccount, FundingInfo, GoalTransaction } from "../types";
import {
  GOAL_FREQUENCY_DISPLAYS,
  GOAL_FREQUENCY_TYPES,
  GOAL_FUNDING_ACCOUNT_STATUS,
  GOAL_TRIGGER_TYPES,
  PAGE_URLS,
  TRANSFER_STATUS,
  GOAL_STATUS,
  NEW_GOAL_TYPES,
} from "../constants";
import numeral from "numeral";
import dayjs from "dayjs";
import groupBy from "lodash/groupBy";
import { formatAccountNumber } from "./formatUtils";
import { Goal } from "../types/goals";
import { PRIVILEGE_TYPE_DATA_2 } from "../constants";

export const getContributionText = (fundingInfo: FundingInfo) => {
  if (!fundingInfo) return "";
  if (
    fundingInfo.frequency === GOAL_FREQUENCY_TYPES.ADHOC &&
    fundingInfo.triggerType === GOAL_TRIGGER_TYPES.PAYDAY
  ) {
    return capitalize(fundingInfo.triggerType);
  }
  return fundingInfo.frequencyDisplayName || capitalize(fundingInfo.frequency);
};

export const getContributionAmt = (fundingInfo: FundingInfo) => {
  if (!fundingInfo) return null;
  if (
    fundingInfo.frequency === GOAL_FREQUENCY_TYPES.ADHOC &&
    fundingInfo.triggerType === GOAL_TRIGGER_TYPES.PAYDAY
  ) {
    return `${numeral(fundingInfo.recurringDepositAmount).format("0%")} of income`;
  }
  return `${numeral(fundingInfo.recurringDepositAmount).format("$0,0")} / ${
    GOAL_FREQUENCY_DISPLAYS[fundingInfo.frequency]
  }`;
};

export const getContributionDescription = (fundingInfo: FundingInfo) => {
  if (!fundingInfo) return null;
  if (
    fundingInfo.frequency === GOAL_FREQUENCY_TYPES.ADHOC &&
    fundingInfo.triggerType === GOAL_TRIGGER_TYPES.PAYDAY
  ) {
    return i18n.t("paydayPercentage", {
      percentage: numeral(fundingInfo.recurringDepositAmount).format("0%"),
    });
  }
  return `${numeral(fundingInfo.recurringDepositAmount).format("$0,0")} ${
    GOAL_FREQUENCY_DISPLAYS[fundingInfo.frequency]
  }`;
};

export const getTransferStatus = (transaction: GoalTransaction) => {
  if (transaction.availableDate && dayjs(transaction.availableDate).isAfter(dayjs())) {
    return i18n.t("(pending)");
  }
  const status = TRANSFER_STATUS[transaction.transferStatus];
  let statusMsg = i18n.t(TRANSFER_STATUS[transaction.transferStatus] as any);
  if (status === TRANSFER_STATUS.DECLINED || status === TRANSFER_STATUS.SKIPPED) {
    statusMsg += i18n.t(transaction.coreProTransferErrorMessage as any) || ")";
  }
  return statusMsg;
};

export const getAvailableDate = (transaction: GoalTransaction) => {
  if (transaction.availableDate && dayjs(transaction.availableDate).isAfter(dayjs())) {
    return dayjs(transaction.availableDate).format("ddd, MMM D");
  }
};

export const getContributionLink = (goal: Goal, funding: FundingInfo, isUser: boolean) => {
  if (!isUser || goal.status !== GOAL_STATUS.ACTIVE) return;
  if (funding.status === GOAL_FUNDING_ACCOUNT_STATUS.PAUSED.toLowerCase()) {
    return PAGE_URLS.GOALS_DETAILS.replace(":goalId", goal.id);
  }
  return PAGE_URLS.GOALS_HOW_SAVE.replace(":goalId", goal.id);
};

export const getGoalDefs = (category: Goal["category"]) => {
  return NEW_GOAL_TYPES.find((type) => type.enum === category)!;
};

export const privilegeDisplayMap = {
  [PRIVILEGE_TYPE_DATA_2.SHARED]: "meAndPartner",
  [PRIVILEGE_TYPE_DATA_2.JUSTME]: "meKeepItPrivate",
  [PRIVILEGE_TYPE_DATA_2.VISIBLE]: "meButPartnerCanSee",
};

interface GoalGroup {
  status?: keyof typeof GOAL_STATUS;
  goals: Goal[];
}

export const getGoalStatusGroups = (goals: Goal[]): GoalGroup[] => {
  const groups = groupBy(goals, "status");
  const hasMultipleGroups = Object.keys(groups).length > 1;
  return [
    GOAL_STATUS.NEW,
    GOAL_STATUS.ACTIVE,
    GOAL_STATUS.PAUSED,
    GOAL_STATUS.CLOSED,
    GOAL_STATUS.COMPLETED,
    GOAL_STATUS.PFRAUD,
  ].reduce((all, status) => {
    if (groups[status]) {
      all.push({
        status: hasMultipleGroups ? status : undefined,
        goals: groups[status],
      } as GoalGroup);
    }
    return all;
  }, [] as GoalGroup[]);
};
