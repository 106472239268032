import { get, put, getCancelTokenSource } from "./apiOps";
import dayjs from "dayjs";
import flatMap from "lodash/flatMap";
import orderBy from "lodash/orderBy";
import { FunFact, SaveCardsType, FeedsParams, SpendingByPersonType, BudgetItem } from "../types";
import { Dispatch } from "redux";
import i18n from "i18next";
import { setHomeCardPreferences } from "../redux/actions";
import { FeedType } from "./feedUtils";
import { TransactionRuleTypes } from "../types/transactionRules";
import { CancelTokenSource } from "axios";
import { HOME_CARDS } from "../constants";

export const sortHomeCards = (firstWeekTime: boolean, cards: string[]) => {
  try {
    let newCards = cards;
    if (firstWeekTime) {
      newCards = cards.filter(
        (card) => card !== HOME_CARDS.FUN_FACT && card !== HOME_CARDS.WELCOME
      );
      newCards.unshift(HOME_CARDS.FUN_FACT);
      newCards.unshift(HOME_CARDS.WELCOME);
    }
    return newCards;
  } catch {
    return cards;
  }
};

export const getFunFact = async (
  setFunFactData: React.Dispatch<React.SetStateAction<FunFact[]>>
) => {
  const startDate = dayjs().subtract(90, "days").format("YYYY-MM-DD");
  try {
    const { data } = await get(true, {
      endpoint: "/analytics/funfact",
      params: { getMerchCt: 1, startDate },
    });
    setFunFactData(data);
  } catch (error) {}
};

let cancelTokenSource: CancelTokenSource;

export const saveCards = (saveCardParams: SaveCardsType, dispatch: Dispatch<any>) => {
  if (cancelTokenSource) {
    // Cancel previous request before making a new request
    console.debug("Request was canceled");
    cancelTokenSource.cancel();
  }

  const newCardPref = {
    cardArr: saveCardParams.newHomeCardsArray,
    cardObj: saveCardParams.cardPreferences.cardObj,
    cardAdded: saveCardParams.cardPreferences.cardAdded,
  };

  cancelTokenSource = getCancelTokenSource();
  put(true, {
    endpoint: "/devices/update",
    bodyData: {
      deviceUUID: saveCardParams.uuid,
      cardPreferences: newCardPref,
    },
    cancelToken: cancelTokenSource.token,
  })
    .then((success) => {
      // currently, apiOps will return undefined for a canceled request
      if (success) {
        dispatch(setHomeCardPreferences(newCardPref));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getFeedDesc = (feed: FeedsParams, userId: string, partnerFirstName: string) => {
  let displayFeed = "";
  if (feed.version === "v1") {
    if (feed.createdBy === userId) {
      displayFeed = i18n.t("you") + " " + feed.desc;
    } else {
      displayFeed = partnerFirstName + " " + feed.desc;
    }
    if (feed.feedType === FeedType.TRANSACTION_UNSHARED) {
      if (feed.taggedTo === userId) {
        if (feed.createdBy === userId) {
          displayFeed = displayFeed + " " + i18n.t("toYourself");
        } else {
          displayFeed = displayFeed + " " + i18n.t("toYou");
        }
      } else {
        displayFeed = displayFeed + " " + i18n.t("to").toLowerCase() + " " + partnerFirstName;
      }
    }
  } else {
    displayFeed = feed.desc!;
  }
  return displayFeed;
};

export const getFeedSubDes = (feed: FeedsParams, partnerName: string) => {
  let entityType = feed.entityDetails?.entityType;
  switch (entityType) {
    case "transactions":
      return `${feed.entityDetails.name} ${i18n.t("fromLower")} ${dayjs
        .utc(feed.entityDetails.date)
        .format("MMM. D, YYYY")}`;
    case "accounts":
      return `${feed.entityDetails.name}`;
    case "spendingChallenges":
      return `${feed.entityDetails.name}`;
    case "goals":
      if (feed.entityDetails.goalContributors) {
        if (feed.entityDetails.goalContributors === "single") {
          return `${feed.entityDetails.name} ${i18n.t("for")} ${i18n.t("youLower")}`;
        } else {
          return `${feed.entityDetails.name} ${i18n.t("for")} ${i18n.t("youLower")} ${i18n.t(
            "and"
          )} ${partnerName}`;
        }
      }
    case "rules":
      if (feed.entityDetails.ruleType) {
        if (feed.entityDetails.ruleType === TransactionRuleTypes.YOM) {
          return i18n.t("sharingRule");
        } else {
          return i18n.t("categoryRule");
        }
      }
    case "budgets":
      return `${dayjs.utc(feed.entityDetails.date).format("MMMM YYYY")} ${i18n.t("budget")} `;
    default:
      break;
  }
};

export const getFeedDetailSubDes = (feed: FeedsParams) => {
  return `${dayjs(feed.createdOn).format("h:mm A")} | ${i18n.t("view")} ${
    feed.entityDetails?.entityType || ""
  }`;
};

export const getPersonAvatar = (personData: SpendingByPersonType, userId: string) => {
  let id = personData.id;
  let tempArr = id.split("-");
  if (tempArr[0] === "user") {
    if (tempArr[1] === userId) {
      return 1;
    } else {
      return 2;
    }
  } else if (tempArr[0] === "hh") {
    return 0;
  } else {
    return 3;
  }
};

export const getMaxSpendingAmount = (arr: SpendingByPersonType[]) => {
  return Math.max(...arr.map((ele) => ele.Expenses.amountActual), 0);
};

export const getTopSpendingSubCategories = (data?: BudgetItem) => {
  const subCategories = flatMap(data?.hh?.budget?.Expenses?.subs, (cat) => Object.values(cat.subs));
  return orderBy(subCategories, "amountActual", "desc");
};
