import React, { useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ResponsiveLine, LineSvgProps } from '@nivo/line'
import numeral from 'numeral';
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy';
import merge from 'lodash/merge';
import dayjs from 'dayjs';

import './SpendingForecastChart.scss'

import { calculateHoverPosition, dedupeForecastPoint, getBaseline } from '../../../utils/spendingForecastUtils';

const MemoizedResponsiveLine = React.memo(ResponsiveLine)

type ForecastChartProps = {
  data: Array<{
    id: string;
    data: Array<{ x: string, y: number }>;
  }>;
  hoveredDay?: string;
  onDayHovered?: (month: string) => void;
}

export const ForecastChart: React.FC<ForecastChartProps> = props => {
  const { data, hoveredDay, onDayHovered } = props;
  const ref = useRef<any>();

  // Make sure the baseline accounts for negative spending:
  const staticProps = useRef<typeof commonProps>(merge({}, commonProps))
  staticProps.current.yScale.min = getBaseline(data);

  const handleMouseOver = (e: any) => {
    if (!onDayHovered) return;
    const newlyHoveredDay = calculateHoverPosition(data, e.target.dataset.index);
    if (newlyHoveredDay && newlyHoveredDay !== hoveredDay) {
      onDayHovered(newlyHoveredDay);
    }
  }

  return <div className="spending-forecast-chart" onPointerOver={handleMouseOver} ref={ref}>
    <div id="tooltip-root" />
    <MemoizedResponsiveLine
      data={data}
      margin={staticProps.current.margin}
      yScale={staticProps.current.yScale}
      curve="catmullRom"
      lineWidth={4}
      enableArea
      areaOpacity={0.1}
      enableSlices={onDayHovered ? 'x' : undefined}
      enableGridX={false}
      enableGridY={false}
      colors="#1C7FFF"
      isInteractive={!!onDayHovered}
      axisLeft={null}
      axisBottom={staticProps.current.axisBottom}
      layers={staticProps.current.layers as any}
      sliceTooltip={() => null}
    />
  </div>
}

// Below are some custom SVG generators (to match design)

const styleById: any = {
  actual: {
    strokeWidth: 3,
  },
  forecast: {
    strokeDasharray: '5, 3',
    strokeWidth: 2,
  },
  actualIncome: {
    strokeWidth: 3,
  },
  budgetedIncome: {
    strokeDasharray: '5, 3',
    strokeWidth: 2,
  },
  budgetedSpend: {
    strokeDasharray: '5, 3',
    strokeWidth: 2,
  },
  prevMonth: {
    strokeWidth: 3,
  }
}

export const colorById: any = {
  actual: "#1C7FFF",
  forecast: "#C4C4C4",
  actualIncome: "#00C264",
  budgetedIncome: "#00C264",
  budgetedSpend: "#181818",
  prevMonth: "#181818",
}

// for generating either a solid (for actual) or dashed (for forecast) line
const Line: React.FC<Readonly<LineSvgProps>> = props => {
  const { series, lineGenerator, xScale, yScale } = props as any
  return series.map((datum: any) => {
    const { id, data } = datum
    return < path
      key={id}
      d={
        lineGenerator(
          data.map((d: any) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          }))
        )}
      fill="none"
      stroke={colorById[id]}
      style={styleById[id]}
    />
  })
}

// So that we only have a single point at the current date
const Point: React.FC<any> = (props) => {
  const { points } = props
  const today = dayjs().format("YYYY-MM-DD");
  const currentPoint = points.find((point: any) => point?.data?.x === today);
  if (!currentPoint) return <g />;

  return <g>
    <circle cx={currentPoint.x} cy={currentPoint.y} fill="#fff" r={7} strokeWidth={3} stroke="#1C7FFF" />
  </g>
}

// To only show ticks for every 5th day
const Tick: React.FC<string> = (props) => {
  const day = Number(dayjs(props).format("D"));
  return <tspan>{day === 1 || day % 5 === 0 ? day : ""}</tspan>
}

const useMonthSync = ({ currentSlice, slices, setCurrentSlice }: any) => {
  const sliceDate = currentSlice?.points[0]?.data?.x;
  const displayedDate = slices && slices[0]?.points[0]?.data?.x;

  useEffect(() => {
    if (!sliceDate || !displayedDate || dayjs(sliceDate).isSame(displayedDate, "month")) return;
    const selectedDay = sliceDate.slice(8);
    const targetDay = Math.min(slices.length, selectedDay) // since current month may have less days then prev
    const newSlice = slices.find((s: any) => targetDay == s?.points[0]?.data?.x?.slice(8));
    setCurrentSlice(newSlice || null);
  }, [dayjs(displayedDate).month()]);
}

// Custom slices (to handle mouse hover)
const Slices: React.FC<any> = props => {
  useMonthSync(props);

  return <g>
    {props.slices.map((slice: any, index: number) => (
      <rect
        key={slice.x0}
        x={slice.x0}
        y={slice.y0}
        width={slice.width}
        height={slice.height}
        strokeWidth={0}
        strokeOpacity={0.75}
        data-index={index}
        fillOpacity={0}
        onPointerEnter={() => props.setCurrentSlice(slice)}
      />
    ))}
  </g>
}

const SliceMarker: React.FC<any> = props => {
  const { currentSlice } = props
  if (!currentSlice) return <g />;
  const points = dedupeForecastPoint(currentSlice.points);
  const min = minBy(points, 'y') as any;
  const max = maxBy(points, 'y') as any;
  const onlyOne = min.y >= max.y;

  return <g>
    {onlyOne ? null : <rect
      x={min.x - 1}
      y={min.y - 1}
      width={2}
      height={max.y - min.y}
      stroke="#5C5C5C"
      fill="#ffffff"
      strokeWidth={2}
    />}
    <circle cx={min.x} cy={min.y} fill="#fff" r={7} strokeWidth={3} stroke={colorById[min.serieId]} />
    {onlyOne ? null : <circle cx={max.x} cy={max.y} fill="#fff" r={7} strokeWidth={3} stroke={colorById[max.serieId]} />}
  </g>
}

const getTooltipPosition = (points: any, rightEdge: number) => {
  const min = minBy(points, 'y') as any;
  const { x, y } = min
  const [PADDING, CHAR_WIDTH, BASE_HEIGHT, LINE_HEIGHT] = [28, 5.5, 25, 20];
  const maxChars = Math.max(...points.map((p: any) => numeral(p.data.y).format("$0,0").length));
  const tooltipWidth = PADDING + (CHAR_WIDTH * maxChars);
  const halfWidth = tooltipWidth / 2;
  const maxLeft = rightEdge - tooltipWidth;
  const minLeft = 0;
  const left = Math.max(minLeft, Math.min(x - halfWidth, maxLeft));
  const top = y - (BASE_HEIGHT + (LINE_HEIGHT * points.length));
  return { top, left };
}

// Custom tooltip that shows values next to the pointer
const SliceTooltip: React.FC<any> = props => {
  const { currentSlice, innerWidth } = props
  if (!currentSlice) return <g />;
  const points = dedupeForecastPoint(currentSlice.points);
  return ReactDOM.createPortal(
    <div className="slice-tooltip" style={getTooltipPosition(points, innerWidth)}>
      <span className="tooltip-date">{dayjs(points[0].data.x).format('MMM D')}</span>
      <div className="tooltip-box">
        {points.sort((a: any, b: any) => a.y - b.y).map((p: any) => <div
          key={p.id}
          className="amount"
          style={{ color: colorById[p.serieId] }}
        >
          {numeral(p.data.y).format("$0,0")}
        </div>)}
      </div>
    </div>, document.getElementById("tooltip-root")!);
}

// These are so that we get the same object identity to aid in memoization
const commonProps = {
  yScale: {
    min: 0,
    type: "linear" as any,
  },
  axisBottom: {
    tickSize: 0,
    tickPadding: -24,
    format: Tick,
  },
  margin: { top: 20, bottom: 55 },
  layers: ['axes', 'areas', Slices, Line, Point, SliceMarker, SliceTooltip],
};