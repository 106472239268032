import {
  BillsAuditBillsharkStatus,
  billsAuditDataType,
  billsAuditGetStartedButton,
  BillsAuditSteps,
} from "../types/billsAudit";
import i18n from "i18next";
import store from "../redux/store";
import { PAGE_URLS } from "../constants";

export const billStatusTriage = async (billProviderId: billsAuditDataType[0]["_id"]) => {
  const { billsAuditData, userInfo } = store.getState().billsAudit;
  const selectedBiller = billsAuditData?.find(
    (bill) => bill.billsharkProviderId === billProviderId
  );
  let buttonDetails: billsAuditGetStartedButton = {
    buttonText: i18n.t("billsAuditBillerNotFound"),
    buttonLink: PAGE_URLS.BILLS_AUDIT_UPLOAD_DOCS,
    baStep: BillsAuditSteps.UPLOAD_DOCS,
    createBill: true,
  };

  if (!billProviderId) {
    //user got here most likely by typing in the url direclty. shouldn't be empty billProviderId
    buttonDetails.buttonLink = PAGE_URLS.BILLS_AUDIT_MAIN;
    buttonDetails.baStep = BillsAuditSteps.NEW;
    return buttonDetails;
  }

  if (!selectedBiller?.billsharkInfo) {
    // no billshark bill started
    buttonDetails.buttonText = i18n.t("billsAuditHomeGetStarted");
  } else {
    // we've started a billshark bill
    buttonDetails.buttonText = i18n.t("billsAuditContinue");
    buttonDetails.createBill = false;
    switch (selectedBiller.billsharkInfo.status as BillsAuditBillsharkStatus) {
      case BillsAuditBillsharkStatus.NEW:
      case BillsAuditBillsharkStatus.WAITING_DOCS:
        buttonDetails.buttonText = i18n.t("billsAuditContinue");
        buttonDetails.buttonLink = PAGE_URLS.BILLS_AUDIT_UPLOAD_DOCS;
        buttonDetails.baStep = BillsAuditSteps.UPLOAD_DOCS;
        break;

      case BillsAuditBillsharkStatus.SUBMITTED:
      case BillsAuditBillsharkStatus.COMPLETED:
        buttonDetails.buttonText = selectedBiller.billsharkInfo.billsharkStatus!;
        buttonDetails.baStep = BillsAuditSteps.COMPLETE;

      default:
        break;
    }
  }

  // no payment details
  if (!userInfo?.paymentInfoSubmitted) {
    buttonDetails.buttonText = i18n.t("billsAuditContinue");
    buttonDetails.buttonLink = PAGE_URLS.BILLS_AUDIT_USER_PAYMENT_DETAILS;
    buttonDetails.baStep = BillsAuditSteps.ENTER_PAYMENT;
  }

  // no user info
  if (!userInfo?.kycInfoSubmitted) {
    buttonDetails.buttonLink = PAGE_URLS.BILLS_AUDIT_CREATE_USER;
    buttonDetails.baStep = BillsAuditSteps.ENTER_USER;
  }

  return buttonDetails;
};
