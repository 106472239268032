import { BillsData } from "../../types";
import { SET_BILLS, BILLS_LOADING } from "../actionTypes";

const initialState: BillsState = {
  loading: false,
  billsData: [],
};

export interface BillsState {
  loading: boolean;
  billsData: BillsData[];
}

interface action {
  type: string;
  payload: any;
}

const bills = (state: BillsState = initialState, action: action) => {
  switch (action.type) {
    case SET_BILLS: {
      return {
        ...state,
        billsData: action.payload,
        loading: false,
      };
    }
    case BILLS_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default bills;
