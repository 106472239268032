import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { IonButton, IonFooter, IonList, IonText } from "@ionic/react";

import "./NewGoal.scss";
import { Goal } from "../../../types";
import { PRIVILEGE_TYPE_DATA_3, PAGE_URLS } from "../../../constants";
import { ChangeTargetButton } from "./ChangeTargetButton";
import { ChangeCategoryButton } from "./ChangeCategoryButton";
import { ChangeSharingButton } from "./ChangeSharingButton";
import { createNewGoal } from "../../../redux/actions";
import { useDispatch } from "../../../redux/hooks";
import { useUserName } from "../../shared/User/useUserName";
import { useNavigation } from "../../../utils/useNavigation";
import { OwnershipType } from "../../../types/goals";
import { PageTemplate, Section } from "../../shared/PageTemplate";
import { useViewport } from "../../../hooks/useViewport";
import { useInterestRate } from "../../../hooks/useInterestRate";
import { trackEvent } from "../../../vendors/monitoring";
import images from "../../../assets/images/illustrations";

const defaultGoal: Goal = {
  id: "NEW",
  targetAmount: 1000,
  contributionAmount: 0,
  partnerContributionAmount: 0,
  privilegeType: "SHARED",
  ownership: "JOINT",
  contributors: "SINGLE",
  display: "Emergency fund",
  category: "EMERGENCY",
  name: "Emergency fund",
};

type NewGoalProps = {
  header?: ReactElement;
  firstTime?: boolean;
};
export const NewGoal: React.FC<NewGoalProps> = (props) => {
  const { header, firstTime } = props;
  const { singlePlayerMode, partnerName } = useUserName();
  const { isMobile } = useViewport();
  const [goal, setGoal] = useState(() => ({
    ...defaultGoal,
    ownership: singlePlayerMode ? "INDIVIDUAL" : ("JOINT" as OwnershipType),
  }));
  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const { t } = useTranslation();
  const { showRate, interestRate } = useInterestRate();

  const onGoalChange = (slice: Partial<Goal>) => setGoal({ ...goal, ...slice });

  const footerSection = (
    <IonFooter className="ion-text-center ion-no-border flex-column">
      <IonButton
        className="ion-margin ion-align-self-center"
        data-testid="createGoalButton"
        onClick={() => {
          (dispatch(createNewGoal(goal)) as any)
            .then((id: string) =>
              navigate(PAGE_URLS.GOALS_NEW_NAME.replace(":goalId", id), "forward")
            )
            .then(() => trackEvent("start_goalSetup", { category: "goals" }));
        }}
      >
        {t("startSaving")}
      </IonButton>
      <IonText className="ion-margin-bottom">
        {firstTime
          ? t(showRate ? "fdicInsuredWithBonus" : "theMoneyIsFdicInsured", { interestRate })
          : t("dontWorryYouCanUpdate")}
      </IonText>
    </IonFooter>
  );

  return (
    <PageTemplate
      contentProps={{ fullscreen: true, className: "new-goal ion-text-center" }}
      header={header}
      footer={isMobile ? footerSection : undefined}
    >
      <Section>
        {isMobile && (
          <div className="ion-margin ion-padding-horizontal ion-padding-top">
            <img src={images.goalsHiker} />
          </div>
        )}
        <IonList className="new-goal-options">
          <ChangeCategoryButton label={t("whatIsThisFor")} goal={goal} onChange={onGoalChange} />
          <ChangeTargetButton
            label={
              !singlePlayerMode && goal.privilegeType === PRIVILEGE_TYPE_DATA_3.SHARED
                ? t("howMuchWillYouAndPartnerSave", { partnerName })
                : t("howMuchWillYouSave")
            }
            goal={goal}
            onChange={onGoalChange}
          />
          {singlePlayerMode ? null : (
            <ChangeSharingButton label={t("whoIsThisFor")} goal={goal} onChange={onGoalChange} />
          )}
        </IonList>
        {isMobile ? null : footerSection}
      </Section>
    </PageTemplate>
  );
};
