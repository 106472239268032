import React, { useState } from "react";
import { IonToolbar, IonTitle } from "@ionic/react";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

import "./NetWorth.scss";

import { ShareSettingsButtonAndPopover } from "../../shared/User/ShareSettingsButtonAndPopover";
import { NetWorthChart } from "./NetWorthChart";
import { useSelector } from "../../../redux/hooks";
import { getNetWorthData } from "../../../redux/selectors";
import { ActionBarSelect } from "../../shared/Select/ActionBarSelect";
import { NET_WORTH_FACTOR } from "../../../constants";
import { BalanceList } from "./BalanceList";
import { NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";

type tabType = "hh" | "user";

export const NetWorthPage: React.FC = (props) => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState(NET_WORTH_FACTOR.netWorth);
  const [tab, setTab] = useState<tabType>("hh");
  const [_selectedMonth, setSelectedMonth] = useState("");
  const data = useSelector(getNetWorthData(tab === "user"));

  if (!data.length) return null;

  const selectedMonth = _selectedMonth || data[data.length - 1]?.month;
  const selectedData = data.find(({ month }) => month === selectedMonth)!;
  const total: number = selectedData[selectedCategory as never];

  return (
    <PageTemplate
      pageProps={{ className: "net-worth-page" }}
      header={
        <NavHeader title={t("trends")} desktopTitle={t("netWorthTrendsTitle")}>
          <ShareSettingsButtonAndPopover
            isRoute={false}
            budgetType={tab}
            onClick={(tab: tabType) => setTab(tab)}
          />
        </NavHeader>
      }
    >
      <Section>
        <IonToolbar>
          <IonTitle slot="start">{t(selectedCategory)}</IonTitle>
          <ActionBarSelect
            slot="end"
            options={Object.values(NET_WORTH_FACTOR)}
            selectedOption={selectedCategory}
            onSelect={setSelectedCategory}
          />
        </IonToolbar>
        <div className="chart-container">
          <div className="chart-wrapper" style={{ minWidth: 100 * data.length }}>
            <NetWorthChart
              data={data}
              keyBy={selectedCategory as any}
              selectedMonth={selectedMonth}
              onSelect={setSelectedMonth}
            />
          </div>
        </div>
      </Section>

      <Section>
        <BalanceList
          category={selectedCategory}
          data={selectedData}
          onCategoryChange={setSelectedCategory}
          isPrivate={tab === "user"}
        />
        <IonToolbar slot="end" className="totals-toolbar ion-padding-top ion-padding-bottom">
          <IonTitle slot="start">{t(selectedCategory + "_total")}</IonTitle>
          <IonTitle slot="end">
            <span className="with-underline">
              {numeral(total).format("$0,0")}
              <span className={total > 0 ? "positive" : "negative"} />
            </span>
          </IonTitle>
        </IonToolbar>
      </Section>
    </PageTemplate>
  );
};
