import dayjs from "dayjs";
import sortedUniqBy from "lodash/sortedUniqBy";
import { PAGE_URLS } from "../constants";

import { Comparisons, SpendingTransaction } from "../redux/reducers/spendingForecast";

export const prepSpendingForecastData = (data: any[]) => {
  const actual = { id: "actual", data: [] as Array<any | null> };
  const forecast = { id: "forecast", data: [] as Array<any | null> };
  const now = dayjs();
  data.forEach((day: any) => {
    const date = dayjs(day.date);
    const dataPoint = { x: day.date, y: day.overallTotal };
    if (now.isSame(date, "day")) {
      actual.data.push(dataPoint);
      forecast.data.push(dataPoint);
    } else if (now.isBefore(date, "day")) {
      forecast.data.push(dataPoint);
    } else {
      actual.data.push(dataPoint);
    }
  });
  return [actual, forecast];
};

export const prepComparisonData = ({
  actualIncome = [],
  prevMonth = [],
  budgetedIncome,
  budgetedSpend,
}: Comparisons) => {
  const [_actualIncome, _prevMonth, _budgetedIncome, _budgetedSpend] = [[], [], [], []] as any[][];
  actualIncome.forEach(({ date, overallTotal }, index) => {
    _actualIncome.push({ x: date, y: overallTotal });
    _budgetedIncome.push({ x: date, y: budgetedIncome });
    _budgetedSpend.push({ x: date, y: budgetedSpend });

    // handle prevMonth being shorter than current month
    _prevMonth.push({
      x: date,
      y: prevMonth[prevMonth[index] ? index : prevMonth.length - 1]?.overallTotal,
    });
  });
  // handle prevMonth being longer than current month
  if (prevMonth.length > _prevMonth.length) {
    _prevMonth[_prevMonth.length - 1].y = prevMonth[prevMonth.length - 1].overallTotal;
  }

  return [
    { id: "actualIncome", data: _actualIncome },
    { id: "budgetedIncome", data: _budgetedIncome },
    { id: "budgetedSpend", data: _budgetedSpend },
    { id: "prevMonth", data: _prevMonth },
  ];
};

export const calculateHoverPosition = (
  data: ReturnType<typeof prepSpendingForecastData>,
  index: number
) => {
  const days = sortedUniqBy([...data[0].data, ...data[1].data], "x").map(({ x }) => x);
  return days[index] || null;
};

export const dedupeForecastPoint = (points: any[]) => {
  if (
    points.find((p: any) => p.serieId === "actual") &&
    points.find((p: any) => p.serieId === "forecast")
  ) {
    return points.filter((p: any) => p.serieId !== "forecast");
  }
  return points;
};

export const getAmountToDisplay = (txn: SpendingTransaction | null) => {
  if (!txn) return txn;
  return txn.isBill && txn.billStatus === "unpaid" ? txn.billAmount : txn.amtWithSign;
};

export const getTransactionLink = (txn: SpendingTransaction | null, date: string) => {
  if (txn?.txnId) {
    return PAGE_URLS.TRANS_DETAILS.replace(":transactionId", txn?.txnId || "");
  }
  if (txn?.isBill) {
    return PAGE_URLS.BILLS.replace(
      ":effectiveDate",
      dayjs(date).startOf("month").format("YYYY-MM-DD")
    );
  }
};

export const getBaseline = (data: ReturnType<typeof prepSpendingForecastData>) => {
  return Math.min(...data.flatMap((s) => s.data).map((d) => d.y), 0);
};
