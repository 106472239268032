import * as types from "../actionTypes";
import { Dispatch } from "redux";
import { Plugins } from "@capacitor/core";
import { get } from "../../utils/apiOps";
import { versionCheckResponse } from "../reducers/versioncheck";

const { Device } = Plugins;
/**
 * The versionCheck loads some important unauthenticated data
 * that's used in a bunch of different spots. E.g., it includes a chicken switch for subscription,
 * it includes the global savings bonus rate for Goals, etc.
 */
export const getVersionCheckData =
  (verionCheckData?: versionCheckResponse) => async (dispatch: Dispatch<any>) => {
    if (verionCheckData) {
      dispatch(saveVersionCheck(verionCheckData));
    } else {
      dispatch(setVersionCheckLoading(true));
      const { data } = await getVersionCheckFromAPI();
      dispatch(saveVersionCheck(data));
    }
  };

export const getVersionCheckFromAPI = async () => {
  const { platform, appVersion } = await Device.getInfo();
  return await get(false, {
    endpoint: "/versionCheck",
    params: {
      appVersion: appVersion || "9.0.0",
      deviceType: platform,
    },
  });
};

export const saveVersionCheck = (versionCheck: any) => {
  return {
    type: types.SAVE_VERSION_CHECK,
    payload: versionCheck,
  };
};

export const setVersionCheckLoading = (status: boolean = true) => {
  return {
    type: types.VERSION_CHECK_LOADING,
    payload: status,
  };
};
