import React, { useState, useEffect } from "react";
import { IonButton, IonFooter, IonToolbar, IonIcon, IonLoading } from "@ionic/react";
import Link from "../../shared/Link/Link";
import Template, { OnboardingCopy } from "./Template";
import { useTranslation } from "react-i18next";
import "./onboarding.scss";
import { getAccountsState, getUsersState } from "../../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { LinkAccountsListModal } from "./LinkAccountsListModal";
import { onboardingFlags, PAGE_URLS } from "../../../constants";
import { editUser, finishOnboarding, getBudget, initGlobalData } from "../../../redux/actions";
import { UserMemberTypes } from "../../../redux/reducers/users";
import { trackEvent } from "../../../vendors/monitoring";
import { useHistory } from "react-router";
import dayjs from "dayjs";
import { OnboardingNavHeader } from "./OnboardingNavHeader";
import { OnboardingProgress } from "./OnboardingProgress";
import { lock_closed_outline } from "../../../assets/icons/generic";

const pageLocation = "link-accounts";

export const LinkAccounts: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { myAccounts, loading } = useSelector(getAccountsState);
  const [accountsModalOpen, setAccountsModalOpen] = useState<boolean>(false);
  const { memberType, singlePlayerMode } = useSelector(getUsersState);

  const hasLinkedAccounts = myAccounts.length > 0;

  const noAccountsToLink = () => {
    trackEvent("click_noLinkAccts", {
      marketingEvent: "completeregistration",
      category: "onboard",
    });
    (dispatch(getBudget({ effectiveDate: dayjs().startOf("month").format("YYYY-MM-DD") })) as any)
      .then(dispatch(initGlobalData()) as any)
      .then(() => {
        history.push(PAGE_URLS.HOME);
      });
  };

  const next = async () => {
    trackEvent("finish_linkedAcct_cv", {
      marketingEvent: "linkedacct",
      category: "onboard",
    });
    if (singlePlayerMode) {
      const nextPage = (await dispatch(finishOnboarding())) as any;
      history.push(nextPage);
      return;
    }
    await dispatch(editUser({ flag: onboardingFlags.SHARE_SETTINGS }));
    history.push(PAGE_URLS.SIGNUP_SHARE_SETTINGS);
  };

  return (
    <Template
      header={
        <OnboardingNavHeader
          pageLocation={pageLocation}
          desktopTitle={<OnboardingProgress step={6} />}
        />
      }
      showFooter={hasLinkedAccounts}
      handlePrimary={next}
      primaryCopy={t("noMoreAccounts")}
      primaryTestId="nextBtn"
      primaryProps={{ fill: "outline" }}
      footerDisabled={!hasLinkedAccounts}
      onboardingStep={6}
      legal={
        <span className="font-xs flex-center">
          <IonIcon src={lock_closed_outline} className="inline-logo" />
          {t("bankLevelProtection")}
        </span>
      }
    >
      <IonLoading isOpen={loading} />
      <OnboardingCopy title={loading ? ". . ." : hasLinkedAccounts ? "linkMoreAccounts" : "linkYourAccounts"} />
      <div className="bounded">
        <Link />

        {hasLinkedAccounts && (
          <IonButton
            color="secondary"
            fill="clear"
            onClick={() => setAccountsModalOpen(true)}
            className="button-aligned-left"
          >
            {t("linkedAccounts")}
          </IonButton>
        )}
        <LinkAccountsListModal
          isOpen={accountsModalOpen}
          onClose={() => setAccountsModalOpen(false)}
        />
        {memberType === UserMemberTypes.P2 && !hasLinkedAccounts && (
          <IonFooter className="ion-no-border">
            <IonToolbar no-border className="ion-text-center ion-margin-top">
              <IonButton
                onClick={noAccountsToLink}
                data-testid="no-accounts-to-add"
                size="large"
                fill="outline"
              >
                {t("noAccountsToLink")}
              </IonButton>
            </IonToolbar>
          </IonFooter>
        )}
      </div>
    </Template>
  );
};
