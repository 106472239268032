import React, { useState } from "react";
import {
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  useIonViewDidEnter,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../../utils/formUtils";
import { PAGE_URLS } from "../../../constants";
import { useDispatch } from "react-redux";
import Template from "./Template";
import {
  setToastMessage,
  setLoading,
  registerPartner,
  setUserProperties,
} from "../../../redux/actions";
import { useTranslation } from "react-i18next";
import { trackEvent } from "../../../vendors/monitoring";
import { PartnerForm } from "../../shared/Partner/PartnerForm";
import { OnboardingNavHeader } from "./OnboardingNavHeader";

const pageLocation = "enter-partner";

export const EnterPartner: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [partner, setPartner] = useState({ name: "", email: "" });

  const formGood = Boolean(partner.name && partner.email && validateEmail(partner.email));

  const handleNext = () => {
    if (!partner.email || validateEmail(partner.email) === false) {
      dispatch(setToastMessage(t("invalidEmail")));
      return;
    }

    dispatch(setLoading(true));
    (dispatch(registerPartner(partner.name, partner.email)) as any)
      .then(() => history.push(PAGE_URLS.SIGNUP_LINK_ACCTS))
      .catch((error: any) => { })
      .finally(() => dispatch(setLoading(false)));
  };

  const handleSkip = () => {
    trackEvent("Skip_partner_invite", { category: "onboard" });
    dispatch(setUserProperties({ hasPartner: false }));
  };

  return (
    <Template
      header={<OnboardingNavHeader pageLocation={pageLocation} />}
      primaryCopy="invite"
      footerDisabled={!formGood}
      primaryTestId="partner_submit"
      handlePrimary={handleNext}
      onboardingStep={5}
      secondaryCopy="skip"
      showSecondary={true}
      handleSecondary={handleSkip}
      secondaryRouterLink={PAGE_URLS.SIGNUP_LINK_ACCTS}
      secondaryProps={{ routerDirection: "root" }}
    >
      <PartnerForm partner={partner} onChange={setPartner} />
    </Template>
  );
};
