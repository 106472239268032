import { IonText } from "@ionic/react";
import React from "react";
import { formatAccountNumber } from "../../../utils/formatUtils";
import { AccountLogo } from "./AccountLogo";
import numeral from "numeral";
import "./AccountCard.scss";

export const AccountCard: React.FC<any> = (props: any) => {
  const { account, isGoalAccount = false } = props;
  const balance = account.balance.balToUse || account.balance;
  const formattedBalance = numeral(balance).format("$0,0");
  const moneyStyle = balance > 0 ? "money-in" : "money-out";
  const accountName = account.accountNickname || account.accountName || account.name;

  return (
    <>
      <IonText className="account-label ion-padding-end flex-start">
        <AccountLogo account={account} />
        <h3 className="account-text font-md text-no-wrap">
          {accountName}{" "}
          <p className="ion-no-margin font-sm text-margins">
            {account.institutionName}{" "}
            {account.accountNumber && `(${formatAccountNumber(account.accountNumber)})`}
          </p>
        </h3>
      </IonText>

      <IonText slot="end" className={`amount ion-text-end ion-no-margin ${moneyStyle}`}>
        {formattedBalance}
      </IonText>
    </>
  );
};
