import React from "react";
import { IonInfiniteScroll, IonInfiniteScrollContent, IonItemGroup, IonList } from "@ionic/react";
import dayjs from "dayjs";
import _, { cloneDeep } from "lodash";
import { ItemDateDivider } from "../../shared/Miscellaneous/ItemDateDivider";
import { Section } from "../../shared/PageTemplate";
import Transaction from "../../pages/Transaction/Transaction";
import { RecentActivitiesItem } from "../../pages/Budget/RecentActivities";
import * as type from "../../../types/transactions";
import { FeedsParams } from "../../../types";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

/**
 * Group an array of items by a date
 * @param items
 * @param groupProperty
 * @returns
 */
export const groupItemByDate = (items: any[], groupProperty: string) => {
  // 1) Create a clean date for grouping
  // 2) Group by said clean date
  // 3) Create a new array with grouped objects { date: [clean_date_key], items: Array }
  // 4) Order descending by group date
  return _(cloneDeep(items))
    .map((item) => {
      item.groupDate = dayjs.utc(item[groupProperty]).format("YYYY-MM-DD");
      return item;
    })
    .groupBy("groupDate")
    .map((items, date) => {
      return {
        date,
        items,
      };
    })
    .orderBy("groupDate", ["desc"])
    .value();
};

type itemsType = "feed" | "transactions";

interface ListWithDateDividerProps {
  items: type.Transaction[] | FeedsParams[];
  itemsType: itemsType;
  itemsGroupProp: string;
  infiniteScrollHandler: any;
}

export const ListWithDateDivider: React.FC<ListWithDateDividerProps> = ({
  items,
  itemsType,
  itemsGroupProp,
  infiniteScrollHandler,
}) => {
  const getChild = (item: type.Transaction | FeedsParams, type: itemsType) => {
    switch (type) {
      case "transactions":
        return <Transaction key={item._id} transaction={item as type.Transaction} />;
      case "feed":
        return <RecentActivitiesItem key={item._id} feed={item as FeedsParams} showDate />;
      default:
        return null;
    }
  };

  return (
    <>
      <IonList>
        <React.Fragment>
          {groupItemByDate(items, itemsGroupProp)?.map((dateGroup) => (
            <Section key={dateGroup.date}>
              <IonItemGroup>
                <ItemDateDivider>{dayjs.utc(dateGroup.date).format("ddd, MMM D")}</ItemDateDivider>
                {dateGroup.items.map((item) => getChild(item, itemsType))}
              </IonItemGroup>
            </Section>
          ))}
        </React.Fragment>
      </IonList>
      <IonInfiniteScroll onIonInfinite={infiniteScrollHandler}>
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    </>
  );
};
