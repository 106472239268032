import React, { useEffect, useState } from "react";
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonFooter,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PAGE_URLS } from "../../../constants";
import { get, post } from "../../../utils/apiOps";
import { BillsAuditGetStarted } from "./BillsAuditGetStarted";
import { BillsAuditAddManualModal } from "./BillsAuditAddManualModal";
import {
  setLoading,
  setSelectedBillerInfo,
  getBillsAuditData,
  getBillersList,
} from "../../../redux/actions";
import {
  billersListApiRes,
  billsAuditDataType,
  billsharkBillerDetailsApiResp,
  potentialSavingsApiResp,
  seletectedBillerInfoType,
} from "../../../types/billsAudit";
import { getBillsAuditDataState } from "../../../redux/selectors";
import { BillsAuditBillsharkStatus, BillsAuditSteps } from "../../../types/billsAudit";
import { billStatusTriage } from "../../../utils/billsAuditUtils";
import images from "../../../assets/images/illustrations";
import "./BillsAuditMain.scss";
import { PageTemplate, Section } from "../../shared/PageTemplate";
import { NavHeader } from '../../shared/PageTemplate/NavHeader';

export const BillsAuditMain: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { billsAuditData, billersList } = useSelector(getBillsAuditDataState);
  const [showBillsAuditAddManualModal, setShowBillsAuditAddManualModal] = useState(false);

  const getPageData = async () => {
    dispatch(setLoading(true));
    await (dispatch(getBillsAuditData()) as any);
    await (dispatch(getBillersList()) as any);

    dispatch(setLoading(false));
  };

  const calcPotentialSavings = async (billsharkProviderId: string, currentBillAmount: number) => {
    try {
      const { data }: potentialSavingsApiResp = await get(true, {
        endpoint: "/billshark/potentialSavings",
        params: {
          billsharkProviderId,
          currentBillAmount,
        },
      });
      return data;
    } catch (error) {
      return null;
    }
  };

  const confirmBillsAuditForBiller = async (bill: seletectedBillerInfoType) => {
    try {
      dispatch(setLoading(true));
      const potentialSavings = await calcPotentialSavings(
        bill.billsharkProviderId!,
        bill.currentBillAmount!
      );
      const { data }: billsharkBillerDetailsApiResp = await get(true, {
        endpoint: "/billshark/billerDetails?billsharkProviderId=" + bill.billsharkProviderId,
      });
      dispatch(
        setSelectedBillerInfo({
          ...bill,
          estimatedSavings: potentialSavings?.expected_saving || 0,
          billsharkBillerDetails: data,
        })
      );
      const triageResp = await billStatusTriage(bill.billsharkProviderId!);
      dispatch(setLoading(false));
      if (triageResp.createBill) {
        history.push(PAGE_URLS.BILLS_AUDIT_GET_STARTED);
      } else {
        history.push(triageResp.buttonLink!);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getStarted = async (biller: billsAuditDataType[0]) => {
    confirmBillsAuditForBiller({
      offersRulesId: biller._id,
      billsharkProviderId: biller.billsharkProviderId!,
      currentBillAmount: biller.txnInfo.amt,
      isPrivate: biller.txnInfo.txnVisibility ? biller.txnInfo.txnVisibility.length > 1 : false,
      displayName: biller.displayName,
      billLogo: biller.logo,
    });
  };

  const getStartedManual = async (
    biller: billersListApiRes["data"][0],
    amt: number,
    isPrivate: boolean
  ) => {
    confirmBillsAuditForBiller({
      offersRulesId: biller._id,
      billsharkProviderId: biller.billsharkProviderId!,
      currentBillAmount: amt,
      isPrivate: isPrivate,
      displayName: biller.displayName,
      billLogo: biller.logo,
    });
    setShowBillsAuditAddManualModal(false);
  };

  useEffect(() => {
    getPageData();
  }, []);

  return (
    <PageTemplate
      pageProps={{ id: "BillsAuditMain" }}
      contentProps={{ fullscreen: true }}
      header={<NavHeader title={t("homeCards_billsAuditTitle")} />}
      footer={
        <IonFooter className="ion-text-center ion-no-border ion-padding fy-transparent-section">
          <IonButton
            color="primary"
            onClick={() => setShowBillsAuditAddManualModal(true)}
            data-testid="ba_bill_search"
          >
            {t("billsAuditHomeFindMoreBills")}
          </IonButton>
        </IonFooter>
      }
    >
      <Section>
        <IonItem lines="none">
          <h1 className="font-title-3">{t("billsAuditHomeSaveMoney")}</h1>
          <IonAvatar id="BillsharkLogoAvatar" slot="end">
            <img id="BillsharkLogoAvatarImg" src={images.billsAuditBillshark} />
          </IonAvatar>
        </IonItem>
        <IonItem lines="none">
          <h3 className="font-md">{t("billsAuditHomeShortDescription")}</h3>
        </IonItem>
        <IonList lines="none">
          <IonListHeader>
            <h1 className="font-title-3">{t("billsAuditHomeBillsWeFound")}</h1>
          </IonListHeader>
          {billsAuditData &&
            billsAuditData.length > 0 &&
            billsAuditData.map((bill) => (
              <IonItem key={bill._id} id="BillerStatusRow">
                <IonAvatar id="BillerLogoAvatar" slot="start">
                  <img id="BillerLogoAvatarImg" src={bill.logo} />
                </IonAvatar>
                <IonLabel>
                  <h3>{bill.displayName}</h3>
                  <p>{t("billsAuditLastPaid", { amt: bill.txnInfo.amt })}</p>
                </IonLabel>
                {(!bill.billsharkInfo ||
                  bill.billsharkInfo?.status === BillsAuditBillsharkStatus.NEW ||
                  bill.billsharkInfo?.status === BillsAuditBillsharkStatus.WAITING_DOCS) && (
                    <IonButton
                      fill="outline"
                      id="BillerGetStartedButton"
                      onClick={() => getStarted(bill)}
                      data-testid="ba_status_in_progress"
                    >
                      {bill.billsharkInfo ? bill.buttonText : t("getStarted")}
                    </IonButton>
                  )}
                {(bill.billsharkInfo?.status === BillsAuditBillsharkStatus.COMPLETED ||
                  bill.billsharkInfo?.status === BillsAuditBillsharkStatus.SUBMITTED) && (
                    <IonText data-testid="ba_status_submitted">
                      {bill.billsharkInfo?.billsharkStatus}
                    </IonText>
                  )}
              </IonItem>
            ))}
          {!billsAuditData ||
            (billsAuditData.length === 0 && (
              <IonItem key={123456} id="BillsAuditNoBillsFound" data-testid="ba_no_bills_found">
                <p className="font-md">{t("billsAuditNoBillsFound")}</p>
              </IonItem>
            ))}
        </IonList>
      </Section>
      <BillsAuditAddManualModal
        isOpen={showBillsAuditAddManualModal}
        onSubmit={getStartedManual}
        onCancel={() => {
          setShowBillsAuditAddManualModal(false);
        }}
        billersList={billersList || []}
      />
    </PageTemplate>
  );
};
