import React, { useState } from 'react'
import { IonButton, IonIcon, IonContent, IonFooter } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import { Modal } from '../../shared/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { LegendLabel } from './ChartLegend';

type ForecastInfoModalProps = {
  comparison: string;
}

export const ForecastInfoModal: React.FC<ForecastInfoModalProps> = props => {
  const { comparison } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return <>
    <IonIcon icon={informationCircleOutline} onClick={() => setIsOpen(!isOpen)} className="info-button" />
    <Modal open={isOpen} onClose={() => setIsOpen(false)} title={t("projectedSpending")}>
      <IonContent className="ion-padding ion-padding">
        <h4>
          {t("projectedSpendingExplanation")}
          <br />
          <br />
          {t("chartLinesMeaning")}
        </h4>
        <div className="info-modal-legend">
          <LegendLabel value="actual" />
          <LegendLabel value="forecast" />
          {comparison ? <LegendLabel value={comparison} /> : null}
        </div>
      </IonContent>
      <IonFooter className="ion-no-border ion-text-center ion-padding">
        <IonButton onClick={() => setIsOpen(false)}>
          {t("gotIt")}
        </IonButton>
      </IonFooter>
    </Modal>
  </>
}