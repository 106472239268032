import React, { useState } from "react";
import { IonLabel, IonItem, IonThumbnail } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { InAppBrowser, InAppBrowserEvent, InAppBrowserObject } from "@ionic-native/in-app-browser";
import { v4 as uuidv4 } from "uuid";

import { AccountLogo } from "../../pages/AccountsTab/AccountLogo";
import bankLogosImp from "../../../assets/images/bank_logos";
import { getAccountData, saveLastLinkedAccount, setToastMessage } from "../../../redux/actions";
import { BankLinkFormat, InstitutionSearchResp } from "./LinkInstitutionWrapper";
import { BankLinkSplashModal } from "./BankLinkSplashModal";
import { getDeviceDataState, getUsersState } from "../../../redux/selectors";

const bankLogos: any = { ...bankLogosImp };

interface BankLinkWrapperProps {
  format: BankLinkFormat;
  institution: InstitutionSearchResp;
  onClose: any;
}

interface HoneyfiOauthMessage {
  source: string;
  status: "success" | "fail";
  accountsProcessed?: any;
}

const BankLinkWrapper: React.FC<BankLinkWrapperProps> = ({
  institution,
  format,
  onClose,
  children,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [bankSplashModalOpen, setBankSplashModalOpen] = useState<boolean>(false);
  const { userId } = useSelector(getUsersState);
  const deviceInfo = useSelector(getDeviceDataState);
  const bankTokenId = institution.bankTokenId || "new";

  const openBankLink = async (institution: InstitutionSearchResp) => {
    try {
      if (!institution.url) {
        throw new Error(`missing institution url for institution: ${institution.id}`);
      }
      setBankSplashModalOpen(true);
      const fullUrl = encodeURI(
        `${institution.url}?state=${userId}~${bankTokenId}~${institution.id}~${uuidv4()}`
      );
      const options = "hardwareback=no,location=no,toolbar=no,hidden=yes";

      const processChildMessage = (
        messageData: HoneyfiOauthMessage,
        browser: InAppBrowserObject | Window,
        location: string = "onboarding"
      ) => {
        browser.close();
        //TODO: add loader
        if (messageData.status === "success") {
          if (location === "account-alert") {
            dispatch(setToastMessage("Account fixed!"));
            dispatch(getAccountData());
            // TODO: close modal if account alert
          } else {
            dispatch(saveLastLinkedAccount(messageData.accountsProcessed));
            dispatch(getAccountData());
            if (location === "settings") {
              // TODO: where to go next?
              // PlaidService.addedAccountNextPage();
            }
          }
          onClose(true);
        } else {
          throw new Error(`processChildMessage was not successful for ${institution.id}`);
        }
      };

      const onBrowserClose = (userClosed: boolean) => {
        console.debug(`window closed by ${userClosed ? "user" : "system"}`);
        setBankSplashModalOpen(false);
      };

      const onBrowserMessage = (
        message: MessageEvent | InAppBrowserEvent,
        browser: InAppBrowserObject | Window
      ) => {
        try {
          let messageData = message.data;
          if (typeof messageData === "string") {
            messageData = JSON.parse(message.data);
          }
          if (messageData?.source === "firstly") {
            console.debug("cypress firstly message received");
            console.debug(message);
            processChildMessage(messageData, browser);
          }
        } catch (e) {
          console.error(e);
        }
      };

      if (deviceInfo?.deviceDetails?.platform !== "web") {
        // leverage plugin
        console.debug("using plugin");
        const browser = InAppBrowser.create(fullUrl, "_blank", options);
        browser.on("exit").subscribe((event) => {
          onBrowserClose(true);
        });
        browser.on("message").subscribe((event) => {
          onBrowserMessage(event, browser);
        });
        setTimeout(() => {
          browser.show();
        }, 3000);
      } else {
        // leverage window
        console.debug("using window");
        setTimeout(() => {
          const browser = window.open(fullUrl, "_blank", options);
          if (browser) {
            window.addEventListener("message", (event) => {
              onBrowserMessage(event, browser);
            });
            const timer = setInterval(() => {
              if (browser.closed) {
                clearInterval(timer);
                onBrowserClose(true);
              }
            }, 300);
          }
        }, 3000);
      }
    } catch (err) {
      console.error(err);
      setBankSplashModalOpen(false);
      dispatch(setToastMessage(t("openInAppBrowserErr")));
    }
  };

  return (
    <div>
      <div>
        <BankLinkSplashModal
          isOpen={bankSplashModalOpen}
          onClose={() => setBankSplashModalOpen(false)}
          institution={institution}
        />
      </div>
      <div
        className={`${
          format === BankLinkFormat.QUICKBANK ? "quickbank-wrapper" : "bank-search-item"
        } quickbank-${institution.id}`}
        onClick={() => openBankLink(institution)}
      >
        {children}
      </div>
    </div>
  );
};

export default BankLinkWrapper;
