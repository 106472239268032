import numeral from "numeral";
import { GOAL_CONSTANTS } from "../constants";
import { useSelector } from "../redux/hooks";
import { getVersionCheckState } from "../redux/selectors";

export const useInterestRate = function useInterestRate() {
  const rates = useSelector(getVersionCheckState)?.versionCheck?.savingsInfo?.rates ?? [
    { rate: GOAL_CONSTANTS.INTEREST_RATE },
  ];
  const primaryRate = rates[0].rate;
  return {
    interestRateValue: primaryRate / 10000, // since the server returns percent decimals (i.e., 25 = 0.25%)
    interestRate: numeral(primaryRate / 10000).format(primaryRate % 100 === 0 ? "0%" : "0.00%"),
    showRate: primaryRate > 0,
  };
};
