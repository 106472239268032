import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "../../../../redux/hooks";
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonPicker,
} from "@ionic/react";
import { PAGE_URLS } from "../../../../constants";
import { getUsersState, getHouseholdDataState } from "../../../../redux/selectors";
import { editUserFinancialProfile } from "../../../../redux/actions";
import { PickerColumn } from "@ionic/core";
import { PageTemplate, Section } from '../../../shared/PageTemplate';
import { NavHeader } from '../../../shared/PageTemplate/NavHeader';
import { Picker } from "../../../shared/Picker/Picker";

export const IndivFinances: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector(getUsersState);
  const hh = useSelector(getHouseholdDataState);
  const singlePlayer = hh?.members[0]?.singlePlayerMode;
  const financialProfile = userData.financialProfile || {};
  const financesDef = singlePlayer ? "indivFinancesSinglePlayerDef" : "indivFinancesDef";

  const financesFields = [
    {
      name: "personality",
      options: [
        t("profileNotSet"),
        t("personalitySaver"),
        t("personalitySpender"),
        t("personalityDontKnow"),
      ],
      value: financialProfile.personality,
    },
    {
      name: "studentDebt",
      options: [
        "$0-$9,999",
        "$10,000-$19,999",
        "$20,000-$39,999",
        "$40,000-$74,999",
        "$75,000-$99,999",
        "$100,000-$124,999",
        "$125,000+",
      ],
      value: financialProfile.studentDebt,
    },
  ];

  const handleUpdateProfile = (slice: object) => {
    dispatch(
      editUserFinancialProfile({
        financialProfile: {
          ...financialProfile,
          ...slice,
        },
      })
    );
  };

  return (
    <PageTemplate
      header={<NavHeader title={t("profile")} defaultHref={PAGE_URLS.MORE_PROFILE} />}
    >
      <Section>
        <IonText className="finances-header">
          <h2>{t("indivFinances")}</h2>
          <p>{t(financesDef)}</p>
        </IonText>
        {financesFields.map((field) => {
          return (
            <IonItem lines="none" key={field.name}>
              <IonLabel position="stacked">{t(field.name)}</IonLabel>
              <Picker
                name={field.name}
                options={field.options}
                value={field.value}
                onChange={value => handleUpdateProfile({ [field.name]: value })}
              />
            </IonItem>
          );
        })}
        <IonItem detail routerLink={PAGE_URLS.MORE_PROFILE_INDIV_FINANCES_GOALS} lines="none">
          <IonLabel>{t("tab_goals")}</IonLabel>
        </IonItem>
      </Section>
    </PageTemplate>
  );
};
