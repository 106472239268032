import React, { ReactElement } from "react";
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton } from "@ionic/react";
import constants, { EXTERNAL_URLS } from "../../../constants";
import { FAQButton } from "../../shared/Support/FAQButton";
import { HelpButton } from "../../shared/Support/HelpButton";
import { firstlyHeaderLogo } from "../../../assets/images";
import "./OnboardingNavHeader.scss";
import { useViewport } from '../../../hooks/useViewport';
import { useTranslation } from "react-i18next";
import { NavFAQ, NavHelp } from "../../shared/PageTemplate";

interface OnboardingNavHeaderProps {
  showBackButton?: boolean;
  showFAQ?: boolean;
  showHelp?: boolean;
  defaultHref?: string;
  title?: string | ReactElement;
  desktopTitle?: string | ReactElement;
  pageLocation?: string;
}
export const OnboardingNavHeader: React.FC<OnboardingNavHeaderProps> = ({
  showBackButton = false,
  showFAQ = false,
  showHelp = true,
  defaultHref,
  title,
  desktopTitle,
  pageLocation,
}) => {
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const logoLink = <a href={EXTERNAL_URLS.MAIN}>
    <img src={firstlyHeaderLogo} alt={constants.APP_NAME} />
  </a>
  return (
    <IonHeader id="onboarding-nav-header">
      <IonToolbar mode="ios">
        {showBackButton && (
          <IonButtons slot="start" className="hidden-desktop">
            <IonBackButton defaultHref={defaultHref} text="" />
          </IonButtons>
        )}
        <IonTitle slot="start" className="font-lg ion-text-start ion-padding hidden-mobile">
          {logoLink}
        </IonTitle>
        {desktopTitle || isMobile ? <IonTitle className="title-2 ion-padding nav-title">
          {isMobile ? title || logoLink : desktopTitle}
        </IonTitle> : null}

        <IonButtons slot="end">
          {showHelp && <HelpButton pageLocation={pageLocation} helpMsg={t(`help_${pageLocation}`) || t("help")} color="primary" />}
          {showFAQ && <FAQButton pageLocation={pageLocation} color="primary" />}
        </IonButtons>
      </IonToolbar>
    </IonHeader >
  );
};
