import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonThumbnail,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { closeOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import { useDispatch } from "../../../redux/hooks";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import { operations } from "../../../types/schema";
import { billersListApiRes } from "../../../types/billsAudit";
import { useResponsiveInterface } from '../../../hooks/useResponsiveInterface';

interface BillsAuditAddManualModalProps {
  isOpen: boolean;
  onSubmit: any;
  onCancel: any;
  billersList: billersListApiRes["data"];
}

export const BillsAuditAddManualModal: React.FC<BillsAuditAddManualModalProps> = ({
  isOpen,
  onSubmit,
  onCancel,
  billersList,
}) => {
  const { t } = useTranslation();
  const _interface = useResponsiveInterface();

  const resetFields = () => {
    setMonthlyAmount(null);
    setSelectedBiller(null);
    setIsPrivate(false);
  };

  const getStarted = () => {
    resetFields();
    onSubmit(selectedBiller, monthlyAmount, isPrivate);
  };

  const cancelIt = () => {
    resetFields();
    onCancel();
  };

  const [selectedBiller, setSelectedBiller] = useState<billersListApiRes["data"][0] | null>();
  const [monthlyAmount, setMonthlyAmount] = useState<number | null>();
  const [isPrivate, setIsPrivate] = useState(false);
  const [readyToSubmit, setReadyToSubmit] = useState(false);

  useEffect(() => {
    if (selectedBiller && monthlyAmount && monthlyAmount > 0) {
      setReadyToSubmit(true);
    } else {
      setReadyToSubmit(false);
    }
  }, [selectedBiller, monthlyAmount]);

  return (
    <IonModal isOpen={isOpen} id="BillsAuditAddManualModal" onDidDismiss={cancelIt}>
      <ModalHeader
        title={t("homeCards_billsAudit")}
        startBtnIcon={closeOutline}
        startBtnOnClick={() => cancelIt()}
      />
      <IonContent>
        <form id="manualBillAudit" className="ion-padding">
          <IonList lines="none">
            <IonListHeader>
              <h1 className="font-title-3">{t("billsAuditSelectADifferentBill")}</h1>
            </IonListHeader>
            <IonItem>
              <IonLabel>{t("billsAuditBiller")}</IonLabel>
              <IonSelect
                slot="end"
                onIonChange={({ detail }) => setSelectedBiller(detail.value)}
                interface={_interface}
                value={selectedBiller}
                placeholder={t("billsAuditSelectBiller")}
                data-testid="ba_biller_select"
              >
                {billersList &&
                  billersList.map((biller) => {
                    return (
                      <IonSelectOption value={biller} key={biller._id}>
                        {biller.displayName}
                      </IonSelectOption>
                    );
                  })}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel>{t("billsAuditMonthlyBillAmt")}</IonLabel>
              <IonInput
                type="number"
                placeholder="88"
                onIonChange={({ detail }) => setMonthlyAmount(parseInt(detail.value!, 10))}
                value={monthlyAmount}
                slot="end"
                className="ion-text-right"
                size={3}
                min="1"
                max="5000"
                data-testid="ba_manual_amt"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>{t("billsAuditManualPrivateBill")}</IonLabel>
              <IonToggle
                checked={isPrivate}
                onIonChange={({ detail }) => setIsPrivate(detail.checked)}
              />
            </IonItem>
          </IonList>
        </form>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar no-border className="ion-text-center">
          <IonButton
            fill="clear"
            data-testid="billsAuditAddCancel"
            size="large"
            className="ion-margin-start ion-margin-bottom"
            onClick={cancelIt}
          >
            {t("cancel")}
          </IonButton>
          <IonButton
            color="primary"
            data-testid="ba_add_save"
            size="large"
            className="ion-margin-start ion-margin-bottom"
            onClick={getStarted}
            disabled={!readyToSubmit}
          >
            {t("billsAuditHomeGetStarted")}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
