import React, { ReactElement } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonButton,
} from "@ionic/react";

import "./NavHeader.scss";

import constants from "../../../constants";
import { FAQButton } from "../../shared/Support/FAQButton";
import { HelpButton } from "../../shared/Support/HelpButton";
import { firstlyLogo } from "../../../assets/images";
import { useViewport } from "../../../hooks/useViewport";
import { PAGE_URLS } from "../../../constants";
import { chevronBackOutline } from "ionicons/icons";

const getTitleWrapper = (title?: string | ReactElement, defaultTitle?: ReactElement) =>
  title ? (
    typeof title === "string" ? (
      <IonTitle className="bold main-header-title font-title-2">{title}</IonTitle>
    ) : (
      title
    )
  ) : (
    defaultTitle
  );

interface NavHeaderProps {
  showBackButton?: boolean;
  backButton?: ReactElement;
  pageLocation?: string;
  defaultHref?: string;
  title?: string | ReactElement;
  subheader?: "useTitle" | string | ReactElement;
  desktopTitle?: string | ReactElement;
  extraMobileSubheader?: ReactElement;
}
export const NavHeader: React.FC<NavHeaderProps> = ({
  showBackButton = true,
  backButton,
  defaultHref = PAGE_URLS.HOME,
  title,
  desktopTitle,
  subheader,
  extraMobileSubheader,
  children,
}) => {
  const { isDesktop } = useViewport();

  const backButtonSection = showBackButton ? (
    <IonButtons slot="start">
      {backButton || (
        <IonBackButton
          defaultHref={defaultHref}
          text={isDesktop ? "Back" : ""}
          icon={chevronBackOutline}
          color="primary"
          data-testid="backButton"
        />
      )}
    </IonButtons>
  ) : null;

  const titleSection = getTitleWrapper(
    title,
    <IonTitle>
      <img src={firstlyLogo} alt={constants.APP_NAME} className="header-title-image" />
    </IonTitle>
  );

  const desktopTitleSection = getTitleWrapper(desktopTitle, titleSection);

  const desktopSubheaderSection = subheader === "useTitle" ? titleSection : subheader;

  return (
    <IonHeader className="fy-nav-header" mode="md">
      {isDesktop ? (
        <>
          {backButtonSection ? <IonToolbar>{backButtonSection}</IonToolbar> : null}
          <IonToolbar
            className={`
          title-section
          ${desktopSubheaderSection ? "has-subheader " : ""}
          ${showBackButton ? "has-back-button " : ""}
        `}
          >
            {desktopTitleSection}
            <IonButtons slot="end" className="fy-nav-buttons">
              {children}
            </IonButtons>
            <hr className="title-section-border" />
          </IonToolbar>
          {desktopSubheaderSection ? (
            <IonToolbar className="subheader">{desktopSubheaderSection}</IonToolbar>
          ) : null}
        </>
      ) : (
        <>
          <IonToolbar mode="ios">
            {backButtonSection}
            {titleSection}
            <IonButtons slot="end" className="fy-nav-buttons">
              {children}
            </IonButtons>
          </IonToolbar>
          {extraMobileSubheader}
        </>
      )}
    </IonHeader>
  );
};

type NavHelpProps = {
  pageLocation?: string;
  helpMsg?: string;
};
export const NavHelp: React.FC<NavHelpProps> = (props) => {
  const { pageLocation, helpMsg = "help" } = props;
  return <HelpButton pageLocation={pageLocation} helpMsg={helpMsg} />;
};

type NavFAQProps = {
  pageLocation?: string;
};
export const NavFAQ: React.FC<NavFAQProps> = (props) => {
  const { pageLocation } = props;
  return <FAQButton pageLocation={pageLocation} />;
};

type NavButtonProps = {
  icon?: ReactElement;
  label: string | ReactElement;
  [key: string]: any;
};

export const NavButton: React.FC<NavButtonProps> = (props) => {
  const { isDesktop } = useViewport();
  const { icon, label, ...buttonProps } = props;

  return (
    <IonButton {...buttonProps}>
      {icon}
      {isDesktop ? <span className="nav-button-label inter-font">{label}</span> : null}
    </IonButton>
  );
};
