import React, { ReactElement } from 'react';
import { useViewport } from '../../../hooks/useViewport';

type SectionProps = {
  className?: string;
}

export const Section: React.FC<SectionProps> = props => {
  const { className, children } = props;
  const { isDesktop } = useViewport();

  if (isDesktop) {
    return <section className={`fy-section-card ${className}`}>
      {children}
    </section>;
  }
  return <>{children}</>
}