import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { IonButton, IonIcon, IonItem, IonList } from "@ionic/react";
import { lockClosed } from "ionicons/icons";
import { loadStripe, StripeCardElement } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_URLS } from "../../../constants";
import { post } from "../../../utils/apiOps";
import { getBillsAuditDataState } from "../../../redux/selectors";
import { getBillsAuditData, updateSelectedBillInfo, setLoading } from "../../../redux/actions";
import { BillsAuditSteps } from "../../../types/billsAudit";
import { billStatusTriage } from "../../../utils/billsAuditUtils";
import images from "../../../assets/images/illustrations";
import "./BillsAuditEnterPayment.scss";
import { NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { trackEvent } from "../../../vendors/monitoring";

export const BillsAuditEnterPayment: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { selectedBillerInfo, billsAuditData } = useSelector(getBillsAuditDataState);
  const stripePromise = loadStripe(process.env.REACT_APP_BILLSHARK_STRIPE_TOKEN!);

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event: any) => {
      event.preventDefault();
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      dispatch(setLoading(true));
      const { token, error } = await stripe.createToken(
        elements.getElement(CardElement) as StripeCardElement
      );
      if (error) {
        console.error(error);
        dispatch(setLoading(false));
        return;
      }
      if (token) {
        try {
          await post(true, {
            endpoint: "/billshark/submitPayment",
            bodyData: {
              paymentToken: token.id,
            },
          });
          await (dispatch(getBillsAuditData()) as any);
          dispatch(updateSelectedBillInfo(selectedBillerInfo.billsharkProviderId!));
          const triageResp = await billStatusTriage(selectedBillerInfo.billsharkProviderId!);

          trackEvent("submitCC_billsAudit", { category: "analyzeBills" });
          history.replace(triageResp.buttonLink!);
        } catch (error) {
        } finally {
          dispatch(setLoading(false));
        }
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <CardElement
          options={{
            style: {
              base: {
                backgroundColor: "#EEEEEE",
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
        <div id="BillsAuditSubmitDiv">
          <IonButton
            id="BillsAuditSubmitButton"
            type="submit"
            disabled={!stripe}
            data-testid="ba_pymt_submit"
          >
            {t("next")}
          </IonButton>
          <p className="font-sm ion-text-center">
            <IonIcon icon={lockClosed} />
            &nbsp;
            {t("billsAuditPaymentSecurity")}
          </p>
        </div>
      </form>
    );
  };

  // make sure they should be here
  const rightPageCheck = () => {
    billStatusTriage(selectedBillerInfo.billsharkProviderId!).then((triageResp) => {
      if (triageResp.baStep !== BillsAuditSteps.ENTER_PAYMENT) {
        history.replace(PAGE_URLS.BILLS_AUDIT_MAIN);
      }
    });
  };

  useEffect(() => {
    if (!billsAuditData) {
      (dispatch(getBillsAuditData()) as any).then(() => {
        rightPageCheck();
        dispatch(updateSelectedBillInfo(selectedBillerInfo.billsharkProviderId!));
      });
    } else {
      rightPageCheck();
      dispatch(updateSelectedBillInfo(selectedBillerInfo.billsharkProviderId!));
    }
  }, [dispatch]);

  return (
    <PageTemplate
      pageProps={{ id: "BillsAuditEnterPayment" }}
      contentProps={{ fullscreen: true, className: "ion-padding" }}
      header={<NavHeader title={t("homeCards_billsAuditTitle")} />}
    >
      <Section>
        <IonList lines="none">
          <IonItem>
            <img id="BillsAuditCoin" src={images.billsAuditCoin} alt={t("billsAuditCoinImgAlt")} />
          </IonItem>
          <IonItem lines="none">
            <h1 className="font-title-3">{t("billsAuditPaysForItself")}</h1>
          </IonItem>
          <IonItem lines="none">
            <p className="font-md">{t("billsAuditPaymentGuarantee")}</p>
          </IonItem>
        </IonList>
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </Section>
    </PageTemplate>
  );
};
