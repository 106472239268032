import ApolloClient from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";

import { getCognitoToken } from "./apiOps";

export const apolloClient = new ApolloClient<any>({
  uri: process.env.REACT_APP_API_URL + "graphql",
  request: async function request(operation) {
    const authToken = await getCognitoToken();
    operation.setContext({
      headers: {
        api_key: process.env.REACT_APP_API_KEY,
        "content-type": "application/json",
        Authorization: authToken,
      },
    });
  },
  onError: function onError(err) {
    let msg: string = "";
    try {
      // @ts-ignore
      msg = err.response.errors[0].message;
    } catch (err) {
      msg = "Sorry, there's been an error. Please contact support.";
    }
    console.error(msg);
    console.error("Graphql Error");
  },
  cache: new InMemoryCache({
    // Uncomment if you need to get fancier with the caching
    // dataIdFromObject: (object) => {
    //   switch (object.__typename) {
    //     case "SomeType":
    //       return object.__typename;
    //     default:
    //       return object.id || object._id; // fall back to `id` and `_id` for all other types
    //   }
    // },
  }),
});
apolloClient.defaultOptions.query = {
  errorPolicy: "all",
};
