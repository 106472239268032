import React from "react";
import { IonButton } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { openFAQ } from "../../../vendors/intercom";
import { trackEvent } from "../../../vendors/monitoring";

interface FAQButtonProps {
  pageLocation?: string;
  [key: string]: any;
}

export const FAQButton: React.FC<FAQButtonProps> = ({ pageLocation, ...buttonProps }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    openFAQ();
    trackEvent("Tapped FAQ button", {
      pageLocation,
    });
  };

  return (
    <IonButton color="medium" onClick={handleClick} {...buttonProps}>
      {t("faq")}
    </IonButton>
  );
};
