import { get, post, put } from "./apiOps";
import dayjs from "dayjs";
import numeral from "numeral";
import i18n from "i18next";
import { Dispatch } from "redux";
import { updateChallengeFreAndAmount } from "../redux/actions";
import {
  SpendingChallengeCreateParams,
  SpendingChallengeType,
  ChallengeType,
  ChallengeStatus,
  RangeData,
} from "../types/spendingChallenge";
import { orderBy } from "lodash";
import utc from "dayjs/plugin/utc";
import calendar from "dayjs/plugin/calendar";
import advancedFormat from "dayjs/plugin/advancedFormat";
import spendingChallenge from "../redux/reducers/spendingChallenge";
import find from "lodash/find";

dayjs.extend(utc);
dayjs.extend(calendar);
dayjs.extend(advancedFormat);

export const getCategoryData = async (
  category: string | null,
  subCategory: string | null,
  competitionType: any
) => {
  try {
    const { data } = await get(true, {
      endpoint: "/filterTransaction",
      params: {
        isPrivate: false, // TODO: if private challenges enabled, add visibility logic here.
        categoryFilter: category,
        subCategoryFilter: subCategory,
        transactionFilter: competitionType === ChallengeType.INDIVIDUAL ? "Just mine" : null,
        includeMetaData: true,
        includeMetaDataOnly: true,
      },
    });
    return data;
  } catch (err) {
    return i18n.t("noSpending");
  }
};

export const getChallengeTypeDetail = (
  subCategory: string,
  competitionType: string,
  data: any
): string => {
  // competitive category does not show spending averages
  if (competitionType === ChallengeType.COMPETITIVE) {
    return i18n.t("whoCanSpendLessCategory", { spendCategory: subCategory });
  }
  const noSpend = i18n.t("noSpending");
  if (data && data.meta) {
    let debitData = data.meta.debit;
    let creditData = data.meta.credit;
    let total = debitData.total - creditData.total;
    let count = debitData.count - creditData.count;
    if (total > 0) {
      return `${numeral(total).format("$0,00")} ${i18n.t("inLast3")} (${count} ${i18n
        .t("transactions")
        .toLowerCase()})`;
    } else {
      return noSpend;
    }
  } else {
    return noSpend;
  }
};

export const getChallengeTypeDesForLength = (
  competitionType: string,
  period: string,
  dispatch: Dispatch<any>,
  data: any
) => {
  const factor = period === "week" ? 90 / 7 : 3;
  const noSpend = i18n.t("noSpending");

  if (competitionType === ChallengeType.COMPETITIVE) {
    return `${i18n.t("whoCanSpendLess")} ${i18n.t(period)}`;
  }

  if (data && data.meta) {
    let debitData = data.meta.debit;
    let total = debitData.total;
    let count = debitData.count;
    if (total > 0) {
      dispatch(updateChallengeFreAndAmount(`${period}ly`, total));
      // Average weekly and monthly values
      const description = `${i18n.t("youAverage")} ${numeral(total / factor).format(
        "$0,00"
      )}/${period} ${i18n.t("on").toLowerCase()} ${Math.ceil(count)} ${i18n
        .t("transactions")
        .toLowerCase()}`;

      return description;
    } else {
      return noSpend;
    }
  } else {
    dispatch(updateChallengeFreAndAmount(`${period}ly`, 0));
    return noSpend;
  }
};

export const getSpendingChanllengeTypeDes = (competitionType: string, partnerName?: string) => {
  switch (competitionType) {
    case ChallengeType.COLLABORATIVE:
      return `${i18n.t("chanllengeTypeSharedP1")} ${partnerName} ${i18n.t(
        "chanllengeTypeSharedP2"
      )}`;
    case ChallengeType.COMPETITIVE:
      return `${i18n.t("chanllengeTypeSharedP1")} ${partnerName} ${i18n.t(
        "chanllengeTypeCompeteP2"
      )}`;
    case ChallengeType.INDIVIDUAL:
      return `${i18n.t("chanllengeTypeIndividual")}`;
    default:
      break;
  }
};

type SpendingChallengeResultType = "won" | "tied" | "lost";

type SpendingChallengeResult = {
  diff: number;
  winner: boolean | undefined;
  message: string;
  title: string;
  header: SpendingChallengeResultType;
  max: number;
};

export const formatSpendingChallengeMessage = (
  spendingChallengeResult: SpendingChallengeResult,
  challenge: SpendingChallengeType,
  partnerName?: string
) => {
  const textParams = {
    amount: "",
    user: "",
    title: "",
    result: "",
  };

  if (
    challenge.competitionType === ChallengeType.COLLABORATIVE ||
    challenge.competitionType === ChallengeType.INDIVIDUAL
  ) {
    const desP1 =
      challenge.competitionType === ChallengeType.COLLABORATIVE
        ? ` ${i18n.t("and")} ${partnerName}`
        : "";
    textParams.user = `${i18n.t("you")}${desP1} ${i18n.t("spent")}`;
    textParams.amount = numeral(Math.abs(spendingChallengeResult.diff)).format("$0,00.00");
    textParams.result = spendingChallengeResult.winner ? i18n.t("lessOn") : i18n.t("moreOn");
    textParams.title = spendingChallengeResult.title;
  } else if (challenge.competitionType === ChallengeType.COMPETITIVE) {
    if (spendingChallengeResult.diff === 0) {
      textParams.user = `${i18n.t("you")} ${i18n.t("tiedLower")} ${partnerName} ${i18n.t(
        "bySpending"
      )}`;
      textParams.amount = numeral(spendingChallengeResult.max).format("$0,00.00");
      textParams.result = i18n.t("on").toLowerCase();
      textParams.title = spendingChallengeResult.title;
    } else if (spendingChallengeResult.winner) {
      textParams.user = `${i18n.t("you")} ${i18n.t("spent")}`;
      textParams.amount = numeral(Math.abs(spendingChallengeResult.diff)).format("$0,00.00");
      textParams.result = i18n.t("lessOn");
      textParams.title = spendingChallengeResult.title;
    } else {
      textParams.user = `${partnerName} ${i18n.t("spent")}`;
      textParams.amount = numeral(Math.abs(spendingChallengeResult.diff)).format("$0,00.00");
      textParams.result = i18n.t("lessOn");
      textParams.title = spendingChallengeResult.title;
    }
  }
  return textParams;
};

export const getSpendingChallengeResult = (
  challenge: SpendingChallengeType,
  userId: string,
  partnerName?: string
) => {
  let spendingChallengeResult = {} as SpendingChallengeResult;
  let subCategoryName = challenge.targets[0].matchingCriteria[1].value;
  // maps over users
  // adds amt to allArr
  // includes positive, zero and negative amounts.
  const allArr: number[] = [];
  challenge.targets.map((t, i) => {
    if (t && challenge.results.length) {
      const resultValue = challenge.results[i][t.type];
      allArr.push(resultValue);
    }
  });
  if (subCategoryName) {
    spendingChallengeResult.title = subCategoryName;
  } else {
    spendingChallengeResult.title = i18n.t("challenge");
  }
  if (
    challenge.competitionType === ChallengeType.COLLABORATIVE ||
    challenge.competitionType === ChallengeType.INDIVIDUAL
  ) {
    let totSpent = allArr.length ? allArr.reduce((acc, curr) => acc + curr) : 0;
    let totTarg = 0;
    if (challenge.competitionType === ChallengeType.COLLABORATIVE) {
      totTarg = challenge.targets[0].value;
    }
    challenge.targets.map((t) => {
      const thisResult = find(challenge.results, (r) => r.targetId === t._id);
      spendingChallengeResult.winner = thisResult?.winner;
    });

    spendingChallengeResult.diff = totTarg - totSpent;
  } else if (challenge.competitionType === ChallengeType.COMPETITIVE) {
    spendingChallengeResult.max = allArr.length ? Math.max(...allArr) : 0;

    const min = allArr.length ? Math.min(...allArr) : 0;

    challenge.targets.map((t) => {
      const thisResult = find(challenge.results, (r) => r.targetId === t._id);
      if (find(thisResult?.userIds, (r) => r === userId)) {
        spendingChallengeResult.winner = thisResult?.winner;
      }
    });
    spendingChallengeResult.diff = min - spendingChallengeResult.max;
  }
  if (challenge.status === ChallengeStatus.COMPLETED) {
    if (
      challenge.competitionType === ChallengeType.COMPETITIVE &&
      spendingChallengeResult.diff === 0
    ) {
      spendingChallengeResult.header = "tied";
    } else {
      spendingChallengeResult.header = spendingChallengeResult.winner ? "won" : "lost";
    }
    spendingChallengeResult.message = i18n.t(
      "completedSpendingChallengeResult",
      formatSpendingChallengeMessage(spendingChallengeResult, challenge, partnerName)
    );
  }
  return spendingChallengeResult;
};

export const createNewSpendingChallenge = async (bodyData: SpendingChallengeCreateParams) => {
  try {
    return await post(true, {
      endpoint: "/spendingchallenges/create",
      bodyData,
    });
  } catch (error) {}
};

export const editSpendingChallenge = async (bodyData: any) => {
  try {
    return await put(true, {
      endpoint: "/spendingchallenges",
      bodyData,
    });
  } catch (error) {}
};

export const showedSpendingChallenge = (spendingChallengeData: SpendingChallengeType[]) => {
  const activeSpendingChallenge = spendingChallengeData.filter(
    (data: SpendingChallengeType) => data.status === ChallengeStatus.ACTIVE
  );
  const completedChallenge = spendingChallengeData.filter(
    (data: SpendingChallengeType) => data.status === ChallengeStatus.COMPLETED
  );
  if (activeSpendingChallenge.length > 0) {
    return [orderBy(activeSpendingChallenge, ["endDate"], ["asc"])[0]];
  } else if (completedChallenge.length > 0) {
    let closestCompleteChallenge = orderBy(completedChallenge, ["endDate"], ["desc"])[0];
    if (dayjs(new Date()).diff(closestCompleteChallenge.endDate, "week") <= 2) {
      return [closestCompleteChallenge];
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export const getFormatedChallengeDateDes = (spendingChanllenge: SpendingChallengeType) => {
  if (!spendingChallenge) return;
  const dayjsFormats = {
    sameDay: "[today]",
    nextDay: "[tomorrow]",
    nextWeek: "dddd",
    lastDay: "[yesterday]",
    lastWeek: "[Last] dddd",
    sameElse: "MM/DD/YY",
  };
  if (spendingChanllenge.status === ChallengeStatus.CANCELLED) {
    return `${i18n.t("cancelled")}`;
  } else {
    const mEnd = dayjs.utc(spendingChanllenge.endDate);
    const mSt = dayjs.utc(spendingChanllenge.startDate);
    const now = dayjs.utc(dayjs().startOf("day").format("YYYY-MM-DD"));

    if (now.isBefore(mSt)) {
      return `${i18n.t("starts")} ${mSt.calendar(now, dayjsFormats)}`;
    } else if (now.isAfter(mEnd)) {
      if (spendingChanllenge.status === ChallengeStatus.COMPLETED) {
        return `${i18n.t("completed")}: ${mSt.format("MMM Do")} - ${mEnd.format("MMM Do")}`;
      } else {
        return `${i18n.t("ended")} ${mEnd.calendar(now, dayjsFormats)}`;
      }
    } else if (now.isSame(mEnd, "day")) {
      return `${i18n.t("endsTodays")}`;
    } else {
      const daysLeft = mEnd.diff(now, "days");
      return `${daysLeft} day${daysLeft > 1 ? "s" : ""} ${i18n.t("left")}`;
    }
  }
};

export const validateSpendingChallengeData = (
  newSpendingChallenge: any,
  categoryFilter: any,
  frequency: string
) => {
  return (
    !!newSpendingChallenge.competitionType &&
    !!newSpendingChallenge.targets &&
    !!newSpendingChallenge.startDate &&
    !!categoryFilter &&
    !!frequency
  );
};

// defines range variables
export const defineGoalRange = (frequency: string, amount: number): RangeData => {
  const amountBasedOnFrequency = frequency === "weekly" ? (amount / 90) * 7 : amount / 3;
  if (amountBasedOnFrequency > 0) {
    const base = amountBasedOnFrequency < 100 ? 5 : 10;
    const suggestedAmount = Math.max(Math.floor((amountBasedOnFrequency * 0.9) / base) * base, 1);
    const ceil = Math.max(Math.floor((amountBasedOnFrequency * 1.3) / base) * base, 50);
    return {
      floor: base,
      ceil,
      step: base,
      amountBasedOnFrequency,
      suggestedAmount,
      goalAmount: suggestedAmount,
    };
  }
  return {
    floor: 0,
    ceil: 300,
    step: 10,
    amountBasedOnFrequency: 100,
    suggestedAmount: 50,
    goalAmount: 50,
  };
};
