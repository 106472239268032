import { useIonAlert } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useNavigation } from "../utils/useNavigation";
import { PAGE_URLS } from "../constants";

export const useInvitePrompt = () => {
  const [present] = useIonAlert();
  const { navigate } = useNavigation();
  const { t } = useTranslation();
  return useCallback(
    () =>
      present({
        header: t("enterPartner_title"),
        message: t("partnerInviteMessage"),
        buttons: [
          t("no"),
          { text: t("yes"), handler: () => navigate(PAGE_URLS.INVITE_PARTNER, "forward") },
        ],
      }),
    []
  ) as (...args: any[]) => void;
};
