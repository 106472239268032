import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { SpendingAnalysis } from "./SpendingAnalysis";
import { ShareSettingsButtonAndPopover } from "../../shared/User/ShareSettingsButtonAndPopover";
import { PAGE_URLS } from "../../../constants";
import { MonthPicker } from "../../shared/MonthPicker/MonthPicker";
import { useSpendingAnalysisData } from "./useSpendingAnalysisData";
import { PageTemplate, NavHeader } from '../../shared/PageTemplate';
type tabType = "hh" | "user";

export const SpendingAnalysisPage: React.FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation();
  const {
    history: {
      location: { search },
    },
  } = props;
  const initialIndex = Number((search?.match(/^\?i=(\d+)/) || [])[1] || 0);
  const [tab, setTab] = useState<tabType>("hh");
  const [month, setMonth] = useState(() => dayjs().startOf("month"));
  const data = useSpendingAnalysisData(month.format("YYYY-MM-DD"), tab, initialIndex);

  return (
    <PageTemplate
      header={<NavHeader
        title={<MonthPicker month={month} onChange={setMonth} />}
        desktopTitle={t("spendingByCategory")}
        subheader="useTitle"
      >
        <ShareSettingsButtonAndPopover isRoute={false} budgetType={tab} onClick={setTab} />
      </NavHeader>}
    >
      <SpendingAnalysis {...data} month={month} visibility={tab} includeSubCategoryList />
    </PageTemplate>
  );
};
