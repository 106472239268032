import React, { useState } from "react";
import {
  IonButton,
  IonFooter,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonContent,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import images from "../../../assets/images/illustrations";
import { useDispatch, useSelector } from "../../../redux/hooks";
import { getUsersState } from "../../../redux/selectors";
import { Modal } from "../../shared/Modal/Modal";
import { PAGE_URLS } from "../../../constants";
import { resendVerificationEmail } from "../../../redux/actions";

export const VerifyEmailCard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emailValidated, hideEmailVerification, userId, email } = useSelector(getUsersState);
  const [modalOpen, setModalOpen] = useState(false);

  return emailValidated === false && !hideEmailVerification ? (
    <>
      <IonCard>
        <IonCardContent className="ion-text-center ion-margin-horizontal ion-margin-top">
          <img src={images.email} alt="mail inside an envelope" className="ion-margin-bottom" />
        </IonCardContent>
        <IonFooter className="ion-no-border ion-margin-bottom">
          <IonToolbar no-border className="ion-text-center">
            <IonButton
              expand="block"
              size="large"
              className="ion-margin-horizontal"
              data-testid="verify-email-card-button"
              onClick={() => setModalOpen(true)}
            >
              {t("verifyEmailCard_cta")}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonCard>

      <Modal
        title={t("verifyEmail_title")}
        onClose={() => setModalOpen(false)}
        onDidDismiss={() => setModalOpen(false)}
        open={modalOpen}
      >
        <IonContent className="ion-padding">
          <h3>{t("verifyEmail_msg1")}</h3>
          <br />
          <h3>{t("verifyEmail_msg2", { email })}</h3>
        </IonContent>
        <IonFooter className="ion-padding ion-no-border flex-center">
          {/* <IonButton
            size="large"
            expand="block"
            fill="clear"
            className="ion-margin"
            routerLink={PAGE_URLS.MORE_PROFILE + "#user"}
            onClick={() => setModalOpen(false)}
          >
            {t("editEmail")}
          </IonButton> */}
          <IonButton
            size="large"
            fill="solid"
            expand="block"
            className="ion-margin"
            data-testid="resend-verification-email-button"
            onClick={() => dispatch(resendVerificationEmail(userId, email))}
          >
            {t("resendEmail")}
          </IonButton>
        </IonFooter>
      </Modal>
    </>
  ) : null;
};
