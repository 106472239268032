import { cloneDeep } from "lodash";
import { BUDGET_SUPER_CATEGORIES } from "../constants";
import { Category, CategoryMapObj, Design, SubCategory, SubcategoryObjForApi } from "../types";
import { post } from "./apiOps";

export const getCategoryObject = (category: string, categories: Category[]) => {
  return categories.find((c) => c.categoryShortName === category);
};

export const getCatDisplayName = (category: string, categories: Category[]) => {
  return getCategoryObject(category, categories)?.categoryFullName;
};

export const getSubcatDisplayName = (subcat: string, subcategories: SubCategory[]) => {
  return subcategories.find((c: any) => c.subCategory === subcat)?.displayName;
};

export const customCategoryDesigns = () => {
  const colors = [
    "#00E3FF",
    "#EFA523",
    "#5955BD",
    "#FF62BE",
    "#5EA4FE",
    "#8685F9",
    "#F44E71",
    "#88473F",
  ];
  return colors.map((c, i) => {
    return {
      iconDarkValue: `custom_${i + 1}_dark`,
      iconDarkType: "local",
      iconLightValue: `custom_${i + 1}_light`,
      iconLightType: "local",
      bgColor: c,
      textColor: c,
    } as Design;
  });
};

function generateMongoId() {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    "xxxxxxxxxxxxxxxx"
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
}

export const createCategoryObject = (categoryName: string, design: Design) => {
  return {
    categoryShortName: generateMongoId(),
    categoryFullName: categoryName,
    categoryOriginalName: categoryName,
    superCategory: BUDGET_SUPER_CATEGORIES.EXPENSES,
    isCustom: true,
    isDeleted: false,
    design,
  };
};

/**
 * For CUSTOM subcategories
 * @param {String} catShortName
 * @param {String} newSubcatName
 */
function createSubcatObjForMap(catShortName: string, newSubcatName: string) {
  return {
    category: catShortName,
    subCategory: generateMongoId(),
    displayName: newSubcatName,
    isDeleted: false,
    isDeactivated: false,
    isCustom: true,
    isCat: false,
    type: "subcat",
    name: newSubcatName,
  };
}

export const createSubcategoryObj = (subCategoryData: SubCategory[]) => {
  const subCategories: SubcategoryObjForApi = {};
  subCategoryData.map((s: SubCategory) => (subCategories[s.subCategory] = s));
  return subCategories;
};

interface createNewSubcategoryProps {
  categoryData: Category[];
  subCategoryData: SubCategory[];
  selectedCategory: string;
  newSubcategoryName: string;
  generateObjectOnly?: boolean;
}

export const createNewSubcategory = async ({
  categoryData,
  subCategoryData,
  selectedCategory,
  newSubcategoryName,
}: createNewSubcategoryProps) => {
  try {
    const { subCategories, newSubcategory } = getSubcatObjWithCustomSubcat({
      subCategoryData,
      selectedCategory,
      newSubcategoryName,
    });
    const categoryMap: CategoryMapObj = {
      isCustomMap: true,
      categories: cloneDeep(categoryData),
      subCategories,
    };
    await postCategoryMap(categoryMap);
    return newSubcategory;
  } catch {}
};

interface getSubcatObjWithCustomSubcatProps {
  subCategoryData: SubCategory[];
  selectedCategory: string;
  newSubcategoryName: string;
}

export const getSubcatObjWithCustomSubcat = ({
  subCategoryData,
  selectedCategory,
  newSubcategoryName,
}: getSubcatObjWithCustomSubcatProps) => {
  const subCategories = createSubcategoryObj(subCategoryData);
  const newSubcategory = createSubcatObjForMap(selectedCategory, newSubcategoryName);
  subCategories[newSubcategory.subCategory] = newSubcategory;
  return { subCategories, newSubcategory };
};

export const postCategoryMap = async function (
  categoryMap: CategoryMapObj,
  dryRun: boolean = false
) {
  return await post(true, {
    endpoint: "/categoryMap/edit",
    bodyData: {
      dryRun,
      categoryMap,
    },
  });
};
