import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./i18n";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import * as Sentry from "@sentry/react";
import * as Logger from "./vendors/logger";
import * as Monitoring from "./vendors/monitoring";
import * as Attribution from "./vendors/attribution";
import { rehydrateDataFromStorage } from "./redux/actions";

Monitoring.init();
Logger.init();
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_ENV_NAME || process.env.NODE_ENV,
  enabled: process.env.REACT_APP_SENTRY_ENABLED === "true" ? true : false,
  normalizeDepth: 10, // how deep we want state depth to be
});
Attribution.init();
store.dispatch(rehydrateDataFromStorage);

// Strips out Capacitor url headers when on mobile device
const PATH_STRIP_RE = /capacitor:\/\/localhost/;
function normalizeUrl(url: string, pathStripRe: RegExp): string {
  return url.replace(pathStripRe, "~");
}

Sentry.addGlobalEventProcessor((data: any) => {
  if (data.culprit) {
    data.culprit = normalizeUrl(data.culprit, PATH_STRIP_RE);
  }
  const stacktrace =
    data.stacktrace ||
    (data.exception && data.exception.values && data.exception.values[0].stacktrace);
  if (stacktrace) {
    stacktrace.frames.forEach((frame: any) => {
      if (frame.filename !== "[native code]" && frame.filename !== "<anonymous>") {
        frame.filename = normalizeUrl(frame.filename, PATH_STRIP_RE);
      }
    });
  }
  return data;
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
