import React, { useEffect, useState } from "react";
import { IonAlert, useIonViewDidEnter } from "@ionic/react";
import { RouteProps } from "react-router-dom";
import { trackEvent } from "../../../vendors/monitoring";
import "./onboarding.scss";
import Template, { OnboardingCopy } from "./Template";
import { useTranslation } from "react-i18next";
import { get } from "../../../utils/apiOps";
import { saveUserProperties, setLoading } from "../../../redux/actions";
import { PAGE_URLS } from "../../../constants";
import { UserMemberTypes } from "../../../redux/reducers/users";
import { useDispatch } from "../../../redux/hooks";
import { OnboardingNavHeader } from "./OnboardingNavHeader";
import { OnboardingProgress } from "./OnboardingProgress";
import { useNavigation } from "../../../utils/useNavigation";
import { setToastMessage } from "../../../redux/actions/ui";

const pageLocation = "enter-invite";

interface EnterInviteProps extends Partial<RouteProps> {
  inviteCode: string;
}

export const EnterInvite: React.FC<EnterInviteProps> = (props: EnterInviteProps) => {
  const { navigate } = useNavigation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openInviteAlert, setOpenInviteAlert] = useState<boolean>(false);
  const inviteCode = new URLSearchParams(props?.location?.search).get("inviteCode")! as string;

  useEffect(() => {
    if (inviteCode) {
      // if it exists (e.g. came from link, put it in)
      handleInviteCode(inviteCode);
    }
  }, [inviteCode]);

  const handleInviteCode = (inviteCode: string) => {
    dispatch(setLoading(true));
    get(true, {
      endpoint: "/validateInviteCode",
      params: {
        inviteCode,
      },
    })
      .then(({ data }) => {
        dispatch(
          saveUserProperties({
            firstName: data.requestedTo.firstName,
            email: data.requestedTo.emailId,
            hhIdDefault: data.houseHoldId,
            userName: data.requestedTo.firstName,
            memberType: UserMemberTypes.P2,
            inviteCode,
            requestedBy: data.requestedBy,
          })
        );
        navigate(PAGE_URLS.SIGNUP_NAME, "forward");
      })
      .catch((e) => {
        const error_message = typeof e?.data === "string" ? e.data : t("apiErrNoResponse");
        trackEvent("Enter_invite_code_failed", { category: "onboard", error: error_message });
        dispatch(setToastMessage(error_message));
      })
      .finally(() => {
        trackEvent("Enter_invite_code", { category: "onboard" });
        dispatch(setLoading(false));
      });
  };

  const progressBar = <OnboardingProgress step={2} />;

  return (
    <Template
      header={
        <OnboardingNavHeader
          defaultHref={PAGE_URLS.SIGNUP_HAS_PARTNER}
          pageLocation={pageLocation}
          desktopTitle={progressBar}
          showBackButton
        />
      }
      handlePrimary={() => {}}
      primaryRouterLink={PAGE_URLS.SIGNUP_NAME}
      primaryTestId="invite_no"
      primaryCopy="no"
      onboardingStep={2}
      showSecondary={true}
      secondaryCopy="yes"
      handleSecondary={() => setOpenInviteAlert(true)}
      secondaryTestId="invite_yes"
    >
      <OnboardingCopy title="hasCode_title" copy_1="hasCode_1" />
      <IonAlert
        isOpen={openInviteAlert}
        onDidDismiss={() => setOpenInviteAlert(false)}
        header={t("enterInviteCodeHeader")}
        message={t("enterInviteCodeMsg")}
        inputs={[
          {
            name: "inviteCode",
            type: "text",
          },
        ]}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: t("submit"),
            cssClass: "submitBtn",
            handler: (e) => handleInviteCode(e.inviteCode),
          },
        ]}
      />
    </Template>
  );
};
