import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IonText,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonTextarea,
} from "@ionic/react";
import { chevronDown, chevronForward } from "ionicons/icons";
import { editHouseholdFinancialProfile } from "../../../../redux/actions";
import { PAGE_URLS } from "../../../../constants";
import { useDispatch, useSelector } from "../../../../redux/hooks";
import { getHouseholdDataState } from "../../../../redux/selectors";
import { PageTemplate, Section, NavHeader } from '../../../shared/PageTemplate';

export const SharedFinancesHelp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hh = useSelector(getHouseholdDataState);
  const hhProfile = hh?.hhProfiles[0] || {};
  const financialProfile = hhProfile.financialProfile || {};
  const help = financialProfile.help || {};
  const [isOpen, setIsOpen] = useState(false);
  const handleHelpUpdate = (slice: any) => {
    dispatch(
      editHouseholdFinancialProfile([
        {
          ...hhProfile,
          financialProfile: {
            ...financialProfile,
            help: {
              ...help,
              ...slice,
            },
          },
        },
      ])
    );
  };
  return (
    <PageTemplate
      header={<NavHeader title={t("profile")} defaultHref={PAGE_URLS.MORE_PROFILE_SHARED_FINANCES} />}
    >
      <Section>
        <IonText className="finances-header">
          <h2 className="fy-section-title-border">{t("hhFinancesHelp")}</h2>
          <p>{t("hhFinancesHelpDef")}</p>
        </IonText>
        {Object.keys(help).map((helpTopic) => {
          if (helpTopic === "other") {
            return (
              <div key={helpTopic}>
                <IonItem
                  lines="none"
                  detail
                  detailIcon={!isOpen ? chevronForward : chevronDown}
                  className="other-field"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <IonLabel>{t(`other`)}</IonLabel>
                </IonItem>
                <IonTextarea
                  rows={4}
                  cols={2}
                  className={isOpen ? "other-goals-text-after ion-margin-bottom" : "other-goals-text"}
                  placeholder={t("help_hh-finances_placeholder")}
                  value={help[helpTopic]}
                  onIonChange={(e) => handleHelpUpdate({ [helpTopic]: e.detail.value! })}
                ></IonTextarea>
              </div>
            );
          }
          return (
            <IonItem lines="none" key={helpTopic}>
              <IonLabel>{t(`hhFinances_${helpTopic}Help`)}</IonLabel>
              <IonCheckbox
                checked={help[helpTopic]}
                onIonChange={(e) => handleHelpUpdate({ [helpTopic]: e.detail.checked! })}
              />
            </IonItem>
          );
        })}
      </Section>
    </PageTemplate>
  );
};
