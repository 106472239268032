import React, { useEffect, useState } from "react";
import { IonTitle, IonToolbar, IonButtons, IonGrid, IonRow, IonCol } from "@ionic/react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PAGE_URLS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountsState,
  getCategoryState,
  getTransactionRulesState,
  getUsersState,
} from "../../../redux/selectors";
import { RuleItem, RulesResponse, TransactionRuleTypes } from "../../../types/transactionRules";
import { findAccountById } from "../../../redux/reducers/accounts";
import { getRuleSharedStatus, getRulesText } from "../../../utils/transactions";
import { cloneDeep } from "lodash";
import { RuleDetailItem } from "./RuleDetailItem";
import "./ManageRulesDetail.scss";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";

interface ManageRulesDetailUrlParams {
  sourceInstitutionId: string;
  ruleType: TransactionRuleTypes;
}

export const ManageRulesDetail: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { allBalances } = useSelector(getAccountsState);
  const { rulesData } = useSelector(getTransactionRulesState);
  const { userId } = useSelector(getUsersState);
  const { subCategoryData, categoryData } = useSelector(getCategoryState);
  const { sourceInstitutionId, ruleType } = useParams<ManageRulesDetailUrlParams>();

  const [rules, setRules] = useState<RuleItem[]>([]);

  useEffect(() => {
    if (!sourceInstitutionId || !ruleType) {
      history.push(PAGE_URLS.MORE_SETTINGS_RULES);
    }
    if (
      !allBalances ||
      !rulesData ||
      allBalances.hh?.all.accts.length +
        allBalances.user?.all.accts.length +
        allBalances.partner?.all.accts.length ===
        0
    ) {
      return;
    }

    // create a flattened rules array
    const types: string[] = [];
    const rules = cloneDeep(rulesData)
      .filter((a: RulesResponse) => {
        if (sourceInstitutionId === "all") {
          return a.accountInfo._id === null;
        } else {
          return a.accountInfo.sourceInstitutionId === sourceInstitutionId;
        }
      })
      .filter((a: RulesResponse) => a.rules && a.rules.length > 0)
      .flatMap((account: RulesResponse) => {
        return account.rules
          .filter((r) => !r.isDeleted)
          .map((r) => {
            if (r.accountId) {
              r.accountInfo = findAccountById(allBalances, r.accountId);
            }
            r.instId = (r.accountInfo && r.accountInfo.sourceInstitutionBaseId) || null;
            types.push(r.type);
            return r;
          });
      });

    // pull out the different rule types and institutions
    const distinctTypes: string[] = [];
    types.map((t) => {
      if (!distinctTypes.includes(t)) {
        distinctTypes.push(t);
      }
    });

    // const distinctTypes = [...new Set(types)];
    // create array with all the institutions for each rule type i.e, [ (cat) [Chase, BofA], (yom) [Citi] ]
    const institutions = distinctTypes.map((t) =>
      rules.filter((r: RuleItem) => r.type === t).map((r: RuleItem) => r.instId)
    );

    const distinctInst = institutions.map((institution) => {
      const distinct: any[] = [];
      institution.map((i: string) => {
        if (!distinct.includes(i)) {
          distinct.push(i);
        }
      });

      // const distinct = [...new Set(institution)];
      const newArr = distinct.filter((i) => i).sort();
      if (distinct.includes(null)) {
        newArr.unshift(null);
      }
      return newArr;
    });

    // create an array with Types (cat, yom) > Institutions (BofA, Chase) > Rules
    const typeGrouped = distinctTypes
      .filter((t) => t === ruleType)
      .map((t, ind) => {
        return distinctInst[ind].map((i) =>
          rules.filter((r: RuleItem) => r.type === t && r.instId === i && !r.isDeleted)
        );
      });

    const newRules: RuleItem[] = [];
    typeGrouped.map((r) => {
      r.map((r2) => {
        r2.map((r3: RuleItem) => {
          r3.ruleText = getRulesText(r3, categoryData, subCategoryData, t);
          if (ruleType === TransactionRuleTypes.YOM) {
            const info = getRuleSharedStatus(r3.updateInfo, userId);
            r3.isOwner = info.isOwner;
            r3.privType = info.privType;
            r3.avatarDisplay = info.avatarDisplay;
          }
          newRules.push(r3);
        });
      });
    });

    setRules(newRules);
  }, [allBalances, sourceInstitutionId, rulesData]);

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={<NavHeader defaultHref={PAGE_URLS.MORE_SETTINGS_RULES} title={t("manageRules")} />}
    >
      <Section>
        <IonGrid>
          <IonRow>
            <IonCol>
              <h1 className="font-lg">
                {ruleType === TransactionRuleTypes.YOM ? t("person") : t(ruleType)} {t("rules")} -{" "}
                {rules.length > 0 && rules[0].accountInfo
                  ? rules[0].accountInfo.institutionName
                  : t("allAccounts")}
              </h1>
            </IonCol>
          </IonRow>
          {rules.length > 0 ? (
            rules.map((rule, index) => (
              <RuleDetailItem key={index} ruleId={rule._id} showDelete={true} />
            ))
          ) : (
            <IonRow>
              <IonCol className="ion-text-center">
                <h3 className="font-sm">{t("noRulesThisAccount")}</h3>
                <h3 className="font-sm">
                  {t(ruleType === TransactionRuleTypes.CATEGORY ? "noCategoryRules" : "noYomRules")}
                </h3>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </Section>
    </PageTemplate>
  );
};
