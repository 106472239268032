import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { BUDGET_SHARED_TYPE, CATEGORY_CONSTANTS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { getBudgetAnalyticsState } from "../../../redux/selectors";
import { BudgetItem, BudgetObj } from "../../../types";
import { closeCircleOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import numeral from "numeral";
import dayjs from "dayjs";
import "./IncomeCard.scss";
import { HideCardAlert } from "./HideCardAlert";
import { trackEvent } from "../../../vendors/monitoring";
import { getTransactionLink } from "../../../utils/transUtils";

export const IncomeCard: React.FC = () => {
  const { t } = useTranslation();
  const effectiveDate = dayjs().startOf("month").format("YYYY-MM-DD");

  //redux state
  const { budgetsArray } = useSelector(getBudgetAnalyticsState);

  //state
  const [budgetAnalyticsForDate, setBudgetAnalyticsForDate] = useState<BudgetObj>();
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    const budgetAnalyticsForThisDate = budgetsArray.find(
      (item: BudgetItem) => item.budgetAnalyticsDate === effectiveDate
    );
    if (!budgetAnalyticsForThisDate) {
      return;
    }
    const dataForChart = budgetAnalyticsForThisDate[BUDGET_SHARED_TYPE.HOUSEHOLD]?.budget;
    if (dataForChart) {
      setBudgetAnalyticsForDate(dataForChart);
    }
  }, [budgetsArray, effectiveDate, budgetAnalyticsForDate]);

  const handleHideButtonClick = (event: any) => {
    event.stopPropagation();
    setAlertOpen(true);
  };

  return budgetAnalyticsForDate && budgetAnalyticsForDate.Income.amountActual < 0 ? (
    <IonCard
      className="income-card"
      routerLink={getTransactionLink("advancedSearch", {
        budgetMonth: effectiveDate,
        isPrivate: false,
        categoryFilter: CATEGORY_CONSTANTS.INCOME,
      })}
      onClick={() => trackEvent("view_seeIncome_budget", { category: "seeIncome" })}
      data-testid="IncomeCard"
    >
      <IonButtons className="income-card-close" onClick={(e) => handleHideButtonClick(e)}>
        <IonButton>
          <IonIcon icon={closeCircleOutline} className="income-card-close-icon" />
        </IonButton>
      </IonButtons>
      <IonCardHeader>
        <IonCardTitle className="ion-text-center income-card-header half-padding">
          {t("incomeThisMonth")}
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent className="ion-text-center">
        <h1>{numeral((budgetAnalyticsForDate?.Income.amountActual || 0) * -1).format("$0,0")}</h1>
      </IonCardContent>
      <HideCardAlert open={alertOpen} close={() => setAlertOpen(false)} removedCard="income" />
    </IonCard>
  ) : null;
};
