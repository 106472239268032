import React, { useState } from "react";
import { IonButton, IonCol, IonIcon, IonInput, IonItem, IonLabel, IonRow } from "@ionic/react";
import { Recategorization } from "./Recategorization";
import {
  getCatDisplayName,
  getCategoryObject,
  getSubcatDisplayName,
} from "../../../utils/categorizationUtils";
import { useSelector } from "react-redux";
import { getCategoryState } from "../../../redux/selectors";
import { Transaction, SplitTransactionType } from "../../../types/transactions";
import { useTranslation } from "react-i18next";
import { close } from "ionicons/icons";
import "./SplitTransaction.scss";
import images from "../../../assets/images/categorization";
import { CategoryImg } from "../../shared/Miscellaneous/CategoryImg";
import { categoryState } from "../../../redux/reducers/category";

interface SplitTransactionItemProps {
  splitTransaction: SplitTransactionType;
  transactionDetail: Transaction;
  handleCatChange: (category: string, subCategory: string, uuid: string) => void;
  handleInputChange: (amount: string, uuid: string) => void;
  uuid: string;
  removeSplitTransaction: (uuid: string) => void;
}

export const SplitTransactionItem: React.FC<SplitTransactionItemProps> = (props) => {
  const {
    splitTransaction,
    transactionDetail,
    handleCatChange,
    handleInputChange,
    uuid,
    removeSplitTransaction,
  } = props;
  const { t } = useTranslation();
  type TransactionModalType = "" | "RECATEGORIZATION";
  const recategorizationImg: any = {
    ...images,
  };

  //redux state
  const { categoryData, subCategoryData }: categoryState = useSelector(getCategoryState);

  //state
  const [openModal, setOpenModal] = useState<TransactionModalType>();
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  return (
    <>
      <IonRow className="transaction-detail-row ion-align-items-center ion-margin-bottom">
        <IonCol size="1">
          {uuid ? (
            <IonIcon
              icon={close}
              onClick={() => setShowDeleteButton(!showDeleteButton)}
              className="splitTransaction-delete-icon"
            />
          ) : null}
        </IonCol>
        <IonCol
          onClick={() => setOpenModal("RECATEGORIZATION")}
          className="splitTransaction-category text-no-wrap"
          size="5"
        >
          <CategoryImg
            category={getCategoryObject(splitTransaction.category, categoryData)}
            imgProps={{ className: "splitTransaction-category-icon ion-margin-end" }}
          />
          <h6 className="font-sm ion-no-margin">
            {t(getCatDisplayName(splitTransaction.category, categoryData) || "category")}
            {" | "}
            {t(
              getSubcatDisplayName(splitTransaction.subCategory, subCategoryData) || "subCategory"
            )}
          </h6>
        </IonCol>
        <IonCol size="2" className="ion-text-end">
          <IonLabel>$</IonLabel>
        </IonCol>
        <IonCol size="4" className="ion-text-end ion-padding-end splitTransaction-input-column">
          {showDeleteButton ? (
            <IonButton
              className="splitTransaction-delete-button"
              onClick={() => {
                removeSplitTransaction(uuid);
                setShowDeleteButton(false);
              }}
            >
              Delete
            </IonButton>
          ) : (
            <IonInput
              type="number"
              placeholder="0"
              value={splitTransaction.amount}
              readonly={splitTransaction.readonly}
              onIonChange={(e) => handleInputChange(e.detail.value || "", uuid)}
              className={splitTransaction.readonly ? "" : "splitTransaction-input-amount"}
            />
          )}
        </IonCol>
      </IonRow>
      {openModal === "RECATEGORIZATION" ? (
        <Recategorization
          onClose={(type) => setOpenModal(type as TransactionModalType)}
          transaction={transactionDetail}
          sendApi={false}
          handleCatChange={handleCatChange}
          uuid={uuid}
        />
      ) : null}
    </>
  );
};
