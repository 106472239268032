import React from "react";
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonFooter,
  IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import images from "../../../assets/images/illustrations";
import { PAGE_URLS } from "../../../constants";

export const SpendingChallengeCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonCard data-testid="newSpendingChallengeCard">
      <IonCardHeader className="ion-text-center">
        <img src={images.spendingChallenge} alt="spending-challenge" className="ion-margin" />
        <IonCardTitle>{t("createSpendingChallenge")}</IonCardTitle>
        <h3 className="ion-margin-horizontal">{t("createSpendingChallengeSub")}</h3>
      </IonCardHeader>
      <IonFooter className="ion-no-border ion-margin-bottom">
        <IonToolbar no-border className="ion-text-center">
          <IonButton
            routerLink={PAGE_URLS.SPENDING_CHALLENGES_NEW}
            expand="block"
            size="large"
            className="ion-margin-horizontal"
            data-testid="createNewSpendingBtn"
          >
            {t("getStarted")}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonCard>
  );
};
