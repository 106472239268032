import React, { useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryState } from "../../../redux/selectors";
import { Category, CategoryMapObj, SubCategory, SubcategoryObjForApi } from "../../../types";
import { cloneDeep } from "lodash";
import {
  getAllCategorizationData,
  refreshCategoryDependencies,
  setLoading,
} from "../../../redux/actions";
import { postCategoryMap, getCatDisplayName } from "../../../utils/categorizationUtils";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import { useResponsiveInterface } from "../../../hooks/useResponsiveInterface";
import { trackEvent } from "../../../vendors/monitoring";

interface DeleteSubcategoryModalProps {
  isOpen: boolean;
  onClose: any;
  subCategoryToDelete?: string;
}

export const DeleteSubcategoryModal: React.FC<DeleteSubcategoryModalProps> = ({
  isOpen,
  onClose,
  subCategoryToDelete = "",
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const _interface = useResponsiveInterface("alert");
  const { categoryData, subCategoryData } = useSelector(getCategoryState);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>();

  const deleteSubcategory = () => {
    if (!selectedSubcategory) {
      return;
    }
    dispatch(setLoading(true));
    const subCategories: SubcategoryObjForApi = {};
    cloneDeep(subCategoryData).map((s: SubCategory) => {
      if (s.subCategory === subCategoryToDelete) {
        s.isDeleted = true;
        s.remappedTo = selectedSubcategory;
      }
      subCategories[s.subCategory] = s;
      return null;
    });
    const categoryMap: CategoryMapObj = {
      isCustomMap: true,
      categories: cloneDeep(categoryData),
      subCategories,
    };
    postCategoryMap(categoryMap)
      .then(() => {
        dispatch(getAllCategorizationData());
        onClose(false);
      })
      .then(() => dispatch(refreshCategoryDependencies()))
      .catch()
      .finally(() => {
        trackEvent("click_deleteSubcategory", { category: "editCategories" });
        dispatch(setLoading(false));
      });
  };

  return (
    <IonModal isOpen={isOpen} cssClass="deleteCategoryModal" onDidDismiss={() => onClose(false)}>
      <ModalHeader title={t("deleteSubcategoryModalTitle")} />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-center">
              {t("deleteSubcategoryModalMsg1")}
              <br />
              <br />
              {t("deleteSubcategoryModalMsg2")}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonList>
          <IonItem lines="none">
            <IonLabel>{t("subcategory")}</IonLabel>
            <IonSelect
              slot="end"
              interface={_interface}
              onIonChange={({ detail }) => setSelectedSubcategory(detail.value)}
              data-testid="DeleteSubcategoryModal_subcategory"
              value={selectedSubcategory}
            >
              {subCategoryData
                .filter(
                  (s: SubCategory) =>
                    !s.isDeleted && !s.isDeactivated && s.subCategory !== subCategoryToDelete
                )
                .map((s: SubCategory) => {
                  return (
                    <IonSelectOption value={s.subCategory} key={s.subCategory}>
                      {s.displayName} ({getCatDisplayName(s.category, categoryData)})
                    </IonSelectOption>
                  );
                })}
            </IonSelect>
          </IonItem>
        </IonList>
        <div className="ion-text-center">
          <IonButton fill="clear" slot="start" onClick={() => onClose(false)}>
            {t("cancel")}
          </IonButton>
          <IonButton
            color="danger"
            slot="end"
            onClick={() => deleteSubcategory()}
            data-testid="DeleteSubcategoryModal_delete"
          >
            {t("delete")}
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};
