import { components, operations } from "./schema";

export type offerApiResp =
  operations["api_offers_getForUser"]["responses"]["200"]["content"]["application/json"];
export type billsAuditDataTypeApi =
  operations["api_offers_getForUser"]["responses"]["200"]["content"]["application/json"]["data"]["bills"][0] & {
    buttonText?: string;
  };
export type billsAuditDataType = Array<billsAuditDataTypeApi>;

export type userInfoType = components["schemas"]["OffersForUserSchema"]["userInfo"];
export type billersListApiRes =
  operations["api_offers_rules_getAllOrOne"]["responses"]["200"]["content"]["application/json"];

export type potentialSavingsApiResp =
  operations["api_billshark_potentialSavings"]["responses"]["200"]["content"]["application/json"];

export type billsharkBillerDetailsApiResp =
  operations["api_billshark_billerDetails"]["responses"]["200"]["content"]["application/json"];
export type billsharkBillerDetailsType = components["schemas"]["BillsharkBillersSchema"];

export type billsharkCreateBillApiResp =
  operations["api_billshark_createBill"]["responses"]["200"]["content"]["application/json"];

export type billsharkGetS3UrlApiResp =
  operations["api_aws_s3SignedUrl"]["responses"]["200"]["content"]["application/json"];

export type createBillsharkBillType = {
  offersRulesId: string;
  billsharkProviderId: string;
  currentBillAmount: number;
  isPrivate: boolean;
  honeyfiTransactionId?: string;
};

export type seletectedBillerInfoType = {
  displayName: string;
  estimatedSavings?: number;
  offersRulesId: string | null;
  billsharkProviderId: string | null;
  currentBillAmount?: number | null;
  isPrivate?: boolean;
  honeyfiTransactionId?: string;
  billsharkBillerDetails?: billsharkBillerDetailsType;
  billLogo?: string;
  billsharkBillDetails?: billsAuditDataType[0];
};

export type billsAuditGetStartedButton = {
  buttonText: string;
  buttonLink: null | string;
  baStep: BillsAuditSteps;
  createBill: boolean;
};

export type createBillsharkUserType = {
  email: string;
  legalFirstName: string;
  legalLastName: string;
  mobile: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
  dob: string;
  ssn4?: string;
};

export enum BillsAuditBillsharkStatus {
  NEW = "NEW",
  WAITING_DOCS = "WAITING_DOCS",
  SUBMITTED = "SUBMITTED",
  COMPLETED = "COMPLETED",
}

export enum BillsAuditSteps {
  NEW = "NEW",
  ENTER_USER = "ENTER_USER",
  ENTER_PAYMENT = "ENTER_PAYMENT",
  UPLOAD_DOCS = "UPLOAD_DOCS",
  COMPLETE = "COMPLETE",
}
