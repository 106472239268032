import React from "react";
import { IonButton, IonCol, IonFooter, IonGrid, IonRow, IonText, IonToolbar } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import numeral from "numeral";
import { PAGE_URLS } from "../../../constants";
import { BillsAuditSteps, createBillsharkBillType } from "../../../types/billsAudit";
import { billStatusTriage } from "../../../utils/billsAuditUtils";
import { getBillsAuditDataState } from "../../../redux/selectors";
import { post } from "../../../utils/apiOps";
import { getBillsAuditData, setLoading, updateSelectedBillInfo } from "../../../redux/actions";
import images from "../../../assets/images/illustrations";
import "./BillsAuditGetStarted.scss";
import { NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { useViewport } from "../../../hooks/useViewport";
import { trackEvent } from "../../../vendors/monitoring";

export const BillsAuditGetStarted: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const { selectedBillerInfo } = useSelector(getBillsAuditDataState);

  const startBillAuditForBiller = async () => {
    try {
      dispatch(setLoading(true));
      const triageResp = await billStatusTriage(selectedBillerInfo.billsharkProviderId!);
      const bodyFields: createBillsharkBillType = {
        offersRulesId: selectedBillerInfo.offersRulesId!,
        billsharkProviderId: selectedBillerInfo.billsharkProviderId!,
        currentBillAmount: selectedBillerInfo.currentBillAmount!,
        isPrivate: selectedBillerInfo.isPrivate!,
        honeyfiTransactionId: selectedBillerInfo.honeyfiTransactionId,
      };
      const { billsharkBillsId } = await post(true, {
        endpoint: "/billshark/createBill",
        bodyData: bodyFields,
      });
      trackEvent("continue_billsAudit", { category: "analyzeBills" });

      await (dispatch(getBillsAuditData()) as any);
      dispatch(updateSelectedBillInfo(selectedBillerInfo.billsharkProviderId!));
      history.replace(triageResp.buttonLink!);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  // make sure they should be here
  billStatusTriage(selectedBillerInfo.billsharkProviderId!).then((triageResp) => {
    if (triageResp.baStep !== BillsAuditSteps.NEW) {
      return <Redirect to={PAGE_URLS.BILLS_AUDIT_MAIN} />;
    }
  });

  const footerSection = (
    <IonFooter className="ion-no-border">
      <IonToolbar no-border className="ion-text-center">
        <IonButton
          color="primary"
          data-testid="ba_how_submit"
          size="large"
          className="ion-margin-start ion-margin-bottom"
          onClick={startBillAuditForBiller}
        >
          {t("billsAuditLetsDoIt")}
        </IonButton>
      </IonToolbar>
    </IonFooter>
  );

  return (
    <PageTemplate
      pageProps={{ id: "BillsAuditGetStarted" }}
      contentProps={{ className: "ion-padding" }}
      header={<NavHeader title={t("billsAuditHomeGetStarted")} />}
      footer={isMobile ? footerSection : undefined}
    >
      <Section>
        <IonGrid className="ion-text-center font-md">
          <IonRow>
            <IonCol>
              <img id="GetStartedIconImage" src={images.billsAuditBillshark} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p>
                {t("billsAuditGetStartedLine1", { billerName: selectedBillerInfo.displayName })}
              </p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <img id="GetStartedIconImage" src={images.billsAuditWallet} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p>{t("billsAuditGetStartedLine2")}</p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <img id="GetStartedIconImage" src={images.billsAuditCouch} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p>{t("billsAuditGetStartedLine3")}</p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText color="secondary">
                <h2 className="font-xl">
                  {numeral(selectedBillerInfo.estimatedSavings).format("$0,0")}
                </h2>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p>{t("billsAuditGetStartedLine4")}</p>
            </IonCol>
          </IonRow>
        </IonGrid>
        {isMobile ? null : footerSection}
      </Section>
    </PageTemplate>
  );
};
