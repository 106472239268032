import React from 'react'
import { IonCard, IonCardContent } from '@ionic/react';
import { ResponsiveBar } from '@nivo/bar'
import numeral from 'numeral';
import dayjs from 'dayjs';

import './NetWorthChart.scss'

import { MonthlyBalance } from '../../../utils/netWorthUtils';
import { NET_WORTH_FACTOR } from '../../../constants';

const commonProps = {
  margin: { top: 32, bottom: 80 },
  padding: 0.45,
}
type NetWorthChartProps = {
  data: MonthlyBalance[];
  keyBy: typeof NET_WORTH_FACTOR.netWorth | typeof NET_WORTH_FACTOR.assets | typeof NET_WORTH_FACTOR.debts;
  onSelect?: (month: string) => void;
  selectedMonth?: string;
}

export const NetWorthChart: React.FC<NetWorthChartProps> = props => {
  const { data, keyBy = NET_WORTH_FACTOR.netWorth, onSelect, selectedMonth } = props;

  const formatTick = (month: string) => {
    const item = data.find((i) => i.month === month);
    if (!item) return [];
    const format = dayjs(item.month).isBefore(dayjs().startOf("year")) ? "MMM `YY" : "MMM";
    const handleClick = () => onSelect && onSelect(month);
    return [
      <tspan key="month" className="month-tick" fill="#000000" onClick={handleClick}>
        {dayjs(item.month).format(format)}
      </tspan>,
      <tspan key="value" className="value" x="0" y="22" fill="#000000" onClick={handleClick}>
        {numeral(item[keyBy as never]).format("$0,0")}
      </tspan>,
    ];
  }

  return <div className="net-worth-chart">
    <ResponsiveBar
      data={data}
      keys={[keyBy]}
      indexBy="month"
      colors={d => !onSelect || d.indexValue === selectedMonth ? "#1C7FFF" : "rgb(28,127,255, 0.3)"}
      layout="vertical"
      enableGridX={false}
      enableGridY={false}
      enableLabel={false}
      axisLeft={null}
      axisBottom={{
        tickPadding: 16,
        tickSize: 0,
        format: formatTick,
      }}
      isInteractive={false}
      markers={[
        {
          axis: 'y',
          value: 0,
        }
      ]}
      onClick={(data) => onSelect && onSelect(data.indexValue as string)}
      {...commonProps}
    />
  </div>
}