import { IonItem, IonLabel, IonList, IonPage, IonText } from "@ionic/react";
import numeral from "numeral";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteChildrenProps } from "react-router";
import { useSelector } from "../../../redux/hooks";
import { getUserFundingInfo } from "../../../redux/selectors";
import { FundingInfo } from "../../../types";
import { useCurrentGoal } from "../../../utils/useCurrentGoal";
import { TransactionList } from "./TransactionList";
import { Goal } from "../../../types/goals";
import { PAGE_URLS } from "../../../constants";
import { useNavigation } from "../../../utils/useNavigation";
import { NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { useInterestRate } from "../../../hooks/useInterestRate";
import { Field } from "../../shared/Field/Field";

// helper reduce function callback:
const sum = (a: number, b: FundingInfo["fundingAccount"]) => a + b.amount;

export const GoalTransactions: React.FC<RouteChildrenProps<{ isUser: string }>> = (props) => {
  const { isUser } = props.match?.params as any;
  const { goBack } = useNavigation();
  const goal = useCurrentGoal() as Goal;
  const _isUser = isUser === "1";
  const fundingInfo = useSelector(getUserFundingInfo(goal.id, _isUser));
  const { showRate } = useInterestRate();
  const { t } = useTranslation();

  if (!fundingInfo || !goal.transfers) {
    // Still loading
    return <IonPage />; // we need to return a page to trigger the useCurrentGoals ionDidEnter hook
  }

  if (!fundingInfo.fundingAccount) {
    // Funding account not yet linked.
    goBack(PAGE_URLS.GOALS_EDIT.replace(":goalId", goal.id));
    return <IonPage />; // we need to return a page to trigger the useCurrentGoals ionDidEnter hook
  }

  const account = fundingInfo.fundingAccount as FundingInfo["fundingAccount"];
  const relevantTransfers = goal.transfers.rows.filter((t) => t.ownedByViewer === _isUser);
  const totalSavingsBonuses = relevantTransfers.filter((t) => t.isSavingsBonus).reduce(sum, 0);
  // Changed from legacy logic since `toAccountId` doesn't exist and `fromAccountId` is often null:
  const totalDeposits = relevantTransfers.filter((t) => t.baseType === "CREDIT").reduce(sum, 0);
  const totalWithdrawals = relevantTransfers.filter((t) => t.baseType === "DEBIT").reduce(sum, 0);

  return (
    <PageTemplate
      header={
        <NavHeader
          title={t("balances")}
          desktopTitle={t("balancesAndActivity")}
          defaultHref={PAGE_URLS.GOALS_EDIT.replace(":goalId", goal.id)}
        />
      }
    >
      <Section>
        <IonList>
          <h2 className="font-title-3 fy-section-title-border ion-margin-start">{t("balances")}</h2>
          <Field label={t("deposits")}>
            <IonText>{numeral(totalDeposits).format("$0,0.00")}</IonText>
          </Field>
          {/* We are no longer showing any reference to savings bonuses to new users. See https://stratfs.atlassian.net/browse/IB-1193 */}
          {showRate || (totalSavingsBonuses && totalSavingsBonuses > 0) ? (
            <IonItem lines="none">
              <IonLabel>{t("savingsBonuses")}</IonLabel>
              <IonText slot="end">{numeral(totalSavingsBonuses).format("$0,0.00")}</IonText>
            </IonItem>
          ) : null}
          <Field label={t("withdrawals")}>
            <IonText>{numeral(-totalWithdrawals).format("$0,0.00")}</IonText>
          </Field>
        </IonList>
      </Section>
      <Section>
        <TransactionList
          account={account}
          transactions={relevantTransfers}
          dateFormat="dddd, MMM D"
          includeAccountName={false}
        />
      </Section>
    </PageTemplate>
  );
};
