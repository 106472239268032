import { IonButton, IonContent, IonFooter } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Confetti } from "../Confetti/Confetti";
import { HighLight } from "../Highlight/HighLight";
import { Modal } from "../Modal/Modal";
import images from "../../../assets/images/illustrations";
import { CelebrationModal } from "../Modal/CelebrationModal";

type ChallengeModalProps = {
  onClose: () => void;
  open: boolean;
};

export const NewChallengeModal: React.FC<ChallengeModalProps> = (props) => {
  const { open, onClose } = props;
  const { t } = useTranslation();
  return (
    <CelebrationModal open={open} onClose={onClose} buttonText={t("wooHoo")}>
      <img src={images.celebrationImg} alt="celebration" />
      <h1>{t("wayToGo")}</h1>
      <h4>
        <span>{t("spendingChallengeCelebrateMessageP1")}</span>
        <HighLight>{t("spendingChallengeCelebrateMessageP2")}</HighLight>
        <span>{t("spendingChallengeCelebrateMessageP3")}</span>
      </h4>
    </CelebrationModal >
  );
};
