import React, { useState } from "react";
import { IonIcon, IonContent, IonFooter } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import "./GoalsInfoModal.scss";
import { Modal } from "../../shared/Modal/Modal";
import images from "../../../assets/images/illustrations";
import { NavButton } from "../../shared/PageTemplate";
import { useInterestRate } from "../../../hooks/useInterestRate";
import { openFAQ } from "../../../vendors/intercom";
import { trackEvent } from "../../../vendors/monitoring";

export const GoalsInfoModal: React.FC = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { showRate, interestRate } = useInterestRate();

  return (
    <>
      <NavButton
        icon={<IonIcon icon={informationCircleOutline} />}
        label={t("moreInfo")}
        onClick={() => setIsOpen(!isOpen)}
        className="w-100"
      />
      <Modal open={isOpen} onClose={() => setIsOpen(false)} title={<>{t("howGoalsWork")}</>}>
        <IonContent className="ion-padding ion-padding goals-info-modal">
          <div className="image-container">
            <img src={images.hiker} />
          </div>
          <br />
          <h5 className="font-md margin-top-24">
            {t("whenYouSave")}{" "}
            <strong>
              <u>{t("fdicInsured")} </u>
            </strong>
            {showRate && (
              <>
                {t("andEarnsA")}{" "}
                <strong>
                  <u>{t("percentSavingsBonus", { interestRate })}</u>
                </strong>
              </>
            )}
          </h5>
          <h3 className="font-title-3 margin-top-24 ion-padding-top">{t("heresHowItWorks")}</h3>
          <h5 className="font-md margin-top-24">
            <strong>
              {t("pickAGoal")}
              {" - "}
            </strong>
            {t("anythingFromAVacation")}
          </h5>
          <h5 className="font-md margin-top-24">
            <strong>
              {t("pickASavingsRule")}
              {" - "}
            </strong>
            {t("eitherAPortionOfIncome")}
          </h5>
          <h5 className="font-md margin-top-24">
            <strong>
              {t("saveAutomatically")}
              {" - "}
            </strong>
            {t("weTransferMoneyToBankPartner")}
          </h5>
        </IonContent>
        <IonFooter className="ion-no-border ion-text-center ion-padding">
          <h5 className="d-flex ion-justify-content-center ion-align-items-center font-md ion-padding-bottom">
            <span className="">{t("moreQuestions")}&nbsp;</span>
            <span
              className="weblink"
              onClick={() => {
                openFAQ();
                trackEvent("Tapped FAQ button", {
                  pageLocation: "GoalsInfoModal",
                });
              }}
            >
              {t("readOurFAQ")}
            </span>
          </h5>
        </IonFooter>
      </Modal>
    </>
  );
};
