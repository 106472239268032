import React, { useEffect } from "react";
import {
  IonBackButton,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
  IonText,
  IonButtons,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_URLS } from "../../../constants";
import { getNotificationPref, setLoading, setToastMessage } from "../../../redux/actions";
import { getNotificationPrefState } from "../../../redux/selectors";
import { NOTIFICATION_TYPES } from "../../../redux/reducers/notificationPreferences";
import { useUserName } from "../../shared/User/useUserName";
import { PageTemplate, Section, NavHeader } from '../../shared/PageTemplate';

export const Notifications: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { singlePlayerMode } = useUserName();
  const dispatch = useDispatch();
  const notificationPreferences = useSelector(getNotificationPrefState);
  useEffect(() => {
    dispatch(setLoading(true));
    (dispatch(getNotificationPref()) as any)
      .then(() => { })
      .catch((error: any) => dispatch(setToastMessage(error)))
      .finally(() => dispatch(setLoading(false)));
  }, [dispatch]);

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={<NavHeader
        defaultHref={PAGE_URLS.MORE_SETTINGS}
        title={t("settings")}
      />}
    >
      <Section>
        <IonList lines="none">
          {!singlePlayerMode && (
            <IonItem
              routerLink={`${PAGE_URLS.MORE_SETTINGS_NOTIFS_EDIT.replace(
                ":notificationType",
                NOTIFICATION_TYPES.PARTNER_ACTIVITY.toString()
              )}`}
              data-testid={`ManageNotificationType-${NOTIFICATION_TYPES.PARTNER_ACTIVITY.toString()}`}
            >
              <IonLabel>{t("notifTypePartnerActivity")}</IonLabel>
              {notificationPreferences.typesDisabled.includes(
                NOTIFICATION_TYPES.PARTNER_ACTIVITY
              ) && <IonText color="danger">{t("off")}</IonText>}
              {!notificationPreferences.typesDisabled.includes(
                NOTIFICATION_TYPES.PARTNER_ACTIVITY
              ) && <IonText>{t("on")}</IonText>}
            </IonItem>
          )}
          <IonItem
            routerLink={`${PAGE_URLS.MORE_SETTINGS_NOTIFS_EDIT.replace(
              ":notificationType",
              NOTIFICATION_TYPES.ACCOUNT_ALERT.toString()
            )}`}
            data-testid={`ManageNotificationType-${NOTIFICATION_TYPES.ACCOUNT_ALERT.toString()}`}
          >
            <IonLabel>{t("notifTypeAccountAlert")}</IonLabel>
            {notificationPreferences.typesDisabled.includes(NOTIFICATION_TYPES.ACCOUNT_ALERT) && (
              <IonText color="danger">{t("off")}</IonText>
            )}
            {!notificationPreferences.typesDisabled.includes(NOTIFICATION_TYPES.ACCOUNT_ALERT) && (
              <IonText>{t("on")}</IonText>
            )}
          </IonItem>
          <IonItem
            routerLink={`${PAGE_URLS.MORE_SETTINGS_NOTIFS_EDIT.replace(
              ":notificationType",
              NOTIFICATION_TYPES.NEW_TRANSACTIONS.toString()
            )}`}
            data-testid={`ManageNotificationType-${NOTIFICATION_TYPES.NEW_TRANSACTIONS.toString()}`}
          >
            <IonLabel>{t("notifTypeNewTransactions")}</IonLabel>
            {notificationPreferences.typesDisabled.includes(
              NOTIFICATION_TYPES.NEW_TRANSACTIONS
            ) && <IonText color="danger">{t("off")}</IonText>}
            {!notificationPreferences.typesDisabled.includes(
              NOTIFICATION_TYPES.NEW_TRANSACTIONS
            ) && <IonText>{t("on")}</IonText>}
          </IonItem>
          <IonItem
            routerLink={`${PAGE_URLS.MORE_SETTINGS_NOTIFS_EDIT.replace(
              ":notificationType",
              NOTIFICATION_TYPES.INSIGHT.toString()
            )}`}
            data-testid={`ManageNotificationType-${NOTIFICATION_TYPES.INSIGHT.toString()}`}
          >
            <IonLabel>{t("notifTypeInsights")}</IonLabel>
            {notificationPreferences.typesDisabled.includes(NOTIFICATION_TYPES.INSIGHT) && (
              <IonText color="danger">{t("off")}</IonText>
            )}
            {!notificationPreferences.typesDisabled.includes(NOTIFICATION_TYPES.INSIGHT) && (
              <IonText>{t("on")}</IonText>
            )}
          </IonItem>
          <IonItem
            routerLink={`${PAGE_URLS.MORE_SETTINGS_NOTIFS_EDIT.replace(
              ":notificationType",
              NOTIFICATION_TYPES.BILL_REMINDERS.toString()
            )}`}
            data-testid={`ManageNotificationType-${NOTIFICATION_TYPES.BILL_REMINDERS.toString()}`}
          >
            <IonLabel>{t("notifTypeBillReminders")}</IonLabel>
            {notificationPreferences.typesDisabled.includes(NOTIFICATION_TYPES.BILL_REMINDERS) && (
              <IonText color="danger">{t("off")}</IonText>
            )}
            {!notificationPreferences.typesDisabled.includes(NOTIFICATION_TYPES.BILL_REMINDERS) && (
              <IonText>{t("on")}</IonText>
            )}
          </IonItem>
          <IonItem
            routerLink={`${PAGE_URLS.MORE_SETTINGS_NOTIFS_EDIT.replace(
              ":notificationType",
              NOTIFICATION_TYPES.BALANCE.toString()
            )}`}
            data-testid={`ManageNotificationType-${NOTIFICATION_TYPES.BALANCE.toString()}`}
          >
            <IonLabel>{t("notifTypeBalanceUpdates")}</IonLabel>
            {notificationPreferences.typesDisabled.includes(NOTIFICATION_TYPES.BALANCE) && (
              <IonText color="danger">{t("off")}</IonText>
            )}
            {!notificationPreferences.typesDisabled.includes(NOTIFICATION_TYPES.BALANCE) && (
              <IonText>{t("on")}</IonText>
            )}
          </IonItem>
          <IonItem
            routerLink={`${PAGE_URLS.MORE_SETTINGS_NOTIFS_EDIT.replace(
              ":notificationType",
              NOTIFICATION_TYPES.BUDGET_ALERT.toString()
            )}`}
            data-testid={`ManageNotificationType-${NOTIFICATION_TYPES.BUDGET_ALERT.toString()}`}
          >
            <IonLabel>{t("notifTypeBudgetUpdates")}</IonLabel>
            {notificationPreferences.typesDisabled.includes(NOTIFICATION_TYPES.BUDGET_ALERT) && (
              <IonText color="danger">{t("off")}</IonText>
            )}
            {!notificationPreferences.typesDisabled.includes(NOTIFICATION_TYPES.BUDGET_ALERT) && (
              <IonText>{t("on")}</IonText>
            )}
          </IonItem>
        </IonList>
      </Section>
    </PageTemplate>
  );
};
