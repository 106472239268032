import { useSelector, useDispatch } from "react-redux";
import { getHouseholdDataState } from "../../../redux/selectors";
import { useTranslation } from "react-i18next";
import {
  getHouseholdData,
  registerPartner,
  setLoading,
  setToastMessage,
} from "../../../redux/actions";
import { post } from "../../../../src/utils/apiOps";
import { validateEmail } from "../../../../src/utils/formUtils";
import { PAGE_URLS } from "../../../constants";
import { useNavigation } from "../../../utils/useNavigation";
import { useIonAlert } from "@ionic/react";

export function usePartnerInvite(
  inviteType: "reminder" | "newInvite",
  partnerInfo: { name: string; email: string } | null
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { navigate, goBack } = useNavigation();
  const { members } = useSelector(getHouseholdDataState);
  const [present] = useIonAlert();
  const singleMember = members[0] || {};
  const userName = singleMember?.firstName;
  const invites = singleMember.invites || [];
  const partner = invites[0] || {};
  const partnerName = partner?.firstName || partnerInfo?.name;
  const partnerEmail = partner?.emailId || partnerInfo?.email;

  return async () => {
    try {
      let error = null;
      if (!partnerName) error = t("invalidFirstName");
      if (!partnerEmail || !validateEmail(partnerEmail)) error = t("invalidEmail");
      if (error) {
        return dispatch(setToastMessage(error));
      }
      dispatch(setLoading(true));
      // code block for sending registered partners a reminder
      if (inviteType === "reminder") {
        // POST request to send notification
        await post(true, {
          endpoint: "/user/partner/notify",
          bodyData: {
            partnerOneName: userName,
            emailId: partnerEmail,
          },
        });
        dispatch(setToastMessage(t("partnerReminderNotification")));
      }
      // Code block for new partner invites
      if (inviteType === "newInvite") {
        (
          dispatch(
            registerPartner(partnerInfo?.name as string, partnerInfo?.email as string, true)
          ) as any
        ).then(async () => {
          // POST request to send notification
          await post(true, {
            endpoint: "/user/partner/notify",
            bodyData: {
              partnerOneName: userName,
              emailId: partnerEmail,
            },
          });
          present({
            header: t("inviteSentAlertProps_title"),
            message: t("inviteSentAlertProps_msg"),
            buttons: [
              {
                text: t("skip"),
                handler: goBack,
              },
              {
                text: t("updateSettings"),
                handler: () => navigate(PAGE_URLS.MORE_MANAGE_ACCTS, "back"),
              },
            ],
          });
        });
      }
      await post(true, {
        endpoint: "/user/partner/notify",
        bodyData: {
          partnerOneName: userName,
          emailId: partnerEmail,
        },
      });
      // Actions that happen for both invite types
      dispatch(getHouseholdData());
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setLoading(false));
      console.debug(err);
    }
  };
}
