import React from "react";
import { IonSlides, IonSlide, IonCard, IonGrid, IonRow, IonCol } from "@ionic/react";
import { useTranslation } from "react-i18next";
import constants from "../../../constants";
import { useSelector } from "react-redux";
import { getVersionCheckState } from "../../../redux/selectors";
import numeral from "numeral";
import images from "../../../assets/images/welcome_slides";

import { trackEvent } from "../../../vendors/monitoring";

// Optional parameters to pass to the swiper instance.
// See http://idangero.us/swiper/api/ for valid options.
const slideOpts = {
  initialSlide: 0,
  speed: 400,
};

export const WelcomeSlides: React.FC = () => {
  const { t } = useTranslation();
  const { versionCheck } = useSelector(getVersionCheckState);
  const { goalsInfo, subscriptionInfo } = versionCheck;
  const slideImages: any = {
    ...images,
  };

  return (
    <IonSlides
      onIonSlideDidChange={async (event: any) =>
        await event.target
          .getActiveIndex()
          .then((value: any) =>
            trackEvent("view_card" + value + "_onboarding", { category: "onboard" })
          )
      }
      id="slides-container"
      pager={true}
      options={slideOpts}
    >
      {[0, 1, 2, 3].map((i) => {
        return (
          <WelcomeSlide
            key={`slidesTitle_${i}`}
            title={`slidesTitle_${i}`}
            msg={`slidesMsg_${i}`}
            imgSrc={slideImages[`slidesImg_${i}`]}
          />
        );
      })}

      <WelcomeSlide>
        <>
          <h1 className="font-title-3">{t("slidesTitle_4")}</h1>
          {subscriptionInfo.active ? (
            <h4 className="font-md">
              {t("slidesMsg_4", {
                appName: constants.APP_NAME,
                price: numeral(subscriptionInfo.annualAmt).format("$0.00"),
              })}
            </h4>
          ) : (
            <h4 className="font-md">
              {t("slidesMsg_4alt", {
                appName: constants.APP_NAME,
              })}
            </h4>
          )}

          <IonGrid>
            {[0, 1, 2, 3, 4].map((i) => {
              return (
                <IonRow key={`slidesrow_${i}`}>
                  <IonCol size="2">
                    <img alt="benefits" src={slideImages[`benefits_${i}`]} />
                  </IonCol>
                  <IonCol className="ion-text-start font-md ion-align-self-center">
                    {t(`slidesBen_${i}`, {
                      // rate:
                      //   i === 1 &&
                      //   numeral(goalsInfo.interestRate).format(
                      //     goalsInfo.showDecimals ? "0.0%" : "0%"
                      //   ),
                    })}
                  </IonCol>
                </IonRow>
              );
            })}
          </IonGrid>
        </>
      </WelcomeSlide>
    </IonSlides>
  );
};

interface WelcomeSlideProps {
  title?: string;
  msg?: string;
  imgSrc?: any;
}
export const WelcomeSlide: React.FC<WelcomeSlideProps> = ({
  title = "",
  msg = "",
  imgSrc = "slidesImg_0",
  children,
}) => {
  const { t } = useTranslation();
  return (
    <IonSlide>
      <IonCard className="ion-padding">
        {children ? (
          children
        ) : (
          <>
            <img alt={title} src={imgSrc} />
            <h1 className="font-title-3">{t(title)}</h1>
            <h4 className="font-md">{t(msg)}</h4>
          </>
        )}
      </IonCard>
    </IonSlide>
  );
};
