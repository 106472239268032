import React, { useState } from "react";
import { IonContent, IonButton, IonInput, IonLabel, IonRange, IonFooter } from "@ionic/react";
import { useTranslation } from "react-i18next";

import "./AmountInputSlider.scss";

type AmountInputSliderProps = {
  initialValue: number;
  mostCommon?: number;
  max: number;
  min: number;
  step: number;
  onChange: (updatedValue: number) => void;
  format?: string;
  suffix?: string;
  submitText?: string;
  withFooter?: boolean;
  wrapper?: React.FC | React.Component<any, any, any>;
  color?: string;
};

export const AmountInputSlider: React.FC<AmountInputSliderProps> = (props) => {
  const { t } = useTranslation();
  const {
    initialValue,
    mostCommon,
    max,
    min,
    step,
    onChange,
    format = "$0,0",
    suffix = "",
    submitText = t("save"),
    children,
    color,
    wrapper = IonContent,
  } = props;
  const [amount, setAmount] = useState(initialValue ?? mostCommon);
  const Wrapper = wrapper as any;
  return (
    <>
      <Wrapper>
        <div className="amount-input-slider">
          <div className="ion-padding">{children}</div>
          <>
            <div className="input-row ion-padding">
              <h3>{format.includes("$") ? "$" : null}</h3>
              <IonInput
                className="ion-padding ion-border"
                data-testid="amountInput"
                type="number"
                value={amount}
                onBlur={(e) => setAmount(Number(e.target.value))}
                autofocus
              />
              {format.includes("%") ? <strong>% </strong> : null}
              <h3 className="suffix ion-margin">{suffix}</h3>
              <span className="most-common-label font-sm">
                {amount === mostCommon ? `${t("mostCommon")}` : null}
              </span>
            </div>
            <IonRange
              value={amount}
              min={min}
              max={max}
              step={step}
              onIonChange={(e) => setAmount(e.detail.value as number)}
              style={color && { "--range-color": color } as any}
            ></IonRange>
          </>
        </div>
      </Wrapper>
      <IonFooter className="ion-text-center ion-no-border ion-padding">
        <IonButton
          onClick={() => onChange(amount)}
          data-testid="amountSubmitButton"
          size="large"
        >
          {submitText}
        </IonButton>
      </IonFooter>
    </>
  );
};
