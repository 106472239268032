import React, { useState, useEffect } from "react";
import { IonIcon, useIonViewDidEnter } from "@ionic/react";
import { searchOutline, addOutline } from "ionicons/icons";
import {
  TransactionList,
  TransactionAdvancedSearch,
  TransactionAdvancedSearchResult,
  TransactionSearch,
  CreateNewTransaction,
} from "../Transaction";
import { useTranslation } from "react-i18next";
import "./Transaction.scss";
import { useDispatch, } from "react-redux";
import { useLocation } from "react-router";
import { checkToAskForPushPermissions } from "../../../utils/pushUtils";
import { NavButton, NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { useViewport } from "../../../hooks/useViewport";
import { trackEvent } from "../../../vendors/monitoring";
import { extractTransactionSearchParams } from "../../../utils/transUtils";

export const TransactionsTab: React.FC = () => {
  useIonViewDidEnter(() => {
    setTimeout(() => dispatch(checkToAskForPushPermissions()), 3000);
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = useViewport();
  const location = useLocation();

  //state
  const { context } = extractTransactionSearchParams(location.search);
  const [searchSectionPopUp, setSearchSectionPopUp] = useState(context === "search");
  const [advancedSearchIsOpen, setAdvancedSearchIsOpen] = useState(false);
  const [createNewTransactionOpen, setCreateNewTransactionOpen] = useState(false);

  useEffect(() => {
    if (!searchSectionPopUp && context === "search") setSearchSectionPopUp(true);
  }, [context]);

  const searchBar = (
    <TransactionSearch handleAdvancedSearchToggleOpen={() => setAdvancedSearchIsOpen(true)} />
  );
  const advancedSearchDescription = (
    <TransactionAdvancedSearchResult
      handleAdvancedSearchToggleOpen={() => setAdvancedSearchIsOpen(true)}
    />
  );

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={
        <NavHeader
          title={t("tab_transactions")}
          showBackButton={false}
          subheader={context === "advancedSearch" ? advancedSearchDescription : searchBar}
          extraMobileSubheader={
            searchSectionPopUp && context !== "advancedSearch" ? searchBar : undefined
          }
        >
          <NavButton
            icon={<IonIcon icon={addOutline} />}
            label={t("addTransaction")}
            data-testid="createNewTransaction"
            onClick={() => {
              trackEvent("view_addTrans", { category: "addTrans" });
              setCreateNewTransactionOpen(true);
            }}
          />
          {isDesktop ? null : (
            <NavButton
              icon={<IonIcon icon={searchOutline} />}
              data-testid="transactionSearchIcon"
              onClick={() => setSearchSectionPopUp(!searchSectionPopUp)}
              label=""
            />
          )}
        </NavHeader>
      }
    >
      <TransactionAdvancedSearch
        advancedSearchIsOpen={advancedSearchIsOpen}
        handleAdvancedSearchToggleClose={() => setAdvancedSearchIsOpen(false)}
        handleAdvancedSearchActive={() => setSearchSectionPopUp(false)}
        key={advancedSearchIsOpen.toString()}
      />
      {isMobile && context === "advancedSearch" ? advancedSearchDescription : null}
      {createNewTransactionOpen ? (
        <CreateNewTransaction
          createNewTransactionOpen={createNewTransactionOpen}
          handleCreateNewTransactionClose={() => setCreateNewTransactionOpen(false)}
        />
      ) : (
        ""
      )}
      <TransactionList />
    </PageTemplate>
  );
};
