import { BranchInitEvent } from "capacitor-branch-deep-links";
import { SessionData } from "branch-sdk";
import { SET_BRANCH_DATA } from "../actionTypes";

export interface branchDataState {
  lastUpdated: String | null;
  branchResp?: SessionData | BranchInitEvent | null;
}

const initialState: any = {
  lastUpdated: null,
  branchResp: null,
};

interface action {
  type: string;
  payload: SessionData | BranchInitEvent;
}

const branchData = (state: branchDataState = initialState, action: action) => {
  switch (action.type) {
    case SET_BRANCH_DATA: {
      return {
        ...state,
        lastUpdated: new Date().toISOString(),
        branchResp: action.payload as BranchInitEvent | SessionData,
      };
    }

    default: {
      return state;
    }
  }
};

export default branchData;
