/**
 * Overrides the console logs to send to loggly
 */
//@ts-ignore
import { LogglyTracker } from "loggly-jslogger";
import * as Sentry from "@sentry/react";
import { Device, DeviceInfo } from "@capacitor/core";

const logglyKey = process.env.REACT_APP_LOGGLY_CUSTOMER_TOKEN;
const logLevel = process.env.REACT_APP_LOGGLY_LEVEL || "ERROR";
const logLevels = ["LOG", "ERROR", "WARN", "INFO", "DEBUG"];
const logLevelIndex = logLevels.indexOf(logLevel);
let deviceInfoForLogger: Partial<DeviceInfo> = {};

export const logger = new LogglyTracker();

export const init = async () => {
  if (!logglyKey) {
    console.log("log: No loggly key, can not init");
    return;
  }
  const deviceInfo = await Device.getInfo();
  deviceInfoForLogger = {
    operatingSystem: deviceInfo.operatingSystem,
    osVersion: deviceInfo.osVersion,
    name: deviceInfo.name,
    model: deviceInfo.model,
    platform: deviceInfo.platform,
    appVersion: deviceInfo.appVersion,
    appBuild: deviceInfo.appBuild,
  };
  logger.push({
    logglyKey: logglyKey,
    tag: process.env.REACT_APP_LOGGLY_TAG,
  });
  //override the console.XXX
  const orgLog = console.log;
  console.log = (message: any) => {
    sendToLoggly(message, "LOG");
    //Invoke the original console.log
    if (process.env.NODE_ENV !== "production") {
      return orgLog(message);
    }
  };
  const orgInfo = console.info;
  console.info = (message: any) => {
    sendToLoggly(message, "INFO");
    //Invoke the original console.info
    if (process.env.NODE_ENV !== "production") {
      return orgInfo(message);
    }
  };
  const orgDebug = console.debug;
  console.debug = (message: any) => {
    sendToLoggly(message, "DEBUG");
    //Invoke the original console.debug
    if (process.env.NODE_ENV !== "production") {
      return orgDebug(message);
    }
  };
  const orgWarn = console.warn;
  console.warn = (message: any) => {
    sendToLoggly(message, "WARN");
    //Invoke the original console.warn
    if (process.env.NODE_ENV !== "production") {
      return orgWarn(message);
    }
  };
  const orgError = console.error;
  console.error = (message: any) => {
    sendToLoggly(message, "ERROR");
    //Invoke the original console.error
    if (process.env.NODE_ENV !== "production") {
      return orgError(message);
    }
  };
};

const sendToLoggly = (message: any, msgLevel: string) => {
  if (!logglyKey) {
    return;
  }
  // if error also send to Sentry
  if (msgLevel === "ERROR") {
    let isError = false;
    if (message instanceof Error || (message.stack && message.message)) {
      isError = true;
    }
    if (isError) {
      Sentry.captureException(message);
    } else {
      Sentry.captureException(new Error(JSON.stringify(message)));
    }
  }
  const msgLevelIndex = logLevels.indexOf(msgLevel);
  if (msgLevelIndex <= logLevelIndex) {
    logger.push({
      level: msgLevel,
      message,
      timestamp: new Date(),
      userAgent: window.navigator.userAgent,
      deviceInfo: deviceInfoForLogger,
    });
  }
};
