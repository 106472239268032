import React, { useState } from "react";
import {
  IonRadioGroup,
  IonButtons,
  IonIcon,
  IonItem,
  IonRadio,
  IonModal,
  IonContent,
  IonHeader,
  IonToolbar,
  IonText,
} from "@ionic/react";
import { closeOutline, chevronUp, chevronDown } from "ionicons/icons";

import "./ChangeSharingButton.scss";

import { Goal, PrivilegeType } from "../../../types";
import { PRIVILEGE_TYPE_DATA_3, ACCOUNT_OWNERSHIP } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useUserName } from "../../shared/User/useUserName";
import { GoalAvatars } from "./GoalAvatars";
import { Field } from "../../shared/Field/Field";

const privilegeDisplayMap = {
  [PRIVILEGE_TYPE_DATA_3.SHARED]: "meAndPartner",
  [PRIVILEGE_TYPE_DATA_3.JUSTME]: "meKeepItPrivate",
  [PRIVILEGE_TYPE_DATA_3.VISIBLE]: "meButPartnerCanSee",
};

type ChangeSharingButtonProps = {
  goal: Goal;
  onChange: (goal: Partial<Goal>) => void;
  label?: string;
};

export const ChangeSharingButton: React.FC<ChangeSharingButtonProps> = (props) => {
  const { goal, onChange, label = "" } = props;
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const { partnerName } = useUserName();

  return (
    <>
      <Field
        label={label}
        onClick={() => setModalOpen(true)}
        testId="changeSharingButton"
        icon={modalOpen ? chevronUp : chevronDown}
      >
        <div className="d-flex ion-align-items-center mr-auto">
          <GoalAvatars privilegeType={goal.privilegeType} sizeStyle="sm" className="inline-logo" />
          {t(privilegeDisplayMap[goal.privilegeType] as any, { partnerName })}
        </div>
      </Field>
      <IonModal
        isOpen={modalOpen}
        cssClass="change-sharing-button"
        onDidDismiss={() => setModalOpen(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons>
              <IonIcon
                onClick={() => setModalOpen(false)}
                icon={closeOutline}
                size="large ion-padding"
              />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonText>
            <h1 className="ion-padding">{t("whoIsThisFor")}</h1>
          </IonText>
          <IonRadioGroup value={goal.privilegeType}>
            {(
              [
                PRIVILEGE_TYPE_DATA_3.SHARED,
                PRIVILEGE_TYPE_DATA_3.VISIBLE,
                PRIVILEGE_TYPE_DATA_3.JUSTME,
              ] as PrivilegeType[]
            ).map((privilegeType) => (
              <IonItem
                key={privilegeType}
                lines="none"
                onClick={() => {
                  onChange({
                    privilegeType,
                    // @ts-ignore
                    ownership:
                      privilegeType === PRIVILEGE_TYPE_DATA_3.SHARED
                        ? ACCOUNT_OWNERSHIP.JOINT
                        : ACCOUNT_OWNERSHIP.INDIVIDUAL,
                  });
                  setModalOpen(false);
                }}
              >
                <GoalAvatars privilegeType={privilegeType} />
                <IonText>{t(privilegeDisplayMap[privilegeType] as any, { partnerName })}</IonText>
                <IonRadio slot="end" value={privilegeType} />
              </IonItem>
            ))}
          </IonRadioGroup>
        </IonContent>
      </IonModal>
    </>
  );
};
