import React from "react";
import {
  IonList,
  IonButton,
  IonContent,
  IonFooter,
  IonToolbar,
  IonText,
} from "@ionic/react";
import numeral from "numeral";
import { useTranslation } from "react-i18next";

import { Goal } from "../../../types";
import { formatAccountNumber } from "../../../utils/formatUtils";
import { useSelector, useDispatch } from "../../../redux/hooks";
import { makeTransfer } from "../../../redux/actions/goal";
import { trackEvent } from "../../../vendors/monitoring";
import { Field } from "../../shared/Field/Field";

type GoalTransferConfirmationProps = {
  type: "WITHDRAWAL" | "DEPOSIT";
  goal: Goal;
  amount: number;
  onSaveEnd: () => void;
};

export const GoalTransferConfirmation: React.FC<GoalTransferConfirmationProps> = (props) => {
  const { type, goal, amount, onSaveEnd } = props;
  const fundingAccount = goal?.viewerFundingInfo?.fundingAccount || {};
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const goalAccount = (
    <div>
      <div>{goal.name}</div>
    </div>
  );
  const bankAccount = (
    <div>
      <div>
        {fundingAccount?.accountNickname || fundingAccount?.accountName}{" "}
        {formatAccountNumber(fundingAccount?.accountNumber || "")}
      </div>
    </div>
  );

  return (
    <>
      <IonContent>
        <h3 className="ion-margin ion-padding-top">
          {t(type === "WITHDRAWAL" ? "goalsAccountDebitedToday" : "bankAccountDebitedToday")}
        </h3>
        <IonList>
          <Field label={t("from")}>
            <IonText>
              {type === "WITHDRAWAL" ? goalAccount : bankAccount}
            </IonText>
          </Field>
          <Field label={t("to")}>
            <IonText>
              {type === "WITHDRAWAL" ? bankAccount : goalAccount}
            </IonText>
          </Field>
          <Field label={t("transferAmount")}>
            <IonText>
              {numeral(amount).format("$0,0.00")}
            </IonText>
          </Field>
        </IonList>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar className="ion-text-center">
          <IonButton
            fill="solid"
            size="large"
            className="ion-margin"
            onClick={() => {
              trackEvent(type === "WITHDRAWAL" ? "withdraw_money_goal" : "add_money_goal", {
                category: "goals",
              });
              dispatch(makeTransfer(goal.id, amount, type)).then(onSaveEnd);
            }}
          >
            {t(type === "WITHDRAWAL" ? "confirmWithdrawal" : "confirmDeposit")}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
