import React, { useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import numeral from "numeral";
import { IonList, IonListHeader, IonTitle, IonText, IonItem, IonIcon } from "@ionic/react";
import "./BalanceList.scss";
import { AccountBalance, MonthlyBalance } from "../../../utils/netWorthUtils";
import { AccountLogo } from "../AccountsTab/AccountLogo";
import { AccountCard } from "../AccountsTab/AccountCard";
import { NET_WORTH_FACTOR } from "../../../constants";
import { documentOutline, walletOutline } from "ionicons/icons";
import { ActionSheet } from "../../shared/PageTemplate/ActionSheet";
import { getTransactionLink } from "../../../utils/transUtils";
import { formatAccountNumber } from "../../../utils/formatUtils";

const icons = {
  [NET_WORTH_FACTOR.assets]: walletOutline,
  [NET_WORTH_FACTOR.debts]: documentOutline,
};

type BalanceListProps = {
  data: MonthlyBalance;
  category: string;
  onCategoryChange: (category: string) => void;
  isPrivate: boolean;
};

export const BalanceList: React.FC<BalanceListProps> = (props) => {
  const { data, category, onCategoryChange, isPrivate } = props;
  const [[selectedAccount, popoverEvent], setSelectedAccount] = useState<
    [AccountBalance | null, Event | undefined]
  >(() => [null, undefined]);
  const { t } = useTranslation();
  const history = useHistory();

  const debtFilter = (account: any) => account.balance < 0;
  const assetFilter = (account: any) => account.balance >= 0;
  const visibleAccounts = data.accounts.filter(
    category === NET_WORTH_FACTOR.debts ? debtFilter : assetFilter
  );

  return (
    <>
      <IonList className="balance-list">
        <IonListHeader>
          <IonTitle className="ion-no-padding">
            {t("month_" + category, { month: dayjs(data.month).format("MMMM") })}
          </IonTitle>
        </IonListHeader>
        {category === NET_WORTH_FACTOR.netWorth ? (
          [NET_WORTH_FACTOR.assets, NET_WORTH_FACTOR.debts].map((type) => (
            <IonItem lines="none" key={type} button onClick={() => onCategoryChange(type)}>
              <IonText slot="start">
                <IonIcon icon={icons[type as keyof typeof icons]} className="type-icon" />
                <span className="font-md">{t(type)}</span>
              </IonText>
              <IonText
                slot="end"
                className={`amount font-md ion-text-end ion-no-margin ${
                  data[type as never] > 0 && "positive"
                }`}
              >
                {numeral(data[type as never]).format("$0,0")}
              </IonText>
            </IonItem>
          ))
        ) : (
          <>
            {visibleAccounts.map((account) => (
              <IonItem
                key={account.accountId}
                lines="none"
                className="account-item"
                onClick={(e) => {
                  e.persist();
                  setSelectedAccount([account, (e as any) as Event]);
                }}
              >
                <AccountCard account={account} />
              </IonItem>
            ))}
            <IonItem
              key="net-worth"
              lines="none"
              button
              onClick={() => onCategoryChange(NET_WORTH_FACTOR.netWorth)}
              className="ion-margin-top font-md"
            >
              <IonText>{t("backToNetWorth")}</IonText>
            </IonItem>
          </>
        )}
      </IonList>
      <ActionSheet
        openEvent={popoverEvent}
        onDidDismiss={() => setSelectedAccount([null, undefined])}
        hideButton
        buttons={[
          {
            text: t("seeTransactions"),
            handler: () => {
              history.push(
                getTransactionLink("advancedSearch", {
                  accountIds: `["${selectedAccount?.accountId}"]`,
                  startDate: dayjs(data.month).utc().format("YYYY-MM-DD"),
                  endDate: dayjs(data.month).endOf("month").utc().format("YYYY-MM-DD"),
                  isPrivate: isPrivate || "both",
                })
              );
              setSelectedAccount([null, undefined]);
            },
          },
        ]}
      />
    </>
  );
};
