import { RulesResponse } from "../../types/transactionRules";
import { SET_TRANSACTION_RULES, TRANSACTION_RULES_LOADING } from "../actionTypes";

const initialState: TransactionRulesState = {
  loading: false,
  rulesData: [],
};

export interface TransactionRulesState {
  loading: boolean;
  rulesData: RulesResponse[];
}

interface action {
  type: string;
  payload: any;
}

const transactionRules = (state: TransactionRulesState = initialState, action: action) => {
  switch (action.type) {
    case SET_TRANSACTION_RULES: {
      return {
        ...state,
        rulesData: action.payload,
        loading: false,
      };
    }
    case TRANSACTION_RULES_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default transactionRules;
