import sortBy from "lodash/sortBy";
import { GOAL_STATUS } from "../../constants";
import { Goal, ViewerGoalsResponse, AccountInfo } from "../../types";
import {
  GOAL_DATA_LOADING,
  GOAL_DETAIL_LOADING,
  SET_ALL_VIEWER_GOALS,
  SET_GOAL_DETAIL,
  SET_VIEWER_ACCOUNT_INFO,
} from "../actionTypes";

interface goalsState {
  viewerGoals: Goal[];
  accountInfo: AccountInfo;
  goalDetails: {
    [key: string]: Goal;
  };
  loading: boolean;
}

const initialState: goalsState = {
  viewerGoals: [] as Goal[],
  accountInfo: {} as AccountInfo,
  goalDetails: {},
  loading: true,
};

interface action {
  type: string;
  payload: {};
}

const goals = (state: goalsState = initialState, action: action) => {
  switch (action.type) {
    case SET_ALL_VIEWER_GOALS: {
      return {
        ...state,
        loading: false,
        viewerGoals: prepViewerGoals(action.payload as ViewerGoalsResponse),
        goalDetails: {},
        accountInfo: {
          ...state.accountInfo,
          hasSavingsAccount:
            (action.payload as any)?.hasSavingsAccount ?? state.accountInfo.hasSavingsAccount,
          hasExternalFundingAccount:
            (action.payload as any)?.hasExternalFundingAccount ??
            state.accountInfo.hasExternalFundingAccount,
        } as AccountInfo,
      };
    }
    case SET_VIEWER_ACCOUNT_INFO: {
      return {
        ...state,
        accountInfo: action.payload as AccountInfo,
      };
    }
    case GOAL_DATA_LOADING: {
      return {
        ...state,
        loading: action.payload as boolean,
      };
    }
    case GOAL_DETAIL_LOADING: {
      return {
        ...state,
        goalDetails: {
          ...state.goalDetails,
          [action.payload as string]: {
            loading: true,
          } as Goal,
        },
      };
    }
    case SET_GOAL_DETAIL: {
      return {
        ...state,
        goalDetails: {
          ...state.goalDetails,
          [(action.payload as Goal).id]: {
            ...addBalance(action.payload as Goal),
            loading: false,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

const calcCurBal = (goal: Goal) => {
  var a = calcAccountBal(goal);
  var b = calcPendBal(goal);
  return a + b;
};

const calcAccountBal = (goal: Goal) => {
  // from corepro docs:
  // Total balance of account, including funds that have holds placed on them.
  // Represents all settled transactions to date.
  // This is the balance used for interest accrual calculations,
  // if this is an interest-bearing account.
  var a = 0;
  if (goal && goal.viewerFundingInfo && goal.viewerFundingInfo != null) {
    a += goal.viewerFundingInfo.balance;
  }
  if (goal && goal.nonViewerFundingInfo && goal.nonViewerFundingInfo != null) {
    a += goal.nonViewerFundingInfo.balance;
  }
  return a;
};

const calcAvailBal = (goal: Goal) => {
  // from corepro docs:
  // Balance available for immediate withdrawal.
  // Settling a transaction for a DDA account will typically credit
  // the availableBalance immediately. However, savings or FBO accounts may
  // have a "deposit hold time" or a "new customer hold time", meaning there
  // could be a length of time between when the transaction is settled and
  // the corresponding funds are made available for withdrawal.
  var a = 0;
  if (goal && goal.viewerFundingInfo && goal.viewerFundingInfo != null) {
    a += goal.viewerFundingInfo.availableBalance;
  }
  if (goal && goal.nonViewerFundingInfo && goal.nonViewerFundingInfo != null) {
    a += goal.nonViewerFundingInfo.availableBalance;
  }
  return a;
};

const calcPendBal = (goal: Goal) => {
  // from corepro docs:
  // Balance of pending deposit transactions.
  // When a pending deposit transaction settles, it will debit this balance.
  // Deposit transactions which settle immediately will never affect this balance.
  var a = 0;
  if (goal && goal.viewerFundingInfo && goal.viewerFundingInfo != null) {
    a += goal.viewerFundingInfo.pendingBalance;
  }
  if (goal && goal.nonViewerFundingInfo && goal.nonViewerFundingInfo != null) {
    a += goal.nonViewerFundingInfo.pendingBalance;
  }
  return a;
};

const prepViewerGoals = (viewerGoals: ViewerGoalsResponse) => {
  const statusOrder = [
    GOAL_STATUS.ACTIVE,
    GOAL_STATUS.NEW,
    GOAL_STATUS.PAUSED,
    GOAL_STATUS.COMPLETED,
    GOAL_STATUS.CLOSED,
    GOAL_STATUS.PFRAUD,
  ];

  const { activeGoals, newGoals, completedGoals, closedGoals, pfraudGoals } = viewerGoals;
  const allGoals = activeGoals.rows
    .concat(newGoals.rows)
    .concat(completedGoals.rows)
    .concat(closedGoals.rows)
    .concat(pfraudGoals.rows);
  return sortBy(allGoals, [
    (goal) => statusOrder.indexOf(goal.status as string),
    (goal) => new Date(goal.targetDate || ""),
  ]).map(addBalance) as Goal[];
};

const addBalance = (goal: Goal) => {
  const curBal = calcCurBal(goal);
  const availBal = calcAvailBal(goal);
  const progress = (curBal / goal.targetAmount) * 100;
  return { ...goal, curBal, availBal, progress };
};

export default goals;
