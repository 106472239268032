const images = {
  Everyday_dark: require("./icon-budget-wallet-shadow.svg"),
  Everyday_light: require("./icon-budget-wallet.svg"),
  Financial_dark: require("./icon-budget-calculator-shadow.svg"),
  Financial_light: require("./icon-budget-calculator.svg"),
  Fun_dark: require("./icon-budget-balloons-shadow.svg"),
  Fun_light: require("./icon-budget-balloons.svg"),
  Goals_dark: require("./icon-budget-mountain-shadow.svg"),
  Goals_light: require("./icon-budget-mountain.svg"),
  Home_dark: require("./icon-budget-home-shadow.svg"),
  Home_light: require("./icon-budget-home.svg"),
  Ignore_dark: require("./icon-budget-ignore-shadow.svg"),
  Ignore_light: require("./icon-budget-ignore.svg"),
  Income_dark: require("./icon-budget-income-shadow.svg"),
  Income_light: require("./icon-budget-income.svg"),
  Personal_dark: require("./icon-budget-handHeart-shadow.svg"),
  Personal_light: require("./icon-budget-handHeart.svg"),
  Transportation_dark: require("./icon-budget-car-shadow.svg"),
  Transportation_light: require("./icon-budget-car.svg"),
  custom_1_dark: require("./icon-budget-tag-shadow.svg"),
  custom_1_light: require("./icon-budget-tag.svg"),
  custom_2_dark: require("./icon-budget-tools-shadow.svg"),
  custom_2_light: require("./icon-budget-tools.svg"),
  custom_3_dark: require("./icon-budget-star-shadow.svg"),
  custom_3_light: require("./icon-budget-star.svg"),
  custom_4_dark: require("./icon-budget-folder-shadow.svg"),
  custom_4_light: require("./icon-budget-folder.svg"),
  custom_5_dark: require("./icon-budget-document-shadow.svg"),
  custom_5_light: require("./icon-budget-document.svg"),
  custom_6_dark: require("./icon-budget-coins-shadow.svg"),
  custom_6_light: require("./icon-budget-coins.svg"),
  custom_7_dark: require("./icon-budget-lightbulb-shadow.svg"),
  custom_7_light: require("./icon-budget-lightbulb.svg"),
  custom_8_dark: require("./icon-budget-briefcase-shadow.svg"),
  custom_8_light: require("./icon-budget-briefcase.svg"),
};

export default images;
