import * as types from "../actionTypes";
import { get, put } from "../../utils/apiOps";
import { Dispatch } from "redux";
import { setUserPropertiesFromHouseholdData } from "../actions";

export const setHouseholdMembersData = (householdData: any) => {
  return {
    type: types.SET_HOUSEHOLD_MEMBER_DATA,
    payload: householdData,
  };
};

export const setHouseholdDataLoading = (status: boolean = true) => {
  return {
    type: types.HOUSEHOLD_DATA_LOADING,
    payload: status,
  };
};

export const clearHouseholdData = () => {
  return {
    type: types.CLEAR_HOUSEHOLD_DATA,
  };
};

export const setHouseholdProfileData = (householdData: any) => {
  return {
    type: types.SET_HOUSEHOLD_PROFILE_DATA,
    payload: householdData,
  };
};
export const getHouseholdData = () => async (dispatch: Dispatch<any>) => {
  dispatch(setHouseholdDataLoading(true));
  const [hhData, hhProfile] = await Promise.all([
    get(true, {
      endpoint: "/user/householdData",
    }),
    get(true, {
      endpoint: "/user/hhProfile",
    }),
  ]);
  dispatch(setHouseholdProfileData(hhProfile.data));
  dispatch(setHouseholdMembersData(hhData.data));
  dispatch(setUserPropertiesFromHouseholdData(hhData.data));
};

export const editHouseholdProfile = (profiles: any) => async (dispatch: Dispatch<any>) => {
  const profile = profiles[0];
  const toUpdate = {
    anniversary: profile.anniversary,
    relationshipStage: profile.relationshipStage,
    name: profile.name,
  };
  await put(true, { endpoint: "user/editHh", bodyData: toUpdate });
  dispatch(setHouseholdProfileData(profiles));
};

export const editHouseholdFinancialProfile = (profiles: any) => async (dispatch: Dispatch<any>) => {
  const profile = profiles[0];
  const toUpdate = {
    financialProfile: profile.financialProfile,
  };
  await put(true, {
    endpoint: "user/editHh",
    bodyData: toUpdate,
  });
  dispatch(setHouseholdProfileData(profiles));
};
