// @ts-nocheck
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import PlaidLink from "react-plaid-link";
import { plaidSettings } from "../../../vendors/plaid";
import { getAccountDataFromToken, setToastMessage } from "../../../redux/actions";
import { BankLinkFormat } from "./LinkInstitutionWrapper";
import { useTranslation } from "react-i18next";
import { trackEvent } from "../../../vendors/monitoring";

interface PlaidLinkWrapperProps {
  format: BankLinkFormat;
  institution: {
    source: string;
    id: string;
    name: string;
  };
  onClose: any;
  publicToken?: string;
  fromOnboarding?: boolean;
}

const PlaidLinkWrapper: React.FC<PlaidLinkWrapperProps> = ({
  institution,
  format,
  onClose,
  children,
  publicToken,
  fromOnboarding = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const linkSuccess = useRef(false);

  const onSuccess = (token, metadata) => {
    // send token to server
    console.debug("token", token);
    console.debug("metadata", metadata);
    dispatch(getAccountDataFromToken(token, !fromOnboarding));
    linkSuccess.current = true;
    trackEvent("linkSuccess", metadata);
  };

  const onEventHandler = (eventName, metadata) => {
    console.debug(eventName);
    trackEvent(eventName, metadata);
    if (eventName === "HANDOFF") {
      trackEvent("finish_addFinAcct", { category: "balances" });
      onClose(linkSuccess.current);
    }
  };

  const style = {
    width: "100%",
  };

  return (
    <PlaidLink
      clientName={plaidSettings.clientName}
      env={plaidSettings.env}
      product={plaidSettings.product}
      publicKey={plaidSettings.key}
      token={publicToken}
      onSuccess={onSuccess}
      onEvent={onEventHandler}
      institution={institution.id}
      webhook={plaidSettings.webhook}
      style={style}
      className={`${
        format === BankLinkFormat.QUICKBANK ? "quickbank-wrapper" : "bank-search-item"
      } quickbank-${institution.id}`}
    >
      {children}
    </PlaidLink>
  );
};

export default PlaidLinkWrapper;
