import React from "react";
import { useTranslation } from "react-i18next";
import { NavHeader } from "../../shared/PageTemplate";
import { GoalsInfoModal } from "./GoalsInfoModal";
import { NewGoal } from "./NewGoal";

export const NewGoalPage: React.FC = () => {
  const { t } = useTranslation();
  return <NewGoal header={<NavHeader title={t("createSavingsGoal")} >
    <GoalsInfoModal />
  </NavHeader>} />
};
