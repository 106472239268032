import React, { useState } from "react";
import { IonItem, IonInput, IonLabel, IonText, IonIcon } from "@ionic/react";
import { useUserName } from "../../../../components/shared/User/useUserName";
import { useTranslation } from "react-i18next";
import { AVATAR_DISPLAY } from "../../../../constants";
import Avatars from "../../../shared/Avatar/Avatars";
import { partnerFields } from "../../../../types/user";
import { formattedName } from "../../../../utils/formatUtils";
import "./Me.scss";
import { InputChangeEventDetail } from "@ionic/core";
import { useDispatch } from "../../../../redux/hooks";
import { setToastMessage, updatePartnerInvite } from "../../../../redux/actions";
import { validateEmail } from "../../../../utils/formUtils";
import { createOutline } from "ionicons/icons";

export const Partner: React.FC = () => {
  const {
    partnerName,
    partnerEmail,
    partnerGender,
    partnerMobile,
    inviteCode,
    hasPartner,
    partnerId,
  } = useUserName();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const phoneFormat = partnerMobile.replace(/\D/g, "").match(/(\d{3})(\d{3})(\d{4})/);
  const formattedPhone = phoneFormat
    ? "(" + phoneFormat[1] + ")" + phoneFormat[2] + "-" + phoneFormat[3]
    : partnerMobile;
  const [updatedFields, setUpdatedFields] = useState({
    firstName: partnerName,
    emailId: partnerEmail,
    userId: partnerId,
  });
  const partnerFields: Array<partnerFields> = [
    { label: "firstName", value: formattedName(updatedFields.firstName), canEdit: !hasPartner },
    { label: "emailId", value: updatedFields.emailId, canEdit: !hasPartner },
    { label: "mobile", value: formattedPhone, isPending: !!inviteCode, canEdit: false },
    { label: "gender", value: partnerGender, isPending: !!inviteCode, canEdit: false },
    { label: "partnerInviteCode", value: inviteCode!, isPending: !inviteCode, canEdit: false },
  ];
  const updatePartnerData = (e: CustomEvent<InputChangeEventDetail>, field: string) => {
    setUpdatedFields({ ...updatedFields, [field]: e!.detail.value });
  };
  const handleInviteUpdate = (e: CustomEvent<FocusEvent>) => {
    if (!validateEmail(updatedFields.emailId)) {
      return dispatch(setToastMessage(t("invalidEmail")));
    }
    return dispatch(updatePartnerInvite(updatedFields));
  };
  return (
    <div>
      <Avatars
        className="ion-margin-top ion-margin-bottom"
        sizeStyle="lg"
        avatarType={AVATAR_DISPLAY.PARTNER}
      />
      {partnerFields.map((field) => {
        if (!field.isPending) {
          return (
            <IonItem lines="none" key={`partner_${field.label}`}>
              <IonLabel position="floating">{t(field.label)}</IonLabel>
              <IonInput
                readonly={!field.canEdit}
                value={field.value}
                onIonBlur={(e: CustomEvent<FocusEvent>) => handleInviteUpdate(e)}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) =>
                  updatePartnerData(e, field.label)
                }
              ></IonInput>
              {field.canEdit && (
                <IonIcon className="ion-align-self-end font-md" icon={createOutline} slot="end" />
              )}
            </IonItem>
          );
        }
      })}
      {inviteCode && (
        <div>
          <IonItem lines="none" className="partner-pending-invite">
            <IonText>
              <p>{t("partnerPendingInvite", { partnerName })} </p>
              <p>{t("pendingInviteMessage")}</p>
            </IonText>
          </IonItem>
        </div>
      )}
    </div>
  );
};
