import React, { ReactElement } from "react";
import {
  IonContent,
  IonPage,
  IonToolbar,
  IonFooter,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonProgressBar,
  IonCardContent,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { isPlatform } from "@ionic/core";
import { OnboardingNavHeader } from "./OnboardingNavHeader";
import { SIGNUP_URLS } from "../../../constants";
import { useViewport } from "../../../hooks/useViewport";

interface TemplateProps {
  header?: ReactElement;
  showFooter?: boolean;
  footer?: ReactElement;
  legal?: string | ReactElement;
  primaryCopy?: string;
  handlePrimary?: any;
  primaryRouterLink?: string;
  primaryTestId?: string;
  primaryProps?: any;
  footerDisabled?: boolean;
  staticFooter?: boolean;
  showSecondary?: boolean;
  handleSecondary?: any;
  onboardingStep?: number;
  secondaryCopy?: string;
  secondaryTestId?: string;
  secondaryRouterLink?: string;
  secondaryProps?: any;
}

const Template: React.FC<TemplateProps> = ({
  children,
  header = null,
  showFooter = true,
  footer,
  legal,
  handlePrimary = () => { },
  primaryRouterLink,
  primaryCopy = "next",
  primaryTestId,
  primaryProps = {},
  footerDisabled = false,
  staticFooter = false,
  showSecondary = false,
  handleSecondary = () => { },
  onboardingStep = 0,
  secondaryCopy,
  secondaryTestId,
  secondaryRouterLink,
  secondaryProps = {},
}) => {
  const { isMobile } = useViewport();

  const _footer = footer || (
    <OnboardingFooterButtons
      buttons={
        showFooter
          ? tempMapper({
            primaryCopy,
            handlePrimary,
            primaryRouterLink,
            primaryTestId,
            primaryProps,
            footerDisabled,
            showSecondary,
            handleSecondary,
            secondaryCopy,
            secondaryRouterLink,
            secondaryTestId,
            secondaryProps,
          })
          : []
      }
      legal={legal}
    />
  );

  return (
    <IonPage>
      {header}
      <IonContent fullscreen className={staticFooter ? "static-footer-container" : ""}>
        {isMobile ? (
          <div className="ion-padding ion-margin-top">
            {onboardingStep ? (
              <IonProgressBar color="tertiary" value={onboardingStep / SIGNUP_URLS.length} />
            ) : null}
            {children}
          </div>
        ) : (
          <div className="container">
            <IonCard>
              <IonCardContent>{children}</IonCardContent>
              {_footer}
            </IonCard>
          </div>
        )}
        {isMobile && staticFooter && _footer}
      </IonContent>
      {isMobile && !staticFooter && _footer}
    </IonPage>
  );
};

export default Template;

interface FooterButton {
  text?: string;
  routerLink?: string;
  handler?: any;
  testId?: string;
  disabled?: boolean;
  props?: any;
}

interface OnboardingFooterButtonsProps {
  buttons: FooterButton[];
  legal?: string | ReactElement;
}

export const OnboardingFooterButtons: React.FC<OnboardingFooterButtonsProps> = ({
  buttons,
  legal,
}) => {
  const { t } = useTranslation();
  const [primary, secondary] = buttons;

  return (
    <IonFooter className="ion-no-border fy-feathered">
      {primary && (
        <IonToolbar no-border className="ion-text-center">
          {secondary && (
            <IonButton
              fill="clear"
              size="large"
              onClick={secondary.handler}
              routerLink={secondary.routerLink}
              className="ion-margin-end ion-margin-bottom"
              data-testid={secondary.testId}
              {...secondary.props}
            >
              {t(secondary.text || "ignoreAccount")}
            </IonButton>
          )}
          <IonButton
            disabled={primary.disabled}
            onClick={primary.handler}
            data-testid={primary.testId}
            size="large"
            className={`${secondary && "ion-margin-start"} ion-margin-bottom`}
            routerLink={primary.routerLink}
            {...primary.props}
          >
            {t(primary.text || "")}
          </IonButton>
        </IonToolbar>
      )}
      {legal && (
        <IonToolbar className="ion-text-center bounded" style={{ marginTop: -16 }}>
          {legal}
        </IonToolbar>
      )}
    </IonFooter>
  );
};

interface OnboardingCopyProps {
  title?: string;
  desktopPrefix?: string;
  copy_1?: string;
  copy_2?: string;
}

export const OnboardingCopy: React.FC<OnboardingCopyProps> = ({
  desktopPrefix,
  title,
  copy_1,
  copy_2,
}) => {
  const { t } = useTranslation();

  return (
    <IonGrid>
      <IonRow>
        <IonCol className="ion-padding-horizontal onboarding-copy">
          <h1 className="font-title-3 ion-margin-vertical">
            {desktopPrefix && (
              <span className="hidden-mobile">
                {t(desktopPrefix)}
                <br />
              </span>
            )}
            {title && t(title)}
          </h1>
          {copy_1 && <h4 className="font-md">{t(copy_1)}</h4>}
          {copy_2 && <h4 className="font-md">{t(copy_2)}</h4>}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

type DeprecatedProps = {
  primaryCopy: string;
  handlePrimary?: any;
  primaryRouterLink?: string;
  primaryTestId?: string;
  primaryProps?: any;
  secondaryProps?: any;
  footerDisabled?: boolean;
  showSecondary?: boolean;
  handleSecondary?: any;
  onboardingStep?: number;
  secondaryCopy?: string;
  secondaryTestId?: string;
  secondaryRouterLink?: string;
};

const tempMapper = ({
  primaryCopy,
  handlePrimary,
  primaryRouterLink,
  primaryTestId,
  primaryProps,
  footerDisabled,
  showSecondary,
  handleSecondary,
  secondaryCopy,
  secondaryTestId,
  secondaryRouterLink,
  secondaryProps,
}: DeprecatedProps): FooterButton[] =>
  [
    {
      text: primaryCopy,
      handler: handlePrimary,
      routerLink: primaryRouterLink,
      testId: primaryTestId,
      disabled: footerDisabled,
      props: primaryProps,
    },
    {
      text: secondaryCopy,
      handler: handleSecondary,
      routerLink: secondaryRouterLink,
      testId: secondaryTestId,
      props: secondaryProps,
    },
  ].slice(0, showSecondary ? 2 : 1);
