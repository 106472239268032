import React, { useState } from "react";
import { IonFooter, IonButton } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { PartnerForm } from "../../shared/Partner/PartnerForm";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { useViewport } from "../../../hooks/useViewport";
import { usePartnerInvite } from "../../shared/Partner/usePartnerInvite";

export const InvitePartnerPage: React.FC = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const [partner, setPartner] = useState({ name: "", email: "" });
  const invite = usePartnerInvite("newInvite", partner);
  const formGood = Boolean(partner.name && partner.email);

  const footerSection = (
    <IonFooter className="ion-text-center ion-no-border ion-padding">
      <IonButton onClick={invite} data-testid="invite_btn" disabled={!formGood}>
        {t("invite")}
      </IonButton>
    </IonFooter>
  );

  return (
    <PageTemplate header={<NavHeader />} footer={isMobile ? footerSection : undefined}>
      <Section>
        <PartnerForm partner={partner} onChange={setPartner} />
        {isMobile ? null : footerSection}
      </Section>
    </PageTemplate>
  );
};
