import React, { useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryState } from "../../../redux/selectors";
import { Category, CategoryMapObj, SubCategory, SubcategoryObjForApi } from "../../../types";
import { cloneDeep } from "lodash";
import {
  getAllCategorizationData,
  refreshCategoryDependencies,
  setLoading,
} from "../../../redux/actions";
import { BUDGET_SUPER_CATEGORIES, CATEGORY_CONSTANTS } from "../../../constants";
import "./DeleteCategoryModal.scss";
import { postCategoryMap } from "../../../utils/categorizationUtils";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import { useResponsiveInterface } from "../../../hooks/useResponsiveInterface";
import { trackEvent } from "../../../vendors/monitoring";

interface DeleteCategoryModalProps {
  isOpen: boolean;
  onClose: any;
  categoryShortNameToDelete?: string;
}

export const DeleteCategoryModal: React.FC<DeleteCategoryModalProps> = ({
  isOpen,
  onClose,
  categoryShortNameToDelete = "",
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const _interface = useResponsiveInterface();
  const { categoryData, subCategoryData } = useSelector(getCategoryState);
  const [selectedCategory, setSelectedCategory] = useState<string>();

  const deleteCategory = () => {
    if (!selectedCategory) {
      return;
    }
    dispatch(setLoading(true));
    const subCategories: SubcategoryObjForApi = {};
    cloneDeep(subCategoryData).map((s: SubCategory) => {
      if (s.category === categoryShortNameToDelete) {
        s.category = selectedCategory;
      }
      subCategories[s.subCategory] = s;
    });

    const categories = cloneDeep(categoryData).map((c: Category) => {
      if (c.categoryShortName === categoryShortNameToDelete) {
        c.isDeleted = true;
      }
      return c;
    });

    const categoryMap: CategoryMapObj = {
      isCustomMap: true,
      categories,
      subCategories,
    };
    postCategoryMap(categoryMap)
      .then(() => {
        dispatch(getAllCategorizationData());
        resetStateAndClose();
      })
      .then(() => dispatch(refreshCategoryDependencies()))
      .catch()
      .finally(() => {
        trackEvent("click_deleteCategory", { category: "editCategories" });
        dispatch(setLoading(false));
      });
  };

  const resetStateAndClose = () => {
    setSelectedCategory(undefined);
    onClose(false);
  };

  return (
    <IonModal isOpen={isOpen} cssClass="deleteCategoryModal" onDidDismiss={resetStateAndClose}>
      <ModalHeader title={t("deleteCategoryModalTitle")} />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-center">
              {t("deleteCategoryModalMsg1")}
              <br />
              <br />
              {t("deleteCategoryModalMsg2")}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonList>
          <IonItem lines="none">
            <IonLabel>{t("category")}</IonLabel>
            <IonSelect
              slot="end"
              onIonChange={({ detail }) => setSelectedCategory(detail.value)}
              data-testid="DeleteCategoryModal_category"
              interface={_interface}
              value={selectedCategory}
            >
              {categoryData
                .filter(
                  (c: Category) =>
                    !c.isDeleted &&
                    c.superCategory === BUDGET_SUPER_CATEGORIES.EXPENSES &&
                    c.categoryShortName !== CATEGORY_CONSTANTS.GOALS &&
                    c.categoryShortName !== categoryShortNameToDelete
                )
                .map((c: Category) => {
                  return (
                    <IonSelectOption value={c.categoryShortName} key={c.categoryShortName}>
                      {c.categoryFullName}
                    </IonSelectOption>
                  );
                })}
            </IonSelect>
          </IonItem>
        </IonList>
        <div className="ion-text-center">
          <IonButton fill="clear" slot="start" onClick={() => resetStateAndClose()}>
            {t("cancel")}
          </IonButton>
          <IonButton
            color="danger"
            slot="end"
            onClick={() => deleteCategory()}
            disabled={!selectedCategory}
            data-testid="DeleteCategoryModal_delete"
          >
            {t("delete")}
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};
