import React, { useEffect, useState } from "react";
import {
  IonList,
  IonIcon,
  IonRow,
  IonGrid,
  IonCol,
  IonItem,
  IonLabel,
  IonListHeader,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { PAGE_URLS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { getBillsState } from "../../../redux/selectors";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { Bill, BillsData, BillsURLParams } from "../../../types";
import { cloneDeep, isEqual, startCase } from "lodash";
import { useParams } from "react-router";
import { BudgetMonthHeader } from "./BudgetMonthHeader";
import { addOutline, listOutline } from "ionicons/icons";
import numeral from "numeral";
import { getBills, setLoading } from "../../../redux/actions";
import { useDispatch } from "../../../redux/hooks";
import { BillItem, BillPaidBadge } from "./BillItem";
import { CreateBillModal } from "./CreateBillModal";
import { NavButton, NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import "./BillsDetail.scss";
dayjs.extend(weekOfYear);

type Week = {
  wInd: number;
  days: Day[];
  bills: Bill[];
  amount: number;
  text?: string;
};

type Day = {
  today: boolean;
  notMonth: boolean;
  date: number;
  mObj: any;
  billBubbles?: any[];
};

/**
 * Creates duplicates of bills for cases where bill is at beginning or end of month
 * Purely for viewing
 */
function createDups(billsArr: Bill[], effectiveDate: string) {
  let newBillsArr: Bill[] = [];
  billsArr.map((b) => {
    if (b.frequency === "weekly") {
      let firstDay = 1;
      const dayOfWeek = dayjs(b.startDate).day();
      for (let x = 1; x < 8; x++) {
        if (dayjs(effectiveDate).date(x).day() === dayOfWeek) {
          firstDay = x;
          break;
        }
      }
      for (let m = 0; m * 7 < 32; m++) {
        const newB = JSON.parse(JSON.stringify(b));
        newB.day = firstDay + m * 7;
        newBillsArr.push(newB);
      }
    } else if (b.frequency === "biweekly") {
      let firstDay = 1;
      for (let x = 1; x < 17; x++) {
        const diffDays = dayjs(effectiveDate).date(x).diff(dayjs(b.startDate), "days");
        if (diffDays % 14 === 0) {
          firstDay = x;
          break;
        }
      }
      for (let m = 0; m * 14 < 32; m++) {
        const newB = JSON.parse(JSON.stringify(b));
        newB.day = firstDay + m * 14;
        newBillsArr.push(newB);
      }
    } else {
      newBillsArr.push(b);
    }
  });
  return [...newBillsArr];
}

/**
 * Groups an array of objects by object key/value
 * @param {Array} arr
 * @param {String} key
 */
function groupBy(arr: any[], key: string) {
  return arr.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

/**
 * Adjust for last day of month
 * e.g., due date is 31, but only 30 days in month
 */
function adjustForEndOfMonth(daysObj: any, effectiveDate: string) {
  const eom = dayjs(effectiveDate).endOf("month").date();
  Object.keys(daysObj).map((day) => {
    if (Number(day) > eom) {
      daysObj[eom] = daysObj[eom] ? daysObj[eom].concat(daysObj[day]) : daysObj[day];
      delete daysObj[day];
    }
  });
  return daysObj;
}

/**
 * Groups bills by week and day
 */
const groupBills = function (billsArray: Bill[], effectiveDate: string, weeksArray: Week[]) {
  const newCalData = cloneDeep(weeksArray);
  const newBillsArray = cloneDeep(billsArray);
  const daysObj = groupBy(newBillsArray, "day");
  const g = adjustForEndOfMonth(daysObj, effectiveDate);
  const monthlyTotal = {
    amount: 0,
    paidCt: 0,
    paidAmt: 0,
    dueCt: 0,
    dueAmt: 0,
  };
  newCalData.map((w) => {
    w.bills = [];
    const s = w.days[0];
    const e = w.days[6];

    let start = dayjs(s.mObj);
    let end = dayjs(e.mObj);
    if (s.notMonth) {
      start = dayjs(e.mObj).startOf("month");
    }

    if (e.notMonth) {
      end = dayjs(s.mObj).endOf("month");
    }

    w.text = dayjs(start).format("MMM D") + dayjs(end).format(" - D");

    if (start.isSame(end, "day")) {
      w.text = dayjs(start).format("MMM D");
    }

    w.days.map((d) => {
      d.billBubbles = [];
      if (g[d.date]) {
        g[d.date].map((b: Bill) => {
          if (d.billBubbles) {
            d.billBubbles.push(b);
          }
          if (!d.notMonth) {
            w.bills.push(b);
            w.amount += b.amount;
            monthlyTotal.amount += b.amount;
            if (b.status === "paid") {
              monthlyTotal.paidAmt += b.amount;
              monthlyTotal.paidCt++;
            } else {
              monthlyTotal.dueAmt += b.amount;
              monthlyTotal.dueCt++;
            }
          }
        });
      }
    });
  });
  return { weeksArray: newCalData, monthlyTotal };
};

const generateWeeksArray = (effectiveDate: string, selectWeek: any) => {
  const weeksArray: Week[] = [];
  const endOfMonth = dayjs(effectiveDate).endOf("month");
  const weekdayStart = dayjs(effectiveDate).startOf("month").day();
  const daysInWeek = 7;
  const numWeeks = Math.ceil((endOfMonth.date() + weekdayStart) / daysInWeek);

  for (let wInd = 0; wInd < numWeeks; wInd++) {
    weeksArray.push({
      wInd,
      days: [],
      bills: [],
      amount: 0,
    });
    for (let x = 1; x <= daysInWeek; x++) {
      const dayCalc = x + wInd * daysInWeek - weekdayStart;
      let dayjsObj = dayjs(dayjs(effectiveDate).date(dayCalc));
      if (wInd === 0 && weekdayStart !== 0) {
        if (weekdayStart <= x - 1) {
          dayjsObj = dayjs(dayjs(effectiveDate).date(x - weekdayStart));
        } else {
          dayjsObj = dayjs(
            dayjs(effectiveDate)
              .startOf("month")
              .subtract(weekdayStart - x + 1, "day")
          );
        }
      } else if (dayCalc > endOfMonth.date()) {
        dayjsObj = dayjs(
          dayjs(effectiveDate)
            .startOf("month")
            .add(dayCalc - 1, "day")
        );
      }
      const dObj: Day = {
        today: dayjs().isSame(dayjsObj, "day"),
        notMonth: !dayjs(effectiveDate).isSame(dayjsObj, "month"),
        date: dayjs(dayjsObj).date(),
        mObj: dayjs(dayjsObj),
      };
      weeksArray[wInd].days.push(dObj);
    }
  }
  return weeksArray;
};

export const BillsDetail: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, billsData } = useSelector(getBillsState);
  const { effectiveDate } = useParams<BillsURLParams>();

  const [billsDataForDate, setBillsDataForDate] = useState<BillsData>();
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(() =>
    dayjs(effectiveDate).isSame(dayjs(), "month") ? dayjs().week() - dayjs(effectiveDate).week() : 0
  );
  const [rawWeeksData, setRawWeeksData] = useState<Week[]>([]);
  const [weeksArray, setWeeksArray] = useState<Week[]>([]);
  const [monthlyTotal, setMonthlyTotal] = useState<any>([]);
  const [showCalendar, setShowCalendar] = useState(true);
  const [showCreateBillModal, setShowCreateBillModal] = useState(false);

  const weekdays = ["S", "M", "T", "W", "T", "F", "S"];

  useEffect(() => {
    dispatch(setLoading(loading));
  }, [loading, dispatch]);

  /**
   * Creates data for the calendar
   */
  useEffect(() => {
    if (!billsDataForDate) {
      return;
    }

    const { weeksArray, monthlyTotal } = groupBills(
      billsDataForDate.billsArray,
      effectiveDate,
      rawWeeksData
    );
    if (weeksArray) {
      setWeeksArray(weeksArray);
    }
    if (monthlyTotal) {
      setMonthlyTotal(monthlyTotal);
    }
  }, [effectiveDate, billsDataForDate, billsData]);

  useEffect(() => {
    const billsForThisDate = billsData.find(
      (item: BillsData) => item.effectiveDate === effectiveDate
    );
    if (!billsForThisDate || !billsForThisDate.billsArray) {
      dispatch(getBills({ effectiveDate: dayjs(effectiveDate).format("YYYY-MM-DD") }));
      return;
    }
    if (effectiveDate !== billsDataForDate?.effectiveDate) {
      setRawWeeksData(generateWeeksArray(effectiveDate, setSelectedWeekIndex));
    }
    if (
      effectiveDate !== billsDataForDate?.effectiveDate ||
      billsForThisDate.billsArray.length !== billsDataForDate?.billsArray.length ||
      !isEqual(billsForThisDate.billsArray, billsDataForDate?.billsArray)
    ) {
      setBillsDataForDate(billsForThisDate);
    }
  }, [billsData, effectiveDate]);

  return (
    <PageTemplate
      contentProps={{ fullscreen: true, id: "BillsDetail" }}
      header={
        <NavHeader
          desktopTitle={t("bills")}
          title={<BudgetMonthHeader effectiveDate={effectiveDate} baseUrl={PAGE_URLS.BILLS} allowFutureMonths />}
          subheader="useTitle"
        >
          <NavButton
            icon={<IonIcon icon={listOutline} />}
            label={t(showCalendar ? "listView" : "calendarView")}
            onClick={() => setShowCalendar(!showCalendar)}
            data-testid="toggleCalendar"
          />
          <NavButton
            icon={<IonIcon icon={addOutline} />}
            label={t("createNewBill")}
            onClick={() => setShowCreateBillModal(true)}
            data-testid="createBill"
          />
        </NavHeader>
      }
    >
      {showCalendar ? (
        <Section>
          <IonGrid id="bills-calendar" className="ion-no-padding" data-testid="bills-calendar">
            <IonRow className="week-row">
              {weekdays.map((day, i) => {
                return (
                  <IonCol key={i} className="ion-text-center placeholder-text">
                    <div className="bold font-md">{day}</div>
                  </IonCol>
                );
              })}
            </IonRow>
            {weeksArray.map((week) => {
              return (
                <IonRow
                  onClick={() => setSelectedWeekIndex(week.wInd)}
                  key={week.wInd}
                  className={`week-row font-sm ${week.wInd === selectedWeekIndex && "selected-week"
                    }`}
                >
                  {week.days.map((day, i) => {
                    return (
                      <IonCol
                        key={i}
                        className={`ion-align-self-center ion-text-center ${day.notMonth && "placeholder-text"
                          }`}
                      >
                        <div>{day.date}</div>
                        {day.today && <div className="today primary-text font-md">{day.date}</div>}

                        {day.billBubbles && (
                          <div className="bullets font-md">
                            <div>
                              {day.billBubbles?.map((b, bubbleIndex) => {
                                return (
                                  <div
                                    key={bubbleIndex}
                                    className={`${day.notMonth && "placeholder-text"} ${b.status !== "unpaid" && "money-in"
                                      }`}
                                  >
                                    &bull;
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </IonCol>
                    );
                  })}
                </IonRow>
              );
            })}
          </IonGrid>
        </Section>
      ) : (
        <Section className="bills-monthly-total-list-section">
          <IonList lines="none" id="bills-monthly-total-list" data-testid="bills-monthly-total-list">
            <IonItem>
              <IonLabel>{startCase(t("total"))}</IonLabel>
              <IonLabel className="ion-text-end">
                {numeral(monthlyTotal.amount).format("$0,0")}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                {monthlyTotal.paidCt} <BillPaidBadge className="vertical-align-bottom" />
              </IonLabel>
              <IonLabel className="ion-text-end">
                {numeral(monthlyTotal.paidAmt).format("$0,0")}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                {monthlyTotal.dueCt} {t("due")}
              </IonLabel>
              <IonLabel className="ion-text-end">
                {numeral(monthlyTotal.dueAmt).format("$0,0")}
              </IonLabel>
            </IonItem>
          </IonList>
        </Section>
      )}
      <IonList lines="none" id="bills-item-list" data-testid="bills-item-list">
        {weeksArray
          .filter((week) => !showCalendar || week.wInd === selectedWeekIndex)
          .map((week) => {
            return (
              <Section key={week.wInd}>
                <WeekItem week={week} effectiveDate={effectiveDate} />
              </Section>
            );
          })}
      </IonList>

      <CreateBillModal
        effectiveDate={effectiveDate}
        isOpen={showCreateBillModal}
        onClose={() => setShowCreateBillModal(false)}
      />
    </PageTemplate>
  );
};

interface WeekItemProps {
  week: Week;
  effectiveDate: string;
}

export const WeekItem: React.FC<WeekItemProps> = ({ week, effectiveDate }) => {
  const { t } = useTranslation();

  return (
    <>
      <IonListHeader data-testid={`bills-week-${week.wInd}`}>
        <IonLabel>
          <h1 className="font-title-3">{week.text}</h1>
        </IonLabel>
        <IonLabel className="ion-text-end ion-margin-end font-lg">
          <h1 className="font-xl">{numeral(week.amount * -1).format("$0,0")}</h1>
        </IonLabel>
      </IonListHeader>
      {week.bills.length > 0 ? (
        week.bills.map((bill) => (
          <BillItem
            key={bill._id}
            billId={bill._id}
            effectiveDate={effectiveDate}
            clickable={true}
          />
        ))
      ) : (
        <IonItem>
          <IonLabel>{t("noBills")}</IonLabel>
        </IonItem>
      )}
    </>
  );
};
