import { App, PluginListenerHandle } from "@capacitor/core";

let handle: PluginListenerHandle;

export function useHandleBackBtn() {
  return {
    register: () => {
      handle = App.addListener("backButton", () => {});
    },
    deregister: () => handle?.remove(),
  };
}
