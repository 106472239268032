const images: Record<string, any> = {
  h_ins_9: require("./h_ins_9.svg"),
  ins_127989: require("./ins_127989.svg"),
  ins_3: require("./ins_3.svg"),
  ins_4: require("./ins_4.svg"),
  ins_5: require("./ins_5.svg"),
  ins_7: require("./ins_7.svg"),
  ins_10: require("./ins_10.svg"),
};
export default images;
