import React, { useState } from "react";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonItem,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
import { useUserName } from "../../shared/User/useUserName";
import { useCurrentGoal } from "../../../utils/useCurrentGoal";
import { useDispatch } from "../../../redux/hooks";
import { modifyGoal } from "../../../redux/actions/goal";
import { PAGE_URLS } from "../../../constants";
import { useNavigation } from "../../../utils/useNavigation";
import { useViewport } from "../../../hooks/useViewport";
import { NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { HelpButton } from "../../shared/Support/HelpButton";
import { trackEvent } from "../../../vendors/monitoring";

export const InvitePartner: React.FC<RouteComponentProps> = (props) => {
  const { navigate } = useNavigation();
  const { isMobile } = useViewport();
  const goal = useCurrentGoal();
  const { partnerName } = useUserName();
  const dispatch = useDispatch();
  const [invitePartner, setInvitePartner] = useState(true);
  const { t } = useTranslation();

  const save = () => {
    trackEvent("invite_partner", { category: "goals" });
    dispatch(modifyGoal(goal.id, { invitePartner })).then((url) =>
      navigate(PAGE_URLS.GOALS_CELEBRATE_ACTIVATION.replace(":goalId", goal.id), "forward")
    );
  };

  const footerSection = (
    <IonFooter className="ion-no-border ion-text-center">
      <IonButton
        fill="solid"
        className="ion-margin"
        onClick={save}
        data-testid="invitePartnerButton"
      >
        {t("save")}
      </IonButton>
    </IonFooter>
  );

  return (
    <PageTemplate
      header={
        <NavHeader title={t("savingsGoal")} defaultHref={PAGE_URLS.GOALS_HOME}>
          <HelpButton pageLocation="goal-invite-partner" className="fy-nav-buttons" color="" />
        </NavHeader>
      }
      footer={isMobile ? footerSection : undefined}
    >
      <Section>
        <h1 className="ion-margin ion-padding-bottom ion-padding-top">
          {t("invitePartnerToGoal", { partnerName })}
        </h1>
        <IonRadioGroup value={invitePartner} onIonChange={(e) => setInvitePartner(e.detail.value)}>
          {[true, false].map((option) => (
            <IonItem
              key={`${option}`}
              className="ion-margin"
              lines="none"
              data-testid={`inviteOption_${option}`}
            >
              <IonLabel>{option ? t("yes") : t("noThanks")}</IonLabel>
              <IonRadio value={option} />
            </IonItem>
          ))}
        </IonRadioGroup>
        {isMobile ? null : footerSection}
      </Section>
    </PageTemplate>
  );
};
