import { IonButton, IonIcon, IonLabel } from "@ionic/react";
import React from "react";
import { BudgetCategoryObj, Category } from "../../../types";
import { chevronBack, chevronForward } from "ionicons/icons";
import numeral from "numeral";
import { CategoryImg } from "../../shared/Miscellaneous/CategoryImg";

type CategoryButtonsProps = {
  category: BudgetCategoryObj;
  categoryDetails: Category;
  onChange: (diff: -1 | 1) => (event: any) => void;
};

export const CategoryButtons: React.FC<CategoryButtonsProps> = (props) => {
  const { category, categoryDetails, onChange } = props;
  return (
    <div className="category-picker">
      <IonButton fill="clear" onClick={onChange(-1)}>
        <IonIcon icon={chevronBack} size="large" />
      </IonButton>
      <CategoryImg category={categoryDetails} imgProps={{ className: "category-icon" }} />
      <div className="category-labels font-sm ion-text-start">
        <IonLabel className="font-md">{category?.displayName}</IonLabel>
        {numeral(category?.amountActual).format("$0,0")}
      </div>
      <IonButton fill="clear" onClick={onChange(1)}>
        <IonIcon icon={chevronForward} size="large" />
      </IonButton>
    </div>
  );
};
