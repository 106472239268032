import React, { useState } from "react";
import { IonContent, IonRow, IonGrid, IonCol, IonModal, IonAlert } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { closeOutline } from "ionicons/icons";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import { BillModalSearch } from "./BillModalSearch";
import { CreateBillForm } from "./CreateBillForm";

interface CreateBillModalProps {
  isOpen: boolean;
  onClose: () => void;
  effectiveDate: string;
}

export const CreateBillModal: React.FC<CreateBillModalProps> = ({
  isOpen = false,
  onClose,
  effectiveDate,
}) => {
  const { t } = useTranslation();

  const [showSkipAlert, setShowSkipAlert] = useState<boolean>(false);
  const [selectedManual, setSelectedManual] = useState<boolean>(false);
  const [billTransactionId, setBillTransactionId] = useState<string | undefined>(undefined);

  const clearAndClose = () => {
    setSelectedManual(false);
    setBillTransactionId(undefined);
    onClose();
  };

  return (
    <IonModal id="CreateBillModal" isOpen={isOpen} onDidDismiss={clearAndClose}>
      <ModalHeader
        title={t("createBill")}
        startBtnIcon={closeOutline}
        startBtnOnClick={clearAndClose}
        endBtnText={billTransactionId || selectedManual ? undefined : t("skip")}
        endBtnOnClick={() => setShowSkipAlert(true)}
      />
      {billTransactionId || selectedManual ? (
        <CreateBillForm
          onClose={clearAndClose}
          transactionId={billTransactionId}
          selectedManual={selectedManual}
          effectiveDate={effectiveDate}
        />
      ) : (
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <h1 className="font-title-3">{t("createBillModal_title")}</h1>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <h3 className="font-md">{t("createBillModal_msg")}</h3>
              </IonCol>
            </IonRow>
          </IonGrid>
          <BillModalSearch
            effectiveDate={effectiveDate}
            transactionOnClick={(transactionId: string) => setBillTransactionId(transactionId)}
          />
        </IonContent>
      )}
      <IonAlert
        isOpen={showSkipAlert}
        onDidDismiss={() => setShowSkipAlert(false)}
        header={t("manualBillAlert_header")}
        message={t("manualBillAlert_msg")}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
          },
          {
            text: t("skip"),
            role: "destructive",
            handler: () => setSelectedManual(true),
          },
        ]}
      />
    </IonModal>
  );
};
