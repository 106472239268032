import React, { useState, useEffect } from 'react';
import { IonSpinner, IonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { useSelector } from '../../../redux/hooks';
import { getTransactionState } from '../../../redux/selectors';
import { refreshOutline } from 'ionicons/icons';
import { filteredFieldChanged } from '../../../utils/transUtils';

interface RefresherToastProps {
  onTap: () => Promise<void>;
}

export const RefresherToast: React.FC<RefresherToastProps> = (props) => {
  const { t } = useTranslation();
  const { onTap } = props;
  const { searchParams, updatedFields } = useSelector(getTransactionState);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open && filteredFieldChanged(searchParams, updatedFields)) {
      setOpen(true);
    }
  }, [searchParams, updatedFields]);

  const handler = () => {
    setLoading(true);
    onTap()
      .then(() => setOpen(false))
      .then(() => setLoading(false));
  }

  return (
    <>
      <IonToast
        color="primary"
        isOpen={open}
        buttons={[{
          icon: refreshOutline,
          side: 'start',
          handler,
        }]}
        message={t("transactionFieldUpdated")}
        position="top"
        duration={7000}
      />
      {loading && <IonSpinner className="wrapped-text-container ion-margin" />}
    </>
  );
};
