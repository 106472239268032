import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonInput,
  IonLabel,
  IonItem,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { PAGE_URLS } from "../../../../constants";
import { setLoading, setToastMessage } from "../../../../redux/actions";
import { useDispatch } from "../../../../redux/hooks";
import { useHistory } from "react-router";
import { PageTemplate, Section, NavHeader } from "../../../shared/PageTemplate";
import { Auth } from "aws-amplify";

export const ChangePassword: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  //state
  const [formValid, setFormValid] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reenteredPassword, setReenteredPassword] = useState("");
  const passwordRequirementsRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/;

  useEffect(() => {
    if (!currentPassword.length || !newPassword.length || !reenteredPassword.length) {
      setFormValid(false);
      return;
    }
    if (
      !passwordRequirementsRegex.test(newPassword) ||
      !passwordRequirementsRegex.test(reenteredPassword)
    ) {
      setFormValid(false);
      return;
    }
    if (newPassword !== reenteredPassword) {
      dispatch(setToastMessage(t("passwordsDontMatch")));
      setFormValid(false);
      return;
    }
    setFormValid(true);
  }, [currentPassword, newPassword, reenteredPassword]);

  const changePassword = async () => {
    if (!formValid) {
      return;
    }
    dispatch(setLoading(true));
    const currentUser = await Auth.currentAuthenticatedUser();
    try {
      await Auth.changePassword(currentUser, currentPassword, newPassword);
      dispatch(setToastMessage(t("passwordChangeSuccess")));
      console.debug("cypress changePassword success");
      history.push(PAGE_URLS.HOME);
    } catch (err) {
      console.debug("cypress changePassword fail");
      console.error(err);
      dispatch(setToastMessage(err.message || t("passwordChangeFailure")));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <PageTemplate
      header={<NavHeader defaultHref={PAGE_URLS.MORE_SETTINGS} title={t("settings")} />}
    >
      <Section>
        <IonList lines="none">
          <IonItem>
            <IonLabel position="floating">{t("currentPassword")}</IonLabel>
            <IonInput
              onIonChange={(e) => setCurrentPassword(e.detail.value || "")}
              value={currentPassword}
              type="password"
              data-testid="changePassword_currentPassword"
            ></IonInput>
          </IonItem>
          <IonItem className="ion-margin-top">{t("changePasswordDes")}</IonItem>
          <IonItem>
            <IonLabel position="floating">{t("newPassword")}</IonLabel>
            <IonInput
              onIonChange={(e) => setNewPassword(e.detail.value || "")}
              clearOnEdit={false}
              value={newPassword}
              type="password"
              data-testid="changePassword_newPassword"
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">{t("newPasswordAgain")}</IonLabel>
            <IonInput
              onIonChange={(e) => setReenteredPassword(e.detail.value || "")}
              clearOnEdit={false}
              value={reenteredPassword}
              type="password"
              data-testid="changePassword_newPasswordAgain"
            ></IonInput>
          </IonItem>
        </IonList>
        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-center ion-margin-top">
              <IonButton
                onClick={changePassword}
                disabled={!formValid}
                data-testid="changePassword_submit"
              >
                {t("changePassword")}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </Section>
    </PageTemplate>
  );
};
