import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonSpinner,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { PAGE_URLS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { getBillsState } from "../../../redux/selectors";
import dayjs from "dayjs";
import { Bill, BillsData } from "../../../types";
import { cloneDeep } from "lodash";
import { BillItem } from "./BillItem";

const BillsCard: React.FC = () => {
  const { t } = useTranslation();

  const { loading, billsData } = useSelector(getBillsState);
  const effectiveDate = dayjs().startOf("month").format("YYYY-MM-DD");
  const [billsArrayForDate, setBillsArrayForDate] = useState<Bill[]>([]);

  useEffect(() => {
    const billsForThisDate = billsData.find(
      (item: BillsData) => item.effectiveDate === effectiveDate
    );
    if (!billsForThisDate) {
      return;
    }

    const newBills: BillsData = cloneDeep(billsForThisDate);
    const startOfWeek = dayjs().startOf("week");
    const endOfWeek = dayjs().endOf("week");
    const startOfWeekNum = startOfWeek.date();
    const endOfWeekNum = endOfWeek.date();
    const sameMonth = endOfWeek.isSame(startOfWeek, "month");

    // get the bills for this week
    const filteredBills = newBills.billsArray.filter((bill) => {
      if (sameMonth) {
        return bill.day >= startOfWeekNum && bill.day <= endOfWeekNum;
      } else {
        return bill.day >= startOfWeekNum || bill.day <= endOfWeekNum;
      }
    });

    const billsForDisplay: Bill[] = filteredBills.map((bill) => {
      // account for cases where the due date is more than days in month
      // e.g., due on 31, but only 30 days in month
      const endOfMonth = dayjs(startOfWeek).endOf("month");
      const billDay = endOfMonth.date() < bill.day ? endOfMonth.date() : bill.day;

      let disDate = dayjs(startOfWeek).date(billDay);
      if (!sameMonth) {
        if (startOfWeek.isAfter(disDate)) {
          disDate = dayjs(endOfWeek).date(billDay);
        } else if (endOfWeek.isBefore(disDate)) {
          disDate = dayjs(startOfWeek).date(billDay);
        }
      }
      return {
        ...bill,
        sortDate: disDate.toDate(),
        displayDate: dayjs(disDate).format("ddd, MMM D"),
      };
    });
    // @ts-ignore
    billsForDisplay.sort((a, b) => new Date(a.sortDate) - new Date(b.sortDate));

    // billsForDisplay.hasUpcomingBills = billsForDisplay.length > 0;

    setBillsArrayForDate(billsForDisplay.splice(0, 3));
  }, [billsData, effectiveDate]);

  return (
    <IonCard
      id="BillsCard"
      routerLink={PAGE_URLS.BILLS.replace(
        ":effectiveDate",
        dayjs(effectiveDate).format("YYYY-MM-DD")
      )}
      data-testid="BillsCard"
    >
      <IonCardHeader>
        <IonCardTitle className="ion-text-center">{t("homeCards_upcoming-bills")}</IonCardTitle>
      </IonCardHeader>
      {loading ? (
        <IonCardContent className="ion-text-center">
          <IonSpinner />
        </IonCardContent>
      ) : (
        <IonCardContent className="ion-no-padding ion-padding-vertical">
          <IonList lines="none" className="ion-no-padding">
            {billsArrayForDate?.length > 0 ? (
              billsArrayForDate?.map((bill) => {
                return <BillItem key={bill._id} billId={bill._id} effectiveDate={effectiveDate} />;
              })
            ) : (
              <IonItem className="ion-text-center">
                <IonLabel>{t("noBillsThisWeek")}</IonLabel>
              </IonItem>
            )}
          </IonList>
        </IonCardContent>
      )}
    </IonCard>
  );
};

export default BillsCard;
