import dayjs from "dayjs";
import {
  setLoading,
  TransactionUpdateDataFields,
  updateBill,
  updateDataForArrayTransactions,
} from "../redux/actions";
import { post } from "./apiOps";

export const markBillFromTrans = async (
  selectedBillId: string | undefined,
  transactionId: string,
  effectiveDate: string,
  dispatch: any,
  clearAndClose: any
) => {
  try {
    if (!selectedBillId) {
      return;
    }
    const bodyData = {
      transactionId,
      billId: selectedBillId,
      billMonth: Number(dayjs(effectiveDate).format("M")) - 1,
      billYear: dayjs(effectiveDate).year(),
    };
    dispatch(setLoading(true));

    await post(true, {
      endpoint: "/bills/markAsPaid",
      bodyData,
    });

    await dispatch(
      updateBill({
        billId: selectedBillId,
        updateData: [
          { field: "status", value: "paid" },
          { field: "paidSource", value: "transaction" },
          { field: "transactionId", value: transactionId },
        ],
        effectiveDate,
      })
    );
    await dispatch(
      updateDataForArrayTransactions({
        transactionIdsArray: [transactionId],
        updateDataArray: [
          { field: TransactionUpdateDataFields.IS_BILL, value: true },
          {
            field: TransactionUpdateDataFields.BILLS,
            value: [
              {
                billId: selectedBillId,
                billMonth: dayjs(effectiveDate).startOf("month").format("YYYY-MM-DD"),
              },
            ],
          },
        ],
      })
    );
  } catch (err) {
    console.debug(err);
  } finally {
    clearAndClose();
    dispatch(setLoading(false));
  }
};

// if (!selectedManual) {
//   const transactionIdsArray = data.transactions.map((t) => t._id);
//   dispatch(
//     updateDataForArrayTransactions({
//       transactionIdsArray,
//       updateDataArray: [
//         { field: TransactionUpdateDataFields.IS_BILL, value: true },
//         {
//           field: TransactionUpdateDataFields.BILLS,
//           value: [
//             {
//               billId: data.newBillId,
//               billMonth: dayjs(effectiveDate).startOf("month").format("YYYY-MM-DD"),
//             },
//           ],
//         },
//       ],
//     })
//   );
// }
