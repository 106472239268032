import React, { useState } from "react";
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonList,
  IonListHeader,
  IonPage,
  IonText,
  IonPopover,
  IonFooter,
  IonButtons,
  IonIcon,
  IonActionSheet,
  IonTitle,
  IonAlert,
  IonLabel,
} from "@ionic/react";
import { createOutline, ellipsisHorizontal, chevronForward } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

import "./EditGoal.scss";

import { Goal } from "../../../types/goals";
import { useCurrentGoal } from "../../../utils/useCurrentGoal";
import { useDispatch, useSelector } from "../../../redux/hooks";
import { deleteGoal, modifyGoal, getAllViewerGoals } from "../../../redux/actions/goal";
import { setToastMessage } from "../../../redux/actions";
import { getGoalsState } from "../../../redux/selectors";
import Avatars from "../../shared/Avatar/Avatars";
import { useUserName } from "../../shared/User/useUserName";
import {
  getContributionLink,
  getContributionText,
  getContributionAmt,
  privilegeDisplayMap,
} from "../../../utils/goalUtils";
import {
  GOAL_FUNDING_ACCOUNT_STATUS,
  GOAL_STATUS,
  PAGE_URLS,
  PRIVILEGE_TYPE_DATA_2,
} from "../../../constants";
import { useNavigation } from "../../../utils/useNavigation";
import { displayedAccountShort } from "../../../utils/formatUtils";
import { ActionSheet, PageTemplate, Section } from "../../shared/PageTemplate";
import { NavButton, NavHeader } from "../../shared/PageTemplate/NavHeader";
import { Field } from "../../shared/Field/Field";
import { trackEvent } from "../../../vendors/monitoring";

type Dialog =
  | ""
  | "nameEditor"
  | "targetAmountEditor"
  | "actionSheet"
  | "confirmPause"
  | "confirmClose"
  | "confirmComplete"
  | "confirmDelete"
  | "rejectDeletePerm"
  | "rejectDeleteBal";

export const EditGoal: React.FC = (props) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { partnerName, singlePlayerMode } = useUserName();
  const goal = useCurrentGoal() as Goal;
  const [dialog, setDialog] = useState<Dialog>("");

  const goalIsClosed = [GOAL_STATUS.CLOSED, GOAL_STATUS.COMPLETED].includes(
    goal?.status?.toUpperCase() as string
  );
  const deleteAction = goal.ownedByViewer
    ? (goal.curBal as number) + (goal.availBal as number) === 0
      ? "confirmDelete"
      : "rejectDeleteBal"
    : "rejectDeletePerm";

  const actions = [];
  if (goalIsClosed && goal.viewerFundingInfo) {
    actions.push({ text: t("deleteGoal"), handler: () => setDialog(deleteAction) });
  } else {
    if (goal.viewerFundingInfo?.status?.toUpperCase() !== GOAL_FUNDING_ACCOUNT_STATUS.PAUSED) {
      actions.push({ text: t("pauseContributions"), handler: () => setDialog("confirmPause") });
    }
    actions.push({ text: t("closeGoal"), handler: () => setDialog("confirmClose") });
  }
  if (goal.status === GOAL_STATUS.ACTIVE && (goal.transfers?.totalCount as number) > 0) {
    actions.push({ text: t("markGoalAsComplete"), handler: () => setDialog("confirmComplete") });
  }
  actions.push({ text: t("cancel"), handler: () => setDialog("") });

  const save = (params: any, successMessage?: string) => {
    return dispatch(modifyGoal(goal.id, params, successMessage)).then(() => setDialog(""));
  };

  return (
    <PageTemplate
      pageProps={{ className: "edit-goal-page" }}
      contentProps={{ className: "ion-padding-end" }}
      header={
        <NavHeader
          title={t("editGoal")}
          defaultHref={PAGE_URLS.GOALS_DETAILS.replace(":goalId", goal.id)}
        >
          {goal.viewerFundingInfo && goal.status !== GOAL_STATUS.PFRAUD ? (
            <ActionSheet
              buttonProps={{
                size: "medium",
                "data-testid": "goalEditActionsButton",
                color: "primary",
              }}
              buttons={actions}
            />
          ) : (
            <div />
          )}
        </NavHeader>
      }
    >
      <Section>
        <h2 className="fy-section-title-border font-title-3 ion-padding-start">{t("details")}</h2>
        <IonList key="goal-details" className="ion-no-padding ion-padding-bottom ion-margin-bottom">
          <Field label={t("name")} icon={createOutline} onClick={() => setDialog("nameEditor")}>
            <IonText>{goal.name}</IonText>
          </Field>
          <Field
            label={t("targetAmount")}
            icon={createOutline}
            testId="changeTargetAmountButton"
            onClick={() => setDialog("targetAmountEditor")}
          >
            <IonText>{numeral(goal.targetAmount).format("$0,0")}</IonText>
          </Field>
          {singlePlayerMode ? null : (
            <IonItem lines="none" className="fy-field">
              <IonLabel position="stacked">{t("whoIsThisFor")}</IonLabel>
              <IonText className="d-flex">
                {goal.hhInfo && t(privilegeDisplayMap[goal.hhInfo?.privilegeType], { partnerName })}
                <Avatars
                  diagonal
                  sizeStyle="xs"
                  className="ml-auto"
                  alignment="end"
                  avatarType={Number(goal?.hhInfo?.privilegeType)}
                />
              </IonText>
            </IonItem>
          )}
        </IonList>
      </Section>
      {[goal?.viewerFundingInfo, goal.nonViewerFundingInfo]
        .filter((info) => info)
        .map((info: any, i) => {
          const isUser = info === goal?.viewerFundingInfo;
          const isInvited = info?.status?.toUpperCase() === GOAL_FUNDING_ACCOUNT_STATUS.INVITED;
          const isActiveOrPaused = [
            GOAL_FUNDING_ACCOUNT_STATUS.ACTIVE,
            GOAL_FUNDING_ACCOUNT_STATUS.PAUSED,
          ].includes(info?.status?.toUpperCase() as string);

          return (
            <Section key={`funding-details-${i}`}>
              <h2 className="fy-section-title-border font-title-3 ion-padding-start">
                {isUser ? t("yourFundingAccount") : t("partnerFundingAccount", { partnerName })}
              </h2>
              <IonList className="ion-no-padding ion-padding-bottom ion-margin-bottom">
                {/* If invite not accepted and no funding account linked, show status and skip everything else */}
                {isInvited ? (
                  <Field label={<IonLabel color="danger">{t("noAccountLinkedYet")}</IonLabel>} />
                ) : (
                  <>
                    {/* Account has been linked (but not necessarily has a contribution has been defined) */}
                    <Field label={t("account")}>
                      <IonText>{displayedAccountShort(info?.fundingAccount)}</IonText>
                    </Field>

                    {/*
                      Funding account has been activated.
                      Link to either the detail page (if currently paused or goal closed)
                      or contribution setup page
                    */}
                    {isActiveOrPaused ? (
                      <Field
                        testId="changeContributionStrategy"
                        routerLink={getContributionLink(goal, info as any, isUser)}
                        routerDirection="forward"
                        icon={chevronForward}
                        label={
                          goalIsClosed ? (
                            <IonLabel position="stacked" color="danger">
                              {t(goal.status as any)}
                            </IonLabel>
                          ) : (
                            t("contributionRule")
                          )
                        }
                      >
                        {goal.status === GOAL_STATUS.ACTIVE ? (
                          info?.status === GOAL_FUNDING_ACCOUNT_STATUS.PAUSED.toLowerCase() ? (
                            <IonText color="danger">{t(info.status as any)}</IonText>
                          ) : (
                            <IonText>
                              {getContributionText(info)} - {getContributionAmt(info)}
                            </IonText>
                          )
                        ) : null}
                      </Field>
                    ) : null}
                    <Field
                      routerLink={PAGE_URLS.GOALS_TRANSACTIONS.replace(":goalId", goal.id).replace(
                        ":isUser",
                        isUser ? "1" : "0"
                      )}
                      routerDirection="forward"
                      testId="goalTransactions"
                      label={t("balance")}
                      icon={chevronForward}
                    >
                      <IonText>
                        {numeral(info.balance + info.pendingBalance).format("$0,0.00")}
                        {info.pendingBalance > 0 ? (
                          <p>{t("includingPendingBalance", info)}</p>
                        ) : null}
                      </IonText>
                    </Field>
                  </>
                )}
              </IonList>
            </Section>
          );
        })}
      {!singlePlayerMode &&
        !goalIsClosed &&
        !goal.nonViewerFundingInfo &&
        goal.hhInfo?.privilegeType === PRIVILEGE_TYPE_DATA_2.SHARED ? (
        <IonButtons>
          <IonButton
            fill="outline"
            className="button-center"
            data-testid="invitePartnerButton"
            onClick={() => save({ invitePartner: true }, t("invitedPartner", { partnerName }))}
          >
            {t("invitePartnerToSave", { partnerName })}
          </IonButton>
        </IonButtons>
      ) : null}

      {/* Dialogs: */}
      {dialog === "nameEditor" ? (
        <NameEditor goal={goal} key={goal.name} onClose={() => setDialog("")} onSave={save} />
      ) : null}
      {dialog === "targetAmountEditor" ? (
        <TargetAmountEditor
          goal={goal}
          key={goal.targetAmount}
          onClose={() => setDialog("")}
          onSave={save}
          onError={(message) => dispatch(setToastMessage(message))}
        />
      ) : null}
      {dialog === "confirmPause" ? (
        <Confirm
          onClose={setDialog}
          handler={() => {
            trackEvent("pause_goal", { category: "goals" });
            save({ fundingStatus: GOAL_STATUS.PAUSED }, t("pausedContributions"));
          }}
          text={t("pause")}
        >
          {t("confirmPauseContributions")}
        </Confirm>
      ) : null}
      {dialog === "confirmClose" ? (
        <Confirm
          onClose={setDialog}
          handler={() => {
            trackEvent("close_goal", { category: "goals" });
            save({ status: GOAL_STATUS.CLOSED }, t("closedGoal"));
          }}
          text={t("closeGoal")}
        >
          {t("confirmCloseGoal")}
        </Confirm>
      ) : null}
      {dialog === "confirmComplete" ? (
        <Confirm
          onClose={setDialog}
          handler={() => save({ status: GOAL_STATUS.COMPLETED })}
          text={t("markComplete")}
        >
          {t("confirmComplete")}
        </Confirm>
      ) : null}
      {dialog === "rejectDeletePerm" ? (
        <Confirm
          confirmOnly
          onClose={setDialog}
          handler={() => setDialog("")}
          text={t("cantDeleteGoal")}
        >
          {t("onlyPartnerCanDeleteGoal", { partnerName })}
        </Confirm>
      ) : null}
      {dialog === "rejectDeleteBal" ? (
        <Confirm
          confirmOnly
          onClose={setDialog}
          handler={() => setDialog("")}
          text={t("cantDeleteGoal")}
        >
          {t("cantDeleteGoalWithBalance")}
        </Confirm>
      ) : null}
      {dialog === "confirmDelete" ? (
        <Confirm
          onClose={setDialog}
          handler={() =>
            dispatch(deleteGoal(goal.id))
              .then(() => setDialog(""))
              .then(() => navigate(PAGE_URLS.GOALS_HOME, "back"))
              .then(() => dispatch(getAllViewerGoals()))
          }
          text={t("deleteGoal")}
        >
          {t("deleteGoalConfirmation")}
        </Confirm>
      ) : null}
    </PageTemplate>
  );
};

type NameEditorProps = {
  goal: Goal;
  onClose: () => void;
  onSave: (params: any) => void;
};

const NameEditor: React.FC<NameEditorProps> = (props) => {
  const { goal, onClose, onSave } = props;
  const { t } = useTranslation();

  return <IonAlert
    isOpen={true}
    inputs={[{
      value: goal.name,
      name: "name",
      attributes: { "data-testid": "edit-name-input" } as any,
    }]}
    header={t("editName")}
    buttons={[{
      text: t("cancel"),
      role: "cancel",
      handler: () => onClose(),
    }, {
      text: t("save"),
      handler: onSave,
    }]}
  />
};

type TargetAmountEditorProps = {
  goal: Goal;
  onClose: () => void;
  onSave: (params: any) => void;
  onError: (message: string) => void;
};

const TargetAmountEditor: React.FC<TargetAmountEditorProps> = (props) => {
  const { goal, onClose, onSave, onError } = props;
  const { accountInfo } = useSelector(getGoalsState);
  const { t } = useTranslation();

  const handler = (input: any) => {
    const targetAmount = numeral(input.targetAmount).value()!;
    targetAmount > (accountInfo?.goalsLimits?.goalMax as number)
      ? onError(
        t("targetAmountAboveMax", {
          max: numeral(accountInfo?.goalsLimits?.goalMax).format("$0,0"),
        })
      )
      : targetAmount > 0 && onSave({ targetAmount });
  }

  return <IonAlert
    isOpen={true}
    inputs={[{
      value: goal.targetAmount,
      name: "targetAmount",
      type: "number",
      attributes: { "data-testid": "edit-amount-input" } as any
    }]}
    header={t("editTargetAmount")}
    message={t("enterMaxAmount", {
      amount: numeral(accountInfo?.goalsLimits?.goalMax).format("$0,0"),
    })}
    buttons={[{
      text: t("cancel"),
      role: "cancel",
      handler: () => onClose(),
    }, {
      text: t("save"),
      handler,
    }]}
  />
};

type ConfirmProps = {
  handler: () => void;
  onClose: (dialog: Dialog) => void;
  text: string;
  confirmOnly?: boolean;
  children?: string;
};

const Confirm: React.FC<ConfirmProps> = (props) => {
  const { handler, onClose, text, confirmOnly = false, children } = props;
  const { t } = useTranslation();
  const alertProps = {
    isOpen: true,
    header: text,
    message: children,
    buttons: confirmOnly ? [{
      text: t("gotIt"),
      handler: () => onClose(""),
    }] : [{
      text: t("cancel"),
      role: "cancel",
      handler: () => onClose(""),
    }, {
      text,
      role: "destructive",
      handler,
    }],
  }
  return <IonAlert {...alertProps} />
};
