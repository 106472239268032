const images = {
  hiker: require("./hiker.png"),
  goalsHiker: require("./illustration-goals-hiker.png"),
  celebration: require("./celebration.png"),
  funFact: require("./funFact.png"),
  spendingChallenge: require("./spending_challenge/spendingChallenge.png"),
  celebrationImg: require("./celebrationImg.png"),
  billsAudit: require("./billsAudit.png"),
  billsAuditBillshark: require("./billsAudit_billshark.png"),
  billsAuditWallet: require("./billsAudit_wallet.png"),
  billsAuditCouch: require("./billsAudit_couch.png"),
  billsAuditCoin: require("./billsAudit_coin.png"),
  billsAuditDoc: require("./billsAudit_doc.png"),
  billsAuditCelebrate: require("./billsAudit_celebrate.png"),
  spendingChallengeActive: require("./spending_challenge/spendingChallengeActiveCard.png"),
  welcome: require("./illustration-home-welcome.svg"),
  spendingIndividual: require("./spending_challenge/individual_challenge.png"),
  spendingCompete: require("./spending_challenge/compete_challenge.png"),
  spendingCancel: require("./spending_challenge/cancel_challenge.png"),
  dining: require("./spending_challenge/dining.png"),
  alcohol: require("./spending_challenge/alcohol.png"),
  coffee: require("./spending_challenge/coffee.png"),
  entertainment: require("./spending_challenge/entertainment.png"),
  taxi: require("./spending_challenge/taxi.png"),
  shopping: require("./spending_challenge/wants.png"),
  others: require("./spending_challenge/others.png"),
  goalsTestimonial_1: require("./goals-testimonial_1.png"),
  goalsTestimonial_2: require("./goals-testimonial_2.png"),
  email: require("./email.svg"),
  trophy: require("./illustration-generic-trophy.png"),
};

export default images;
