import * as types from "../actionTypes";

export const setShowAlert = (showAlert: boolean = false) => {
  return {
    type: types.SET_SHOW_ALERT,
    payload: {
      showAlert,
    },
  };
};

export const setAlertUiState = (alertData: any) => {
  return {
    type: types.SET_ALERT_UI_STATE,
    payload: {
      alertData
    }
  };
};
