import React, { useState } from "react";
import { IonPopover, IonGrid, IonRow, IonCol } from "@ionic/react";
import { AVATAR_DISPLAY, BILLS_SHARED_STATUS } from "../../../constants";
import { useTranslation } from "react-i18next";
import Avatars from "../../shared/Avatar/Avatars";

interface YomPopoverProps {
  updateYom: any;
  partnerName: string;
  showEditYomPopover: any;
  setShowEditYomPopover: any;
  cssClass?: string;
}

export const YomPopover: React.FC<YomPopoverProps> = ({
  updateYom,
  partnerName,
  showEditYomPopover,
  setShowEditYomPopover,
  cssClass,
}) => {
  const { t } = useTranslation();

  return (
    <IonPopover
      id="BillShareSettingsPopover"
      cssClass={cssClass || ""}
      isOpen={showEditYomPopover.isOpen}
      onDidDismiss={() => setShowEditYomPopover({ isOpen: false, event: undefined })}
      event={showEditYomPopover.event}
    >
      <IonGrid className="ion-text-center font-sm">
        <IonRow className="ion-align-items-center">
          <IonCol onClick={() => updateYom(BILLS_SHARED_STATUS.PARTNER)}>
            <Avatars sizeStyle="sm" avatarType={AVATAR_DISPLAY.PARTNER} />
          </IonCol>
          <IonCol onClick={() => updateYom(BILLS_SHARED_STATUS.SHARED)}>
            <Avatars vertical={true} sizeStyle="sm" avatarType={AVATAR_DISPLAY.SHARED} />
          </IonCol>
          <IonCol onClick={() => updateYom(BILLS_SHARED_STATUS.ME)}>
            <Avatars sizeStyle="sm" avatarType={AVATAR_DISPLAY.ME} />
          </IonCol>
          <IonCol onClick={() => updateYom(BILLS_SHARED_STATUS.PRIVATE)}>
            <Avatars sizeStyle="sm" avatarType={AVATAR_DISPLAY.PRIVATE} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>{partnerName}</IonCol>
          <IonCol>{t("household")}</IonCol>
          <IonCol>{t("me")}</IonCol>
          <IonCol>{t("private")}</IonCol>
        </IonRow>
      </IonGrid>
    </IonPopover>
  );
};
