import React, { useState } from "react";
import { IonButton, IonButtons, IonIcon } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";
import { HideCardAlert } from "../Budget/HideCardAlert";

interface HomeCardCloseAlertProps {
  removedCard: string;
}

export const HomeCardCloseAlert: React.FC<HomeCardCloseAlertProps> = ({ removedCard }) => {
  const [alertOpen, setAlertOpen] = useState(false);

  return (
    <>
      <IonButtons onClick={() => setAlertOpen(true)} className="fun-fact-close">
        <IonButton>
          <IonIcon icon={closeCircleOutline} />
        </IonButton>
      </IonButtons>
      <HideCardAlert open={alertOpen} close={() => setAlertOpen(false)} removedCard={removedCard} />
    </>
  );
};
