import { BUDGET_SUPER_CATEGORIES } from "../constants";
import { BudgetSuperCategory, BudgetSubcategoryObj, BudgetSuperCategoryObj } from "../types";

export const getOperator = (superCategory: BudgetSuperCategory | undefined) => {
  return superCategory === BUDGET_SUPER_CATEGORIES.INCOME ? -1 : 1;
};

export const isOverBudget = (
  superCategory: BudgetSuperCategory,
  budgetItemData: BudgetSubcategoryObj | BudgetSuperCategoryObj | undefined
) => {
  return (
    getOperator(superCategory) * (budgetItemData?.amount || 0) <
    getOperator(superCategory) * (budgetItemData?.amountActual || 0)
  );
};
