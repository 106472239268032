import React from "react";
import { IonButton } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { openChatWithMessage } from "../../../vendors/intercom";
import { trackEvent } from "../../../vendors/monitoring";

interface HelpButtonProps {
  buttonCopy?: string;
  helpMsg?: string;
  pageLocation?: string;
  [key: string]: any;
}

export const HelpButton: React.FC<HelpButtonProps> = ({
  buttonCopy,
  helpMsg,
  pageLocation,
  ...buttonProps
}) => {
  const { t } = useTranslation();
  const handleClick = () => {
    const message = pageLocation && typeof helpMsg === "undefined"
      ? t(`help_${pageLocation}`)
      : ""
    openChatWithMessage(message);
    trackEvent("Tapped help button", {
      pageLocation,
    });
  };
  return (
    <IonButton
      className={`font-sm ${buttonProps.className || ""}`}
      color="medium"
      onClick={handleClick}
      {...buttonProps}
    >
      {t(buttonCopy || "help")}
    </IonButton>
  );
};
