import React from "react";
import { PrivilegeType } from "../../../types";
import { PRIVILEGE_TYPE_DATA_3, AVATAR_DISPLAY } from "../../../constants";
import Avatars from "../../shared/Avatar/Avatars";

type GoalAvatarsProps = {
  privilegeType: PrivilegeType;
  sizeStyle?: "sm" | "md" | "lg";
  className?: string;
};

const shareStatusMap = {
  [PRIVILEGE_TYPE_DATA_3.SHARED]: AVATAR_DISPLAY.SHARED,
  [PRIVILEGE_TYPE_DATA_3.VISIBLE]: AVATAR_DISPLAY.ME,
  [PRIVILEGE_TYPE_DATA_3.IGNORE]: undefined,
  [PRIVILEGE_TYPE_DATA_3.JUSTME]: AVATAR_DISPLAY.PRIVATE,
};

export const GoalAvatars: React.FC<GoalAvatarsProps> = (props) => {
  const { privilegeType, sizeStyle, className = "" } = props;
  return <Avatars avatarType={shareStatusMap[privilegeType] as any} sizeStyle={sizeStyle} className={className} />;
};
