import { cloneDeep, remove, sortBy } from "lodash";
import { Dispatch } from "react";
import { AccountTypesMapItem, Category, CategoryMapApiResponse, SubCategory } from "../../types";
import { get } from "../../utils/apiOps";
import { BUDGET_SUPER_CATEGORIES, CATEGORY_CONSTANTS } from "../../constants";
import * as types from "../actionTypes";

interface CategoryMapApiDataRes {
  data: CategoryMapApiResponse;
}

export const getAllCategorizationData = () => async (dispatch: Dispatch<any>) => {
  try {
    const { data }: CategoryMapApiDataRes = await get(true, { endpoint: "/view/categoryMap" });
    data.categories = sortCategories(data.categories);
    data.subCategories = sortSubcategories(data.subCategories);
    dispatch(saveCategorizationData(data));
  } catch {}
};

function sortCategories(categoriesArr: Category[]) {
  const allCats = cloneDeep(categoriesArr);
  const goalsCats = remove(allCats, (c) => c.categoryShortName === CATEGORY_CONSTANTS.GOALS);
  const incomeCats = remove(allCats, (c) => c.superCategory === BUDGET_SUPER_CATEGORIES.INCOME);
  const ignoreCats = remove(allCats, (c) => c.superCategory === BUDGET_SUPER_CATEGORIES.IGNORE);
  return allCats.concat(goalsCats).concat(incomeCats).concat(ignoreCats);
}

function sortSubcategories(subcategoriesArr: SubCategory[]) {
  const allSorted = sortBy(cloneDeep(subcategoriesArr), (s) => s.category);
  const incomeSubcats = remove(allSorted, (s) => s.category === CATEGORY_CONSTANTS.INCOME);
  const ignoreSubcats = remove(allSorted, (s) => s.category === CATEGORY_CONSTANTS.IGNORE);
  return allSorted.concat(incomeSubcats).concat(ignoreSubcats);
}

export const saveCategorizationData = (data: CategoryMapApiResponse) => {
  return {
    type: types.SAVE_CATEGORIZATION_DATA,
    payload: data,
  };
};

export const getCategoryData = (category: Array<Category>) => {
  return {
    type: types.GET_CATEGORY,
    payload: category,
  };
};

export const getSubCategoryData = (subCategory: Array<SubCategory>) => {
  return {
    type: types.GET_SUBCATEGORY,
    payload: subCategory,
  };
};

export const getCustomMap = (isCustomMap: boolean) => {
  return {
    type: types.CUSTOM_MAP,
    payload: isCustomMap,
  };
};

export const getCategoryErrorMessage = (categoryErrorMessage: any) => {
  return {
    type: types.GET_CATEGORY_ERRORMESSAGE,
    payload: categoryErrorMessage,
  };
};

export const getAccountTypesMap = (accountTypesMap: Array<AccountTypesMapItem>) => {
  return {
    type: types.ACCOUNT_TYPES_MAP,
    payload: accountTypesMap,
  };
};
