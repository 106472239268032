import React from "react";
import { IonItemDivider } from "@ionic/react";
import "./ItemDateDivider.scss";

interface ItemDateDividerProps {
  children: string;
}

export const ItemDateDivider: React.FC<ItemDateDividerProps> = ({ children }) => {
  return (
    <IonItemDivider className="item-date-divider ion-no-lines ion-padding-top">
      <h2 className="font-title-3 ion-no-margin">{children}</h2>
    </IonItemDivider>
  );
};
