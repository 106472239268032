import React, { useState } from "react";
import { IonButton, IonCard, IonCardContent, IonCardTitle, IonIcon } from "@ionic/react";
import BudgetCard from "../Budget/BudgetCard";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "../../../redux/hooks";
import { getBills, getBudget } from "../../../redux/actions";
import {
  getDeviceDataState,
  getSpendingChallengeState,
  getUsersState,
} from "../../../redux/selectors";
import { HomeCardManagementModal } from "./HomeCardsManagement";
import { HOME_CARDS } from "../../../constants";
import CashFlowCard from "../Budget/CashFlowCard";
import { RecentActivities } from "../Budget/RecentActivities";
import { FunFactCard, SpendingByPersonCard, IncomeCard } from "../Budget";
import { SpendingChallengeCard } from "../SpendingChallenge/SpendingChallengeCard";
import { SpendingChallengeActiveCard } from "../SpendingChallenge/SpendingChallengeActiveCard";
import dayjs from "dayjs";
import { cogOutline } from "ionicons/icons";
import { RefresherEventDetail } from "@ionic/core";
import "./HomeTab.scss";
import BillsCard from "../Budget/BillsCard";
import BillsAuditCard from "../BillsAudit/BillsAuditCard";
import { NetWorthCard } from "../NetWorth/NetWorthCard";
import { showedSpendingChallenge } from "../../../utils/spendingChallenge";
import { WelcomeCard } from "./WelcomeCard";
import { SpendingForecastCard } from "../Forecast/SpendingForecastCard";
import { SpendingAnalysisCard } from "../SpendingAnalysis/SpendingAnalysisCard";
import { TopSpendingSubCategoriesCard } from "../Budget/TopSpendingSubCategoriesCard";
import AccountAlert from "../../shared/Link/AccountAlert";
import { PageTemplate, NavHeader } from "../../shared/PageTemplate";
import { useViewport } from "../../../hooks/useViewport";
import { useUserName } from "../../shared/User/useUserName";
import { CardsContainer } from "./CardsContainer";
import { PullToRefresh } from "../../shared/PullToRefresh/PullToRefresh";
import { getSpendingForecastSummary } from "../../../redux/actions/spendingForecast";
import UpgradeAppVersionBanner from "../../shared/Alerts/UpgradeAppVersionBanner";
import { getSpendingByPersonData } from "../../../redux/actions/budgetAnalytics";
import DataNotReadyCard from "../Budget/DataNotReadyCard";
import { sortHomeCards } from "../../../utils/homeTab";

import { trackEvent } from "../../../vendors/monitoring";
import { VerifyEmailCard } from "./VerifyEmailCard";

export const HomeTab: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isDesktop } = useViewport();
  const { userName } = useUserName();
  const { cardPreferences } = useSelector(getDeviceDataState);
  const { spendingChallengesList } = useSelector(getSpendingChallengeState);
  const { userId, firstWeekTime } = useSelector(getUsersState);

  // state
  const [homeCardsModalIsOpen, setHomeCardsModalIsOpen] = useState<boolean>(false);

  const filteredSpendingChallengesList = showedSpendingChallenge(spendingChallengesList || []);

  const refreshHomeData = ({ detail }: CustomEvent<RefresherEventDetail>) => {
    const effectiveDate = dayjs().startOf("month").format("YYYY-MM-DD");
    Promise.all([
      dispatch(getBudget({ effectiveDate, forceRefresh: true })),
      dispatch(getBills({ effectiveDate, forceRefresh: true })),
      dispatch(getSpendingForecastSummary()),
      dispatch(getSpendingByPersonData()),
      // TO DO - refresh more cards
    ])
      .catch(() => {})
      .finally(() => detail.complete());
  };

  // This is to prevent the home cards from beginning to load.
  // Otherwise, we'll get a bunch of unauthorized errors / toasts.
  if (!userId || userId === "NEW") {
    return null;
  }
  return (
    <PageTemplate
      pageProps={{ id: "home-tab" }}
      contentProps={{ fullscreen: true }}
      header={
        <NavHeader
          showBackButton={false}
          title={isDesktop ? t("welcomeUser", { userName }) : undefined}
        />
      }
    >
      <PullToRefresh refreshHandler={refreshHomeData} />
      <CardsContainer>
        <AccountAlert />
        <UpgradeAppVersionBanner />
        <DataNotReadyCard />
        <VerifyEmailCard />
        {cardPreferences.cardArr &&
          cardPreferences.cardArr.length > 0 &&
          sortHomeCards(firstWeekTime, cardPreferences.cardArr).map((card) => {
            switch (card) {
              case HOME_CARDS.BUDGET:
                return <BudgetCard key={card} />;
              case HOME_CARDS.CASH_FLOW:
                return <CashFlowCard key={card} />;
              case HOME_CARDS.INCOME:
                return <IncomeCard key={card} />;
              case HOME_CARDS.FUN_FACT:
                return <FunFactCard key={card} />;
              case HOME_CARDS.SPENDING_BY_PERSON:
                return <SpendingByPersonCard key={card} />;
              case HOME_CARDS.BILLS:
                return <BillsCard key={card} />;
              case HOME_CARDS.ACTIVITY_FEED:
                return <RecentActivities key={card} />;
              case HOME_CARDS.BILLS_AUDIT:
                return <BillsAuditCard key={card} />;
              case HOME_CARDS.SPENDING_CHALLENGES:
                if (filteredSpendingChallengesList?.length) {
                  return <SpendingChallengeActiveCard key={card + "_a"} />;
                } else {
                  return <SpendingChallengeCard key={card + "_b"} />;
                }
              case HOME_CARDS.NET_WORTH:
                return <NetWorthCard key={card} />;
              case HOME_CARDS.WELCOME:
                return <WelcomeCard key={card} />;
              case HOME_CARDS.FORECAST:
                return <SpendingForecastCard key={card} />;
              case HOME_CARDS.SPENDING_ANALYSIS:
                return <SpendingAnalysisCard key={card} />;
              case HOME_CARDS.TOP_SUBCATS:
                return <TopSpendingSubCategoriesCard key={card} />;
              default:
                return (
                  <IonCard key={card}>
                    <IonCardTitle className="ion-text-center">{t("card")}</IonCardTitle>
                    <IonCardContent className="ion-text-center">{t("noData")}</IonCardContent>
                  </IonCard>
                );
            }
          })}
        <div className="ion-text-center ion-padding-vertical">
          <IonButton
            fill="clear"
            onClick={() => {
              setHomeCardsModalIsOpen(true);
              trackEvent("view_manageCards", { category: "manageCards" });
            }}
            data-testid="home-manage-cards"
          >
            <IonIcon icon={cogOutline} />
            {t("manageCards")}
          </IonButton>
        </div>
      </CardsContainer>

      <HomeCardManagementModal
        onClose={setHomeCardsModalIsOpen}
        isOpen={homeCardsModalIsOpen}
      ></HomeCardManagementModal>
    </PageTemplate>
  );
};
