import { post } from "../utils/apiOps";

export const plaidSettings = {
  clientName: String(process.env.REACT_APP_PLAID_CLIENT_NAME),
  env: String(process.env.REACT_APP_PLAID_ENV),
  product: ["transactions"],
  key: String(process.env.REACT_APP_PLAID_KEY),
  webhook: String(process.env.REACT_APP_PLAID_WEBHOOK),
  forceIframe: Boolean(true),
  apiVersion: String("v2"),
};

/**
 * Post Plaid public token to API
 * @param public_token
 * @param skipBudgetRecalc
 */
export const postPlaidToken = async (public_token: String, skipBudgetRecalc: Boolean = false) => {
  return await post(true, {
    endpoint: "/plaid/authenticate",
    bodyData: { public_token, skipBudgetRecalc },
  });
};

// function postToken(public_token, institution_name, skipRecalc, callback) {
//   var params = {
//     userId: StorageService.getItem("LG_UserLocalID"),
//     hhId: StorageService.getItem("LG_UserHHID"),
//     public_token: public_token,
//     skipBudgetRecalc: skipRecalc,
//   };
//   $log.debug("postToken par", params);
//   var cb = callback || angular.noop;
//   $http({
//     method: "POST",
//     url: Config.url + "/plaid/authenticate",
//     data: JSON.stringify(params),
//     headers: Config.AuthHeader,
//   }).then(
//     function mySucces(response) {
//       $log.debug("postToken", response);
//       ErrorHandler.responseHandler(response, function (response2) {
//         $log.debug("postToken2", response2);
//         setJustAddedAccounts(institution_name, response2.data);
//         cb(response2);
//       });
//     },
//     function myError(err) {
//       ErrorHandler.responseHandler(err, function (err2) {
//         cb(err2);
//       });
//     }
//   );
// }
