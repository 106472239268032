import { operations } from "../../types/schema";
import { SAVE_VERSION_CHECK, VERSION_CHECK_LOADING } from "../actionTypes";

const initialState: versionCheckState = {
  loading: false,
  versionCheck: {
    inviteWall: false,
    godModeInviteCode: "GDINV",
    recommendations: {
      active: false,
    },
    maintenance: {
      active: false,
    },
    subscriptionInfo: {
      active: false,
    },
    referralInfo: {
      active: false,
    },
    goalsInfo: {
      interestRate: 0.005,
      showDecimals: true,
    },
    savingsInfo: {},
  },
};

export interface versionCheckState {
  loading: boolean;
  versionCheck: versionCheckInfo;
}

export type versionCheckResponse =
  operations["api_versionCheck"]["responses"]["200"]["content"]["application/json"]["data"];

export interface versionCheckInfo {
  inviteWall: boolean;
  godModeInviteCode: string;
  recommendations: any;
  status?: VersionCheckStatus;
  maintenance: {
    active: boolean;
    msg?: string;
  };
  subscriptionInfo: any;
  referralInfo: any;
  goalsInfo: {
    interestRate: number;
    showDecimals: boolean;
  };
  savingsInfo: any;
}

export enum VersionCheckStatus {
  HARD = "HARD",
  SOFT = "SOFT",
  OK = "OK",
}

interface action {
  type: string;
  payload: any;
}

const versionCheck = (state: versionCheckState = initialState, action: action) => {
  switch (action.type) {
    case SAVE_VERSION_CHECK: {
      return {
        ...state,
        versionCheck: action.payload,
        loading: false,
      };
    }
    case VERSION_CHECK_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default versionCheck;
