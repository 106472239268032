import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IonButton,
  IonFooter,
  IonList,
} from "@ionic/react";
import numeral from "numeral";

import "./PaydayFunding.scss";

import { Goal } from "../../../types";
import {
  GOAL_STATUS,
  PRIVILEGE_TYPE_DATA_2,
  GOAL_FREQUENCY_TYPES,
  GOAL_TRIGGER_TYPES,
  GOAL_FUNDING_ACCOUNT_STATUS,
} from "../../../constants";
import { activateGoal } from "../../../redux/actions";
import { useDispatch } from "../../../redux/hooks";
import { useCurrentGoal } from "../../../utils/useCurrentGoal";
import { useUserName } from "../../shared/User/useUserName";
import { AmountInput } from "../../shared/AmountInput/AmountInput";
import { FundingAccountPickerButton } from "./FundingAccountPickerButton";
import { useNavigation } from "../../../utils/useNavigation";
import { NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { useViewport } from "../../../hooks/useViewport";
import { HelpButton } from "../../shared/Support/HelpButton";
import { PAGE_URLS } from "../../../constants";
import { trackEvent } from "../../../vendors/monitoring";
import { aOrAnFilter } from "../../../utils/formatUtils";

type PaydayFundingProps = {};
export const PaydayFunding: React.FC<PaydayFundingProps> = (props) => {
  const goal = useCurrentGoal() as Goal;
  const { isMobile } = useViewport();
  const { partnerName } = useUserName();
  const [triggerAmount, setTriggerAmount] = useState(50);
  const [depositPercent, setDepositPercent] = useState(0.1);
  const [targetAccountId, setTargetAccountId] = useState("");
  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const { t } = useTranslation();

  const save = () => {
    const params: any = {
      id: goal.id,
      contributionAmount: depositPercent,
      frequency: GOAL_FREQUENCY_TYPES.ADHOC,
      triggerType: GOAL_TRIGGER_TYPES.PAYDAY,
      triggerDetails: {
        minAmt: triggerAmount,
        targetAccounts: [targetAccountId],
        pctToSave: depositPercent,
      },
      fundingStatus: GOAL_FUNDING_ACCOUNT_STATUS.ACTIVE,
    };
    if (goal.status === GOAL_STATUS.NEW) {
      params.status = GOAL_STATUS.ACTIVE;
    }

    dispatch(activateGoal(params))
      .then((nextPage) => navigate(nextPage, "forward"))
      .then(() => trackEvent("finish_setupGoal", { category: "goals" }));
  };

  const footerSection = (
    <IonFooter className="ion-no-border ion-text-center">
      <IonButton
        className="ion-margin"
        disabled={!targetAccountId}
        onClick={save}
        data-testid="paydayFundingSubmit"
      >
        {t("save")}
      </IonButton>
    </IonFooter>
  );

  return (
    <PageTemplate
      contentProps={{ fullscreen: true, className: "payday-funding" }}
      header={
        <NavHeader title={t("savingsGoal")} defaultHref={PAGE_URLS.GOALS_HOME}>
          <HelpButton pageLocation="goal-payday-funding" className="fy-nav-buttons" color="" />
        </NavHeader>
      }
      footer={isMobile ? footerSection : undefined}
    >
      <Section>
        <div className="ion-margin font-title-3 fy-section-title-border">{t("saveForGoalRule")}</div>
        <div className="ion-margin fy-padding-vertical font-md">
          {t(goal?.hhInfo?.privilegeType === PRIVILEGE_TYPE_DATA_2.SHARED ? "thinkHowToSaveWithFamily" : "thinkHowMuchSave", {
            amount: numeral(goal.targetAmount).format("$0,0"),
            aOrAn: aOrAnFilter(goal.name),
            goalName: goal.name,
          })}
        </div>
        <IonList className="ion-no-">
          <AmountInput
            label={t("whenAmountDeposited")}
            initialValue={triggerAmount}
            mostCommon={50}
            step={25}
            min={25}
            max={500}
            onChange={setTriggerAmount}
          >
            <h3 className="">{t("weTransferMoney")}</h3>
          </AmountInput>
          <FundingAccountPickerButton
            goal={goal}
            selectedId={targetAccountId}
            onChange={setTargetAccountId}
            label={t("inThisAccount")}
          >
            <h3 className="ion-padding ion-margin-top">{t("selectPaycheckAccount")}</h3>
          </FundingAccountPickerButton>
          <AmountInput
            label={t("saveTowardsGoal")}
            initialValue={depositPercent * 100}
            mostCommon={10}
            step={1}
            min={1}
            max={30}
            format="0%"
            onChange={(value) => setDepositPercent(value / 100)}
          >
            <h3>{t("whenYouGetPayedTransferPercentage")}</h3>
          </AmountInput>{" "}
        </IonList>
        {isMobile ? null : footerSection}
      </Section>
    </PageTemplate>
  );
};
