import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonFooter,
  IonGrid,
  IonRow,
  IonToolbar,
  IonList,
  IonItem,
  IonCol,
  IonIcon,
} from "@ionic/react";
import { PAGE_URLS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getSpendingChallengeState,
  getHouseholdDataState,
  getUsersState,
} from "../../../redux/selectors";
import { useUserName } from "../../shared/User/useUserName";
import { checkmarkOutline } from "ionicons/icons";
import {
  updateCreateNewChanllengeParams,
  updateChallengeType,
  setLoading,
} from "../../../redux/actions";
import {
  getCategoryData,
  getChallengeTypeDetail,
  getSpendingChanllengeTypeDes,
} from "../../../utils/spendingChallenge";
import "./SpendingChallenge.scss";
import icons from "../../../assets/images/spending_challenge_icons";
import { NewSpendingChallengeMoreTypeModal } from "./NewSpendingChallengeMoreTypeModal";
import { useHistory } from "react-router-dom";
import { ChallengeType, SpendingChallengeFieldTypes } from "../../../types/spendingChallenge";
import images from "../../../assets/images/illustrations";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { trackEvent } from "../../../vendors/monitoring";

interface IchallType {
  categoryFilter: string;
  subCategoryFilter: string;
  display: string;
  des?: string | undefined;
  img?: string;
}
export const NewSpendingChallengeCategory: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { newSpendingChallenge } = useSelector(getSpendingChallengeState);
  const { userId } = useSelector(getUsersState);
  const { members } = useSelector(getHouseholdDataState);
  const { partnerName } = useUserName();

  const competitionType = newSpendingChallenge.competitionType
    ? newSpendingChallenge.competitionType
    : ChallengeType.INDIVIDUAL;
  //state
  const [selectedChalType, setSelectedChalType] = useState<IchallType | null>(null);
  const [chalTypes, setChalTypes] = useState<IchallType[]>([]);
  const [showSeeMoreBtn, setShowSeeMoreBtn] = useState(false);
  const [moreChalTypeModalOpen, setMoreChalTypeModalOpen] = useState(false);

  const constructTypes = async () => {
    const challTypes: Promise<IchallType>[] = [
      {
        categoryFilter: "Fun",
        subCategoryFilter: "Dining",
        display: "Dining",
      },
      {
        categoryFilter: "Fun",
        subCategoryFilter: "Alcohol",
        display: "Alcohol & Bars",
      },
      {
        categoryFilter: "Fun",
        subCategoryFilter: "Entertainment",
        display: "Entertainment",
      },
      {
        categoryFilter: "Transportation",
        subCategoryFilter: "Taxi",
        display: "Taxi & Car service",
      },
      {
        categoryFilter: "Personal",
        subCategoryFilter: "Shopping",
        display: "Wants and Desires",
      },
      {
        categoryFilter: "Fun",
        subCategoryFilter: "Coffee",
        display: "Coffee",
      },
    ].map(async (type) => {
      // specific category spend data
      const spendData = await getCategoryData(
        type.categoryFilter,
        type.subCategoryFilter,
        competitionType
      );
      // challenge detail string
      const result = getChallengeTypeDetail(type.subCategoryFilter, competitionType, spendData);
      return {
        categoryFilter: type.categoryFilter,
        subCategoryFilter: type.subCategoryFilter,
        display: t("spendLessOn", { category: type.display }),
        des: result,
        //@ts-ignore
        img: images[type.subCategoryFilter.toLowerCase()],
      };
    });

    return Promise.all(challTypes);
  };
  // Gets all spend data from the server
  const getChallTypes = async () => {
    const challTypes: IchallType[] = await constructTypes();
    setChalTypes(challTypes);
    setSelectedChalType(challTypes[0]);

    return challTypes;
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getChallTypes();
    dispatch(setLoading(false));
  }, [newSpendingChallenge]);

  const filteredChalType = !showSeeMoreBtn ? chalTypes.slice(0, 3) : chalTypes;
  //methods
  const validateSpendingChallengeData = () => {
    return !!newSpendingChallenge.competitionType;
  };

  const renderParamsForChallengeType = (challengeType: ChallengeType) => {
    switch (challengeType) {
      case ChallengeType.COLLABORATIVE:
        return {
          name: selectedChalType?.display,
          isPrivate: false,
          challengeType: "spending_category",
          targets: [
            {
              users: members.map((member: any) => {
                return { userId: member._id, status: "accepted" };
              }),
              matchingCriteria: [
                {
                  prefix: "hhInfo",
                  type: "string",
                  field: SpendingChallengeFieldTypes.CATEGORY,
                  value: selectedChalType?.categoryFilter,
                  matchType: "$eq",
                  matchTypeDetails: {
                    caseSensitive: false,
                    start: 0,
                    exactPosition: true,
                    wholeWord: true,
                  },
                },
                {
                  prefix: "hhInfo",
                  type: "string",
                  field: SpendingChallengeFieldTypes.SUBCATEGORY,
                  value: selectedChalType?.subCategoryFilter,
                  matchType: "$eq",
                  matchTypeDetails: {
                    caseSensitive: false,
                    start: 0,
                    exactPosition: true,
                    wholeWord: true,
                  },
                },
              ],
            },
          ],
          rewards: [
            {
              userIds: members.map((member: any) => member._id),
              type: "custom",
              details: { text: "" },
            },
          ],
        };
      case ChallengeType.COMPETITIVE:
        return {
          name: selectedChalType?.display,
          isPrivate: false,
          challengeType: "spending_category",
          targets: members.map((member: any) => ({
            users: [{ userId: member._id, status: "accepted" }],
            matchingCriteria: [
              {
                prefix: "hhInfo",
                type: "string",
                field: SpendingChallengeFieldTypes.CATEGORY,
                value: selectedChalType?.categoryFilter,
                matchType: "$eq",
                matchTypeDetails: {
                  caseSensitive: false,
                  start: 0,
                  exactPosition: true,
                  wholeWord: true,
                },
              },
              {
                prefix: "hhInfo",
                type: "string",
                field: SpendingChallengeFieldTypes.SUBCATEGORY,
                value: selectedChalType?.subCategoryFilter,
                matchType: "$eq",
                matchTypeDetails: {
                  caseSensitive: false,
                  start: 0,
                  exactPosition: true,
                  wholeWord: true,
                },
              },
            ],
          })),
          rewards: [
            {
              userIds: members.map((member: any) => member._id),
              type: "custom",
              details: { text: "" },
            },
          ],
        };
      case ChallengeType.INDIVIDUAL:
        return {
          name: selectedChalType?.display,
          isPrivate: true,
          challengeType: "spending_category",
          targets: [
            {
              users: [{ userId: userId, status: "accepted" }],
              matchingCriteria: [
                {
                  prefix: "hhInfo",
                  type: "string",
                  field: SpendingChallengeFieldTypes.CATEGORY,
                  value: selectedChalType?.categoryFilter,
                  matchType: "$eq",
                  matchTypeDetails: {
                    caseSensitive: false,
                    start: 0,
                    exactPosition: true,
                    wholeWord: true,
                  },
                },
                {
                  prefix: "hhInfo",
                  type: "string",
                  field: SpendingChallengeFieldTypes.SUBCATEGORY,
                  value: selectedChalType?.subCategoryFilter,
                  matchType: "$eq",
                  matchTypeDetails: {
                    caseSensitive: false,
                    start: 0,
                    exactPosition: true,
                    wholeWord: true,
                  },
                },
              ],
            },
          ],
          rewards: [
            {
              userIds: [userId],
              type: "custom",
              details: { text: "" },
            },
          ],
        };
      default:
        return {};
    }
  };

  const handleSpendingChalTypeNext = () => {
    if (!validateSpendingChallengeData()) {
      history.push(PAGE_URLS.SPENDING_CHALLENGES_NEW);
      return;
    }
    let params = renderParamsForChallengeType(competitionType);
    dispatch(updateCreateNewChanllengeParams(params));
    if (selectedChalType) {
      dispatch(
        updateChallengeType(selectedChalType.categoryFilter, selectedChalType.subCategoryFilter)
      );
    }
    trackEvent("select_subCat_spendChallenge", {
      category: "spendingChallenge",
      label: selectedChalType?.subCategoryFilter,
    });

    history.push(PAGE_URLS.SPENDING_CHALLENGES_NEW_LENGTH);
    setSelectedChalType(chalTypes[0]);
    setMoreChalTypeModalOpen(false);
  };

  return (
    <PageTemplate
      header={<NavHeader desktopTitle={t("spendingChallengeTitle")} />}
      footer={
        <IonFooter className="ion-no-border">
          <IonToolbar no-border>
            <div className="newSpendingChallenge-btns">
              {!showSeeMoreBtn ? (
                <h3 onClick={() => setShowSeeMoreBtn(true)} data-testid="seeMoreSpendingCatBtn">
                  {t("seeMoreOptions")}
                </h3>
              ) : null}
              <IonButton onClick={handleSpendingChalTypeNext} data-testid="newSpendingNextBtn2">
                {t("next")}
              </IonButton>
            </div>
          </IonToolbar>
        </IonFooter>
      }
    >
      <Section>
        <IonGrid>
          <IonRow>
            <h2 className="ion-margin-start font-lg">{t("spendingChallenge")}</h2>
          </IonRow>
          <IonRow>
            <h3 className="ion-margin-start font-md">
              {getSpendingChanllengeTypeDes(competitionType, partnerName)}
            </h3>
          </IonRow>
          <IonRow>
            <IonList
              lines="none"
              className="newSpendingChallenge"
              data-testid="newSpendingChallenge-categoryList"
            >
              {filteredChalType.map((type, index) => (
                <IonItem
                  key={index}
                  onClick={() =>
                    setSelectedChalType({
                      categoryFilter: type.categoryFilter,
                      subCategoryFilter: type.subCategoryFilter,
                      display: type.display,
                      des: type.des,
                    })
                  }
                  className={`ion-margin fy-padding-vertical-sm ${
                    selectedChalType?.subCategoryFilter === type.subCategoryFilter
                      ? "newSpendingChallenge-typeSelected"
                      : "newSpendingChallenge-type"
                  }`}
                  data-testid="newSpendingChallengeCategory"
                >
                  <IonCol size="1">
                    <img src={type.img} alt="spending challenge type" />
                  </IonCol>
                  <IonCol>
                    <IonRow>
                      <div className="fy-padding-vertical-xs font-md newSpendingChallenge-h4">
                        {type.display}
                      </div>
                    </IonRow>
                    <IonRow>
                      <div className="font-sm newSpendingChallenge-h5">{type.des}</div>
                    </IonRow>
                  </IonCol>
                  {selectedChalType?.subCategoryFilter === type.subCategoryFilter ? (
                    <IonCol size="1" className="edit-sharedStatus-icon">
                      <IonIcon icon={checkmarkOutline} />
                    </IonCol>
                  ) : null}
                </IonItem>
              ))}
              {showSeeMoreBtn ? (
                <IonItem className="ion-margin newSpendingChallenge-type">
                  <IonCol size="1">
                    <img src={images.others} alt="spending challenge type" />
                  </IonCol>
                  <IonCol className="ion-no-padding">
                    <IonRow
                      onClick={() => {
                        setMoreChalTypeModalOpen(true);
                        setShowSeeMoreBtn(false);
                        setSelectedChalType(chalTypes[0]);
                      }}
                    >
                      <h4
                        className="font-md newSpendingChallenge-h4"
                        data-testid="newSpendingChallengeCategory-other"
                      >
                        {t("other")}
                      </h4>
                    </IonRow>
                  </IonCol>
                </IonItem>
              ) : null}
            </IonList>
          </IonRow>
        </IonGrid>
        {moreChalTypeModalOpen ? (
          <NewSpendingChallengeMoreTypeModal
            isOpen={moreChalTypeModalOpen}
            onClose={() => setMoreChalTypeModalOpen(false)}
          />
        ) : null}
      </Section>
    </PageTemplate>
  );
};
