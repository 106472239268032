import React, { useEffect } from "react";
import { IonAvatar, IonCol, IonGrid, IonItem, IonList, IonProgressBar, IonRow } from "@ionic/react";
import { BudgetMonthHeader } from "./BudgetMonthHeader";
import { PAGE_URLS } from "../../../constants";
import { useParams, useHistory } from "react-router-dom";
import { BudgetURLParams, SpendingByPersonType } from "../../../types";
import { getMaxSpendingAmount } from "../../../utils/homeTab";
import { useSelector, useDispatch } from "react-redux";
import { getBudgetAnalyticsState, getUsersState } from "../../../redux/selectors";
import { getPersonAvatar } from "../../../utils/homeTab";
import { useTranslation } from "react-i18next";
import Avatars from "../../shared/Avatar/Avatars";
import "./SpendingByPerson.scss";
import { getSpendingByPersonData } from "../../../redux/actions";
import { useUserName } from "../../shared/User/useUserName";
import numeral from "numeral";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { getTransactionLink } from "../../../utils/transUtils";

export const SpendingByPersonDetail: React.FC = () => {
  const { effectiveDate, budgetType } = useParams<BudgetURLParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  //redux stata
  const { userId } = useSelector(getUsersState);
  const { partnerName, userName, singlePlayerMode } = useUserName();
  const spendingByPersonArray = useSelector(getBudgetAnalyticsState).spendingByPersonArray?.filter(
    (spending) => !singlePlayerMode || spending.id?.includes(userId)
  );

  const maxSpendingAmount = getMaxSpendingAmount(spendingByPersonArray);

  useEffect(() => {
    dispatch(getSpendingByPersonData(effectiveDate));
  }, [effectiveDate]);

  //method
  const handleSpendingByPersonExpenseFilterClick = (data: SpendingByPersonType) => {
    const transactionFilterArray = ["Just shared", "Just mine", "Just theirs"];
    const transactionFilter = transactionFilterArray[getPersonAvatar(data, userId)];
    history.push(getTransactionLink("advancedSearch", {
      transactionFilter,
      isPrivate: false,
      budgetMonth: effectiveDate,
    }));
  };

  const renderMemberName = (data: SpendingByPersonType, userId: string) => {
    let memberType = getPersonAvatar(data, userId);
    switch (memberType) {
      case 0:
        return t("householdLower");
      case 1:
        return `${userName}'s`;
      case 2:
        return `${partnerName}'s`;
      default:
        break;
    }
  };

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={
        <NavHeader
          desktopTitle={t("spendingByPersonTitle")}
          subheader="useTitle"
          title={
            <BudgetMonthHeader
              effectiveDate={effectiveDate}
              baseUrl={PAGE_URLS.SPENDING_BY_PERSON_HOME}
              budgetType={budgetType}
            />
          }
        ></NavHeader>
      }
    >
      <Section>
        <IonList
          className="flex-column ion-justify-content-start spending-list"
          data-testid="SpendingByPersonList"
        >
          {spendingByPersonArray.map((data) => {
            return (
              <IonItem
                lines="none"
                key={data.id}
                onClick={() => handleSpendingByPersonExpenseFilterClick(data)}
              >
                <IonAvatar slot="start">
                  <Avatars
                    avatarType={getPersonAvatar(data, userId)}
                    sizeStyle="sm"
                    diagonal={true}
                  />
                </IonAvatar>
                <IonGrid>
                  <IonCol>
                    <IonRow className="font-md">
                      {numeral(data.Expenses.amountActual).format("$0,0")}
                    </IonRow>
                    <IonRow className="spendingByPersonProgressBar">
                      <IonProgressBar
                        value={data.Expenses.amountActual / maxSpendingAmount}
                      ></IonProgressBar>
                    </IonRow>
                    <IonRow>
                      <h3 className="ion-no-margin font-sm">{`${t("view")} ${renderMemberName(
                        data,
                        userId
                      )} ${t("transactionsLower")}`}</h3>
                    </IonRow>
                  </IonCol>
                </IonGrid>
              </IonItem>
            );
          })}
        </IonList>
      </Section>
    </PageTemplate>
  );
};
