import { IonLoading } from "@ionic/react";
import React from "react";

interface MainLoadingUiProps {}

const MainLoadingUi: React.FC<MainLoadingUiProps> = () => {
  return (
    <>
      <IonLoading isOpen={true} />
    </>
  );
};

export default MainLoadingUi;
