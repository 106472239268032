import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonList,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonAlert,
} from "@ionic/react";

import { useSelector } from "react-redux";
import { getAccountsState } from "../../../redux/selectors";
import { useTranslation } from "react-i18next";
import { AccountLogo } from "../../pages/AccountsTab/AccountLogo";
import { updateShareSettings } from "../../../utils/financialAccounts";
import { PRIVILEGE_TYPE_DATA_2, ACCOUNT_OWNER_TYPE } from "../../../constants";
import { SetSharedModal } from "../../shared/Link/SetSharedModal";
import { useDispatch } from "react-redux";
import { getMyAccountsData } from "../../../redux/actions";
import { useUserName } from "../../shared/User/useUserName";
import { formatAccountNumber } from "../../../utils/formatUtils";
import "../../pages/Onboarding/onboarding.scss";
import { Account } from "../../../redux/reducers/accounts";
import { trackEvent } from "../../../vendors/monitoring";

type ShareSettingsAccountListProps = {
  selectedBank?: any;
  index?: number;
  useLastLinkedAccount?: boolean;
};

export const ShareSettingsAccountList: React.FC<ShareSettingsAccountListProps> = ({
  selectedBank,
  index,
  useLastLinkedAccount = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { myAccounts, lastLinkedAccounts } = useSelector(getAccountsState);
  const { partnerName } = useUserName();
  const [shareAllAlert, setShareAllAlert] = useState<boolean>(false);
  const [institution, setInstitution] = useState<any>(selectedBank);

  useEffect(() => {
    if (useLastLinkedAccount) {
      const [exampleAcctId] = Object.keys(lastLinkedAccounts);
      if (!exampleAcctId) {
        return;
      }
      myAccounts.map((bank: any) => {
        bank.subBanks.map((acct: Account) => {
          if (acct._id === exampleAcctId) {
            setInstitution(bank);
          }
        });
      });
    } else {
      setInstitution(selectedBank);
    }
  }, [lastLinkedAccounts, myAccounts, selectedBank]);

  const handleAllJointConfirm = async () => {
    const accountIds = institution.subBanks.map((a: any) => !a.jointIgnore && a._id);
    await updateShareSettings({
      accountIds,
      accountStatus: PRIVILEGE_TYPE_DATA_2.SHARED,
      ownership: ACCOUNT_OWNER_TYPE.JOINT,
      balVisibility: true,
    });
    dispatch(getMyAccountsData());
  };

  return (
    <>
      {institution && (
        <IonList lines="none">
          <IonItem>
            <AccountLogo account={institution} />
            <span className="font-sm">{institution.bank}</span>
            {index !== undefined && (
              <h4 slot="end" className="font-sm placeholder-text">{`${index + 1} of ${myAccounts.length
                }`}</h4>
            )}
          </IonItem>
          {institution.subBanks.map((account: any) => {
            return <LinkedAccountItem key={account._id} account={account}></LinkedAccountItem>;
          })}
        </IonList>
      )}

      <IonButton
        color="secondary"
        fill="clear"
        onClick={() => {
          setShareAllAlert(true);
          trackEvent("mark_allJoint", { category: "onboard" });
        }}
        data-testid="markAllJoint"
      >
        {t("markAllJoint")}
      </IonButton>

      <IonAlert
        isOpen={shareAllAlert}
        onDidDismiss={() => setShareAllAlert(false)}
        header={t("shareAllTitle")}
        message={t("shareAllMsg", { partnerName })}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Okay",
            handler: handleAllJointConfirm,
          },
        ]}
      />
    </>
  );
};

enum SharedStatus {
  IGNORE = "ignore",
  NONE = "",
}

type LinkedAccountItemProps = {
  account: any;
};

const LinkedAccountItem: React.FC<LinkedAccountItemProps> = ({ account }) => {
  const { t } = useTranslation();
  const [openSetSharedModal, setOpenSetSharedModal] = useState(false);
  const [sharedStatus, setSharedStatus] = useState(SharedStatus.NONE);
  const dispatch = useDispatch();

  useEffect(() => {
    if (account.jointIgnore || account.hhInfo[0].privilegeType === PRIVILEGE_TYPE_DATA_2.IGNORE) {
      setSharedStatus(SharedStatus.IGNORE);
    } else {
      setSharedStatus(account.hhInfo[0].accountOwnershipType);
    }
  }, [account]);

  const handleSegment = async ({ detail }: any, accountId: string) => {
    const { value } = detail;

    setSharedStatus(value);
    if (value === ACCOUNT_OWNER_TYPE.JOINT) {
      await updateShareSettings({
        accountIds: [accountId],
        accountStatus: value === ACCOUNT_OWNER_TYPE.JOINT ? PRIVILEGE_TYPE_DATA_2.SHARED : "1",
        ownership: value,
        balVisibility: value === ACCOUNT_OWNER_TYPE.JOINT,
      });
      dispatch(getMyAccountsData());
    }
  };

  return (
    <IonItem className="share-linked-account-item">
      <IonGrid className="ion-no-padding ion-padding-top">
        <IonRow>
          <div>
            <h4 className="font-sm text-no-wrap">{account.accountName}</h4>
            <h4 className="font-sm text-no-wrap">{formatAccountNumber(account.accountNumber)}</h4>
          </div>
          <div className="ml-auto">
            <IonSegment
              className="ion-no-margin"
              mode="ios"
              onIonChange={(e) => handleSegment(e, account._id)}
              value={sharedStatus}
            >
              {sharedStatus === SharedStatus.IGNORE ? (
                <IonSegmentButton
                  mode="ios"
                  onClick={() => setSharedStatus(SharedStatus.NONE)}
                  value={SharedStatus.IGNORE}
                >
                  <IonLabel className="font-xs">{t`ignored`}</IonLabel>
                </IonSegmentButton>
              ) : (
                <>
                  <IonSegmentButton
                    data-testid={`${account.sourceInstitutionBaseId}-${account.accountNumber}-joint`}
                    value={ACCOUNT_OWNER_TYPE.JOINT}
                  >
                    <IonLabel className="font-xs">{t`joint`}</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    mode="ios"
                    data-testid={`${account.sourceInstitutionBaseId}-${account.accountNumber}-indiv`}
                    onClick={() => setOpenSetSharedModal(true)}
                    value={ACCOUNT_OWNER_TYPE.INDIVIDUAL}
                  >
                    <IonLabel className="font-xs">{t`indiv`}</IonLabel>
                  </IonSegmentButton>
                </>
              )}
            </IonSegment>
          </div>
        </IonRow>
      </IonGrid>

      <SetSharedModal
        account={account}
        isOpen={openSetSharedModal}
        onClose={() => setOpenSetSharedModal(false)}
        showJointIndividual
      ></SetSharedModal>
    </IonItem>
  );
};
