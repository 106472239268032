import { Dispatch } from "redux";
import * as types from "../actionTypes";
import { get } from "../../utils/apiOps";
import { cloneDeep, remove } from "lodash";
import { Bill, BillsData } from "../../types";
import dayjs from "dayjs";

export const setBills = (billsDataObj: BillsData[]) => {
  return {
    type: types.SET_BILLS,
    payload: billsDataObj,
  };
};

export const setBillsLoading = (status: boolean = true) => {
  return {
    type: types.BILLS_LOADING,
    payload: status,
  };
};

interface getBillsFields {
  effectiveDate: string;
  forceRefresh?: boolean;
}

export const getBills =
  (fields: getBillsFields) => async (dispatch: Dispatch<any>, getState: any) => {
    console.debug("getBills", fields);
    const { billsData } = getState().bills;
    const billsForThisDate = billsData.find(
      (item: BillsData) => item.effectiveDate === fields.effectiveDate
    );
    if (!billsForThisDate || fields.forceRefresh) {
      dispatch(getBillsFromAPI(fields));
    }
  };

const getBillsFromAPI = (fields: any) => async (dispatch: Dispatch<any>, getState: any) => {
  dispatch(setBillsLoading(true));
  const { billsData } = getState().bills;
  await get(true, { endpoint: "/bills", params: { ...fields, includePaid: true } })
    .then(({ data }) => {
      // scope.bills.sort((a, b) => new Date(a.sortDate) - new Date(b.sortDate));
      const newBillsDataObj = {
        billsArray: addDate(fields.effectiveDate, data),
        effectiveDate: fields.effectiveDate,
      };
      const newBillsData = cleanseBillsArray(billsData, newBillsDataObj);
      dispatch(setBills(newBillsData));
    })
    .catch(() => {});
};

// const formatBillsDates = () => {
//   const endOfMonth = dayjs(startOfWeek).endOf("month");
//   const billDay = endOfMonth.date() < bill.day ? endOfMonth.date() : bill.day;

//   let disDate = dayjs(startOfWeek).date(billDay);
//   if (!sameMonth) {
//     if (startOfWeek.isAfter(disDate)) {
//       disDate = dayjs(endOfWeek).date(billDay);
//     } else if (endOfWeek.isBefore(disDate)) {
//       disDate = dayjs(startOfWeek).date(billDay);
//     }
//   }
//   bill.sortDate = disDate.toDate();
//   bill.displayDate = dayjs(disDate).format("ddd, MMM D");
// };

interface UpdateBillProps {
  billId: string;
  updateData: BillUpdateData[];
  effectiveDate?: string;
}
type BillUpdateData = {
  field: string;
  value: any;
};
/**
 * Update one bill
 */
export const updateBill =
  ({ billId, updateData, effectiveDate }: UpdateBillProps) =>
  async (dispatch: Dispatch<any>, getState: any) => {
    const { billsData } = getState().bills;
    const newBillsArr: BillsData[] = cloneDeep(billsData);
    let billsToUpdate = newBillsArr;
    if (effectiveDate) {
      billsToUpdate = newBillsArr.filter((d) => d.effectiveDate === effectiveDate);
    }
    billsToUpdate.map((billsDataItem) => {
      const thisBill = billsDataItem.billsArray.find((b) => b._id === billId);
      if (thisBill) {
        updateData.map(({ field, value }) => {
          // @ts-ignore
          thisBill[field] = value;
        });
      }
    });
    dispatch(setBills(newBillsArr));
  };

/**
 * Adds the display date to the bills
 */
const addDate = (effectiveDate: string, billsArray: Bill[]) => {
  const newBillsArray = cloneDeep(billsArray);
  newBillsArray.map((b) => {
    const eom = dayjs(effectiveDate).endOf("month").date();
    if (b.day > eom) {
      b.day = eom;
    }
    b.displayDate = dayjs(effectiveDate).date(b.day).format("MM/DD/YYYY");
    b.sortDate = Number(dayjs(effectiveDate).date(b.day));
    calcOverUnder(b);
  });
  return newBillsArray;
};

/**
 * Calculate if bills has been over or under paid
 * @param {Object} bill
 * @param {String} bill.status
 */
function calcOverUnder(bill: Bill) {
  if (bill.status !== "paid") {
    return;
  }
  bill.amountOver = (bill.amountActual || 0) - bill.amount;
  bill.showOver = Math.round(Math.abs(bill.amountOver)) > 0;
}

/**
 * Ensures that there's only one bills for that effectiveDate in Redux
 * @param billsArray
 * @param payload
 * @returns new bills array
 */
const cleanseBillsArray = (billsArray: BillsData[], billsObj: BillsData) => {
  const newBillsArr = [...billsArray];
  remove(newBillsArr, (b) => b.effectiveDate === billsObj.effectiveDate);
  newBillsArr.push({ ...billsObj });
  return newBillsArr;
};
