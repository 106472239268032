import React from 'react';
import { IonList, IonTitle, IonListHeader, IonItem } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import './Policies.scss';

import { EXTERNAL_URLS, PAGE_URLS } from '../../../constants';
import { PageTemplate, Section, NavHeader } from '../../shared/PageTemplate';

export const Policies: React.FC = props => {
  const { t } = useTranslation();
  return <PageTemplate
    pageProps={{ className: "policies-page" }}
    header={<NavHeader defaultHref={PAGE_URLS.MORE_HOME} title={t("policies")} />}
  >
    <Section>
      <IonList className="policy-links-list">
        <IonListHeader>
          <IonTitle className="ion-no-padding ion-margin-bottom">
            {t("firstlysPolicies")}
          </IonTitle>
        </IonListHeader>
        <IonItem lines="none" className="ion-margin-top">
          <a href={EXTERNAL_URLS.TERMS_AND_CONDITIONS}>
            • {t("termsOfUse")}
          </a>
        </IonItem>
        <IonItem lines="none">
          <a href={EXTERNAL_URLS.PRIVACY_POLICY}>
            • {t("privacyPolicy")}
          </a>
        </IonItem>
      </IonList>
    </Section>
  </PageTemplate>
}