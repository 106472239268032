/**
 * NOTE: window.Intercom (JS) is bundled with segment JS
 * we use the intercom plugin to manage the mobile implementation
 * events are handled by the JS in both mobile and web
 *
 */
import { Intercom } from "@ionic-native/intercom";
import { DeviceInfo, Plugins } from "@capacitor/core";

const { Device } = Plugins;
const mobilePlatforms = ["ios", "android"];
let isMobile = false;

type intercomUserDetails = {
  userId: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  hhIdDefault?: string;
  memberType?: string;
};

export const boot = async () => {
  Device.getInfo().then((deviceInfo: DeviceInfo) => {
    isMobile = mobilePlatforms.includes(deviceInfo.platform) ? true : false;

    if (!isMobile && (window as any).Intercom) {
      (window as any).Intercom("onUnreadCountChange", function (unreadCount: number) {
        console.debug("TODO: update redux with unread ct");
      });
    }
  });
};

export const intercomLogout = async () => {
  if (isMobile) {
    Intercom.logout();
    intercomRegisterAnonUser();
  } else {
    (window as any).Intercom && (window as any).Intercom("shutdown");
  }
};

export const openChat = async () => {
  if (isMobile) {
    Intercom.displayMessenger();
  } else {
    // this is added by segment
    (window as any).Intercom && (window as any).Intercom("show");
  }
};

export const openChatWithMessage = async (message: string) => {
  if (isMobile) {
    Intercom.displayMessageComposerWithInitialMessage(message);
  } else {
    // this is added by segment
    (window as any).Intercom && (window as any).Intercom("show");
  }
};

export const intercomGetUnreadConversationCt = async () => {
  if (isMobile) {
    return Intercom.unreadConversationCount();
  } else {
    // this is added by segment
    console.debug("getUnread - this is handled by the handler?");
  }
};

export const openFAQ = async () => {
  if (isMobile) {
    Intercom.displayHelpCenter();
  } else {
    //doesn't seem to be a way to open the FAQs directly with JS library
    let url = "http://help.firstly.com";
    window.open(encodeURI(url), "_blank", "location=no");
  }
};

export const intercomUpdateUser = (params: any) => {
  if (isMobile) {
    Intercom.updateUser(params);
  } else {
    (window as any).Intercom && (window as any).Intercom("update", params);
  }
};

// Use to register an anonymous user with mobile plugin only - web is handled by JS
export const intercomRegisterAnonUser = () => {
  if (isMobile) {
    Intercom.registerUnidentifiedUser({})
      .then((res) => {
        console.debug("intercom registerUnidentifiedUser res: ", res);
      })
      .catch((err) => {
        console.debug("intercom registerUnidentifiedUser err: ", err);
      });
  }
};

// Use to registerUser with mobile plugin only - web is handled by JS
export const intercomRegisterUser = async (userDetails: intercomUserDetails) => {
  if (isMobile) {
    Intercom.registerIdentifiedUser(userDetails)
      .then((res) => {
        console.debug("intercom registerIdentifiedUser res: ", res);
      })
      .catch((err) => {
        console.debug("intercom registerIdentifiedUser err: ", err);
      });
  }
};
