import * as Sentry from "@sentry/react";
import {
  Capacitor,
  DeviceInfo,
  DeviceLanguageCodeResult,
  NetworkStatus,
  Plugins,
} from "@capacitor/core";
import { userState } from "../redux/reducers/users";
import { intercomLogout, intercomRegisterUser } from "../vendors/intercom";
import { attributionLogout, setAppUserId } from "./attribution";
import { onboardingFlags } from "../constants";

const { Device, Network } = Plugins;

let analyticsAnonymousId: string | null = null;
let analyticsUserId: string | null = null;
let deviceInfo: null | DeviceInfo = null;
let locale: null | DeviceLanguageCodeResult = null;
let connectionInfo: null | NetworkStatus = null;

export type segmentIntegrationsOptions = {
  All?: boolean;
  Mixpanel?: boolean;
  Intercom?: boolean;
  "Google Analytics"?: boolean;
};

Network.addListener("networkStatusChange", (connInfo) => {
  connectionInfo = connInfo;
});

//init monitoring
export const init = () => {
  try {
    console.debug("init monitoring");
    Network.getStatus()
      .then((connInfo) => {
        connectionInfo = connInfo;
      })
      .catch((e) => console.error(e));
    Device.getInfo().then((deviceInfoResp) => {
      Device.getLanguageCode()
        .then((deviceLanguageResp) => {
          locale = deviceLanguageResp;
        })
        .catch((e) => console.error(e));
      deviceInfo = deviceInfoResp;
      analyticsAnonymousId = deviceInfo.uuid;
    });
  } catch (err) {
    console.error(err);
  }
};

export const setMonitoringUser = async (user: Partial<userState>) => {
  if (user?.userId) {
    console.debug("setUser - userId");
    analyticsUserId = user.userId;
    Sentry.setUser({ id: user.userId, email: user.email, ip_address: "{{auto}}" });
    analytics.identify(analyticsUserId, {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      hhIdDefault: user.hhIdDefault,
      memberType: user.memberType || undefined,
      createdAt: user.createdOn,
      finished_onboarding: user.flag === onboardingFlags.FINISHED_ONBOARDING,
    });
    intercomRegisterUser({
      userId: analyticsUserId,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      hhIdDefault: user.hhIdDefault,
      memberType: user.memberType || undefined,
    });
    setAppUserId(user.userId);
  } else {
    console.debug("setUser - anonymous");
    Sentry.setUser({ ip_address: "{{auto}}" });
    analytics.reset();
    intercomLogout();
    attributionLogout();
  }
};

export const identifyUser = (user: Partial<userState>, regPerson: boolean, action: any) => {
  if (action === "appOpen") {
    //coming from app.js and isAuthenticated
    trackEvent("opened app", { metricType: "usage" });
  }
  if (action === "login") {
    trackEvent("Tapped login", { metricType: "usage" });
  }
  if (user.userId) {
    // mixpanel.identify(user.userId);
  }
};

export const getContext = async () => {
  const context: any = {
    app: {
      name: "Firstly",
      version: deviceInfo?.appVersion,
      build: deviceInfo?.appBuild,
    },
    device: {
      id: deviceInfo?.uuid,
      manufacturer: deviceInfo?.manufacturer,
      model: deviceInfo?.model,
      name: deviceInfo?.name,
      isVirtual: deviceInfo?.isVirtual, // extra - test
      platform: deviceInfo?.platform, // extra - test
    },
    network: {
      cellular: connectionInfo?.connectionType === "cellular",
      wifi: connectionInfo?.connectionType === "wifi",
    },
    os: {
      name: deviceInfo?.operatingSystem,
      version: deviceInfo?.osVersion,
    },
    locale: locale?.value,
  };
  const eventProps = {
    "Device ID": deviceInfo?.uuid,
    Platform: deviceInfo?.platform,
    isVirtual: deviceInfo?.isVirtual,
  };
  return {
    context,
    eventProps,
  };
};

export const trackEvent = async (
  name: any,
  eventPropsFromComponent: any = {},
  integrations: segmentIntegrationsOptions = {}
) => {
  try {
    console.debug("trackEvent: ", name, eventPropsFromComponent, analyticsUserId);
    const { context, eventProps } = await getContext();
    const finalEventProps = { ...eventPropsFromComponent, ...eventProps };
    analytics.track(name, finalEventProps, {
      integrations: integrations,
      context: context,
    });
  } catch (err) {
    console.error("Error recording segment event", err);
  }
};

export const reset = () => {
  analytics.reset();
};
