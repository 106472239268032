import React from "react";
import { history } from "../../../history";
import { useDispatch, useSelector } from "react-redux";
import { useIonAlert } from "@ionic/react";
import { pushPermissionNeverAgain } from "../../../redux/actions";
import { getAlertUiState } from "../../../redux/selectors";
import { AlertButton } from "@ionic/core/dist/types/components/alert/alert-interface";
import { AlertBoxType, AlertActionType, alertDataType } from "../../../redux/reducers/alertUi";
import { Dispatch } from "redux";
import { checkToAskForPushPermissions } from "../../../utils/pushUtils";

export const AlertUi: React.FC = () => {
  const { showAlert, alertData } = useSelector(getAlertUiState);
  const dispatch = useDispatch();
  const [present, dismiss] = useIonAlert();

  const getButtons = (
    alertBoxType: AlertBoxType,
    alertData: alertDataType,
    history: any,
    dispatch: Dispatch
  ) => {
    const buttons = [] as AlertButton[];

    switch (alertBoxType) {
      case AlertBoxType.OK_ONLY:
        break;
      case AlertBoxType.YES_ALT_NO:
      case AlertBoxType.YES_NO:
        if (alertData.negativeButton) {
          buttons.push({
            text: alertData.negativeButton.text,
            role: alertData.negativeButton.role || "cancel",
            handler: alertBoxActionSwitch(alertData.negativeButton.action, null, history, dispatch),
          });
        }
        if (alertData.alternativeButton) {
          buttons.push({
            text: alertData.alternativeButton.text,
            role: "submit",
            handler: alertBoxActionSwitch(
              alertData.alternativeButton.action,
              null,
              history,
              dispatch
            ),
          });
        }
    }
    buttons.push({
      text: alertData.positiveButton.text,
      handler: alertBoxActionSwitch(
        alertData.positiveButton.action,
        alertData.positiveButton.payload,
        history,
        dispatch
      ),
    });
    return buttons;
  };

  const alertBoxActionSwitch = (
    action: AlertActionType,
    payload: any,
    history: any,
    dispatch: Dispatch<any>
  ) => {
    switch (action) {
      case AlertActionType.GO_TO_PAGE:
        return () => {
          history.push(payload.page);
        };
      case AlertActionType.SOFT_PUSH_PERMISSION:
        return () => dispatch(checkToAskForPushPermissions(true));

      case AlertActionType.SOFT_PUSH_NEVER_AGAIN:
        return () => dispatch(pushPermissionNeverAgain());

      case AlertActionType.DO_NOTHING:
      default:
        return () => {};
    }
  };

  const generatedButtons = getButtons(alertData.alertType, alertData, history, dispatch);

  const handlePresent = async () => {
    await dismiss();
    present({
      header: alertData.header,
      subHeader: alertData.subHeader,
      message: alertData.message,
      buttons: generatedButtons,
      cssClass: `alert-title-format`,
    });
  };

  if (showAlert && alertData.message) {
    handlePresent();
  }

  return null;
};

export default AlertUi;
