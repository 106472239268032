import React, { useState, useRef, useLayoutEffect, createContext, useContext } from "react";
import debounce from "lodash/debounce";

type Viewport = "mobile" | "desktop" | "desktop_large";
const getViewport = () => (window.innerWidth >= 940 ? window.innerWidth >= 1440 ? "desktop_large" : "desktop" : "mobile");
const ViewportContext = createContext<Viewport>(getViewport());

export const ViewportProvider: React.FC = (props) => {
  const { children } = props;
  const [viewport, setViewport] = useState<Viewport>(getViewport);
  const ref = useRef(viewport);
  useLayoutEffect(() => {
    const handler = debounce(
      () => {
        const newViewport = getViewport();
        if (newViewport !== ref.current) {
          // besides for state, we need a ref so that we can access the prev-viewport as a closure;
          ref.current = newViewport;
          setViewport(newViewport);
        }
      },
      60,
      { leading: true, trailing: true }
    );
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
      handler();
    };
  }, []);

  return <ViewportContext.Provider value={viewport}>
    {children}
  </ViewportContext.Provider>;
};

export const useViewport = () => {
  const viewport = useContext(ViewportContext);
  return {
    viewport,
    isMobile: viewport === "mobile",
    isDesktop: viewport === "desktop" || viewport === "desktop_large",
    isLargeDesktop: viewport === "desktop_large",
  };
};
