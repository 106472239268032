import React from "react";
import { IonItem } from "@ionic/react";
import { BudgetSuperCategory, BudgetType, Category } from "../../../types";
import { PAGE_URLS } from "../../../constants";
import { BudgetDetailItem } from "./BudgetDetailItem";
import { trackEvent } from "../../../vendors/monitoring";

interface BudgetCategoryDetailItemProps {
  category: Category;
  effectiveDate: string;
  budgetType: BudgetType;
  superCategory: BudgetSuperCategory;
  buttonIcon?: any;
}

export const BudgetCategoryDetailItem: React.FC<BudgetCategoryDetailItemProps> = ({
  category,
  effectiveDate,
  budgetType,
  superCategory,
  buttonIcon,
}) => {
  return (
    <IonItem
      className="BudgetDetailItem"
      lines="none"
      routerLink={PAGE_URLS.BUDGET_DETAILS.replace(":effectiveDate", effectiveDate)
        .replace(":budgetType", budgetType)
        .replace(":category", category.categoryShortName)}
      detail={false}
      data-testid={`budgetCat_${category.categoryShortName}`}
      onClick={() =>
        trackEvent("view_categorization_budget", {
          category: "categorization",
          budgetCategory: category.categoryFullName,
        })
      }
    >
      <BudgetDetailItem
        category={category}
        effectiveDate={effectiveDate}
        budgetType={budgetType}
        superCategory={superCategory}
        buttonIcon={buttonIcon}
      />
    </IonItem>
  );
};
