import React from "react";
import { useSelector } from "react-redux";
import { getHouseholdDataState, getUsersState } from "../../../redux/selectors";
import { useUserName } from "../../shared/User/useUserName";
import constants from "../../../constants";
import "./Avatars.scss";

const settings = {
  textColor: "#ffffff",
  fontsize: 20, // unit in pixels
  height: 32, // unit in pixels
  width: 32, // unit in pixels
  fontWeight: 400, //
  fontFamily:
    "'sf_ui_display_bold', HelveticaNeue-Light,Helvetica Neue Light,Helvetica Neue,Helvetica, Arial,Lucida Grande, sans-serif",
  base: "data:image/svg+xml;base64,",
  fallbackName: "P",
  userColor: "#72C4BD",
  partnerColor: "#5BC6F9",
};
interface AvatarProps {
  isUser: boolean;
  className?: string;
}

const Avatar: React.FC<AvatarProps> = ({ isUser, className }) => {
  const { userName, partnerName } = useUserName();
  const { imageURL } = useSelector(getUsersState);
  const { primaryPartner } = useSelector(getHouseholdDataState);

  const useImage = Boolean(
    (isUser && imageURL && imageURL !== constants.NO_IMAGE) ||
      (!isUser && primaryPartner?.imageURL && primaryPartner.imageURL !== constants.NO_IMAGE)
  );

  const imageToUse = isUser ? imageURL : primaryPartner?.imageURL;
  const color = isUser ? settings.userColor : settings.partnerColor;
  const name = isUser ? userName || settings.fallbackName : partnerName || settings.fallbackName;

  return (
    <div className="avatar-container" data-testid="user-avatar">
      {useImage ? (
        <div className={"user-avatar ion-text-center " + className}>
          <img src={imageToUse} alt="user_image"></img>
        </div>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          pointerEvents="none"
          width={settings.width}
          height={settings.height}
          style={{ backgroundColor: color }}
          className={className + " user-avatar"}
        >
          <text
            y="50%"
            x="50%"
            dy="0.35em"
            pointerEvents="auto"
            fill={settings.textColor}
            textAnchor="middle"
            fontFamily={settings.fontFamily}
            fontWeight={settings.fontWeight}
            fontSize={settings.fontsize}
          >
            {name.substr(0, 1).toUpperCase()}
          </text>
        </svg>
      )}
    </div>
  );
};

export default Avatar;
