import React from "react";

import "./Confetti.scss";

export const Confetti: React.FC = (props) => {
  const { children } = props;
  return (
    <div className="confetti-wrapper">
      {new Array(150).fill(null).map((e, i) => (
        <div key={i} className={`confetti confetti-${i}`} />
      ))}
      {children}
    </div>
  );
};
