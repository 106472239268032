import dayjs from "dayjs";
import map from "lodash/map";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import keyBy from "lodash/keyBy";

import { FINANCIAL_ACCOUNT_STATUS, NET_WORTH_FACTOR } from "../constants";
import { Account, AllBalances, BalanceItem, Balance } from "../redux/reducers/accounts";

export interface MonthlyBalance {
  month: string;
  accounts: AccountBalance[];
  "Net Worth": number;
  Debts: number;
  Assets: number;
}

export interface AccountBalance {
  accountId: string;
  accountName: string;
  accountNumber: string;
  institutionName: string;
  isAsset: boolean;
  logo?: string | null;
  source: string;
  balance: number;
  isAvailableBalance: boolean; // temporary
  month: string;
}

export const getAccountBalancesByMonth = function getAccountBalancesByMonth(
  allBalances: AllBalances,
  isPrivate: boolean,
  monthLimit?: number
): MonthlyBalance[] {
  const owner = isPrivate ? "user" : "hh";
  if (!allBalances || !allBalances[owner]) return [];
  const visibleAccounts = allBalances[owner].all.accts.filter(
    (acct) => acct.balanceHistory && acct.accountStatus === FINANCIAL_ACCOUNT_STATUS.ACTIVE
  );
  // map account balances to monthly balance object (without tallying)
  // Note that there will still be duplicates by month
  const allMonthlyBalances = _getMonthlyAccountBalances(visibleAccounts);

  // group all account balances by month;
  let balancesByMonth = sortBy(groupBy(allMonthlyBalances, "month"), (items) => items[0].month);
  balancesByMonth = balancesByMonth.map(_withoutPendingBalances);
  const realData = balancesByMonth.map(_tally);
  return _combineWithFillerData(realData, monthLimit);
};

const _getMonthlyAccountBalances = function _getMonthlyAccountBalances(
  visibleAccounts: Account[]
): AccountBalance[] {
  return visibleAccounts.flatMap((account) => {
    return ["current", "available"].flatMap((availability: any) => {
      return map((account?.balanceHistory[availability] as unknown) as BalanceItem, (value) => ({
        accountId: account._id,
        month: dayjs(value.mY).add(1, "month").format("YYYY-M"),
        accountName: account.accountNickname || account.accountName || account.name,
        accountNumber: account.accountNumber,
        institutionName: account.institutionName,
        isAsset: account.isAsset,
        logo: account.logo,
        source: account.source,
        balance: Math.abs(value.amt) * (account.isAsset ? 1 : -1),
        isAvailableBalance: availability === "available",
      }));
    });
  });
};

const _withoutPendingBalances = function _withoutPendingBalances(
  balancesForMonth: AccountBalance[]
) {
  const availableBalances = new Set(
    balancesForMonth.map((b) => (b.isAvailableBalance ? b.accountId : ""))
  );
  const currentBalances = new Set(
    balancesForMonth.map((b) => (!b.isAvailableBalance ? b.accountId : ""))
  );
  return balancesForMonth.filter((b) =>
    b.isAvailableBalance
      ? // credit cards should not use available balance (unless there is no current balance)
        // (since available balance might just be remaining credit line)
        b.isAsset || !currentBalances.has(b.accountId)
      : // only include currentBalances where there is no availableBalance (or if it's a credit card)
        !availableBalances.has(b.accountId) || !b.isAsset
  );
};

const _generateFillerData = function _generateFillerData(minMonths: number): MonthlyBalance[] {
  return Array(minMonths)
    .fill(null)
    .map((_, monthsAgo) => ({
      month: dayjs().subtract(monthsAgo, "months").format("YYYY-M"),
      accounts: [],
      "Net Worth": 0,
      Debts: 0,
      Assets: 0,
    }))
    .reverse();
};

const _combineWithFillerData = function _combineWithFillerData(
  realData: MonthlyBalance[],
  monthLimit?: number
) {
  // if no limit is specified, display all available months plus three filler months
  const _firstMonthWithData = realData[0] && realData[0].month;
  const _firstMonth = dayjs(_firstMonthWithData).subtract(4, "months").startOf("month");
  const numberOfMonths = monthLimit || dayjs().endOf("month").diff(dayjs(_firstMonth), "months");

  // generate that much filler data:
  const fillerData = _generateFillerData(numberOfMonths);

  // use fillerData for each month that is missing real data
  const realDataDictionary = keyBy(realData, "month");
  return fillerData.map((data) => realDataDictionary[data.month] || data);
};

// reduce callback helper
const sum = (total: number, account: AccountBalance) => total + account.balance;

const _tally = function _tally(balances: AccountBalance[]): MonthlyBalance {
  const byAsset = groupBy(balances, "isAsset");
  const totalAssets = byAsset.true?.reduce(sum, 0) || 0;
  const totalDebt = byAsset.false?.reduce(sum, 0) || 0;
  return {
    month: balances[0].month,
    accounts: balances,
    "Net Worth": totalAssets + totalDebt,
    Debts: totalDebt,
    Assets: totalAssets,
  };
};
