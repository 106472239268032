import React, { ReactElement } from 'react'
import { IonItem, IonLabel, IonIcon } from '@ionic/react';
import { useTranslation } from "react-i18next";

import "./Field.scss"

interface FieldProps {
  label: string | ReactElement;
  icon?: string;
  testId?: string;
  onClick?: React.MouseEventHandler<HTMLIonItemElement>;
  routerLink?: string;
  routerDirection?: "none" | "forward" | "back" | "root";
  className?: string;
}

export const Field: React.FC<FieldProps> = ({
  label,
  children,
  icon,
  testId,
  onClick,
  routerLink,
  routerDirection,
  className = "",
}) => {
  return (
    <IonItem
      detail={false}
      onClick={onClick}
      button={!!onClick}
      className={`fy-field ${className}`}
      data-testid={testId}
      routerLink={routerLink}
      routerDirection={routerDirection}
    >
      {typeof label === "string" ? <IonLabel position="stacked">
        {label}
      </IonLabel> : label}
      {children}
      {icon && <IonIcon className="ion-align-self-end font-md" icon={icon} slot="end" />}
    </IonItem>
  );
};