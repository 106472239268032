import { get } from "../../utils/apiOps";
import { SET_SPENDING_FORECAST_LOADING, SET_SPENDING_FORECAST_SUMMARY } from "../actionTypes";
import { SpendingForecastState } from "../reducers/spendingForecast";
import { Dispatch } from "redux";
import dayjs from "dayjs";

export const setSpendingForecast = (payload: Partial<SpendingForecastState>) => {
  return {
    type: SET_SPENDING_FORECAST_SUMMARY,
    payload,
  };
};

export const setSpendingForecastLoading = (status: boolean = true) => {
  return {
    type: SET_SPENDING_FORECAST_LOADING,
    payload: status,
  };
};

type SpendingForecastParams = {
  detailLevel: "full" | "summary";
  type: "user" | "hh";
  effectiveDate: string;
  userDate: string;
};

export const getSpendingForecast = (params: SpendingForecastParams) => {
  return get(true, { endpoint: "/forecast", params });
};

export const getSpendingForecastSummary = () => (dispatch: Dispatch) => {
  dispatch(setSpendingForecastLoading(true));
  return getSpendingForecast({
    detailLevel: "summary",
    type: "hh",
    effectiveDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    userDate: dayjs().format("YYYY-MM-DD"),
  })
    .then(({ data }) => dispatch(setSpendingForecast(data.hh)))
    .finally(() => dispatch(setSpendingForecastLoading(false)));
};
