import merge from "lodash/merge";
import values from "lodash/values";
import {
  SAVE_ACCOUNT_DATA,
  ACCOUNT_DATA_LOADING,
  UPDATE_PROPERTY_BALANCE,
  SAVE_LAST_LINKED_ACCOUNT_DATA,
} from "../actionTypes";
import {
  BALANCE_TYPES,
  TYPE_TRANSLATE,
  ACCOUNT_OWNER_TYPE,
  PRIVILEGE_TYPE_DATA_2,
  FINANCIAL_ACCOUNT_STATUS,
} from "../../constants";

type type = "CASH" | "CREDIT" | "INVEST" | "LOAN" | "PROPERTY" | "OTHER";

const initialState: accountState = {
  loading: true,
  myAccounts: [],
  otherAccountCollection: [],
  properties: {},
  allBalances: {} as AllBalances,
  lastLinkedAccounts: {},
  accountsInAlert: {
    myAccounts: [],
    otherAccountCollection: [],
  },
};

export type AllBalances = {
  hh: BalanceList;
  user: BalanceList;
  partner: BalanceList;
};

export interface BalanceList {
  cash: BalanceMetadata;
  credit: BalanceMetadata;
  invest: BalanceMetadata;
  loan: BalanceMetadata;
  property: BalanceMetadata;
  other: BalanceMetadata;
  all: BalanceMetadata;
}

interface BalanceMetadata {
  name: string;
  total: number;
  accts: Account[];
}

export interface AccountCollectionItem {
  bank: string;
  subBanks: Account[];
  isAlert: boolean;
  lastUpdated: string;
  sourceInstitutionId: string;
  sourceInstitutionBaseId: string;
  pendingHistory: boolean;
  source: AccountSource;
  publicToken: string;
  loginUrl?: string;
  logo?: string;
  accountStatus?: string;
}

export enum AccountSource {
  PLAID = "plaid",
  MANUAL = "manual",
  BANK_CAPONE = "bank_capone",
  BANK_COINBASE = "bank_coinbase",
  BANK_CITI = "bank_citi",
  BANK_FINASTRA = "bank_finastra",
  COREPRO = "corepro",
}

export type BalanceItem = {
  [key: string]: {
    mY: string;
    amt: number;
    updatedOn: string;
  };
};

export type Account = {
  balance: {
    balToUse: number;
    available: Balance;
    current: Balance;
  };
  balanceHistory: Array<{
    current: BalanceItem;
    available?: BalanceItem;
  }>;
  isActive: boolean;
  _id: string;
  sourceAccountId: string;
  accountType: string;
  accountSubType: string;
  sourceInstitutionId: string;
  sourceInstitutionBaseId: string;
  source: AccountSource;
  institutionName: string;
  accountNickname: string;
  accountName: string;
  name: string;
  accountStatus: string;
  accountNumber: string;
  isAsset: boolean;
  logo?: null | string;
  hhInfo: HHInfo[];
  seeBal: boolean;
  isOwner: boolean;
  isJoint: boolean;
  balType: string;
};

export interface Balance {
  amount: number;
}

interface HHInfo {
  updatedOn: string;
  seeBal: boolean;
  isOwner: boolean;
  isJoint: boolean;
  balType: string;
  privilegeType: "0" | "1" | "3" | "4";
  accountOwnershipType?: string;
}

export interface accountState {
  loading?: boolean;
  myAccounts: AccountCollectionItem[];
  otherAccountCollection: AccountCollectionItem[];
  properties: {
    owned?: Account[];
    visible?: Account[];
  };
  allBalances: AllBalances;
  lastLinkedAccounts: { [dynamic: string]: lastLinkedAccountsObj };
  accountsInAlert: AccountsAlertObj;
}

export type AccountsAlertObj = {
  myAccounts: string[];
  otherAccountCollection: string[];
};

// has this format
interface lastLinkedAccountsObj {
  accountName: string;
  accountNumber: string;
  institutionName: string;
  newTxns: Array<any>;
  publicToken: string;
  sourceInstitutionBaseId: string;
  status: string;
  txnAdded: number;
  txnNetNew: number;
  txnProcessed: number;
  txnRemoved: number;
  txnSkipped: number;
  _id: string;
}

interface action {
  type: string;
  payload: any;
}

const accounts = (state: accountState = initialState, action: action) => {
  switch (action.type) {
    case SAVE_ACCOUNT_DATA: {
      const myAccounts = action.payload.myAccounts || state.myAccounts;
      const otherAccountCollection =
        action.payload.otherAccountCollection || state.otherAccountCollection;
      const properties = action.payload.properties || state.properties;
      return {
        ...state,
        myAccounts,
        otherAccountCollection,
        properties,
        allBalances: getNetworth([myAccounts, otherAccountCollection], properties),
        accountsInAlert: action.payload.accountsInAlert || state.accountsInAlert,
        loading: false,
      };
    }
    case ACCOUNT_DATA_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case UPDATE_PROPERTY_BALANCE: {
      const { properties, myAccounts, otherAccountCollection } = updateAccountBalance(
        state,
        action.payload.itemId,
        action.payload.amount
      );
      return {
        ...state,
        myAccounts,
        otherAccountCollection,
        properties,
        allBalances: getNetworth([myAccounts, otherAccountCollection], properties),
      };
    }
    case SAVE_LAST_LINKED_ACCOUNT_DATA: {
      return {
        ...state,
        lastLinkedAccounts: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

// This function will only return active and not deleted accounts
// In other words, if you need access to a closed account, use findAccountByIdFromState
export const findAccountById = (accounts: AllBalances, id: string | null) => {
  if (!id) return null;
  return (
    values(accounts)
      .reduce((all, cat) => all.concat(cat.all.accts), [] as Account[])
      .find((account) => account._id === id) || null
  );
};

export const findAccountByIdFromState = (state: accountState, id: string | null) => {
  if (!id) return null;
  return (
    values(state.myAccounts.concat(state.otherAccountCollection || []))
      .reduce((all, bank) => all.concat(bank.subBanks), [] as Account[])
      .find((account) => account._id === id) || null
  );
};

const updateAccountBalance = (prevState: accountState, id: string, newBalance: number) => {
  const newState = merge({}, prevState);
  const accountsList: Account[] = ([] as Account[]).concat(
    newState.properties.owned || [],
    newState.properties.visible || [],
    newState.myAccounts.reduce((all, bank) => all.concat(...bank.subBanks), [] as Account[]),
    newState.otherAccountCollection.reduce(
      (all, bank) => all.concat(...bank.subBanks),
      [] as Account[]
    )
  );
  accountsList.forEach((account) => {
    if (account._id === id) account.balance.current.amount = newBalance;
  });
  return newState;
};

// This is the master getNetworthCall
function getNetworth(accounts: any[], property: any): AllBalances {
  const sortedAccounts = calcHhAndUser(merge([], accounts));
  const finalRes = addProperty(sortedAccounts, merge({}, property));
  return finalRes;
}

/**
 * Step 3 - Add property (bal sheet items)
 * @param  {Object} nwObj Networth object
 * @param  {Array} property Propery array
 */
function addProperty(nwObj: any, property: any) {
  ["owned", "visible"].map((t, i) => {
    const isOwner = i === 0;
    (property[t] || []).map((p: any) => {
      p.balType = BALANCE_TYPES.PROPERTY;
      p.isOwner = isOwner;
      p.isJoint = p.hhInfo[0].itemOwnershipType == ACCOUNT_OWNER_TYPE.JOINT;
      p.seeBal = isOwner ? p.hhInfo[0].balanceShared : true;
      p.balance.balToUse = p.balance.current.amount;
      const yom = p.isJoint || p.seeBal || isOwner === false ? "hh" : "user";
      nwObj[yom].property.accts.push(p);
      nwObj[yom].all.accts.push(p);
      nwObj[yom].property.total += p.balance.current.amount;
      nwObj[yom].all.total += p.balance.current.amount;
    });
  });

  return nwObj;
}

// Step 2 - create the object
function calcHhAndUser(hhLinkedAccounts: any) {
  var typesArray = [
    BALANCE_TYPES.CASH,
    BALANCE_TYPES.CREDIT,
    BALANCE_TYPES.INVEST,
    BALANCE_TYPES.LOAN,
    BALANCE_TYPES.PROPERTY,
    BALANCE_TYPES.OTHER,
    "All",
  ];
  var bArray = ["hh", "user", "partner"];
  var sortedAccounts = {};

  // seedObj:
  for (var i in bArray) {
    // @ts-ignore
    sortedAccounts[bArray[i]] = {};
    for (var t in typesArray) {
      // @ts-ignore
      sortedAccounts[bArray[i]][typesArray[t].toLowerCase()] = {
        name: typesArray[t],
        total: 0,
        accts: [],
      };
    }
  }

  calcNet(hhLinkedAccounts, sortedAccounts);
  return sortedAccounts;
}

// Function to figure out which type of account it is
function typeSwitch(type: string, acct: any, sortedAccounts: any) {
  var multiple = acct.isAsset ? 1 : -1;
  let balAmt = acct.balance.current.amount * multiple;

  // if its an asset, try to use the available bal
  if (acct.isAsset && acct.accountType !== "investment") {
    try {
      balAmt = acct.balance.available.amount * multiple;
    } catch (e) {
      // don't do anything
    }
  }

  // For loans and mortgages, make sure they are negative.
  // Have seen a couple cases where Plaid sends back wrong bal info.
  // Also, if the current balans is null, use the available bal instead
  const negAccountTypes = ["loan", "mortgage", "home"];
  if (negAccountTypes.includes(acct.accountType)) {
    try {
      const balToUse =
        acct.balance.current.amount !== null
          ? acct.balance.current.amount
          : acct.balance.available.amount;
      balAmt = Math.abs(balToUse) * multiple;
      acct.balance.available.amount = Math.abs(acct.balance.available.amount) * multiple;
    } catch (e) {
      // don't do anything
    }
  }
  acct.balance.balToUse = balAmt;

  sortedAccounts[type].all.accts.push(acct);
  sortedAccounts[type].all.total += balAmt;

  // See Plaid docs for full list of types:
  // https://plaid.com/docs/api/#connect-account-types
  let t: type = "CASH";
  switch (acct.accountType) {
    case "bank": // Yodlee
    case "depository": // Plaid
      t = "CASH";
      break;
    case "creditCard": // Yodlee
    case "credit": // Plaid
      t = "CREDIT";
      break;
    case "investment":
    case "brokerage": // Plaid\
      t = "INVEST";
      break;
    case "loan": // Plaid
    case "mortgage": // Plaid
      t = "LOAN";
      break;
    case "property":
      t = "PROPERTY";
      break;
    case "other": // Plaid
    default:
      t = "OTHER";
      break;
  }

  acct.balType = BALANCE_TYPES[t];
  sortedAccounts[type][TYPE_TRANSLATE[t]].accts.push(acct);
  sortedAccounts[type][TYPE_TRANSLATE[t]].total += balAmt;
}

// Function to figure out if hh, just me, or ignore acct in networth
function acctSwitch(isUser: boolean, acct: any, sortedAccounts: any) {
  acct.seeBal = true;
  acct.isOwner = isUser;
  acct.isJoint = acct.hhInfo[0].accountOwnershipType === ACCOUNT_OWNER_TYPE.JOINT;
  switch (acct.hhInfo[0].privilegeType) {
    case PRIVILEGE_TYPE_DATA_2.SHARED: // shared
      typeSwitch("hh", acct, sortedAccounts);
      break;
    case PRIVILEGE_TYPE_DATA_2.VISIBLE: // visible
    case PRIVILEGE_TYPE_DATA_2.JUSTME: // just me
      if (acct.hhInfo[0].balanceShared) {
        typeSwitch("hh", acct, sortedAccounts);
      } else if (isUser) {
        typeSwitch("user", acct, sortedAccounts);
      } else {
        acct.seeBal = false;
        typeSwitch("partner", acct, sortedAccounts);
      }
      break;
    // case "3": ignore
    default:
      break;
  }
}

// Function to loop through p1 and p2 accounts
function calcNet(accounts: any[], sortedAccounts: any) {
  for (var a = 0; a < accounts.length; a++) {
    var isUser = a === 0;
    if (accounts[a] !== undefined && accounts[a] !== null) {
      for (var inst = 0; inst < accounts[a].length; inst++) {
        // loop through bank accounts
        for (var acct = 0; acct < accounts[a][inst].subBanks.length; acct++) {
          if (accounts[a][inst].subBanks[acct].accountStatus === FINANCIAL_ACCOUNT_STATUS.ACTIVE) {
            acctSwitch(isUser, accounts[a][inst].subBanks[acct], sortedAccounts);
          }
        }
      }
    }
  }
}

export default accounts;
