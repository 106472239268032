import React, { useState } from "react";
import {
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonPage,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../../utils/formUtils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading, setToastMessage } from "../../../redux/actions";
import { PAGE_URLS } from "../../../constants";
import { OnboardingNavHeader } from "../Onboarding/OnboardingNavHeader";
import { post } from "../../../utils/apiOps";
import "../Onboarding/onboarding.scss";
import Template, { OnboardingCopy } from "../Onboarding/Template";
import { useViewport } from "../../../hooks/useViewport";

export const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const { isDesktop } = useViewport();
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>();
  const dispatch = useDispatch();
  const formGood = Boolean(email);

  const handleLogin = async () => {
    if (!email || validateEmail(email) === false) {
      dispatch(setToastMessage(t("invalidEmail")));
      return;
    }

    dispatch(setLoading(true));
    post(false, {
      endpoint: "/user/forgotpassword",
      bodyData: {
        email,
      },
    })
      .then(() => history.push(PAGE_URLS.FORGOT_PASS_SUCCESS))
      .catch(() => { })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <Template
      header={<OnboardingNavHeader
        title={t("forgotPasswordTitle")}
        desktopTitle=" "
        showBackButton={true}
        defaultHref={PAGE_URLS.LOGIN}
        showHelp={false}
      />}
      showFooter={false}
    >
      <IonGrid>
        <OnboardingCopy
          title={isDesktop ? "forgotPasswordTitle" : "enterEmail"}
          copy_1={"forgotPasswordPrompt"}
        />
        <IonRow className="bounded">
          <IonCol>
            <IonList>
              <IonItem>
                <IonLabel position="stacked">{t("email")}</IonLabel>
                <IonInput
                  type="email"
                  value={email}
                  minlength={3}
                  required={true}
                  inputmode="email"
                  data-testid="email"
                  onIonChange={(e) => {
                    setEmail(e.detail.value!);
                  }}
                  onKeyPress={(e) => e.key === "Enter" && handleLogin()}
                ></IonInput>
              </IonItem>
            </IonList>
            <br />
            <IonButton
              disabled={!formGood}
              color="primary"
              expand="block"
              size="large"
              data-testid="login"
              onClick={handleLogin}
              className="ion-padding-horizontal"
            >
              {t("resetPassword")}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Template>
  );
};
