import React, { useState } from "react";
import numeral from "numeral";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonIcon,
  IonAlert,
} from "@ionic/react";
import { eyeOffOutline } from "ionicons/icons";

import "./GoalSummary.scss";

import { AccountInfo, Goal } from "../../../types/goals";
import { deleteGoal } from "../../../redux/actions";
import { CategoryIcon } from "./CategoryIcon";
import { GoalProgress } from "./GoalProgress";
import {
  GOAL_FUNDING_ACCOUNT_STATUS,
  GOAL_STATUS,
  PAGE_URLS,
  PRIVILEGE_TYPE_DATA_2,
} from "../../../constants";
import { useUserName } from "../../shared/User/useUserName";
import { useTranslation } from "react-i18next";
import { getAllViewerGoals } from "../../../redux/actions/goal";
import { useDispatch } from "../../../redux/hooks";
import { trackEvent } from "../../../vendors/monitoring";

type GoalProps = {
  goal: Goal;
  accountInfo: AccountInfo;
};

export const GoalSummary: React.FC<GoalProps> = (props) => {
  const { goal, accountInfo } = props;
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const { partnerName } = useUserName();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isNewGoal =
    goal.status === GOAL_STATUS.NEW &&
    goal.viewerFundingInfo?.status?.toUpperCase() !== GOAL_FUNDING_ACCOUNT_STATUS.INVITED;
  const isClosedOrCompleted = goal.status === GOAL_STATUS.CLOSED || goal.status === GOAL_STATUS.COMPLETED;
  return (
    <IonCard
      className="goal-summary-card ion-padding ion-margin-horizontal margin-top-24 fy-section-card"
      routerLink={getNextPage(goal, accountInfo)}
      routerDirection="forward"
    >
      <IonCardContent>
        <CategoryIcon
          category={goal.category}
          type="circle"
          subIcon={isClosedOrCompleted && goal.status?.toLowerCase() as any}
        />
        <br />
        <IonCardTitle className="ion-text-center ion-margin-vertical ion-padding-bottom">
          {goal.name}
          {goal.hhInfo?.privilegeType === PRIVILEGE_TYPE_DATA_2.JUSTME ? (
            <IonIcon icon={eyeOffOutline} className="private-icon" />
          ) : null}
        </IonCardTitle>
        {isNewGoal ? (
          <>
            <IonButton color="primary" expand="full" data-testid="goalFinishSetupButton">
              {t("finishSettingUpGoal")}
            </IonButton>
            <IonButton
              fill="clear"
              expand="full"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setConfirmDeleteOpen(true);
              }}
            >
              {t("deleteGoal")}
            </IonButton>
          </>
        ) : (
          <>
            {goal.viewerFundingInfo?.status?.toUpperCase() ===
              GOAL_FUNDING_ACCOUNT_STATUS.INVITED ? (
              <div className="ion-padding">{t("partnerInvitedYouToGoal", { partnerName })}</div>
            ) : null}
            {goal.status !== GOAL_STATUS.NEW &&
              goal.viewerFundingInfo?.status?.toUpperCase() !==
              GOAL_FUNDING_ACCOUNT_STATUS.INVITED ? (
              <GoalProgress goal={goal} />
            ) : null}
            {goal.viewerFundingInfo?.status?.toUpperCase() ===
              GOAL_FUNDING_ACCOUNT_STATUS.INVITED ? (
              <IonButton expand="full" data-testid="joinGoalButton">
                {t("learnMore")}
              </IonButton>
            ) : null}
            {goal.status === GOAL_STATUS.ACTIVE &&
              goal.viewerFundingInfo?.status?.toUpperCase() === GOAL_FUNDING_ACCOUNT_STATUS.PAUSED ? (
              <IonButton expand="full" data-testid="unpauseContributionsButton" className="ion-margin-top">
                {t("unpauseContributions")}
              </IonButton>
            ) : null}
            {isClosedOrCompleted ? <div className="font-md ion-padding-top ion-margin-top text-muted">
              {t("goal_" + goal.status as any)}
            </div> : null}
          </>
        )}
      </IonCardContent>
      <IonAlert
        isOpen={confirmDeleteOpen}
        onDidDismiss={() => setConfirmDeleteOpen(false)}
        header={t("confirmDeleteGoal")}
        message={t("areYouSureDeleteGoal")}
        buttons={[
          { text: t("cancel"), role: "cancel" },
          {
            text: t("yes"),
            handler: () =>
              dispatch(deleteGoal(goal.id))
                .then(() => setConfirmDeleteOpen(false))
                .then(() => dispatch(getAllViewerGoals()))
                .then(() => trackEvent("delete_goalSetup", { category: "goals" })),
          },
        ]}
      />
    </IonCard>
  );
};

const getNextPage = (goal: Goal, accountInfo: AccountInfo) => {
  let nextPage = PAGE_URLS.GOALS_DETAILS;
  if (goal.status === GOAL_STATUS.NEW && !accountInfo.hasSavingsAccount) {
    nextPage = PAGE_URLS.GOALS_FUNDING_SETUP_START;
  } else if (
    goal.status === GOAL_STATUS.ACTIVE &&
    goal.viewerFundingInfo &&
    goal.viewerFundingInfo.status.toUpperCase() === GOAL_FUNDING_ACCOUNT_STATUS.INVITED
  ) {
    // this is case where you have been invited to join goal by your partner
    // but you don't have corepro setup yet
    nextPage = PAGE_URLS.GOALS_PARTNER_OVERVIEW;
  } else if (goal.status === GOAL_STATUS.NEW && !accountInfo.hasExternalFundingAccount) {
    nextPage = PAGE_URLS.GOALS_FUNDING_CHOOSE_ACCOUNT;
  } else if (goal.status === GOAL_STATUS.NEW) {
    nextPage = PAGE_URLS.GOALS_HOW_SAVE;
  }
  return nextPage.replace(":goalId", goal.id);
};
