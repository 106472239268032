import React, { useEffect } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getFeeds } from "../../../redux/actions";
import { getBudgetAnalyticsState, getUsersState } from "../../../redux/selectors";
import { useUserName } from "../../shared/User/useUserName";
import { getFeedDesc, getFeedDetailSubDes, getFeedSubDes } from "../../../utils/homeTab";
import {
  trophyOutline,
  chatbubblesOutline as chatbubbles,
  swapHorizontalOutline,
  bookmarkOutline,
  discOutline,
  idCardOutline,
  bookOutline,
} from "ionicons/icons";
import { FeedsIconType, FeedsParams } from "../../../types";
import { useHistory } from "react-router-dom";
import { PAGE_URLS } from "../../../constants";
import { feedDetailSwitch, FeedType } from "../../../utils/feedUtils";

const FeedApiRes = "";

export const RecentActivities: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  //redux state
  const { feedsArray } = useSelector(getBudgetAnalyticsState);

  useEffect(() => {
    dispatch(getFeeds(1));
  }, []);

  return (
    <IonCard data-testid="RecentActivityCard">
      <IonCardHeader>
        <IonCardTitle className="ion-text-center">{t("recentActivity")}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className="ion-text-center ion-no-padding" data-testid="RecentActivityContent">
        {feedsArray.length > 0 ? (
          <>
            <IonList lines="none" data-testid="FeedList">
              {feedsArray.slice(0, 3).map((feed) => (
                <RecentActivitiesItem key={feed._id} feed={feed} />
              ))}
            </IonList>
            <IonButton
              routerLink={PAGE_URLS.RECENT_ACTIVITY}
              fill="clear"
              size="large"
              expand="block"
              className="ion-margin-top"
            >
              {t("viewAll")}
            </IonButton>
          </>
        ) : (
          <h3 className="ion-padding font-md">{t("noRecentActivity")}</h3>
        )}
      </IonCardContent>
    </IonCard>
  );
};

interface RecentActivitiesItemProps {
  feed: FeedsParams;
  showDate?: boolean;
}

export const RecentActivitiesItem: React.FC<RecentActivitiesItemProps> = ({ feed, showDate }) => {
  const history = useHistory();

  //redux state
  const { userId } = useSelector(getUsersState);
  const { partnerName } = useUserName();

  const icon = {
    trophyOutline,
    chatbubbles,
    swapHorizontalOutline,
    bookmarkOutline,
    discOutline,
    idCardOutline,
    bookOutline,
  }[feed.icon as FeedsIconType];

  const feedDetailLink = feedDetailSwitch(feed.feedType as FeedType, feed.entityId, false).page;

  return (
    <IonItem
      detail={!showDate}
      lines="none"
      routerLink={feedDetailLink || undefined}
      className="no-inner-padding"
      button={!!feedDetailLink}
    >
      <IonRow>
        <IonIcon icon={icon} className="ion-margin-end" />
      </IonRow>
      <IonCol>
        <IonRow>
          <h3 className="font-md ion-no-margin">{getFeedDesc(feed, userId, partnerName)}</h3>
        </IonRow>
        <IonRow>
          <h3 className="font-sm ion-no-margin text-faded">{getFeedSubDes(feed, partnerName)}</h3>
        </IonRow>
        {showDate && (
          <IonRow>
            <h3 className="font-xs placeholder-text ion-no-margin text-faded">{getFeedDetailSubDes(feed)}</h3>
          </IonRow>
        )}
      </IonCol>
    </IonItem>
  );
};
