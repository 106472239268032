import React, { useState } from "react";
import { IonButton, IonFooter, IonItem, IonLabel, IonTextarea, IonToolbar } from "@ionic/react";
import { PAGE_URLS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { getSpendingChallengeState } from "../../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getSpendingChallenges, updateCreateNewChanllengeParams } from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import { cloneDeep } from "lodash";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { NewChallengeModal } from "../../shared/SpendingChallenges/NewChallengeModal";
import {
  createNewSpendingChallenge,
  validateSpendingChallengeData,
} from "../../../utils/spendingChallenge";

export const NewSpendingChalllengeStack: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  //redux state
  const { newSpendingChallenge, categoryFilter, frequency } =
    useSelector(getSpendingChallengeState);

  //state
  const [customStackMessage, setCustomStackMessage] = useState("");
  const [confettiModalOpen, setConfettiModalOpen] = useState(false);

  const handleNextBtnClick = async () => {
    if (!validateSpendingChallengeData(newSpendingChallenge, categoryFilter, frequency)) {
      history.push(PAGE_URLS.SPENDING_CHALLENGES_NEW);
      return;
    }
    let challengeTargets = [...newSpendingChallenge.targets];
    const newChallengeTargets = challengeTargets.map((target: any) => ({
      ...target,
      value: 0,
      type: "amt",
    }));
    const copyRewards = cloneDeep(newSpendingChallenge.rewards);
    copyRewards[0].details.text = customStackMessage;
    const newCompetitiveChallenge = {
      ...newSpendingChallenge,
      rewards: copyRewards,
      targets: newChallengeTargets,
    };

    dispatch(updateCreateNewChanllengeParams(newCompetitiveChallenge));
    await createNewSpendingChallenge(newCompetitiveChallenge);
    setConfettiModalOpen(true);
    dispatch(getSpendingChallenges());
    setCustomStackMessage("");
    history.push(PAGE_URLS.HOME);
  };

  return (
    <PageTemplate
      header={<NavHeader desktopTitle={t("spendingChallengeTitle")} />}
      footer={
        <IonFooter className="ion-no-border">
          <IonToolbar no-border className="ion-text-center">
            <IonButton onClick={handleNextBtnClick} data-testid="newSpendingNextBtnStack">
              {t("next")}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      }
    >
      <Section>
        <IonItem lines="none" className="ion-margin newSpendingChallenge-stack">
          <IonLabel position="stacked">{t("whatsAtStacks")}</IonLabel>
          <IonTextarea
            autoGrow={true}
            rows={8}
            placeholder={t("stackPlaceHolder")}
            value={customStackMessage}
            onIonChange={(e) => setCustomStackMessage(e.detail.value || "")}
            data-testid="newSpendingChallengeStack"
          ></IonTextarea>
        </IonItem>
        <NewChallengeModal
          open={confettiModalOpen}
          onClose={() => setConfettiModalOpen(false)}
        />
      </Section>
    </PageTemplate>
  );
};
