import React from "react";
import {
  IonList,
  IonButton,
  IonItem,
  IonLabel,
  IonNote,
  IonContent,
  IonFooter,
  IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";

import { Goal } from "../../../types";
import { formatAccountNumber } from "../../../utils/formatUtils";
import { useDispatch } from "../../../redux/hooks";
import { modifyGoal } from "../../../redux/actions/goal";
import { getContributionDescription } from "../../../utils/goalUtils";
import { GOAL_FUNDING_ACCOUNT_STATUS, GOAL_STATUS } from "../../../constants";
import { trackEvent } from "../../../vendors/monitoring";
import { Field } from "../../shared/Field/Field";
import { IonText } from '@ionic/react';

type ReopenGoalConfirmationProps = {
  type: "REOPEN" | "UNPAUSE";
  goal: Goal;
  onSaveEnd: () => void;
};

export const ReopenGoalConfirmation: React.FC<ReopenGoalConfirmationProps> = (props) => {
  const { type, goal, onSaveEnd } = props;
  const fundingAccount = goal?.viewerFundingInfo?.fundingAccount || {};
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSave = () => {
    const params = { fundingStatus: GOAL_FUNDING_ACCOUNT_STATUS.ACTIVE } as any;
    if (type === "REOPEN") {
      trackEvent("reopen_goal", { category: "goals" });

      params.status = GOAL_STATUS.ACTIVE;
    }
    trackEvent("unpause_goal", { category: "goals" });

    dispatch(modifyGoal(goal.id, params)).then(onSaveEnd);
  };

  return (
    <>
      <IonContent>
        <IonList>
          <Field label={t("account")} className="">
            <IonText>
              {fundingAccount?.nickname || fundingAccount?.accountName}{" "}
              {formatAccountNumber(fundingAccount?.accountNumber || "")}
            </IonText>
          </Field>
          <Field label={t("contributionRule")} className="">
            <IonText>
              {getContributionDescription(goal.viewerFundingInfo!)}
            </IonText>
          </Field>
        </IonList>
      </IonContent>
      <IonFooter className="ion-no-border ion-margin">
        <IonToolbar className="ion-text-center">
          <IonButton fill="solid" size="large" onClick={onSave}>
            {t(type === "REOPEN" ? "reopenGoal" : "unpause")}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
