import React, { useEffect, useState } from "react";
import {
  IonItem,
  IonLabel,
  IonBadge,
  IonModal,
  IonContent,
  IonList,
  IonSelect,
  IonSelectOption,
  IonAlert,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonFooter,
  IonToolbar,
  IonAvatar,
} from "@ionic/react";
import { BILLS_FREQUENCY_TYPES, BILL_REMINDER_OPTIONS, PAGE_URLS } from "../../../constants";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  Bill,
  BillFrequency,
  BillReminderDaysOptions,
  BillsData,
  EditBillsBodyData,
  EditBillTypes,
  Transaction,
} from "../../../types";
import Avatars, { calcSharedTrans } from "../../shared/Avatar/Avatars";
import numeral from "numeral";
import { startCase } from "lodash";
import { setLoading, updateBill } from "../../../redux/actions";
import { useDispatch, useSelector } from "../../../redux/hooks";
import { useHistory } from "react-router";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import { closeOutline } from "ionicons/icons";
import { post, put } from "../../../utils/apiOps";
import { getBillsState, getUsersState } from "../../../redux/selectors";
import { useUserName } from "../../shared/User/useUserName";
import { BillModalSearch } from "./BillModalSearch";
import "./BillItem.scss";
import { YomPopover } from "../../shared/Sharing/YomPopover";
import { UnmarkBillAlert } from "./UnmarkBillAlert";
import { ActionSheet } from "../../shared/PageTemplate";
import { DatePicker } from "../../shared/DatePicker/DatePicker";
import { useResponsiveInterface } from "../../../hooks/useResponsiveInterface";
import { getTransactionLink } from "../../../utils/transUtils";

interface BillItemProps {
  billId: string;
  effectiveDate: string;
  clickable?: boolean;
}

export const BillItem: React.FC<BillItemProps> = ({ billId, effectiveDate, clickable = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { billsData } = useSelector(getBillsState);
  const { userId } = useSelector(getUsersState);

  const [showBillItemEvent, setShowBillItemEvent] = useState<Event>();
  const [showEditBillModal, setShowEditBillModal] = useState(false);
  const [showMarkBillPaid, setShowMarkBillPaid] = useState(false);
  const [showUnmarkBillPaid, setShowUnmarkBillPaid] = useState(false);
  const [bill, setBill] = useState<Bill>();
  const [billAvatarType, setBillAvatarType] = useState<string | number>();

  useEffect(() => {
    const thisBillsData: BillsData = billsData.find(
      (b: BillsData) => b.effectiveDate === effectiveDate
    );
    if (thisBillsData) {
      const bill = thisBillsData.billsArray.find((b) => b._id === billId);
      if (bill) {
        setBill(bill);
        setBillAvatarType(
          bill.sharedStatus ? bill.sharedStatus : calcSharedTrans(bill?.hhInfo[0], userId)
        );
      }
    }
  }, [effectiveDate, billsData, billId]);

  return bill ? (
    <>
      <IonItem
        button
        detail={false}
        className="BillItem"
        onClick={(e) => {
          if (!clickable) return;
          e.persist();
          setShowBillItemEvent(e as any);
        }}
      >
        <IonAvatar slot="start">
          <Avatars sizeStyle="sm" diagonal={true} avatarType={billAvatarType} />
        </IonAvatar>
        <IonLabel>
          <h3 className="font-md">{bill.nickname || bill.name}</h3>
          <h4 className="font-sm">
            {t("expected")} {dayjs(effectiveDate).set("date", bill.day).format("M/D")}
          </h4>
        </IonLabel>

        <IonLabel className="ion-text-end">
          <h3 className="font-md">{numeral(bill.amount * -1).format("$0,0")}</h3>
          <h4 className="font-sm ion-no-margin">{bill.status === "paid" && <BillPaidBadge />}</h4>
        </IonLabel>
      </IonItem>
      <hr className="dashed-bottom-border ion-margin-horizontal" />
      <ActionSheet
        hideButton
        openEvent={showBillItemEvent}
        onDidDismiss={setShowBillItemEvent as () => void}
        header={t("bills")}
        buttons={[
          {
            text: bill.status === "paid" ? t("markAsUnpaid") : t("markAsPaid"),
            handler: () => {
              if (bill.status === "paid") {
                setShowUnmarkBillPaid(true);
              } else {
                setShowMarkBillPaid(true);
              }
            },
          },
          {
            text: t("editBill"),
            handler: () => setShowEditBillModal(true),
          },
          {
            text: t("viewTransactions"),
            handler: () => {
              history.push(getTransactionLink("advancedSearch", {
                startDate: dayjs().startOf("month").subtract(3, "months").format("MM/DD/YYYY"),
                endDate: dayjs().endOf("month").format("MM/DD/YYYY"),
                billIds: JSON.stringify([bill._id]),
                isPrivate: "both",
              }));
            },
          },
          {
            text: t("cancel"),
            role: "cancel",
          },
        ]}
      />
      <EditBillModal
        billId={billId}
        isOpen={showEditBillModal}
        onClose={() => setShowEditBillModal(false)}
        effectiveDate={effectiveDate}
      />
      {bill.status === "paid" ? (
        <UnmarkBillAlert
          billId={bill._id}
          paidSource={bill.paidSource || "transaction"}
          transactionId={bill.transactionId}
          paidDate={dayjs(effectiveDate).date(bill.day).toDate()}
          isOpen={showUnmarkBillPaid}
          onClose={() => setShowUnmarkBillPaid(false)}
        />
      ) : (
        <MarkBillPaidModal
          billId={billId}
          isOpen={showMarkBillPaid}
          onClose={() => setShowMarkBillPaid(false)}
          effectiveDate={effectiveDate}
        />
      )}
    </>
  ) : (
    <></>
  );
};

interface BillPaidBadgeProps {
  className?: string;
}

export const BillPaidBadge: React.FC<BillPaidBadgeProps> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <IonBadge className={className} color="success">
      {startCase(t("paid"))}
    </IonBadge>
  );
};

interface EditBillModalProps {
  isOpen: boolean;
  onClose: () => void;
  billId: string;
  effectiveDate: string;
}

export const EditBillModal: React.FC<EditBillModalProps> = ({
  isOpen = false,
  onClose,
  billId,
  effectiveDate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _interface = useResponsiveInterface("alert");
  const { billsData } = useSelector(getBillsState);
  const { partnerName } = useUserName();
  const { userId } = useSelector(getUsersState);

  const [bill, setBill] = useState<Bill>();
  const [billFrequency, setBillFrequency] = useState<BillFrequency>();
  const [reminderDays, setReminderDays] = useState<BillReminderDaysOptions>();
  const [billName, setBillName] = useState<string>();
  const [billAmount, setBillAmount] = useState<number>();
  const [billDay, setBillDay] = useState<string>();
  const [billAvatarType, setBillAvatarType] = useState<string | number>();

  const [showNicknameAlert, setShowNicknameAlert] = useState(false);
  const [showAmountAlert, setShowAmountAlert] = useState(false);
  const [showDeactivateBillAlert, setShowDeactivateBillAlert] = useState(false);
  const [showEditYomPopover, setShowEditYomPopover] = useState<any>({
    isOpen: false,
    event: null,
  });

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const thisBillsData: BillsData = billsData.find(
      (b: BillsData) => b.effectiveDate === effectiveDate
    );
    if (thisBillsData) {
      const bill = thisBillsData.billsArray.find((b) => b._id === billId);
      if (bill) {
        setBill(bill);
        setBillFrequency(bill.frequency);
        setReminderDays(bill.sendReminder === false ? bill.sendReminder : bill.reminderDays);
        setBillName(bill.nickname || bill.name);
        setBillAmount(bill.amount);
        setBillDay(dayjs(effectiveDate).set("date", bill.day).format("YYYY-MM-DD"));
        setBillAvatarType(
          bill.sharedStatus ? bill.sharedStatus : calcSharedTrans(bill?.hhInfo[0], userId)
        );
      }
    }
  }, [effectiveDate, billsData, billId, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    if (bill && billFrequency && billFrequency !== bill?.frequency) {
      editBill({ field: "frequency", value: billFrequency }).catch(() =>
        setBillFrequency(bill?.frequency)
      );
    }
  }, [billFrequency]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const billRedata = bill?.sendReminder === false ? bill.sendReminder : bill?.reminderDays;
    if (bill && reminderDays && reminderDays !== billRedata)
      editBill({
        field: billRedata === false ? "sendReminder" : "reminderDays",
        value: reminderDays,
      }).catch(() =>
        setReminderDays(bill?.sendReminder === false ? bill?.sendReminder : bill?.reminderDays)
      );
  }, [reminderDays]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const newDate = dayjs(billDay).date();
    if (bill && newDate && newDate !== bill.day) {
      editBill({ field: "day", value: newDate }).catch(() =>
        setBillDay(dayjs(effectiveDate).set("date", bill.day).format("YYYY-MM-DD"))
      );
    }
  }, [billDay]);

  const updateYom = (newTransDisplay: string) => {
    setShowEditYomPopover({ isOpen: false, event: undefined });
    editBill({ field: "sharedStatus", value: newTransDisplay })
      .then(() => {
        setShowEditYomPopover({ isOpen: false, event: undefined });
        setBillAvatarType(newTransDisplay);
      })
      .catch(() => {
        // setBillDay(dayjs(effectiveDate).set("date", bill.day).format("YYYY-MM-DD"));
      });
  };

  interface EditBill {
    field: EditBillTypes;
    value: string | number | boolean;
  }

  const editBill = async ({ field, value }: EditBill) => {
    dispatch(setLoading(true));
    const bodyData: EditBillsBodyData = {
      billId: billId,
    };
    // @ts-ignore
    bodyData[field] = value;
    await put(true, {
      endpoint: "/bills/edit",
      bodyData,
    })
      .then(() => {
        dispatch(updateBill({ billId, updateData: [{ field, value }] }));
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <IonModal id="EditBillModal" isOpen={isOpen} onDidDismiss={onClose}>
      <ModalHeader title={t("editBill")} startBtnIcon={closeOutline} startBtnOnClick={onClose} />
      <IonContent>
        <IonList lines="none">
          <IonItem onClick={() => setShowNicknameAlert(true)}>
            <IonLabel>{t("name")}</IonLabel>
            <IonLabel className="ion-text-end ion-margin-end">{billName}</IonLabel>
          </IonItem>
          <IonItem onClick={() => setShowAmountAlert(true)}>
            <IonLabel>{t("amount")}</IonLabel>
            <IonLabel className="ion-text-end ion-margin-end">
              {numeral(billAmount).format("$0,0")}
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>{t("nextDueDate")}</IonLabel>
            <DatePicker
              min={dayjs(effectiveDate).startOf("month").format("YYYY-MM-DD")}
              max={dayjs(effectiveDate).endOf("month").format("YYYY-MM-DD")}
              displayFormat="M/D/YY (DDD)"
              value={billDay}
              onChange={setBillDay}
            />
          </IonItem>
          <IonItem>
            <IonLabel>{t("person")}</IonLabel>

            <IonButton
              slot="end"
              fill="clear"
              className="ion-text-end ion-margin-end"
              onClick={(event) => {
                event.persist();
                setShowEditYomPopover({ isOpen: true, event });
              }}
            >
              <Avatars sizeStyle="sm" avatarType={billAvatarType} alignment="end" />
            </IonButton>
          </IonItem>
          <IonItem>
            <IonLabel>{t("timing")}</IonLabel>
            <IonSelect
              slot="end"
              value={billFrequency}
              interface={_interface}
              onIonChange={({ detail }) => setBillFrequency(detail.value)}
            >
              {BILLS_FREQUENCY_TYPES.map((s, i) => {
                return (
                  <IonSelectOption key={i} value={s.val}>
                    {t(s.disp)}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>{t("reminder")}</IonLabel>
            <IonSelect
              slot="end"
              value={reminderDays}
              interface={_interface}
              onIonChange={({ detail }) => setReminderDays(detail.value)}
            >
              {BILL_REMINDER_OPTIONS.map((s, i) => {
                return (
                  <IonSelectOption key={i} value={s.val}>
                    {t(s.disp)}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>

          {bill?.deactivatedDate ? (
            <IonItem>
              <IonLabel className="ion-text-center">
                {t("billDeactivatedAsOf")} {dayjs(bill.deactivatedDate).format("MMMM")}.
              </IonLabel>
            </IonItem>
          ) : null}
        </IonList>
      </IonContent>

      {!bill?.deactivatedDate && (
        <IonFooter className="ion-no-border">
          <IonToolbar no-border className="ion-text-center">
            <IonButton
              fill="clear"
              data-testid="deactivateBill"
              className={"ion-margin-bottom"}
              onClick={() => setShowDeactivateBillAlert(true)}
            >
              {t("deactivateBill")}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      )}

      <IonAlert
        isOpen={showNicknameAlert}
        onDidDismiss={() => setShowNicknameAlert(false)}
        header={t("editName")}
        inputs={[
          {
            name: "name",
            type: "text",
            cssClass: "ion-text-center",
            placeholder: t("name"),
            value: String(bill?.nickname || bill?.name),
          },
        ]}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
          },
          {
            text: t("update"),
            handler: ({ name }) => {
              editBill({ field: "nickname", value: name })
                .then(() => {
                  setBillName(name);
                })
                .catch(() => setBillName(bill?.nickname || bill?.name));
            },
          },
        ]}
      />
      <IonAlert
        isOpen={showAmountAlert}
        onDidDismiss={() => setShowAmountAlert(false)}
        header={t("editAmount")}
        inputs={[
          {
            name: "amount",
            type: "number",
            cssClass: "ion-text-center",
            placeholder: "$50",
            value: bill?.amount,
          },
        ]}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
          },
          {
            text: t("update"),
            handler: ({ amount }) => {
              editBill({ field: "amount", value: Number(amount) })
                .then(() => {
                  setBillAmount(Number(amount));
                })
                .catch(() => setBillAmount(bill?.amount));
            },
          },
        ]}
      />
      <IonAlert
        isOpen={showDeactivateBillAlert}
        onDidDismiss={() => setShowDeactivateBillAlert(false)}
        header={t("deactivateBill")}
        message={t("deactivateBill_msg") + dayjs(effectiveDate).format("MMMM") + "."}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
          },
          {
            text: t("deactivate"),
            handler: () => {
              editBill({
                field: "deactivatedDate",
                value: dayjs.utc(effectiveDate).startOf("month").subtract(1, "month").format(),
              }).catch(() => { });
            },
          },
        ]}
      />
      <YomPopover
        showEditYomPopover={showEditYomPopover}
        setShowEditYomPopover={setShowEditYomPopover}
        partnerName={partnerName}
        updateYom={updateYom}
      />
    </IonModal>
  );
};

interface MarkBillPaidModalProps {
  isOpen: boolean;
  onClose: () => void;
  billId: string;
  effectiveDate: string;
}

type searchResults = {
  transactions: Transaction[];
};

export const MarkBillPaidModal: React.FC<MarkBillPaidModalProps> = ({
  isOpen = false,
  onClose,
  billId,
  effectiveDate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { billsData } = useSelector(getBillsState);

  const [bill, setBill] = useState<Bill>();
  const [showSkipAlert, setShowSkipAlert] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const thisBillsData: BillsData = billsData.find(
      (b: BillsData) => b.effectiveDate === effectiveDate
    );
    if (thisBillsData) {
      const bill = thisBillsData.billsArray.find((b) => b._id === billId);
      if (bill) {
        setBill(bill);
      }
    }
  }, [effectiveDate, billsData, billId, isOpen]);

  const markBillFromTrans = (transactionId: string) => {
    if (!bill) {
      return;
    }
    const bodyData = {
      transactionId,
      billId: bill?._id,
      billMonth: Number(dayjs(effectiveDate).format("M")) - 1,
      billYear: dayjs(effectiveDate).year(),
    };
    dispatch(setLoading(true));
    post(true, {
      endpoint: "/bills/markAsPaid",
      bodyData,
    })
      .then(() => {
        dispatch(
          updateBill({
            billId: bill?._id,
            updateData: [
              { field: "status", value: "paid" },
              { field: "paidSource", value: "transaction" },
              { field: "transactionId", value: transactionId },
            ],
            effectiveDate,
          })
        );
        onClose();
      })
      .catch()
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <IonModal id="MarkBillPaidModal" isOpen={isOpen} onDidDismiss={onClose}>
      <ModalHeader
        title={t("markPaid")}
        startBtnIcon={closeOutline}
        startBtnOnClick={onClose}
        endBtnText={t("skip")}
        endBtnOnClick={() => setShowSkipAlert(true)}
      />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <h1 className="font-md">{t("markPaidModal_title")}</h1>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <h3 className="font-sm ion-no-margin">{bill?.nickname || bill?.name}</h3>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="font-sm">
              <h3 className="font-sm">
                {t("markPaidModal_msg")} {dayjs(bill?.displayDate).format("MMM. D")} {t("for")}{" "}
                {numeral(bill?.amount).format("$0,0")}.
              </h3>
            </IonCol>
          </IonRow>
        </IonGrid>
        <BillModalSearch effectiveDate={effectiveDate} transactionOnClick={markBillFromTrans} />
      </IonContent>
      <IonAlert
        isOpen={showSkipAlert}
        onDidDismiss={() => setShowSkipAlert(false)}
        header={t("manualBillAlert_header")}
        message={t("manualBillAlert_msg")}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
          },
          {
            text: t("skip"),
            role: "destructive",
            handler: () => {
              if (!bill) {
                return;
              }
              const bodyData = {
                billId: bill?._id,
                paidDate: dayjs.utc(effectiveDate).date(bill?.day).format(),
              };

              dispatch(setLoading(true));
              post(true, {
                endpoint: "/bills/markAsPaidManual",
                bodyData,
              })
                .then(() => {
                  dispatch(
                    updateBill({
                      billId: bill?._id,
                      updateData: [
                        { field: "status", value: "paid" },
                        { field: "paidSource", value: "manual" },
                      ],
                      effectiveDate,
                    })
                  );
                  onClose();
                })
                .catch()
                .finally(() => {
                  dispatch(setLoading(false));
                });
            },
          },
        ]}
      />
    </IonModal>
  );
};
