import React, { useState } from "react";
import {
  IonList,
  IonModal,
  IonSearchbar,
  IonContent,
  IonButton,
  IonRow,
  IonCol,
  IonGrid,
  IonSpinner,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { get, getCancelTokenSource } from "../../../utils/apiOps";
import LinkInstitutionWrapper, { BankLinkFormat } from "./LinkInstitutionWrapper";
import { ModalHeader } from "../Header/ModalHeader";
import { AddNewManualAccountModal } from "./AddNewManualAccountModal";
import "./LinkSearchModal.scss";
import { CancelTokenSource } from "axios";
import { trackEvent } from "../../../vendors/monitoring";

let cancelTokenSource: CancelTokenSource;

type LinkSearchModalProps = {
  isOpen: boolean;
  onClose: any;
  searchAuthOnly?: boolean;
  allowManualAccount?: boolean;
  fromOnboarding?: boolean;
};

export const LinkSearchModal: React.FC<LinkSearchModalProps> = ({
  isOpen,
  onClose,
  searchAuthOnly = false,
  allowManualAccount = false,
  fromOnboarding = false,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [searchingFlag, setSearchingFlag] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Array<any>>([]);
  const [manualAccountModalOpen, setManualAccountModalOpen] = useState(false);
  const { t } = useTranslation();
  const handleSearch = ({ detail }: any) => {
    setSearchingFlag(true);
    setSearchText(detail.value!);
    if (!detail.value || detail.value.length < 3) {
      setSearchResults([]);
      return;
    }

    cancelTokenSource && cancelTokenSource.cancel();
    cancelTokenSource = getCancelTokenSource();
    const getData = {
      endpoint: "/plaid/institutions",
      params: {
        bankName: encodeURIComponent(detail.value),
        searchAuthOnly,
      },
      cancelToken: cancelTokenSource.token,
    };
    get(true, getData)
      .then(({ data }) => setSearchResults(data || []))
      .catch((error) => setSearchResults([]))
      .finally(() => setSearchingFlag(false));
  };

  const handleClose = (successfullyLinkedAccount: boolean = false) => {
    setSearchText("");
    setSearchingFlag(false);
    setSearchResults([]);
    onClose(successfullyLinkedAccount);
  };

  return (
    <IonModal isOpen={isOpen} cssClass="link-search-modal" onDidDismiss={() => handleClose(false)}>
      <ModalHeader
        title={t("searchForBank")}
        startBtnIcon={closeOutline}
        startBtnOnClick={() => handleClose(false)}
      />
      <IonContent>
        <IonSearchbar
          onIonChange={handleSearch}
          debounce={250}
          data-testid="linkSearchModalInput"
        ></IonSearchbar>
        {searchingFlag && (
          <div className="ion-text-center">
            <IonSpinner />
          </div>
        )}
        <IonList>
          {searchResults.length > 0
            ? searchResults.map((bank) => {
                return (
                  <LinkInstitutionWrapper
                    onClose={handleClose}
                    key={bank.id}
                    format={BankLinkFormat.SEARCH_ITEM}
                    institution={bank}
                  fromOnboarding={fromOnboarding}
                  />
                );
              })
            : searchText !== "" &&
              !searchingFlag && <div className="ion-text-center ion-margin">{t("noResults")}</div>}
        </IonList>
        {manualAccountModalOpen ? (
          <AddNewManualAccountModal
            isOpen={manualAccountModalOpen}
            handleClose={() => setManualAccountModalOpen(false)}
          />
        ) : null}
        {allowManualAccount ? (
          <IonGrid>
            <IonRow>
              <IonCol className="ion-text-center">
                <IonButton
                  onClick={() => {
                    trackEvent("view_addManualAcct", { category: "addManualAcct" });
                    setManualAccountModalOpen(true);
                  }}
                  data-testid="addManualAccountBtn"
                >
                  {t("addItManually")}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : null}
      </IonContent>
    </IonModal>
  );
};
