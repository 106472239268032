import React, { useEffect, useState } from "react";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonList,
  IonModal,
  IonRow,
  IonToggle,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { closeOutline } from "ionicons/icons";
import { post } from "../../../utils/apiOps";
import { BudgetItem, BudgetType, SubCategory } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { getBudget } from "../../../redux/actions";
import dayjs from "dayjs";
import { getBudgetAnalyticsState, getUsersState } from "../../../redux/selectors";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import { trackEvent } from "../../../vendors/monitoring";

const BUDGET_NOTIFICATION_TYPES: any = {
  TWENTY_FIVE: "TWENTY_FIVE",
  FIFTY: "FIFTY",
  SEVENTY_FIVE: "SEVENTY_FIVE",
  EXACT: "EXACT",
  OVER: "OVER",
};

const initEnrollmentObj: any = {
  [BUDGET_NOTIFICATION_TYPES.TWENTY_FIVE]: false,
  [BUDGET_NOTIFICATION_TYPES.FIFTY]: false,
  [BUDGET_NOTIFICATION_TYPES.OVER]: false,
};

interface BudgetNotificationsModalProps {
  isOpen: boolean;
  onClose: any;
  budgetType: BudgetType;
  subcategory: SubCategory;
}

export const BudgetNotificationsModal: React.FC<BudgetNotificationsModalProps> = ({
  isOpen,
  onClose,
  budgetType,
  subcategory,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { budgetsArray } = useSelector(getBudgetAnalyticsState);
  const [notificationsEnrollmentsObj, setNotificationsEnrollmentsObj] =
    useState<any>(initEnrollmentObj);
  const effectiveDate = dayjs().startOf("month").format("YYYY-MM-DD");
  const { userId } = useSelector(getUsersState);

  useEffect(() => {
    if (!budgetsArray || budgetsArray.length === 0) {
      return;
    }
    const budgetAnalyticsData: BudgetItem | undefined = budgetsArray.find(
      (item: BudgetItem) => item.budgetAnalyticsDate === effectiveDate
    );

    if (!budgetAnalyticsData) {
      return;
    }
    const enrollmentsArr = budgetAnalyticsData[budgetType]?.notificationMilestonesEnrollment;
    if (!enrollmentsArr) {
      return;
    }
    const newEnrollmentsArr = enrollmentsArr.filter(
      (n) => n.value === subcategory.subCategory && n.userIds.includes(userId)
    );
    const newEnrollmentsObj = { ...initEnrollmentObj };
    newEnrollmentsArr.map((notification) => (newEnrollmentsObj[notification.type] = true));
    setNotificationsEnrollmentsObj(newEnrollmentsObj);
  }, [
    budgetsArray,
    budgetType,
    effectiveDate,
    userId,
    // notificationsEnrollmentsObj,
    subcategory.subCategory,
  ]);

  const toggleBudgetNotification = (triggerType: string, checked: boolean) => {
    notificationsEnrollmentsObj[triggerType] = checked;
    post(true, {
      endpoint: "/budget/updateMilestoneEnrollment",
      bodyData: {
        type: budgetType,
        currentEffectiveDate: effectiveDate,
        enrollmentUpdates: [
          {
            type: BUDGET_NOTIFICATION_TYPES[triggerType],
            value: subcategory?.subCategory,
            status: checked ? "active" : "inactive",
          },
        ],
      },
    }).then(() => {
      trackEvent(checked ? "notification_toggleOn" : "notification_toggleOff", {
        category: "spendingBudget",
        label: triggerType,
      });
      dispatch(
        getBudget({
          effectiveDate,
          forceRefresh: true,
        })
      );
    });
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => onClose(false)}>
      <ModalHeader
        title={t("notifications")}
        startBtnIcon={closeOutline}
        startBtnOnClick={() => onClose(false)}
      />
      <IonContent>
        <IonGrid>
          <IonRow className="ion-margin-top">
            <IonCol className="font-title-3">{t("budgetNotifications")}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="font-md">{t("budgetModalNotificationMsg")}</IonCol>
          </IonRow>
        </IonGrid>
        <IonList lines="none" className="ion-margin-top">
          {[
            BUDGET_NOTIFICATION_TYPES.OVER,
            BUDGET_NOTIFICATION_TYPES.SEVENTY_FIVE,
            BUDGET_NOTIFICATION_TYPES.FIFTY,
          ].map((notificationType: string, i: number) => {
            return (
              <IonItem key={i} className="font-md">
                {t(`budgetModalNotifications_${notificationType}`)}
                <IonToggle
                  slot="end"
                  onIonChange={(e) => toggleBudgetNotification(notificationType, e.detail.checked)}
                  checked={notificationsEnrollmentsObj[notificationType]}
                  data-testid={`notificationToggle_${notificationType}`}
                />
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </IonModal>
  );
};
