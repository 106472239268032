import { Dispatch } from "redux";
import { loader } from "graphql.macro";
import i18n from "i18next";

import { AccountInfo, Goal } from "../../types";
import * as types from "../actionTypes";
import { apolloClient } from "../../utils/apolloClient";
import { ObservableQuery } from "apollo-boost";
import { setLoading, setToastMessage } from "./ui";
import { initGlobalData } from "./globalActions";
import { RootState } from "../reducers";
import { getFundingAccount } from "../selectors";
import { getAccountData } from "./accounts";
import {
  GOAL_STATUS,
  GOAL_FUNDING_ACCOUNT_STATUS,
  PAGE_URLS,
  PRIVILEGE_TYPE_DATA_2,
  ACCOUNT_OWNER_TYPE,
} from "../../constants";

const getGoal = loader("../../graphql/goals/queries/GoalQuery.graphql");
const getAllViewerGoalsQuery = loader("../../graphql/goals/queries/ViewerGoals.graphql");
const createNewGoalMutation = loader("../../graphql/goals/mutations/CreateNewGoal.graphql");
const deleteGoalMutation = loader("../../graphql/goals/mutations/DeleteGoal.graphql");
const modifyGoalMutation = loader("../../graphql/goals/mutations/ModifyGoal.graphql");
const viewerAccountInfoQuery = loader("../../graphql/goals/queries/ViewerAccountInfo.graphql");
const createSavingsUserMutation = loader("../../graphql/goals/mutations/CreateSavingsUser.graphql");
const getLinkableAccountsQuery = loader("../../graphql/goals/queries/LinkableAccounts.graphql");
const makeOneTimeTransfer = loader("../../graphql/goals/mutations/OneTimeTransfer.graphql");
const linkFundingAccountMutation = loader(
  "../../graphql/goals/mutations/LinkFundingAccount.graphql"
);

export const setAllViewerGoals = (goals: any) => {
  return {
    type: types.SET_ALL_VIEWER_GOALS,
    payload: goals,
  };
};

export const goalDataLoading = (loading = true) => {
  return {
    type: types.GOAL_DATA_LOADING,
    payload: loading,
  };
};

export const goalDetailLoading = (goalId: string) => {
  return {
    type: types.GOAL_DETAIL_LOADING,
    payload: goalId,
  };
};

export const setGoalDetail = (goal: Goal) => {
  return {
    type: types.SET_GOAL_DETAIL,
    payload: goal,
  };
};

export const setViewerAccountInfo = (accountInfo: AccountInfo) => {
  return {
    type: types.SET_VIEWER_ACCOUNT_INFO,
    payload: accountInfo,
  };
};

let observer: ObservableQuery;
let subscription: ZenObservable.Subscription;
export const getAllViewerGoals = () => async (dispatch: Dispatch) => {
  const options = {
    query: getAllViewerGoalsQuery,
  };
  let fetching;
  if (observer) {
    fetching = observer.refetch();
  } else {
    fetching = new Promise<void>((resolve) => {
      observer = apolloClient.watchQuery(options);
      subscription = observer.subscribe({
        next(data) {
          dispatch(setAllViewerGoals(data.data.viewer));
          resolve();
        },
      });
    });
  }
  await fetching;
  return { observer, unsubscribe: () => subscription.unsubscribe() };
};

export const getViewerAccountInfo = () => async (dispatch: Dispatch) => {
  const options = { query: viewerAccountInfoQuery };
  try {
    const res = await apolloClient.query(options);
    dispatch(setViewerAccountInfo(res.data.viewer));
  } catch {}
};

export const createNewGoal = (goal: Goal) => async (dispatch: Dispatch) => {
  const { id, display, ...input } = goal;
  const options = {
    mutation: createNewGoalMutation,
    variables: { input },
  };
  dispatch(setLoading(true));
  dispatch(goalDataLoading(true));
  const response = await apolloClient.mutate(options);
  await dispatch(getAllViewerGoals() as any);
  dispatch(setLoading(false));
  return response.data.createGoal.id;
};

export const deleteGoal = (id: string) => async (dispatch: Dispatch) => {
  const options = {
    mutation: deleteGoalMutation,
    variables: { id },
  };
  dispatch(setLoading(true));
  try {
    await apolloClient.mutate(options);
    dispatch(setToastMessage("Goal deleted."));
  } catch (err) {
    const message = err?.graphQLErrors[0]?.message || err?.message || "Something went wrong";
    dispatch(setToastMessage(message));
  }
  dispatch(setLoading(false));
};

export const fetchGoalDetail = (goalId: string) => async (dispatch: Dispatch<any>) => {
  dispatch(goalDetailLoading(goalId));

  const options = {
    query: getGoal,
    variables: {
      id: goalId,
    },
  };
  subscription = apolloClient.watchQuery(options).subscribe({
    next(data) {
      dispatch(setGoalDetail(data.data.goal));
    },
  });

  return subscription;
};

export const modifyGoal =
  (id: string, updatedFields: Partial<Goal>, successMessage = i18n.t("goalUpdated")) =>
  async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const options = {
      mutation: modifyGoalMutation,
      variables: {
        input: { ...updatedFields, id },
      },
    };
    dispatch(setLoading(true));
    await apolloClient.mutate(options);
    await dispatch(getAllViewerGoals() as any);
    await dispatch(getAccountData());
    if (getState().goals.viewerGoals.find((g) => g.id === id)?.status !== GOAL_STATUS.NEW) {
      dispatch(setToastMessage(successMessage));
    }
    dispatch(setLoading(false));
  };

type name = { firstName: string; lastName: string };
export const createSavingsUser = (input: name) => async (dispatch: Dispatch) => {
  const options = {
    mutation: createSavingsUserMutation,
    variables: { input },
  };
  dispatch(setLoading(true));
  await apolloClient.mutate(options);
  dispatch(setLoading(false));
};

export const getLinkableAccounts = () => async (dispatch: Dispatch) => {
  const data = await apolloClient
    .query({
      query: getLinkableAccountsQuery,
      fetchPolicy: "network-only",
    })
    .catch(() => null);
  return data?.data?.viewer?.linkedAccounts ?? {};
};

export const linkFundingAccount = (accountId: string) => async (dispatch: Dispatch) => {
  await apolloClient.mutate({
    mutation: linkFundingAccountMutation,
    variables: {
      input: {
        accountId,
      },
    },
  });
};

export const activateGoal =
  (input: any) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(setLoading(true));
    const prevGoal = getState().goals.viewerGoals.find((goal) => goal.id === input.id)!;
    const fundingAccount = getFundingAccount(getState());

    await apolloClient.mutate({
      mutation: modifyGoalMutation,
      variables: {
        input,
      },
    });

    await dispatch(initGlobalData());

    let nextPageUrl = "";
    // case 1: it isn't a new goal and you're just trying to update the contribution rules
    if (
      prevGoal.status !== GOAL_STATUS.NEW &&
      prevGoal.viewerFundingInfo !== undefined &&
      prevGoal.viewerFundingInfo !== null &&
      prevGoal.viewerFundingInfo.status &&
      prevGoal.viewerFundingInfo.status.toUpperCase() === GOAL_FUNDING_ACCOUNT_STATUS.ACTIVE
    ) {
      dispatch(setToastMessage(i18n.t("ruleUpdated")));
      nextPageUrl = PAGE_URLS.GOALS_DETAILS;
      // case 2: it's a new goal, you set it up, and you aren't funding it from a joint account
    } else if (
      prevGoal.hhInfo?.privilegeType === PRIVILEGE_TYPE_DATA_2.SHARED &&
      fundingAccount?.hhInfo[0]?.accountOwnershipType &&
      ACCOUNT_OWNER_TYPE.JOINT !== fundingAccount?.hhInfo[0]?.accountOwnershipType &&
      (prevGoal.nonViewerFundingInfo === undefined || prevGoal.nonViewerFundingInfo === null)
    ) {
      nextPageUrl = PAGE_URLS.GOALS_INVITE_PARTNER;

      // case 3: it's a new goal
    } else {
      nextPageUrl = PAGE_URLS.GOALS_CELEBRATE_ACTIVATION;
    }
    dispatch(setLoading(false));
    return nextPageUrl.replace(":goalId", input.id);
  };

export const makeTransfer =
  (goalId: string, amount: number, type: "WITHDRAWAL" | "DEPOSIT") =>
  async (dispatch: Dispatch<any>) => {
    if (!amount || amount < 0) {
      dispatch(setToastMessage(i18n.t("checkTransferAmount")));
      return;
    }
    dispatch(setLoading(true));
    await apolloClient
      .mutate({
        mutation: makeOneTimeTransfer,
        variables: {
          input: { goalId, amount, type },
        },
        refetchQueries: [
          {
            query: getGoal,
            variables: {
              id: goalId,
            },
          },
        ],
      })
      .catch((err) => {
        const message = err?.graphQLErrors[0]?.message || err?.message || "Something went wrong";
        dispatch(setToastMessage(message));
      });
    dispatch(setLoading(false));
  };
