import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonTextarea,
  IonToolbar,
} from "@ionic/react";
import { PAGE_URLS } from "../../../constants";
import { SpendingChallengeType } from "../../../types/spendingChallenge";
import { useTranslation } from "react-i18next";
import {
  getFormatedChallengeDateDes,
  editSpendingChallenge,
} from "../../../utils/spendingChallenge";
import images from "../../../assets/images/illustrations";
import { sadOutline, happyOutline, ellipsisHorizontal } from "ionicons/icons";
import numeral from "numeral";
import { useDispatch, useSelector } from "../../../redux/hooks";
import { upperFirst } from "lodash";
import dayjs from "dayjs";
import {
  getSpendingChallenges,
  setToastMessage,
  updateSpendingChallenge,
} from "../../../redux/actions";
import { SpendingChallengeProgressBar } from "./SpendingChallengeProgressBar";
import utc from "dayjs/plugin/utc";
import { Comments } from "../../shared/Comments/Comments";
import { ChallengeType, ChallengeStatus } from "../../../types/spendingChallenge";
import { getSpendingChallengeState } from "../../../redux/selectors";
import { NavHeader, ActionSheet, PageTemplate, Section } from "../../shared/PageTemplate";
import { useViewport } from "../../../hooks/useViewport";
import { trackEvent } from "../../../vendors/monitoring";
import { getTransactionLink } from "../../../utils/transUtils";
dayjs.extend(utc);

export const SpendingChallengeDetail: React.FC = () => {
  const { challengeId } = useParams<any>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useViewport();
  const { spendingChallengesList } = useSelector(getSpendingChallengeState);

  //state
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [chatMessage, setChatMessage] = useState("");
  const [sendChatMessageChange, setSendChatMessageChange] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const targetChallenge = spendingChallengesList.find(
    (challenge: SpendingChallengeType) => challenge._id === challengeId
  );

  useEffect(() => {
    dispatch(getSpendingChallenges());
  }, [sendChatMessageChange, (spendingChallengesList || []).length, targetChallenge?.status]);

  const actionSheetButtons = () => {
    let buttons = [
      {
        text: t("createNewChallenge"),
        handler: () => {
          history.push(PAGE_URLS.SPENDING_CHALLENGES_NEW);
        },
      },
    ];
    if (targetChallenge?.status === ChallengeStatus.ACTIVE) {
      buttons.push({
        text: t("cancelChallenge"),
        handler: () => setAlertOpen(true),
      });
    }
    if (
      targetChallenge?.status === ChallengeStatus.ACTIVE ||
      targetChallenge?.status === ChallengeStatus.COMPLETED
    ) {
      buttons.unshift({
        text: t("seeTransactions"),
        handler: () => {
          history.push(getTransactionLink("advancedSearch", {
            spendingTargetId: targetChallenge?.targets[0]._id,
            spendingChallengeId: targetChallenge?._id,
          }));
        },
      });
    }
    return buttons;
  };

  const imagesSource = () => {
    return (
      // @ts-ignore
      images[targetChallenge?.targets[0].matchingCriteria[1].value.toLowerCase()] || images.others
    );
  };

  const handleReactionSend = async (reaction: any) => {
    await editSpendingChallenge({ challengeId: targetChallenge?._id, reaction })
      .then(() => dispatch(getSpendingChallenges()))
      .then(() => dispatch(setToastMessage(`${t("reaction")} ${t("sent")}`)));
    setSendChatMessageChange(!sendChatMessageChange);
  };

  const reactionSource = () => {
    switch (targetChallenge?.competitionType) {
      case ChallengeType.INDIVIDUAL:
        return <></>;
      case ChallengeType.COLLABORATIVE:
        return (
          <IonCol className="spendingChallenge-detail-reaction ion-justify-content-end" size="8">
            <IonButton fill="outline" onClick={() => handleReactionSend("cheer")}>
              <IonIcon icon={happyOutline} />
              <h4>{t("cheer")}</h4>
            </IonButton>
          </IonCol>
        );
      case ChallengeType.COMPETITIVE:
        return (
          <IonCol className="spendingChallenge-detail-reaction ion-justify-content-end" size="8">
            <IonButton fill="outline" onClick={() => handleReactionSend("taunt")}>
              <IonIcon icon={sadOutline} />
              <h4>{t("taunt")}</h4>
            </IonButton>
            <IonButton fill="outline" onClick={() => handleReactionSend("cheer")}>
              <IonIcon icon={happyOutline} />
              <h4>{t("cheer")}</h4>
            </IonButton>
          </IonCol>
        );
      default:
        return <></>;
    }
  };

  const renderSpendLessOrStakes = () => {
    if (targetChallenge?.competitionType === ChallengeType.COMPETITIVE) {
      if (targetChallenge.rewards[0].details?.text) {
        return (
          <IonItem>
            <IonLabel position="stacked">{t("theStakes")}</IonLabel>
            <IonRow className="ion-no-padding spendingChallenge-detail-duration-des">
              <p className="ion-text-end">{targetChallenge.rewards[0].details.text}</p>
            </IonRow>
          </IonItem>
        );
      }
      return <></>;
    } else {
      return (
        <IonItem>
          <IonLabel position="stacked">{t("spendLessThan")}</IonLabel>
          <IonRow>
            <h4>{numeral(targetChallenge?.targets[0].value).format("$0,0")}</h4>
          </IonRow>
        </IonItem>
      );
    }
  };

  const handleCommentSend = async () => {
    await editSpendingChallenge({
      challengeId: targetChallenge?._id,
      comment: chatMessage,
    })
      .then(() => dispatch(getSpendingChallenges()))
      .then(() => setChatMessage(""))
      .catch();
    setSendChatMessageChange(!sendChatMessageChange);
  };

  const handleChallengeReopen = () => {
    trackEvent("reopen_goal", { category: "goals" });
    dispatch(
      updateSpendingChallenge({
        challengeId: targetChallenge?._id,
        status: ChallengeStatus.ACTIVE,
      })
    );
  };

  const footerSection = (
    <IonFooter>
      <IonGrid>
        <IonRow className="ion-justify-content-between ion-align-items-center">
          <IonCol size="9" className="ion-no-padding">
            <IonTextarea
              rows={1}
              value={chatMessage}
              onIonChange={(e) => setChatMessage(e.detail.value || "")}
              autoGrow
              inputMode="text"
              className="transaction-detail-comments ion-padding-start"
              placeholder={t("addComment")}
            ></IonTextarea>
          </IonCol>
          <IonCol size="auto">
            <IonButton onClick={handleCommentSend}>{t("send")}</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonFooter>
  );

  return targetChallenge ? (
    <PageTemplate
      header={
        <NavHeader defaultHref={PAGE_URLS.HOME} desktopTitle={t("challenges")}>
          <ActionSheet buttons={actionSheetButtons()} />
        </NavHeader>
      }
      footer={isMobile ? footerSection : undefined}
    >
      <IonToolbar>
        <IonGrid className="ion-margin spendingChallenge">
          <Section>
            <IonRow className="spendingChallenge-detail-header ion-text-center">
              <h3>{`${t("challenge")} ${getFormatedChallengeDateDes(targetChallenge)}`}</h3>
            </IonRow>
            <IonRow className="ion-justify-content-between ion-align-items-center">
              <IonCol size="4">
                <img src={imagesSource()} alt="spending-challenge-compete" />
              </IonCol>
              {targetChallenge.status === ChallengeStatus.CANCELLED ? (
                <IonButton onClick={handleChallengeReopen}>{t("reopen")}</IonButton>
              ) : (
                reactionSource()
              )}
            </IonRow>
            <IonRow>
              <IonCol>
                <h2 className="font-lg bold">
                  {
                    targetChallenge.targets[0].matchingCriteria.find(
                      (ele: any) => ele.field === "beefCategoryName"
                    )?.value
                  }
                </h2>
              </IonCol>
            </IonRow>
            <SpendingChallengeProgressBar spendingChallenge={targetChallenge} />
            <IonItem className="ion-margin-top">
              <IonLabel position="stacked">{t("duration")}</IonLabel>
              <h4>
                {upperFirst(targetChallenge.periodType)}{" "}
                {`(${dayjs.utc(targetChallenge.startDate).format("ddd MMM D")} - ${dayjs
                  .utc(targetChallenge.endDate)
                  .format("ddd MMM D")})`}
              </h4>
            </IonItem>
            {renderSpendLessOrStakes()}
          </Section>
          <Section>
            <IonRow>
              <h2 className="font-lg bold">{t("activity")}</h2>
            </IonRow>
            <Comments
              comments={targetChallenge.comments}
              reactions={targetChallenge.reactions}
              clickEvents="list"
            />
          </Section>
        </IonGrid>
      </IonToolbar>
      {alertOpen ? (
        <IonAlert
          isOpen={alertOpen}
          onDidDismiss={() => setAlertOpen(false)}
          header={t("cancelSpendingChallenge")}
          buttons={[
            {
              text: t("no"),
              role: "cancel",
              handler: () => setAlertOpen(false),
            },
            {
              text: t("yes"),
              handler: () => {
                dispatch(
                  updateSpendingChallenge({
                    challengeId: targetChallenge?._id,
                    status: ChallengeStatus.CANCELLED,
                  })
                );
              },
            },
          ]}
        />
      ) : null}
    </PageTemplate>
  ) : null;
};
