import React, { useEffect, useState } from "react";
import { IonFooter, IonButton } from "@ionic/react";
import { useTranslation } from "react-i18next";

import { useDispatch } from "../../../redux/hooks";
import { getLinkableAccounts, linkFundingAccount, setLoading } from "../../../redux/actions";
import { AccountPicker } from "./AccountPicker";
import { LinkAccountButton } from "../../shared/Link/LinkAccountButton";
import { PAGE_URLS } from "../../../constants";
import { useCurrentGoal } from "../../../utils/useCurrentGoal";
import { ExitAlert } from "./ExitAlert";
import { useNavigation } from "../../../utils/useNavigation";
import { Section, PageTemplate, NavHeader } from "../../shared/PageTemplate";
import { useViewport } from "../../../hooks/useViewport";
import { HelpButton } from "../../shared/Support/HelpButton";
import { trackEvent } from "../../../vendors/monitoring";

export const FundingChooseAccount: React.FC = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const goal = useCurrentGoal();
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const [accounts, setAccounts] = useState({} as any);
  const [selectedId, setSelectedId] = useState("");
  const [reloadToken, setReloadToken] = useState(1);
  useEffect(() => {
    dispatch(getLinkableAccounts())
      .then(setAccounts)
      .catch((err) => console.error(err));
  }, [reloadToken]);

  const noAccounts = accounts.totalCount === 0;
  const next = () => {
    dispatch(setLoading(true));
    trackEvent("linkFundingAccount", { selectedId });
    dispatch(linkFundingAccount(selectedId)).then(() => {
      trackEvent("linkFundingAccount_successful", { selectedId });
      dispatch(setLoading(false));
      navigate(PAGE_URLS.GOALS_HOW_SAVE.replace(":goalId", goal.id), "forward");
    });
  };

  const footerSection = (
    <IonFooter className="ion-no-border ion-text-center">
      {noAccounts ? (
        <LinkAccountButton
          className="ion-margin"
          fill="solid"
          data-testid="linkAccountButton"
          onLink={() => setTimeout(() => setReloadToken(reloadToken + 1), 1000)}
        >
          {t("linkAnAccount")}
        </LinkAccountButton>
      ) : (
        <IonButton
          className="ion-margin "
          fill="solid"
          data-testid="chooseFundingAccountButton"
          disabled={!selectedId}
          onClick={next}
        >
          {t("next")}
        </IonButton>
      )}
    </IonFooter>
  );

  return (
    <PageTemplate
      header={
        <NavHeader title={t("savingsGoal")} backButton={<ExitAlert />}>
          <HelpButton
            pageLocation="goal-choose-funding-account"
            className="fy-nav-buttons"
            color=""
          />
        </NavHeader>
      }
      footer={isMobile ? footerSection : undefined}
    >
      <Section>
        <div className="ion-margin fy-section-title-border font-title-3 inter-font">
          {t("chooseAccount")}
        </div>
        <div className="ion-margin ion-padding-vertical font-md">
          {t(noAccounts ? "needToLinkAccount" : "linkAccountToGoal")}
        </div>
        {noAccounts ? <div className="ion-margin font-md">{t("sorryNoLinkedAccount")}</div> : null}
        <AccountPicker
          rows={accounts.rows || []}
          onSelect={setSelectedId}
          selectedAccountId={selectedId}
        />
        {isMobile ? null : footerSection}
      </Section>
    </PageTemplate>
  );
};
