import { createBrowserHistory } from "history";
import { getContext } from "./vendors/monitoring";

export const history = createBrowserHistory();

let prevPath: string | null = null;

// listen and notify Segment of client-side page updates
history.listen(async (location) => {
  if (location.pathname !== prevPath) {
    prevPath = location.pathname;
    const { context, eventProps } = await getContext();
    analytics.page(eventProps, context);
  }
});
