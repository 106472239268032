import React from "react";
import { IonLabel, IonList, IonItem, IonIcon } from "@ionic/react";
import { repeatSharp } from "ionicons/icons";
import dayjs from "dayjs";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

import { DailyPoint } from "../../../redux/reducers/spendingForecast";
import { useDispatch } from "../../../redux/hooks";
import { PAGE_URLS } from "../../../constants";
import { getAmountToDisplay, getTransactionLink } from "../../../utils/spendingForecastUtils";
import Amount from "../../shared/Amount/Amount";
import { TransactionBaseType } from "../../../types";
import { XRNoDomElement } from "@aws-amplify/xr";

type SpendingTransactionListProps = {
  data?: DailyPoint;
};

export const SpendingTransactionList: React.FC<SpendingTransactionListProps> = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  const date = dayjs(data?.date);
  const isProjected = data && !date.isBefore(dayjs(), "day");
  const variableSpending =
    data &&
    data.total - data.txns.reduce((total, txn) => total + (getAmountToDisplay(txn) ?? 0), 0);

  return (
    <IonList>
      <IonItem lines="full" key="header">
        <IonLabel slot="start" className="font-lg medium">
          {date.format("ddd, MMM D")} {isProjected ? ` (${t("projected")})` : null}
        </IonLabel>
        <IonLabel slot="end" className="ion-text-right font-lg medium">
          {numeral(data?.total).format("$0,0")}
        </IonLabel>
      </IonItem>
      {data?.txns
        .filter((txn) => txn)
        .map((txn) => (
          <IonItem
            lines="none"
            key={txn?.txnId || txn?.billId}
            routerLink={getTransactionLink(txn, data.date)}
          >
            <IonLabel slot="start">
              {txn?.isBill ? <IonIcon icon={repeatSharp} className="bill-icon" /> : null}
              {txn?.desc}
            </IonLabel>
            <IonLabel
              slot="end"
              className="ion-text-right"
              color={(getAmountToDisplay(txn) || 0) > 0 ? "danger" : "primary"}
            >
              <Amount amount={getAmountToDisplay(txn) || 0} formatOff />
            </IonLabel>
          </IonItem>
        ))}
      {isProjected ? (
        <IonItem lines="none" key="variable-spending">
          <IonLabel slot="start">{t("variableSpending")}</IonLabel>
          <IonLabel
            slot="end"
            className="ion-text-right"
            color={(variableSpending || 0) > 0 ? "danger" : "primary"}
          >
            {numeral(variableSpending).format("$0,0")}
          </IonLabel>
        </IonItem>
      ) : null}
      {!data?.txns.length ? (
        <IonItem lines="none" key="no-data">
          <IonLabel>{t("noTransactions")}</IonLabel>
        </IonItem>
      ) : null}
    </IonList>
  );
};
