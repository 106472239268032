import React from "react";
import { Category, Design } from "../../../types";
import categoryImages from "./../../../assets/images/categorization";

type CategoryImgStyleType = "light" | "dark";

export const getCategoryImgSrc = (design: Design, styleType: CategoryImgStyleType = "dark") => {
  const iconValue = styleType === "dark" ? design.iconDarkValue : design.iconLightValue;
  const iconType = styleType === "dark" ? design.iconDarkType : design.iconLightType;

  if (iconType === "uri") {
    return iconValue;
  }

  if (iconType === "local") {
    return categoryImages[iconValue as keyof typeof categoryImages];
  }
};

interface CategoryImgProps {
  category: Category | undefined;
  styleType?: CategoryImgStyleType;
  imgProps?: any;
}

export const CategoryImg: React.FC<CategoryImgProps> = ({
  category,
  imgProps,
  styleType = "dark",
}) => {
  if (!category || !category.design) return null;

  return (
    <img
      {...imgProps}
      src={getCategoryImgSrc(category.design, styleType)}
      alt={category.categoryFullName}
    />
  );
};
