import React from "react";
import { IonButton, IonFooter } from "@ionic/react";
import { useTranslation } from "react-i18next";

import goalIcons from "../../../assets/images/goal_icons";
import { PAGE_URLS } from "../../../constants";
import { useCurrentGoal } from "../../../utils/useCurrentGoal";
import { ExitAlert } from "./ExitAlert";
import { NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { useViewport } from "../../../hooks/useViewport";
import { HelpButton } from "../../shared/Support/HelpButton";
import { useInterestRate } from "../../../hooks/useInterestRate";

export const FundingSetupStart: React.FC = (props) => {
  const goal = useCurrentGoal();
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const { interestRate, showRate } = useInterestRate();

  const footerSection = (
    <IonFooter className="ion-text-center ion-no-border">
      <IonButton
        fill="solid"
        size="large"
        data-testid="fundingSetupStartButton"
        routerLink={PAGE_URLS.GOALS_FUNDING_SETUP_NAME.replace(":goalId", goal?.id ?? "")}
        routerDirection="forward"
      >
        {t("next")}
      </IonButton>
      <div className="ion-text-center ion-margin font-xs">{t("noMinimums")}</div>
    </IonFooter>
  );

  return (
    <PageTemplate
      header={
        <NavHeader
          title={t("savingsGoal")}
          backButton={<ExitAlert message={t("areYouSureExit")} />}
        >
          <HelpButton pageLocation="funding-setup-start" className="fy-nav-buttons" color="" />
        </NavHeader>
      }
      footer={isMobile ? footerSection : undefined}
    >
      <Section>
        <div className="ion-text-center ion-padding ion-margin-horizontal limit-desktop-width">
          <img alt="money bag" src={goalIcons.moneyBag} />
          <p className="font-md">
            {t(showRate ? "saveAndEarnInterest" : "saveAndMoneyIsInsured", { interestRate })}
          </p>
          <img alt="target" src={goalIcons.target} className="ion-padding-top" />
          <p className="font-md">{t("justSelectRule")}</p>
          <img alt="repeat icon" src={goalIcons.repeat} className="ion-padding-top" />
          <p className="font-md">{t("thenWellTransfer")}</p>
        </div>
        {isMobile ? null : footerSection}
      </Section>
    </PageTemplate>
  );
};
