import React from "react";
import { useIonViewDidEnter } from "@ionic/react";
import { trackEvent } from "../../../vendors/monitoring";
import Template, { OnboardingCopy } from "./Template";
import { PAGE_URLS } from "../../../constants";
import { useDispatch } from "../../../redux/hooks";
import { setUserProperties } from "../../../redux/actions";
import "./onboarding.scss";
import { OnboardingNavHeader } from "./OnboardingNavHeader";
import { OnboardingProgress } from "./OnboardingProgress";

const pageLocation = "has-partner";

export const HasPartner: React.FC = () => {
  const dispatch = useDispatch();

  const handleYes = () => {
    dispatch(setUserProperties({ hasPartner: true }));
  };

  const handleNo = () => {
    dispatch(setUserProperties({ hasPartner: false }));
  };

  const progressBar = <OnboardingProgress step={1} />;

  return (
    <Template
      header={
        <OnboardingNavHeader
          defaultHref={PAGE_URLS.WELCOME}
          pageLocation={pageLocation}
          showBackButton
          desktopTitle={progressBar}
        />
      }
      handlePrimary={handleYes}
      primaryRouterLink={PAGE_URLS.SIGNUP_INVITE}
      primaryTestId="hasPartner_yes"
      primaryCopy="yes"
      onboardingStep={1}
      showSecondary={true}
      secondaryCopy="no"
      handleSecondary={handleNo}
      secondaryTestId="hasPartner_no"
      secondaryRouterLink={PAGE_URLS.SIGNUP_NAME}
    >
      <OnboardingCopy
        desktopPrefix="enterInvite_prefix"
        title="enterInvite_title"
        copy_1="enterInvite_1"
      />
    </Template>
  );
};
