import React from "react";
import { useTranslation } from "react-i18next";
import {
  IonText,
  IonCheckbox,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { editHouseholdFinancialProfile } from "../../../../redux/actions";
import { useDispatch, useSelector } from "../../../../redux/hooks";
import { getHouseholdDataState } from "../../../../redux/selectors";
import { PAGE_URLS } from "../../../../constants";
import { PageTemplate, Section, NavHeader } from '../../../shared/PageTemplate';

export const SharedFinancesInsurance = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hh = useSelector(getHouseholdDataState);
  const hhProfile = hh?.hhProfiles[0] || {};
  const financialProfile = hhProfile.financialProfile || {};
  const insurance = financialProfile.insurance || {};
  const handleInsuranceUpdate = (slice: any) => {
    dispatch(
      editHouseholdFinancialProfile([
        {
          ...hhProfile,
          financialProfile: {
            ...financialProfile,
            insurance: {
              ...insurance,
              ...slice,
            },
          },
        },
      ])
    );
  };
  return (
    <PageTemplate
      header={<NavHeader title={t("profile")} defaultHref={PAGE_URLS.MORE_PROFILE_SHARED_FINANCES} />}
    >
      <Section>
        <IonText className="finances-header">
          <h2>{t("hhFinancesInsurance")}</h2>
          <p>{t("hhFinancesInsuranceDef")}</p>
        </IonText>
        {Object.keys(insurance).map((insuranceType) => {
          return (
            <IonItem lines="none" key={insuranceType}>
              <IonLabel>{t(`hhFinances_${insuranceType}Insurance`)}</IonLabel>
              <IonCheckbox
                checked={insurance[insuranceType]}
                onIonChange={(e) => handleInsuranceUpdate({ [insuranceType]: e.detail.checked! })}
              />
            </IonItem>
          );
        })}
      </Section>
    </PageTemplate>
  );
};
