import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonCol,
  IonFooter,
  IonInput,
  IonLabel,
  IonRange,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { PAGE_URLS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getCategoryState, getSpendingChallengeState } from "../../../redux/selectors";
import { getSpendingChallenges, updateCreateNewChanllengeParams } from "../../../redux/actions";
import "../GoalsTab/CelebrateActivation.scss";
import {
  createNewSpendingChallenge,
  validateSpendingChallengeData,
  defineGoalRange,
} from "../../../utils/spendingChallenge";
import { useHistory } from "react-router-dom";
import numeral from "numeral";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { NewChallengeModal } from "../../shared/SpendingChallenges/NewChallengeModal";
import { getSubcatDisplayName } from "../../../utils/categorizationUtils";
import { trackEvent } from "../../../vendors/monitoring";

export const NewSpendingChalllengeGoal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { amount, subCategoryFilter, newSpendingChallenge, categoryFilter } =
    useSelector(getSpendingChallengeState);
  const { subCategoryData } = useSelector(getCategoryState);
  const frequency = newSpendingChallenge.periodType || "weekly";
  const history = useHistory();
  const [confettiModalOpen, setConfettiModalOpen] = useState(false);
  //state
  const [rangeData, setRangeData] = useState(() => defineGoalRange(frequency, amount));

  useEffect(() => { }, [categoryFilter, subCategoryFilter, newSpendingChallenge]);

  const handleNextBtnClick = async () => {
    if (!validateSpendingChallengeData(newSpendingChallenge, subCategoryFilter, frequency)) {
      history.push(PAGE_URLS.SPENDING_CHALLENGES_NEW);
      return;
    }
    let targetValue = rangeData.goalAmount;
    let challengeTargets = [...newSpendingChallenge.targets];
    const newChallengeTargets = challengeTargets.map((target: any) => ({
      ...target,
      value: targetValue,
      type: "amt",
    }));
    const updatedNewSpendingChallengeData = {
      ...newSpendingChallenge,
      targets: newChallengeTargets,
    };
    dispatch(updateCreateNewChanllengeParams(updatedNewSpendingChallengeData));
    await createNewSpendingChallenge(updatedNewSpendingChallengeData);
    trackEvent("created_spendChallenge", { category: "spendingChallenge" });
    setConfettiModalOpen(true);
    dispatch(getSpendingChallenges());
    history.push(PAGE_URLS.HOME);
  };

  return (
    <PageTemplate
      header={<NavHeader desktopTitle={t("spendingChallengeTitle")} />}
      footer={
        <IonFooter className="ion-no-border">
          <IonToolbar no-border className="ion-text-center">
            <IonButton onClick={handleNextBtnClick} data-testid="newSpendingNextBtn4">
              {t("next")}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      }
    >
      <Section>
        <IonRow>
          <h2 className="ion-margin-start font-lg">{t("spendingChallenge")}</h2>
        </IonRow>
        <IonRow>
          {subCategoryFilter && amount > 0 ? (
            <h3 className="ion-margin-start font-md">{`${t("yourAverageSpending")} ${getSubcatDisplayName(subCategoryFilter, subCategoryData) || subCategoryFilter
              } ${t("is")} ${numeral(rangeData.amountBasedOnFrequency).format(
                "$0,0.00"
              )} ${frequency}. ${t("spendingGoal")}`}</h3>
          ) : (
            <h3 className="ion-margin-start font-md">{t("spendingGoal")}</h3>
          )}
        </IonRow>
        <IonRow className="ion-align-items-center ion-margin">
          <IonLabel className="ion-padding-end">$</IonLabel>
          <IonCol size="2">
            <IonInput
              data-testid="newSpendingChallengeGoal"
              className="newSpendingChallenge-input ion-text-center"
              value={rangeData.goalAmount}
              onIonChange={(e) => {
                setRangeData({
                  ...rangeData,
                  goalAmount: +e.detail.value! || 0,
                });
              }}
            ></IonInput>
          </IonCol>
          {rangeData.goalAmount === rangeData.suggestedAmount && <h3>{t("suggested")}</h3>}
        </IonRow>
        <IonRow className="ion-margin">
          <IonRange
            value={rangeData.goalAmount}
            max={rangeData.ceil}
            min={rangeData.floor}
            step={rangeData.step}
            onIonChange={(e) => {
              setRangeData({
                ...rangeData,
                goalAmount: e.detail.value as number,
              });
            }}
          ></IonRange>
        </IonRow>
        <NewChallengeModal
          open={confettiModalOpen}
          onClose={() => setConfettiModalOpen(false)}
        />
      </Section>
    </PageTemplate>
  );
};
