import React from "react";
import {
  IonButton,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
  IonFooter,
  IonContent,
} from "@ionic/react";
import "./PartialScreenModal.scss";
import { useTranslation } from "react-i18next";

type ModalProps = {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
  title?: string;
  subTitle?: string;
  leftButtonText?: string;
  rightButtonText?: string;
  rightButtonProps?: any;
  maxHeight?: number;
  className?: string;
};

export const PartialScreenModal: React.FC<ModalProps> = (props) => {
  const { t } = useTranslation();
  const {
    open,
    onClose,
    onAccept,
    title,
    subTitle,
    leftButtonText = t("cancel"),
    rightButtonText = t("update"),
    rightButtonProps = {},
    className = "",
    children,
  } = props;

  return (
    <IonModal isOpen={open} cssClass={`partial-modal ${className}`} onDidDismiss={onClose}>
      <IonHeader className="alert-head sc-ion-alert-md ion-text-center ion-no-border" mode="md">
        <IonTitle className="ion-padding-top font-lg bold">{title}</IonTitle>
        {subTitle ? <h5 className="font-sm half-padding ion-no-margin">{subTitle}</h5> : null}
      </IonHeader>
      <IonContent>{children}</IonContent>
      <IonFooter className="ion-no-border ion-no-margin">
        <IonToolbar no-border className="ion-text-center">
          <IonButton fill="outline" onClick={() => onClose()}>{leftButtonText}</IonButton>
          {rightButtonText ? (
            <IonButton onClick={() => onAccept()} data-testid="partialModalUpdateBtn" {...rightButtonProps}>
              {rightButtonText}
            </IonButton>
          ) : null}
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
