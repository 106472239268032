import {
  GET_CATEGORY,
  GET_SUBCATEGORY,
  CUSTOM_MAP,
  ACCOUNT_TYPES_MAP,
  SAVE_CATEGORIZATION_DATA,
} from "../actionTypes";
import { Category, SubCategory, AccountTypesMapItem } from "../../types";

export interface categoryState {
  categoryData: Category[];
  subCategoryData: SubCategory[];
  isCustomMap: boolean;
  categoryErrorMessage: any;
  accountTypesMap: Array<AccountTypesMapItem>;
}

const initialState: categoryState = {
  categoryData: [],
  subCategoryData: [],
  isCustomMap: false,
  categoryErrorMessage: "",
  accountTypesMap: [],
};

interface action {
  type: string;
  payload: any;
}

const category = (state: categoryState = initialState, action: action) => {
  switch (action.type) {
    case SAVE_CATEGORIZATION_DATA: {
      return {
        ...state,
        categoryData: action.payload.categories as Category[],
        subCategoryData: action.payload.subCategories as SubCategory[],
        isCustomMap: action.payload.isCustomMap as boolean,
        accountTypesMap: action.payload.accountTypesMap as Array<AccountTypesMapItem>,
      };
    }
    case GET_CATEGORY: {
      return {
        ...state,
        categoryData: action.payload as Category[],
      };
    }
    case GET_SUBCATEGORY: {
      return {
        ...state,
        subCategoryData: action.payload as SubCategory[],
      };
    }
    case CUSTOM_MAP: {
      return {
        ...state,
        isCustomMap: action.payload as boolean,
      };
    }
    case ACCOUNT_TYPES_MAP: {
      return {
        ...state,
        accountTypesMap: action.payload as Array<AccountTypesMapItem>,
      };
    }
    default: {
      return state;
    }
  }
};

export default category;
