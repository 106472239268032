import React, { useState, useEffect } from 'react'
import { IonFooter, IonText, IonButton } from '@ionic/react';
import { RouteComponentProps } from 'react-router'
import { get } from '../../../utils/apiOps';
import { components } from '../../../types/schema';
import { useTranslation } from 'react-i18next';
import { PAGE_URLS } from '../../../constants';
import { useNavigation } from '../../../utils/useNavigation';
import { PageTemplate, Section, NavHeader } from '../../shared/PageTemplate';
import { useViewport } from '../../../hooks/useViewport';

export const OfferDetails: React.FC<RouteComponentProps<{ offerId: string }>> = props => {
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const { isMobile } = useViewport();
  const { match: { params: { offerId } } } = props;
  let [offer, setOffer] = useState<components["schemas"]["OffersExample"]>();
  const goToOffer = () => {
    if (offer?.name === "Billshark") {
      return navigate(PAGE_URLS.BILLS_AUDIT_MAIN);
    }
    if (!offer?.offerDetails?.cta?.url) return;
    window.open(
      encodeURI(offer.offerDetails.cta.url),
      offer?.offerDetails.cta.blank ? "_system" : "_blank",
      `location=${offer.offerDetails.cta.location ? "yes" : "no"}`,
    )
  }

  useEffect(() => {
    get(true, { endpoint: `/offers/getAllOrOne?offersIds=${JSON.stringify([offerId])}` })
      .then(({ data }) => setOffer(data ? data[0] : { name: t("noResults") }))
    return () => { setOffer = () => { } };
  }, [])

  const footerSection = <IonFooter className="ion-text-center ion-no-border ion-padding">
    <IonButton onClick={goToOffer}>
      {t("getStarted")}
    </IonButton>
  </IonFooter>

  return <PageTemplate
    contentProps={{ className: "ion-text-center offer-details" }}
    header={<NavHeader title={offer?.name} />}
    footer={isMobile ? footerSection : undefined}
  >
    <Section >
      <IonText className="ion-padding ion-margin">
        <h4>
          {offer?.offerDetails?.topText}
        </h4>
      </IonText>
      {offer?.offerDetails?.logo ? <img
        src={offer.offerDetails.logo}
        alt={`${offer?.name} logo`}
        className="ion-padding ion-margin-top"
        style={{ width: 350, maxWidth: "80%" }}
      /> : null}
      <ul className="ion-text-start">
        {offer?.offerDetails?.bullets?.map(bullet => <IonText key={bullet} className="ion-padding">
          <li>
            <h3 className="ion-padding-end">
              {bullet}
            </h3>
          </li>
        </IonText>)}
      </ul>
      <h4>{offer?.additionalInfo?.text}</h4>
      {isMobile ? null : footerSection}
    </Section>
  </PageTemplate>
}

