import React, { useEffect, useState } from "react";
import Banner from "@workday/canvas-kit-react-banner";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../redux/hooks";
import { getAccountsState } from "../../../redux/selectors";
import "../NoInternet/NoInternet.scss";
import { post } from "../../../utils/apiOps";
import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonModal,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { ModalHeader } from "../Header/ModalHeader";
import { closeOutline } from "ionicons/icons";
import {
  AccountCollectionItem,
  AccountSource,
  accountState,
} from "../../../redux/reducers/accounts";
import LinkInstitutionWrapper, {
  BankLinkFormat,
  InstitutionSearchResp,
} from "./LinkInstitutionWrapper";
import { operations } from "../../../types/schema";
import { setLoading } from "../../../redux/actions";
import { PAGE_URLS } from "../../../constants";
import { useHistory } from "react-router";

const getInstitutionObject = (institution: AccountCollectionItem) => {
  const baseObj: InstitutionSearchResp = {
    id: institution?.sourceInstitutionBaseId,
    name: institution?.bank,
    source: institution?.source,
  };
  if (institution.source !== AccountSource.PLAID) {
    baseObj.bankTokenId = institution.sourceInstitutionId;
    baseObj.url = institution.loginUrl;
    baseObj.logo = institution.logo;
  }
  return baseObj;
};

export const AccountAlert: React.FC = () => {
  const { t } = useTranslation();
  const { accountsInAlert } = useSelector(getAccountsState);

  const [showFixModal, setShowFixModal] = useState(false);

  return accountsInAlert?.myAccounts.length > 0 ? (
    <div className="accounts-ui-alert">
      <Banner
        actionText=""
        slot="center"
        label={t("troubleConnecting")}
        onClick={() => setShowFixModal(true)}
      />
      <FixAccountModal
        isOpen={showFixModal}
        onClose={() => setShowFixModal(false)}
        sourceInstitutionId={accountsInAlert.myAccounts[0]}
      />
    </div>
  ) : null;
};

type apiRespNotifSettings =
  operations["api_plaid_publictoken"]["responses"]["200"]["content"]["application/json"];

export const FixAccountModal: React.FC<{
  onClose: () => void;
  isOpen: boolean;
  sourceInstitutionId: string;
}> = ({ onClose, isOpen, sourceInstitutionId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { accountsInAlert, myAccounts, otherAccountCollection }: accountState =
    useSelector(getAccountsState);

  const [institution, setInstitution] = useState<AccountCollectionItem>();
  const [publicToken, setPublicToken] = useState<string>();

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const ins = myAccounts
      .concat(otherAccountCollection || [])
      .find((inst) => inst.sourceInstitutionId === sourceInstitutionId);
    setInstitution(ins);

    if (ins && ins.source === AccountSource.PLAID) {
      dispatch(setLoading(true));
      post(true, {
        endpoint: "/plaid/publictoken",
        bodyData: {
          sourceInstitutionId,
        },
      })
        .then(({ data }: apiRespNotifSettings) => setPublicToken(data?.public_token))
        .catch(() => { })
        .finally(() => dispatch(setLoading(false)));
    }
  }, [isOpen, accountsInAlert, myAccounts, otherAccountCollection]);

  const handleClosePostLink = (accountWasLinked: boolean = false) => {
    if (accountWasLinked) {
      // dispatch(getAccountData());
    }
    onClose();
  };

  return (
    <IonModal id="FixAccountModal" isOpen={isOpen} onDidDismiss={onClose}>
      <ModalHeader title={t("alert")} startBtnOnClick={onClose} startBtnIcon={closeOutline} />
      <IonContent>
        <IonGrid className="ion-padding-horizontal">
          <IonRow>
            <IonCol>
              <h1 className="font-title-3">{t("fixAccountTitle")}</h1>
            </IonCol>
          </IonRow>
          <IonRow className="ion-margin-top font-md">
            <IonCol>
              {t("fixAccountMsg", {
                bankName: institution?.bank,
              })}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      {institution &&
        ((institution.source === AccountSource.PLAID && publicToken) ||
          institution.source !== AccountSource.PLAID) && (
          <IonFooter className="ion-no-border">
            <IonToolbar>
              <IonButton
                onClick={() => {
                  history.push(
                    PAGE_URLS.MORE_ACCTS_INSTITUTION_UNLINK.replace(
                      ":sourceInstitutionId",
                      institution?.sourceInstitutionId
                    )
                  );
                  onClose();
                }}
                fill="clear"
                color="medium"
                expand="full"
                data-testid="accountHasBeenClosed"
              >
                {t("accountHasBeenClosed")}
              </IonButton>

              <LinkInstitutionWrapper
                onClose={handleClosePostLink}
                buttonCopy={t("fix")}
                format={BankLinkFormat.BUTTON}
                publicToken={publicToken}
                institution={getInstitutionObject(institution)}
              />
            </IonToolbar>
          </IonFooter>
        )}
    </IonModal>
  );
};

export default AccountAlert;
