import React from 'react'
import { IonButton, IonButtons, IonIcon, IonLabel } from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import dayjs, { Dayjs } from 'dayjs'

import { useViewport } from '../../../hooks/useViewport';

type MonthPickerProps = {
  month: Dayjs;
  onChange?: (month: Dayjs) => void;
  getLink?: (month: string) => string;
  allowFutureMonths?: boolean;
}

export const MonthPicker: React.FC<MonthPickerProps> = props => {
  const { isMobile } = useViewport();
  const { month, onChange = () => { }, getLink = () => undefined, allowFutureMonths = false } = props
  return <IonButtons className={isMobile ? "ion-justify-content-center ion-text-center" : ""}>
    <IonButton
      onClick={() => onChange(month.subtract(1, "month"))}
      routerLink={getLink(month.subtract(1, "month").format("YYYY-MM-DD"))}
      routerDirection="none"
      fill="clear"
      size="small"
      data-testid="prev-month-button"
    >
      <IonIcon icon={chevronBackOutline} />
    </IonButton>
    <IonLabel style={{ width: 125 }} className="header-month font-title-3 ion-text-center">
      {month.format(month.isSame(dayjs(), "year") ? "MMMM" : "MMM. 'YY")}
    </IonLabel>
    <IonButton
      className={!allowFutureMonths && month.isSame(dayjs(), "month") ? 'hidden' : ''}
      onClick={() => onChange(month.add(1, "month"))}
      routerLink={getLink(month.add(1, "month").format("YYYY-MM-DD"))}
      routerDirection="none"
      fill="clear"
      size="small"
      data-testid="next-month-button"
    >
      <IonIcon icon={chevronForwardOutline} />
    </IonButton>
  </IonButtons>
}