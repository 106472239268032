import React, { useState } from "react";
import { IonAvatar, IonButton, IonItem, IonLabel, IonList, IonPopover } from "@ionic/react";
import { useTranslation } from "react-i18next";
import Avatars from "../Avatar/Avatars";
import { AVATAR_DISPLAY, BUDGET_SHARED_TYPE, PAGE_URLS } from "../../../constants";
import { startCase } from "lodash";
import { BudgetType } from "../../../types";
import dayjs from "dayjs";
import { useUserName } from "./useUserName";
import { useInvitePrompt } from "../../../hooks/useInvitePrompt";

interface ShareSettingsPopoverProps {
  budgetType?: BudgetType;
  baseUrl?: string;
  effectiveDate?: string;
  category?: string;
  isRoute?: boolean;
  onClick?: any;
}

export const ShareSettingsButtonAndPopover: React.FC<ShareSettingsPopoverProps> = ({
  budgetType,
  baseUrl = PAGE_URLS.BUDGET_HOME,
  category,
  effectiveDate,
  isRoute = true,
  onClick,
}) => {
  const { t } = useTranslation();
  const openInvitePrompt = useInvitePrompt();
  const { singlePlayerMode } = useUserName();
  const [sharedTypePopoverIsOpenObject, setSharedTypePopoverIsOpenObject] = useState<any>({
    isOpen: false,
    event: null,
  });
  const avatarType = singlePlayerMode
    ? AVATAR_DISPLAY.ME
    : budgetType === BUDGET_SHARED_TYPE.USER
    ? AVATAR_DISPLAY.PRIVATE
    : AVATAR_DISPLAY.SHARED;

  return (
    <>
      <IonButton
        onClick={
          singlePlayerMode
            ? openInvitePrompt
            : (event) => {
                event.persist();
                setSharedTypePopoverIsOpenObject({ isOpen: true, event });
              }
        }
        data-testid="ShareSettingsButton_user"
      >
        <Avatars avatarType={avatarType} sizeStyle="sm" />
      </IonButton>
      <IonPopover
        id="ShareSettingsPopover"
        data-testid="ShareSettingsPopover"
        isOpen={sharedTypePopoverIsOpenObject.isOpen}
        onDidDismiss={() => setSharedTypePopoverIsOpenObject({ isOpen: false, event: undefined })}
        event={sharedTypePopoverIsOpenObject.event}
      >
        <IonList className="ion-padding">
          <ShareSettingsItem
            routerLink={baseUrl
              .replace(":effectiveDate", dayjs(effectiveDate).format("YYYY-MM-DD"))
              .replace(":budgetType", BUDGET_SHARED_TYPE.HOUSEHOLD)
              .replace(":category", category || "")}
            avatarType={AVATAR_DISPLAY.SHARED}
            onClick={onClick}
            isRoute={isRoute}
            setSharedTypePopoverIsOpenObject={setSharedTypePopoverIsOpenObject}
            lines="full"
            label={startCase(t("household"))}
            className="ion-padding-bottom"
          />
          <ShareSettingsItem
            routerLink={baseUrl
              .replace(":effectiveDate", dayjs(effectiveDate).format("YYYY-MM-DD"))
              .replace(":budgetType", BUDGET_SHARED_TYPE.USER)
              .replace(":category", category || "")}
            avatarType={AVATAR_DISPLAY.PRIVATE}
            onClick={onClick}
            setSharedTypePopoverIsOpenObject={setSharedTypePopoverIsOpenObject}
            isRoute={isRoute}
            label={startCase(t("private"))}
          />
        </IonList>
      </IonPopover>
    </>
  );
};

interface ShareSettingsItemProps {
  avatarType: number;
  label: string;
  lines?: "none" | "full";
  className?: string;
  onClick: any;
  routerLink?: string;
  isRoute: boolean;
  setSharedTypePopoverIsOpenObject: any;
}

export const ShareSettingsItem: React.FC<ShareSettingsItemProps> = ({
  isRoute = true,
  label,
  routerLink,
  avatarType,
  lines = "none",
  className,
  onClick,
  setSharedTypePopoverIsOpenObject,
}) => {
  return isRoute ? (
    <IonItem
      className={className}
      lines={lines}
      onClick={() => {
        setSharedTypePopoverIsOpenObject({ isOpen: false, event: undefined });
      }}
      routerLink={routerLink}
      routerDirection="none"
      detail={false}
      data-testid={`ShareSettingsPopover_${avatarType === 0 ? "hh" : "user"}`}
    >
      <ShareSettingsItemContent avatarType={avatarType} label={label} />
    </IonItem>
  ) : (
    <IonItem
      className={className}
      lines={lines}
      onClick={() => {
        setSharedTypePopoverIsOpenObject({ isOpen: false, event: undefined });
        onClick(
          avatarType === AVATAR_DISPLAY.SHARED
            ? BUDGET_SHARED_TYPE.HOUSEHOLD
            : BUDGET_SHARED_TYPE.USER
        );
      }}
      data-testid={`ShareSettingsPopover_${avatarType === 0 ? "hh" : "user"}`}
    >
      <ShareSettingsItemContent avatarType={avatarType} label={label} />
    </IonItem>
  );
};

interface ShareSettingsItemContentProps {
  avatarType: number;
  label: string;
}

export const ShareSettingsItemContent: React.FC<ShareSettingsItemContentProps> = ({
  avatarType,
  label,
}) => {
  return (
    <>
      <IonAvatar slot="start">
        <Avatars avatarType={avatarType} />
      </IonAvatar>
      <IonLabel className="font-sm ion-text-center">{label}</IonLabel>
    </>
  );
};
