import React from "react";
import {
  IonButtons,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonToolbar,
  IonFooter,
  IonTitle,
} from "@ionic/react";
import images from "../../../assets/images/illustrations";
import icons from "../../../assets/images/spending_challenge_icons";
import {
  ChallengeStatus,
  SpendingChallengeType,
  ChallengeType,
} from "../../../types/spendingChallenge";
import { SpendingChallengeProgressBar } from "./SpendingChallengeProgressBar";
import {
  getFormatedChallengeDateDes,
  getSpendingChallengeResult,
} from "../../../utils/spendingChallenge";
import { PAGE_URLS } from "../../../constants";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUsersState } from "../../../redux/selectors";
import { useUserName } from "../../shared/User/useUserName";
import { useTranslation } from "react-i18next";
import { addOutline } from "ionicons/icons";

type CardType = "active" | "overview";
interface SpendingChallengeCardTemp {
  spendingChallenge: SpendingChallengeType;
  type: CardType;
}

export const SpendingChallengeCardTemp: React.FC<SpendingChallengeCardTemp> = (props) => {
  const { spendingChallenge, type } = props;
  const history = useHistory();
  const { t } = useTranslation();

  switch (type) {
    case "active":
      return (
        <IonCard className="ion-padding-bottom fy-section-card">
          <IonButtons className="ion-padding-top newSpendingChallenge-home-card-header">
            <div className=" font-md fy-margin-horizontal">
              {t("homeCards_spending-challenges")}
            </div>
            <IonButton routerLink={PAGE_URLS.SPENDING_CHALLENGES_NEW} className="create-icon">
              <IonIcon icon={addOutline} />
            </IonButton>
          </IonButtons>
          <IonToolbar
            onClick={() =>
              history.push(
                PAGE_URLS.SPENDING_CHALLENGES_DETAILS.replace(":challengeId", spendingChallenge._id)
              )
            }
          >
            <CardHeader spendingChallenge={spendingChallenge} />
            <CardContent spendingChallenge={spendingChallenge} />
          </IonToolbar>
          <IonFooter className="ion-no-border">
            <IonToolbar no-border className="ion-text-center">
              <IonButton
                routerLink={PAGE_URLS.SPENDING_CHALLENGES_OVERVIEW}
                fill="clear"
                expand="full"
              >
                {t("seeMore")}
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </IonCard>
      );
    case "overview":
      return (
        <IonCard
          className="ion-padding-bottom fy-section-card"
          routerLink={PAGE_URLS.SPENDING_CHALLENGES_DETAILS.replace(
            ":challengeId",
            spendingChallenge._id
          )}
          data-testid="spendingChallengeCard"
        >
          <CardHeader spendingChallenge={spendingChallenge} />
          <CardContent spendingChallenge={spendingChallenge} />
        </IonCard>
      );
    default:
      return null;
  }
};

type CardSectionProps = {
  spendingChallenge: SpendingChallengeType;
};

const CardContent: React.FC<CardSectionProps> = (props) => {
  const { spendingChallenge } = props;
  return (
    <IonCardContent className="spendingChallenge-progressBar ion-text-center">
      {spendingChallenge.status === ChallengeStatus.COMPLETED ? null : (
        <h3 className="ion-text-center">{getFormatedChallengeDateDes(spendingChallenge)}</h3>
      )}
      <SpendingChallengeProgressBar spendingChallenge={spendingChallenge} />
      {spendingChallenge.status === ChallengeStatus.COMPLETED ? (
        <h3 className="ion-text-center text-muted">
          {getFormatedChallengeDateDes(spendingChallenge)}
        </h3>
      ) : null}
    </IonCardContent>
  );
};

const CardHeader: React.FC<CardSectionProps> = (props) => {
  const { spendingChallenge } = props;
  const { t } = useTranslation();
  const { userId } = useSelector(getUsersState);
  const { partnerName } = useUserName();

  const spendingChallengeResult = getSpendingChallengeResult(
    spendingChallenge,
    userId,
    partnerName
  );

  const imagesSource =
    // @ts-ignore
    images[spendingChallenge?.targets[0].matchingCriteria[1].value.toLowerCase()] || images.others;

  if (spendingChallenge.status === ChallengeStatus.COMPLETED && spendingChallengeResult.message)
    return (
      <IonCardHeader className="ion-text-center">
        <div className="spendingChallenge-result">
          <img src={imagesSource} alt="spending-challenge-trophy" className="ion-margin" />
          <img
            src={icons[`${spendingChallengeResult.header}Icon`]}
            alt="spending-challenge-result-icon"
            className="spendingChallenge-result-icon"
          />
        </div>
        <IonCardTitle className="bold">{t(spendingChallengeResult.header)}</IonCardTitle>
        <h3 className="ion-no-margin ion-margin-top font-md">{spendingChallengeResult.message}</h3>
      </IonCardHeader>
    );
  return (
    <IonCardHeader className="ion-text-center">
      <img
        src={imagesSource}
        alt={spendingChallenge?.targets[0].matchingCriteria[1].value.toLowerCase()}
        className="ion-margin"
      />
      <IonCardTitle>{spendingChallenge.name}</IonCardTitle>
    </IonCardHeader>
  );
};
