import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
} from "@ionic/react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { PAGE_URLS, S3_DOC_TYPES, S3_URL_TYPES } from "../../../constants";
import { get, post, put } from "../../../utils/apiOps";
import { getBillsAuditDataState } from "../../../redux/selectors";
import { getBillsAuditData, setLoading, updateSelectedBillInfo } from "../../../redux/actions";
import { billStatusTriage } from "../../../utils/billsAuditUtils";
import { BillsAuditSteps, billsharkGetS3UrlApiResp } from "../../../types/billsAudit";
import images from "../../../assets/images/illustrations";
import "./BillsAuditUploadDocs.scss";
import { cloudUpload } from "ionicons/icons";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";

export const BillsAuditUploadDocs: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { selectedBillerInfo, billsAuditData } = useSelector(getBillsAuditDataState);
  const [selectedFile, setSelectedFile] = useState<File | null>();

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target.files![0]);
  };

  const formatFileName = (fileName: string | undefined, fallbackText: string, maxLength = 25) => {
    if (fileName) {
      const fileExtenstion = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2); //e.g. jpg
      const fileNameBase = fileName.substr(0, fileName.length - fileExtenstion.length - 1); //e.g. my_bill_invoice
      return (
        fileNameBase.substr(0, maxLength) +
        (fileNameBase.length > maxLength ? ".." : "") +
        "." +
        fileExtenstion
      );
    } else {
      return fallbackText;
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!selectedFile) {
      alert("select file");
      return;
    }
    dispatch(setLoading(true));
    try {
      // get a signed url from s3
      const s3Url: billsharkGetS3UrlApiResp = await get(true, {
        endpoint: "/aws/s3SignedUrl",
        params: {
          billsharkBillsId: selectedBillerInfo.billsharkBillDetails?.billsharkInfo?._id,
          urlType: S3_URL_TYPES.PUT_OBJECT,
          contentType: selectedFile.type,
          docType: S3_DOC_TYPES.BILLSHARK,
        },
      });
      //update to S3
      await put(false, {
        endpoint: s3Url.data?.signedUrl!,
        bodyData: selectedFile,
        headers: {
          "content-type": selectedFile.type,
          "Access-Control-Allow-Origin": "*",
        },
      });
      //update doc url in DB
      await post(true, {
        endpoint: "/billshark/updateDocUrl",
        bodyData: {
          billsharkBillsId: selectedBillerInfo.billsharkBillDetails?.billsharkInfo?._id,
          urlType: S3_URL_TYPES.GET_OBJECT,
          docType: "billsharkBills",
          fileName: selectedFile.name,
        },
      });
      // refresh the billsAuditData
      await (dispatch(getBillsAuditData()) as any);
      dispatch(updateSelectedBillInfo(selectedBillerInfo.billsharkProviderId!));
      setSelectedFile(null);
      const myForm: HTMLFormElement = document.getElementById("FileUploadForm") as HTMLFormElement;
      if (myForm) {
        myForm.reset();
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const noMoreDocs = () => {
    history.push(PAGE_URLS.BILLS_AUDIT_USER_ADDITIONAL_DATA);
  };

  // make sure they should be here
  const rightPageCheck = () => {
    billStatusTriage(selectedBillerInfo.billsharkProviderId!).then((triageResp) => {
      if (triageResp.baStep !== BillsAuditSteps.UPLOAD_DOCS) {
        history.replace(PAGE_URLS.BILLS_AUDIT_MAIN);
      }
    });
  };

  useEffect(() => {
    if (!billsAuditData) {
      (dispatch(getBillsAuditData()) as any).then(() => {
        rightPageCheck();
        dispatch(updateSelectedBillInfo(selectedBillerInfo.billsharkProviderId!));
      });
    } else {
      rightPageCheck();
      dispatch(updateSelectedBillInfo(selectedBillerInfo.billsharkProviderId!));
    }
  }, [dispatch]);

  return (
    <PageTemplate
      pageProps={{ id: "BillsAuditUploadDocs" }}
      contentProps={{ fullscreen: true, className: "ion-padding" }}
      header={<NavHeader title={t("homeCards_billsAuditTitle")} />}
    >
      <Section className="ion-padding-start ion-padding-end">
        <IonGrid>
          <IonRow>
            <IonCol>
              <img id="UploadDocImg" src={images.billsAuditDoc} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <h1 className="font-title-3">
                {t("billsAuditUploadRecent", { billerName: selectedBillerInfo.displayName })}
              </h1>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <h3 className="font-md">{t("billsAuditUploadWhy")}</h3>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <form id="FileUploadForm" onSubmit={handleSubmit}>
                <div className="ion-margin-bottom ion-margin-top">
                  <label className="fileContainer">
                    <IonIcon icon={cloudUpload} />
                    &nbsp;
                    {formatFileName(selectedFile?.name, t("billsAuditChooseFile"), 25)}
                    <input
                      id="FileUploadInput"
                      name="fileNameInput"
                      type="file"
                      accept="image/*,application/pdf"
                      onChange={(e) => onFileChange(e)}
                      data-testid="ba_filename_input"
                    />
                  </label>
                </div>
                <div>
                  <IonButton type="submit" hidden={!selectedFile} data-testid="ba_upload_submit">
                    {t("billsAuditUpload")}
                  </IonButton>
                </div>
              </form>
            </IonCol>
          </IonRow>
          {(selectedBillerInfo.billsharkBillDetails?.billsharkInfo?.billDocs?.length || 0) > 0 && (
            <IonRow>
              <IonCol>
                <h3>{t("billsAuditUploadedDocs")}</h3>
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol>
              <IonList>
                {selectedBillerInfo.billsharkBillDetails?.billsharkInfo?.billDocs?.map((f) => {
                  return (
                    <IonItem key={uuidv4()} data-testid="bs_uploaded_doc">
                      <a target="_blank" href={f.url}>
                        {formatFileName(f.fileName, "file ", 35)}
                      </a>
                    </IonItem>
                  );
                })}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div id="NoMoreBillsUploadDiv">
          <IonButton
            id="NoMoreBillsUploadButton"
            className="ion-margin-bottom"
            hidden={
              (selectedBillerInfo.billsharkBillDetails?.billsharkInfo?.billDocs?.length || 0) === 0
            }
            disabled={
              (selectedBillerInfo.billsharkBillDetails?.billsharkInfo?.billDocs?.length || 0) === 0
            }
            onClick={noMoreDocs}
            data-testid="ba_no_more_docs"
          >
            {t("billsAuditNoMoreDocs")}
          </IonButton>
        </div>
      </Section>
    </PageTemplate>
  );
};
