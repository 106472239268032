import axios, { AxiosRequestConfig, AxiosResponse, CancelToken } from "axios";
import { Network, Plugins } from "@capacitor/core";
import { Auth } from "@aws-amplify/auth";
import i18n from "i18next";
import { setToastMessage } from "../redux/actions";
import { API_STATUS_CODES } from "../constants";
import store from "../redux/store";
import lodash from "lodash";

const { Device } = Plugins;

interface requestData {
  endpoint: string;
  params?: any;
  bodyData?: any;
  headers?: any;
  cancelToken?: CancelToken;
}

export const getCognitoToken = async () => {
  let userSession = null;
  try {
    userSession = await Auth.currentSession();
  } catch {}
  return userSession ? `Bearer ${userSession.getIdToken().getJwtToken()}` : "";
};

/**
 * In event node responds a string of errors, this function parses it for Toasting
 * @param errorMessages
 */
const cleanUpErrors = (errorMessages: any) => {
  return errorMessages.map((msg: any) => {
    const field = msg.slice(msg.search(/:/) + 1, msg.search(/,/)).replace(/'/g, "");
    const errors = msg.slice(msg.search(/\[/) + 1, msg.search("]")).split(",");
    const clean = errors.map((m: string) => m.replace(/'/g, "").trim());
    return clean.map((e: any) => {
      const r = `${field.trim()} ${clean}`.toLowerCase();
      return r.charAt(0).toUpperCase() + r.slice(1);
    });
  });
};

async function checkResponses(response: any) {
  if (response.data.status >= 400) {
    const codesToSendBack = [API_STATUS_CODES.UNAUTHORIZED, API_STATUS_CODES.NO_CONTENT];
    if (codesToSendBack.includes(response.data.status)) {
      throw response.data.status;
    } else {
      throw response.data.message;
    }
  }
}

const handleUnauthorized = async (response: AxiosResponse) => {
  console.debug("handleUnauthorized");
  console.error(response);
};

type httpMethod = "get" | "post" | "put" | "delete";
const sendApiRequest = async (method: httpMethod, authenticated: boolean, data: requestData) => {
  const finalHeaders = {
    api_key: process.env.REACT_APP_API_KEY,
    "content-type": "application/json",
    ...data.headers,
  };
  const config: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL + "api/",
    headers: finalHeaders,
    url: data.endpoint,
    method,
    data: data.bodyData,
    params: data.params,
  };
  if (authenticated) {
    config.headers.Authorization = await getCognitoToken();
  }
  //TODO: Store in Redux on app open; don't call it each time
  const deviceInfo = await Device.getInfo();
  config.headers["X-device-uuid"] = deviceInfo.uuid;

  if (data.cancelToken) {
    config.cancelToken = data.cancelToken;
  }

  try {
    const response: AxiosResponse = await axios.request(config);
    console.debug(response);
    // check the inner response - fallback shouldn't happen anymore
    await checkResponses(response);

    return response.data;
  } catch (err) {
    // if the request was canceled (e.g., user searched for new term
    // before previous request was returned)
    if (axios.isCancel(err)) {
      return;
    }

    let apiError = err;
    if (err.response) {
      console.debug(`not 200`);
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      apiError = err.response.data; //extract the Honeyfi error payload
      if (
        err.response.error_code === API_STATUS_CODES.UNAUTHORIZED ||
        err.response.status === API_STATUS_CODES.UNAUTHORIZED
      ) {
        await handleUnauthorized(err.response);
      }
      try {
        apiError.data = cleanUpErrors(err.response.data.data);
      } catch (cleanUpError) {
        console.debug("cleanUpErrors failed: " + cleanUpError);
      }
      store.dispatch(
        setToastMessage(lodash.isEmpty(apiError.data) ? apiError.message : apiError.data)
      );
    } else if (err.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      const { connected } = await Network.getStatus();
      if (connected) {
        console.debug(`sendApiRequest err: No response from server, but online.`);
        console.error(err);
        store.dispatch(setToastMessage(i18n.t("apiErrNoResponse")));
      } else {
        console.debug(`sendApiRequest err: No response from server, OFFLINE.`);
        store.dispatch(setToastMessage(i18n.t("offline")));
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error(err);
      store.dispatch(setToastMessage(i18n.t("apiErrUnknown")));
    }
    //send it back to the component to do what it needs to.
    throw apiError;
  }
};

/**
 * GET against node
 * @param authenticated True/false whether to add auth_token
 * @param data GET request data
 */
export const get = async (authenticated: boolean, data: requestData) => {
  return sendApiRequest("get", authenticated, data);
};

/**
 * POST against node
 * @param authenticated True/false whether to add auth_token
 * @param data POST request data
 */
export const post = async (authenticated: boolean, data: requestData) => {
  return sendApiRequest("post", authenticated, data);
};

/**
 * PUT against node
 * @param authenticated True/false whether to add auth_token
 * @param data PUT request data
 */
export const put = async (authenticated: boolean, data: requestData) => {
  return sendApiRequest("put", authenticated, data);
};

/**
 * DELETE against node
 * @param authenticated True/false whether to add auth_token
 * @param data Delete request data
 */
export const httpDelete = async (authenticated: boolean, data: requestData) => {
  return sendApiRequest("delete", authenticated, data);
};

/**
 * Generates a fresh cancel token source from Axios
 * @returns cancelTokenSource
 */
export const getCancelTokenSource = () => {
  return axios.CancelToken.source();
};
