import * as types from "../actionTypes";
import * as typescriptType from "../../types/transactions";
import { get, getCancelTokenSource, post } from "../../utils/apiOps";
import { Dispatch } from "redux";
import { setLoading } from "./ui";
import { CancelTokenSource } from "axios";
import { RootState } from "../reducers";
import { EditTransactionType } from "../../types/transactions";
import { refreshTransactionDependencies } from "./globalActions";

export enum TransactionUpdateDataFields {
  IS_BILL = "isBill",
  BILLS = "bills",
  CATEGORY = "category",
  SUBCATEGORY = "subcategory",
  RULES = "rules",
  IS_PRIVATE = "isPrivate",
  SHARED_EXPENSE = "sharedExpense",
  SHARED_EXPENSE_INDIVIDUAL = "sharedExpenseIndividual",
  TRANSACTION_VISIBILITY = "transactionVisibility",
}

export const defaultSearchResultsMeta: typescriptType.CreditDebitData = {
  credit: { count: 0, total: 0 },
  debit: { count: 0, total: 0 },
};

export const defaultSearchParams: typescriptType.SearchParam = {
  keyword: "",
  transactionFilter: "",
  categoryFilter: "",
  subCategoryFilter: "",
  accountIds: "",
  baseType: typescriptType.TransactionBaseType.NONE,
  isPrivate: "both",
  pageNumber: 1,
};

export type TransactionUpdateData = {
  field: TransactionUpdateDataFields;
  value: any;
  hhId?: string;
};

export interface UpdateDataForArrayTransactionsProps {
  transactionIdsArray: string[];
  updateDataArray: TransactionUpdateData[];
}

export const updateDataForArrayTransactions = ({
  transactionIdsArray,
  updateDataArray,
}: UpdateDataForArrayTransactionsProps) => {
  return {
    type: types.UPDATE_DATA_FOR_ARRAY_TRANSACTIONS,
    payload: {
      transactionIdsArray,
      updateDataArray,
    },
  };
};

let cancelTokenSource: CancelTokenSource;

export const getAllTransactions =
  (
    context: typescriptType.TransactionContext = "list",
    params: typescriptType.SearchParam = defaultSearchParams
  ) =>
  async (dispatch: Dispatch<any>) => {
    try {
      if (cancelTokenSource) {
        // Cancel the previous request before making a new request
        console.debug("Previous request was canceled");
        cancelTokenSource.cancel();
      }

      if (params.pageNumber === undefined) {
        params.pageNumber = 1;
      }

      dispatch(clearOrUpdateTransactionSearchResults(context, params));

      cancelTokenSource = getCancelTokenSource();
      const { data } = await get(true, {
        endpoint: "/filterTransaction",
        params: {
          ...defaultSearchParams,
          includeMetaData: context !== "list",
          ...params,
        },
        cancelToken: cancelTokenSource.token,
      });
      if (data) {
        dispatch(setTransactionSearchResults(data, context));
        dispatch(setLoading(false));
      } else {
        dispatch(
          setTransactionSearchResults({ transactions: [], meta: defaultSearchResultsMeta }, context)
        );
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

export const refreshTransactions =
  () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const {
      transactions: { context, searchParams },
    } = getState();
    await dispatch(getAllTransactions(context, searchParams));
  };

export const createNewTransaction = (params: any) => async (dispatch: Dispatch<any>) => {
  dispatch(clearOrUpdateTransactionSearchResults("list", params));
  try {
    await post(true, { endpoint: "/transaction/add", bodyData: params });
    dispatch(getAllTransactions());
  } catch (error) {}
};

export const updateTransactionDetail =
  (bodyData: EditTransactionType) => async (dispatch: Dispatch<any>) => {
    try {
      await post(true, {
        endpoint: "transaction/edit",
        bodyData,
      });
      return dispatch(getAllTransactions());
    } catch (error) {}
  };

export const clearOrUpdateTransactionSearchResults = (
  context: typescriptType.TransactionContext,
  searchParams: typescriptType.SearchParam
) => {
  return {
    type: types.SET_TRANSACTIONS_LOAD_START,
    payload: { searchParams, context },
  };
};

export const setTransactionSearchResults = (
  transactionResult: typescriptType.SearchResult,
  context: typescriptType.TransactionContext
) => {
  return {
    type: types.SET_TRANSACTION_SEARCH_RESULTS,
    payload: { transactionResult, context },
  };
};

export const deleteManualTransaction =
  (transactionId: string) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const { userId, hhIdDefault: hhId } = getState().users;
    dispatch(setLoading(true));
    await post(true, {
      endpoint: "/transaction/delete",
      bodyData: {
        userId,
        hhId,
        transactionId,
      },
    });
    await dispatch(refreshTransactionDependencies(true));
    dispatch(setLoading(false));
  };
