import {
  SET_TRANSACTION_SEARCH_RESULTS,
  SET_TRANSACTIONS_LOAD_START,
  UPDATE_DATA_FOR_ARRAY_TRANSACTIONS,
} from "../actionTypes";
import * as type from "../../types/transactions";
import { UpdateDataForArrayTransactionsProps } from "../actions";
import cloneDeep from "lodash/cloneDeep";
import { defaultSearchParams, defaultSearchResultsMeta } from "../actions/transactions";
import uniqBy from "lodash/uniqBy";
interface transactionState {
  context: type.TransactionContext;
  searchResultsMeta: type.CreditDebitData;
  searchParams: type.SearchParam;
  updatedFields: string[];
  transactionsList: type.Transaction[];
  loading: boolean;
}

const initialState: transactionState = {
  searchResultsMeta: defaultSearchResultsMeta,
  context: "list",
  searchParams: {} as type.SearchParam,
  updatedFields: [],
  transactionsList: [],
  loading: false,
};

type TransactionPayload = {
  transactionResult: type.SearchResult;
  context: type.TransactionContext;
};

interface action {
  type: string;
  payload: {};
}

const transactions = (state: transactionState = initialState, action: action) => {
  switch (action.type) {
    case UPDATE_DATA_FOR_ARRAY_TRANSACTIONS: {
      const data = action.payload as UpdateDataForArrayTransactionsProps;
      const { transactionIdsArray, updateDataArray } = data;
      if (!transactionIdsArray) {
        return state;
      }
      const copyTransactionsList: type.Transaction[] = cloneDeep(state.transactionsList);
      const updatedFields = [...state.updatedFields];
      transactionIdsArray.map((txnId) => {
        const thisTxn = copyTransactionsList.find((t) => t._id === txnId);
        if (thisTxn) {
          updateDataArray.map((d) => {
            let toEditHh = thisTxn.hhInfo[0];
            const thisHh = thisTxn.hhInfo.find((hh) => hh.hhId === d.hhId);
            updatedFields.push(d.field);
            if (d.hhId && thisHh) {
              toEditHh = thisHh;
            }
            switch (d.field) {
              case "category":
                toEditHh.beefCategoryName = d.value;
                break;
              case "subcategory":
                toEditHh.honeyfiCategoryName = d.value;
                break;
              case "isPrivate":
                toEditHh.isPrivate = d.value;
                break;
              case "sharedExpense":
                toEditHh.sharedExpense = d.value;
                break;
              case "sharedExpenseIndividual":
                toEditHh.sharedExpenseIndividual = d.value;
              default:
                // @ts-ignore
                thisTxn[d.field] = d.value;
                break;
            }
          });
        }
      });

      return {
        ...state,
        updatedFields,
        transactionsList: copyTransactionsList,
      };
    }
    case SET_TRANSACTION_SEARCH_RESULTS: {
      let {
        transactionResult: { transactions, meta },
        context,
      } = action.payload as TransactionPayload;
      const isNewSearch =
        state.searchParams.pageNumber === 1 ||
        (context && context !== state.context) ||
        !state.searchParams.pageNumber;
      return {
        ...state,
        searchResultsMeta:
          isNewSearch && transactions.length
            ? meta || defaultSearchResultsMeta
            : state.searchResultsMeta,
        updatedFields: isNewSearch ? [] : state.updatedFields,
        context: context || state.context,
        transactionsList: isNewSearch
          ? transactions
          : uniqBy([...state.transactionsList, ...transactions], "_id"),

        loading: false,
      };
    }
    case SET_TRANSACTIONS_LOAD_START: {
      const { searchParams, context } = action.payload as Partial<transactionState>;
      const isNewSearch = searchParams?.pageNumber === 1 || (context && state.context !== context);
      return {
        ...state,
        updatedFields: isNewSearch ? [] : state.updatedFields,
        searchResultsMeta: isNewSearch ? defaultSearchResultsMeta : state.searchResultsMeta,
        context: context || state.context,
        transactionsList:
          //regular search triggers while user types. We don't want to clear the results, until the new results load
          isNewSearch && context !== "search" ? ([] as type.Transaction[]) : state.transactionsList,
        searchParams: searchParams as type.SearchParam,
        loading: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default transactions;
