import { useState } from "react";
import { useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";

export const useInView = () => {
  const [isInView, setIsInView] = useState<boolean | undefined>();
  useIonViewDidEnter(() => {
    setIsInView(true);
  });
  useIonViewDidLeave(() => {
    setIsInView(false);
  });
  return isInView;
};
