import React from 'react'
import { useSelector } from '../../../redux/hooks';
import { getNetWorthData } from '../../../redux/selectors';

import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';
import { NetWorthChart } from './NetWorthChart';
import { useTranslation } from 'react-i18next';
import { PAGE_URLS, NET_WORTH_FACTOR } from '../../../constants';

export const NetWorthCard: React.FC = props => {
  const chartData = useSelector(getNetWorthData(false, 3));

  const { t } = useTranslation();

  return <IonCard routerLink={PAGE_URLS.NET_WORTH}>
    <IonCardHeader>
      <IonCardTitle className="ion-text-center">
        {t("netWorthTrends")}
      </IonCardTitle>
    </IonCardHeader>
    <IonCardContent style={{ height: 238 }}>
      <NetWorthChart data={chartData} keyBy={NET_WORTH_FACTOR.netWorth} />
    </IonCardContent>
  </IonCard>
}