import React from "react";
import { IonButton, IonContent, IonFooter, IonModal, IonToolbar } from "@ionic/react";
import { ShareSettingsAccountList } from "../../shared/Link/ShareSettingsAccountList";
import { ModalHeader } from "../Header/ModalHeader";
import { useTranslation } from "react-i18next";

interface SharedSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave?: () => void;
  title: string;
}

export const SharedSettingsModal: React.FC<SharedSettingsModalProps> = (props) => {
  const { isOpen, onClose, onSave, title } = props;
  const { t } = useTranslation();

  return (
    <IonModal isOpen={isOpen} cssClass="add-property-modal" onDidDismiss={onClose}>
      <ModalHeader title={title} />
      <IonContent>
        <ShareSettingsAccountList useLastLinkedAccount={true} />
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar no-border className="ion-text-center">
          <IonButton onClick={onSave || onClose} data-testid="shareSettings_finishSharing">
            {t("save")}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
