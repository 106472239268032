import React, { useEffect, useState } from "react";
import {
  IonBackButton,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
  IonButtons,
} from "@ionic/react";

import { useTranslation } from "react-i18next";
import { PAGE_URLS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getAccountsState, getTransactionRulesState } from "../../../redux/selectors";
import { get } from "../../../utils/apiOps";
import { setLoading } from "../../../redux/actions";
import {
  RuleItem,
  RulesRes,
  RulesResponse,
  TransactionRuleTypes,
} from "../../../types/transactionRules";
import { findAccountById } from "../../../redux/reducers/accounts";
import { getTransactionRules } from "../../../redux/actions/transactionRules";
import { cloneDeep } from "lodash";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";

export const ManageRules: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { allBalances } = useSelector(getAccountsState);
  const { rulesData } = useSelector(getTransactionRulesState);

  const [typeGroups, setTypeGroups] = useState<RuleItem[][][]>([]);

  useEffect(() => {
    if (
      !allBalances ||
      !rulesData ||
      allBalances.hh?.all.accts.length +
        allBalances.user?.all.accts.length +
        allBalances.partner?.all.accts.length ===
        0
    ) {
      return;
    }

    // create a flattened rules array
    const types: string[] = [];
    const rules = cloneDeep(rulesData)
      .filter((a: RulesResponse) => a.rules && a.rules.length > 0)
      .flatMap((account: RulesResponse) => {
        return account.rules
          .filter((r) => !r.isDeleted)
          .map((r) => {
            if (r.accountId) {
              r.accountInfo = findAccountById(allBalances, r.accountId);
            }
            r.instId = (r.accountInfo && r.accountInfo.sourceInstitutionBaseId) || null;
            types.push(r.type);
            return r;
          });
      });

    // pull out the different rule types and institutions
    const distinctTypes: string[] = [];
    types.map((t) => {
      if (!distinctTypes.includes(t)) {
        distinctTypes.push(t);
      }
    });

    // const distinctTypes = [...new Set(types)];
    // create array with all the institutions for each rule type i.e, [ (cat) [Chase, BofA], (yom) [Citi] ]
    const institutions = distinctTypes.map((t) =>
      rules.filter((r: RuleItem) => r.type === t).map((r: RuleItem) => r.instId)
    );
    const distinctInst = institutions.map((institution) => {
      const distinct: any = [];
      institution.map((i: string) => {
        if (!distinct.includes(i)) {
          distinct.push(i);
        }
      });

      // const distinct = [...new Set(institution)];
      const newArr = distinct.filter((i: string) => i).sort();
      if (distinct.includes(null)) {
        newArr.unshift(null);
      }
      return newArr;
    });

    // create an array with Types (cat, yom) > Institutions (BofA, Chase) > Rules
    const typeGrouped = distinctTypes.map((t, ind) => {
      return distinctInst[ind].map((i: string) =>
        rules.filter((r: RuleItem) => r.type === t && r.instId === i && !r.isDeleted)
      );
    });

    setTypeGroups(typeGrouped);
  }, [allBalances, rulesData]);

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={<NavHeader defaultHref={PAGE_URLS.MORE_SETTINGS} title={t("settings")} />}
    >
      <Section>
        <IonList lines="none">
          {typeGroups.length > 0 ? (
            typeGroups.map((item, index) => {
              return <ManageRulesItem key={index} item={item} />;
            })
          ) : (
            <IonItem>
              <IonLabel className="ion-text-center">{t("noRules")}</IonLabel>
            </IonItem>
          )}
        </IonList>
      </Section>
    </PageTemplate>
  );
};

interface ManageRulesItemProps {
  item: RuleItem[][];
}

export const ManageRulesItem: React.FC<ManageRulesItemProps> = ({ item }) => {
  const { t } = useTranslation();
  console.debug("ManageRulesItem", item);

  return (
    <>
      <IonItemDivider>
        <IonLabel>
          {item[0][0].type === TransactionRuleTypes.YOM ? t("person") : t(item[0][0].type)}{" "}
          {t("rules")}
        </IonLabel>
      </IonItemDivider>
      {item.map((inst, ind) => {
        return (
          <IonItem
            key={ind}
            mode="ios"
            detail={true}
            routerLink={PAGE_URLS.MORE_SETTINGS_RULES_DETAIL.replace(
              ":ruleType",
              item[0][0].type
            ).replace(
              ":sourceInstitutionId",
              inst[0].accountInfo ? inst[0].accountInfo?.sourceInstitutionId : "all"
            )}
          >
            <IonLabel>
              {inst[0].accountInfo ? inst[0].accountInfo.institutionName : t("allAccounts")}
            </IonLabel>
          </IonItem>
        );
      })}
    </>
  );
};
