import React from "react"
import { IonRow, IonCol, IonIcon } from "@ionic/react";
import { checkmarkCircle, checkmarkCircleOutline } from "ionicons/icons";

import "./PasswordIndicator.scss"

type PasswordIndicatorProps = {
  text: string;
  active: boolean;
  valid: boolean;
}

export const PasswordIndicator: React.FC<PasswordIndicatorProps> = props => {
  const { text, active, valid } = props;
  return <IonRow className={`password-indicator ${valid ? 'valid' : ''} ${active ? 'active' : ''}`}>
    <IonCol>
      <IonIcon
        className="inline-logo"
        icon={active ? checkmarkCircle : checkmarkCircleOutline}
      ></IonIcon>
      <div className="inline-block font-sm">{text}</div>
    </IonCol>
  </IonRow>
}