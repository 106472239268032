import React, { useState } from "react";
import { Plugins } from "@capacitor/core";
import Banner from "@workday/canvas-kit-react-banner";
import { useTranslation } from "react-i18next";

import "./NoInternet.scss";

const { Network } = Plugins;

export const NoInternetUi: React.FC = () => {
  const { t } = useTranslation();
  const [connected, setConnected] = useState(false);
  Network.getStatus().then(({ connected }) => {
    setConnected(connected);
  });
  Network.addListener("networkStatusChange", ({ connected }) => {
    setConnected(connected);
  });
  return !connected ? (
    <div className="no-internet-ui-alert">
      <Banner actionText="" slot="center" label={t("offline")}></Banner>
    </div>
  ) : null;
};

export default NoInternetUi;
