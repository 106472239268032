import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, RouteProps } from "react-router-dom";
import { getUsersState } from "../../../redux/selectors";
import { Auth } from "aws-amplify";
import { logout, setToastMessage } from "../../../redux/actions";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { getCurrentOnboaringRoute } from "../../../utils/routeUtils";
import { onboardingFlags, PAGE_URLS, SIGNUP_URLS } from "../../../constants";

interface ProtectedRouteProps extends Partial<RouteProps> {}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { component: Component, ...rest } = props;
  const { flag } = useSelector(getUsersState);
  let finalRedirectTo: string | null = PAGE_URLS.LOGIN;
  React.useEffect(() => {
    Auth.currentSession()
      .then((userSession) => {
        console.debug("currentSession: ");
        console.debug(userSession);
        if (!userSession.isValid()) {
          dispatch(logout(false));
          dispatch(setToastMessage(t("loginRequired")));
          history.push(PAGE_URLS.LOGIN);
        } else {
          finalRedirectTo = getCurrentOnboaringRoute(flag);
        }
        if (finalRedirectTo && pathname !== finalRedirectTo) {
          if (
            flag === onboardingFlags.ADD_ACCOUNTS_P2 &&
            pathname !== PAGE_URLS.SIGNUP_LINK_ACCTS
          ) {
            // exception for this rule when P2 is linking accounts
            // history.replace(finalRedirectTo);
          }
        } else if (flag === onboardingFlags.FINISHED_ONBOARDING && SIGNUP_URLS.includes(pathname)) {
          // history.replace(PAGE_URLS.HOME);
        }
        // dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(logout(false));
        if (err === "No current user") {
          history.replace(PAGE_URLS.WELCOME);
        } else {
          dispatch(setToastMessage(t("loginRequired")));
          history.push(PAGE_URLS.LOGIN);
        }
      });
  }, []);

  return (
    <Route
      {...rest}
      //@ts-ignore
      render={(props) => <Component {...props} />}
    />
  );
};

export default ProtectedRoute;
