import React, { useState } from "react";
import numeral from "numeral";
import {
  IonButtons,
  IonHeader,
  IonIcon,
  IonModal,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { closeOutline, createOutline } from "ionicons/icons";
import { AmountInputSlider } from "./AmountInputSlider";
import { Field } from "../Field/Field";

type AmountInputProps = {
  initialValue: number;
  mostCommon: number;
  max: number;
  min: number;
  step: number;
  onChange: (updatedValue: number) => void;
  label?: string;
  format?: string;
  className?: string;
};

export const AmountInput: React.FC<AmountInputProps> = (props) => {
  const { children, initialValue, mostCommon, max, min, step, onChange, label = "", format = "$0,0", className } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const formatMultiplier = format.includes("%") ? 0.01 : 1;

  return (
    <>
      <Field
        onClick={() => setModalOpen(true)}
        className={className}
        testId="amountOpenButton"
        label={label}
        icon={createOutline}
      >
        <IonText>
          {format.includes("$") ? <strong className="ion-padding-end">$</strong> : null}
          {numeral(initialValue * formatMultiplier).format(format.includes("$") ? "0,0" : format)}
        </IonText>
      </Field>
      <IonModal isOpen={modalOpen} onDidDismiss={() => setModalOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons>
              <IonIcon
                onClick={() => setModalOpen(false)}
                icon={closeOutline}
                size="large ion-padding"
              />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {modalOpen ? (
          <AmountInputSlider
            initialValue={initialValue}
            mostCommon={mostCommon}
            max={max}
            min={min}
            step={step}
            onChange={(amount) => {
              onChange(amount);
              setModalOpen(false);
            }}
            format={format}
          >
            {children}
          </AmountInputSlider>
        ) : null}
      </IonModal>
    </>
  );
};
