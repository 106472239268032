import { combineReducers } from "redux";
import users from "./users";
import ui from "./ui";
import accounts from "./accounts";
import category from "./category";
import transactions from "./transactions";
import versionCheck from "./versioncheck";
import householdData from "./householdData";
import goals from "./goals";
import deviceData from "./deviceData";
import alertUi from "./alertUi";
import notificationPreferences from "./notificationPreferences";
import budgetAnalytics from "./budgetAnalytics";
import bills from "./bills";
import billsAudit from "./billsAudit";
import transactionRules from "./transactionRules";
import spendingChallenge from "./spendingChallenge";
import spendingForecast from "./spendingForecast";
import branchData from "./branchData";

const appReducer = combineReducers({
  users,
  ui,
  accounts,
  category,
  transactions,
  versionCheck,
  goals,
  householdData,
  deviceData,
  alertUi,
  notificationPreferences,
  budgetAnalytics,
  bills,
  billsAudit,
  transactionRules,
  spendingChallenge,
  spendingForecast,
  branchData,
});

// adapted from https://www.digitalocean.com/community/tutorials/redux-reset-state-redux
const rootReducer = (state: any, action: action) => {
  switch (action.type) {
    case "RESET_APP":
      const currBranchData = { ...state.branchData };
      state = {
        branchData: currBranchData,
      };
      break;

    case "RESET_APP_HARD":
      state = undefined;
  }
  return appReducer(state, action);
};

interface action {
  type: string;
  payload: any;
}

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
