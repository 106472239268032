import React, { useEffect } from "react";
import {
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  IonButton,
  useIonModal,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../redux/hooks";
import { firstlyLogo } from "../../../assets/images";
import constants, { PAGE_URLS } from "../../../constants";
import { FingerprintAIO } from "@ionic-native/fingerprint-aio";
import "./FingerprintAuthModal.scss";
import { getDeviceDataState } from "../../../redux/selectors";
import { DeviceType } from "../../../redux/reducers/deviceData";
import { logout } from "../../../redux/actions";
import { AppState, PluginListenerHandle, Plugins } from "@capacitor/core";

const { App } = Plugins;
let handle: PluginListenerHandle;

interface FingerprintAuthModalProps {}

export const FingerprintAuthModal: React.FC<FingerprintAuthModalProps> = () => {
  const dispatch = useDispatch();
  const deviceData: DeviceType = useSelector(getDeviceDataState);

  useEffect(() => {
    checkAndShowModal();
  }, [deviceData.fingerprintSignin]);

  const checkAndShowModal = async () => {
    FingerprintAIO.isAvailable()
      .then(() => {
        if (deviceData.fingerprintSignin) {
          present({ id: "FingerprintAuthModal" });
          handle = App.addListener("backButton", () => {});
        }
      })
      .catch((err) => console.debug(err));
  };

  const showFp = () => {
    FingerprintAIO.show({})
      .then(() => deregisterBackBtnAndDismiss())
      .catch((err) => console.debug(err));
  };

  const quit = () => {
    (dispatch(logout()) as any).then(() => {
      deregisterBackBtnAndDismiss();
    });
  };

  const deregisterBackBtnAndDismiss = () => {
    handle.remove();
    dismiss();
  };

  const [present, dismiss] = useIonModal(FingerprintAuthModalBody, {
    showFp,
    quit,
  });

  // TO DO
  // Maybe do something with unpausing the app. But the below was too active.
  // App.addListener("appStateChange", (state: AppState) => {
  //   console.log("app is resumed", state);
  //   if (state.isActive) {
  //     checkAndShowModal();
  //   }
  // });

  return null;
};

interface FingerprintAuthModalBody {
  showFp: () => void;
  quit: () => void;
}

const FingerprintAuthModalBody: React.FC<FingerprintAuthModalBody> = ({ showFp, quit }) => {
  const { t } = useTranslation();

  useEffect(() => {
    showFp();
  }, []);

  return (
    <>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-center">
              <img src={firstlyLogo} alt={constants.APP_NAME} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter className="ion-text-center ion-no-border ion-margin-bottom ion-padding-bottom">
        <IonButton
          fill="clear"
          routerLink={PAGE_URLS.LOGOUT}
          onClick={quit}
          className="ion-margin-end"
        >
          {t("signOut")}
        </IonButton>
        <IonButton fill="solid" onClick={showFp}>
          {t("unlock")}
        </IonButton>
      </IonFooter>
    </>
  );
};
