import {
  billersListApiRes,
  billsAuditDataType,
  seletectedBillerInfoType,
  userInfoType,
} from "../../types/billsAudit";
import {
  SET_BILLS_AUDIT_DATA,
  SET_BILLS_AUDIT_USERINFO,
  SET_BILLS_AUDIT_BILLERS_LIST,
  SET_BILLS_AUDIT_SELECTED_BILLER_INFO,
} from "../actionTypes";

const initialState: billsAuditState = {
  billsAuditData: null,
  userInfo: null,
  billersList: null,
  selectedBillerInfo: {
    displayName: "Scary Cable",
    estimatedSavings: 1000000,
    billsharkProviderId: null,
    offersRulesId: null,
  },
};

export interface billsAuditState {
  billsAuditData: billsAuditDataType | null;
  userInfo: userInfoType | null;
  billersList: billersListApiRes["data"] | null;
  selectedBillerInfo: seletectedBillerInfoType;
  billLogo?: string;
}

interface action {
  type: string;
  payload: billsAuditState;
}

const billsAudit = (state: billsAuditState = initialState, action: action) => {
  switch (action.type) {
    case SET_BILLS_AUDIT_DATA: {
      return {
        ...state,
        billsAuditData: action.payload.billsAuditData,
      };
    }

    case SET_BILLS_AUDIT_USERINFO: {
      return {
        ...state,
        userInfo: action.payload.userInfo,
      };
    }

    case SET_BILLS_AUDIT_BILLERS_LIST: {
      return {
        ...state,
        billersList: action.payload.billersList,
      };
    }

    case SET_BILLS_AUDIT_SELECTED_BILLER_INFO: {
      return {
        ...state,
        selectedBillerInfo: action.payload.selectedBillerInfo,
      };
    }

    default: {
      return state;
    }
  }
};

export default billsAudit;
