import React, { useState } from "react";
import {
  IonAlert,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonNote,
} from "@ionic/react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getAccountsState } from "../../../redux/selectors";
import { formatAccountNumber } from "../../../utils/formatUtils";
import { Account, AccountCollectionItem } from "../../../redux/reducers/accounts";
import { getMyAccountsData, setToastMessage } from "../../../redux/actions";
import { ManageAccountsUrlParams } from "../../../types";
import { API_STATUS_CODES, FINANCIAL_ACCOUNT_STATUS, PAGE_URLS } from "../../../constants";
import { post } from "../../../utils/apiOps";
import { NoAccountsItem } from "./ManageLinkedInstitutionsList";
import { FixAccountModal } from "../../shared/Link/AccountAlert";
import { PageTemplate, Section, NavHeader, NavButton } from "../../shared/PageTemplate";
import { alertCircleSharp } from "ionicons/icons";

export const ManageLinkedAccountsList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { sourceInstitutionId } = useParams<ManageAccountsUrlParams>();
  const { myAccounts, otherAccountCollection } = useSelector(getAccountsState);
  const allInstitutions: AccountCollectionItem[] = myAccounts.concat(otherAccountCollection || []);
  const selectedInstitution = allInstitutions.find(
    (a) => a.sourceInstitutionId === sourceInstitutionId
  );
  const [refreshedAlertIsOpen, setRefreshedAlertIsOpen] = useState<boolean>(false);
  const [showFixModal, setShowFixModal] = useState(false);

  // TO DO - Add alerts

  const refreshAccount = async () => {
    const data = await post(true, {
      endpoint: "/accounts/refresh",
      bodyData: {
        accountId: selectedInstitution?.subBanks[0]._id,
      },
    });
    if (data.status === API_STATUS_CODES.OK) {
      setRefreshedAlertIsOpen(true);
    } else {
      dispatch(setToastMessage(t("accountRefreshed")));
      dispatch(getMyAccountsData());
    }
  };

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={
        <NavHeader
          defaultHref={PAGE_URLS.MORE_MANAGE_ACCTS}
          title={selectedInstitution?.bank || t("accounts")}
        >
          {selectedInstitution?.isAlert &&
            selectedInstitution.subBanks.some((acct) => acct.isActive) && (
              <>
                <NavButton
                  icon={<IonIcon icon={alertCircleSharp} />}
                  label={t("alert")}
                  color="danger"
                  onClick={() => setShowFixModal(true)}
                />
                <FixAccountModal
                  isOpen={showFixModal}
                  onClose={() => setShowFixModal(false)}
                  sourceInstitutionId={selectedInstitution.sourceInstitutionId}
                />
              </>
            )}
        </NavHeader>
      }
    >
      <IonList lines="none">
        <Section>
          <IonItemDivider>
            <IonLabel>{t("accounts")}</IonLabel>
          </IonItemDivider>
          {selectedInstitution?.subBanks && selectedInstitution.subBanks.length > 0 ? (
            selectedInstitution.subBanks.map((account: any) => {
              return <ManageLinkedAccountItem key={account._id} account={account} />;
            })
          ) : (
            <NoAccountsItem />
          )}
        </Section>
        <Section>
          <IonItemDivider>
            <IonLabel>{t("actions")}</IonLabel>
          </IonItemDivider>
          {selectedInstitution ? (
            <>
              <IonItem detail onClick={refreshAccount} className="font-md">
                <IonLabel>
                  {t("refresh")} ({t("updated")}{" "}
                  {dayjs(selectedInstitution?.lastUpdated).format("MMMM DD")})
                </IonLabel>
              </IonItem>
              <IonItem
                detail
                routerLink={PAGE_URLS.MORE_ACCTS_INSTITUTION_STATUS.replace(
                  ":sourceInstitutionId",
                  sourceInstitutionId
                )}
                className="font-md"
              >
                <IonLabel>{t("insStatus")}</IonLabel>
              </IonItem>
            </>
          ) : null}
          {selectedInstitution?.subBanks.every((account) => account.isOwner) && (
            <IonItem
              detail
              routerLink={PAGE_URLS.MORE_ACCTS_INSTITUTION_UNLINK.replace(
                ":sourceInstitutionId",
                sourceInstitutionId
              )}
              data-testid="unlink_bank"
              className="font-md"
            >
              <IonLabel>{t("delete")}</IonLabel>
            </IonItem>
          )}
        </Section>
      </IonList>
      <IonAlert
        onDidDismiss={() => setRefreshedAlertIsOpen(false)}
        isOpen={refreshedAlertIsOpen}
        header={t("alreadyRefreshed_title")}
        message={
          t("alreadyRefresh_msg1") +
          "<br /><br />" +
          t("alreadyRefresh_msg2") +
          t("alreadyRefresh_msg3")
        }
        buttons={[t("gotIt")]}
      ></IonAlert>
    </PageTemplate>
  );
};

interface ManageLinkedAccountItemProps {
  account: Account;
}
const ManageLinkedAccountItem: React.FC<ManageLinkedAccountItemProps> = ({ account }) => {
  const { t } = useTranslation();
  return (
    <IonItem
      routerLink={`${PAGE_URLS.MORE_MANAGE_ACCTS}/${account.sourceInstitutionId}/${account._id}`}
      data-testid="ManageLinkedAccountItem"
      className="font-md"
    >
      <IonLabel>
        {account.accountNickname || account.accountName}{" "}
        {formatAccountNumber(account.accountNumber)}
      </IonLabel>
      {account.accountStatus === FINANCIAL_ACCOUNT_STATUS.CLOSED && (
        <IonNote slot="end">({t("closed")})</IonNote>
      )}
    </IonItem>
  );
};
