import { IonIcon } from '@ionic/react';
import { cameraSharp } from 'ionicons/icons';
import React from 'react';
import { Plugins, CameraResultType, CameraDirection } from "@capacitor/core";

import { useDispatch } from '../../../../redux/hooks';
import { updateProfilePicture } from '../../../../redux/actions';
import { isPlatform } from '@ionic/core';
import { setToastMessage } from '../../../../redux/actions/ui';
const { Camera } = Plugins;

export const CameraIcon: React.FC = props => {
  const dispatch = useDispatch();
  const handlePhoto = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 40,
        height: 250,
        width: 250,
        correctOrientation: true,
        preserveAspectRatio: true,
        saveToGallery: false,
        allowEditing: isPlatform("ios"),
        direction: CameraDirection.Rear,
        resultType: CameraResultType.Base64,
      })
      dispatch(updateProfilePicture(image))
    } catch (error) {
      error?.message && dispatch(setToastMessage(error.message))
    }
  }
  return <IonIcon icon={cameraSharp} onClick={handlePhoto} className="sub-icon" size="small" />
}