import React, { useState } from "react";
import {
  IonButton,
  IonFooter,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonNote,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useUserName } from "../../shared/User/useUserName";
import { useSelector } from "react-redux";
import { getAccountsState } from "../../../redux/selectors";
import { AccountLogo } from "../AccountsTab/AccountLogo";
import { FINANCIAL_ACCOUNT_STATUS, PAGE_URLS } from "../../../constants";
import { LinkAndShareAccounts } from "../../shared/Link/LinkAndShareAccounts";
import {
  AccountCollectionItem,
  AccountSource,
  accountState,
} from "../../../redux/reducers/accounts";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";

export const ManageLinkedInstitutionsList: React.FC = () => {
  const { t } = useTranslation();
  const { partnerName, singlePlayerMode } = useUserName();
  const { myAccounts, otherAccountCollection, loading }: accountState =
    useSelector(getAccountsState);

  const [addAccountModalOpen, setAddAccountModalOpen] = useState<boolean>(false);

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={<NavHeader defaultHref={PAGE_URLS.MORE_HOME} title={t("linkedAccountsTitle")} />}
      footer={
        <IonFooter className="ion-text-center ion-padding fy-section-card">
          <IonButton
            onClick={() => setAddAccountModalOpen(true)}
            data-testid="ManageLinkedInstitutionsList_addAccount"
          >
            {t("addAccount")}
          </IonButton>
        </IonFooter>
      }
    >
      {loading === false && (
        <IonList lines="none">
          <AccountsList label={t("accounts")} accountsArray={myAccounts} />
          {!singlePlayerMode && (
            <AccountsList
              label={t("partnersAccounts", { partnerName })}
              accountsArray={otherAccountCollection}
            />
          )}
        </IonList>
      )}
      <LinkAndShareAccounts
        isOpen={addAccountModalOpen}
        onSuccess={() => setAddAccountModalOpen(false)}
      />
    </PageTemplate>
  );
};

interface ManageLinkedInstitutionsItemProps {
  account: AccountCollectionItem;
}
export const ManageLinkedInstitutionsItem: React.FC<ManageLinkedInstitutionsItemProps> = ({
  account,
}) => {
  const { t } = useTranslation();

  return (
    <IonItem
      data-testid="ManageLinkedInstitutionsItem"
      detail
      routerLink={`${PAGE_URLS.MORE_MANAGE_ACCTS}/${account.sourceInstitutionId}`}
    >
      <AccountLogo account={account as any} />
      <IonLabel>
        <h3 className="font-md">{account.bank}</h3>
        <h4 className="font-sm">
          {t("updated")} {dayjs(account.lastUpdated).format("MMMM DD")}
        </h4>
      </IonLabel>
      {account.subBanks.every((acct) => acct.accountStatus === FINANCIAL_ACCOUNT_STATUS.CLOSED) ? (
        <IonNote slot="end">({t("closed")})</IonNote>
      ) : (
        account.isAlert && (
          <IonNote slot="end" color="danger">
            {t("alert")}
          </IonNote>
        )
      )}
    </IonItem>
  );
};

interface AccountsListProps {
  label: string;
  accountsArray: AccountCollectionItem[];
}

export const AccountsList: React.FC<AccountsListProps> = ({ label, accountsArray }) => {
  return (
    <Section>
      <IonItemDivider className="ion-margin-top">
        <IonLabel>{label}</IonLabel>
      </IonItemDivider>
      {accountsArray?.length > 0 ? (
        accountsArray
          .filter((acct) => acct.source !== AccountSource.COREPRO)
          .map((account, i) => <ManageLinkedInstitutionsItem key={i} account={account} />)
      ) : (
        <NoAccountsItem />
      )}
    </Section>
  );
};

export const NoAccountsItem: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonItem>
      <IonLabel>{t("noAccounts")}</IonLabel>
    </IonItem>
  );
};
