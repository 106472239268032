import React, { useEffect, useState } from "react";
import { IonButtons, IonCol, IonGrid, IonItem, IonList, IonRow } from "@ionic/react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ellipsisHorizontal } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryState } from "../../../redux/selectors";
import {
  BudgetItem,
  BudgetSuperCategory,
  BudgetURLParams,
  Category,
  SubCategory,
} from "../../../types";
import { BUDGET_SHARED_TYPE, BUDGET_SUPER_CATEGORIES, PAGE_URLS } from "../../../constants";
import { getBudgetAnalyticsState } from "../../../redux/selectors";
import dayjs from "dayjs";
import "./BudgetDetailItem.scss";
import { BudgetDetailItem } from "./BudgetDetailItem";
import { ShareSettingsButtonAndPopover } from "../../shared/User/ShareSettingsButtonAndPopover";
import { getBudget, setLoading } from "../../../redux/actions";
import { BudgetSubcategoryDetailItem } from "./BudgetSubcategoryDetailItem";
import { BudgetMonthHeader } from "./BudgetMonthHeader";
import { AddBudgetModal } from "./AddBudgetModal";
import "./AddBudgetModal.scss";
import { EditCategoriesModal } from "./EditCategoriesModal";
import { ActionSheet, NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { getCategoryObject } from "../../../utils/categorizationUtils";
import { trackEvent } from "../../../vendors/monitoring";
import { EditCategoriesButton } from "./EditCategoriesButton";

export const BudgetSubcategoryDetail: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    effectiveDate,
    category,
    budgetType = BUDGET_SHARED_TYPE.HOUSEHOLD,
  } = useParams<BudgetURLParams>();
  const { budgetsArray, loading } = useSelector(getBudgetAnalyticsState);
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [selectedCategorySubcats, setSelectedCategorySubcats] = useState<Array<SubCategory>>([]);
  const [showCategoryEvent, setShowCategoryEvent] = useState<Event>();
  const [showAddBudgetModal, setShowAddBudgetModal] = useState<boolean>(false);
  const [showEditCategoriesModal, setShowEditCategoriesModal] = useState<boolean>(false);

  const { categoryData, subCategoryData } = useSelector(getCategoryState);

  // this view is only for Expense subcategories, so hardcoding the superCategory to expenses
  const superCategory: BudgetSuperCategory = BUDGET_SUPER_CATEGORIES.EXPENSES;
  const defaultBackHref = PAGE_URLS.BUDGET_HOME.replace(
    ":effectiveDate",
    dayjs(effectiveDate).format("YYYY-MM-DD")
  ).replace(":budgetType", BUDGET_SHARED_TYPE.HOUSEHOLD);

  useEffect(() => {
    dispatch(setLoading(loading));
  }, [loading]);

  useEffect(() => {
    setSelectedCategory(getCategoryObject(category, categoryData));
  }, [category, categoryData]);

  useEffect(() => {
    setSelectedCategorySubcats(
      subCategoryData.filter(
        (s: SubCategory) => !s.isDeactivated && !s.isDeleted && s.category === category
      )
    );
  }, [category, subCategoryData]);

  useEffect(() => {
    const budgetAnalyticsForThisDate = budgetsArray.find(
      (item: BudgetItem) => item.budgetAnalyticsDate === effectiveDate
    );
    if (!budgetAnalyticsForThisDate) {
      dispatch(getBudget({ effectiveDate: dayjs(effectiveDate).format("YYYY-MM-DD") }));
    }
  }, [budgetsArray, effectiveDate]);

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={
        <NavHeader
          defaultHref={defaultBackHref}
          desktopTitle={t("budget")}
          subheader="useTitle"
          title={
            <BudgetMonthHeader
              effectiveDate={effectiveDate}
              category={category}
              baseUrl={PAGE_URLS.BUDGET_DETAILS}
              budgetType={budgetType}
            />
          }
        >
          <ShareSettingsButtonAndPopover
            budgetType={budgetType}
            baseUrl={PAGE_URLS.BUDGET_DETAILS}
            effectiveDate={effectiveDate}
            category={selectedCategory?.categoryShortName}
          />
        </NavHeader>
      }
    >
      <Section>
        <IonGrid>
          <IonRow>
            <IonCol>
              {selectedCategory && (
                <>
                  <IonItem
                    className="BudgetDetailItem ion-margin-top"
                    lines="none"
                    button
                    onClick={(e) => {
                      e.persist();
                      setShowCategoryEvent(e as any);
                    }}
                    detail={false}
                  >
                    <BudgetDetailItem
                      category={selectedCategory}
                      effectiveDate={effectiveDate}
                      budgetType={budgetType}
                      superCategory={superCategory}
                      buttonIcon={ellipsisHorizontal}
                    />
                  </IonItem>
                  <AddBudgetModal
                    isOpen={showAddBudgetModal}
                    onClose={() => setShowAddBudgetModal(false)}
                    category={selectedCategory}
                    effectiveDate={effectiveDate}
                    budgetType={budgetType}
                    superCategory={superCategory}
                  />
                </>
              )}
              <ActionSheet
                hideButton
                openEvent={showCategoryEvent}
                onDidDismiss={setShowCategoryEvent as () => void}
                header={t("budget")}
                buttons={[
                  {
                    text: `${t("add")} ${selectedCategory?.categoryFullName} ${t("budget")}`,
                    handler: () => setShowAddBudgetModal(true),
                  },
                  {
                    text: t("editCategories"),
                    handler: () => {
                      setShowEditCategoriesModal(true);
                      trackEvent("view_editCategories", { category: "editCategories" });
                    },
                  },
                  {
                    text: t("cancel"),
                    role: "cancel",
                  },
                ]}
              />
              <EditCategoriesModal
                isOpen={showEditCategoriesModal}
                onClose={() => setShowEditCategoriesModal(false)}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </Section>
      <Section>
        <IonGrid>
          <IonRow>
            <IonCol>
              <h1 className="font-title-3">{t("budgets")}</h1>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonList id="BudgetSubcategoryDetailList">
          {selectedCategorySubcats.map((subcategory: SubCategory, i: number) => {
            return selectedCategory?.superCategory === superCategory ? (
              <BudgetSubcategoryDetailItem
                effectiveDate={effectiveDate}
                budgetType={budgetType}
                category={selectedCategory}
                subcategory={subcategory}
                superCategory={superCategory}
                key={i}
              />
            ) : null;
          })}
        </IonList>
        <IonButtons className="flex-center ion-margin">
          <EditCategoriesButton onClick={() => {
            setShowEditCategoriesModal(true);
            trackEvent("view_editCategories", { category: "editCategories" });
          }} />
        </IonButtons>
      </Section>
    </PageTemplate>
  );
};
