import React from 'react'
import { IonProgressBar } from '@ionic/react';

import { SIGNUP_URLS } from '../../../constants';

type OnboardingProgressProps = {
  step: number;
}

export const OnboardingProgress: React.FC<OnboardingProgressProps> = props => {
  const { step } = props;

  return <IonProgressBar color="tertiary" value={step / SIGNUP_URLS.length} />
}