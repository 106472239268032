import React from "react";
import { IonList } from "@ionic/react";
import { trackEvent } from "../../../vendors/monitoring";
import "./onboarding.scss";
import Template, { OnboardingCopy } from "./Template";
import { useSelector } from "react-redux";
import { getAccountsState } from "../../../redux/selectors";
import { LinkedAccountItem } from "./LinkAccountsListModal";
import { PAGE_URLS } from "../../../constants";
import { OnboardingNavHeader } from "./OnboardingNavHeader";
import { OnboardingProgress } from "./OnboardingProgress";
import { useUserName } from "../../shared/User/useUserName";
import { useTranslation } from "react-i18next";

const pageLocation = "partners-linked-accounts";

export const PartnersLinkedAccounts: React.FC = () => {
  const { partnerName } = useUserName();
  const { t } = useTranslation();
  const { otherAccountCollection } = useSelector(getAccountsState);

  return (
    <Template
      header={<OnboardingNavHeader desktopTitle={<OnboardingProgress step={5} />} />}
      handlePrimary={() => trackEvent("Next partner-linked-accounts")}
      primaryRouterLink={PAGE_URLS.SIGNUP_LINK_ACCTS}
      primaryProps={{ routerDirection: "root" }}
      primaryTestId="partnersAccountsNext"
      primaryCopy="gotIt"
      onboardingStep={5}
    >
      <OnboardingCopy title={t("partnersLinkedAccounts_title", { partnerName })} />

      <IonList lines="none" className="bounded">
        {otherAccountCollection &&
          otherAccountCollection.length > 0 &&
          otherAccountCollection.map((bank: any) => (
            <LinkedAccountItem key={bank.sourceInstitutionId} bank={bank}></LinkedAccountItem>
          ))}
      </IonList>
    </Template>
  );
};
