import React from "react";
import PlaidLinkWrapper from "./PlaidLinkWrapper";
// TO DO - Create a direct bank link wrapper for cap one
import BankLinkWrapper from "./BankLinkWrapper";
import { AccountSource } from "../../../redux/reducers/accounts";
import { IonButton, IonItem, IonLabel, IonThumbnail, useIonAlert } from "@ionic/react";
import { AccountLogo } from "../../pages/AccountsTab/AccountLogo";
import bankLogos from "../../../assets/images/bank_logos";
import { trackEvent } from "../../../vendors/monitoring";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getAccountsState } from "../../../redux/selectors";
import { accountState } from "../../../redux/reducers/accounts";
import { FINANCIAL_ACCOUNT_STATUS } from "../../../constants";
export enum BankLinkFormat {
  QUICKBANK = "quickbank",
  SEARCH_ITEM = "search_item",
  BUTTON = "button",
  NONE = "none",
}
export interface InstitutionSearchResp {
  id: string;
  name: string;
  source: AccountSource;
  bankTokenId?: string;
  url?: string;
  len?: number;
  logo?: null | string;
}

interface LinkInstitutionWrapperProps {
  format?: BankLinkFormat;
  institution: InstitutionSearchResp;
  onClose?: (accountWasLinked?: boolean) => void;
  buttonCopy?: string;
  publicToken?: string;
  fromOnboarding?: boolean;
}

const LinkInstitutionWrapper: React.FC<LinkInstitutionWrapperProps> = ({
  institution,
  format = BankLinkFormat.NONE,
  onClose,
  buttonCopy,
  publicToken,
  fromOnboarding,
}) => {
  const renderSwitch = (source: string) => {
    switch (source) {
      case AccountSource.BANK_CAPONE:
      case AccountSource.BANK_COINBASE:
        return (
          <BankLinkWrapper institution={institution} format={format} onClose={onClose}>
            <LinkInstitutionItem
              institution={institution}
              format={format}
              buttonCopy={buttonCopy}
            />
          </BankLinkWrapper>
        );
      case AccountSource.PLAID:
      default:
        return (
          <PlaidLinkWrapper
            institution={institution}
            format={format}
            onClose={onClose}
            publicToken={publicToken}
            fromOnboarding={fromOnboarding}
          >
            <LinkInstitutionItem
              institution={institution}
              format={format}
              buttonCopy={buttonCopy}
            />
          </PlaidLinkWrapper>
        );
    }
  };

  const instSource = renderSwitch(institution.source);

  return instSource;
};

const LinkInstitutionItem: React.FC<LinkInstitutionWrapperProps> = ({
  institution,
  format = BankLinkFormat.NONE,
  buttonCopy,
}) => {
  const { myAccounts }: accountState = useSelector(getAccountsState);
  let isDuplicate = myAccounts.some(
    (acc) =>
      acc.sourceInstitutionBaseId === institution.id &&
      acc.accountStatus !== FINANCIAL_ACCOUNT_STATUS.CLOSED
  );
  const { t } = useTranslation();
  const [present, dismiss] = useIonAlert();
  const instImage: any = document.getElementsByClassName(
    `inst-button-${_.kebabCase(institution.name)}`
  )[0];
  const alertUser = async (e: any) => {
    if (isDuplicate) {
      e.stopPropagation();
      // dismisses all other alerts (if any)
      await dismiss();
      present({
        header: t("linkedDuplicateAccountTitle"),
        message: t("linkedDuplicateAccountMsg", { institution: institution.name }),
        buttons: [
          {
            text: t("cancel"),
          },
          {
            text: t("yes"),
            handler: () => {
              // switches value of isDuplicate to false to make it clickable
              // switches it back to true after click event, so that user can
              // get the alert again if they come back to the bank list by
              // closing the Institution source modal
              isDuplicate = false;
              instImage.click();
              isDuplicate = true;
            },
          },
        ],
      });
    }
  };

  const renderSwitch = (format: BankLinkFormat) => {
    switch (format) {
      case BankLinkFormat.QUICKBANK:
        return (
          <img
            src={bankLogos[`${institution.id}`]}
            alt={institution.name}
            data-testid={`linkBank_${institution.id}`}
            className={`quickbank-logo inst-button-${_.kebabCase(institution.name)}`}
            onClick={alertUser}
          />
        );
      case BankLinkFormat.SEARCH_ITEM:
        return (
          <IonItem
            onClick={(e: any) => {
              trackEvent("searchBank", { category: "balances" });
              alertUser(e);
            }}
            lines="full"
            className={`inst-button-${_.kebabCase(institution.name)}`}
            key={institution.id}
            data-testid={`linkBank_${institution.id}`}
          >
            <IonThumbnail slot="start">
              <AccountLogo account={institution} />
            </IonThumbnail>
            <IonLabel>{institution.name}</IonLabel>
          </IonItem>
        );
      case BankLinkFormat.BUTTON:
        return <IonButton expand="full">{buttonCopy}</IonButton>;
      default:
        return null;
    }
  };

  return renderSwitch(format);
};

export default LinkInstitutionWrapper;
