import React, { useEffect, useState } from "react";
import {
  IonModal,
  IonContent,
  IonSegmentButton,
  IonLabel,
  IonSegment,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonToggle,
  IonButton,
  IonFooter,
  IonToolbar,
  IonAlert,
  IonButtons,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import constants, {
  ACCOUNT_OWNER_TYPE,
  AVATAR_DISPLAY,
  PRIVILEGE_TYPE_DATA_2,
  ACCOUNT_PRIMARY_TYPE,
} from "../../../constants";
import { getAccountSettingType, updateShareSettings } from "../../../utils/financialAccounts";
import { useDispatch } from "react-redux";
import { getMyAccountsData } from "../../../redux/actions";
import Avatars from "../Avatar/Avatars";
import { useUserName } from "../User/useUserName";
import { ModalHeader } from "../Header/ModalHeader";
import { useViewport } from "../../../hooks/useViewport";

type SetSharedModalProps = {
  isOpen: boolean;
  onClose: () => void;
  account: any;
  allowClose?: boolean;
  redirectUrl?: string;
  handleClose?: () => void;
  showJointIndividual?: boolean;
};

export const SetSharedModal: React.FC<SetSharedModalProps> = ({
  account,
  isOpen,
  onClose,
  allowClose = false,
  handleClose,
  redirectUrl,
  showJointIndividual = false,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useViewport();
  const [primaryType, setPrimaryType] = useState<string>("");
  const [ownershipType, setOwnershipType] = useState<string>(ACCOUNT_OWNER_TYPE.INDIVIDUAL);
  const [showTransactions, setShowTransactions] = useState<boolean>(false);
  const [showBalances, setShowBalances] = useState<boolean>(false);
  const [showIgnoreAlert, setShowIgnoreAlert] = useState<boolean>(false);
  const [showCantToggleAlert, setShowCantToggleAlert] = useState<boolean>(false);
  const formGood = Boolean(primaryType);
  const dispatch = useDispatch();
  const { partnerName } = useUserName();

  useEffect(() => {
    if (allowClose) {
      setOwnershipType(account?.hhInfo[0].accountOwnershipType);
      const setting = getAccountSettingType(account?.hhInfo[0].privilegeType);
      console.debug(account?.hhInfo[0], setting);
      setPrimaryType(setting.primary);
      setShowBalances(account?.hhInfo[0].balanceShared);
      setShowTransactions(setting.shareTrans);
    }
  }, [account, allowClose]);

  const handlePrimaryType = ({ detail }: any) => {
    const { value } = detail;
    setPrimaryType(value);
    if (value === ACCOUNT_PRIMARY_TYPE.HOUSEHOLD) {
      setShowTransactions(true);
      setShowBalances(true);
    }
  };

  const handleIgnoreConfirm = async () => {
    await updateShareSettings({
      accountIds: [account._id],
      accountStatus: PRIVILEGE_TYPE_DATA_2.IGNORE,
      ownership: ACCOUNT_OWNER_TYPE.INDIVIDUAL,
      balVisibility: false,
    });
    dispatch(getMyAccountsData());
    onClose();
    // window.location.reload();
  };

  const handleToggleClick = () => {
    if (primaryType === ACCOUNT_PRIMARY_TYPE.HOUSEHOLD) {
      setShowCantToggleAlert(true);
    }
  };

  const handleSave = async () => {
    let accountStatus = PRIVILEGE_TYPE_DATA_2.SHARED;
    if (primaryType === ACCOUNT_PRIMARY_TYPE.PERSONAL) {
      if (showTransactions) {
        accountStatus = PRIVILEGE_TYPE_DATA_2.VISIBLE;
      } else {
        accountStatus = PRIVILEGE_TYPE_DATA_2.JUSTME;
      }
    }
    await updateShareSettings({
      accountIds: [account._id],
      accountStatus,
      ownership: ownershipType,
      balVisibility: showBalances,
    });
    dispatch(getMyAccountsData());
    onClose();
    // window.location.reload();
  };

  const handleOwnership = ({ detail }: any) => {
    const { value } = detail;
    setOwnershipType(value);
    if (value === ACCOUNT_OWNER_TYPE.JOINT) {
      setPrimaryType(ACCOUNT_PRIMARY_TYPE.HOUSEHOLD);
      setShowTransactions(true);
      setShowBalances(true);
    }
  };

  return (
    <IonModal isOpen={isOpen} cssClass="link-search-modal" onDidDismiss={onClose}>
      <ModalHeader
        startBtnIcon={allowClose ? closeOutline : undefined}
        startBtnOnClick={onClose}
        title={`${account.accountName} ${account.accountNumber}`}
        titleProps={
          isDesktop ? { className: "ion-no-padding ion-margin-start ion-padding-start" } : {}
        }
      />
      <IonContent>
        <IonGrid className="ion-padding">
          {(allowClose || showJointIndividual) && (
            <IonRow>
              <IonCol className="ion-padding-start">
                <h5 className="ion-text-left font-md">{t("ownership")}:</h5>
                <br />
                <IonSegment
                  data-testid="account-ownership"
                  value={ownershipType}
                  mode="ios"
                  onIonChange={(e) => handleOwnership(e)}
                >
                  <IonSegmentButton
                    value={ACCOUNT_OWNER_TYPE.JOINT}
                    data-testid="share-set-ownership"
                  >
                    <IonLabel>{t`joint`}</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value={ACCOUNT_OWNER_TYPE.INDIVIDUAL}
                    data-testid="share-set-individual"
                  >
                    <IonLabel>{t`individual`}</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol className="ion-padding-start">
              <h5 className="font-md">{t("purchasesOnAccount")}</h5>
              <br />
              <IonSegment
                data-testid="account-primary-type"
                disabled={ownershipType === ACCOUNT_OWNER_TYPE.JOINT}
                value={primaryType}
                mode="ios"
                onIonChange={(e) => handlePrimaryType(e)}
              >
                <IonSegmentButton
                  value={ACCOUNT_PRIMARY_TYPE.HOUSEHOLD}
                  data-testid="share-set-household"
                >
                  <IonLabel>{t`household`}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value={ACCOUNT_PRIMARY_TYPE.PERSONAL}
                  data-testid="share-set-personal"
                >
                  <IonLabel>{t`personal`}</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-padding-start">
              <h5 className="font-md">
                {t("allow")} {partnerName} {t("toSee")}
              </h5>
              <br />
              <IonList lines="none" onClick={handleToggleClick}>
                <IonItem className="ion-no-padding">
                  <IonLabel className="font-sm">{t("transactions")}</IonLabel>
                  <IonToggle
                    checked={showTransactions}
                    onIonChange={(e) => setShowTransactions(e.detail.checked)}
                    disabled={
                      ownershipType === ACCOUNT_OWNER_TYPE.JOINT ||
                      primaryType === ACCOUNT_PRIMARY_TYPE.HOUSEHOLD
                    }
                    data-testid="share-set-transactions"
                  />
                </IonItem>
                <IonItem className="ion-no-padding">
                  <IonLabel className="font-sm">{t("balance")}</IonLabel>
                  <IonToggle
                    checked={showBalances}
                    onIonChange={(e) => setShowBalances(e.detail.checked)}
                    disabled={
                      ownershipType === ACCOUNT_OWNER_TYPE.JOINT ||
                      primaryType === ACCOUNT_PRIMARY_TYPE.HOUSEHOLD
                    }
                    data-testid="share-set-balances"
                  />
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
          <br />
          {primaryType && (
            <>
              <IonRow>
                <IonCol className="ion-padding-start">
                  <h5 className="font-md">{t("transTagged")}</h5>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="2.5" className="ion-padding-start">
                  <Avatars
                    alignment="start"
                    avatarType={
                      primaryType === ACCOUNT_PRIMARY_TYPE.HOUSEHOLD
                        ? AVATAR_DISPLAY.SHARED
                        : showTransactions
                        ? AVATAR_DISPLAY.ME
                        : AVATAR_DISPLAY.PRIVATE
                    }
                  />
                </IonCol>
                <IonCol size="9.5" className="font-sm  ion-padding-top">
                  {showTransactions
                    ? primaryType === ACCOUNT_PRIMARY_TYPE.HOUSEHOLD
                      ? t("youAndPartner", { partnerName })
                      : t("you")
                    : t("justYou")}
                </IonCol>
              </IonRow>
            </>
          )}
        </IonGrid>
      </IonContent>
      <IonFooter className="flex-center">
        <IonButton
          onClick={() => setShowIgnoreAlert(true)}
          fill="clear"
          size="large"
          color="primary"
          data-testid="share-set-ignore"
          className="ion-margin"
        >
          {t("ignoreAccount")}
        </IonButton>
        <IonButton
          color="primary"
          fill="solid"
          size="large"
          disabled={!formGood}
          onClick={handleSave}
          data-testid="share-set-save"
          className="ion-margin"
        >
          {t("save")}
        </IonButton>
      </IonFooter>
      <IonAlert
        isOpen={showIgnoreAlert}
        onDidDismiss={() => setShowIgnoreAlert(false)}
        header={t("alertSureTitle")}
        message={t("ignoreAlertMsg") + constants.APP_NAME + "?"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "Okay",
            handler: handleIgnoreConfirm,
            cssClass: "alert-confirm-btn",
          },
        ]}
      />
      <IonAlert
        isOpen={showCantToggleAlert}
        onDidDismiss={() => setShowCantToggleAlert(false)}
        header={t("cantToggleTitle")}
        message={t("cantToggleMsg")}
        buttons={["OK"]}
      />
    </IonModal>
  );
};
