import React from "react";
import { useSelector } from "react-redux";
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonButton } from "@ionic/react";
import { PAGE_URLS } from "../../../constants";
import { getUsersState } from "../../../redux/selectors";
import { getCurrentOnboaringRoute } from "../../../utils/routeUtils";
import { useTranslation } from "react-i18next";

const FallbackComponent: React.FC = () => {
  const { t } = useTranslation();
  const { flag } = useSelector(getUsersState);
  const fallbackPage = getCurrentOnboaringRoute(flag) || PAGE_URLS.HOME;
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{t("ohNo")}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>{t("fallbackMsg")}</IonCardContent>
      <IonButton routerLink={fallbackPage}>{t("go")}</IonButton>
    </IonCard>
  );
};

export default FallbackComponent;