import { useSelector } from "react-redux";
import { getHouseholdDataState, getUsersState } from "../../../redux/selectors";
import { useTranslation } from "react-i18next";
export function useUserName() {
  const { t } = useTranslation();
  const { userName, hasPartner, singlePlayerMode } = useSelector(getUsersState);
  const { primaryPartner, members } = useSelector(getHouseholdDataState);
  // assigns default value to partner invite array, which is undefined on first render.
  const singleMember = members[0] || {};
  const invites = singleMember.invites || [];
  const partnerInvite = invites[0] || {};
  const notSet = t("profileNotSet");
  const partner = t("partner");

  if (!primaryPartner) {
    return {
      userName,
      partnerName: partnerInvite?.firstName || partner,
      partnerEmail: partnerInvite?.emailId || notSet,
      inviteCode: partnerInvite?.inviteCode || "",
      partnerId: partnerInvite?._id || "",
      partnerMobile: notSet,
      partnerGender: notSet,
      hasPartner,
      singlePlayerMode,
    };
  }
  return {
    userName,
    partnerName: primaryPartner?.firstName || partner,
    partnerEmail: primaryPartner?.emailId || notSet,
    partnerMobile: primaryPartner?.mobile || notSet,
    partnerGender: primaryPartner?.gender || notSet,
    hasPartner,
    singlePlayerMode,
  };
}
