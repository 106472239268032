import React, { useState } from "react";
import { useUserName } from "../../../components/shared/User/useUserName";
import { useDispatch, useSelector } from "../../../redux/hooks";
import { getUsersState, getHouseholdDataState } from "../../../redux/selectors";
import { setToastMessage } from "../../../redux/actions";
import { validateEmail, validateMobile } from "../../../utils/formUtils";
import { chevronBackOutline } from "ionicons/icons";
import { mailOutline } from "ionicons/icons";
import {
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonToolbar,
  IonButton,
  IonAlert,
  IonIcon,
  IonFooter,
  IonText,
  IonBackButton,
  useIonAlert,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Household } from "./ProfileScreens/Household";
import { Partner } from "./ProfileScreens/Partner";
import { Me } from "./ProfileScreens/Me";
import { editHouseholdProfile, editUser } from "../../../redux/actions";
import { PAGE_URLS } from "../../../constants";
import { useNavigation } from "../../../utils/useNavigation";
import "./ProfilePage.scss";
import "./ProfileScreens/SharedFinances.scss";
import { userState } from "../../../redux/reducers/users";
import { HouseholdProfile } from "../../../types";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { useViewport } from "../../../hooks/useViewport";
import { usePartnerInvite } from "../../shared/Partner/usePartnerInvite";
import { setMonitoringUser } from "../../../vendors/monitoring";
import { RouteComponentProps } from "react-router";

enum tabType {
  HOUSEHOLD = "hh",
  USER = "user",
  PARTNER = "partner",
}

export const ProfilePage: React.FC<RouteComponentProps> = ({ location }) => {
  const { t } = useTranslation();
  const { goBack } = useNavigation();
  const { isDesktop } = useViewport();
  const { partnerName, singlePlayerMode, inviteCode } = useUserName();
  const user = useSelector(getUsersState);
  const hh = useSelector(getHouseholdDataState);
  const dispatch = useDispatch();
  const [tab, setTab] = useState<tabType>(() => singlePlayerMode || location.hash.includes(tabType.USER) ? tabType.USER : tabType.HOUSEHOLD);
  const [householdData, setHouseholdData] = useState(() => hh?.hhProfiles[0] || {});
  const [userData, setUserData] = useState(() => user || {});
  const [isOn, setIsOn] = useState(false);
  const [touched, setTouched] = useState(false);
  const invite = usePartnerInvite("reminder", null);

  // handles field updates
  const handleUpdate = (slice: Object) => {
    // gets field and value to compare and determine if form has been changed
    const field = Object.keys(slice)[0];
    const value: string = Object.values(slice)[0];
    let isChanged = false;
    if (tab === "user") {
      if (user[field as keyof userState] !== value) {
        isChanged = true;
      } else {
        isChanged = false;
      }
      setUserData({ ...userData, ...slice });
    }

    if (tab === "hh") {
      if (hh?.hhProfiles[0][field as keyof HouseholdProfile] !== value) {
        isChanged = true;
      } else {
        isChanged = false;
      }
      setHouseholdData({ ...householdData, ...slice });
    }
    setTouched(isChanged);
  };

  // handles submission of household or user changes
  const handleSubmit = async (e: any): Promise<any> => {
    if (e) {
      e.preventDefault();
    }
    let errorMessage = null;
    // single user submit actions

    if (!userData.firstName || userData.firstName === "") {
      errorMessage = t("invalidFirstName");
    } else if (!userData.lastName || userData.lastName === "") {
      errorMessage = t("invalidLastName");
    } else if (!validateMobile(userData.mobile) && userData.mobile) {
      errorMessage = t("invalidMobile");
    } else if (userData.mobile && userData.mobile?.length !== 10) {
      errorMessage = t("invalidMobileLength");
    } else if (!validateEmail(userData.email)) {
      errorMessage = t("invalidEmail");
    }
    // form data validation message
    if (errorMessage) {
      dispatch(setToastMessage(errorMessage));
      return;
    }
    dispatch(editUser({ ...userData, userName: userData.firstName }));
    dispatch(editHouseholdProfile([{ ...householdData }]));
    await setMonitoringUser(userData);
    setTouched(false); // sets unsaved to false since all changes will now be saved
    dispatch(setToastMessage(t(`${tab}ProfileUpdated`)));

    // If handleSubmit comes from the back-button and there are no error messages,
    // the user can navigate back to the More tab
    if (!e && !errorMessage) {
      goBack(PAGE_URLS.MORE_HOME);
    }
  };
  // determines whether or not we can go back to the the More tab
  const handleGoBack = (e: any) => {
    if (touched) {
      // triggers Alert asking user to save or discard changes
      setIsOn(true);
      e.preventDefault();
    } else {
      goBack(PAGE_URLS.MORE_HOME);
    }
  };
  // shows screen based on selected tab
  const screens = {
    user: <Me userData={userData} handleUpdate={handleUpdate} />,
    hh: <Household hhData={householdData} handleUpdate={handleUpdate} />,
    partner: <Partner />,
  };

  const selectedTab = screens[tab];
  return (
    <PageTemplate
      pageProps={{ className: "profile-page" }}
      header={
        <NavHeader
          title={t("profile")}
          backButton={
            <IonBackButton
              defaultHref={PAGE_URLS.MORE_HOME}
              text={isDesktop ? "Back" : ""}
              icon={chevronBackOutline}
              color="primary"
              {...{ onClick: handleGoBack }}
            />
          }
        />
      }
      footer={
        <IonFooter className="ion-no-border">
          {tab != "partner" ? (
            <IonButton
              type="submit"
              expand="block"
              onClick={handleSubmit}
              className="profile-button"
            >
              {t("save")}
            </IonButton>
          ) : (
            inviteCode && (
              <IonButton expand="block" className="profile-button" onClick={invite}>
                <IonIcon icon={mailOutline} />
                <IonText>&nbsp;&nbsp;&nbsp;{t("homeCards_invite-partner")}</IonText>
              </IonButton>
            )
          )}
        </IonFooter>
      }
    >
      <Section>
        {!singlePlayerMode && (
          <IonToolbar>
            <IonSegment onIonChange={(e: any) => setTab(e.detail.value)} value={tab}>
              <IonSegmentButton value="user">
                <IonLabel>{t("me")}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="hh">
                <IonLabel>{t("household")}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="partner">
                <IonLabel>{partnerName}</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        )}
        {selectedTab}
      </Section>
      <IonAlert
        isOpen={!!isOn}
        header={t("saveChanges")}
        message={t("saveChangesDes")}
        onDidDismiss={() => setIsOn(false)}
        buttons={[
          {
            text: t("yes"),
            handler: () => {
              handleSubmit(null); // null form event
            },
          },
          {
            text: t("no"),
            handler: () => {
              goBack(PAGE_URLS.MORE_HOME);
            },
          },
        ]}
      />
    </PageTemplate>
  );
};
