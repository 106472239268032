import React, { useState, useEffect, useMemo } from "react";
import { IonButtons, IonTitle, IonText, IonIcon, IonItem } from "@ionic/react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import "./SpendingForecastPage.scss";

import { getSpendingForecastState } from "../../../redux/selectors";
import { getSpendingForecast } from "../../../redux/actions/spendingForecast";
import { useSelector } from "../../../redux/hooks";
import { prepComparisonData, prepSpendingForecastData } from "../../../utils/spendingForecastUtils";
import { ForecastChart, colorById } from "./SpendingForecastChart";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import numeral from "numeral";
import { ShareSettingsButtonAndPopover } from "../../shared/User/ShareSettingsButtonAndPopover";
import { SpendingTransactionList } from "./SpendingTransactionList";
import { Comparisons, SpendingForecastState } from "../../../redux/reducers/spendingForecast";
import { ChartLegend } from "./ChartLegend";
import { ForecastInfoModal } from "./ForecastInfoModal";
import { PAGE_URLS } from "../../../constants";
import { MonthPicker } from "../../shared/MonthPicker/MonthPicker";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";

type tabType = "hh" | "user";

export const SpendingForecastPage: React.FC = (props) => {
  const { t } = useTranslation();
  const { summary }: SpendingForecastState = useSelector(getSpendingForecastState);
  const [visibleMonth, setVisibleMonth] = useState(() => dayjs());
  const [tab, setTab] = useState<tabType>("hh");
  const [hoveredDay, setHoveredDay] = useState("");
  const [chartData, setChartData] = useState(summary);
  const [comparison, setComparison] = useState<keyof Comparisons | "">("");

  const isCurrentMonth = visibleMonth.isSame(dayjs(), "month");
  const total =
    chartData?.dailyPoints?.length &&
    chartData.dailyPoints[chartData.dailyPoints.length - 1].overallTotal;

  /*
    these operations are not super expensive however we were having performance issues
    with the entire svg chart rerendering on every hover
    using React.memo on the chart fixes the issue only if the object id of it's `data` prop is also memoized
  */
  const data = useMemo(() => {
    const comparisonData =
      chartData.comparisons && comparison
        ? prepComparisonData(chartData.comparisons).filter(({ id }) => id === comparison)
        : [];
    return prepSpendingForecastData(chartData.dailyPoints).concat(comparisonData);
  }, [comparison, chartData]);

  useEffect(() => {
    getSpendingForecast({
      detailLevel: "full",
      type: tab,
      effectiveDate: visibleMonth.startOf("month").format("YYYY-MM-DD"),
      userDate: visibleMonth.format("YYYY-MM-DD"),
    }).then(({ data }) => setChartData(data[tab]));
  }, [visibleMonth.valueOf(), tab]);

  return (
    <PageTemplate
      pageProps={{ className: "spending-forecast-page" }}
      header={
        <NavHeader
          title={<MonthPicker month={visibleMonth} onChange={setVisibleMonth} />}
          desktopTitle={t("forecastTitle")}
          subheader="useTitle"
        >
          <ShareSettingsButtonAndPopover isRoute={false} budgetType={tab} onClick={setTab} />
        </NavHeader>
      }
    >
      <Section>
        {chartData.noPredictionAvail && visibleMonth.isSame(dayjs(), "month") ? (
          <IonText>
            <h2 className="ion-text-center ion-padding">
              {t("needMoreForecastMonthData", { month: visibleMonth.format("MMMM") })}
            </h2>
          </IonText>
        ) : (
          <>
            <IonText>
              <h2 className="d-flex ion-padding ion-justify-content-between">
                <span className="font-lg medium">
                  {t(isCurrentMonth ? "spendingProjection" : "spending")}
                  <ForecastInfoModal comparison={comparison} />
                </span>
                <span className="font-lg medium">{numeral(total).format("$0,0")}</span>
              </h2>
            </IonText>
            <div className="chart-container">
              <ForecastChart data={data} hoveredDay={hoveredDay} onDayHovered={setHoveredDay} />
            </div>
            <ChartLegend comparison={comparison} onComparisonChange={setComparison} />
            <SpendingTransactionList
              data={chartData.dailyPoints.find((d) => d.date === hoveredDay)}
            />
          </>
        )}
      </Section>
    </PageTemplate>
  );
};
