import {
  BudgetCategoryObj,
  BudgetItem,
  BudgetSuperCategory,
  BudgetType,
  Category,
  SubCategory,
  FeedsParams,
  SpendingByPersonType,
  SpendingByPersonDataType,
} from "../../types";

import {
  BUDGET_ANALYTICS_DATA_LOADING,
  SET_BUDGET_ANALYTICS,
  SET_FEEDS,
  CLEAR_FEEDS,
  SPENDING_BY_PERSON_DATA,
} from "../actionTypes";

const initialState: BudgetState = {
  loading: false,
  loadingMonth: "",
  budgetsArray: [],
  feedsArray: [],
  feedsPage: 1,
  spendingByPersonArray: [],
};

interface BudgetState {
  loading: boolean;
  loadingMonth: string;
  budgetsArray: Array<BudgetItem>;
  feedsArray: Array<FeedsParams>;
  feedsPage: number;
  spendingByPersonArray: Array<SpendingByPersonType>;
}

interface action {
  type: string;
  payload: any;
}

const budgetAnalytics = (state: BudgetState = initialState, action: action) => {
  switch (action.type) {
    case SET_BUDGET_ANALYTICS: {
      return {
        ...state,
        budgetsArray: action.payload as Array<BudgetItem>,
        loading: false,
      };
    }
    case BUDGET_ANALYTICS_DATA_LOADING: {
      return {
        ...state,
        loading: !!action.payload,
        loadingMonth: action.payload || "",
      };
    }
    case SET_FEEDS: {
      const filteredFeeds = action.payload.filter((feed: FeedsParams) => {
        if (state.feedsArray.find((stateFeed) => stateFeed._id === feed._id)) return false;
        return true;
      });
      return {
        ...state,
        feedsArray: [...state.feedsArray, ...(filteredFeeds as FeedsParams[])],
        feedsPage: state.feedsPage + 1,
      };
    }
    case CLEAR_FEEDS: {
      return {
        ...state,
        feedsArray: [],
        feedsPage: 1,
      };
    }

    case SPENDING_BY_PERSON_DATA: {
      return {
        ...state,
        spendingByPersonArray: action.payload as SpendingByPersonType[],
      };
    }
    default: {
      return state;
    }
  }
};

interface parseBudgetAnalyticsForDateProps {
  budgetsArray: Array<BudgetItem>;
  effectiveDate: string;
  budgetType: BudgetType;
  superCategory: BudgetSuperCategory;
  category: Category;
  subcategoryLevel?: boolean;
  subcategory?: SubCategory;
}

export const parseBudgetAnalyticsForDate = ({
  budgetsArray,
  effectiveDate,
  budgetType,
  superCategory,
  category,
  subcategoryLevel,
  subcategory,
}: parseBudgetAnalyticsForDateProps) => {
  if (!budgetsArray || budgetsArray.length === 0) {
    return;
  }
  const budgetAnalyticsForThisDate = budgetsArray.find(
    (item: BudgetItem) => item.budgetAnalyticsDate === effectiveDate
  );
  if (!budgetAnalyticsForThisDate) {
    return;
  }
  const budgetData = budgetAnalyticsForThisDate[budgetType]?.budget;
  if (!budgetData) {
    return;
  }
  const categoryData: BudgetCategoryObj =
    budgetData[superCategory].subs[category.categoryShortName];

  let dataForChart: any = categoryData;
  if (subcategoryLevel && subcategory) {
    dataForChart = categoryData?.subs[subcategory?.subCategory] || {};
  }
  return dataForChart;
};

export default budgetAnalytics;
