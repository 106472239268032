import React, { useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import { closeOutline } from "ionicons/icons";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getSpendingChanllengeTypeDes } from "../../../utils/spendingChallenge";
import { useUserName } from "../../shared/User/useUserName";
import {
  getSpendingChallengeState,
  getCategoryState,
  getHouseholdDataState,
  getUsersState,
} from "../../../redux/selectors";
import { getAllSubCategory, getRelatedSubCategory } from "../../../utils/transactions";
import { PAGE_URLS, BUDGET_SUPER_CATEGORIES } from "../../../constants";
import { updateCreateNewChanllengeParams, updateChallengeType } from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import { ChallengeType, SpendingChallengeFieldTypes } from "../../../types/spendingChallenge";
import { useResponsiveInterface } from "../../../hooks/useResponsiveInterface";
import { getSubcatDisplayName } from "../../../utils/categorizationUtils";

interface SpendingChallengeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const NewSpendingChallengeMoreTypeModal: React.FC<SpendingChallengeModalProps> = (props) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();
  const _interface = useResponsiveInterface();
  const dispatch = useDispatch();
  const { partnerName } = useUserName();
  const history = useHistory();

  //redux state
  const { newSpendingChallenge } = useSelector(getSpendingChallengeState);
  const { categoryData, subCategoryData } = useSelector(getCategoryState);
  const { members } = useSelector(getHouseholdDataState);
  const { userId } = useSelector(getUsersState);

  const competitionType = newSpendingChallenge.competitionType
    ? newSpendingChallenge.competitionType
    : ChallengeType.INDIVIDUAL;

  //state
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  //method
  const validateSpendingChallengeData = () => {
    return !!newSpendingChallenge.competitionType;
  };

  const renderParamsForChallengeType = (challengeType: ChallengeType) => {
    const name = t("spendLessOn", {
      category: getSubcatDisplayName(selectedSubCategory, subCategoryData),
    });
    switch (challengeType) {
      case ChallengeType.COLLABORATIVE:
        return {
          name,
          isPrivate: false,
          challengeType: "spending_category",
          targets: [
            {
              users: members.map((member) => ({ userId: member._id, status: "accepted" })),
              matchingCriteria: [
                {
                  prefix: "hhInfo",
                  type: "string",
                  field: SpendingChallengeFieldTypes.CATEGORY,
                  value: selectedCategory,
                  matchType: "$eq",
                  matchTypeDetails: {
                    caseSensitive: false,
                    start: 0,
                    exactPosition: true,
                    wholeWord: true,
                  },
                },
                {
                  prefix: "hhInfo",
                  type: "string",
                  field: SpendingChallengeFieldTypes.SUBCATEGORY,
                  value: selectedSubCategory,
                  matchType: "$eq",
                  matchTypeDetails: {
                    caseSensitive: false,
                    start: 0,
                    exactPosition: true,
                    wholeWord: true,
                  },
                },
              ],
            },
          ],
          rewards: [
            {
              userIds: members.map((member: any) => member._id),
              type: "custom",
              details: { text: "" },
            },
          ],
        };
      case ChallengeType.COMPETITIVE:
        return {
          name,
          isPrivate: false,
          challengeType: "spending_category",
          targets: members.map((member: any) => ({
            users: [{ userId: member._id, status: "accepted" }],
            matchingCriteria: [
              {
                prefix: "hhInfo",
                type: "string",
                field: SpendingChallengeFieldTypes.CATEGORY,
                value: setSelectedCategory,
                matchType: "$eq",
                matchTypeDetails: {
                  caseSensitive: false,
                  start: 0,
                  exactPosition: true,
                  wholeWord: true,
                },
              },
              {
                prefix: "hhInfo",
                type: "string",
                field: SpendingChallengeFieldTypes.SUBCATEGORY,
                value: selectedSubCategory,
                matchType: "$eq",
                matchTypeDetails: {
                  caseSensitive: false,
                  start: 0,
                  exactPosition: true,
                  wholeWord: true,
                },
              },
            ],
          })),
          rewards: [
            {
              userIds: members.map((member: any) => member._id),
              type: "custom",
              details: { text: "" },
            },
          ],
        };
      case ChallengeType.INDIVIDUAL:
        return {
          name,
          isPrivate: true,
          challengeType: "spending_category",
          targets: [
            {
              users: [{ userId: userId, status: "accepted" }],
              matchingCriteria: [
                {
                  prefix: "hhInfo",
                  type: "string",
                  field: SpendingChallengeFieldTypes.CATEGORY,
                  value: selectedCategory,
                  matchType: "$eq",
                  matchTypeDetails: {
                    caseSensitive: false,
                    start: 0,
                    exactPosition: true,
                    wholeWord: true,
                  },
                },
                {
                  prefix: "hhInfo",
                  type: "string",
                  field: SpendingChallengeFieldTypes.SUBCATEGORY,
                  value: selectedSubCategory,
                  matchType: "$eq",
                  matchTypeDetails: {
                    caseSensitive: false,
                    start: 0,
                    exactPosition: true,
                    wholeWord: true,
                  },
                },
              ],
            },
          ],
          rewards: [
            {
              userIds: [userId],
              type: "custom",
              details: { text: "" },
            },
          ],
        };
      default:
        return {};
    }
  };

  const handleSpendingTypeSelect = () => {
    if (!validateSpendingChallengeData()) {
      history.push(PAGE_URLS.SPENDING_CHALLENGES_NEW);
      return;
    }
    let params = renderParamsForChallengeType(competitionType);
    dispatch(updateCreateNewChanllengeParams(params as any));
    dispatch(updateChallengeType(selectedCategory, selectedSubCategory));
    onClose();
    history.push(PAGE_URLS.SPENDING_CHALLENGES_NEW_LENGTH);
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} data-testid="NewSpendingChallenge-otherModal">
      <ModalHeader startBtnIcon={closeOutline} startBtnOnClick={() => onClose()} />
      <IonContent>
        <IonRow>
          <h2 className="ion-margin-start font-lg">{t("spendingChallenge")}</h2>
        </IonRow>
        <IonRow>
          <h3 className="ion-text-center ion-margin-start">
            {getSpendingChanllengeTypeDes(competitionType, partnerName)}
          </h3>
        </IonRow>
        <IonGrid className="ion-margin-start">
          <IonRow className="ion-justify-content-between">
            <IonCol size="4">
              <IonLabel>{t("category")}</IonLabel>
            </IonCol>
            <IonCol className="ion-text-end" size="6">
              <IonSelect
                value={selectedCategory}
                onIonChange={(e) => setSelectedCategory(e.detail.value || "")}
                interface={_interface}
                className="newSpendingChallenge-select"
              >
                <IonSelectOption value="">(Select)</IonSelectOption>
                {categoryData
                  .filter(
                    (category: any) =>
                      !category.isDeleted && category !== BUDGET_SUPER_CATEGORIES.IGNORE
                  )
                  .map((category: any, index: number) => {
                    return (
                      <IonSelectOption value={category.categoryShortName} key={index}>
                        {category.categoryFullName}
                      </IonSelectOption>
                    );
                  })}
              </IonSelect>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-between">
            <IonCol size="4">
              <IonLabel>{t("subCategory")}</IonLabel>
            </IonCol>
            <IonCol className="ion-text-end" size="6">
              <IonSelect
                value={selectedSubCategory}
                onIonChange={(e) => setSelectedSubCategory(e.detail.value || "")}
                interface={_interface}
                className="newSpendingChallenge-select"
              >
                <IonSelectOption value="">(Select)</IonSelectOption>
                {!selectedCategory
                  ? getAllSubCategory(subCategoryData).map((subCategory: any, index: number) => {
                      return (
                        <IonSelectOption value={subCategory.subCategory} key={index}>
                          {subCategory.displayName}
                        </IonSelectOption>
                      );
                    })
                  : getRelatedSubCategory(subCategoryData, selectedCategory).map(
                      (subCategory: any, index: number) => {
                        return (
                          <IonSelectOption value={subCategory.subCategory} key={index}>
                            {subCategory.displayName}
                          </IonSelectOption>
                        );
                      }
                    )}
              </IonSelect>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonButton
        disabled={!selectedCategory || !selectedSubCategory}
        onClick={handleSpendingTypeSelect}
      >
        {t("next")}
      </IonButton>
    </IonModal>
  );
};
