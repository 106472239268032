import React, { useEffect, useState } from "react";
import { IonIcon, IonLabel, IonThumbnail } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { chevronForwardOutline, ellipsisHorizontal } from "ionicons/icons";
import { useSelector } from "react-redux";
import {
  BudgetSubcategoryObj,
  BudgetSuperCategory,
  BudgetType,
  Category,
  SubCategory,
} from "../../../types";
import { getBudgetAnalyticsState } from "../../../redux/selectors";
import numeral from "numeral";
import { BudgetPieChart } from "./BudgetPieChart";
import "./BudgetDetailItem.scss";
import { parseBudgetAnalyticsForDate } from "../../../redux/reducers/budgetAnalytics";
import { BUDGET_SUPER_CATEGORIES } from "../../../constants";
import { getOperator, isOverBudget } from "../../../utils/budget";

interface BudgetDetailItemProps {
  category: Category;
  effectiveDate: string;
  budgetType: BudgetType;
  superCategory: BudgetSuperCategory;
  subcategoryLevel?: boolean;
  subcategory?: SubCategory;
  buttonIcon?: any;
}

export const BudgetDetailItem: React.FC<BudgetDetailItemProps> = ({
  category,
  effectiveDate,
  budgetType,
  superCategory,
  subcategoryLevel = false,
  subcategory,
  buttonIcon,
}) => {
  const { t } = useTranslation();
  const { budgetsArray } = useSelector(getBudgetAnalyticsState);
  const [budgetItemData, setBudgetItemData] = useState<BudgetSubcategoryObj>();
  const [dataForChart, setDataForChart] = useState<Array<any>>([]);

  useEffect(() => {
    let dataForChart = parseBudgetAnalyticsForDate({
      budgetsArray,
      effectiveDate,
      budgetType,
      superCategory,
      category,
      subcategoryLevel,
      subcategory,
    });
    if (!dataForChart) {
      dataForChart = {
        amount: 0,
        amountActual: 0,
      };
    }
    setBudgetItemData(dataForChart);
    setDataForChart([
      {
        id: "budgetLeft",
        value: dataForChart.amount - dataForChart.amountActual || 1,
      },
      {
        id: "budgetSpent",
        value: dataForChart.amountActual,
      },
    ]);
  }, [
    budgetsArray,
    effectiveDate,
    budgetType,
    superCategory,
    category,
    subcategory,
    subcategoryLevel,
  ]);

  return (
    <>
      <IonThumbnail slot="start">
        <BudgetPieChart
          budgetAnalyticsDataForPieChart={dataForChart}
          primaryColor={category.design.bgColor}
          design={!subcategoryLevel ? category.design : undefined}
        />
      </IonThumbnail>
      <IonLabel className="budget-label-progress">
        <h3 className="font-md">
          {subcategoryLevel ? subcategory?.displayName : category.categoryFullName}
        </h3>
        <h4 className="font-sm">
          <span className="semibold">
            {numeral(budgetItemData?.amountActual || 0).format("$0,0")}
          </span>{" "}
          / {numeral(budgetItemData?.amount || 0).format("$0,0")}
        </h4>
      </IonLabel>
      <IonLabel slot="end" className="ion-text-end">
        <IonIcon
          icon={
            buttonIcon ? buttonIcon : subcategoryLevel ? ellipsisHorizontal : chevronForwardOutline
          }
          data-testid={`BudgetDetailItem_button_${
            subcategoryLevel ? subcategory?.displayName : category.categoryFullName
          }`}
        />
        <div className={isOverBudget(superCategory, budgetItemData) ? " budget-list-status" : ""}>
          <h4
            className={
              isOverBudget(superCategory, budgetItemData) ? "font-sm over-budget-pill" : "font-sm"
            }
          >
            {budgetItemData?.superCategory === BUDGET_SUPER_CATEGORIES.INCOME}
            {numeral(
              Math.abs(
                getOperator(budgetItemData?.superCategory) * (budgetItemData?.amount || 0) -
                  getOperator(budgetItemData?.superCategory) * (budgetItemData?.amountActual || 0)
              )
            ).format("$0,0")}{" "}
            {isOverBudget(superCategory, budgetItemData) ? t("over") : t("left")}
          </h4>
        </div>
      </IonLabel>
    </>
  );
};
