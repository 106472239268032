import { IonDatetime } from '@ionic/react';
import dayjs from 'dayjs';
import React from 'react'
import { useViewport } from '../../../hooks/useViewport';

import './DatePicker.scss';

type DatePickerProps = {
  value?: string;
  onChange: (date: string) => void;
  min?: string;
  max?: string;
  displayFormat?: string;
  placeholder?: string;
  className?: string;
  [key: string]: any;
}

export const DatePicker: React.FC<DatePickerProps> = props => {
  const { value, onChange, min, max, displayFormat, placeholder, className = "", ...rest } = props
  const { isDesktop } = useViewport();
  return isDesktop ? <input
    type="date"
    value={dayjs(value).format("YYYY-MM-DD")}
    min={min}
    max={max}
    placeholder={placeholder}
    className={`date-picker font-md ${className}`}
    onChange={e => e.target.value && onChange(e.target.value)}
    {...rest}
  /> : <IonDatetime
    value={value}
    onIonChange={e => e.detail.value &&
      dayjs(e.detail.value).isValid() &&
      onChange(dayjs(e.detail.value).format("YYYY-MM-DD"))
    }
    min={min}
    max={max}
    displayFormat={displayFormat}
    placeholder={placeholder}
    className={className}
    {...rest}
  />
}