import { SET_SPENDING_FORECAST_LOADING, SET_SPENDING_FORECAST_SUMMARY } from "../actionTypes";

export type SpendingForecastState = {
  loading: boolean;
  summary: {
    actualsOnly?: boolean;
    noPredictionAvail?: boolean;
    dailyPoints: Array<DailyPoint>;
    comparisons?: Comparisons;
  };
};

export type Comparisons = {
  actualIncome: Array<DailyPoint>;
  prevMonth: Array<DailyPoint>;
  budgetedIncome: number;
  budgetedSpend: number;
};

export type DailyPoint = {
  date: string; // in 'YYYY-MM-DD' format
  ct: number;
  overallCt: number;
  total: number;
  overallTotal: number;
  txns: Array<SpendingTransaction | null>;
};

export type SpendingTransaction = {
  amtWithSign: number;
  desc: string;
  txnId: string;
  isBill?: boolean;
  billAmount?: number;
  billDay?: number;
  billId?: string;
  billStatus?: "paid" | "unpaid";
};

type SpendingForecastAction = {
  type: string;
  payload: SpendingForecastState["summary"];
};

const initialState = {
  loading: false,
  summary: {
    dailyPoints: [],
  },
};

const spendingForecast = (
  state: SpendingForecastState = initialState,
  action: SpendingForecastAction
) => {
  switch (action.type) {
    case SET_SPENDING_FORECAST_LOADING:
      return { ...state, loading: action.payload };
    case SET_SPENDING_FORECAST_SUMMARY:
      return { ...state, summary: action.payload, loading: false };
    default:
      return state;
  }
};

export default spendingForecast;
