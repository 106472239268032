import { RootState } from "./reducers";
import { Account, findAccountById, AccountSource } from "./reducers/accounts";
import { getAccountBalancesByMonth } from "../utils/netWorthUtils";
import { FINANCIAL_ACCOUNT_STATUS, PRIVILEGE_TYPE_DATA_2 } from "../constants";

export const getUsersState = (state: RootState) => state.users;
export const getLoadingState = (state: RootState) => state.ui.loading;
export const getToastMessage = (state: RootState) => state.ui.toastMessage;
export const getAccountsState = (state: RootState) => state.accounts;
export const getOwnedOrSharedAccounts = (state: RootState): Account[] =>
  state.accounts?.myAccounts
    .concat(...(state.accounts.otherAccountCollection || []))
    .reduce((all: Account[], bank: any) => all.concat(...bank.subBanks), [] as Account[])
    .filter(
      (account: Account) =>
        account.accountStatus === FINANCIAL_ACCOUNT_STATUS.ACTIVE &&
        account.source !== AccountSource.COREPRO &&
        (account.isOwner || account.hhInfo[0].privilegeType === PRIVILEGE_TYPE_DATA_2.SHARED)
    );
export const getVersionCheckState = (state: RootState) => state.versionCheck;
export const getTransactionState = (state: RootState) => state.transactions;
export const getCategoryState = (state: RootState) => state.category;
export const getGoalsState = (state: RootState) => state.goals;
export const getHouseholdDataState = (state: RootState) => state.householdData;
export const getDeviceDataState = (state: RootState) => state.deviceData;
export const getAlertUiState = (state: RootState) => state.alertUi;
export const getNotificationPrefState = (state: RootState) => state.notificationPreferences;
export const getBudgetAnalyticsState = (state: RootState) => state.budgetAnalytics;
export const getBillsState = (state: RootState) => state.bills;
export const getTransactionRulesState = (state: RootState) => state.transactionRules;
export const getAccountById = (id: string) => (state: RootState) =>
  findAccountById(state.accounts.allBalances, id);
export const getFundingAccount = (state: RootState) => {
  const fundingAccountId = state.goals.accountInfo.externalFundingAccountFinId;
  return getAccountById(fundingAccountId)(state);
};
export const getUserFundingInfo = (goalId: string, isUser: boolean) => (state: RootState) =>
  state.goals.goalDetails[goalId]
    ? state.goals.goalDetails[goalId][isUser ? "viewerFundingInfo" : "nonViewerFundingInfo"]
    : null;
export const getBillsAuditDataState = (state: RootState) => state.billsAudit;
export const getNetWorthData =
  (isPrivate = false, monthLimit?: number) =>
  (state: RootState) =>
    getAccountBalancesByMonth(state.accounts.allBalances, isPrivate, monthLimit);
export const getSpendingChallengeState = (state: RootState) => state.spendingChallenge;
export const getSpendingForecastState = (state: RootState) => state.spendingForecast;
export const getCategoryMap = (state: RootState) =>
  state.category.categoryData.reduce((all: any, cat: any) => {
    all[cat.categoryShortName] = cat;
    return all;
  }, {});
export const getBranchDataState = (state: RootState) => state.branchData;
