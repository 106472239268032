// Uses default IonRefresher
// Building into custom component for global styling purposes

import React from "react";
import { RefresherEventDetail } from "@ionic/core";
import { IonRefresher, IonRefresherContent } from "@ionic/react";

interface PullToRefreshProps {
  refreshHandler: (event: CustomEvent<RefresherEventDetail>) => void;
}

export const PullToRefresh: React.FC<PullToRefreshProps> = ({ refreshHandler }) => {
  return (
    <IonRefresher
      slot="fixed"
      onIonRefresh={refreshHandler}
      pullFactor={0.5}
      pullMin={100}
      pullMax={200}
    >
      <IonRefresherContent></IonRefresherContent>
    </IonRefresher>
  );
};
