import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonLabel,
  IonList,
  IonItem,
  IonButton,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import merge from "lodash/merge";
import numeral from "numeral";

import { NewProperty, savePropertyItem } from "../../../redux/actions";
import { useUserName } from "../../shared/User/useUserName";

type PropertyFormProps = {
  onSaveEnd: () => void;
  type: "house" | "car" | "other" | undefined;
};

const defaultFields = {
  name: "",
  amount: null,
  category: "",
  ownership: "",
  details: {},
  isAsset: true,
  balVisibility: false,
};

const singlePlayerDefaults = {
  ownership: "indiv",
  balVisibility: true,
};

export const PropertyForm: React.FC<PropertyFormProps> = (props) => {
  const { type, onSaveEnd } = props;
  const { singlePlayerMode } = useUserName();
  const [fields, setFields] = useState<NewProperty>(() => ({
    ...defaultFields,
    ...(singlePlayerMode ? singlePlayerDefaults : {}),
  }));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!type) return null;

  const namePlaceholder = { house: "Our home", car: "My car", other: "My ring" }[type];
  const formReady = fields.name && fields.amount !== null && fields.ownership;
  const onChange = (slice: Partial<NewProperty>) => setFields(merge({}, fields, slice));
  const onSave = () =>
    (dispatch(savePropertyItem({ ...fields, category: type })) as any).then(onSaveEnd);
  console.debug(fields);
  return (
    <>
      <IonList className="fields-list">
        <IonItem>
          <IonLabel>{t("nickname")}</IonLabel>
          <IonInput
            value={fields.name}
            slot="end"
            placeholder={namePlaceholder}
            onIonChange={(e) => onChange({ name: e.detail.value || "" })}
          />
        </IonItem>
        {type === "other" ? (
          <IonItem>
            <IonLabel>{t("type")}</IonLabel>
            <IonSelect
              interface="popover"
              slot="end"
              value={fields.details.otherType || ""}
              onIonChange={(e) => onChange({ details: { otherType: e.detail.value } })}
            >
              <IonSelectOption value="">{t("optional")}</IonSelectOption>
              <IonSelectOption value="PROPERTY">{t("property")}</IonSelectOption>
              <IonSelectOption value="VEHICLE">{t("vehicle")}</IonSelectOption>
              <IonSelectOption value="PERSONAL">{t("personalItem")}</IonSelectOption>
              <IonSelectOption value="OTHER">{t("other")}</IonSelectOption>
            </IonSelect>
          </IonItem>
        ) : null}
        {type === "car" ? (
          <IonItem>
            <IonLabel>{t("yearMakeModel")}</IonLabel>
            <IonInput
              slot="end"
              placeholder="2011 Subaru Impreza"
              onIonChange={(e) => onChange({ details: { carDetails: e.detail.value || "" } })}
            />
          </IonItem>
        ) : null}
        <IonItem>
          <IonLabel>{t("estValue")}</IonLabel>
          <IonInput
            slot="end"
            placeholder="$11.01"
            type="number"
            value={!fields.amount && fields.amount !== 0 ? "" : fields.amount}
            onIonChange={(e) =>
              onChange({ amount: Math.abs(numeral(e.detail.value).value() as number) })
            }
          />
        </IonItem>
        {type === "house" ? (
          <IonItem>
            <IonLabel>{t("primarySecondary")}</IonLabel>
            <IonSelect
              interface="popover"
              slot="end"
              value={fields.details.primary || ""}
              onIonChange={(e) => onChange({ details: { primary: e.detail.value } })}
            >
              <IonSelectOption value="">{t("optional")}</IonSelectOption>
              <IonSelectOption value="PRIMARY">{t("primary")}</IonSelectOption>
              <IonSelectOption value="SECONDARY">{t("secondary")}</IonSelectOption>
            </IonSelect>
          </IonItem>
        ) : null}
        {type === "car" ? (
          <IonItem>
            <IonLabel>{t("own")}</IonLabel>
            <IonSelect
              interface="popover"
              slot="end"
              value={fields.details.ownOrNot || ""}
              onIonChange={(e) => onChange({ details: { ownOrNot: e.detail.value } })}
            >
              <IonSelectOption value="">{t("optional")}</IonSelectOption>
              <IonSelectOption value="OWNED">{t("fullyOwned")}</IonSelectOption>
              <IonSelectOption value="LEASED">{t("leased")}</IonSelectOption>
              <IonSelectOption value="FINANCED">{t("financed")}</IonSelectOption>
            </IonSelect>
          </IonItem>
        ) : null}
        {singlePlayerMode ? null : (
          <IonItem>
            <IonLabel>{t("ownership")}</IonLabel>
            <IonSelect
              interface="popover"
              slot="end"
              value={fields.ownership || ""}
              onIonChange={(e) => onChange({ ownership: e.detail.value })}
            >
              <IonSelectOption value="">{t("pickOne")}</IonSelectOption>
              <IonSelectOption value="joint">{t("joint")}</IonSelectOption>
              <IonSelectOption value="indiv">{t("individual")}</IonSelectOption>
            </IonSelect>
          </IonItem>
        )}
        {fields.ownership === "indiv" && !singlePlayerMode ? (
          <IonItem>
            <IonLabel>{t("shareBalance")}</IonLabel>
            <IonCheckbox
              slot="end"
              onIonChange={(e) => onChange({ balVisibility: e.detail.checked })}
            />
          </IonItem>
        ) : null}
      </IonList>
      <IonButton className="submit-button" expand="full" disabled={!formReady} onClick={onSave}>
        {t("save")}
      </IonButton>
    </>
  );
};
