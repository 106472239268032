import React, { useState } from "react";
import {
  useIonViewDidEnter,
} from "@ionic/react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PAGE_URLS } from "../../../constants";
import images from "../../../assets/images/illustrations";
import { useSelector, useDispatch } from "react-redux";
import { getBillsAuditDataState } from "../../../redux/selectors";
import { billStatusTriage } from "../../../utils/billsAuditUtils";
import { BillsAuditSteps } from "../../../types/billsAudit";
import { getBillsAuditData, updateSelectedBillInfo } from "../../../redux/actions";
import { PageTemplate, NavHeader } from "../../shared/PageTemplate";
import { CelebrationModal } from "../../shared/Modal/CelebrationModal";

export const BillsAuditCelebrate: React.FC<RouteComponentProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedBillerInfo, billsAuditData } = useSelector(getBillsAuditDataState);
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  const close = () => {
    setModalOpen(false);
    history.push(PAGE_URLS.BILLS_AUDIT_MAIN);
  };

  // make sure they should be here
  const rightPageCheck = () => {
    billStatusTriage(selectedBillerInfo.billsharkProviderId!).then((triageResp) => {
      if (triageResp.baStep !== BillsAuditSteps.COMPLETE) {
        return history.replace(PAGE_URLS.BILLS_AUDIT_MAIN);
      }
      setModalOpen(true);
    });
  };

  useIonViewDidEnter(() => {
    if (!billsAuditData) {
      (dispatch(getBillsAuditData()) as any).then(() => {
        rightPageCheck();
        dispatch(updateSelectedBillInfo(selectedBillerInfo.billsharkProviderId!));
      });
    } else {
      rightPageCheck();
      dispatch(updateSelectedBillInfo(selectedBillerInfo.billsharkProviderId!));
    }
  }, []);

  return (
    <PageTemplate
      header={<NavHeader title={t("homeCards_billsAuditTitle")} />}
    >
      <CelebrationModal open={modalOpen} onClose={close} buttonText={t("billsAuditWoohoo")}>
        <img src={images.celebrationImg} alt="celebration" />
        <h1>{t("billsAuditWayToGo")}</h1>
        <p>
          {t("billsAuditCongrats", { billerName: selectedBillerInfo.displayName })}
        </p>
      </CelebrationModal>
    </PageTemplate>
  );
};
