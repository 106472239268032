import React, { useState } from 'react'
import { IonActionSheet, IonButton, IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next'
import { ellipsisHorizontal } from 'ionicons/icons';
import { ActionSheet } from '../PageTemplate/ActionSheet';

type ActionBarSelectProps = {
  options: string[];
  selectedOption: string;
  onSelect: (selectedOption: string) => void;
  slot?: "start" | "end"
}

export const ActionBarSelect: React.FC<ActionBarSelectProps> = props => {
  const { options, selectedOption, onSelect, slot } = props
  const [open, setOpen] = useState(false);
  const { t } = useTranslation()

  const getHandler = (option: string) => () => onSelect(option);
  return <ActionSheet
    buttonProps={{ slot }}
    buttons={[
      ...options
        .filter(option => option !== selectedOption)
        .map(option => ({ text: t(option), handler: getHandler(option) })),
      t("cancel")
    ]}
  />

}