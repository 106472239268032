import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonItem,
  IonLabel,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonToggle,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeOutline } from "ionicons/icons";
import { getDeviceDataState } from "../../../redux/selectors";
import { difference, remove } from "lodash";
import { ALL_HOME_CARDS_ARRAY } from "../../../constants";
import { ModalHeader } from "../../shared/Header/ModalHeader";
import { saveCards } from "../../../utils/homeTab";
import { trackEvent } from "../../../vendors/monitoring";

// grabbed from https://github.com/sindresorhus/array-move
const arrayMove = (array: Array<any>, from: number, to: number) => {
  array = [...array];
  const startIndex = from < 0 ? array.length + from : from;
  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;
    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
  return array;
};

interface HomeCardManagementModalProps {
  isOpen: boolean;
  onClose: any;
}
export const HomeCardManagementModal: React.FC<HomeCardManagementModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deviceDetails, cardPreferences } = useSelector(getDeviceDataState);

  // state
  const [allowReorder, setAllowReorder] = useState(false);
  const [allCardsArray, setAllCardsArray] = useState<string[]>([]);
  const [enabledCardsArray, setEnabledCardsArray] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen) {
      setArrays(cardPreferences.cardArr);
    }
  }, [isOpen, cardPreferences.cardArr]);

  const setArrays = (newHomeCardsArray: string[]) => {
    setEnabledCardsArray(newHomeCardsArray);
    setAllCardsArray([
      ...newHomeCardsArray,
      ...difference(ALL_HOME_CARDS_ARRAY, newHomeCardsArray),
    ]);
  };

  const setAndSave = (newHomeCardsArray: string[]) => {
    setArrays(newHomeCardsArray);
    saveCards({ newHomeCardsArray, cardPreferences, uuid: deviceDetails!.uuid }, dispatch);
  };

  const toggleCard = (card: string, addCard: boolean) => {
    const newHomeCardsArray = [...enabledCardsArray];
    if (addCard) {
      trackEvent("manageCard_toggleOn", { category: "manageCards", label: card });
      newHomeCardsArray.push(card);
    } else {
      trackEvent("manageCard_toggleOff", { category: "manageCards", label: card });

      remove(newHomeCardsArray, (c) => c === card);
    }
    setAndSave(newHomeCardsArray);
  };

  const reorderHomeCards = ({ detail }: any) => {
    const { from, to } = detail;
    const newHomeCardsArray = arrayMove([...enabledCardsArray], from, to);
    setAndSave(newHomeCardsArray);
    detail.complete();
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => onClose(false)}>
      <ModalHeader
        title={t("manageCards")}
        startBtnIcon={closeOutline}
        startBtnOnClick={() => onClose(false)}
        endBtnText={allowReorder ? t("toggle") : t("reorder")}
        endBtnOnClick={() => setAllowReorder(!allowReorder)}
      />
      <IonContent>
        <IonReorderGroup disabled={!allowReorder} onIonItemReorder={reorderHomeCards}>
          {allCardsArray &&
            allCardsArray.map((card) => {
              return (
                (!allowReorder || enabledCardsArray.includes(card)) && (
                  <IonItem lines="none" key={card}>
                    <IonLabel>{t(`homeCards_${card}`)}</IonLabel>
                    {allowReorder ? (
                      <IonReorder slot="end" />
                    ) : (
                      <IonToggle
                        onIonChange={(e) => toggleCard(card, e.detail.checked)}
                        checked={enabledCardsArray.includes(card)}
                        data-testid={`${card}_toggle`}
                      />
                    )}
                  </IonItem>
                )
              );
            })}
        </IonReorderGroup>
      </IonContent>
    </IonModal>
  );
};
