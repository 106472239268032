import React from "react";
import numeral from "numeral";

import "./GoalProgress.scss";
import { useTranslation } from "react-i18next";
import { Goal } from "../../../types/goals";
import { getGoalDefs } from "../../../utils/goalUtils";
import { GOAL_STATUS } from '../../../constants';

type GoalProgressProps = {
  goal: Goal;
};

export const GoalProgress: React.FC<GoalProgressProps> = (props) => {
  const { goal } = props;
  const { t } = useTranslation();

  const isClosedOrCompleted = [GOAL_STATUS.CLOSED, GOAL_STATUS.COMPLETED].includes(goal?.status ?? "")

  return (
    <div className="goal-progress ion-margin-top">
      <div className="goal-labels font-sm">
        <span className="semibold">{numeral(isClosedOrCompleted ? goal.availBal : goal.curBal).format("$0,0")}</span>
        &nbsp;
        {isClosedOrCompleted ? t("balanceAvailable") : <span>
          /
          &nbsp;
          {numeral(goal.targetAmount).format("$0,0")}
        </span>}
      </div>
      <div className="progress-bar">
        <div className="progress-indicator" style={{
          width: `${(goal.curBal! / goal.targetAmount) * 100}%`,
          "--progress-color": getGoalDefs(goal.category)?.design.textColor,
        } as any} />
      </div>
    </div>
  );
};
