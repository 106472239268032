import React, { useEffect } from "react";
import {
  IonBackButton,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
  IonToggle,
  IonSelect,
  IonSelectOption,
  IonItemDivider,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationPref,
  setLoading,
  setToastMessage,
  updateNotificationPref,
} from "../../../redux/actions";
import { getAccountsState, getNotificationPrefState } from "../../../redux/selectors";
import {
  NOTIFICATION_BAL_TIMING,
  NOTIFICATION_TYPES,
} from "../../../redux/reducers/notificationPreferences";
import { put } from "../../../utils/apiOps";
import { PAGE_URLS } from "../../../constants";
import { Account, AccountCollectionItem } from "../../../redux/reducers/accounts";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { useResponsiveInterface } from "../../../hooks/useResponsiveInterface";
import { trackEvent } from "../../../vendors/monitoring";

interface ParamTypes {
  notificationType: string;
}

const labels: Record<string, string> = {
  "1": "notifTypePartnerActivity",
  "3": "notifTypeAccountAlert",
  "4": "notifTypeNewTransactions",
  "5": "notifTypeInsights",
  "6": "notifTypeBillReminders",
  "7": "notifTypeBalanceUpdates",
  "8": "notifTypeBudgetUpdates",
};

export const NotificationPreference: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const _interface = useResponsiveInterface("alert");
  let { typesDisabled, balTiming, balanceFinAcct } = useSelector(getNotificationPrefState);
  const { myAccounts, otherAccountCollection } = useSelector(getAccountsState);
  const { notificationType } = useParams<ParamTypes>();
  let label: string = "X";
  let desc: string = "";
  label = labels[notificationType];
  if (!label) {
    history.push(PAGE_URLS.MORE_SETTINGS_NOTIFS);
  }
  desc = `${label}Desc`;
  const notificationTypeNum: NOTIFICATION_TYPES = parseInt(notificationType);
  const balUpdatePage: boolean = notificationTypeNum === NOTIFICATION_TYPES.BALANCE;
  let checked: boolean = !typesDisabled.includes(notificationTypeNum);
  let selectedTiming: NOTIFICATION_BAL_TIMING = balTiming;

  const toggleNotif = (checked: boolean) => {
    let newTypesDisabled = [...typesDisabled];
    if (!checked) {
      if (!typesDisabled.includes(notificationTypeNum)) {
        newTypesDisabled.push(notificationTypeNum);
      }
    } else {
      newTypesDisabled = typesDisabled.filter((value, index, arr) => {
        return value !== notificationTypeNum;
      });
    }
    updatePreferences({ typesDisabled: newTypesDisabled });
    trackEvent(checked ? "toggle_notificationsOn" : "toggle_notificationsOff", {
      category: "notifications",
      label: notificationTypeNum,
    });
  };

  const toggleBalAlert = (acctId: string, checked: boolean) => {
    let newBalanceFinAcct = [...balanceFinAcct];
    if (checked) {
      if (!newBalanceFinAcct.includes(acctId)) {
        newBalanceFinAcct.push(acctId);
      }
    } else {
      newBalanceFinAcct = balanceFinAcct.filter((value, index, arr) => {
        return value !== acctId;
      });
    }
    updatePreferences({ balanceFinAcct: newBalanceFinAcct });
    trackEvent(checked ? "toggle_notificationsOn" : "toggle_notificationsOff", {
      category: "notifications",
      label: notificationTypeNum,
    });
  };

  const updateTiming = (newTiming: NOTIFICATION_BAL_TIMING) => {
    updatePreferences({ balTiming: newTiming });
  };

  const updatePreferences = (payload: any) => {
    put(true, {
      endpoint: "/user/edit/notificationSettings",
      bodyData: payload,
    }).then(() => {
      dispatch(updateNotificationPref(payload));
    });
  };

  useEffect(() => {
    dispatch(setLoading(true));
    (dispatch(getNotificationPref()) as any)
      .then(() => {})
      .catch((error: any) => dispatch(setToastMessage(error)))
      .finally(() => dispatch(setLoading(false)));
  }, [dispatch]);

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={<NavHeader defaultHref={PAGE_URLS.MORE_SETTINGS_NOTIFS} title={t("settings")} />}
    >
      <Section>
        <IonList lines="none">
          <IonItemDivider>
            <IonLabel>{t("notifications")}</IonLabel>
          </IonItemDivider>
          <IonItem>
            <IonLabel>{t(label)}</IonLabel>
            <IonToggle
              checked={checked}
              onIonChange={(e) => toggleNotif(e.detail.checked)}
              data-testid="NotificationPrefToggle"
            />
          </IonItem>
          {balUpdatePage && (
            <IonItem>
              <IonLabel>{t("timing")}</IonLabel>
              <IonSelect
                value={selectedTiming}
                interface={_interface}
                onIonChange={(e) => updateTiming(e.detail.value)}
                data-testid="NotificationTimingDropDown"
              >
                <IonSelectOption value="daily">{t("daily")}</IonSelectOption>
                <IonSelectOption value="weekly">{t("weekly")}</IonSelectOption>
              </IonSelect>
            </IonItem>
          )}
          <IonGrid>
            <IonRow>
              <IonCol className="ion-margin-horizontal">{t(desc)}</IonCol>
            </IonRow>
          </IonGrid>
          {balUpdatePage && (
            <IonItemDivider>
              <IonLabel>{t("myAccounts")}</IonLabel>
            </IonItemDivider>
          )}
          {balUpdatePage &&
            myAccounts.length > 0 &&
            myAccounts.map((ins: AccountCollectionItem) =>
              ins.subBanks.map((acct: Account) => (
                <IonItem key={acct._id}>
                  <IonLabel>{acct.accountNickname || acct.accountName}</IonLabel>
                  <IonToggle
                    checked={balanceFinAcct.includes(acct._id)}
                    onIonChange={(e) => toggleBalAlert(acct._id, e.detail.checked)}
                    data-testid={`NotificationBalanceToggle_${acct._id}`}
                  ></IonToggle>
                </IonItem>
              ))
            )}
          {balUpdatePage && otherAccountCollection.length > 0 && (
            <IonItemDivider>{t("otherAccounts")}</IonItemDivider>
          )}
          {balUpdatePage &&
            otherAccountCollection.length > 0 &&
            otherAccountCollection.map((ins: AccountCollectionItem) =>
              ins.subBanks.map((acct: Account) => (
                <IonItem key={acct._id}>
                  <IonLabel>{acct.accountNickname || acct.accountName}</IonLabel>
                  <IonToggle
                    checked={balanceFinAcct.includes(acct._id)}
                    onIonChange={(e) => toggleBalAlert(acct._id, e.detail.checked)}
                  ></IonToggle>
                </IonItem>
              ))
            )}
        </IonList>
      </Section>
    </PageTemplate>
  );
};
