export type Transaction = {
  _id: string;
  hhInfo: TransactionHHInfo[];
  amount: any;
  description: TransactionDescription;
  baseType: TransactionBaseType;
  date: string;
  accountSubType: string;
  reactions: Reaction[];
  beefCategoryFullName: string;
  honeyfiCategoryDisplayName: string;
  accountId: string;
  status: TransactionStatus;
  isChild: boolean;
  isChildOfTxnId?: string;
  comments: Comment[];
  transactionOwner: string;
  isPrivate?: boolean;
  rules: string[];
  isBill?: boolean;
  bills?: TransactionsBill[];
  hasOffers?: boolean;
  offers?: Offer[];
  source: TransactionSource;
  isManual?: boolean;
};

export enum TransactionStatus {
  PENDING = "PENDING",
  POSTED = "POSTED",
}

export enum TransactionSource {
  MANUAL = "user",
  PLAID = "plaid",
  BANK_CAPONE = "bank_capone",
}

export type TransactionDescription = {
  nickname?: string;
  simple?: string;
  original: string;
  nameHash: string;
};

export type Offer = {
  offersRulesId: string;
  offersId: string;
  status: "active" | string;
  cta: string;
  logo: string;
  url: string;
};

export type TransactionsBill = {
  billId: string | any;
  billMonth: Date;
};

export type TransactionHHInfo = {
  userUpdatedCategory: boolean;
  isPrivate: boolean;
  rules: string[];
  hhId: string;
  sharedExpense: TransactionSharedExpense;
  beefCategoryName: string;
  beefCategoryNameOrig: string;
  honeyfiCategoryName: string;
  honeyfiCategoryNameOrig: string;
  comments: Comment[];
  reactions: Reaction[];
  sharedExpenseIndividual: string[];
  transactionVisibility: string[];
};

export enum TransactionSharedExpense {
  SHARED = "0",
  VISIBLE = "1",
  IGNORED = "3",
  JUSTME = "4",
}

export type SearchParam = {
  keyword?: string;
  transactionFilter?: string;
  categoryFilter?: string;
  subCategoryFilter?: string;
  accountIds?: string;
  baseType?: TransactionBaseType;
  startDate?: string;
  endDate?: string;
  isPrivate?: "both" | boolean;
  pageNumber?: number;
  merchName?: string;
  budgetMonth?: string;
  showBack?: boolean;
  billIds?: string;
  spendingTargetId?: string;
  spendingChallengeId?: string;
};

export enum TransactionBaseType {
  CREDIT = "CREDIT",
  DEBIT = "DEBIT",
  NONE = "",
}

export type CreditDebitData = {
  credit: CreditDebitDataDetail;
  debit: CreditDebitDataDetail;
};

type CreditDebitDataDetail = {
  count: number;
  total: number;
};

export type TransactionMapList = {
  date: string;
  transactions: Transaction[];
};

export type SearchResult = {
  meta: CreditDebitData;
  transactions: Transaction[];
};

export type NewTransaction = {
  name: string;
  amount: string;
  date: string;
  baseType: TransactionBaseType;
  accountId: string;
  category: string;
  subCategory: string;
};

export type SaveReaction = {
  reaction: string;
  transactionId: string;
  isPrivate: boolean | "both";
};

export type RemoveReaction = {
  reaction: string;
  reactionId: string;
  transactionId: string;
  isPrivate: boolean | "both";
};

export type UpdateSubCategory = {
  transactionId: string;
  subcategory: string;
};

export type SplitTransactionType = {
  category: string;
  subCategory: string;
  amount: string;
  readonly: boolean;
  uuid: string;
};

export type SaveSplitTransactionType = {
  transactionId: string;
  amounts: Array<number>;
  beefCategoryNames: Array<string>;
  honeyfiCategoryNames: Array<string>;
};

export type EditTransactionSharingType = {
  transactionId: string;
  action: string;
  sharedExpenseIndividual?: Array<string>;
  transactionVisibility?: Array<string>;
};

export type EditTransactionType = {
  transactionId: string;
  nickname?: string;
  commentId?: string;
  date?: string;
};

export type FindSimilarTransactionType = {
  nameHash?: number;
  merchName?: string;
  keyword?: string;
  isPrivate: boolean | "both";
  pageNumber: number;
};

export type AddComments = {
  message: string;
  transactionId: string;
  userFirstName: string;
  isPrivate: boolean | "both";
};

export type Comment = {
  _id: string;
  message: string;
  updatedOn: string;
  userId?: string;
};

export type Reaction = {
  _id: string;
  userId: string;
  reaction: ReactionType;
  createdOn: Date;
};

export type ReactionType = "" | "angry" | "like" | "sad" | "love" | "wow" | "happy";

export enum TransactionModalType {
  NONE = "",
  RECATEGORIZATION = "RECATEGORIZATION",
  EDIT_SHARED_STATUS = "EDIT_SHARED_STATUS",
  CATEGORY_RULE = "CATEGORY_RULE",
  SHARED_STATUS_RULE = "SHARED_STATUS_RULE",
}

export type TransactionContext = "list" | "search" | "advancedSearch";

export interface TransactionDetailURLParams {
  transactionId: string;
}
