import React from "react";
import {
  IonSplitPane,
  IonMenu,
  IonContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonList,
} from "@ionic/react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

import { titleCase } from "../../../utils/formatUtils";

import "./DesktopNavMenu.scss";

import { TabLink, tabLinks } from "../../../utils/routeUtils";
import Avatar from "../../shared/Avatar/Avatar";

import { trackEvent } from "../../../vendors/monitoring";

export const DesktopNavMenu: React.FC = (props) => {
  return (
    <IonSplitPane when="(min-width: 940px)" className="desktop-nav-menu">
      <IonMenu side="start" contentId="ion-router-outlet" menuId="side-bar">
        <IonContent>
          <IonList>
            {tabLinks.map((item) => (
              <NavMenuLink key={item.url} {...item} />
            ))}
          </IonList>
        </IonContent>
      </IonMenu>
    </IonSplitPane>
  );
};

const NavMenuLink: React.FC<TabLink & { isSubLink?: boolean }> = (props) => {
  const { t } = useTranslation();
  const { name, url, icon, icon_selected, color, subLinks, isSubLink } = props;
  const isActive = useLocation().pathname.startsWith(url);
  return (
    <>
      <IonItem
        detail={false}
        lines="none"
        title={t("tab_" + name)}
        id={`menu-item-${name}`}
        style={{ "--tab-theme-color": color }}
        routerLink={url}
        className={`${isActive ? "active" : ""} ${isSubLink ? "sub-link" : ""}`}
        data-testid={`${name}Tab_web`}
        onClick={() => {
          trackEvent(`click_nav${titleCase(name).replace(" ", "")}`, {
            category: "navigation",
          });
        }}
      >
        {name === "more" ? (
          <Avatar isUser={true} />
        ) : (
          <IonIcon src={isActive ? icon_selected : icon} />
        )}
        <IonLabel className="menu-label">{t("tab_" + name)}</IonLabel>
      </IonItem>
      {isActive &&
        subLinks?.map((subItem) => <NavMenuLink key={subItem.url} {...subItem} isSubLink />)}
    </>
  );
};
