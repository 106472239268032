import React, { useEffect, useState } from "react";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCol,
  IonFooter,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAccountsState, getGoalsState } from "../../../redux/selectors";
import { put } from "../../../utils/apiOps";
import { PAGE_URLS } from "../../../constants";
import { ManageAccountsUrlParams } from "../../../types";
import { useDispatch } from "../../../redux/hooks";
import { getAllTransactions, getMyAccountsData, setLoading } from "../../../redux/actions";
import { AccountCollectionItem } from "../../../redux/reducers/accounts";
import { openChat } from "../../../vendors/intercom";
import { useNavigation } from "../../../utils/useNavigation";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { trackEvent } from "../../../vendors/monitoring";

export const UnlinkInstitution: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [present, dismiss] = useIonAlert();
  const { navigate } = useNavigation();

  const { sourceInstitutionId } = useParams<ManageAccountsUrlParams>();
  const { accountInfo } = useSelector(getGoalsState);
  const { myAccounts, otherAccountCollection } = useSelector(getAccountsState);

  const [selectedInstitution, setSelectedInstitution] = useState<AccountCollectionItem>();
  const [reasonIndex, setReasonIndex] = useState<number>();
  const [showCloseAccountAlert, setShowCloseAccountAlert] = useState(false);

  useEffect(() => {
    const allAccounts = myAccounts.concat(otherAccountCollection || []);
    if (allAccounts.length === 0) {
      return;
    }
    const foundInstitution: AccountCollectionItem = allAccounts?.find(
      (institution: AccountCollectionItem) =>
        institution.sourceInstitutionId === sourceInstitutionId
    );
    setSelectedInstitution(foundInstitution);
  }, [sourceInstitutionId, myAccounts, otherAccountCollection]);

  const unlinkAccount = (unlinkOnly: boolean) => {
    present({
      header: t("unlinkAccount_title"),
      message: t("unlinkAccount_msg"),
      buttons: [
        {
          text: t("cancel"),
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: t(`${unlinkOnly ? "close" : "delete"}`),
          role: "destructive",
          handler: () => {
            (dismiss() as any).then(() => {
              checkGoalsAndClose(unlinkOnly);
            });
          },
        },
      ],
    });
  };

  const checkGoalsAndClose = (unlinkOnly: boolean) => {
    if (
      Boolean(
        selectedInstitution?.subBanks.some(
          (acct) => acct._id === accountInfo.externalFundingAccountFinId
        )
      )
    ) {
      present({
        header: t("pauseGoals_title"),
        message: t("pauseGoals_msg"),
        buttons: [
          {
            text: t("cancel"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: t("delete"),
            role: "destructive",
            handler: () => reallyDelete(unlinkOnly),
          },
        ],
      });
    } else {
      reallyDelete(unlinkOnly);
    }
  };

  const reallyDelete = (unlinkOnly: boolean) => {
    dispatch(setLoading(true));
    put(true, {
      endpoint: "/user/delete/accounts",
      bodyData: {
        accountIds: selectedInstitution?.subBanks.map((account) => account._id),
        unlinkOnly,
      },
    })
      .then(() => {
        dispatch(getMyAccountsData());
        if (!unlinkOnly) {
          dispatch(getAllTransactions());
        }
        navigate(PAGE_URLS.MORE_MANAGE_ACCTS, "back");
      })
      .catch(() => {})
      .finally(() => {
        trackEvent("delete_linkedAcct", { category: "more" });
        dispatch(setLoading(false));
      });
  };

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={
        <NavHeader
          defaultHref={`${PAGE_URLS.MORE_MANAGE_ACCTS}/${sourceInstitutionId}`}
          title={selectedInstitution?.bank || t("accounts")}
        />
      }
      footer={
        reasonIndex === 0 || reasonIndex === 2 || reasonIndex === 3 ? (
          <IonFooter className="ion-no-border">
            <IonToolbar>
              <IonButtons slot="start" className="ion-padding-start">
                <IonButton
                  onClick={() => unlinkAccount(false)}
                  fill="clear"
                  color="medium"
                  expand="full"
                  data-testid="delete-account"
                >
                  {t("deleteAccount")}
                </IonButton>
              </IonButtons>
              <IonButtons slot="end" className="ion-padding-end">
                {reasonIndex === 0 ? (
                  <IonButton
                    size="large"
                    fill="solid"
                    onClick={() => openChat()}
                    expand="block"
                    data-testid="contact-support"
                  >
                    {t("contactSupport")}
                  </IonButton>
                ) : (
                  <IonButton
                    size="large"
                    fill="solid"
                    onClick={() => navigate(PAGE_URLS.MORE_MANAGE_ACCTS, "back")}
                    expand="block"
                  >
                    {t("cancel")}
                  </IonButton>
                )}
              </IonButtons>
            </IonToolbar>
          </IonFooter>
        ) : undefined
      }
    >
      <Section>
        {reasonIndex === 0 || reasonIndex === 2 || reasonIndex === 3 ? (
          <IonGrid className="ion-margin-top">
            <IonRow>
              <IonCol className="font-md">{t(`unlinkInst_header_${reasonIndex}`)}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="font-sm">{t(`unlinkInst_subheader_${reasonIndex}`)}</IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <>
            <IonGrid className="ion-margin-top">
              <IonRow>
                <IonCol className="font-md">{t("unlinkInst_header")}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="font-sm">{t("unlinkInst_subheader")}</IonCol>
              </IonRow>
            </IonGrid>
            <IonList id="unlink-reasons" className="ion-margin-top" lines="none">
              {[0, 1, 2, 3].map((n) => {
                return (
                  <IonItem
                    key={n}
                    detail
                    onClick={() => {
                      trackEvent(`click_unlinkInst_reason_${n}_linkedAcct`, {
                        category: "deleteInstitution",
                      });
                      setReasonIndex(n);
                      setShowCloseAccountAlert(n === 1);
                    }}
                  >
                    <IonLabel className="font-sm">{t(`unlinkInst_reason_${n}`)}</IonLabel>
                  </IonItem>
                );
              })}
            </IonList>
            <IonAlert
              isOpen={showCloseAccountAlert}
              onDidDismiss={() => setShowCloseAccountAlert(false)}
              header={t("areYouSure")}
              message={t("closeInstAlertMsg")}
              buttons={[
                {
                  text: t("cancel"),
                  role: "cancel",
                  cssClass: "secondary",
                },
                {
                  text: t("closeAccounts"),
                  role: "destructive",
                  handler: () => unlinkAccount(true),
                },
              ]}
            />
          </>
        )}
      </Section>
    </PageTemplate>
  );
};
