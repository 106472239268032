import { Dispatch } from "redux";
import {
  DeviceInfo,
  Plugins,
  PermissionType,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  Storage,
} from "@capacitor/core";

import * as types from "../actionTypes";
import { put } from "../../utils/apiOps";
import { LocalPermissionHistory, DeviceType, CardPreferences } from "../reducers/deviceData";
import { handlePush } from "../../utils/pushUtils";
import { AlertActionType, AlertBoxType, alertDataType } from "../reducers/alertUi";
import { setAlertUiState, setShowAlert } from "./alertUi";
import i18n from "i18next";
import { Intercom } from "@ionic-native/intercom";

const { Device, PushNotifications, Permissions } = Plugins;
let intercomPushRegistered = false;

export const setLocalPermissionHistory = (localPermissionHistoryObj: LocalPermissionHistory) => {
  return {
    type: types.SET_LOCAL_PERMISSION_HISTORY,
    payload: localPermissionHistoryObj,
  };
};

export const setHomeCardPreferences = (homeCardPreferencesObj: CardPreferences) => {
  return {
    type: types.UPDATE_HOME_CARD_PREFERENCES,
    payload: homeCardPreferencesObj,
  };
};

export const updateDeviceDetails = (deviceDetailsObj: DeviceInfo) => {
  return {
    type: types.UPDATE_DEVICE_DETAILS,
    payload: deviceDetailsObj,
  };
};

export const updateDeviceInfo = (deviceObj: Partial<DeviceType>) => {
  return {
    type: types.UPDATE_DEVICE_INFO,
    payload: deviceObj,
  };
};

export const postPushAlertFn = () => async (dispatch: Dispatch, getState: any) => {
  return {
    type: types.UPDATE_DEVICE_INFO,
    payload: registerForPush(true, dispatch, getState),
  };
};

const rehydrateDataFromStorage = async (dispatch: Dispatch) => {
  const resp = await Storage.get({ key: "deviceData" });
  if (resp.value) {
    dispatch(updateDeviceInfo(JSON.parse(resp.value)));
  }
};

export const pushInit = () => async (dispatch: Dispatch, getState: any) => {
  console.debug("pushInit start");
  try {
    await rehydrateDataFromStorage(dispatch);
    const deviceInfo = await Device.getInfo();
    dispatch(updateDeviceDetails(deviceInfo));

    if (deviceInfo.platform !== "android" && deviceInfo.platform !== "ios") {
      console.debug("pushInit finish - not on mobile device.");
      await put(true, { endpoint: "/devices/update", bodyData: { deviceDetails: deviceInfo } });
      return;
    }
    await registerListeners(dispatch, getState);
    const { localPermissionHistory } = await updateNotifState(dispatch, getState);
    console.debug(
      `localPermissionHistory on initPush: ${JSON.stringify(localPermissionHistory, null, 2)}`
    );
    if (localPermissionHistory.hardPermissionGranted) {
      // gave permission to us, let's register the listeners and get the token
      PushNotifications.register(); //TODO: is there another way to get the token?
      console.debug("pushInit finish - already registered");
      return;
    }
    if (!localPermissionHistory.hardPermissionGranted) {
      console.debug("NOT HARD PUSH REGISTER");
      if (deviceInfo.platform === "android") {
        //go ahead and get it, no user intervention required
        await registerForPush(true, dispatch, getState);
        console.debug("registerForPush with intercom");
      }
      if (deviceInfo.platform === "ios") {
        // just save the device, we'll ask later
        await put(true, { endpoint: "/devices/update", bodyData: { deviceDetails: deviceInfo } });
      }
    }
    console.debug("pushInit finish");
  } catch (error) {
    console.error(`error pushInit: ${error}`);
  }
};

const registerListeners = async (dispatch: Dispatch, getState: any) => {
  // On success, we should be able to receive notifications
  PushNotifications.addListener("registration", (token: PushNotificationToken) => {
    const deviceData = { ...getState().deviceData };
    dispatch(
      updateDeviceInfo({
        deviceId: token.value,
        localPermissionHistory: {
          softPermissionGranted: true,
          hardPermissionGranted: true,
          lastRequested: deviceData.localPermissionHistory.lastRequested,
          neverAgain: false,
          requests: deviceData.localPermissionHistory.requests,
        },
      })
    );
    if (deviceData.deviceDetails?.platform === "ios") {
      if (!intercomPushRegistered) {
        // stop the infinite loop
        intercomPushRegistered = true;
        console.debug("registerForPush with intercom");
        Intercom.registerForPush();
      }
    } else if (deviceData.deviceDetails?.platform == "android") {
      try {
        Intercom.sendPushTokenToIntercom(token.value)
          .then((success) => console.debug("icomPush: ", success))
          .catch((e) => console.debug("icomPushError: ", e));
      } catch (error) {
        console.error("icomPush Main err: ", error);
      }
    }
    const deviceDataFinal = { ...getState().deviceData };
    put(true, { endpoint: "/devices/update", bodyData: deviceDataFinal });
  });

  // Some issue with our setup and push will not work
  PushNotifications.addListener("registrationError", (error: any) => {
    // alert("Error on registration: " + JSON.stringify(error));
    console.error(`error push registration: ${error}`);
    let deviceData = { ...getState().deviceData };
    let localPermissionHistory = { ...deviceData.localPermissionHistory };
    localPermissionHistory.hardPermissionGranted = false;
    dispatch(setLocalPermissionHistory(localPermissionHistory));
    const deviceDataFinal = { ...getState().deviceData };
    put(true, { endpoint: "/devices/update", bodyData: deviceDataFinal });
  });

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener("pushNotificationReceived", (notification: PushNotification) => {
    console.debug("Push received: " + JSON.stringify(notification));
    handlePush(notification, true, dispatch, getState);
  });

  // Method called when tapping on a notification
  PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification: PushNotificationActionPerformed) => {
      console.debug("Push received closed: " + JSON.stringify(notification));
      handlePush(notification, false, dispatch, getState);
    }
  );
};

const updateNotifState = async (dispatch: Dispatch, getState: any) => {
  let changes: any = {};
  try {
    const notifState = await Permissions.query({ name: PermissionType.Notifications });
    switch (notifState.state) {
      case "denied":
        //check storage to see if we should ask again
        changes.hardPermissionGranted = false;
        break;

      case "granted":
        changes.hardPermissionGranted = true;
        changes.softPermissionGranted = true;
        break;

      case "prompt":
      default:
        break;
    }
    //later we will prompt the user, so no need to do anything but update storage
    dispatch(setLocalPermissionHistory(changes));
  } catch (error) {
    console.error(`error updateNotifState: ${error}`);
  }
  return { ...getState().deviceData };
};

const askPushPermissionCheck = async (dispatch: Dispatch, getState: any) => {
  const { localPermissionHistory } = await updateNotifState(dispatch, getState);
  if (localPermissionHistory.neverAgain || localPermissionHistory.hardPermissionGranted) {
    console.debug("pushPermission: NEVER AGAIN OR ALREADY GRANTED");
    return false;
  }
  if (localPermissionHistory.lastRequested) {
    const lastRequested = localPermissionHistory.lastRequested;
    console.debug(`last asked: ${lastRequested}`);
    const newDate = new Date().getTime();
    const daysSinceLastRequest = Math.floor((newDate - lastRequested) / (1000 * 60 * 60 * 24));
    if (daysSinceLastRequest >= 1) {
      console.debug("lastPushRequest over 1 day ago, ask again");
      return true;
    } else {
      console.debug("lastPushRequest < 1 day ago, no ask");
      return false;
    }
  } else {
    //never asked
    console.debug("never asked");
    return true;
  }
};

export const registerForPush = async (
  skipAskPermission = false,
  dispatch: Dispatch,
  getState: any
) => {
  console.debug("START REGISTER FOR PUSH");
  // allows us to skip this step when we want (e.g. Android or soft granted)
  if (!skipAskPermission) {
    const askPermission = await askPushPermissionCheck(dispatch, getState);
    if (!askPermission) {
      return;
    }
  }
  try {
    //increment the counter
    const deviceData = { ...getState().deviceData } as DeviceType;
    let localPermissionHistory = { ...deviceData.localPermissionHistory };
    if (deviceData.deviceDetails && deviceData.deviceDetails.platform === "web") {
      return;
    }
    // Step to show modal telling them why to enable pushes if on iOS
    if (
      !skipAskPermission &&
      deviceData.deviceDetails &&
      deviceData.deviceDetails.platform === "ios"
    ) {
      localPermissionHistory.lastRequested = new Date().getTime();
      localPermissionHistory.requests++;
      dispatch(setLocalPermissionHistory(localPermissionHistory));
      await requestPushPermissionPopup(dispatch, getState, localPermissionHistory.requests);
      return;
    }
    console.debug("REQUEST PUSH PERMISSIONS.....");
    const result = await PushNotifications.requestPermission();
    if (result.granted) {
      // Register with Apple / Google to receive push via APNS/FCM
      await PushNotifications.register();
    } else {
      console.warn(`error registerForPush: not granted`);
      localPermissionHistory.hardPermissionGranted = false;
      dispatch(setLocalPermissionHistory(localPermissionHistory));
    }
  } catch (error) {
    console.error(`error registerForPush: ${error}`);
  }
};

const requestPushPermissionPopup = async (
  dispatch: Dispatch,
  getState: any,
  requestsCt: number
) => {
  let alertData: alertDataType = {
    message: i18n.t("pushSoftRequestMsg"),
    alertType: AlertBoxType.YES_ALT_NO,
    positiveButton: {
      text: i18n.t("yes"),
      action: AlertActionType.SOFT_PUSH_PERMISSION,
      payload: {},
      role: "cancel",
    },
    negativeButton: {
      text: i18n.t("skip"),
      action: AlertActionType.DO_NOTHING,
      payload: {},
    },
  };
  if (requestsCt >= 3) {
    alertData.alternativeButton = {
      text: i18n.t("stopAsking"),
      action: AlertActionType.SOFT_PUSH_NEVER_AGAIN,
      payload: {},
    };
  }
  dispatch(setAlertUiState(alertData));
  dispatch(setShowAlert(true));
};

export const pushPermissionNeverAgain = () => async (dispatch: Dispatch, getState: any) => {
  const deviceData = { ...getState().deviceData } as DeviceType;
  let localPermissionHistory = { ...deviceData.localPermissionHistory };
  localPermissionHistory.softPermissionGranted = false;
  localPermissionHistory.neverAgain = true;
  dispatch(setLocalPermissionHistory(localPermissionHistory));
};
