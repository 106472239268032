import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getBudgetAnalyticsState } from "../../../redux/selectors";
import { useSelector, useDispatch } from "../../../redux/hooks";
import { clearFeeds, getFeeds, refreshFeeds } from "../../../redux/actions";
import { PageTemplate, NavHeader } from "../../shared/PageTemplate";
import { ListWithDateDivider } from "../../shared/ListWithDateDivider/ListWithDateDivider";
import { PullToRefresh } from "../../shared/PullToRefresh/PullToRefresh";

export const RecentActivitiesDetail: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //redux state
  const { feedsArray, feedsPage } = useSelector(getBudgetAnalyticsState);

  useEffect(() => {
    if (!feedsArray || feedsArray.length === 0) {
      dispatch(getFeeds(1));
    }
  }, []);

  const getMoreFeeds = (e: any) => {
    dispatch(getFeeds(feedsPage))
      .catch(() => {})
      .finally(() => {
        (e.target as HTMLIonInfiniteScrollElement).complete();
      });
  };

  return (
    <PageTemplate
      pageProps={{ "data-testid": "RecentActivityDetail" }}
      header={<NavHeader title={t("recentActivity")} />}
    >
      <PullToRefresh
        refreshHandler={({ detail }) => {
          dispatch(refreshFeeds())
            .catch(() => {})
            .finally(() => (detail as HTMLIonInfiniteScrollElement).complete());
        }}
      />

      {feedsArray.length > 0 ? (
        <ListWithDateDivider
          items={feedsArray}
          itemsType="feed"
          itemsGroupProp="createdOn"
          infiniteScrollHandler={getMoreFeeds}
        />
      ) : (
        <h3 className="font-md ion-text-center">{t("noRecentActivity")}</h3>
      )}
    </PageTemplate>
  );
};
