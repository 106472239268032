import { IonButton } from "@ionic/react";
import React, { useState } from "react";
import { LinkAndShareAccounts } from "./LinkAndShareAccounts";

export const LinkAccountButton: React.FC<any> = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <IonButton {...props} onClick={() => setModalOpen(!modalOpen)} />
      <LinkAndShareAccounts
        isOpen={modalOpen}
        searchAuthOnly
        allowManualAccount={false}
        onSuccess={() => {
          props.onLink();
          setModalOpen(false);
        }}
      />
    </>
  );
};
