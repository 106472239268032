import React, { useEffect, useState } from "react";
import {
  IonInput,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { validateEmail } from "../../../utils/formUtils";
import { useSelector } from "react-redux";
import { getUsersState } from "../../../redux/selectors";
import { registerUser, setLoading, setToastMessage } from "../../../redux/actions";
import "./onboarding.scss";
import Template, { OnboardingCopy } from "./Template";
import { useTranslation } from "react-i18next";
import constants, { PAGE_URLS, EXTERNAL_URLS } from "../../../constants";
import { UserMemberTypes } from "../../../redux/reducers/users";
import { useDispatch } from "../../../redux/hooks";
import { trackEvent } from "../../../vendors/monitoring";
import { OnboardingNavHeader } from "./OnboardingNavHeader";
import { OnboardingProgress } from "./OnboardingProgress";
import { PasswordIndicator } from "./PasswordIndicator";
import { useNavigation } from "../../../utils/useNavigation";
import { Field } from "../../shared/Field/Field";

const pageLocation = "enter-contact";

const checkCaps = new RegExp("[A-Z]");
const checkNum = new RegExp("[0-9]");
const checkLength = new RegExp("().{8,}");
const checkSpecial = new RegExp('[!@#$%^&*(),.?":{}|<>]');

export const EnterContact: React.FC = () => {
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(getUsersState);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [blurred, setBlurred] = useState(false);

  const hasCaps = checkCaps.test(password);
  const hasNum = checkNum.test(password);
  const hasEight = checkLength.test(password);
  const hasSpecial = checkSpecial.test(password);
  const formGood = Boolean(email && password && hasCaps && hasNum && hasEight && hasSpecial);

  useEffect(() => {
    if (user.email && user.email !== "") {
      setEmail(user.email);
    }
  }, [user]);

  // TODO
  // in case you delete everything, need to disable button again

  const handleLogin = () => {
    const firstName = user.firstName;
    const lastName = user.lastName;
    setBlurred(true);
    if (!firstName) {
      // TODO: Do we want to redirect them back to the page that they may enter their first name?
      dispatch(setToastMessage(t("invalidFirstName")));
      return;
    }
    if (!lastName) {
      // TODO: Do we want to redirect them back to the page that they may enter their last name?
      dispatch(setToastMessage(t("invalidLastName")));
      return;
    }

    if (!email || validateEmail(email) === false) {
      dispatch(setToastMessage(t("invalidEmail")));
      return;
    }
    if (!(hasCaps && hasNum && hasEight && hasSpecial)) {
      dispatch(setToastMessage(t("invalidPassword")));
      return;
    }
    dispatch(setLoading(true));

    // registerUser is a thunk action that handles getting device and api data and saving it to redux, storage, and mixpanel
    dispatch(registerUser(firstName, lastName, email, password, user.inviteCode, user.requestedBy))
      .then(() => {
        if (user.memberType === UserMemberTypes.P2) {
          navigate(PAGE_URLS.SIGNUP_PARTNERS_LINKED_ACCTS, "root");
        } else {
          if (user.hasPartner) {
            navigate(PAGE_URLS.SIGNUP_PARTNER, "root");
          } else {
            navigate(PAGE_URLS.SIGNUP_LINK_ACCTS, "root");
          }
        }
      })
      .then(() => trackEvent("next_profileMade", { marketingEvent: "signup", category: "onboard" }))
      .catch(() => { })
      .finally(() => dispatch(setLoading(false)));
  };

  const legal = (
    <IonRow>
      <IonCol>
        <small className="ion-margin-top font-xs text-primary">
          {t("bySigningUp", { appName: `${constants.APP_NAME}'s` })}{" "}
          <a
            href={EXTERNAL_URLS.TERMS_AND_CONDITIONS}
            target="_blank"
            className="weblink"
            rel="noopener noreferrer"
          >
            {t("terms")}
          </a>{" "}
          {t("and")}{" "}
          <a
            href={EXTERNAL_URLS.PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer"
            className="weblink"
          >
            {t("privacy")}
          </a>
          .
        </small>
      </IonCol>
    </IonRow>
  );

  return (
    <Template
      header={
        <OnboardingNavHeader
          pageLocation={pageLocation}
          desktopTitle={<OnboardingProgress step={4} />}
        />
      }
      legal={legal}
      primaryCopy="next"
      handlePrimary={handleLogin}
      primaryTestId={"contact_submit"}
      footerDisabled={!formGood}
      staticFooter
      onboardingStep={4}
    >
      <OnboardingCopy title="enterContact_title" />

      <IonList className="ion-padding-end bounded">
        <Field label={t("email")}>
          <IonInput
            type="email"
            aria-label="email"
            value={email}
            minlength={3}
            required={true}
            inputmode="email"
            data-testid="email"
            autocomplete="email"
            onIonChange={(e) => {
              setEmail(e.detail.value!);
            }}
            onKeyPress={(e) => e.key === "Enter" && handleLogin()}
          ></IonInput>
        </Field>
        <Field label={t("password")}>
          <IonInput
            type="password"
            aria-label="password"
            value={password}
            data-testid="password"
            inputmode="text"
            minlength={8}
            autocomplete="new-password"
            clearOnEdit={false}
            required={true}
            onIonBlur={() => setBlurred(true)}
            onIonFocus={() => setBlurred(false)}
            onIonChange={(e) => {
              setPassword(e.detail.value!);
            }}
            onKeyPress={(e) => e.key === "Enter" && handleLogin()}
          ></IonInput>
        </Field>
      </IonList>
      <IonGrid id="ion-grid-pw-icons" className="ion-padding-start ion-margin-top bounded">
        <IonRow>
          <IonCol className="ion-justify-content-center font-sm">{t("passwordMust")}</IonCol>
        </IonRow>
        <PasswordIndicator text={`8 ${t("characters")}`} valid={hasEight} active={blurred} />
        <PasswordIndicator text={`1 ${t("number")}`} valid={hasNum} active={blurred} />
        <PasswordIndicator text={`1 ${t("capital")}`} valid={hasCaps} active={blurred} />
        <PasswordIndicator text={`1 ${t("specialChar")}`} valid={hasSpecial} active={blurred} />
      </IonGrid>
    </Template>
  );
};
