import React, { useEffect } from "react";
import { IonButton, IonButtons, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { getSpendingChallengeState, getTransactionState } from "../../../redux/selectors";
import {
  SpendingChallengeType,
  ChallengeStatus,
  ChallengeType,
} from "../../../types/spendingChallenge";
import { PAGE_URLS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SpendingChallengeCardTemp } from "./SpendingChallengeCardTemp";
import { getSpendingChallenges } from "../../../redux/actions";
import { add } from "ionicons/icons";
import { orderBy, groupBy, map } from "lodash";
import { PageTemplate, NavHeader, NavButton } from "../../shared/PageTemplate";
import dayjs from "dayjs";

export const SpendingChallengeOverview: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { spendingChallengesList } = useSelector(getSpendingChallengeState);
  const { transactionsList } = useSelector(getTransactionState);
  const activeChallengesList = orderBy(
    (spendingChallengesList || []).filter(
      (challenges: SpendingChallengeType) => challenges.status === ChallengeStatus.ACTIVE
    ),
    ["endDate"],
    ["asc"]
  );

  const oldChallengesList = orderBy(
    (spendingChallengesList || []).filter(
      (challenge: SpendingChallengeType) =>
        challenge.status !== ChallengeStatus.ACTIVE &&
        dayjs().subtract(2, "weeks").isBefore(challenge.endDate)
    ),
    ["endDate"],
    ["desc"]
  );

  const formattedSpendingChallengeList = map(
    groupBy([...activeChallengesList, ...oldChallengesList], "status")
  );

  useEffect(() => {
    dispatch(getSpendingChallenges());
  }, [(spendingChallengesList || []).length, transactionsList]);

  return (
    <PageTemplate
      header={
        <NavHeader title={t("challenges")}>
          <NavButton
            icon={<IonIcon icon={add} />}
            label={t("createNewChallenge")}
            routerLink={PAGE_URLS.SPENDING_CHALLENGES_NEW}
          />
        </NavHeader>
      }
    >
      {spendingChallengesList ? (
        formattedSpendingChallengeList.map((challengeList: SpendingChallengeType[]) => (
          <IonGrid className="ion-no-padding ion-no-margin" key={challengeList[0].status}>
            <IonRow>
              <h2 className="font-title-3 inline-ml-8">{t(`${challengeList[0].status}`)}</h2>
            </IonRow>
            {challengeList.map((challenge: SpendingChallengeType) => (
              <SpendingChallengeCardTemp
                spendingChallenge={challenge}
                key={challenge._id}
                type="overview"
              />
            ))}
          </IonGrid>
        ))
      ) : (
        <h3 className="ion-margin">{t("noSpendingChallengeFound")}</h3>
      )}
    </PageTemplate>
  );
};
