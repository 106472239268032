import React, { useEffect, useState } from "react";
import {
  IonFooter,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonThumbnail,
} from "@ionic/react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryState } from "../../../redux/selectors";
import { BudgetItem, BudgetObj, BudgetURLParams, Category } from "../../../types";
import {
  BUDGET_SHARED_TYPE,
  PAGE_URLS,
  BUDGET_SUPER_CATEGORIES,
  CATEGORY_CONSTANTS,
} from "../../../constants";
import { getBudgetAnalyticsState } from "../../../redux/selectors";
import dayjs from "dayjs";
import { ShareSettingsButtonAndPopover } from "../../shared/User/ShareSettingsButtonAndPopover";
import { getBudget, setLoading } from "../../../redux/actions";
import { BudgetMonthHeader } from "./BudgetMonthHeader";
import numeral from "numeral";
import "./CashFlowDetail.scss";
import { HighLight } from "../../shared/Highlight/HighLight";
import { ActionSheet, NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { useViewport } from "../../../hooks/useViewport";
import { CategoryImg } from "../../shared/Miscellaneous/CategoryImg";
import { categoryState } from "../../../redux/reducers/category";
import { getTransactionLink } from "../../../utils/transUtils";

export const CashFlowDetail: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const { effectiveDate, budgetType } = useParams<BudgetURLParams>();
  const { budgetsArray, loading } = useSelector(getBudgetAnalyticsState);
  const { categoryData, subCategoryData }: categoryState = useSelector(getCategoryState);
  const [cashFlowData, setCashFlowData] = useState<BudgetObj>();
  const [netCashFlowAmount, setNetCashFlowAmount] = useState<number>(0);

  useEffect(() => {
    dispatch(setLoading(loading));
  }, [loading]);

  useEffect(() => {
    const budgetAnalyticsForDate = budgetsArray.find(
      (item: BudgetItem) => item.budgetAnalyticsDate === effectiveDate
    );
    if (!budgetAnalyticsForDate) {
      dispatch(getBudget({ effectiveDate: dayjs(effectiveDate).format("YYYY-MM-DD") }));
    } else {
      const budget = budgetAnalyticsForDate[budgetType]?.budget;
      setCashFlowData(budget);
      setNetCashFlowAmount(
        (budget?.Income.amountActual || 0) * -1 - (budget?.Expenses.amountActual || 0)
      );
    }
  }, [budgetsArray, effectiveDate, budgetType]);

  const total = numeral(netCashFlowAmount).format("$0,0");
  const footerSection = (
    <IonFooter>
      <IonItem lines="none">
        <IonLabel className="ion-text-start">
          <h1 className="font-title-3">{t("netCashFlow")}</h1>
        </IonLabel>
        <IonLabel className="ion-text-end ion-margin-end font-xl">
          {netCashFlowAmount < 0 ? <span> {total}</span> : <HighLight>{total}</HighLight>}
        </IonLabel>
      </IonItem>
    </IonFooter>
  );

  return (
    <PageTemplate
      pageProps={{ id: "CashFlowDetail" }}
      contentProps={{ fullscreen: true }}
      header={
        <NavHeader
          desktopTitle={t("cashFlow")}
          subheader="useTitle"
          title={
            <BudgetMonthHeader
              effectiveDate={effectiveDate}
              baseUrl={PAGE_URLS.CASH_FLOW}
              budgetType={budgetType}
            />
          }
        >
          <ShareSettingsButtonAndPopover
            budgetType={budgetType}
            baseUrl={PAGE_URLS.CASH_FLOW}
            effectiveDate={effectiveDate}
          />
        </NavHeader>
      }
      footer={isMobile ? footerSection : undefined}
    >
      {cashFlowData && (
        <Section>
          <IonList>
            <IonListHeader>
              <IonLabel className="ion-text-start">
                <h1 className="font-title-3">{t("income")}</h1>
              </IonLabel>
              <IonLabel className="ion-text-end ion-margin-end font-xl">
                {numeral(cashFlowData[BUDGET_SUPER_CATEGORIES.INCOME]?.amountActual * -1).format(
                  "$0,0"
                )}
              </IonLabel>
            </IonListHeader>
            <IonItemGroup>
              {subCategoryData
                .filter((subcategory) => subcategory.category === BUDGET_SUPER_CATEGORIES.INCOME)
                .map((subcategory, i) => {
                  return cashFlowData[BUDGET_SUPER_CATEGORIES.INCOME]?.subs[
                    CATEGORY_CONSTANTS.INCOME
                  ]?.subs[subcategory.subCategory]?.amountActual ? (
                    <CashFlowDetailItem
                      key={i}
                      amountActual={
                        cashFlowData[BUDGET_SUPER_CATEGORIES.INCOME]?.subs[
                          CATEGORY_CONSTANTS.INCOME
                        ].subs[subcategory.subCategory]?.amountActual || 0
                      }
                      label={subcategory.displayName}
                      searchParams={{
                        subCategoryFilter: subcategory.subCategory,
                        budgetMonth: effectiveDate,
                        isPrivate: budgetType === BUDGET_SHARED_TYPE.USER,
                      }}
                      category={
                        {
                          design: {
                            iconDarkValue: "Income_dark",
                            iconDarkType: "local",
                          },
                          categoryFullName: subcategory.category,
                        } as Category
                      }
                      isMoneyIn={true}
                    />
                  ) : null;
                })}
            </IonItemGroup>

            <IonListHeader>
              <IonLabel>
                <h1 className="font-title-3">{t("spending")}</h1>
              </IonLabel>
              <IonLabel className="ion-text-end ion-margin-end font-xl">
                {numeral(
                  (cashFlowData[BUDGET_SUPER_CATEGORIES.EXPENSES]?.amountActual || 0) * -1
                ).format("$0,0")}
              </IonLabel>
            </IonListHeader>
            <IonItemGroup>
              {categoryData
                .filter((category) => category.superCategory === BUDGET_SUPER_CATEGORIES.EXPENSES)
                .map((category, i) => {
                  return (
                    <CashFlowDetailItem
                      key={i}
                      amountActual={
                        cashFlowData[BUDGET_SUPER_CATEGORIES.EXPENSES]?.subs[
                          category.categoryShortName
                        ]?.amountActual || 0
                      }
                      label={category.categoryFullName}
                      searchParams={{
                        categoryFilter: category.categoryShortName,
                        budgetMonth: effectiveDate,
                        isPrivate: budgetType === BUDGET_SHARED_TYPE.USER,
                      }}
                      category={category}
                    />
                  );
                })}
            </IonItemGroup>
          </IonList>
          {isMobile ? null : footerSection}
        </Section>
      )}
    </PageTemplate>
  );
};

interface CashFlowDetailItemProps {
  amountActual: number;
  category: Category;
  searchParams: any;
  label: string;
  isMoneyIn?: boolean;
}

export const CashFlowDetailItem: React.FC<CashFlowDetailItemProps> = ({
  amountActual = 0,
  category,
  searchParams,
  label,
  isMoneyIn,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [showItemEvent, setShowItemEvent] = useState<Event>();

  return (
    <>
      <IonItem
        className="font-md"
        lines="none"
        type="button"
        onClick={(e) => {
          e.persist();
          setShowItemEvent(e as any);
        }}
      >
        <IonThumbnail slot="start">
          <CategoryImg category={category} />
        </IonThumbnail>
        <IonLabel className="ion-text-start">{label}</IonLabel>
        <IonLabel className={`ion-text-end ${isMoneyIn && "money-in"}`} slot="end">
          {numeral(amountActual * -1).format("$0,0")}
        </IonLabel>
      </IonItem>
      <ActionSheet
        hideButton
        openEvent={showItemEvent}
        onDidDismiss={setShowItemEvent as () => void}
        buttons={[
          {
            text: t("viewTransactions"),
            handler: () => {
              history.push(getTransactionLink("advancedSearch", searchParams));
            },
          },

          {
            text: t("cancel"),
            role: "cancel",
          },
        ]}
      />
    </>
  );
};
