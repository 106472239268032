import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { informationCircleOutline, lockClosed } from "ionicons/icons";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_URLS } from "../../../constants";
import { post } from "../../../utils/apiOps";
import { getBillsAuditDataState } from "../../../redux/selectors";
import {
  getBillsAuditData,
  setAlertUiState,
  setLoading,
  setShowAlert,
  updateSelectedBillInfo,
} from "../../../redux/actions";
import { billStatusTriage } from "../../../utils/billsAuditUtils";
import { BillsAuditSteps } from "../../../types/billsAudit";
import { alertDataType, AlertBoxType, AlertActionType } from "../../../redux/reducers/alertUi";
import images from "../../../assets/images/illustrations";
import "./BillsAuditAdditionalData.scss";
import { NavHeader, PageTemplate, Section } from "../../shared/PageTemplate";
import { trackEvent } from "../../../vendors/monitoring";

export const BillsAuditAdditionalData: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { selectedBillerInfo, billsAuditData } = useSelector(getBillsAuditDataState);
  const [formValid, setFormValid] = useState(true);
  const [formData, setFormData] = useState<formDataAdditionalInfo>({
    billsharkBillsId: "abcd",
    currentBillAmount: selectedBillerInfo.currentBillAmount!,
  });

  type formDataAdditionalInfo = {
    billsharkBillsId: string;
    currentBillAmount?: number;
    providerAccountNumber?: string;
    providerPasscode?: string;
    paymentLast4?: string;
    specialInstructions?: string;
    ssn4?: string;
  };

  type formDataFields =
    | "providerAccountNumber"
    | "providerPasscode"
    | "specialInstructions"
    | "paymentLast4"
    | "ssn4";

  type validCheckFieldType = {
    field: formDataFields;
    requirementCheck: string;
  };

  const onFormUpdate = (field: formDataFields, value: string | undefined) => {
    let newFormData = { ...formData };
    newFormData[field] = value;
    if (value && ["ssn4", "paymentLast4"].includes(field)) {
      newFormData[field] = value.substr(0, 4);
    }
    setFormData(newFormData);
    validateForm(newFormData);
    console.debug(formData);
  };

  const whyInfo = (field: "ssn4" | "dob") => {
    let alertData: alertDataType = {
      header: field === "ssn4" ? t("billsAuditSSN4") : t("billsAuditDOB"),
      message: t("billsAuditDOB-SSN4Why"),
      alertType: AlertBoxType.OK_ONLY,
      positiveButton: {
        text: t("gotIt"),
        action: AlertActionType.DO_NOTHING,
        payload: {},
        role: "cancel",
      },
    };
    dispatch(setAlertUiState(alertData));
    dispatch(setShowAlert(true));
  };

  const validateForm = (formData: formDataAdditionalInfo) => {
    let valid = true;
    const potentialRequiredFormFields: validCheckFieldType[] = [
      { field: "providerPasscode", requirementCheck: "pin_required" },
      { field: "paymentLast4", requirementCheck: "payment_on_file_last4_required" },
      { field: "ssn4", requirementCheck: "customer_social_last4_required" },
    ];
    potentialRequiredFormFields.map((f) => {
      const value = formData[f.field];
      if (!selectedBillerInfo.billsharkBillerDetails) {
        valid = false;
        return;
      }
      if (
        selectedBillerInfo.billsharkBillerDetails[f.requirementCheck] &&
        (!value || value === null || value === "")
      ) {
        valid = false;
        return;
      }
      if (["ssn4", "paymentLast4"].includes(f.field) && value && value.length < 4) {
        valid = false;
        return;
      }
    });
    setFormValid(valid);
    return valid;
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    dispatch(setLoading(true));
    try {
      if (!formValid) {
        dispatch(setLoading(false));
        return;
      }
      // upload additional data
      await post(true, {
        endpoint: "/billshark/finalSubmit",
        bodyData: {
          ...formData,
          billsharkBillsId: selectedBillerInfo.billsharkBillDetails!.billsharkInfo?._id,
        },
      });
      // refresh the billsAuditData
      await (dispatch(getBillsAuditData()) as any);
      dispatch(updateSelectedBillInfo(selectedBillerInfo.billsharkProviderId!));

      trackEvent("submitBillShark_billsAudit_cv", { category: "analyzeBills" });
      history.replace(PAGE_URLS.BILLS_AUDIT_DONE);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  // make sure they should be here
  const rightPageCheck = () => {
    billStatusTriage(selectedBillerInfo.billsharkProviderId!).then((triageResp) => {
      if (triageResp.baStep !== BillsAuditSteps.UPLOAD_DOCS) {
        // history.replace(PAGE_URLS.BILLS_AUDIT_MAIN);
      }
    });
  };

  useEffect(() => {
    if (!billsAuditData) {
      (dispatch(getBillsAuditData()) as any).then(() => {
        rightPageCheck();
        dispatch(updateSelectedBillInfo(selectedBillerInfo.billsharkProviderId!));
      });
    } else {
      rightPageCheck();
      dispatch(updateSelectedBillInfo(selectedBillerInfo.billsharkProviderId!));
    }
  }, [dispatch]);

  useEffect(() => {
    validateForm(formData);
  }, [selectedBillerInfo]);

  return (
    <PageTemplate
      pageProps={{ id: "BillsAuditAdditionalData" }}
      contentProps={{ fullscreen: true, className: "ion-padding" }}
      header={
        <NavHeader defaultHref={PAGE_URLS.BILLS_AUDIT_MAIN} title={t("homeCards_bills-audit")} />
      }
      footer={
        <Section className="ion-padding">
          <IonFooter className="ion-no-border ion-padding">
            <IonButton
              id="SubmitBillButton"
              type="submit"
              data-testid="ba_final_submit"
              onClick={handleSubmit}
              disabled={!formValid}
            >
              {t("submit")}
            </IonButton>
            <p className="font-sm ion-text-center">{t("billsAuditTandCsSubmit")}</p>
          </IonFooter>
        </Section>
      }
    >
      <Section>
        <IonList lines="none">
          <IonItem>
            <img id="BillsAuditCoin" src={images.billsAuditCoin} alt={t("billsAuditCoinImgAlt")} />
          </IonItem>
          <IonItem lines="none">
            <h1 className="font-title-3">{t("billsAuditAdditionalDataMoreInfo")}</h1>
          </IonItem>
          <IonItem lines="none">
            <p className="font-md">{t("billsAuditAdditionalDataMoreInfoEasier")}</p>
          </IonItem>
        </IonList>
      </Section>
      <form>
        <Section>
          <IonList lines="none">
            {selectedBillerInfo.billsharkBillerDetails?.pin_required && (
              <IonItem>
                <IonLabel position="stacked">
                  <h3>{t("billsAuditAdditionalDataPasscode")}</h3>
                  <p>{selectedBillerInfo.billsharkBillerDetails?.pin_description}</p>
                </IonLabel>
                <IonInput
                  required
                  name="providerPasscode"
                  placeholder={t("billsAuditAdditionalDataPasscodePlaceholder")}
                  value={formData?.providerPasscode}
                  onIonChange={(e) => {
                    onFormUpdate("providerPasscode", e.detail.value!);
                  }}
                ></IonInput>
              </IonItem>
            )}
            {selectedBillerInfo.billsharkBillerDetails?.customer_social_last4_required && (
              <IonItem>
                <IonLabel
                  position="stacked"
                  onClick={() => whyInfo("ssn4")}
                  style={{ pointerEvents: "all" }}
                >
                  <h3>
                    {t("billsAuditSSN4")}
                    <IonIcon icon={informationCircleOutline} />
                  </h3>
                </IonLabel>
                <IonInput
                  required
                  name="ssn"
                  data-testid="ba_ssn4"
                  type="number"
                  min={"0000"}
                  max={"9999"}
                  maxlength={4}
                  minlength={4}
                  value={formData?.ssn4}
                  onIonChange={(e) => {
                    onFormUpdate("ssn4", e.detail.value!);
                  }}
                ></IonInput>
              </IonItem>
            )}
            {selectedBillerInfo.billsharkBillerDetails?.payment_on_file_last4_required && (
              <IonItem>
                <IonLabel position="stacked">
                  <h3>{t("billsAuditPaymentLast4")}</h3>
                </IonLabel>
                <IonInput
                  required
                  name="paymentLast4"
                  data-testid="ba_paymentLast4"
                  type="number"
                  min={"0"}
                  max={"9999"}
                  maxlength={4}
                  minlength={4}
                  value={formData?.paymentLast4}
                  onIonChange={(e) => {
                    onFormUpdate("paymentLast4", e.detail.value!);
                  }}
                ></IonInput>
              </IonItem>
            )}
            <IonItem>
              <IonLabel position="stacked">
                <h3>{t("billsAuditAdditoinalDataSpecial")}</h3>
              </IonLabel>
              <IonTextarea
                name="specialInstructions"
                rows={3}
                placeholder={t("billsAuditAdditoinalDataSpecialPlaceholder")}
                value={formData?.specialInstructions}
                onIonChange={(e) => {
                  onFormUpdate("specialInstructions", e.detail.value!);
                }}
                data-testid="ba_special_instructions"
              ></IonTextarea>
            </IonItem>
          </IonList>
        </Section>
      </form>
    </PageTemplate>
  );
};
