import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonFooter,
  IonGrid,
  IonRow,
  IonToolbar,
  IonList,
  IonItem,
  IonCol,
  IonIcon,
} from "@ionic/react";
import { PAGE_URLS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSpendingChallengeState } from "../../../redux/selectors";
import { useUserName } from "../../shared/User/useUserName";
import { checkmarkOutline } from "ionicons/icons";
import {
  updateCreateNewChanllengeParams,
  updateChallengeFreAndAmount,
} from "../../../redux/actions";
import { getChallengeTypeDesForLength, getCategoryData } from "../../../utils/spendingChallenge";
import "./SpendingChallenge.scss";
import dayjs from "dayjs";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { trackEvent } from "../../../vendors/monitoring";
import _ from "lodash";

export const NewSpendingChallengeLength: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  type ChalLengthType = {
    name: string;
    des: string;
    duration: string;
  };

  //redux state
  const { newSpendingChallenge, categoryFilter, subCategoryFilter, amount } = useSelector(
    getSpendingChallengeState
  );
  //state
  const [selectedChalLength, setSelectedChalLength] = useState<ChalLengthType | null>(null);
  const [chalTypeData, setChalTypeData] = useState<ChalLengthType[]>([]);

  useEffect(() => {
    (async () => {
      const averages: any = await getCategoryData(
        categoryFilter,
        subCategoryFilter,
        newSpendingChallenge.competitionType
      );
      const chalLengthData: any = [
        {
          name: t("oneWeek"),
          des: getChallengeTypeDesForLength(
            newSpendingChallenge.competitionType,
            "week",
            dispatch,
            averages
          ),
          duration: "weekly",
        },
        {
          name: t("oneMonth"),
          des: getChallengeTypeDesForLength(
            newSpendingChallenge.competitionType,
            "month",
            dispatch,
            averages
          ),
          duration: "monthly",
        },
      ];
      setSelectedChalLength(chalLengthData[0]);
      setChalTypeData(chalLengthData);
    })();
  }, [categoryFilter, subCategoryFilter, newSpendingChallenge]);

  //method
  const validateSpendingChallengeData = () => {
    return (
      !!newSpendingChallenge.competitionType && !!newSpendingChallenge.targets && !!categoryFilter
    );
  };

  const handleNextBtnClick = () => {
    if (!validateSpendingChallengeData()) {
      history.push(PAGE_URLS.SPENDING_CHALLENGES_NEW);
      return;
    }
    const competitionType = newSpendingChallenge.competitionType;
    let params = {
      periodType: selectedChalLength?.duration,
      startDate: dayjs(Date.now()).add(1, "day").format("YYYY-MM-DD"),
      endDate:
        selectedChalLength?.duration === "weekly"
          ? dayjs(Date.now()).add(8, "day").format("YYYY-MM-DD")
          : dayjs(Date.now()).add(1, "month").format("YYYY-MM-DD"),
    };
    dispatch(updateCreateNewChanllengeParams(params));
    if (selectedChalLength) {
      dispatch(updateChallengeFreAndAmount(selectedChalLength?.duration as any, amount));
    }
    trackEvent("select_duration_spendChallenge", {
      category: "spendingChallenge",
      label: selectedChalLength?.duration,
    });

    competitionType === "competitive"
      ? history.push(PAGE_URLS.SPENDING_CHALLENGES_NEW_STACK)
      : history.push(PAGE_URLS.SPENDING_CHALLENGES_NEW_GOAL);
    setSelectedChalLength(chalTypeData[0]);
  };

  return (
    <PageTemplate
      header={<NavHeader desktopTitle={t("spendingChallengeTitle")} />}
      footer={
        <IonFooter className="ion-no-border">
          <IonToolbar no-border className="ion-text-center">
            <IonButton onClick={handleNextBtnClick} data-testid="newSpendingNextBtn3">
              {t("next")}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      }
    >
      <Section>
        <IonGrid>
          <IonRow>
            <h2 className="ion-margin-start font-lg">{t("spendingChallenge")}</h2>
          </IonRow>
          <IonRow>
            <h3 className="ion-margin-start font-md">{t("challengeLength")}</h3>
          </IonRow>
          <IonList lines="none">
            {chalTypeData.map((data, index) => (
              <IonItem
                data-testid="newSpendingChallengeLength"
                key={index}
                onClick={() => setSelectedChalLength(data)}
                className={`ion-margin fy-padding-vertical-sm ${
                  selectedChalLength?.name === data.name
                    ? "newSpendingChallenge-lengthSelected"
                    : "newSpendingChallenge-length"
                }`}
              >
                <IonCol>
                  <IonRow>
                    <div className="fy-padding-vertical-xs font-md newSpendingChallenge-h4">
                      {_.capitalize(data.name)}
                    </div>
                  </IonRow>
                  <IonRow>
                    <div className="fy-padding-bottom-xs font-sm newSpendingChallenge-h5">
                      {_.capitalize(data.des)}
                    </div>
                  </IonRow>
                </IonCol>
                {selectedChalLength?.name === data.name ? (
                  <IonCol size="1" className="edit-sharedStatus-icon">
                    <IonIcon icon={checkmarkOutline} />
                  </IonCol>
                ) : null}
              </IonItem>
            ))}
          </IonList>
        </IonGrid>
      </Section>
    </PageTemplate>
  );
};
