import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { PAGE_URLS } from "../constants";
import { setToastMessage } from "../redux/actions";
import { getGoalsState } from "../redux/selectors";
import { fetchGoalDetail } from "../redux/actions/goal";
import { Goal } from "../types/goals";
import { useInView } from "../hooks/useIsInView";
import { useNavigation } from "./useNavigation";

export const useCurrentGoal = (): Goal | { id: string } => {
  const { goalId } = useParams<{ goalId: string }>();
  const history = useHistory();
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pageIsInView = useInView();
  const { viewerGoals, goalDetails, loading } = useSelector(getGoalsState);
  // Ionic router keeps rendering old pages (with the old params) (probably for the page transitions)
  // This causes a needless redirect when a previously visited goal is deleted
  const isOutdatedPage = !goalId || !history.location.pathname.includes(goalId);
  const currentGoalDetail = goalDetails[goalId];
  const currentGoal =
    currentGoalDetail && !currentGoalDetail.loading
      ? currentGoalDetail
      : viewerGoals.find((goal) => goal.id === goalId);
  useEffect(() => {
    if (isOutdatedPage || !pageIsInView) return;
    if (!currentGoalDetail) {
      dispatch(fetchGoalDetail(goalId));
    }
    if (!currentGoal && !loading && !currentGoalDetail?.loading) {
      dispatch(setToastMessage(t("cantFindGoal")));
      navigate(PAGE_URLS.GOALS_HOME, "back");
    }
  }, [currentGoal, loading, pageIsInView]);

  return currentGoal || { id: goalId };
};
