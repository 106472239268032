import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserName } from "../../../../components/shared/User/useUserName";
import { IonText, IonItem, IonLabel, IonInput, IonPicker } from "@ionic/react";
import { PickerColumn } from "@ionic/core";
import { useSelector, useDispatch } from "../../../../redux/hooks";
import { getHouseholdDataState } from "../../../../redux/selectors";
import { editHouseholdFinancialProfile } from "../../../../redux/actions";
import { PAGE_URLS } from "../../../../constants";
import { PageTemplate, Section, NavHeader } from "../../../shared/PageTemplate";
import { Picker } from "../../../shared/Picker/Picker";

export const SharedFinances: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hh = useSelector(getHouseholdDataState);
  const { partnerName } = useUserName();
  const hhProfile = hh?.hhProfiles[0] || {};
  const financialProfile = hhProfile.financialProfile || {};

  const handleIncomeUpdate = (income: string) => {
    dispatch(
      editHouseholdFinancialProfile([
        {
          ...hhProfile,
          financialProfile: {
            ...financialProfile,
            income,
          },
        },
      ])
    );
  };
  return (
    <PageTemplate header={<NavHeader title={t("profile")} defaultHref={PAGE_URLS.MORE_PROFILE} />}>
      <Section>
        <IonText className="finances-header">
          <h2>{t("hhFinances")}</h2>
          <p>{t("hhFinancesDef", { partnerName })}</p>
        </IonText>
        <IonItem lines="none">
          <IonLabel position="stacked">{t("annualIncome")}</IonLabel>
          <Picker
            name="income"
            value={financialProfile.income || t("profileNotSet")}
            options={[
              "$5,000-$29,999",
              "$30,000-$49,999",
              "$50,000 -$74,999",
              "$75,000-$99,999",
              "$100,000-$149,999",
              "$150,000-$249,999",
              "$250,000+",
            ]}
            onChange={handleIncomeUpdate}
          />
        </IonItem>
        <IonItem detail routerLink={PAGE_URLS.MORE_PROFILE_SHARED_FINANCES_INSURANCE} lines="none">
          <IonLabel>{t("hhFinancesInsurance")}</IonLabel>
        </IonItem>
        <IonItem detail routerLink={PAGE_URLS.MORE_PROFILE_SHARED_FINANCES_HELP} lines="none">
          <IonLabel>{t("help_hh-finances")}</IonLabel>
        </IonItem>
      </Section>
    </PageTemplate>
  );
};
