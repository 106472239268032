import React from 'react';
import { useViewport } from '../../../hooks/useViewport';

export const CardsContainer: React.FC = props => {
  const { isLargeDesktop } = useViewport()
  const { children } = props;
  const sections = [[] as any[], [] as any[]];
  React.Children.forEach(children, (card, index) => {
    sections[index % (isLargeDesktop ? 2 : 1)].push(card);
  })
  return <div className="cards-container">
    <div>{sections[0]}</div>
    {isLargeDesktop && <div>{sections[1]}</div>}
  </div>
}