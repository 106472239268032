import React from "react";
import { IonIcon } from "@ionic/react";
import { goalIcons } from "../../../assets/images/illustrations/goals";
import subIcons from "../../../assets/images/goal_icons";

import "./CategoryIcon.scss";

import { NEW_GOAL_TYPES_2 } from "../../../constants";
import { GoalCategory } from "../../../types";

const keyMap = {
  [NEW_GOAL_TYPES_2.VACATION]: "beachBall",
  [NEW_GOAL_TYPES_2.HOME]: "house",
  [NEW_GOAL_TYPES_2.EMERGENCY]: "umbrella",
  [NEW_GOAL_TYPES_2.WEDDING]: "martini",
  [NEW_GOAL_TYPES_2.PAYOFF]: "ladder",
  [NEW_GOAL_TYPES_2.OTHER]: "target",
}

type CategoryIconProps = {
  category: GoalCategory;
  type?: "shadow" | "circle";
  subIcon?: "closed" | "completed"
  className?: string;
  [key: string]: any;
};
export const CategoryIcon: React.FC<CategoryIconProps> = (props) => {
  const { category, type, className = "", subIcon, ...rest } = props;
  const key = keyMap[category] + (type ? "_" + type : "") as keyof typeof goalIcons;
  return <>
    <IonIcon
      icon={goalIcons[key]}
      className={`category-icon ${className}`}
      {...rest}
    />
    {subIcon && subIcons[subIcon] && <IonIcon icon={subIcons[subIcon]} className="category-sub-icon" />}
  </>;
};
