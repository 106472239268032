import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { onboardingFlags, PAGE_URLS, UNAUTHED_URLS } from "../../../constants";
import { getUsersState } from "../../../redux/selectors";

export const RedirectIfAuthed: React.FC = () => {
  const history = useHistory();
  const { flag } = useSelector(getUsersState);
  useEffect(() => {
    if (
      // flag will only ever be set if the user is logged in:
      flag === onboardingFlags.FINISHED_ONBOARDING &&
      UNAUTHED_URLS.includes(history.location.pathname?.toLowerCase())
    ) {
      history.push(PAGE_URLS.HOME);
    }
  }, [flag, history.location.pathname]);
  return null;
};
