import { NavContext } from "@ionic/react";
import { useContext } from "react";

/*
  usage:
  const { goBack, navigate } = useNavigation();
  const handler = () => goBack("/some-route");
  // or
  const handler = () => navigate("/some-route", "forward", "replace");
*/
export const useNavigation = () => {
  return useContext(NavContext);
};
