export interface HouseholdProfile {
  _id: string;
  acceptedBudget: boolean;
  goalsEligible: boolean;
  goalsInterested: boolean;
  tipsEligible: boolean;
  relationshipStage: string;
  name: string;
  anniversary: Date;
  expirements: Array<Experiments>;
  financialProfile: any;
}

export interface Experiments {
  _id: string;
  experimentId: string;
  cohortId: number;
}

export enum MemberRelationshipTypes {
  USER = "user",
  PARTNER = "partner",
}
